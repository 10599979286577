export const filesDownload_en = {
    title: "Files",
    titleDoc: "Documents",
    subTitle: "Download",
    docs:{
        industry4_2021: {
            text: "Materials available for download (speaker presentations) presented at the online conference \"Industry - 4.0 - innovations in the manufacturing sector\" April 29, 2021",
            name1: "Eromkin D. D. Astersoft How to implement ERP and not regret it",
            name2: "Krichmar A. B. PROF - IT ERP system",
            name3: "Martynov S. V. Siemens Application of digital engineering platform",
            name4: "Safonov A. I. BNTU Training of qualified specialists",
            name5: "Osipenko S. Y. СNIITU Unified Virtual Exhibition",
            name6: "Kukareko E. P. Vityaz Integration solution for digital double management",
            name7: "Solovyov S. A. СNIITU Industry 4.0 from theory to practice",
        },
        industry4_2022: {
            text: "Materials available for download (speaker presentations) presented at the online conference \"Industry - 4.0 - innovations in the manufacturing sector\" May 13, 2022",
            name1: "Alekseychik D.V. Prof-IT Appius-PLM",
            name2: "Anufriev Yu.A. YukolaInfo Successful cases of digital transformation",
            name3: "Vaganov A.S. TopSoft Industry 4.0 practical look at current trends in production",
            name4: "Eromkin D.D. Astersoft Automation of personnel management processes",
            name5: "Kukareko E.P. OMPsystem Digital transformation of domestic enterprises",
            name6: "Potapovich A.A. INTERMEH Managing the digital twin of a product",
            name7: "Soloviev S.A. TsNIITU Digital transformation of industrial production",
            name8: "Kheifets M.L. NASB Systemology of objects and processes in the digitalization of production",
        },
        tibo2022: {
            text: "Материалы, доступные для скачивания (презентации докладчиков), представленные на TIBO 2022 года",
            name0: "Глушков, ЦНИИТУ",
            name1: "Интермех, Могилевлифтмаш",
            name2: "МЭТЗ, Мухортов",
            name3: "Бобруйскагромаш, Шостак Жуков Коровкин",
            name4: "Хейфец, НАН РБ",
            name5: "Ваганов, ТопСофт",
            name6: "Кишкурно, Беллесбумпром",
            name7: "Осипенко, ЦНИИТУ",
            name8: "Кричмар, Проф-ИТ",
            name9: "Юкола Ануфриев",
            name10: "Кирсанов, БКМ",
            name11: "Еромкин, Астерсофт",
            name12: "Григорьев, Ресолва Диджитал",
            name13: "Соловьев, круглый стол",
        },
        digitalTrasnformation: {
            text: "Materials available for download (presentations of speakers) presented at the round table “Digital transformation of the industrial sector of the Republic of Belarus. Challenges and Prospects September 20, 2022",
            name0: "Abazovik M.A. Integrated 1C approach to the implementation of ERP projects",
            name1: "Vaganov A.V. TopSoft Belarusian digital product myth or reality",
            name2: "Glushkov A.V. TsNIITU Digital transformation of the manufacturing sector based on the domestic digital platform",
            name3: "Zhukov V.V. Bobruiskagromash Solving urgent problems of digital transformation",
            name4: "Kukareko E.P. Omega Production as a platform for digital transformation of domestic enterprises",
            name5: "Soloviev S.A. Central Research Institute for Digitalization of Enterprises in the Real Sector of the Economy",
        },

        contractClevr: 'Contract for the provision of services for participation in a Single virtual exhibition',
    },
}