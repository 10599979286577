import { Fragment } from "react";
import {
  FileContainer,
  FileIcon,
  FileInfo,
  FileMainBlock,
  FileName,
  FileSettings,
  FileSize,
  FileWrap,
} from "./style/File_css";
import settingExhibitors from "../../assets/Components/test/TestExpanentsImages/images/Settings2.png";
import { icons_svg } from "../Exhibition/Company/imgs/icons.svg";

export const File = ({ documents, page, margin }) => {
  const Container = {
    Manual: page === "manual" ? FileWrap : FileContainer,
  };
  const {points} = icons_svg

  return (
    <Fragment>
      {documents.map((file, index) => {
        return (
          <Container.Manual key={index} style={{ margin: margin }}>
            <FileIcon>{file.icon}</FileIcon>
            <FileInfo>
              <FileMainBlock>
                <FileName>{file.name}</FileName>
                <FileSize>{file?.size}</FileSize>
              </FileMainBlock>
              <a href={file.url} target='_blank' rel="noreferrer"><FileSettings>{points}</FileSettings></a>
            </FileInfo>
          </Container.Manual>
        );
      })}
    </Fragment>
  );
};
