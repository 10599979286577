import styled from "styled-components";

const ModalNewsWrap = styled.div`
  padding: 24px 16px 30px;

  .controls_btn {
    width: 100%;
    display: flex;
    gap: 10px;
    padding-top: 26px;
    button {
      width: 100%;
      height: 36px;
      border-radius: 8px;
      cursor: pointer;
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
    }
    .save {
      background: #34c57f;
      color: #ffffff;
    }
    .back {
      background: #ffffff;
      color: #34c57f;
      border: 1px solid #34c57f;
    }
  }
`;

export{
    ModalNewsWrap
}