import React from "react";
import {Company} from "./Company";
import logoTest from "./imgs/logo-santa-bremor.png"
import bannerBackground from "./imgs/banner-background.png"
import { useDispatch, useSelector } from "react-redux";
import { setAppState } from "../../../store/appSlice/appSlice";
import { useOpenDialogNotFromChat } from "../../../hooks/useOpenDialogNotFromChat";
import { setAuthState } from "../../../store/authSlice/authSlice";
import { setAppChatState } from "../../../store/appChatSlice/appChatSlice";
import { EditCompanyPage } from "./EditCompanyPage/EditCompanyPage";
import { setAdminState } from "../../../store/adminSlice/adminSlice";
import { svg_icons } from "../../../assets/imgs/svg_icons";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GetCompany } from "../../../store/PavilionsSlice/pavilionsAsyncThunk";
import { resetPavilionsState } from "../../../store/PavilionsSlice/pavillionsSlices";





export const CompanyContainer = () => {

  const {userIcon, chatsIcon, trashIcon} = svg_icons
  const {userData} = useSelector((state) => state.profile)
  const {editCompany} = useSelector((state) => state.admin)
  let { id } = useParams();

  const dispatch = useDispatch()
  const openDialogNotFromChat = useOpenDialogNotFromChat();
  const { pathname } = useLocation();

  const getSocialProfile = (value) => {
    let firstProfileIndex = value.indexOf('@')
    let socialProfile = firstProfileIndex !== -1 ? value.slice(firstProfileIndex, value.length) : ''
    let lastProfileIndex = socialProfile.indexOf('/')
    return socialProfile ?  value.slice(firstProfileIndex, lastProfileIndex !== -1 ? lastProfileIndex : value.length ) : value
  }
  const findTagInText = (text) => {
    return text.indexOf('<') === -1 ? false : true
  }

  const createContactsLinks = (contact) => {
    switch (contact.type) {
        case "SITE":
            return (<a href={contact.value} style={{textDecoration: 'none'}}><p className="contact__desc">{contact.value}</p></a>)
        case "INSTAGRAM":
            return (<a href={contact.value} style={{textDecoration: 'none'}}><p className="contact__desc">{getSocialProfile(contact.value)}</p></a>)
        case "YOUTUBE":
            return (<a href={contact.value} style={{textDecoration: 'none'}}><p className="contact__desc">{getSocialProfile(contact.value)}</p></a>)
        case "TIKTOK":
            return (<a href={contact.value} style={{textDecoration: 'none'}}><p className="contact__desc">{getSocialProfile(contact.value)}</p></a>)
        case "VK":
            return (<a href={contact.value} style={{textDecoration: 'none'}}><p className="contact__desc">{getSocialProfile(contact.value)}</p></a>)
        case "FACEBOOK":
            return (<a href={contact.value} style={{textDecoration: 'none'}}><p className="contact__desc">{getSocialProfile(contact.value)}</p></a>)
        case "EMAIL":
            return (<a href={`mailto:${contact.value}`} style={{textDecoration: 'none'}}><p className="contact__desc">{contact.value}</p></a>)
        default:
            return (<p className="contact__desc">{contact.value}</p>)
    }
  }

  const handlerActions = (type, data) => (e, formActions) => {
    switch (type) {
        case "AddFavoritesCompany":
        break;
        case "contextMenu":
            console.log(data)
            dispatch(
                setAppState({
                  contextMenu: {
                    currentTarget: e.currentTarget,
                    listMenu: userData.firstName ? [
                      {
                        onClick: () => {
                          openDialogNotFromChat({dialogUser: data})
                        },
                        icon: chatsIcon,
                        text: "Написать сообщение",
                      },
                      {
                          onClick: () => {
                            dispatch(
                                setAppChatState({
                                  key: "modal",
                                  value: {
                                    type: data.contact ? "removeContactFromContacts" : "addToContact",
                                    content: data,
                                  },
                                })
                              );
                          },
                          icon: data.contact ? trashIcon : userIcon,
                          text: data.contact ? "Убрать из контактов" : "Добавить в контакты",
                          class: data.contact ? " error" : ""
                      }
                    ] :
                    [
                        {
                            onClick: () => {
                                dispatch(setAuthState({ key: "open", value: true }))
                            },
                            icon: userIcon,
                            text: "Войдите в аккаунт"
                          }
                    ]
                  }
              }))
              break;
              case "editCompanyInfo":
                dispatch(setAdminState({key: 'editCompany', value: {open: true, component: data}}))
        break;
        default:
            break;
    }
}

useEffect(() => {
    return () => {
        dispatch(setAdminState({key: 'editCompany', value: {open: false, component: ''}}))
    }
}, [dispatch])

useEffect(() => {
    dispatch(GetCompany(id));

    return () => {
        dispatch(resetPavilionsState('company'))
    }
  }, [dispatch, id]);

useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const testData = {
    logo: logoTest,
    name: "Санта Бремор",
    bannerBackground,
  }


  return (
    <>
    {editCompany.open ?
    <EditCompanyPage component= {editCompany.component}/> 
        :
    <Company
      findTagInText={findTagInText}
      createContactsLinks={createContactsLinks}
      data={testData}
      handlerActions={handlerActions}
      />
    }
    </>
  )
};