import styled from "styled-components";

const AdminHeaderWrap = styled.div`
    position: fixed;
    top: 0;
    height: 60px;
    background: #FFFFFF;
    width: 100%;
    display: flex;
    border-bottom: 0.5px solid #D4D4D4;
    max-width: 1920px;
`;

const HeaderLogo = styled.div`
height: 100%;
display: flex;
align-items: center;
padding: 0 16px;
border-right: 0.5px solid #D4D4D4;
`

const NavigateContentWrap = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-right: 0.5px solid #D4D4D4;
    ul{
        display: inherit;
        gap: 12px;
        width: 100%;
        li{
            width: inherit;
            display: inherit;
            align-items: center;
            justify-content: center;
            list-style: none;
            max-width: 270px;
            height: 32px;
            gap: 6px;
            background: #F5F5F5;
            border-radius: 8px;
            a{
                text-decoration: none;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #363636;
                :hover{
                    color: #34C57F;
                }
            }
        }
    }
`;

const LanguageSelectWrap = styled.div`
width: 150px;
height: inherit;
display: flex;
align-items: center;
margin: 0 16px;
`

const NotificationWrap = styled.div`
height: inherit;
display: flex;
align-items: center;
padding: 0 16px;
border-right: 0.5px solid #D4D4D4;
border-left: 0.5px solid #D4D4D4;
    button{
        width: 32px;
        height: 32px;
        background: #F5F5F5;
        border-radius: 10px;
        border: none;
        outline: none;
        cursor: pointer;
    }
`;

const InfoTextWrap = styled.div`
    height: inherit;
    .text__role{
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #363636;
        margin-left: 12px;
    }
`;
const AdminTextWrap = styled.div`
display: flex;
align-items: center;
margin-left: 12px;
gap: 5px;
p{
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
}
`

export {
    AdminHeaderWrap, HeaderLogo,
    NavigateContentWrap, LanguageSelectWrap,
    NotificationWrap, InfoTextWrap, AdminTextWrap
}