import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminGetUsers, CreateCompany, GetAdminPavilions, updateCompany } from "../../../../../store/adminSlice/adminAsyncThunk";
import { resetAdminState, setCompanyContract, setCompanyPavilionsId, setCreateCompanyInfo } from "../../../../../store/adminSlice/adminSlice";
import { CreateCompanyComponent } from "./CreateCompany";

export const CreateCompanyContainer = ({setCreateCompany, title, content, companyId}) => {
    const localText = useSelector((state) => state.app.appText);
    const {createCompany} = useSelector((state) => state.admin)
    const dispatch = useDispatch()
    const [localState, setLocalState] = useState({
        language: localText.language || "ru",
        content: content,
        pavilionsSelect: false
      });

      console.log(createCompany)

    const handlerActions = (key, data) => (e) => {
        const formData = new FormData();
        let sendData;
        let request;
        switch (key) {
          case "changeLang":
            setLocalState((state) => ({ ...state, language: data }));
            break;
          case "setName":
            dispatch(setCreateCompanyInfo({type: 'name', language: localState.language, value: e.target.value}))
            break;
          case "setContract":
            dispatch(setCompanyContract({file: e.target.files[0]}));
            break;
          case "deleteContract":
            dispatch(setCompanyContract({file: ''}));
            break;
          case "setUrl":
            dispatch(setCreateCompanyInfo({type: 'nameKey', value: e.target.value}))
            break;
          case "setNumberOfContract":
            dispatch(setCreateCompanyInfo({type: 'numberOfContract', value: e.target.value}))
            break;
          case "setEmail":
            dispatch(setCreateCompanyInfo({type: 'email', value: e.target.value}))
            break;
          case "setUser":
            dispatch(setCreateCompanyInfo({type: 'user', value: data}))
            break;
          case 'setNote': 
            dispatch(setCreateCompanyInfo({type: 'note', value: e.target.value}))
            break;
          case "addPavilion":
            dispatch(setCompanyPavilionsId({check: e.target.checked, id: data}))
            break;
          case "openPavilionSelect":
            setLocalState((state) => {return {...state, pavilionsSelect: !state.pavilionsSelect} })
            break;
          case 'create':
            sendData = {
                name: createCompany.name,
                nameKey: createCompany.nameKey,
                numberOfContract: createCompany.numberOfContract,
                email: createCompany.email,
                pavilionsId: createCompany.pavilionsId.map((item) => item.id),
                userId: createCompany.user.id,
                note: createCompany.note,
              };
              request = new Blob([JSON.stringify(sendData)], {
                type: "application/json",
              });
              formData.append("request", request);
              formData.append("contract", createCompany.contract.file);
              dispatch(CreateCompany(formData));
              setCreateCompany({open: false});
            break;
          case 'update':
            sendData = {
                name: createCompany.name,
                nameKey: createCompany.nameKey,
                numberOfContract: createCompany.numberOfContract,
                email: createCompany.email,
                pavilionsId: createCompany.pavilionsId.map((item) => item.id),
                userId: createCompany.user.id,
                note: createCompany.note,
            }
            request = new Blob([JSON.stringify(sendData)], {
                type: "application/json",
              });
              formData.append("request", request);
              if(createCompany.contract.file && !createCompany.contract.fileName) {
                formData.append("contract", createCompany.contract.file);
              }
              dispatch(updateCompany({id: companyId,body: formData}));
              setCreateCompany({open: false});
          break;
          default:
            break;
        }
      };

      useEffect(() => {
        dispatch(AdminGetUsers(''))
        dispatch(GetAdminPavilions())

        return () => {
            dispatch(resetAdminState('users'))
            dispatch(resetAdminState('pavilions'))
            dispatch(resetAdminState('createCompany'))
        }
      }, [dispatch])

    return (
        <CreateCompanyComponent 
        setCreateCompany={setCreateCompany} 
        title={title} 
        localState={localState} 
        handlerActions={handlerActions} />
    )
}