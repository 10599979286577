import {useDispatch, useSelector} from "react-redux";
import {
  setExhibitorLangObj,
  setExhibitorLanguage,
} from "../../../../store/exhibitorSlice/exhibitorSlice";
import {SeoSettingsWrap} from "../../Admin/Companies/CreateCompany/CreateCompany.style";
import {AboutCompanyWrap} from "./AboutCompany.style";

export const AboutCompany = () => {
  const {language, text} = useSelector((state) => state.exhibitor);
  const dispatch = useDispatch();

  return (
    <AboutCompanyWrap>
      <h3>{"Текстовое описание"}</h3>
      <SeoSettingsWrap>
        <div
          className="general_seo_data"
          style={{justifyContent: "space-between", width: "100%"}}
        >
          <p style={{color: "#666666"}}>{"Язык ввода:"}</p>
          <div style={{display: "flex", gap: "10px"}}>
            <button
              className={`seo_language ${language === "ru" ? "active" : ""}`}
              onClick={() => dispatch(setExhibitorLanguage("ru"))}
            >
              {"РУС"}
            </button>
            <button
              className={`seo_language ${language === "en" ? "active" : ""}`}
              onClick={() => dispatch(setExhibitorLanguage("en"))}
            >
              {"ENG"}
            </button>
            <button
              className={`seo_language ${language === "by" ? "active" : ""}`}
              onClick={() => dispatch(setExhibitorLanguage("by"))}
            >
              {"БЕЛ"}
            </button>
          </div>
        </div>
      </SeoSettingsWrap>
      <p className="text_company">{"Текст к разделу"}</p>
      <textarea
        value={text[language]}
        onChange={(e) =>
          dispatch(setExhibitorLangObj({key: "text", value: e.target.value}))
        }
      />
    </AboutCompanyWrap>
  );
};
