export const profile_by = {
    statistics: {
        head: "Статыстыка",
        timePeriod: "Часавы перыяд",
        today: "Сёння",
        week: "Тыдзень",
        month: "Месяц",
        year: "Год",
        setPeriod: "Задаць перыяд",
        periodSelection: "Выбар перыяду",
        apply: "Прымяніць",
        downloadReport: "Спампаваць справаздачу",
    },
    menu: {
        userPanel: "Панэль карыстальніка",
        contactsAndRepres: "Кантакты і прадстаўнікі",
        contacts: "Кантакты",
        myMeet: "Мае сустрэчы",
        requests: "Заяўкі",
        chats: "Чаты",
        favoriteExhs: "Выбраныя экспаненты",
        documents: "Дакументы",
        _3dStand: "3D стэнд",
        exhPage: "Старонка экспаненты",
        isExhibitor: 'Вы з`яўляецеся адміністратарам:',
        profileCompany: 'Прадпрыемства:'
    },
    settings: {
        personalData: "Асабістыя дадзеныя",
        firstName: "Імя",
        lastName: "Прозвішча",
        patronymic: "Імя па бацьку",
        close: "Закрыць",
        save: "Захаваць змены",
        security: "Бяспека",
        password: "Пароль",
        forgetPassword: "Забыліся пароль?",
        currentPassword: "Cучасны пароль",
        change: "Змяніць",
        email: "E-mail",
        settings: "Налады",
    },
    contactsText: {
        name: 'Кантакты',
        all: 'Усяго:',
        searchPl: 'Увядзіце Прозвішча, імя, кантакта',
        offline: 'Усё',
        online: 'У сетцы',
        noContacts: 'Дадайце кантакты',
        noOnline: 'Няма кантактаў у сетцы'
    },
    requestText: {
        name: 'Заяўкі',
        all: 'Усяго',
        searchPl: 'Што вы шукаеце?',
        theme: 'Тэма:',
        noRequests: 'У вас яшчэ няма заявак',
        search: 'Знойдзена',
        all: "Усе",
        requestConsult: "Заяўкі на кансультацыю",
        requestMeet: "Заяўка на сустрэчу",
        incoming: "Якая ўваходзіць",
        outgoing: "Выходная",
        requests: "Заяўкі",
        noRequests: "У вас няма заявак",
    },
    meetsText: {
        name: 'Сустрэчы',
        noMeets: 'У вас яшчэ няма пацверджаных сустрэч',
        searchPl: 'Што вы шукаеце?',
        time: 'Час'
    },
    selectExhText: {
        name: 'Выбраныя экспраненты',
        searchPl: 'Увядзіце назву экспаненты?',
        noSelectExh: 'У вас яшчэ няма абраных экспанентаў',
        add: 'Дададзена'
    },
    documentsText: {
        name: 'Дакументы',
        searchPl: 'Што вы шукаеце?',
        noDocuments: 'У вас яшчэ няма дададзеных дакументаў'
    },
    exhibitorPage: {
        h1: {
            mainScreen: 'Старонка экспанента',
            headScreen: 'Галоўны экран',
            companyDesc: 'Аб кампаніі',
            representatives: 'Прадстаўнікі',
            pavilionsGallery: 'Галерэя павільёна',
            documents: 'Дакументы',
            contacts: 'Кантакты',
        },
        h3: 'Раздзелы',
        descPart1: 'Наладзьце старонку прадпрыемства запоўніўшы прапанаваныя раздзелы.',
        descPart2: 'Каб адлюстраваць усе запоўненыя дадзеныя на старонцы, націсніце',
        descPart3: '“Апублікаваць”.'
    }
}