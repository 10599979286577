import React from "react";
import {
  WrapMessages,
  MessagesView,
  NewContact,
} from "./messages.style";
import SendMessageContainer from "./SendMessage/SendMessageContainer";
import phortUserTest from "../../imgs/Ellipse.png";
import Message from './Message';
import { Element } from "react-scroll";

const Messages = ({handlerActions, messages}) => {


  return (
    <WrapMessages>
      <MessagesView>
        <div className="messages-scroll" id="messages-scroll" onScroll={handlerActions("scrollMessView")} >
          <Element name="scrollMessStart"/>
          {false && (
            <NewContact>
              <img src={phortUserTest} alt="new contact" />
              <p className="contact__name">Анастасия Талашова</p>
              <p className="contact__allow-mes">
                Этот пользователь ещё не в списке ваших контактов. Разрешить
                сообщения?
              </p>
              <div className="contact-btns">
                <button onClick={handlerActions("modalBlockUser")}>
                  Заблокировать
                </button>
                <button onClick={handlerActions("addToContact")}>
                  Разрешить
                </button>
              </div>
            </NewContact>
          )}
          {messages.map(message=> (<Message key={message.messageId} message={message}/>))}
          <Element name="scrollMessEnd"/>
        </div>
      </MessagesView>
      <SendMessageContainer />
    </WrapMessages>
  );
};

export default Messages;
