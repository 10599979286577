import styled from "styled-components";

const RegistrationWrap = styled.div`
    padding: 0 16px;
    margin-top: 24px;
    margin-bottom: 30px;
    .Invalid_Input{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
`;

const ModalContent = styled.div`

    p{
        font-weight: 500;
        font-size: 13px;
        line-height: 110%;
        color: #34C57F;
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 11px;
        .security-icon{
            width: 15.12px;
            height: 18px;
        }
        .phone_icon{
            width: 15.97px;
            height: 16px;
            path{
                stroke: #34C57F;
            }
        }
    }
`;

const UserData = styled.div`
    margin: 12px 0 30px;
 p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
    .SurName{
        margin-bottom: 14px;
    }
    .Name{
        width: 100%;
    }
    .Patronymic{
        width: 100%;
    }
    .Name_Patronymic{
        display: flex;
        gap: 16px;
        @media(max-width: 440px){
            flex-direction: column;
        }
    }
    .Email_Tel{
        display: flex;
        gap: 16px;
        margin-top: 14px;
        @media(max-width: 440px){
            flex-direction: column;
        }
    }
    .Tel{
        width: 100%;
    }
    .Email{
        width: 100%;
    }
`;

const SecurityWrap = styled.div`
    margin: 12px 0 30px;
    p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
    .Password_PasswordRepeat{
        display: flex;
        gap: 16px;
        @media(max-width: 440px){
            flex-direction: column;
        }
    }
    .Password{
        position: relative;
        width: 100%;
    button{
        position: absolute;
        top: 32px;
        right: 3px;
        width: 20px;
        height: 1px;
        border: none;
        background: none;
        cursor: pointer;
    }
    }
    .PasswordRepeat{
        position: relative;
        width: 100%;
        button{
        position: absolute;
        top: 32px;
        right: 3px;
        width: 20px;
        height: 1px;
        border: none;
        background: none;
        cursor: pointer;
        }
    }
    .Email{
        margin-bottom: 14px;
    }
`;

const MoreContacts = styled.div`
    margin: 12px 0 30px;
    p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
`;

const CheckAgreement = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    input[type=checkbox]{
        width: 18px;
        height: 18px;
        border: 1px solid #34C57F;
        border-radius: 4px;
    }
    p{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: #484848;
        padding-left: 20px;
    }
    a{
        text-decoration: none;
        color: #34C57F;
    }
`;
const GoBack = styled.button`
    width: 100%;
    height: 36px;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: none;
    cursor: pointer;
    margin-right: 8px;
    margin-top: 15px;
`

const ActiveRegBtn = styled.button`
    width: 100%;
    height: 36px;
    border: none;
    background: #34C57F;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    margin-top: 15px;
`;

const NotActiveRegBtn = styled.button`
    width: 255px;
    height: 36px;
    border: none;
    background: #34c57f4f;
    border-radius: 8px;
    color: white;
    cursor: initial;
    margin-top: 15px;
    cursor: no-drop;
`;

export {RegistrationWrap,
    ModalContent, UserData,
    SecurityWrap, MoreContacts,
    CheckAgreement,
    GoBack, ActiveRegBtn,
    NotActiveRegBtn
}