import styled from "styled-components";

const ExhibitorPageWrap = styled.div`
width: 100%;
.New_Func{
font-weight: 600;
font-size: 15px;
line-height: 18px;
color: #363636;
margin-top: 350px;
text-align: center;
}
`;

export {
    ExhibitorPageWrap
}