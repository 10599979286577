export const contextMenu_en = {
    writeMes: "Write a message",
    block: "Block",
    unblock: "Unblock",
    removeChat: "Delete a chat",
    removeUser: "Delete a user",
    manamgeAndConfig: "Management and configuration",
    downloadFile: "Download file",
    removeFromContact: "Delete from contacts",
    addFromContact: "Add to contacts",
    remove: "Remove",
}