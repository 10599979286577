import React from "react";
import * as s_c from './style/materials_css'
import * as b_s_c from '../Benefits/style/benefits_css'
import * as f_s_c from '../style/follow_css'
import { File } from "../../File/File";
import { svg_icons } from "../../../assets/imgs/svg_icons";




export const Materials = ({setIsModal, localText}) => {
    const {pdfIcon, jpgIcon} = svg_icons
    const documents = [
    {
        name:`${localText.nameContract}.pdf`,
        icon: pdfIcon,
        url:  'https://dev.files.clevr.by/files/clevr/Типовой договор.pdf'
    },
    {
        name: `${localText.namePolicyHandler}.pdf`,
        icon: pdfIcon,
        url:  'https://dev.files.clevr.by/files/clevr/Политика обработки и хранения персональных данных _ОАО ЦНИИТУ.pdf'
    },
    {
        name: localText.banner_1,
        icon: jpgIcon,
        url:  'https://dev.files.clevr.by/files/clevr/Баннер cleVR 400 x 600.jpg'
    },
    {
        name: localText.banner_2,
        icon: jpgIcon,
        url:  'https://dev.files.clevr.by/files/clevr/Баннер cleVR 192 x 663.jpg'
    },
    {
        name: localText.banner_3,
        icon: jpgIcon,
        url:  'https://dev.files.clevr.by/files/clevr/Баннер cleVR 728 x 90.jpg'
    },
    {
        name: localText.banner_4,
        icon: jpgIcon,
        url:  'https://dev.files.clevr.by/files/clevr/Баннер cleVR 520 x 100.jpg'
    }
    ]


    return (
        <s_c.MaterialsSection>
            <b_s_c.BenefitsHead>
                <h2>{localText.materialsHead}</h2>
                <p>{localText.materialsSubhead}</p>
            </b_s_c.BenefitsHead>
            <s_c.FilesContent>
                <File documents={documents} page={'manual'} margin={'0'} />
            </s_c.FilesContent>
            <s_c.FollowRequest>
                <p>{localText.submitRequest}</p>
            <f_s_c.FollowBtn onClick={() => setIsModal(true)}>{localText.becomeMember}</f_s_c.FollowBtn>
            </s_c.FollowRequest>
        </s_c.MaterialsSection>
    )
}