import styled from "styled-components";

const NewsGalleryWrap  = styled.div`

    padding-bottom: 24px;

    h2{
        padding: 60px 0 22px;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 110%;
        color: #363636;
    }
    .media_img{
        width: 400px;
        height: 196px;
        object-fit: cover;
    }
    .slick-slider{
            width: 100%;
            .slick-prev{
                width: 32px;
                height: 33px;
                left: 10px;
                z-index: 10;
                ::before{
                    content: none;
                }
                :hover{
            .arrow-background{
                fill: white;
            }
            .arrow_1{
                stroke: #363636;
            }
            .arrow_2{
                stroke: #363636;
            }
    }
            }
            .slick-next{
                width: 32px;
                height: 33px;
                right: 10px;
                ::before{
                    content: none;
                }
                :hover{
            .arrow-background{
                fill: white;
            }
            .arrow_1{
                stroke: #363636;
            }
            .arrow_2{
                stroke: #363636;
            }
    }
            }
            .slick-track{
                min-width: 100% !important;
                display: flex !important;
                width: 100% !important;
                .slick-slide{
                    width: auto !important;
                }
            }
        }
`;

export {NewsGalleryWrap}