export const authorization_ru = {
    email: "Ваш e-mail",
    noEmail: "Email не введен",
    alertEmailValid: "Неверный формат Email",
    password: "Ваш пароль",
    noPassword: "Пароль не введен",
    logIn: "Войти",
    logInLoad: "Выполняется вход...",
    signUp: 'Зарегистрироваться',
    userAgreement: 'Пользовательское соглашение',
    registerSuccess: 'Для завершения регистрации нажмите кнопку «Подтвердить» в письме, которое пришло на указанный Вами электронный адрес.',
    placeH: {
        name: 'Ваше имя',
        surname: 'Ваша фамилия',
        patronymic: 'Ваше отчество',
        nickname: 'Ваш никнейм',
        email: 'Электронная почта',
        password: 'Пароль',
        passwordEqual: 'Повторите пароль',
    },
}