import styled from "styled-components";

const AdminContentWrap = styled.div`
    padding: 60px 0 0;
    display: flex;
    background: #fff;
    max-height: 100vh;
    min-height: 100vh;
    overflow: hidden;
`;

export {AdminContentWrap}