import React from "react";
import { Link } from "react-router-dom";
import { serverUrl } from "../../../../../API/api";
import { LinePavilionContent } from "../../../../Main/Pavilions/PavilionVDNX/style/pavilionVDNX_css";
import {
  VDNXContainer,
  DescriptionContainer,
  TextContainer,
} from "./style/NewsSlider_css";

export const NewsSlider = ({news, language}) => {

  return (
    // components for new request 
    <VDNXContainer
      bg={`${serverUrl}/api/media/download/${
        news.media.find((el) => el.preview)?.id
      }`}
    >
      {/*<ImgBg alt="ImgBg" src={filesUrl + news.compressedLogo}></ImgBg>*/}
      <DescriptionContainer>
        <p className="description_title">
          {
            news.newsTranslators.find(
              (item) => item.language.languageCode === language.toUpperCase()
            )?.shortName?.shortName
          }
        </p>
        <LinePavilionContent></LinePavilionContent>
        <TextContainer>
          <p className="description_text">
          {
            news.newsTranslators.find(
              (item) => item.language.languageCode === language.toUpperCase()
            )?.shortName?.shortName
          }
          </p>
          <Link to={`/news/${news.id}`}>
            <button className="description_btn">Читать полностью</button>
          </Link>
        </TextContainer>
      </DescriptionContainer>
    </VDNXContainer>
//    <VDNXContainer bg={'https://files.clevr-expo.com' + news.compressedLogo}>
//    {/*<ImgBg alt="ImgBg" src={filesUrl + news.compressedLogo}></ImgBg>*/}
//    <DescriptionContainer>
//      <p className="description_title">{news.title}</p>
//      <LinePavilionContent></LinePavilionContent>
//      <TextContainer>
//        <p className="description_text">
//          {news.title}
//        </p>
//        <Link to={`/news/${news.id}`}><button className="description_btn">Читать полностью</button></Link>
//      </TextContainer>
//    </DescriptionContainer>
//  </VDNXContainer>

  );
};
