import React from "react";
import { icons_svg } from "../../imgs/icons.svg";
import { ImgSlider } from "./ImgSlider/ImgSlider";

import { WrapMaterials } from "./previewMaterials.styles";
import { VideoSlider } from "./VideoSlider/VideoSlider";
import Preview3DModels from "./Preview3DModels/Preview3DModels";
import { svg_icons } from "../../../../../assets/imgs/svg_icons";
// import { Models3DSlider } from "./Models3DSlider/Models3DSlider";

export const PreviewMaterials = ({
  handlerOpenPreview,
  PavilionId,
  handlerActions,
  type,
  language,
  companyPavilions,
  mediaByTypes,
  company,
  fullScreen,
}) => {
  let { img, videoPlay, cude3D, closeBtn } = icons_svg;
  let { fullScreenIcon } = svg_icons;

  return (
    <>
      <div className="backgroud-modal"></div>
      <WrapMaterials fullScreen={fullScreen}>
        <button className="full-screen-btn" onClick={handlerActions("toggleFullScreen")}>
          {fullScreenIcon}
        </button>

        <div className="materials-header">
          <div className="header-text">
            <h3>Павильон:</h3>
            {companyPavilions.map((pavilion, index) => {
              return (
                <p key={index}>
                  {pavilion.id === PavilionId
                    ? pavilion[language.language]
                    : ""}
                </p>
              );
            })}
          </div>
          <div className="header-btns">
            <button
              onClick={handlerActions("changeType", "image")}
              className={`btns__imgs${type === "image" ? "_active" : ""}`}
            >
              {img} Фото ({mediaByTypes.image.length})
            </button>
            <button
              onClick={handlerActions("changeType", "video")}
              className={`btns__imgs${type === "video" ? "_active" : ""}`}
            >
              {videoPlay} Видео ({mediaByTypes.video.length})
            </button>
            {mediaByTypes.models?.length ? (
              <button
                onClick={handlerActions("changeType", "3dModel")}
                className={`btns__imgs${type === "3dModel" ? "_active" : ""}`}
              >
                {cude3D} 3D модели
                {/* ({mediaByTypes.models?.length}) */}
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="header__close">
            Закрыть{" "}
            <div className="close__icon" onClick={handlerOpenPreview}>
              {closeBtn}
            </div>
          </div>
        </div>

        {/*Content for slider*/}
        <div className="wrap-materials">
          {/* {type === "image" ? <ImgSlider /> : type === "video" ? <VideoSlider /> : <Models3DSlider company={company} models={mediaByTypes.models}/>} */}
          {type === "image" ? (
            <ImgSlider />
          ) : type === "video" ? (
            <VideoSlider />
          ) : (
            <Preview3DModels
              company={company}
              model={mediaByTypes.models && mediaByTypes.models[0]}
            />
          )}
        </div>
      </WrapMaterials>
    </>
  );
};
