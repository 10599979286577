import styled from "styled-components";

const VDNXContainer = styled.div`
    -webkit-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
    width: 100%;
    height: 804px;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${props => props.bg}) center no-repeat;
    background-size: cover;
    @media (max-width: 1200px) {
       height: 600px;
    }
    @media (max-width: 1025px) {
        height: 460px;
    }
`;

const HeadContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .head{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        color: #fff;
        padding: 55px 0 18px;
    }
    .head_text{
        max-width: 330px;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #fff;
        padding-bottom: 74px;
    }
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .description_title{
        -webkit-transition: all 0.4s linear 0s;
        -o-transition: all 0.4s linear 0s;
        transition: all 0.4s linear 0s;
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 34px;
        line-height: 110%;
        color: #fff;
        text-transform: uppercase;
        max-width: 950px;
        text-align: center;
        @media(max-width: 1200px){
            font-size: 30px;
        }
        @media(max-width: 1025px){
            font-size: 26px;
        }
        @media(max-width: 769px){
            font-size: 22px;
        }
        @media(max-width: 480px){
            font-size: 20px;
        }
    }
`;

const TextContainer = styled.div`
    text-align: center;
    .description_text{
        width: 409px;
        height: 74px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #fff;
    }
    .description_btn{
        width: 165px !important;
        height: 34px;
        border: 1.4px solid #FFFFFF;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        cursor: pointer;
        background: none !important;
        color: #fff;
        font-family: 'Gilroy';
    }
`;

const AllpavilionContainer = styled.div`
    display: flex;
    justify-content: center;
    p{
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #fff;
    }
`;


export {VDNXContainer, HeadContent, DescriptionContainer, TextContainer, AllpavilionContainer}