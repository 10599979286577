export const banner_en = {
    title: "CleVR is a virtual exhibition of enterprises with an extensive set of tools for online conferences, seminars and other forms of business communication.",
    h1: 'Virtual exhibition of enterprises',
    subhead: "Visited the stands of enterprises <br /> and get acquainted with the products online!",
    visitExhibition : "Visit the exhibition", 


    h2: "UNIFIED VIRTUAL EXHIBITION",
    h3: "ENTERPRISES OF THE REPUBLIC OF BELARUS",
    h6: "Virtual space for real business",
    toTheExhibition: 'To the exhibition',
}