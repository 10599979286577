import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { svg_icons } from "../../../../assets/imgs/svg_icons"
import { setAppState } from "../../../../store/appSlice/appSlice"
import { GetCompanyDepartments } from "../../../../store/exhibitorSlice/exhibitorAsyncThunk"
import { deleteDepartmentContact, resetStateList, setDepartmentContact, updateDepartmentContact } from "../../../../store/exhibitorSlice/exhibitorSlice"
import { getName } from "../../../../utils/helpers"
import { icons_svg } from "../../../Exhibition/Company/imgs/icons.svg"
import { AddContact } from "./AddContact/AddContact"
import { AddDepartment } from "./addDepartment/AddDepartment"
import { ContactsCard, ContactsPageWrap, DepartmentContactsCard } from "./contactsPage.style"

export const ContactsPage = () => {

    const {companyDepartments} = useSelector((state) => state.exhibitor)
    const companyExhibitor = useSelector((state) => state.profile.userData?.company);
    const companyAdmin = useSelector((state) => state.pavilions.company)
    const {language} = useSelector((state) => state.app.appText)
    const {addIcon, trashIcon, editIcon, siteLinkIcon, pointsIcon} = svg_icons
    const {phone, mail, insta, location, youtubeIcon, facebookIcon,vkIcon, tiktokIcon} = icons_svg
    const [openAddDepartment, setOpenAddDepartment] = useState(false)
    const [addContact, setAddcontact] = useState({open: false, departmentId: -1, update: false})
    const dispatch = useDispatch()
    
    let companyId = companyAdmin?.id || companyExhibitor?.id

    const [localeState, setLocaleState] = useState({
        language: language,
        departments: {
            nameRu: '',
            nameBy: '',
            nameEn: '',
            companyDepartmentContacts: []
        },
        contact: {
            name: '',
            link: ''
        }
    })
    const typeText = (type, key) => {
        switch (type) {
            case "PHONE": 
            if (key === 'select'){
                return 'Телефон'
            }else{
                return (<p className="contact_header">{phone} {'Телефон:'}</p>)
            }
            case "ADDRESS":
                if (key === 'select'){
                    return 'Адрес'
                }else{
                    return (<p className="contact_header">{location} {'Адрес:'}</p>)
                }
            case "FAX":
                if (key === 'select'){
                    return 'Факс'
                }else{
                    return (<p className="contact_header">{phone} {'Факс:'}</p>)
                }
            case "INSTAGRAM":
                if (key === 'select'){
                    return 'Instagram'
                }else{
                    return (<p className="contact_header">{insta} {'Instagram:'}</p>)
                }
            case "YOUTUBE":
                if (key === 'select'){
                    return 'YouTube'
                }else{
                    return (<p className="contact_header">{youtubeIcon} {'YouTube:'}</p>)
                }
            case "TIKTOK":
                if (key === 'select'){
                    return 'TikTok'
                }else{
                    return (<p className="contact_header">{tiktokIcon} {'TikTok:'}</p>)
                }
            case "VK":
                if (key === 'select'){
                    return 'Вконтакте'
                }else{
                    return (<p className="contact_header">{vkIcon} {'Вконтакте:'}</p>)
                }
            case "FACEBOOK":
                if (key === 'select'){
                    return 'Facebook'
                }else{
                    return (<p className="contact_header">{facebookIcon} {'Facebook:'}</p>)
                }
            case "EMAIL":
                if (key === 'select'){
                    return 'Email'
                }else{
                    return (<p className="contact_header">{mail} {'Email:'}</p>)
                }
            case "SITE":
                if (key === 'select'){
                    return 'Сайт'
                }else{
                    return (<p className="contact_header">{siteLinkIcon} {'Сайт:'}</p>)
                }
            default:
                break;
        }
    }


    const handlerActions = (key, data) => (e) => {
        switch (key) {
            case 'setNewDepartments':
                
                break;
            case 'changeLang': 
                setLocaleState((state) => ({ ...state, language: data }));
                break;
            case 'changeDepartmentName': 
                setLocaleState((state) => ({...state, departments: {...state.departments, [getName(state.language)]: e.target.value}}))
                break;
            case 'changeContact':
                setLocaleState((state) => e.target ? ({...state, contact: {...state.contact, [data.key]: e.target.value}}) : ({...state, contact: {...state.contact, [data.key]: e.id}}))
                break;
            case "addContact": 
                dispatch(setDepartmentContact({departmentId: addContact.departmentId, contact: localeState.contact}))
                setLocaleState((state) => {return {...state, contact: {name: '', link: ''}}})
                setAddcontact(false)
                break;
            case "updateContact": 
                dispatch(updateDepartmentContact({departmentId: addContact.departmentId, contact: localeState.contact}))
                setLocaleState((state) => {return {...state, contact: {name: '', link: ''}}})
                setAddcontact(false)
                break;
            case "contextMenu":
                dispatch(
                    setAppState({
                      contextMenu: {
                        currentTarget: e.currentTarget,
                        listMenu: [
                            {
                                onClick: () => {
                                    setLocaleState((state) => {return {...state, contact: {id: data.id ,name: data.type, link: data.value, index: data.index}}})
                                    setAddcontact({open: true, departmentId: data.departmentId, update: true})
                                  },
                                  icon: editIcon,
                                  text: "Редактировать",
                            },
                            {
                            onClick: () => {
                              dispatch(
                                deleteDepartmentContact(data)
                              );
                            },
                            icon: trashIcon,
                            text: "Удалить",
                            class: " error",
                          }
                        ],
                      },
                    })
                  );
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        dispatch(GetCompanyDepartments(companyId))

        return () => {
            dispatch(resetStateList({key: 'companyDepartments'}))
        }
    }, [dispatch, companyId])

    return (
        <ContactsPageWrap>
        {companyDepartments.map((department, departmentIndex) => {
            if(department.remove) return ''
            return (
                <DepartmentContactsCard key={department.id}>
                <div className="department_wrap">
                <div className="text_wrap">
                <h3>
                {department[getName(language)]}
                </h3>
                <p>{'Здесь вы можете добавить контакты и разделить контакты по отделам/подразделениям.'}</p>
                </div>
                <div className="btns_control_wrap">
                    {/*{index === 0 ? <button className="add_department" onClick={() => setOpenAddDepartment(true)}>{addIcon}{'Добавить отдел'}</button> : null}*/}
                    <button className="add_contact" onClick={() => setAddcontact({open: true, departmentId: department.id, update: false})}>{addIcon}{'Добавить контакт'}</button>
                </div>
                </div>
                {addContact.open && addContact.departmentId === department.id ? <AddContact addContact={addContact} typeText={typeText} localeState = {localeState} handlerActions={handlerActions} setAddcontact={setAddcontact} /> : null}
                <div className="contacts_container" >
                    {department.companyDepartmentContacts.map((contact, index) => {
                        if(contact.remove) return ''
                        return (
                            <ContactsCard key={index}>
                                {typeText(contact.type, '')}
                                <p className="contact_info_text">{contact.value}</p>
                                <button className="dots_settings" onClick={handlerActions("contextMenu", {...contact, index, departmentId: department.id})}>{pointsIcon}</button>
                            </ContactsCard>
                        )
                    })}
                </div>
                </DepartmentContactsCard>
            )
        })
        }
        {openAddDepartment ? <AddDepartment localeState = {localeState} handlerActions={handlerActions} setOpenAddDepartment={setOpenAddDepartment}  /> : null}
        </ContactsPageWrap>
    )
}