import { useState } from "react";
import {
  NotificationsButtons,
  NotificationsContainer,
  NotificationsDescription,
  NotificationsItem,
  NotificationsItemInfo,
  NotificationsItemSettings,
  NotificationsWrap,
  SearchContent,
  SendDate,
} from "./style/Notifications_css";

import settings2Icon from "../../../assets/Components/Account/images/Settings2.png";
import testicon from "../../../assets/Components/test/TestlogoFavorites.png";
import { icons_svg } from "../../Exhibition/Company/imgs/icons.svg";

const Notifarr = [
  {
    title: "ЗАО «5 element»",
    date: "12 декабря 2022",
    desc: "Добавил новый стенд в павильоне “Машиностроение”",
  },
  {
    title: "ЗАО «5 element»",
    date: "12 декабря 2022",
    desc: "Добавил новый стенд в павильоне “Машиностроение”",
  },
  {
    title: "ЗАО «5 element»",
    date: "12 декабря 2022",
    desc: "Добавил новый стенд в павильоне “Машиностроение”",
  },
  {
    title: "ЗАО «5 element»",
    date: "12 декабря 2022",
    desc: "Добавил новый стенд в павильоне “Машиностроение”",
  },
  {
    title: "ЗАО «5 element»",
    date: "12 декабря 2022",
    desc: "Добавил новый стенд в павильоне “Машиностроение”",
  },
];

export const Notifications = () => {
  const [activeBtn, setActiveBtn] = useState(true);

  const {search} = icons_svg;


  return (
    <NotificationsContainer>
      <h2>Уведомления (12)</h2>
      <SearchContent>
        <NotificationsButtons>
          <button
            className={`NotificationBtn ${activeBtn ? "active" : ""}`}
            onClick={() => (!activeBtn ? setActiveBtn(true) : "")}
          >
            Все(12)
          </button>
          <button
            className={`NotificationBtn ${!activeBtn ? "active" : ""}`}
            onClick={() => (activeBtn ? setActiveBtn(false) : "")}
          >
            Непрочитанные (4)
          </button>
        </NotificationsButtons>
        <div className="filter-search">
          {search}
          <input placeholder={"Что вы ищите?"}></input>
        </div>
      </SearchContent>
      <NotificationsWrap>
        {Notifarr.map((el, index) => {
          return (
            <NotificationsItem key={index}>
              <img
                style={{ padding: "12px" }}
                src={testicon}
                width={52}
                height={52}
                alt="testicon"
              ></img>
              <div className="Favorites_item_block">
                <NotificationsItemInfo>
                  <h3>{el.title}</h3>
                  <SendDate>{el.date}</SendDate>
                  <NotificationsDescription>{el.desc}</NotificationsDescription>
                </NotificationsItemInfo>
                <NotificationsItemSettings
                  bg={settings2Icon}
                ></NotificationsItemSettings>
              </div>
            </NotificationsItem>
          );
        })}
      </NotificationsWrap>
    </NotificationsContainer>
  );
};
