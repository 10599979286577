import { createSlice } from "@reduxjs/toolkit"
import { extraReducers } from "./newsExtraReducers"
import { reducers } from "./newsReducers"

export const initialState = {
    news: [],
    errors: [],
    selectNews: {},
    filterNews: [],
    createNews: {
        name: {
            ru: '',
            by: '',
            en: ''
        },
        duplicatePreview: false,
        shortName: {
            ru: '',
            by: '',
            en: ''
        },
        text: {
            ru: '',
            by: '',
            en: ''
        },
        draft: false,
        isShow: false,
        mainNews: false,
        newsAuthor: '',
        media: []
    },
}

export const NewsSlice = createSlice({
    name: 'NewsSlice',
    initialState,
    reducers,
    extraReducers
})

export const {
    setNews, 
    setFilteredNews, 
    setInfoNewNews, 
    setMediaNewNews, 
    setNewsAuthor,
    deleteMedia,
    setPreviewMedia,
    resetNewsState,
    setEditNews,
    setText
} = NewsSlice.actions

export const NewsReducer = NewsSlice.reducer