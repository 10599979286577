import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { filesUrl, serverUrl } from "../../../API/api";
import { SLiderContainer } from "./HeadNewsSlider/SliderContainer";
import {
  DateNewsContainer,
  DescContainer,
  NewsContainer,
  NewsItemContent,
  NewsItemsContainer,
  NewsItemWrap,
  NewsLogo,
  NewsSearchBlock,
  NewsWrap,
  ReadMore,
  SearchNewsInput,
} from "./style/newsPage_css";
import { Footer } from "../../Footer/Footer";
import { useEffect, useState } from "react";
import { icons_svg } from "../../Exhibition/Company/imgs/icons.svg";
import { GetNews } from "../../../store/newsSlices/newsAsyncThunk";
import { dateToLocaleDateString } from "../../../utils/helpers";
import { resetNewsState } from "../../../store/newsSlices/NewsSlices";


export const formatDate = (ms) => {
  let date = new Date(ms);
  let months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  let day = date.getDate();
  if (day < 10) day = "0" + day;

  let mm = date.getMonth();

  let month = months.filter((el, index) => index === mm);

  let year = date.getFullYear() ;
  if (year < 10) year = "0" + year;

  return day + " " + month + " " + year;
};

export const NewsPage = () => {
  const dispatch = useDispatch();
  const {language} = useSelector((state) => state.app.appText)
  const { news} = useSelector((state) => state.news);
  const {search} = icons_svg
  const [searchParam, setSearchParam] = useState('')

  useEffect(() => {
    dispatch(GetNews({searchParam, size: 100}));
    return () => {
        dispatch(resetNewsState('news'))
    }
  }, [dispatch, language, searchParam]);

  console.log(news)

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const findTranslatorNews = (type, translators) => {
    switch (type) {
      case "name":
        return translators.find(
          (item) => item.language.languageCode === language.toUpperCase()
        )?.name?.name;
      case "shortName":
        return translators.find(
          (item) => item.language.languageCode === language.toUpperCase()
        )?.shortName?.shortName;
      case "text":
        return translators.find(
          (item) => item.language.languageCode === language.toUpperCase()
        )?.text?.text;
      case "shortText":
        let text = translators.find(
            (item) => item.language.languageCode === language.toUpperCase()
          )?.text?.text;
        return text?.length > 70 ? `${text?.slice(0, 70) + '...'}` : text
      default:
        break;
    }
  }

  return (
    <NewsContainer>
      <SLiderContainer />
      <NewsWrap>
        <NewsSearchBlock>
          <p>{`Все новости (${news.length})`}</p>
          <SearchNewsInput>
          {search}
            <input
              onChange={(e) => setSearchParam(e.currentTarget.value)}
              placeholder={"Что вы ищите ?"}
            ></input>
          </SearchNewsInput>
          {/*<NewsSetting bg={settings1Icon}></NewsSetting>*/}
        </NewsSearchBlock>
        <NewsItemsContainer>
          {news.map((item) => {
            
            return (
              <NewsItemWrap key={item.id}>
                <Link to={`/news/${item.id}`}>
                  <NewsLogo>
                    <img
                      src={`${serverUrl}/api/media/download/${
                        item.media.find((el) => el.preview)?.id
                      }`}
                      alt={"News_Photo"}
                      height={196}
                    ></img>
                  </NewsLogo>
                </Link>
                <NewsItemContent>
                  <Link
                    to={`/news/${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <h2 className="head_name_news">
                      {/*{item.length > 70
                        ? item.title.slice(0, 70) + "..."
                        : item.title}*/}
                      {findTranslatorNews("shortName", item.newsTranslators)}
                    </h2>
                  </Link>
                  <DateNewsContainer>
                    <p>
                      <strong>Добавлено:</strong> {dateToLocaleDateString(item.time)}
                    </p>
                    {/*
                        <ViewsContainer>
                            {eye}{' '}
                            <p>12,3 тыс.</p>
                        </ViewsContainer>
                    */}
                  </DateNewsContainer>
                  <DescContainer>
                    <div className="news_text_container">
                      {/*
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                item.text.length > 0
                                ? item.text.slice(0, 70) + "..."
                                : "",
                            }}
                        >
                        </div>
                      */}
                      <div dangerouslySetInnerHTML={{ __html: findTranslatorNews("shortText", item.newsTranslators)}}></div>
                    </div>
                    <Link to={`/news/${item.id}`}>
                      <ReadMore>Читать больше</ReadMore>
                    </Link>
                  </DescContainer>
                </NewsItemContent>
              </NewsItemWrap>
            );
          })}
        </NewsItemsContainer>
      </NewsWrap>
      <Footer />
    </NewsContainer>

    //<NewsContainer>
    //  <SLiderContainer />
    //  <NewsWrap>
    //    <NewsSearchBlock>
    //      <p>{`Все новости (${news.length})`}</p>
    //      <SearchNewsInput>
    //      {search}
    //        <input
    //          onChange={(e) => setSearchParam(e.target.value)}
    //          placeholder={"Что вы ищите ?"}
    //        ></input>
    //      </SearchNewsInput>
    //      {/*<NewsSetting bg={settings1Icon}></NewsSetting>*/}
    //    </NewsSearchBlock>
    //    <NewsItemsContainer>
    //      {news.map((item) => {
    //        return (
    //          <NewsItemWrap key={item.id}>
    //            <Link to={`/news/${item.id}`}>
    //              <NewsLogo>
    //                <img
    //                //  src={`${serverUrl}/api/media/download/${
    //                //    item.media.find((el) => el.preview)?.id
    //                //  }`}
    //                src={'https://files.clevr-expo.com' + item.compressedLogo}
    //                  alt={"News_Photo"}
    //                  height={196}
    //                ></img>
    //              </NewsLogo>
    //            </Link>
    //            <NewsItemContent>
    //              <Link
    //                to={`/news/${item.id}`}
    //                style={{ textDecoration: "none" }}
    //              >
    //                <h2 className="head_name_news">
    //                  {item.title.length > 41
    //                    ? item.title.slice(0, 41) + "..."
    //                    : item.title}
    //                </h2>
    //              </Link>
    //              <DateNewsContainer>
    //                <p>
    //                  <strong>Добавлено:</strong> {formatDate(item.date)}
    //                </p>
    //                {/*<ViewsContainer>
    //                                        {eye}{' '}
    //                                    <p>12,3 тыс.</p>
    //                                    </ViewsContainer>*/}
    //              </DateNewsContainer>
    //              <DescContainer>
    //                <div className="news_text_container">
    //                  <div
    //                    dangerouslySetInnerHTML={{
    //                      __html:
    //                        item.text.length > 0
    //                          ? item.text.slice(0, 70) + "..."
    //                          : "",
    //                    }}
    //                  ></div>
    //                </div>
    //                <Link to={`/news/${item.id}`}>
    //                  <ReadMore>Читать больше</ReadMore>
    //                </Link>
    //              </DescContainer>
    //            </NewsItemContent>
    //          </NewsItemWrap>
    //        );
    //      })}
    //    </NewsItemsContainer>
    //  </NewsWrap>
    //  <Footer />
    //</NewsContainer>

  );
};
