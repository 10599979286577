import styled from "styled-components";

const HeadScreenWrap = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    h3{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #363636;
    padding-bottom: 22px;
}
`;

const MainInfoWrap = styled.div`
    padding: 26px 16px 0;
    border-right: 0.5px solid #D4D4D4;
    width: 65%;

.logo_container{
    display: flex;
    margin-bottom: 45px;
    .show_logo{
        width: 30%;
        height: 172px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #D4D4D4;
        border-radius: 4px 0px 0px 4px;
        img{
            width: 73px;
            height: 73px;
        }
        position: relative;
        borderRadius: 4px 4px 0px 0px;
    input{
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        left: 0;
        display: none;
      }
      label{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 0 16px;
        top: 0;
        left: 0;
        p{
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            text-align: center;
            width: 200px;
        }
        svg{
            width: 34px;
            height: 20px;
            path{
                fill: #363636;
            }
        }
      }
    }
    .logo_info{
        width: 70%;
        height: 172px;
        background: #F5F5F5;
        border-radius: 0px 5px 4px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        .file_info{
        padding: 12px;
        background: #F5F5F5;
        border-radius: 0px 0px 4px 4px;
        display: flex;
        justify-content: space-between;
        .file_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
        }
        .file_size{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #484848;
        }
        }
        .logo_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            color: #363636;
        }
        .dots_settings{
            width: 28px;
            height: 28px;
            background: #EBEBEB;
            border-radius: 10px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}
.expanents_name{
    .expanents_text{
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        color: #363636;
    }
    .name_container{
        margin-top: 20px;
        textarea{
        width: 100%;
        height: 120px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 9px 12px;
        }
        p{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding-bottom: 5px;
        }
       
    }
}
`;

const PreviewWrap = styled.div`
padding: 26px 16px 0;
width: 75%;

.cover_none{
    position: relative;
    width: 100%;
    background: #F5F5F5; 
    height: inherit; 
    border-radius: 4px 4px 0px 0px;
    input{
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        left: 0;
        display: none;
      }
      label{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        p{
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
        }
        svg{
            height: 20px;
            width: 34px;
            path{
                fill: #363636;
            }
        }
}
}
.preview_cover{
    img{
        height: 398px;
        width: 100%;
        object-fit: cover;
    }
    .file_info{
        padding: 12px;
        background: #F5F5F5;
        border-radius: 0px 0px 4px 4px;
        display: flex;
        justify-content: space-between;
        .file_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
        }
        .file_size{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #484848;
        }
        .dots_settings{
            width: 28px;
            height: 28px;
            background: #EBEBEB;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

`;

export {
    HeadScreenWrap,
    MainInfoWrap,
    PreviewWrap
}