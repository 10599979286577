
import { handlerLocalStorage } from "../../services/handlerLocalStorage.service";
import { ActivateEmail, AddFavoritesCompany, 
    AddRequestsMeeting, AuthUser, 
    ChangeFullName, ChangePassword, 
    ConfirmPassword, DeleteUserDocumentById, getContactsList, 
    GetFavoritesCompany, getRequestsMeetings, 
    getStatisticsCompany, getUserData, 
    GetUserDocuments, RegisterUser, 
    SendConsultCompany, SendRecoverPassword, 
    SendRequestJoinClevr, SendTechSupportQuestion,
    DeleteSelectExhibitor,
    ApproveMeeting,
    getApprovedMeetings,
    deleteMeetings} 
    from "./profileAsyncThunk"

const { set } = handlerLocalStorage();



export const extraReducers = (builder) => {
  /*  bilder.addCase(
    makeCompanyOwner.fulfilled(), (state, {payload}) => {
        state.table.data = state.table.data.map(elem => elem.id === payload ? ({...elem, role: "ROLE_REPRESENTATIVE"}): elem);
    }
    )*/
    builder.addCase(
    AuthUser.fulfilled(), (state, {payload}) => {
        localStorage.setItem('token', payload.token)
        state.auth = payload
    },
    )
    builder.addCase(
    getUserData.fulfilled(), (state, {payload}) => {
        payload.id && set("userId", payload.id);
        state.userData = payload
    },
    getUserData.rejected(), (state, {payload}) => {
        state.errors = payload
    },
    )
    builder.addCase(
        getStatisticsCompany.fulfilled(), (state, {payload}) => {
        state.statistics = payload
    },
    )
    builder.addCase(
        RegisterUser.fulfilled(), (state, {payload}) => {
            state.success = payload.dataToken
        },
        RegisterUser.rejected(), (state, {payload}) => {
            state.errors = state.errors.push(payload)
        },
    )
    builder.addCase(
        ActivateEmail.fulfilled(), (state, {payload}) => {
            state.activateEmail = 'Активация прошла успешно'
        },
        ActivateEmail.rejected(), (state, {payload}) => {
            state.statusSend = 'Ошибка активации'
        },
    )
    builder.addCase(
        ChangeFullName.fulfilled(), (state, {payload}) => {
            state.userData = payload
        },
        ChangeFullName.rejected(), (state, {payload}) => {
            state.errrors = payload
        }
    )
    builder.addCase(
        GetFavoritesCompany.fulfilled(), (state, {payload}) => {
            state.favoritesCompany = payload
        },
        GetFavoritesCompany.rejected(), (state, {payload})=> {
            state.errors = payload
        }
    )
    builder.addCase(
        GetUserDocuments.fulfilled(), (state, {payload}) => {
            state.documents = payload
        },
        GetUserDocuments.rejected(), (state, {payload}) => {
            state.errors = payload
        }
    )
    builder.addCase(
        getContactsList.fulfilled(), (state, {payload}) => {
            state.table.data = payload.contacts;
        },
        getContactsList.rejected(), (state, {payload}) => {
            state.errors = payload
        }
    )
    builder.addCase(
        AddFavoritesCompany.fulfilled, (state, {payload}) => {
            state.message = 'Успешно добавлено в избранное'
            state.openModal = true
        },
        AddFavoritesCompany.rejected, (state, {payload}) => {
            state.errorMessage = 'Уже добавлено в избранное'
            state.openModal = true
        }
    )
    builder.addCase(
        AddRequestsMeeting.fulfilled(), (state, {payload}) => {
            state.message = 'Ваша заявка на встречу успешно отправлена. Следите за изменением статуса в “Уведомлениях”'
            state.openModal = true
        },
        AddRequestsMeeting.rejected(), (state, {payload}) => {
            state.errorMessage = 'Возникла непредвиденная ошибка'
            state.openModal = true
        }
    )
    builder.addCase(
        getRequestsMeetings.fulfilled(), (state, {payload}) => {
            state.requestsMeetings = payload
        },
        getRequestsMeetings.rejected(), (state, {payload}) => {
            state.requestsMeetings = payload
        }
    )
    builder.addCase(
        getApprovedMeetings.fulfilled(), (state, {payload}) => {
            state.table.data = payload;
        }
    )
    builder.addCase(
        SendConsultCompany.fulfilled(), (state, {payload}) => {
            state.message = 'Заявка на консультацию отправлена успешно'
            state.openModal = true
        },
        SendConsultCompany.rejected(), (state, {payload}) => {
            state.errorMessage = 'Произошла ошибка'
            state.openModal = true
        }
    )
    builder.addCase(
        SendRequestJoinClevr.fulfilled(), (state, {payload}) => {
            state.message = 'Заявка на участие уcпешно отправлена'
            state.openModal = true
        },
        SendRequestJoinClevr.rejected(), (state, {payload}) => {
            state.statusSend = 'Ошибка'
            state.openModal = true
        }
    )
    builder.addCase(
        SendTechSupportQuestion.fulfilled(), (state, {payload}) => {
            state.message = 'Спасибо за ваше сообщение! Мы свяжемся с вами в ближайшее время :)'
            state.openModal = true
        },
        SendTechSupportQuestion.rejected(), (state, {payload}) => {
            state.statusSend = 'Произошла непредвиденная ошибка'
            state.openModal = true
        }
    )
    builder.addCase(
        ChangePassword.fulfilled(), (state, {payload}) => {
            console.log('success')
            state.message = 'Запрос на изменение пароля успешно отправлен! Перейдите на почту и подтвердите изменение пароля'
            state.openModal = true
        },
        ChangePassword.rejected(), (state, {payload}) => {
            state.message = 'Произошла ошибка при обновлении пароля. Попробуйте позже'
            state.openModal = true
        }
    )
    builder.addCase(
        ConfirmPassword.fulfilled(), (state, {payload}) => {
            state.message = 'Пароль успешно изменен'
            state.openModal = true
        },
        ConfirmPassword.rejected(), (state, {payload}) => {
            state.message = 'Произошла непредвиденная ошибка'
            state.openModal = true
        }
    )
    builder.addCase(
        SendRecoverPassword.fulfilled(), (state, {payload}) => {
            state.message = 'Временный пароль успешно отправлен на почту. Перейдите на почту и получите пароль'
            state.openModal = true
        },
        SendRecoverPassword.rejected(), (state, {payload}) => {
            state.message = 'Произошла непредвиденная ошибка'
            state.openModal = true
        }
    )
    builder.addCase(
        DeleteUserDocumentById.fulfilled(),(state, {payload}) => {
            if(payload.fileId){
                    state.documents = state.documents.filter(elem => elem.fileId !== payload.fileId);
            }
        }
    )
    builder.addCase(
        DeleteSelectExhibitor.fulfilled(), (state, {payload}) => {
            state.favoritesCompany = state.favoritesCompany.filter((company) => company.companyId !== payload.companyId)
        }
    )
    builder.addCase(
        ApproveMeeting.fulfilled(), (state, {payload}) => {
            state.requestsMeetings = state.requestsMeetings.filter((request) => request.id !== payload.id )
        }
    )
    builder.addCase(
        deleteMeetings.fulfilled(), (state, {payload}) => {
            state.requestsMeetings = state.requestsMeetings.filter((request) => request.id !== payload)
        }
    )
}