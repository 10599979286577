import { setToast } from "../store/appSlice/appSlice"

export const handlerErrorsService = async (
    error, {dispatch, rejectWithValue, getState}
    ) => {

        let {
            app: {
              appText: { baseWords },
            },
          } = (getState && getState()) || { app: { appText: { baseWords: {} } } }

    let text;
    if(!error.response){
        text = baseWords.errorText.message
        await dispatch(setToast({text: baseWords.errorText.message, type: 'error'}))
    }else{
        text = error.response.data.message
        await dispatch(setToast({text: error.response.data.message, type: 'error'}))
    }

    return await rejectWithValue(text)
}