export const companiesPage_by = {
    about: 'Аб кампаніі',
    products: 'Прадукцыя',
    services: 'Паслугі',
    contacts: 'Кантакты',
    shortText: 'Кароткая інфармацыя аб кампаніі',
    partners: 'Партнёры',
    button: 'Для больш дэталёвага знаёмства з прадукцыяй арганізацыі і зносін з яе спецыялістамі, запрашаем наведаць дзеючы стэнд віртуальнай выставы',
    toStand: "на стэнд",
    headerFiles: "Файлы",
    edit: 'Рэдагаваць',
    addFileUserAcc: 'Файл дададзены ў асабісты кабінет',
    repeatFileUserAcc: 'Файл з такім імем ужо існуе ў асабістым кабінеце',
}