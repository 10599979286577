import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { svg_icons } from "../../../../../assets/imgs/svg_icons";
import { AppSwitch } from "../../../../../components/AppSwitch/AppSwitch";
import {
    resetGallery,
  setConsult,
  setGalleryFiles,
  setGalleryInfo,
  setGalleryItems,
} from "../../../../../store/exhibitorSlice/exhibitorSlice";
import { getInfoFile } from "../../../../../utils/helpers";
import { BtnsWrap, CreateMedia } from "./AddGalleryFile.style";

export const NewImage = ({ setAddFile, setOpenAddMedia }) => {
  const { gallery } = useSelector((state) => state.exhibitor);
  const {trashIcon} = svg_icons;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
        dispatch(resetGallery({key: 'image', value: {
        name: '',
        description: '',
        consultation: false,
        src: '',
        file: '',
        type: 'image'
        }}))
    }
  }, [dispatch])

  const ValidForm = Boolean(gallery.image.file)
  return (
    <CreateMedia>
      <div className="preview">
        <p>{"Превью"}</p>
        {Boolean(gallery.image.file) ? (
          <>
            <img src={URL.createObjectURL(gallery.image.file)} alt="" />
            <div className="file_info">
              <div>
                <p className="file_name">
                  {getInfoFile(gallery.image.file).name}
                </p>
                <p className="file_size">
                  {getInfoFile(gallery.image.file).size}
                </p>
              </div>
              <button
                className="delete_file"
                onClick={() =>
                  dispatch(setGalleryFiles({ key: "image", value: "" }))
                }
              >
                {trashIcon}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="image_none_wrap">
              <div className="file_wrap_none">
                <label>
                  <p>{"Изображение не добавлено"}</p>
                  <input
                    type={"file"}
                    accept={"image/png, image/gif, image/jpeg"}
                    onChange={(e) =>
                      dispatch(
                        setGalleryFiles({
                          key: "image",
                          value: e.target.files[0],
                        })
                      )
                    }
                  />
                </label>
              </div>
              <div className="file_info_none"></div>
            </div>
          </>
        )}
      </div>
      <div className="name_exponat">
        <p className="head_text">{"Название экспоната"}</p>
        <input
          placeholder={"Введите название экспоната"}
          onChange={(e) =>
            dispatch(
              setGalleryInfo({
                key: "image",
                infoKey: "name",
                value: e.target.value,
              })
            )
          }
        />
      </div>
      <div className="actions">
        <p className="head_text">{"Действия"}</p>
        <div className="switcher_wrap">
          <p>{"Заказать консультацию"}</p>
          <AppSwitch
            onChange={(e) =>
              dispatch(setConsult({ key: "image", value: e.target.checked }))
            }
          />
        </div>
      </div>
      <div className="description_wrap">
        <p>{"Описание к экспонату"}</p>
        <textarea
          onChange={(e) =>
            dispatch(
              setGalleryInfo({
                key: "image",
                infoKey: "description",
                value: e.target.value,
              })
            )
          }
        />
      </div>
      <BtnsWrap>
        <button className="back" onClick={() => setAddFile("")}>
          {"Отменить"}
        </button>
        <button
          style={ValidForm ? { opacity: "1" } : { opacity: "0.5" }}
          className="send_btn"
          disabled={!ValidForm}
          onClick={() => 
          {
          dispatch(setGalleryItems({key: 'image'}))
          setAddFile('')
          setOpenAddMedia(false)
          }
          }
        >
          {"Сохранить"}
        </button>
      </BtnsWrap>
    </CreateMedia>
  );
};
