import styled from "styled-components";

const WrapModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  background: #fff;
  border-radius: 8px;
  width: 372px;
  padding: 24px 16px 16px;
  text-align: center;

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    background: #f5f5f5;
    border-radius: 8.66667px;
    border: none;
    cursor: pointer;
  }

  & > .modals__head {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
  }

  & > .modals__subhead {
    font-size: 13px;
    line-height: 16px;
    color: #484848;
    padding: 18px 0 26px;
  }

  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    background: #34c57f;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    border: none;
    width: 100%;
    cursor: pointer;
  }
`;

export { WrapModal };
