import React, {useState, useEffect, useCallback} from "react";
import {PreviewMaterials} from "./PreviewMaterials";
import { useDispatch, useSelector } from "react-redux";
import { setAppState } from "../../../../../store/appSlice/appSlice";





export const PreviewMaterialsContainer = ({ handlerOpenPreview, PavilionId }) => {



  const [type, setType] = useState("image");
  const { companyPavilions, companyMedia, company} = useSelector((state) => state.pavilions)
  const fullScreen = useSelector((state) => state.app.fullScreen)
  const dispatch = useDispatch()
  const language = useSelector((state) => state.app.appText);
  let mediaByTypes = {
    image: companyMedia.filter(elem => elem.type === "image") || [],
    video: companyMedia.filter(elem => elem.type === "video") || [],
    models: company.media.filter(elem => elem.type === "model") || [],
  }

  const handlerActions = (key, data) => () => {
    switch (key) {
      case "changeType":
        setType(data);
        break;
      case "toggleFullScreen":
        fullScreen ? document.exitFullscreen() : document.documentElement.requestFullscreen()
        break;   
      default:
        break;
    }
  }

  let fullScreenChange = useCallback((e) => {
    dispatch(setAppState({fullScreen: !fullScreen}))
  }, [dispatch, fullScreen])

  useEffect(() => {
    document.addEventListener("fullscreenchange", fullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", fullScreenChange);
    }
  }, [fullScreenChange]);

  useEffect(() => () => {
    if(fullScreen && document.fullscreenElement) {
      document.exitFullscreen()
      dispatch(setAppState({fullScreen: false}))
    }
  }, [fullScreen, dispatch]);

  return (
    <PreviewMaterials 
      handlerOpenPreview={handlerOpenPreview}
      PavilionId = {PavilionId}
      handlerActions={handlerActions}
      type={type}
      language={language}
      companyPavilions={companyPavilions}
      mediaByTypes={mediaByTypes}
      company={company}
      fullScreen={fullScreen}
      />
  )
};