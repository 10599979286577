export const tibo_by = {
    header: "Матэрыялы, даступныя для запампоўкі (прэзентацыі дакладчыкаў), прадстаўленыя на TIBO 2021",
    documents03_06_2021:{
        name1: 'Яцкевіч А. К. падрыхтоўка кваліфікаваных спецыялістаў пад патрэбы лічбавай трансфармацыі',
        name2: 'Салаўёў С. А. лічбавая платформа кіравання жыццёвым цыклам вырабы і кіравання прадпрыемствам',
        name3: 'Скуратаў А. Г Стандарты Індустрыі 4.0',
        name4: 'Рыбак А. Е. апераджальная падрыхтоўка кадраў для лічбавага вытворчасці',
        name5: 'Патаповіч А. А. пабудова лічбавага прадпрыемства на горадзе Bad рашэнняў ІНТЭРМЕХ',
        name6: 'Пятрушын А. А. вопыт і дасягнутыя вынікі лічбавай трансфармацыі лідэраў пппромышленности',
        name7: 'Асіпенка С. Ю. лічбавая трансфармацыя маркетынгу. ЕВВ ЦНДІТК',
        name8: 'Несцярэнка А. В. Лічбавая трансфармацыя ААТ «Магілёўліфтмаш», праект ўкаранення ERP',
        name9: 'Мышко А. П. Іваноў Ю. М. Віктаравіч В. С. лічбавая трансфармацыя задач суправаджэння вырабаў Мінскага аўтамабільнага завода ў прамысловай эксплуатацыі',
        name10: 'Мухортов А. А. Пабудова лічбавага прадпрыемствы з выкарыстаннем рашэнняў ИНТЕРМЕХ МЭТЗ',
        name11: "Мергур'еў А. В. Нарэжнеў А. М. праект глабальнага пераўзбраення МЭЙКЕР, Рэспубліка Казахстан",
        name12: 'Касьянік А. В. Вопыт укаранення рашэнняў ў харчовае вытворчасць на аснове лічбавага інжынірынгу',
    },
    documents04_06_2021:{
        name1: 'Холева С. А. Вопыт пабудовы сучаснай ІС МЗКЦ',
        name2: 'Хейфец М. Л. Праектаванне і прменение комплексаў тэхналагічнага оборудрования пры цыфравізацыі традыцыйнага і адытыўная вытворчасці',
        name3: 'Сушчанка В. І. Вынікі, планы і перспектывы йифровой трансфармацыі ЗАТ Атлант',
        name4: 'Плебановіч. В. І. Інавацыйныя лічбавыя рашэнні ААТ Планар для вытворчасці сучаснай мікраэлектроннай кампанентнай базы',
        name5: 'Насакин А. кіраванне матрычным вытворчасцю',
        name6: 'Мэйкер. Праект глабальнага пераўзбраення ТАА МЭЙКЕР, Рэспубліка Казахстан, г. Караганда, Карпарацыя Казахмыс.',
        name7: 'Маляроў Д. сусветны вопыт лічбавай трансфармацыі',
        name8: 'Казлоў В. І. Практычныя пытанні ўкаранення Індустрыі 4.0. пры выкананні НДВКП',
        name9: 'Ісакаў К. А. лічбавая трансфармацыя ТАА "Уральскія Лакаматывы" на прыкладзе электрацягніка " Ластаўка»',
        name10: 'Дорош В. А. напрамкі развіцця карпаратыўнай інформсістэмы',
        name11: 'Аляксейчык Д. В. сістэма ERP як аснова лічбавай трансфармацыі на прыкладзе дзеючага прадпрыемства ў Рэспубліцы Беларусь',
    }
}