import React, {useEffect} from "react"
import { useDispatch, useSelector } from "react-redux"
import { WrapAppToast } from "./appToast.style";
import { setAppChatState } from "../../store/appChatSlice/appChatSlice";




const AppToast = () => {

    const toast = useSelector((state) => state.appChat.toast);
    const dispatch = useDispatch();

    useEffect(() => {
        if(toast) {
            setTimeout(() => {
                dispatch(setAppChatState({key: "toast", value: false}));
            }, 3000);
        }
    }, [toast])

    if(!toast) return null;

    return <>
            <WrapAppToast>
                В разработке
            </WrapAppToast>

    </>

}

export default AppToast;