import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GetNews } from "../../store/newsSlices/newsAsyncThunk";
import { GetPavilions } from "../../store/PavilionsSlice/pavilionsAsyncThunk";
import { Footer } from "../Footer/Footer";
import { SLiderContainer } from "../News/NewsPage/HeadNewsSlider/SliderContainer";
import { Banner } from "./Banner/Banner";
import { Description } from "./Description/Description";
import { Features } from "./Features/Features";
import { JoinUS } from "./GetIn/getIn";
import { MobileApp } from "./MobileApp/MobileApp";
import { Partners } from "./Partners/Partners";
import { Pavilions } from "./Pavilions/Pavilions";
import { resetNewsState } from "../../store/newsSlices/NewsSlices";
import { resetPavilionsState } from "../../store/PavilionsSlice/pavillionsSlices";

export const MainPage = () => {

  const dispatch = useDispatch();
  const {language} = useSelector((state) => state.app.appText)

  useEffect(() => {
    dispatch(GetPavilions());
    dispatch(GetNews({size: 5, searchParam: ''}))

    return () => {
        dispatch(resetNewsState('news'))
        dispatch(resetPavilionsState('pavilions'))
    }
  }, [dispatch, language]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Banner />
      <Description />
      <Pavilions />
      <Features />
      <SLiderContainer/>
      <MobileApp />
      <Partners />
      <JoinUS />
      <Footer />
    </>
  );
};
