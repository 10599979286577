import React, { useState } from "react";
import { useRef } from "react";
import { icons_svg } from "../../Pages/Exhibition/Company/imgs/icons.svg";
import { WrapAppSelect, AppSelectLabel } from "./appSelect.style";

// INFO PROPS

// settings={{
  // options: [
  //   {id: 1, value: "1",},
  //   {id: 2, value: "2",},
  //   {id: 3, value: "3",},
  //   {id: 4, value: "4",},
  //   {id: 5, value: "5",},
  // ]
// }

// onChange - function

const AppSelect = ({ settings, onChange, width }) => {
  
  const [{ select }, setLocalState] = useState({
    prevSelect: settings?.options[0].value,
    select: settings?.options[0].value,
  });
  const { arrowList } = icons_svg;
  const appSelectRef = useRef()

  const setPrevSelect = () => {
    setLocalState((state) => ({ ...state, select: state.prevSelect }));
  }

  const setSelectValue = (value) => {
    setLocalState((state) => ({ ...state, select: value }));
  }

  const changeSelect = (option) => () => {
    onChange && onChange({id: option.id, setPrevSelect, setSelectValue, select});
    setLocalState((state) => ({ ...state, prevSelect: state.select, select: option.value }));
    appSelectRef.current?.blur();
  };

  if(!settings) return null;

  return (
    <div className="wrap-app-select">
      {settings?.label ? <AppSelectLabel>{settings?.label}</AppSelectLabel> : ""}
      <WrapAppSelect ref={appSelectRef} className="app-select" id={settings.id}>
        <div className="select__arrow">{arrowList}</div>
        <p className="select__result">{select}</p>
        <div className="select">
          <div className="select__hide" onClick={() => appSelectRef.current?.blur()}/>
          <div className="select-scroll">
            {settings.options?.map((option) => (
              <div
                key={option?.id}
                className="option"
                onClick={changeSelect(option)}
              >{option?.value}</div>
            ))}
          </div>
        </div>
      </WrapAppSelect>
    </div>
  );
};

export default AppSelect;
