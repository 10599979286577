import styled from "styled-components";

const AddNewRoleWrap = styled.div`
padding: 24px 16px 0;
button{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    width: 100%;
    }
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
.new_role_name{
    margin-top: 20px;
    margin-bottom: 25px;
    input{
        width: 100%;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        border: none;
        outline: none;
        padding-left: 12px;
    }
}
.new_role_access{
    margin-bottom: 25px;
.btn_select{
    height: 36px;
    border: none;
    background: #F5F5F5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    color: #363636;
}

}
.new_role_notice{
    margin-bottom: 25px;
    textarea{
        background: #F5F5F5;
    border-radius: 4px;
    height: 108px;
    outline: none;
    border: none;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    width: 100%;
    resize: none;
    padding: 0 12px;
    }
}
`;

const RoleAccessWrap = styled.div`
width: 100%;
box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.35);
padding: 12px;
.pavilion_text{
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #363636;
        padding-bottom: 0;
        padding-right: 16px; 
        padding-left: 32px;
    }
.all_role_access{
    display: flex;
    align-items: center;
    p{
        padding: 0px 10px 0 26px;
    }
}
.search_access_roles{
    width: 100%;
    height: 30px;
    background: #F5F5F5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px;
input{
outline: none;
border: none;
background: #F5F5F5;
width: 100%;
height: 30px;
border-radius: 10px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: #363636;
padding-left: 6px;
}
}
`

const CharterWrap = styled.div`
margin-top: 12px;
border: 0.5px solid #D4D4D4;
border-radius: 4px;
width: 100%;
height: 148px;
padding-right: 4px;
padding-top: 4px;
margin-bottom: 12px;
.charter_container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 8px 0 12px;
    top: 0;
    bottom: 10px;
}
`;

const CharterCard = styled.div`
display: flex;
align-items: center;
padding: 12px;
height: 36px;
background: #F5F5F5;
border-radius: 6px;
p{
    padding-left: 25px;
    padding-top: 5px;
}
`;

const BtnsWrap = styled.div`
display: flex;
gap: 10px;
.back{
    height: 32px;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: none;
}
.send_btn{
    background: #34C57F;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    height: 32px;
}
`;

export {
    AddNewRoleWrap, 
    RoleAccessWrap, 
    CharterWrap,
    CharterCard,
    BtnsWrap
}