
// SEO. Title must be the same as link in header!

export const news_ru = {
    title: "Новости",
    blog: "Блог",
    button: "Читать все новости",
    openNews: 'Открыть новость',
    rollUp: 'Свернуть',
    expand: 'Развернуть',
    addNews: 'Добавить новость',
    editNews: 'Редактировать',
    editPage: {
        btnBack: 'Назад',
        btnSave: 'Сохранить',
        btnLogo: 'Добавить изображение',
        logo: 'Изображение',
        title: 'Заголовок',
        name: 'Название',
        content: 'Контент',
        showNews: 'Показывать новость',
        btnDelete: 'Удалить новость',
    },
}
