import axios from "axios";
import { filesUrl, serverUrl } from "../API/api";
import { handlerLocalStorage } from "./handlerLocalStorage.service";


const downloadFile = async ({type, data}) => {
    const { get } = handlerLocalStorage();

    const createLink = (href, fileName) => {
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const downloadByAxios = async (url, fileName, preview) => {
        let fileUrl;
        await axios({
            url,
            method: 'GET',
            headers: {Authorization: `Bearer ${get("token")}`},
            responseType: 'blob', // important
          }).then((response) => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            if(preview) {
                fileUrl = url;
            } else {
                createLink(url, fileName);
            }
          });
        return fileUrl;
    }
    const downloadMediaByAxios = async (url) => {
         let fileUrl;
         await axios({
           url,
           method: "GET",
           headers: { Authorization: `Bearer ${get("token")}` },
           responseType: "blob", // important
         }).then((response) => {
           let url = window.URL.createObjectURL(new Blob([response.data]));
             fileUrl = url;
         });
         console.log(fileUrl)
         return fileUrl;
    }
    switch (type) {
        case 'excel':
            await downloadByAxios(
                `${serverUrl}/api/statistics/v2/company/report?timeFrom=${data.dateFrom}&timeTo=${data.dateTo}`, 
                `${data.name}`);
            break;
        case 'axios':
            await downloadByAxios(`${serverUrl}/reports/cheques/${data.type}/${data.id}`, `${data.name}.pdf`);
            break;
        case 'previewDialogFile':
            return await downloadByAxios(`${serverUrl}/api/v2/dialogs/file/download?fileId=${data.fileId}&dialogId=${data.dialogId}`, `${data.name}`, true);
        case 'base':
            console.log(data)
            createLink(data.url, data.fileName);
        break;
        case 'mediaNews':
            return await downloadMediaByAxios(
              `${serverUrl}/api/media/download/${data.id}`
            );
        default:
            break;
    }

}

export default downloadFile;