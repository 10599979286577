import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../services/requests.services";

export const SendQuestionFollow = createAsyncThunk(
    'AppSlice/SendQuestionFollow', 
    async (data, thunkAPI) => {
        const {QuestionsApi} = getRequest()
        const response = await QuestionsApi.sendQuestionModal(data)
        return response.status
    }
    )

export const getMetaData = createAsyncThunk(
    'AppSlice/GetAppMetaData',
    async (url, thunkAPI) => {
        const {metaTagsApi} = getRequest()
        const response = await metaTagsApi.getMetaTag(url)
        return response
    }
)