import { Outlet } from "react-router-dom"
import { AccFooter } from "../AccountFooter/AccFooter"
import { AdminContentWrap } from "./Admin.style"
import { AdminHeader } from "./AdminHeader/AdminHeader"
import { AdminPanel } from "./AdminPanel/AdminPanel"

export const  Admin = () => {

    return (
        <>
        <AdminHeader />
        <AdminContentWrap>
            <AdminPanel/>
            <Outlet/>
        </AdminContentWrap>
        <AccFooter/>
        </>
    )
}