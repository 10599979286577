
import { initialState } from './appChatSlice';

const reducers = {

  setAppChatState(state, action) {
    state[action.payload.key] = action.payload.value
  },

  setOpenDialogState(state, action) {
    state.openDialog = {...state.openDialog, ...action.payload};
  },


  setDialogsChat(state, action) {
    switch (action.payload.key) {
      case "unshift":
        state.dialogs.unshift(action.payload.value)
        break;
      case "remove":
        
        break;
    
      default:
        state.dialogs = action.payload.value
        break;
    }
  },

  setNewMessage(state, {payload}) {
    if(state.openDialog?.dialogId === payload.value.dialogId) {
      switch (payload.key) {
        case "unshiftArr":
          state.openDialog.messages = [...payload.value, ...state.openDialog.messages];
          break;
        default:
          state.openDialog.messages?.push(payload.value);
          break;
      }
      state.newMessages = state.newMessages.filter(elem => elem.dialogId !== payload.value.dialogId);
    } else {
      state.newMessages.push(payload.value);
    }    
  },

  resetAppChatState(state, action) {
    switch (action.payload) {
      case "modal":
        state.modal = initialState.modal;
        break;
      case "contextMenu":
        state.contextMenu = initialState.contextMenu;
        break;
      case "openDialog":
        state.openDialog = initialState.openDialog;
        break;
    
      default:
        return initialState;
    }
  }


};

export default reducers;