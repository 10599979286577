import styled from 'styled-components';

const StyledFooter = styled.footer`
    background: #000000;
`

const FooterWidgets = styled.div`
    padding: 32px 16px 30px;
    display: flex;
    justify-content: space-between;
    .download_mobile_container{
        margin-left: 24px;
        @media (max-width: 1025px) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 18px;
            margin-left: 0;
        }
        @media (max-width: 590px) {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }    
    }
    .mobile_line{
            content: '';
            border-radius: 1px;
            background: #3F3F3F;
            height: 1px;
            margin-bottom: 24px;
        }
    @media (max-width: 1025px) {
        flex-direction: column-reverse;
    }
`

const FooterWidgetsColumn = styled.div`
    display: flex;
    justify-content: space-between;
    width: 85%;
    flex-wrap: wrap;
    a{
        text-decoration: none;
    }

  @media (max-width: 1025px) {
    width: 100%;
  }

  @media (max-width: 490px) {
  }
`

const HeadColumns = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
`;

const LinksColumns = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    color: #FFFFFF;
    padding-top: 11px;
`;

const VirtualExhibitionColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const FollowColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const  InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const MobileAppColoumn = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 590px){
        text-align: center;
        margin-bottom: 24px;
    }
    .mobile_links{
        @media (max-width: 1025px) {
        display: flex;
        gap: 16px;
    }
    }
`;

const MobileInstallBtn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 1025px) {
        flex-direction: row;
    }
button{
    width: 152px;
    @media(max-width: 480px){
        width: 135px;
    }
    height: 34px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 1.4px solid #FFFFFF;
    border-radius: 8px;
    color: #FFFFFF;
    background: #000000;
    cursor: pointer;
}

`;

const BottomLine = styled.div`
    content: '';
    border-radius: 1px;
    margin: 0 16px;
    background: #3F3F3F;
    height: 1px;
`

const BlockCopyright = styled.div`
    color: #FFFFFF;
    text-align: center;
`;

const StyledCopyright = styled.p`
  width: 100%;
  padding: 10px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background: #000000;
  color: #FFFFFF;
`;

const AdditInfoBlock = styled.div`
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .mobile_line{
            content: '';
            border-radius: 1px;
            background: #3F3F3F;
            height: 1px;
            width: inherit;
        }
    @media (max-width: 820px) {
        flex-direction: column;
    }
    p{
        color: #FFFFFF;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
    }
    .TechSupport{
        cursor: pointer;
    }
`;

const AdditContent = styled.div`
    display: flex;
    gap: 40px;
    a{
        text-decoration: none;
            color: white;
    }
    @media (max-width: 820px) {
        margin-bottom: 25px;
    }
    @media(max-width: 580px){
        flex-direction: column;
        font-size: 15px;
        gap: 14px;
        margin-bottom: 0;
        p{
            color: #FFFFFF;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
        }
    }
`;

const SocialBlock = styled.div`
    display: flex;
    gap: 38px;
    .Social{
        display: flex;
        gap: 10px;
    }
    .emailIcon{
        display: flex;
        align-items: center;
        gap: 5px;
    }
    @media (max-width: 580px){
        flex-direction: column;
        align-items: center;
        gap: 18px;
        width: 100%;
    }
`;

export { 
        StyledFooter, FooterWidgets, FooterWidgetsColumn, 
        StyledCopyright, VirtualExhibitionColumn, FollowColumn, 
        InfoColumn, MobileAppColoumn, HeadColumns, LinksColumns,
        MobileInstallBtn, BottomLine, BlockCopyright, AdditInfoBlock ,
        AdditContent, SocialBlock
    };