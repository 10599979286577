import { StyledContainer } from "./style/sliderContainer_css";

import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NewsSlider } from "./NewsSlider/NewsSlider";
import { useSelector } from "react-redux";
import { SlickArrowLeft, SlickArrowRight } from "../../../SlickArrowComponent/SlickArrowComponent";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useState } from "react";

export const SLiderContainer = () => {

    const {width} = useWindowDimensions()
    const { news } = useSelector((state) => state.news);
    const {language} = useSelector((state) => state.app.appText)
    const [currSlide, setCurrSlide] = useState(0)
    
    const setCenterPadding = (width) => {
        if(width > 1200){
            return '141px'
        }else if(width < 1200 && width > 769) {
            return '100px'
        } else {
            return '0px'
        }
    }

    const renderSliderDots = (dots) => {
        const mainNews = news.filter((el) => el.mainNews)

        return (
            <div>
                <p style={
                    {
                    color: '#FFF', 
                    textAlign: 'center', 
                    fontFamily: 'Gilroy', 
                    fontSize: '14px', 
                    fontStyle: 'normal', 
                    fontWeight: 500, 
                    lineHeight: '140%'
                    }}
                >{`${currSlide + 1}/${mainNews.length}`}</p>
                <ul>{dots}</ul>
            </div>
        )
    }

  const SliderSettings = {
    centerMode:true,
    centerPadding: setCenterPadding(width),
    dots: true,
    appendDots: (dots) => renderSliderDots(dots),
    customPaging: (i) => <button className="slick_dots_btn"></button>,
    beforeChange: (oldI, nextI) => {
        setCurrSlide(nextI)
    },
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight/>
  };

  if(!news) return null

  return (
    <>
    {news.find((el) => el.mainNews) ?
    <section id="scrollPavilions">
      <StyledContainer>
        <Slider {...SliderSettings}>
        {news.filter((el) => el.mainNews).map((item, index) => {
            return(
                <NewsSlider key={index} news={item} language={language} />
            )
        })
        }
        </Slider>
      </StyledContainer>
    </section>
    :
    null
    }
    </>

/*<>
    <section id="scrollPavilions">
      <StyledContainer>
        <Slider {...SliderSettings}>
        {news.map((item, index) => {
            return(
                <NewsSlider key={index} news={item} language={language} />
            )
        })
        }
        </Slider>
      </StyledContainer>
    </section>
</>*/
);
};
