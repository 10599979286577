import { initialState } from "./profileSlice";


export const reducers = {
    
    setProfileTableState(state, {payload}) {
        state.table = {...state.table, ...payload};
    },

    resetProfileState(state, action) {
        switch (action.payload) {
            case "userData":
                state.userData = initialState.userData;
                break;
            case "ChangeOpenModal" :
                state.openModal = false
                break
            case "table":
                state.table = initialState.table;
                break;
            default:
                break;
        }
    },
    setProfileState(state, action) {
        state[action.payload.key] = action.payload.value;
      },
      resetDocuments(state, {payload}){
        state.documents = state.documents.filter((el) => el.fileId !== payload)
      }
};