import { useSelector } from "react-redux";
import {filesUrl} from "../../../../../API/api";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {changeSizeFile, getInfoFile, getShortName} from "../../../../../utils/helpers";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {AddExhibitor} from "./AddExhibitor";
import {
  AddCompanyWrap,
  BtnsSendWrap,
  SeoSettingsWrap,
} from "./CreateCompany.style";
import { CheckBox } from "../../../../../components/AppCheckBox/AppCheckBox";

export const CreateCompanyComponent = ({
  title,
  setCreateCompany,
  localState,
  handlerActions,
}) => {
  
  const {
    exitIcon, 
    trashIcon, 
    removeIcon, 
    addIcon, 
    pdfIcon, 
    wordIcon, 
    triangleUp, 
    triangleDown
  } = svg_icons;

  const {pavilions, createCompany} = useSelector((state) => state.admin);

  let ValidForm =
    Boolean(createCompany.name.ru) &&
    Boolean(createCompany.name.by) &&
    Boolean(createCompany.name.en) &&
    Boolean(createCompany.email) &&
    Boolean(createCompany.nameKey) &&
    Boolean(createCompany.numberOfContract);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={728}>
        <CloseButton onClick={() => setCreateCompany({open: false})}>
          {exitIcon}
        </CloseButton>
        <AddCompanyWrap>
          <Title>{title}</Title>
          <SeoSettingsWrap>
            <div className="general_seo_data">
              <p>{"Общие данные:"}</p>
              <button
                className={`seo_language ${
                  localState.language === "ru" ? "active" : ""
                }`}
                onClick={handlerActions("changeLang", "ru")}
              >
                {"РУС"}
              </button>
              <button
                className={`seo_language ${
                  localState.language === "en" ? "active" : ""
                }`}
                onClick={handlerActions("changeLang", "en")}
              >
                {"ENG"}
              </button>
              <button
                className={`seo_language ${
                  localState.language === "by" ? "active" : ""
                }`}
                onClick={handlerActions("changeLang", "by")}
              >
                {"БЕЛ"}
              </button>
            </div>
            <button className="open_seo_settings">{"SEO настройки"}</button>
          </SeoSettingsWrap>
          <div className="company_nomination">
            <div>
              <p>{"Наименование"}</p>
              <input
                value={createCompany.name[localState.language]}
                type={"text"}
                placeholder={"Введите название организации"}
                onChange={handlerActions("setName")}
              />
            </div>
            <div>
              <p>{"PagePass"}</p>
              <input
                value={createCompany.nameKey}
                placeholder={"Введите URL"}
                onChange={handlerActions("setUrl")}
              />
            </div>
          </div>
          <div className="company_nomination">
            <div>
              <p>{"Номер договора"}</p>
              <input
                value={createCompany.numberOfContract}
                type={"text"}
                placeholder={"Введите номер договора"}
                onChange={handlerActions("setNumberOfContract")}
              />
            </div>
            <div>
              <p>{"Email"}</p>
              <input
                value={createCompany.email}
                placeholder={"Введите email"}
                onChange={handlerActions("setEmail")}
              />
            </div>
          </div>
          <div className="other_info">
            <div className="info_wrap">
              <p className="info_text">{"Павильон"}</p>
              <button className="btn_select" onClick={handlerActions("openPavilionSelect")}>
                  {createCompany.pavilionsId.length === 0 ? 
                  'Выберите павильон' : 
                  `Выбрано павильонов: ${createCompany.pavilionsId.length}`} 
                  {localState.pavilionsSelect ? triangleUp : triangleDown}
              </button>
              {localState.pavilionsSelect ? 
                <div className="pavilions_container">
                        {pavilions.map((pavilion, i) => {
                            return (
                                <div className="pavilion_card" key={i}>
                                    <CheckBox checked={createCompany.pavilionsId.find((el) => el.id === pavilion.id)?.check || false} onChange={handlerActions("addPavilion", pavilion.id)} />
                                    <p>{pavilion[getShortName(localState.language)]}</p>
                                </div>
                            )
                        }
                        )}
                </div> 
                : 
                null
              }
              {/*<AppSelect
                settings={settings}
                onChange={handlerActions("addPavilion")}
              />*/}
            </div>
            {Boolean(createCompany.user.firstName) ? (
              <div className="info_wrap" style={{position: "relative"}}>
                <p className="info_text">{"Администратор экспонента"}</p>
                <div className="user_info">
                  <img
                    style={{marginRight: "8px"}}
                    src={filesUrl + createCompany.user.avatar}
                    alt="user_avatar"
                  />
                  <div>
                    <div style={{display: "flex", gap: "5px"}}>
                      <p className="fullName_text">
                        {createCompany.user.firstName}
                      </p>
                      <p className="fullName_text">
                        {createCompany.user.lastName}
                      </p>
                    </div>
                    <p className="email_info">{createCompany.user.email}</p>
                  </div>
                  <button
                    className="add_user_company"
                    onClick={handlerActions("setUser", "")}
                  >
                    {removeIcon}
                  </button>
                </div>
              </div>
            ) : (
              <AddExhibitor handlerActions={handlerActions} />
            )}
          </div>
          <div className="agreement_file_wrap">
            <p className="wrap_text">{"Договор"}</p>
            <label className="add_file">
              {createCompany.contract.file ? (
                <div className="contract">
                {
                    createCompany.contract.fileName ?
                      createCompany.contract.fileName.slice(createCompany.contract.fileName.indexOf(".") + 1, createCompany.contract.fileName.length) === ("docx" || "doc") 
                      ?
                        wordIcon
                        : pdfIcon
                    :
                        getInfoFile(createCompany.contract.file).format === ("docx" || "doc")
                    ? wordIcon
                    : pdfIcon
                }
                  <div className="contract_infoWrap">
                    <p className="contract_name">
                      {createCompany.contract.fileName ? createCompany.contract.fileName : getInfoFile(createCompany.contract.file).name}
                    </p>
                    <p className="contract_size">
                      {createCompany.contract.fileName ? changeSizeFile(createCompany.contract.fileSize) : getInfoFile(createCompany.contract.file).size}
                    </p>
                  </div>
                  <button
                    className="btn_basket"
                    onClick={handlerActions("deleteContract")}
                  >
                    {trashIcon}
                  </button>
                </div>
              ) : (
                <>
                  <p className="add_contract">
                    {addIcon} {"Добавить файл договора"}
                  </p>
                  <input
                    accept=".pdf, .docx, .doc"
                    type={"file"}
                    onChange={handlerActions("setContract")}
                  />
                </>
              )}
            </label>
          </div>
          <div className="description">
            <p>{"Примечание"}</p>
            <textarea value={createCompany.note} onChange={handlerActions('setNote')}/>
          </div>
          <BtnsSendWrap>
            <button className="back" onClick={() => setCreateCompany({open: false})}>
              {"Отменить"}
            </button>
            <button
              style={!ValidForm ? {opacity: "0.5"} : {opacity: "1"}}
              className="Send"
              disabled={!ValidForm}
              onClick={handlerActions(localState.content)}
            >
              {"Сохранить"}
            </button>
          </BtnsSendWrap>
        </AddCompanyWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
