import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../services/requests.services";
import { setAppChatState } from "../appChatSlice/appChatSlice";
import { handlerErrorsService } from "../../services/handlerErrors.service";

/*export const makeCompanyOwner = createAsyncThunk(
  "ProfileSlice/makeCompanyOwner",
  async (data, thunkAPI) => {
    let { UserApi } = getRequest();
    await UserApi.makeCompanyOwner(data);
    return data.contactId;
  }
);*/

// export const getContactsUser = createAsyncThunk(
//   "profile/getContactsUser",
//   async (data = {}, thunkAPI) => {
//     let { profileAPI } = getRequest();
//     return await profileAPI.getContactsUser(data);
//   }
// );

export const AuthUser = createAsyncThunk(
  "ProfileSlice/FetchAuthUser",
  async (data, thunkAPI) => {
    const { UserApi } = getRequest();
    try {
        const response = await UserApi.authUser(data);
        return response;
    } catch (error) {
        return await handlerErrorsService(error, thunkAPI)
    }
  }
);

export const getUserData = createAsyncThunk(
  "ProfileSlice/GetLoginUser",
  async (token, thunkAPI) => {
    const { UserApi } = getRequest();
    const response = await UserApi.getUserData(token);
    let exhibitor = Boolean(response.role === "ROLE_EXHIBITOR");
    response.id &&
      thunkAPI.dispatch(setAppChatState({ key: "userId", value: response.id }));

    return { ...response, exhibitor};
  }
);

export const RegisterUser = createAsyncThunk(
  "ProfileSlice/RegisterUser",
  async (data) => {
    const { UserApi } = getRequest();
    const response = await UserApi.registerUser(data);
    return response;
  }
);

export const ActivateEmail = createAsyncThunk(
  "ProfileSlice/ActivateEmail",
  async (token) => {
    const { UserApi } = getRequest();
    try {
      const response = UserApi.activateEmail(token);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const ChangeFullName = createAsyncThunk(
  "ProfileSlice/ChangeFullProfileName",
  async (data) => {
    const { UserApi } = getRequest();
    const response = await UserApi.UpdateProfileName(data);
    return response;
  }
);

export const UpdateAvatar = createAsyncThunk(
  "ProfileSlice/UpdateAvatar",
  async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const { UserApi } = getRequest();
    const type = "image";
    const isAvatar = true;
    const CompanyId = "";
    const avatar = await UserApi.uploadFiles(
      formData,
      type,
      isAvatar,
      CompanyId
    );
    const response = await UserApi.changeAvatar(avatar?.savedFileId);
    return response;
  }
);

export const getStatisticsCompany = createAsyncThunk(
  "ProfileSlice/getStatisticsCompany",
  async (data) => {
    const { UserApi } = getRequest();
    const response = await UserApi.getStatisticsCompany(data);
    return response;
  }
);

export const GetFavoritesCompany = createAsyncThunk(
  "ProfileSlice/GetFavoritesCompany",
  async (searchParam, thunkApi) => {
    const language = thunkApi.getState().app.appText.language;
    const { UserApi } = getRequest();
    const response = await UserApi.getUserFavoritesCompany(
      language,
      searchParam
    );
    return response;
  }
);

export const GetUserDocuments = createAsyncThunk(
  "ProfileSlice/GetUserDocuments",
  async () => {
    const { UserApi } = getRequest();
    const response = await UserApi.getUserDocuments();
    return response;
  }
);

export const getContactsList = createAsyncThunk(
  "ProfileSlice/GetContactList",
  async (searchParam, thunkApi) => {
    const language = thunkApi.getState().app.appText.language;
    const { UserApi } = getRequest();
    const response = await UserApi.getContactsList(language, searchParam);
    return response;
  }
);

export const AddFavoritesCompany = createAsyncThunk(
  "ProfileSlice/AddFavoritesCompany",
  async (id) => {
    const { UserApi } = getRequest();
    const response = await UserApi.addFavoritesCompany(id);
    return response;
  }
);

export const AddRequestsMeeting = createAsyncThunk(
  "ProfileSlice/AddMeeting",
  async (data) => {
    const { UserApi } = getRequest();
    const response = await UserApi.addRequestMeeting(data);
    return response;
  }
);

export const getRequestsMeetings = createAsyncThunk(
  "ProfileSlice/getRequestsMeetings",
  async (data, thunkApi) => {
    const language = thunkApi.getState().app.appText.language;
    const { UserApi } = getRequest();
    const response = await UserApi.getRequestsMeetings(language, data);
    return response;
  }
);

export const deleteMeetings = createAsyncThunk(
  "ProfileSlice/deleteMeetings",
  async ({ id, consult }, thunkAPI) => {
    let { UserApi } = getRequest();
    await UserApi.deleteMeetings(id, consult);
    return id;
  }
);

export const getApprovedMeetings = createAsyncThunk(
  "ProfileSlice/getApprovedMeetings",
  async (data, thunkAPI) => {
    const language = thunkAPI.getState().app.appText.language;
    const { UserApi } = getRequest();
    const response = await UserApi.getApprovedMeetings(language, data);
    return response;
  }
);

export const SendConsultCompany = createAsyncThunk(
  "ProfileSlice/SendConsultCompany",
  async (data, thunkApi) => {
    const language = thunkApi.getState().app.appText.language;
    const { UserApi } = getRequest();
    const response = await UserApi.sendConsultCompany(data, language);
    return response;
  }
);

export const SendRequestJoinClevr = createAsyncThunk(
  "ProfileSlice/SendRequestJoinClevr",
  async (data, thunkApi) => {
    const { UserApi } = getRequest();
    const response = await UserApi.sendRequestJoinClevr(data);
    return response;
  }
);

export const SendTechSupportQuestion = createAsyncThunk(
  "ProfileSlice/SendTechSupportQuestion",
  async (data, thunkApi) => {
    const { UserApi } = getRequest();
    const response = await UserApi.sendTechSupportQuestion(data);
    return response;
  }
);

export const ChangePassword = createAsyncThunk(
  "ProfileSlice/ChangePassword",
  async (data, thunkApi) => {
    const { UserApi } = getRequest();
    const response = await UserApi.changePassword(data);
    return response;
  }
);

export const ConfirmPassword = createAsyncThunk(
  "ProfileSlice/ConfirmPassword",
  async (token) => {
    const { UserApi } = getRequest();
    const response = await UserApi.confirmNewPassword(token);
    return response;
  }
);

export const SendRecoverPassword = createAsyncThunk(
  "ProfileSlice/RecoverPassword",
  async (data) => {
    const { UserApi } = getRequest();
    const response = await UserApi.recoverPassword(data);
    return response;
  }
);

export const DeleteUserDocumentById = createAsyncThunk(
  "ProfileSlice/DeleteUserDocumentById",
  async (fileId, thunkAPI) => {
    const { UserApi } = getRequest();
    const response = await UserApi.deleteUserDocumentsById(fileId);
    return { ...response, fileId };
  }
);

export const DeleteSelectExhibitor = createAsyncThunk(
  "ProfileSice/DeleteSelectExhibitor",
  async (companyId, thunkAPI) => {
    const { UserApi } = getRequest();
    const response = await UserApi.deleteSelectExhibitor(companyId);
    return { ...response, companyId };
  }
);

export const ApproveMeeting = createAsyncThunk(
  "ProfileSlice/ApproveMeeting",
  async (requestId, thunkAPI) => {
    const { UserApi } = getRequest();
    const response = UserApi.approveMeeting(requestId);
    return response;
  }
);

export const DiscardMeeting = createAsyncThunk(
  "ProfileSlice/DiscardMeeting",
  async (meeting, thunkAPI) => {
    const { UserApi } = getRequest();
    const response = UserApi.discardMeeting(meeting.id);
    return { ...response, meetingId: meeting.id, key: meeting.key };
  }
);
