export const footer_en = {
    about: "Description",
    services: "Services",
    partners: "Partners",
    pavilions: "Pavilions",
    follow: "Follow cleVR",
    blog: "News",
    contacts: "Contacts",
    link: "Online Exhibition",
    mail: "Any questions?",
    copyright: "Copyright ",
    rights: "All rights reserved v. 1.2.1",
    manual: 'Instructions',
    documents: "Documents",
}