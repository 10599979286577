import React, { useState } from "react";
import {CompanyPavilions} from "./CompanyPavilions";
import testImg1 from "./imgs/Снимок экрана 2022-10-19 в 09.25 1 (1).png"
import testImg2 from "./imgs/Снимок экрана 2022-10-19 в 09.25 1 (2).png"
import testImg3 from "./imgs/Снимок экрана 2022-10-19 в 09.25 1.png"




export const CompanyPavilionsContainer = () => {

  const [openPreview, setOpenPreview] = useState(false);

  const handlerOpenPreview = () => setOpenPreview(state => !state)
  

  const totalData = {
    head: {
      ru: "Павильоны",
      en: "Pavilions",
    },
    subhead: {
      ru: "Посетите павильоны и ознакомьтесь с продукцией экспонентов с Вашего персонального компьютера",
      en: "Visit the pavilions and check out the exhibitors products from your personal computer",
    },
    desc: {
      head: {
        ru: "Павильон:",
        en: "Pavilion:",
      },
      updated: {
        ru: "Обновлено:",
        en: "Updated:",
      },
      count: {
        ru: "тыс.",
        en: "thous.",
      },
      btns: {
        materials: {
          ru: "Материалы стенда",
          en: "Stand materials",
        },
        stand3D: {
          ru: "3D стенд",
          en: "3D stand",
        },
      }
    },
    pavilions: [
      {
        imgSrc: testImg1,
        name: "Выставка достижений народного хозяйства (ВДНХ)",
        updated: "12 сентября 2022",
        view: "12,3"
      },
      {
        imgSrc: testImg2,
        name: "Выставка достижений народного хозяйства (ВДНХ) 2",
        updated: "13 сентября 2022",
        view: "13,3"
      },
      {
        imgSrc: testImg3,
        name: "Выставка достижений народного хозяйства (ВДНХ) 3",
        updated: "14 сентября 2022",
        view: "14,3"
      },
    ],
  }

  return (
    <CompanyPavilions 
      totalData={totalData} 
      handlerOpenPreview={handlerOpenPreview}
      openPreview={openPreview}
      />
  )
};