import styled, { css } from "styled-components";


const WrapRightBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 16px;

    ${({mobile}) => mobile && css`
    position: absolute;
    left: 361px;
    right: -361px;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: #FFFFFF;
    transition: all 0.2s linear;
    `}

    ${({openMobileBox}) => openMobileBox && css`
    position: absolute;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: #FFFFFF;
    `}
`;

export {
    WrapRightBox,
}