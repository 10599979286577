import styled from "styled-components";

const RepresentetivesPageWrap = styled.div`
padding: 26px 16px 0;
position: relative;
height: 100%;
h3{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #363636;
}
.head_wrap{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    button{
        width: 30px;
        height: 30px;
        background: #F5F5F5;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        svg{
            width: 18px;
            height: 18px;
            path{
                fill: #363636;
            }
        }
    }
}
.representatives{
    margin-top: 25px;
    .text_wrap{
        padding-bottom: 12px;
        border-bottom: 0.5px solid #D9D9D9;
        p{
            display: flex;
            align-items: center;
            gap: 6px;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
        }
    }
    .representative_array_wrap{
        margin-top: 16px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }
}
`;
const RepresantativesCard = styled.div`
    max-width: 340px;
    min-width: 250px;
    height: 66px;
    background: #F5F5F5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
.wrap_repInfo{
    display: flex;
    gap: 12px;
    img{
        width: 42px;
        height: 42px;
        border-radius: 20px;
    }
    div{
        display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
        .rep_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
        }
        .rep_job{
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            text-transform: capitalize;
            color: #484848;
            opacity: 0.7;
            max-width: 150px;
        }
    }
}
.dots-settings{
    width: 24px;
    height: 24px;
    background: #EBEBEB;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
`

export {
    RepresentetivesPageWrap,
    RepresantativesCard
}