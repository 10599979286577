
// SEO. Title must be the same as link in header!

export const manual_en = {
    title_1: 'Instruction',
    desc: '',

    instrHead: "Instruction",
    instrSubhead: "Read the instructions for working with a virtual exhibition on the website or download the file",
    instrDoc: "Instructions CleVR.pdf",

    contactsHead: "Contacts",
    contactsSubhead: "Use additional communication channels if you have any questions",

    mail: "Mail",
    orgQuestions: "Organizational questions",
    commQuestions: "Commercial questions",
    techQuestions: "Technical questions",
    orText: "Or write your message in the form below",
    writeMes: "To write a message"
}
