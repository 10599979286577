import {ErrorMessage, Field, Form, Formik} from "formik";
import {svg_icons} from "../../../../assets/imgs/svg_icons";
import {RecoverSchema} from "../../../../validation/validation";
import {
  CloseButton,
  Desc,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../Registration/Login/style/Login_css";
import {FieldWrap} from "../settings.style";
import {RestoreEmailWrap} from "./RestoreEmail.style";

export const RestoreEmail = ({setModalEmail}) => {
  const initialValues = {
    email: "",
  };

  const {exitIcon} = svg_icons;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setModalEmail(false)}>
          {exitIcon}
        </CloseButton>
        <RestoreEmailWrap>
          <Title>Новый Email</Title>
          <Desc width={275}>
            Введите новый email и перейдите по ссылке в почте для подтверждения
          </Desc>
          <Formik
            initialValues={initialValues}
            validationSchema={RecoverSchema}
            onSubmit={(values) => {}}
          >
            {({isValid, handleSubmit, values}) => (
              <Form>
                <FieldWrap>
                  <div className="NewEmail" style={{paddingBottom: "5px"}}>
                    <label htmlFor="email">
                      <p>Новый Email*</p>
                    </label>
                  </div>
                  <div className="email">
                    <Field name="email" placeholder={"Введите email"} />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <div className="Btns_Menu_Wrap">
                  <button
                    className="BackBtn"
                    type="button"
                    onClick={() => setModalEmail(false)}
                  >
                    Отменить
                  </button>
                  <button
                    className="ChangeBtn"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isValid || !Boolean(values.email)}
                    style={
                      !isValid || !Boolean(values.email)
                        ? {cursor: "no-drop", opacity: "0.5"}
                        : null
                    }
                  >
                    Подтвердить email
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </RestoreEmailWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
