export const description_by = {
/*    h5: "ТЭХНАЛОГІІ ЗМЯНЯЮЦЬ ЗВЫКЛЫ СТАН РЭЧАЎ",
    h6: "НАША ЖЫЦЦЁ РОБIЦЦА БОЛЬШ ДЫНАМІЧНЫМ",
    text:
        "гэта шматфункцыянальная інтэрактыўная платформа, на якой асноўныя прамысловыя гэта шматфункцыянальная інтэрактыўная платформа, на якой асноўныя прамысловыя прадпрыемствы Рэспублікі Беларусь дэманструюць сваю прадукцыю і тэхналогіі вытворчасці. Прадпрыемствы маюць уласныя віртуальныя стэнды, аформленыя згодна з фірмавым стылем прадпрыемстваў. На стэндах прадстаўлены ўзоры прадукцыі, у тым ліку і ў фармаце 3D. cleVR злучае звыклы фармат галіновых афлайн выстаў з функцыянальнасцю і інтэрактыўнасцю сацыяльных сетак. Таксама віртуальная выстава ўключае ў сябе шырокі набор інструментаў для правядзення анлайн канферэнцый, семінараў і іншых формаў дзелавых зносін.",
    h3: "Пераканайцеся ў нашай эфектыўнасці на асабістым вопыце",
    button: "НАВЕДАЦЬ ВЫСТАВУ",
    clevr: "cleVR",
    title1: "Наведвальнікам",
    text1_1:
        "Наведвальнікі могуць бясплатна і без папярэдняй рэгістрацыі наведаць віртуальную выставу ",
    text1_2:
        ", выкарыстоўваючы камп'ютар або смартфон, падключаныя да сеткі інтэрнэт. Любы наведвальнік мае магчымасць паглядзець прадстаўленыя на ",
    text1_3:
        " віртуальныя стэнды прадпрыемстваў і азнаёміцца з іх інфармацыяй, а таксама прапанаваць свайго кандыдата і стаць удзельнікам выстаўкі.",
    text1_4: 'А пасля запаўнення кароткай рэгістрацыйнай формы, карыстальнік зможа ўступіць у дыялог з прадстаўніком прадпрыемства з дапамогай анлайн-чата або запытаць віртуальную сустрэчу з пэўным спецыялістам прадпрыемства, а таксама ўступіць у дыялог з іншымі наведвальнікамі.',
    title2: "Экспанентам",
    text2_1: "Прадпрыемствы экспаненты маюць магчымасць дэманстраваць сваю прадукцыю, абсталяванне і тэхналогіі ў рэжыме рэальнага часу без абмежавання па выставачнай плошчы. Кожнае прадпрыемства экспанент мае ўласны выставачны стэнд аформлены ў адпаведнасці з карпаратыўным стылем. Прадпрыемства размяшчае інфармацыю і мадэлі прадукцыі на сваім стэндзе згодна з выбраным тарыфе размяшчэння.",
    text2_short: 'Прадпрыемствы экспаненты маюць магчымасць дэманстраваць сваю прадукцыю, абсталяванне і тэхналогіі ў рэжыме рэальнага часу без абмежавання па выставачнай плошчы.',
    tabhead: {
        title1: "Паслуга",
        title2: "Тарыф",
    },
    tabBody: {
        tab1: "Віртуальны стэнд",
        tab2: "Размяшчэнне ў каталогу",
        tab3: "Размяшчэнне каталогаў і прэзентацый",
        subtab3: "у фармаце PDF",
        tab4: "Плакаты, банеры на стэндзе",
        tab5: "Бот-памочнік",
        tab6: "Анлайн-чат",
        tab7: "Кнопка пераходу",
        subTab7: "(на свой стенд)",
        tab8: "Доступ да навучальным мерапрыемствам",
        tab9: "Аналітыка",
        tab10: "Размяшчэнне інфармацыі аб перспектыўных планах закупак",
        tab11: "Agenda",
        subTab11: "(прызначэнне анлайн сустрэчай)",
        tab12: "Відэаканферэнцыі",
        tab13: "Размяшчэнне 3D мадэляў прадукцыі",
        tab14: "Персанальнае прасоўванне",
        tab15: "Размяшчэнне вакансій, пошук партнёраў для выканання работ",
        tab16: "Скразны банэр або прамоўтар",
        subTab16: "(на выбар)",
    },
    footer: {
        title: 'Кошт у месяц, BYN'
    },
    title3: "Рэкамендацыі па матэрыялах для стэндаў",
    text3_1:
        "Каб дамагчыся максімальнага выніку ад прысутнасці вашага прадпрыемства на віртуальнай выставе, вам варта надаць асаблівую ўвагу тым матэрыялам якія вы размяшчае на стэндзе. Для зручнасці мы вырашылі пакінуць невялікія рэкамендацыі:",
    text3_2: "Надасце ўвагу напісанню прэзентацыйнага тэксту",
    text3_3:
        "мы рэкамендуем скласці унікальны тэкст апісанне. Паколькі аб'ём тэкставага поля невялікі (да 2000 знакаў), то указвайце толькі самую важную інфармацыю. Звесткі пра мадэлі прадукцыі, цэнах умовах супрацоўніцтва мы рэкамендуем размяшчаць у брашурах ў фармаце PDF. Яны будуць зручна адчыняцца для чытання прама на нашай платформе, а тыя брашуры і каталогі, якія наведвальнік палічыць карыснымі, ён зможа адправіць на друк або спампаваць для захавання. ",
    text3_4: "Уважліва паставіцца да візуалізацыі стэнда",
    text3_5:
        "Якасныя графічныя матэрыялы, 3D мадэлі прадукцыі, добра падабраныя колеры элементаў стэнда, дазволяць вам заявіць пра брэнд сваёй кампаніі і стварыць рэспектабельны вобраз.",
    text3_6: "Надасце ўвагу тэксту прывітання ў анлайн-чаце",
    text3_7:
        "Нават простае «Добры дзень! Мы рады бачыць Вас!» створыць пэўны настрой і эфект прысутнасці кансультанта на стэндзе. Але ж вы можаце пакрэатывіць і звярнуцца да наведвальніка незвычайна і арыгінальна, не саромейцеся выклікаць ўсмешку. Станьце незвычайнымі і незабыўнымі. ",
    text3_8:
        "Вельмі важна своечасова адказваць на ўсе звароты наведвальнікаў",
    text3_9:
        "Не пакідаць па-за ўвагай і такія прыемныя рэчы як запыты камерцыйнага прапановы або падзякі, так і прэтэнзіі і крытыку.",
    text3_10:
        "Прысутнасць на віртуальнай выставе - эфектыўны інструмент маркетынгу. Не выпускайце гэтай выдатнай магчымасці прасоўвання свайго прадпрыемства на міжнародным узроўні!",
    text4_short: 'Дадаткова мы прапануем: розныя віды фотаздымкі, а таксама дызайнерскія паслугі',
    moreAboutServices: 'Падрабязней аб паслугах',
    folowers: "Удзельнікі",
    pavilions: "Павільёны",
    generalPavilion: "Агульны",
    search: 'Пошук',
    folowersHead: "гэта",
    folowersTitle1: "Новы эфектыўны інструмент лічбавага маркетынгу",
    folowersText1: "Магчымасць наведвання віртуальнай выставы ў любы час.",
    folowersButton1: "ПАГЛЯДЗЕЦЬ стэнд",
    folowersTitle2:
        "Віртуальная пляцоўка для дэманстрацыі прадукцыі прадпрыемстваў, іх вытворчага абсталявання і тэхналогій",
    folowersButton2: "ПАГЛЯДЗЕЦЬ стэнд",
    folowersTitle3: "лічбавая воблачная платформа для камунікацый B2B",
    folowersButton3: "ПАЧАЦЬ камунікацыі",
    folowersTitle4: "Віртуальны адукацыйны цэнтр",
    folowersButton4: "ПАЧАЦЬ Дыялог",
    accompanyingServices: "Cпадарожныя паслугі",
    photoServices: "Фотапаслугі",
    item1: "Прадметная фотаздымка прадукцыі",
    item2: "Фотаздымка вытворчых памяшканняў і працэсаў",
    item3: "Партрэтная фотаздымка супрацоўнікаў",
    price1: "40 BYN 1 шт. (ад 10 шт.)",
    price2: "90 BYN 1 шт. (ад 10 шт.)",
    price3: "20 BYN 1 шт. (ад 10 шт.)",
    designServices: "Дызайнерскія паслугі",
    item4: "Распрацоўка персанальнага выставачнага стэнда",
    item5: "Афармленне віртуальнага стэнда і дэманстрацыйных матэрыялаў у фірмавым стылі экспаненты",
    item6: "Распрацоўка (дапрацоўка) фірмавага стылю і айдентики",
    price4: "3000 BYN",
    price5: "1500 BYN",
    price6: "Дагаворная",
    order: "ЗАМОВІЦЬ",
    services: 'Паслугі',*/
    h5: "Віртуальная",
    h6: "выстава прадпрыемстваў",
    clevr: "CleVR",
    text: "гэта шматфункцыянальная інтэрактыўная платформа, на якой ключавыя прамысловыя прадпрыемствы Рэспублікі Беларусь дэманструюць сваю прадукцыю і тэхналогіі вытворчасці",
    AmountHeader1: "6",
    Header1: "павільёнаў",
    AmountHeader2: "79",
    Header2: "экспанентаў",
    AmountHeader3: "> 500",
    Header3: "стендов",
    text1: "Экспазіцыя выставы складаецца з пяці тэматычных павільёнаў",
    text2: "79 прадпрыемстваў удзельнікаў у віртуальнай выставе ў пастаяннай экспазіцыі",
    text3: "500 стэндаў з унікальнымі ўзорамі прадукцыі даступныя для азнаямлення",
    textBtn1: "Каталог павільёнаў",
    textBtn2: "Каталог экспанентаў",
    textBtn3: "Канструктар стэндаў",
}