import ReactPlayer from "react-player"
import { useDispatch, useSelector } from "react-redux"
import { filesUrl } from "../../../../API/api"
import { GalleryCard, PavilionsGalleryWrap } from "./pavilionsGallery.style"
import { useState } from "react";
import { AddGalleryFile } from "./AddGalleryFile/AddGalleryFile";
import { Fragment } from "react";
import { getInfoFile } from "../../../../utils/helpers";
import { SettingsUserBtns, UserSettings } from "../../Admin/Users/AllUsers/AllUsers.style";
import { SettingsContent } from "../../../SettingsContent/SettingsContent";
import { useEffect } from "react"
import { deleteMediaGallery, resetStateList} from "../../../../store/exhibitorSlice/exhibitorSlice"
import { EditGalleryFile } from "./EditGalleryFile/EditGalleryFile"
import { ShowGalleryItem } from "./ShowGalleryItem/ShowGalleryItem"
import { GetCompanyMedia } from "../../../../store/exhibitorSlice/exhibitorAsyncThunk"
import { svg_icons } from "../../../../assets/imgs/svg_icons"

export const PavilionsGallery = () => {
    const { 
        addIcon, 
        applicationIcon, 
        listIcon, 
        pointsIcon, 
        imgIcon, 
        videoPlayIcon, 
        cubeIcon, 
        trashIcon, 
        editIcon, 
        eyeIcon
    } = svg_icons

    const companyExhibitor = useSelector((state) => state.profile.userData?.company);
    const companyAdmin = useSelector((state) => state.pavilions.company)
    const [openAddMedia, setOpenAddMedia] = useState(false)
    const [openSettings, setOpenSettings] = useState({key: -1, open: false, type: ''})
    const [openEditFile, setOpenEditFile] = useState({key: -1, open: false})
    const [openPreview, setOpenPreview] = useState({key: -1, open: false})
    const {newGalleryItems} = useSelector((state) => state.exhibitor)
    const dispatch = useDispatch()

    let companyKey =  companyExhibitor?.companyNameKey || companyAdmin?.companyNameKey
    let companyId = companyExhibitor?.id || companyAdmin?.id
    console.log(newGalleryItems)

    useEffect(() => {
        // set array media

        dispatch(GetCompanyMedia(companyId))
        return () => {
            // reset array media
        dispatch(resetStateList({key: 'newGalleryItems'}))
        }
    }, [dispatch, companyId])

    const getMaterialTextInfo = (item) => {
        switch (item.type) {
            case 'image':
                return (<div className="text_info">
                {item.file ?
                    <>
                        <p className="media_name">{getInfoFile(item.file).name}</p>
                        <p className="media_size">{getInfoFile(item.file).size}</p>
                    </> :
                    <>
                        <p className="media_name">{item.fileName ? item.fileName : 'Белкоммунмаш.jpeg'}</p>
                        <p className="media_size">{item.fileSize ? item.fileSize : '214 МБ'}</p>
                    </>
                }
                </div>)
            case 'video':
                return (<div className="text_info">
                                <p className="media_name">{item.name ? item.name : 'Белкоммунмаш.jpeg'}</p>
                                <p className="media_size">{item.fileSize ? item.fileSize : '214 МБ'}</p>
                        </div>
                        )
            case 'model':
                return (<div className="text_info">
                                <p className="media_name">{item.name ? item.name : 'Белкоммунмаш.jpeg'}</p>
                                <p className="media_size">{item.fileSize ? item.fileSize : '214 МБ'}</p>
                        </div>
                        )
            default:
                break;
        }
    }


    const SettingsItem = [
          {
            component: (openSettings.type !== 'model' ?
              <SettingsUserBtns onClick={() => {
                setOpenEditFile({key: openSettings.key, open: true})
                setOpenSettings({key: -1, open: false})
              }}>
                {editIcon} {"Редактировать"}
              </SettingsUserBtns>
              :
              <></>
            ),
          },
          {
            component: (
              <SettingsUserBtns onClick={() => {
                setOpenPreview({key: openSettings.key, open: true})
                setOpenSettings({key: -1, open: false})
              }}>
                {eyeIcon} {"Предпросмотр"}
              </SettingsUserBtns>
            ),
          },
          {
            component: (
              <SettingsUserBtns onClick={() => {
                dispatch(deleteMediaGallery({key: openSettings.key, type: openSettings.type}))
                setOpenSettings({key:-1})
                }}
              style={{ background: "#FFF2F2", color: "#FE564B" }}>
                {trashIcon} {"Удалить"}
              </SettingsUserBtns>
            ),
          },
        ]


    return (
        <PavilionsGalleryWrap>
        <div className="head_wrap">
            <div>
            <h3>{'Галерея'}</h3>
            <button className="add_icon" onClick={() => setOpenAddMedia(true)}>{addIcon}</button>
            </div>
            <div className="other_btns">
                <button>{applicationIcon}</button>
                <button>{listIcon}</button>
            </div>
        </div>
        <div className="gallery_wrap">
                <div className="map_container">
            {newGalleryItems.map((item,index) => {
                if(item.remove) return ''
                return (
                    <Fragment key={index}>
                    <GalleryCard>
                        <div className="media_wrap">
                        {item.type === 'image' ?
                            <img className="media_image" src={item.file ? URL.createObjectURL(item.file) : (filesUrl + item?.src)} alt={'gallery_wrap'} />
                            : 
                            item.type === "video" ? 
                            <ReactPlayer url={item.src} className="media_video" controls={true}></ReactPlayer>
                            : 
                            <img className="media_image" src={`${filesUrl}/files/3dModels/${companyKey}/${item.link}/0_0.jpg`} alt={'gallery_wrap'} />
                        }
                        <div className="media_type">
                            {
                                item.type === 'image' ? 
                                <>{imgIcon}</>
                                :
                                item.type === 'video' ? 
                                <>{videoPlayIcon}</>
                                :
                                <>{cubeIcon}</>
                            }
                        </div>
                        </div>
                        <div className="media_info">
                        {getMaterialTextInfo(item)}
                        <UserSettings style={{marginLeft: '0'}}>
                                <button className="dots_settings" onClick={() => setOpenSettings({key: index, open: true, type: item.type})}>{pointsIcon}</button>
                                {openSettings.open && openSettings.key === index ? <SettingsContent exhibitor={true} setOpenSettings={setOpenSettings} SettingsItems={SettingsItem}/> : null}
                        </UserSettings>
                        </div>
                        {openEditFile.key === index && openEditFile.open ? <EditGalleryFile galleryItem = {item} itemIndex = {item.id ? null : index} setOpenEditFile={setOpenEditFile} /> : null}
                        {openPreview.key === index && openPreview.open ? <ShowGalleryItem galleryItem={item} setOpenPreview={setOpenPreview} /> : null}
                    </GalleryCard>
                    </Fragment>
                )
            })}
            <div className="add_exponat_wrap">
            <button onClick={() => setOpenAddMedia(true)}>{addIcon}{'Добавить экспонат'}</button>
            </div>
            </div>
        </div>
        {openAddMedia && <AddGalleryFile setOpenAddMedia={setOpenAddMedia} />}
        </PavilionsGalleryWrap>
    )
}