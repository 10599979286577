import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { WrapContextMenuBtn, ListContextMenu } from "./contextMenuBtn.style";
import ModalBackground from './../ModalBackground/ModalBackground';
import { useNavigate } from 'react-router-dom';
import icons_svg from "../AppChat/imgs/icons_svg";
import { setAppChatState, setOpenDialogState } from "../../store/appChatSlice/appChatSlice";



const ContextMenuBtn = ({data: {type, dialog, user}, onClose}) => {

    const contextMenu = useSelector(state => state.appChat.contextMenu);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { messageIcon, block, clearHistory, trash, search, videoCall, removeUserIcon, plusInCircle } = icons_svg;

    const hanlderActions = (key, content) => (e) => {
        e.stopPropagation();
        onClose && onClose(e);

        switch (key) {
            case "closeMenu":
                //  dispatch(resetAppChatState("contextMenu"));
                break;
            case "blockUserChat":
                // dispatch(setAppChatState({key: "modal", value: {type: "blockUser", content}}));
                // break;
            case "unblockUserChat":
                // dispatch(setAppChatState({key: "modal", value: {type: "unblockUser", content}}));
                // break;
            case "clearHistoryChat":
                // dispatch(setAppChatState({key: "modal", value: {type: "clearHistory"}}))
                // break;
            case "removeChat":
                // dispatch(setAppChatState({key: "modal", value: {type: "removeChat"}}))
                // break;
            case "addToContact":
                // dispatch(setAppChatState({ key: "modal", value: {type: "addToContact", content} }))
                // break;
            case "removeContact":
                // dispatch(setAppChatState({ key: "modal", value: {type: "removeContact", content} }))
                dispatch(setAppChatState({ key: "toast", value: true }))
                break;
        
            default:
                console.log("call")
                dispatch(setAppChatState({ key: "toast", value: true }))
                break;
        }   
    }

    const buildContextMenu = (key) => {
        let listMenu;
        switch (key || type) {
            case "dialog":
                listMenu = [
                    {icon: messageIcon, text: 'Написать сообщение', onClick: hanlderActions("sendMesChat")},
                    {icon: block, text: 'Заблокировать ', onClick: hanlderActions("blockUserChat")},
                    {icon: clearHistory, text: 'Очистить историю', onClick: hanlderActions("clearHistoryChat")},
                    {icon: trash, text: 'Удалить чат', onClick: hanlderActions("removeChat"), class: " remove-chat"},
                ];
                return <ListContextMenu>
                    {listMenu.map((item, i) => (
                        <div key={i} className={`list-item${item.class || ""}`} onClick={item.onClick}>
                            {item.icon} {item.text}
                        </div>
                    ))}
                </ListContextMenu>
            case "toolsUser":
                listMenu = [
                    // {icon: search, text: 'Поиск', onClick: hanlderActions()},
                    // {icon: videoCall, text: 'Видео звонок', onClick: hanlderActions()},
                    // {icon: removeUserIcon, text: 'Удалить из контактов', onClick: hanlderActions("removeContact")},
                    {icon: block, text: 'Заблокировать', onClick: hanlderActions("blockUserChat"), },
                    // {icon: clearHistory, text: 'Очистить историю', onClick: hanlderActions("clearHistoryChat"),},
                    {icon: trash, text: 'Удалить чат', onClick: hanlderActions("removeChat"), class: " remove-chat"},
                ];
                return <ListContextMenu>
                    {listMenu.map((item, i) => (
                        <div key={i} className={`list-item${item.class || ""}`} onClick={item.onClick}>
                            {item.icon} {item.text}
                        </div>
                    ))}
                </ListContextMenu>
            case "userCompanyFromStandInfo":
                console.log(user)
                listMenu = [
                    {icon: messageIcon, text: 'Написать сообщение', onClick: hanlderActions("sendMesFromStand")},
                    user.contact ? 
                    {icon: removeUserIcon, text: 'Удалить из контактов', onClick: hanlderActions("removeContact", user)}
                    :
                    {icon: plusInCircle, text: 'Добавить в контакты', onClick: hanlderActions("addToContact", user)},
                    user.block ===  0 || user.block ===  2 ?
                    {icon: block, text: 'Заблокировать', onClick: hanlderActions("blockUserChat", user),}
                    :
                    {icon: block, text: 'Разблокировать', onClick: hanlderActions("unblockUserChat", user), },
                ];
                return <ListContextMenu>
                    {listMenu.map((item, i) => (
                        <div key={i} className={`list-item${item.class || ""}`} onClick={item.onClick}>
                            {item.icon} {item.text}
                        </div>
                    ))}
                </ListContextMenu>
        
            default:
                return null;
        }
    }



    if(!(contextMenu || type)) return null;

    return <>
            <ModalBackground onClose={hanlderActions("closeMenu")}/>
            <WrapContextMenuBtn className="wrap-context-menu-btn">
                {buildContextMenu(contextMenu)}
            </WrapContextMenuBtn>
    </>

}

export default ContextMenuBtn;