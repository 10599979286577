import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { svg_icons } from "../../../../assets/imgs/svg_icons"
import { setAdminState } from "../../../../store/adminSlice/adminSlice"
import { HeadComponentWrap } from "../../../Account/ExhibitorPage/HeadComponent/headComponent.style"
import { resetStateList, setInitValues } from "../../../../store/exhibitorSlice/exhibitorSlice"

export const HeadEditCompanyPage = ({localeState, setLocaleState, 
    setDisabled, setValidForm,
    component, setTitle, handlerActions
}) => {

    const {uncoverLeftIcon } = svg_icons
    const {companyDepartments, newGalleryItems, galleryItems} = useSelector((state) => state.exhibitor)
    const {company} = useSelector((state) => state.pavilions)
    const dispatch = useDispatch()

    useEffect(() => {
        companyDepartments.forEach(department => {
            department.companyDepartmentContacts.some((contact) => contact.update || contact.remove || !contact.id) ?
            setLocaleState((state) => (
                {...state, contacts: {...state.contacts, validate: true}}
            )) 
            :
            setLocaleState((state) => (
                {...state, contacts: {...state.contacts, validate: false}}
            ))
        });
      }, [companyDepartments, setLocaleState])

      useEffect(() => {
        dispatch(setInitValues({key: 'text', value: company?.text}))
      }, [dispatch, company?.text])

      useEffect(() => {
        if (galleryItems) {
          setLocaleState((state) => {
            return {
              ...state,
              localeGalleryItems: newGalleryItems.reduce(
                (prev, current, i, array) => {
                  let filter = prev.removeItems.filter(
                    (elem) => elem.remove && elem.id
                  );
                  prev.removeItems = filter;
                  (!galleryItems.find(
                    (elem) => {
                        return elem.id === current.id
                    }
                  ) ||
                    current.update) &&
                    !current.remove &&
                    prev.addItems.push(current);
                  Boolean(prev.removeItems.length) || Boolean(prev.addItems.length)
                    ? (prev.validate = true)
                    : (prev.validate = false);
                  return {
                    removeItems: prev.removeItems,
                    addItems: prev.addItems,
                    validate: prev.validate,
                  };
                },
                { removeItems: newGalleryItems, addItems: [], validate: false }
              ),
            };
          });
        }
        return () => {
            dispatch(resetStateList('newGalleryItems'))
        }
      }, [newGalleryItems, galleryItems, dispatch, setLocaleState]);

    return (
        <HeadComponentWrap>
              <div className="head_info_wrap">
                  <div className="back_to_headPage" style={{ marginRight: '14px' }}>
                    <button onClick={() => dispatch(setAdminState({key: 'editCompany', value: {component: '', open: false}}))}>
                      {uncoverLeftIcon}
                    </button>
                  </div>
                <h2>{setTitle(component)}</h2>
              </div>
              <div className="head_btns_wrap">
                  <button
                    className="first_head_btn"
                  >
                    {"Отменить действие"}
                  </button>
                  {/*<Link to={`/company/${company.companyNameKey}`}>
                    <button style={{}} >{"Предпросмотр"}</button>
                  </Link>*/}
                <button
                  type={"submit"}
                  disabled={setDisabled(component)}
                  style={setValidForm(component)}
                  className="second_head_btn"
                  onClick={handlerActions(component)}
                >
                    {"Сохранить"}
                </button>
              </div>
            </HeadComponentWrap>
    )
}