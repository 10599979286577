import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { setAuthState } from "../../store/authSlice/authSlice"
import { socialIcons } from "../Footer/socialIcons.svg"
import { AdditionalDesc, AdditionalFuncBtns, AdditionalFuncContainer, AdditionalFuncInfo, AdditionalFuncWrap, AdditionalLogo } from "./AdditionalFunc.style"

export const AdditionalFunc = () => {

    const {logo} = socialIcons
    const {mainPage: {floatLogin: localText}} = useSelector((state) => state.app.appText);
    const dispatch = useDispatch()

    return (
        <AdditionalFuncWrap>
            <AdditionalFuncContainer>
                <AdditionalFuncInfo>
                <AdditionalLogo>{logo}</AdditionalLogo>
                <AdditionalDesc>
                    <h3>{localText.head}</h3>
                    <p>{localText.subhead}</p>
                </AdditionalDesc>
                </AdditionalFuncInfo>
                <AdditionalFuncBtns>
                    <button 
                    className="Additional_Reg" 
                    onClick={() => 
                    dispatch(setAuthState({key: "openRegistration", value: true}))}>
                   {localText.registr}
                    </button>
                    <button 
                    className="Additional_Login" 
                    onClick={() => dispatch(setAuthState({key: "open", value: true}))}>
                    {localText.login}
                    </button>
                    <button 
                    className="Additional_Close" 
                    onClick={() => dispatch(setAuthState({key: "openAdditional", value: false}))}>
                    {localText.close}
                    </button>
                </AdditionalFuncBtns>
            </AdditionalFuncContainer>
        </AdditionalFuncWrap>
    )
}