import styled from "styled-components";

const AppMessageWrap = styled.div`
    position: absolute;
    width: 340px;
    height: 56px;
    background: #FFFFFF;
    box-shadow: 0px 6px 48px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: 2;
    bottom: 50px;
    left: 16px;
    .info_wrap{
        display: flex;
        align-items: center;
        height: inherit;
        margin-left: 16px;
        gap: 8px;
        p{
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
        }
    }
`;

export {
    AppMessageWrap
}