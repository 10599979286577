import {useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useParams} from "react-router-dom";
import {svg_icons} from "../../assets/imgs/svg_icons";
import {ActivateEmail} from "../../store/profileSlice/profileAsyncThunk";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../Registration/Login/style/Login_css";
import {ActivateWrap} from "./activate.style";

export const Activate = ({setActivateAcc}) => {
  const {exitIcon} = svg_icons;
  const language = useSelector((state) => state.app.appText);
  const {activateEmail} = useSelector((state) => state.profile);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [Page, setPage] = useState("Reg");
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes("/activate")) {
      setPage("ActivateEmail");
      const tokenfromUrl = params.token;
      dispatch(ActivateEmail(tokenfromUrl));
      if (activateEmail !== "") {
        setTitle("Успешно!");
        setMessage(language.activate.positiveResponse);
      } else {
        setTitle("Ошибка");
        setMessage("Ошибка активации");
      }
    } else {
      setMessage(language.authorization.registerSuccess);
    }
  }, [
    dispatch,
    location.pathname,
    activateEmail,
    language.activate.positiveResponse,
    language.authorization.registerSuccess,
    params.token,
  ]);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372} style={{height: "180px"}}>
        {Page === "Reg" ? (
          <CloseButton onClick={() => setActivateAcc(false)}>
            {exitIcon}
          </CloseButton>
        ) : null}
        <ActivateWrap>
          <Title>{title}</Title>
          <p className="message">{message}</p>
          {Page === "Reg" ? (
            <button
              onClick={() => setActivateAcc(false)}
              className="Activate_Acc"
            >
              Понятно
            </button>
          ) : (
            <Link to={"/"} state={"login"}>
              <button className="Activate_Acc">
                {message === language.activate.positiveResponse
                  ? "Войти"
                  : "Понятно"}
              </button>
            </Link>
          )}
        </ActivateWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
