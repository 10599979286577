import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { svg_icons } from "../../../assets/imgs/svg_icons"
import { setAdminState } from "../../../store/adminSlice/adminSlice"
import { ModalMessageWrap } from "../../ModalMessage/ModalMessage.style"
import { CloseButton, ModalContainer, ModalOpen, Title } from "../../Registration/Login/style/Login_css"

export const  ModalMessage = () => {
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const {message, errorMessage, openModal} = useSelector((state) => state.admin) 
    const {exitIcon} = svg_icons
    const dispatch = useDispatch()

    useEffect(() => {
        message !== '' ? setTitle('Успешно создано!') : setTitle('Ошибка')
        message !== '' ? setText(message) : setTitle(errorMessage) 
    }, [errorMessage, message])

    if(!openModal) return null

    return (
        <ModalContainer>
            <ModalOpen maxWidth = {372}>
                <CloseButton onClick={() => dispatch(setAdminState({key: 'openModal', value: false}))}>{exitIcon}</CloseButton>
                <ModalMessageWrap>
                    <Title>{title}</Title>
                    <p className="message">{text}</p>
                    <button 
                    onClick={() => dispatch(setAdminState({key: 'openModal', value: false}))}>Понятно, спасибо!</button>
                </ModalMessageWrap>
            </ModalOpen>
        </ModalContainer>
    )
}