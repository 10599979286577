import React, { useState } from "react";
import {
  SearchBlockContent,
  SearchBlockForm,
  SearchBlockInput,
  SearchBlockBtn,
} from "./style/searchBlock_css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { icons_svg } from "../../Exhibition/Company/imgs/icons.svg";
import { setSearchSettings } from "../../../store/PavilionsSlice/pavillionsSlices";

export const SearchBlock = () => {
    const {searchIcon} = icons_svg
    const dispatch = useDispatch()

  const {search: localText} = useSelector((state) => state.app.appText);
  const navigate = useNavigate();
  const [searchCompany, setSearchCompany] = useState('');

  const SearchExp = () => {
    if (searchCompany !== ''){
        dispatch(setSearchSettings({key: 'searchCompany', value: searchCompany}))
      return navigate("/exhibition");
    } else {
      return null;
    }
  };

  return (
    <SearchBlockContent>
      <p>{localText.text}</p>
      <SearchBlockForm>
        {searchIcon}
        <SearchBlockInput
          onChange={(e) => setSearchCompany(e.currentTarget.value)}
          placeholder={localText.input_text}
        />
       {/* <div></div>
        <SearchBlockSelect
          onChange={(e) => setSearchProduct(e.currentTarget.value)}
        >
          <option>{localText.select_text}</option>
          {products.map((product, index) => {
            return <option key={index}>{product.name}</option>;
          })}
        </SearchBlockSelect>*/}
        <SearchBlockBtn onClick={SearchExp}>
          {localText.searchbtn}
        </SearchBlockBtn>
      </SearchBlockForm>
    </SearchBlockContent>
  );
};
