import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialogs from "./Dialogs";
import { setAppChatState, setOpenDialogState } from "../../../../store/appChatSlice/appChatSlice";
import { setAppState } from "../../../../store/appSlice/appSlice";
import { openDialogById } from "../../../../store/appChatSlice/appChatExtraReducers";
import { svg_icons } from "../../../../assets/imgs/svg_icons";




const DialogsContainer = ({mobileVersion}) => {

    const {dialogs, openDialog} = useSelector(state => state.appChat);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { messageIcon, blockIcon, trashIcon } = svg_icons;

    const handlerActions = (key, data) => (e) => {
        switch (key) {
            case "contextMenu":
                e.stopPropagation();
                dispatch(setAppState({contextMenu: {
                    currentTarget: e.currentTarget,
                    listMenu: [
                      { onClick: handlerActions("openDialog", data), icon: messageIcon, text: "writeMes" },

                      data.user?.block ===  0 || data.user?.block ===  2 ?
                      {
                        onClick: () => dispatch(setAppChatState({key: "modal", value: {type: "blockUser", content: data.user}})), 
                        icon: blockIcon, text: "block", 
                    }
                      :
                      {
                        onClick: () => dispatch(setAppChatState({key: "modal", value: {type: "unblockUser", content: data.user}})), 
                        icon: blockIcon, text: "unblock",  
                    },
                    //   { onClick: () => {}, icon: clearHistory, text: "Очистить историю", },
                      {
                         onClick: () => dispatch(setAppChatState({key: "modal", value: {type: "removeDialog", content: data}})), 
                         icon: trashIcon, text: "removeChat", class: " error",
                    },
                    ]
                  }}))
                break;
            case "openDialog":
                if(openDialog.dialogId === data.dialogId) return ''; 
                dispatch(setOpenDialogState({...data, messages: null}))
                dispatch(openDialogById({id: data.dialogId, navigate}));
                dispatch(setAppChatState({key: "dialogs", value: dialogs?.map(dialog => 
                    dialog.dialogId === data.dialogId ? {...dialog, unread: 0} : dialog)})
                );
                !mobileVersion && navigate(`${data.dialogId}`)
                        
                break;
            default:
                break;
        }

    }


    return <Dialogs 
        handlerActions={handlerActions} 
        dialogs={dialogs} 
        />
}

export default DialogsContainer;