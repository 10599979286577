
// SEO. Title must be the same as link in header!

export const contactus_ru = {
    title: "Контакты",
    h2: "Появились вопросы? Свяжитесь с нами!",
    commercialQuestions: 'По организационным и коммерческим вопросам: ',
    technicalQuestions: 'По техническим вопросам: ',
    sendMessage: 'Или отправьте ваше сообщение заполнив форму ниже: ',
    name: "Ваше имя",
    email: "Ваш e-mail",
    subject: "Тема сообщения",
    message: "Текст сообщения",
    alertTerms: "Нужно принять условия использования",
    alertEmailValid: "Неверный формат Email",
    sent: "Отправить",
    ph: "Контакт",
    messageOk: "Сообщение отправлено",
    messageFail: "Ошибка, Попробуйте еще раз",
    noEmail: "Email не введен",
    noName: "Введите Имя",
    noSubject: "Тема не введена",
    noMessage: "Введите текст сообщения",
    terms: {
        text: "Этот сайт защищен reCAPTCHA и Google",
        pp: "Политика конфиденциальност",
        and: "и",
        ts: "Условия использования",
        a: "",
    },
}
