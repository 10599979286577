import {useState} from "react";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {icons_svg} from "../../../../Exhibition/Company/imgs/icons.svg";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {AddGalleryFileWrap} from "./AddGalleryFile.style";
import {NewImage} from "./NewImage";
import {NewVideo} from "./NewVideo";

export const AddGalleryFile = ({setOpenAddMedia}) => {
  const [addFile, setAddFile] = useState("");
  const {exitIcon} = svg_icons;
  const {arrowRight} = icons_svg;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setOpenAddMedia(false)}>
          {exitIcon}
        </CloseButton>
        <AddGalleryFileWrap>
          <Title>{"Новый файл"}</Title>
          {Boolean(addFile) ? (
            addFile === "image" ? (
              <NewImage
                setAddFile={setAddFile}
                setOpenAddMedia={setOpenAddMedia}
              />
            ) : (
              <NewVideo
                setAddFile={setAddFile}
                setOpenAddMedia={setOpenAddMedia}
              />
            )
          ) : (
            <div className="wrap_btns">
              <button
                className="add_gallery_file"
                onClick={() => setAddFile("image")}
              >
                {"Фотография"}
              </button>
              <button
                className="add_gallery_file"
                onClick={() => setAddFile("video")}
              >
                {"Ссылка на видео"}
              </button>
              <div className="wrap_3d">
                <p className="head_3d">{"3D Экспонат"}</p>
                <p className="text_3D">
                  {"Направьте запрос и материалы на наш email:"}
                </p>
                <p className="email_clevr">{"info@clevr.by"}</p>
                <a href="mailto:info@clevr.by" target="_blank" rel="noreferrer">
                  <button>
                    {arrowRight}
                    {"Отправить материалы на почту"}
                  </button>
                </a>
              </div>
            </div>
          )}
        </AddGalleryFileWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
