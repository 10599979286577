export const banner_by = {
    title: "CleVR – віртуальная выстава прадпрыемстваў з шырокім наборам інструментаў онлайн канферэнцый, семінараў і іншых форм дзелавых зносін.",
    h1: "Віртуальная выстава прадпрыемстваў",
    subhead: "Наведай стэнды прадпрыемстваў <br /> і пазнаеміцца з прадукцыяй онлайн!",
    visitExhibition : "Наведаць выставу", 


    h2: "АДЗІНАЯ ВІРТУАЛЬНАЯ ВЫСТАВА",
    h3: "ПРАДПРЫЕМСТВАЎ РЭСПУБЛІКІ БЕЛАРУСЬ",
    h6: "ВІРТУАЛЬНАЯ ПРАСТОРА ДЛЯ РЭАЛЬНАГА БІЗНЕСУ",
    toTheExhibition: 'На выставу',
}