import {filesUrl} from "../../../API/api";
import {svg_icons} from "../../../assets/imgs/svg_icons";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
} from "../../Registration/Login/style/Login_css";
import {
  AddFavoritesBtns,
  AddFavoritesLogo,
  AddFavoritesWrap,
  AddText,
  CompanyName,
} from "./AddFavoritesModal.style";

export const AddFavoritesModal = ({
  handlerDataAdd,
  setOpenModal,
  company,
  sendStatus,
}) => {
  const {exitIcon} = svg_icons;
  return sendStatus ? null : (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setOpenModal(false)}>
          {exitIcon}
        </CloseButton>
        <AddFavoritesWrap>
          <AddFavoritesLogo>
            <img alt="LogoCompany" src={filesUrl + company.logo}></img>
          </AddFavoritesLogo>
          <CompanyName>
            <p>{company.name}</p>
          </CompanyName>
          <AddText>
            Желаете сохранить данного экспонента в списке “Избранных”?
          </AddText>
          <AddFavoritesBtns>
            <button
              onClick={handlerDataAdd("AddCompany", company.id)}
              className="Add_Company_Favorites"
            >
              Да, сохранить в “Избранные”
            </button>
            <button
              onClick={() => setOpenModal(false)}
              className="Not_Add_Company"
            >
              Отменить
            </button>
          </AddFavoritesBtns>
        </AddFavoritesWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
