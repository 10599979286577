import { Expanents } from "./Expanents/Expanents"
import { PavilionsContainer } from "./PavilionsContainer"



export const Exhibition = () => {

    return (
        <>
            <PavilionsContainer />
            <Expanents />
        </>
    )
}