import { handlerLocalStorage } from "../../services/handlerLocalStorage.service";
import { initialState } from "./authSlice";

const { remove, get } = handlerLocalStorage();

const reducers = {
  setAuthState(state, action) {
    state[action.payload.key] = action.payload.value;
  },

  setTokenApp(state, action) {
    if (!action.payload) {
      remove("token");
      state.token = null;
    } else {
      state.token = action.payload;
    }
  },

  resetState: () => ({ ...initialState, token: get("token") }),
};

export default reducers;
