import { useEffect, useState } from "react";
import {
  ContactsScroll,
  ContactDesc,
  ContactName,
  WrapContacts,
  ContactsBlockInfo,
  WrapContactsFilter,
} from "./style/contacts_css";
import { icons_svg } from "../../Exhibition/Company/imgs/icons.svg";
import { useDispatch, useSelector } from "react-redux";
import { getContactsList } from "../../../store/profileSlice/profileAsyncThunk";
import { filesUrl } from "../../../API/api";
import { svg_icons } from './../../../assets/imgs/svg_icons';
import { setAppState } from "../../../store/appSlice/appSlice";
import { useOpenDialogNotFromChat } from "../../../hooks/useOpenDialogNotFromChat";
import { UserSettings } from "../Admin/Users/AllUsers/AllUsers.style";
import { setAppChatState } from "../../../store/appChatSlice/appChatSlice";
import { AddRepresentatives, DeleteRepresentatives } from "../../../store/exhibitorSlice/exhibitorAsyncThunk";


export const Contacts = ({ activePanel }) => {
  const [searchParam, setSearchParam] = useState('')
  const [StatusContacts, setStatusContacts] = useState('offline')

  const {table: {data: contacts}, userData} = useSelector((state) => state.profile)
  const { blockIcon, messageIcon, trashIcon, userIcon } = svg_icons;
  const {profile: {contactsText}} = useSelector((state) => state.app.appText)
  const {search, points} = icons_svg;
  const dispatch = useDispatch();
  let contactsOnline = contacts?.filter(contact => contact.online);
  const openDialogNotFromChat = useOpenDialogNotFromChat();

  useEffect(() => {
    dispatch(getContactsList(searchParam))
  }, [dispatch, searchParam])

  const makeRepresentative = (data) => {
      switch (data.role) {
        case 'ROLE_USER':
            return dispatch(AddRepresentatives({...data, job: data.position ? data.position : '', visibility: true, description: data.note ? data.note : ''}))
        case 'ROLE_REPRESENTATIVE':
            return dispatch(DeleteRepresentatives(data))
        default:
            break;
      }
  }


  const handlerActions = (key, data) => (e) => {
    switch (key) {
      case "contextMenu":
        dispatch(
          setAppState({
            contextMenu: {
              currentTarget: e.currentTarget,
              listMenu: [
                (data.role === "ROLE_USER" ||
                (data.role === "ROLE_REPRESENTATIVE" && data.companyId === userData?.companyId)) ? {
                  onClick: () => makeRepresentative(data),
                  icon: userIcon,
                  text: data.role === "ROLE_USER" ? "Сделать представителем" : "Удалить из представителей",
                } : "",
                {
                  onClick: () => {
                    openDialogNotFromChat({dialogUser: data})
                  },
                  icon: messageIcon,
                  text: "Написать сообщение",
                },
                // {
                //   onClick: () => {
                //     console.log(data);
                //   },
                //   icon: "",
                //   text: "Заявка на встречу",
                // },
                {
                  onClick: () =>
                    dispatch(
                      setAppChatState({
                        key: "modal",
                        value: {
                          type:
                            data.block === 0 || data.block === 2
                              ? "blockUserFromContacts"
                              : "unblockUserFromContacts",
                          content: data,
                        },
                      })
                    ),
                  icon:
                    data.block === 0 || data.block === 2
                      ? blockIcon
                      : blockIcon,
                  text:
                    data.block === 0 || data.block === 2
                      ? "Заблокировать "
                      : "Разблокировать",
                },
                {
                  onClick: () => {
                    dispatch(
                      setAppChatState({
                        key: "modal",
                        value: {
                          type: "removeContactFromContacts",
                          content: data,
                        },
                      })
                    );
                  },
                  icon: trashIcon,
                  text: "Убрать из контактов",
                  class: " error",
                },
              ],
            },
          })
        );
        break;
    
        default:
            break;
    }
  }

  const onlyPage = activePanel === "Onlycontacts";

  const handlerStatus = (status) => {
    setStatusContacts(status)
  }

  if(!contacts) return null;

  return (
    <WrapContacts onlyPage={onlyPage}>
      <ContactsBlockInfo>
        <h2>{onlyPage ? `${contactsText.name} (${contacts.length})` : contactsText.name}</h2>
        {!onlyPage && <p>
          <strong>{contactsText.all}</strong> {contacts.length}
        </p>} 
      </ContactsBlockInfo>

      <WrapContactsFilter onlyPage={onlyPage}>

        <div className="filter-search">
          {search}
          <input placeholder={contactsText.searchPl} onChange={(e) => setSearchParam(e.currentTarget.value)}></input>
        </div>
        <div className="filter-btns">
          <button
            className={`filter-btns__btn ${StatusContacts === 'offline' ? "active" : ""}`}
            onClick={() => handlerStatus('offline')}
          >
            {contactsText.offline} ({contacts.length})
          </button>
          <button
            className={`filter-btns__btn ${StatusContacts === 'online' ? "active" : ""}`}
            onClick={() => handlerStatus('online')}
          >
            {contactsText.online} ({contactsOnline.length})
          </button>
        </div>

      </WrapContactsFilter>

      <ContactsScroll onlyPage={onlyPage}>
        <div className="contacts">
          {
            ((contacts.length && StatusContacts === 'offline') || (contactsOnline.length && StatusContacts === 'online')) ? ((StatusContacts === 'offline' ?  contacts : contactsOnline).map(el => {
              return (
                <div className="wrap-contact" key={el.id}>
                  <img
                    src={filesUrl + el.avatar}
                    alt="testPhoto"
                    className="contact__avatar"
                  ></img>
                  <div className="contact-info">
                    <div>
                      <ContactName>{el.firstName}{' '}{el.lastName}</ContactName>
                      <ContactDesc>{el.position}</ContactDesc>
                    </div>
                    <UserSettings style={{marginLeft: 'auto'}}>
                    <button className="dots_settings" onClick={handlerActions("contextMenu", el)}>{points}</button>
                </UserSettings>
                  </div>
                </div>
              );
            }))
            : StatusContacts === 'offline' ? <p className="empty_arr">{contactsText.noContacts}</p> : <p className="empty_arr">{contactsText.noOnline}</p>
          }
        </div>
      </ContactsScroll>
    </WrapContacts>
  );
};
