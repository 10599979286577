export const HeadIcons = {
    mainLogo: <svg width="368" height="162" viewBox="0 0 368 162" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3019_4669)">
    <path d="M285.818 27.6457C286.628 28.7577 287.608 29.5277 288.46 30.4259C308.231 50.272 328.045 70.1607 347.813 90.0068C349.904 92.1029 351.563 94.4125 351.649 97.5347C351.736 101.598 349.392 105.362 345.726 106.902C341.72 108.527 338.014 107.757 334.903 104.806C331.921 101.94 329.109 98.9461 326.211 95.9948C308.87 78.5442 291.571 61.1359 274.229 43.685C273.547 43.0006 272.865 42.402 272.056 41.6319C271.97 43.0437 271.843 44.3694 271.843 45.6526C271.843 62.7613 271.843 79.8699 271.843 96.9788C271.843 99.2458 271.544 101.47 270.266 103.437C267.241 108.142 260.168 109.04 255.992 105.148C251.987 101.384 248.109 97.4066 244.232 93.5143C221.607 70.8451 199.024 48.1762 176.398 25.5071C174.225 23.3257 172.734 20.845 172.691 17.7226C172.648 13.4882 174.864 10.2803 178.784 8.69779C182.363 7.24355 186.326 8.09899 189.181 10.9219C209.889 31.7518 230.64 52.5816 251.348 73.4113C251.603 73.6683 251.859 74.0103 252.242 74.4808C252.455 73.3259 252.583 72.4277 252.583 71.5294C252.583 57.0298 252.541 42.5302 252.541 28.0306C252.541 24.8227 252.413 21.6149 252.413 18.407C252.413 12.3334 256.333 8.35562 262.383 8.35562C288.673 8.35562 314.92 8.35562 341.212 8.35562C344.363 8.35562 347.261 9.08274 349.263 11.6918C353.866 17.6371 350.712 25.5499 344.406 27.0897C342.874 27.4746 341.295 27.4318 339.762 27.4318C323.698 27.4318 307.592 27.4318 291.528 27.4318C289.611 27.3891 287.779 27.4318 285.818 27.6457Z" fill="#64FA9C"/>
    </g>
    <g filter="url(#filter1_d_3019_4669)">
    <path d="M202.434 88.0416C200.261 84.2778 197.534 81.1126 193.529 79.2734C187.734 76.6217 181.769 75.8943 175.846 78.7602C169.241 81.9681 164.767 87.0579 163.447 94.5428C161.444 105.664 169.838 116.998 180.959 118.324C182.834 118.538 184.708 118.495 186.541 119.009C191.398 120.463 194.253 124.697 193.571 129.445C192.847 134.449 188.884 137.785 183.558 137.742C175.803 137.7 168.645 135.518 162.211 131.198C153.306 125.21 147.383 117.084 145.167 106.476C140.523 84.4059 154.243 63.3623 176.187 58.8284C193.742 55.193 211.595 64.389 219.478 80.3001C221.906 85.2187 223.142 90.437 223.483 95.9115C223.611 98.2642 223.696 100.531 222.588 102.67C220.841 106.091 218.029 107.931 214.237 108.016C203.968 108.23 193.699 108.187 183.473 108.016C178.189 107.931 174.099 103.782 174.014 98.5635C173.928 93.0887 177.934 88.8117 183.26 88.5548C187.521 88.3412 191.782 88.3839 196.043 88.5548C198.13 88.5978 200.218 88.2982 202.434 88.0416Z" fill="white"/>
    </g>
    <g filter="url(#filter2_d_3019_4669)">
    <path d="M16.3486 98.5168C16.3486 78.4569 30.7931 61.5191 49.9245 58.6536C63.9858 56.5578 75.8738 60.7493 85.7162 70.9717C87.5059 72.8109 88.7843 74.8639 88.9973 77.5159C89.3381 81.3652 87.8469 84.4022 84.6937 86.5834C81.5831 88.6792 78.2172 88.7219 74.8935 87.0112C73.2744 86.1557 71.9535 84.9154 70.7179 83.5894C66.2866 78.8847 60.79 76.6604 54.3561 77.3448C44.982 78.3712 37.8236 85.172 36.0341 94.4961C33.861 105.831 41.9567 117.165 53.3762 118.791C57.6794 119.39 61.8551 119.133 65.7754 117.08C67.7778 116.01 69.4824 114.599 70.9735 112.888C72.8483 110.75 74.8935 108.868 77.8762 108.354C81.8816 107.67 85.8442 109.595 87.932 113.273C89.9347 116.78 89.5086 120.844 86.739 123.966C77.8336 133.975 66.6699 138.722 53.2907 137.91C35.0541 136.797 19.7574 122.683 16.9026 104.59C16.5617 102.409 16.4339 100.228 16.3486 98.5168Z" fill="white"/>
    </g>
    <g filter="url(#filter3_d_3019_4669)">
    <path d="M115.362 77.4355C115.362 90.823 115.362 104.254 115.362 117.641C115.362 118.496 115.532 118.753 116.427 118.753C122.478 118.71 128.571 118.71 134.621 118.753C138.839 118.796 142.589 121.619 143.654 125.596C144.805 129.788 143.143 134.194 139.564 136.503C137.987 137.487 136.24 137.915 134.408 137.915C124.949 137.915 115.532 137.958 106.073 137.915C100.15 137.872 96.273 133.851 96.2305 127.906C96.2305 97.8804 96.2305 67.8975 96.2305 37.8716C96.2305 30.0444 103.772 25.3395 110.504 28.9751C113.913 30.8143 115.319 33.9794 115.319 37.7861C115.362 51.0024 115.362 64.2191 115.362 77.4355Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_d_3019_4669" x="156.69" y="0" width="210.961" height="131.738" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="8"/>
    <feGaussianBlur stdDeviation="8"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3019_4669"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3019_4669" result="shape"/>
    </filter>
    <filter id="filter1_d_3019_4669" x="128.265" y="50.0376" width="111.291" height="111.705" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="8"/>
    <feGaussianBlur stdDeviation="8"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3019_4669"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3019_4669" result="shape"/>
    </filter>
    <filter id="filter2_d_3019_4669" x="0.348633" y="50.1284" width="104.828" height="111.87" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="8"/>
    <feGaussianBlur stdDeviation="8"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3019_4669"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3019_4669" result="shape"/>
    </filter>
    <filter id="filter3_d_3019_4669" x="80.2305" y="19.7261" width="79.7849" height="142.208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="8"/>
    <feGaussianBlur stdDeviation="8"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3019_4669"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3019_4669" result="shape"/>
    </filter>
    </defs>
    </svg>,
    mainLogoMobile: (
        <svg xmlns="http://www.w3.org/2000/svg" width="187" height="92" viewBox="0 0 187 92" fill="none">
  <g filter="url(#filter0_d_7365_3411)">
    <path d="M140.494 17.0673C140.867 17.5806 141.32 17.9359 141.713 18.3505C150.838 27.5103 159.983 36.6898 169.107 45.8496C170.072 46.8171 170.837 47.8831 170.877 49.3241C170.917 51.1995 169.836 52.9367 168.143 53.6474C166.295 54.3976 164.584 54.0423 163.148 52.6801C161.772 51.3574 160.474 49.9755 159.136 48.6134C151.133 40.5592 143.148 32.5245 135.144 24.4702C134.83 24.1543 134.515 23.878 134.141 23.5226C134.102 24.1742 134.043 24.786 134.043 25.3783C134.043 33.2747 134.043 41.171 134.043 49.0675C134.043 50.1138 133.905 51.1403 133.316 52.0484C131.919 54.2199 128.655 54.6344 126.727 52.838C124.879 51.1008 123.089 49.265 121.3 47.4685C110.857 37.0057 100.434 26.543 89.9915 16.0803C88.9884 15.0735 88.3002 13.9285 88.2804 12.4874C88.2608 10.5331 89.2835 9.05248 91.0927 8.32206C92.7446 7.65087 94.5736 8.04569 95.8912 9.34859C105.449 18.9625 115.026 28.5763 124.584 38.1901C124.702 38.3087 124.82 38.4666 124.997 38.6837C125.095 38.1507 125.154 37.7361 125.154 37.3216C125.154 30.6294 125.134 23.9371 125.134 17.245C125.134 15.7644 125.076 14.2838 125.076 12.8033C125.076 10 126.885 8.16413 129.677 8.16413C141.811 8.16413 153.925 8.16413 166.06 8.16413C167.514 8.16413 168.852 8.49973 169.776 9.70393C171.901 12.4479 170.445 16.1 167.534 16.8107C166.827 16.9884 166.098 16.9686 165.391 16.9686C157.976 16.9686 150.543 16.9686 143.129 16.9686C142.244 16.9489 141.398 16.9686 140.494 17.0673Z" fill="#64FA9C"/>
  </g>
  <g filter="url(#filter1_d_7365_3411)">
    <path d="M102.008 44.9419C101.005 43.2047 99.7464 41.7438 97.8979 40.895C95.2233 39.6711 92.4701 39.3354 89.7365 40.6581C86.6882 42.1387 84.6232 44.4879 84.0137 47.9425C83.0894 53.0752 86.9636 58.3066 92.0964 58.9186C92.9617 59.0173 93.8269 58.9974 94.6726 59.2344C96.9146 59.9056 98.2322 61.8599 97.9175 64.0512C97.5832 66.3609 95.7543 67.9007 93.2959 67.8809C89.7169 67.8612 86.4129 66.8544 83.4433 64.8606C79.3332 62.0969 76.5996 58.346 75.577 53.4502C73.4334 43.2639 79.7659 33.5513 89.8938 31.4587C97.9962 29.7809 106.236 34.0252 109.874 41.3688C110.995 43.639 111.566 46.0475 111.723 48.5742C111.782 49.6601 111.821 50.7062 111.31 51.6934C110.504 53.2727 109.206 54.1215 107.455 54.161C102.716 54.2597 97.9766 54.24 93.2567 54.161C90.8181 54.1215 88.9301 52.2067 88.8909 49.7982C88.8514 47.2713 90.7001 45.2973 93.1583 45.1787C95.125 45.0802 97.0915 45.0999 99.0582 45.1787C100.022 45.1986 100.985 45.0603 102.008 44.9419Z" fill="white"/>
  </g>
  <g filter="url(#filter2_d_7365_3411)">
    <path d="M16.1217 49.7738C16.1217 40.5153 22.7885 32.6978 31.6184 31.3752C38.1083 30.4079 43.5951 32.3425 48.1378 37.0605C48.9638 37.9094 49.5539 38.857 49.6522 40.081C49.8094 41.8576 49.1212 43.2593 47.6659 44.266C46.2302 45.2333 44.6767 45.253 43.1427 44.4635C42.3954 44.0686 41.7857 43.4962 41.2155 42.8842C39.1702 40.7127 36.6333 39.6861 33.6638 40.002C29.3372 40.4757 26.0333 43.6146 25.2074 47.9181C24.2044 53.1495 27.9409 58.3809 33.2115 59.131C35.1976 59.4075 37.1249 59.2889 38.9343 58.3413C39.8585 57.8479 40.6452 57.1964 41.3334 56.4067C42.1987 55.4197 43.1427 54.5511 44.5193 54.3143C46.368 53.9984 48.1969 54.8867 49.1605 56.5844C50.0848 58.2032 49.8881 60.0786 48.6099 61.5196C44.4997 66.1391 39.3471 68.3303 33.1721 67.9552C24.7551 67.442 17.695 60.9275 16.3774 52.577C16.22 51.5702 16.161 50.5634 16.1217 49.7738Z" fill="white"/>
  </g>
  <g filter="url(#filter3_d_7365_3411)">
    <path d="M61.8206 40.0446C61.8206 46.2235 61.8206 52.4223 61.8206 58.6012C61.8206 58.9959 61.8993 59.1145 62.3123 59.1145C65.1048 59.0946 67.9171 59.0946 70.7096 59.1145C72.6565 59.1342 74.3872 60.437 74.8787 62.2729C75.4097 64.2075 74.6427 66.241 72.9907 67.307C72.2632 67.761 71.4569 67.9584 70.6112 67.9584C66.2454 67.9584 61.8993 67.9781 57.5334 67.9584C54.7999 67.9387 53.0102 66.083 52.9906 63.3389C52.9906 49.4808 52.9906 35.6424 52.9906 21.7842C52.9906 18.1716 56.4715 16 59.5787 17.678C61.152 18.5269 61.8009 19.9877 61.8009 21.7447C61.8206 27.8446 61.8206 33.9447 61.8206 40.0446Z" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d_7365_3411" x="72.2802" y="0" width="114.598" height="78.0312" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="8"/>
      <feGaussianBlur stdDeviation="8"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7365_3411"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7365_3411" result="shape"/>
    </filter>
    <filter id="filter1_d_7365_3411" x="59.1604" y="23.0938" width="68.596" height="68.7891" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="8"/>
      <feGaussianBlur stdDeviation="8"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7365_3411"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7365_3411" result="shape"/>
    </filter>
    <filter id="filter2_d_7365_3411" x="0.121704" y="23.1328" width="65.6132" height="68.8672" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="8"/>
      <feGaussianBlur stdDeviation="8"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7365_3411"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7365_3411" result="shape"/>
    </filter>
    <filter id="filter3_d_7365_3411" x="36.9906" y="9.10156" width="54.0547" height="82.8672" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="8"/>
      <feGaussianBlur stdDeviation="8"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7365_3411"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7365_3411" result="shape"/>
    </filter>
  </defs>
</svg>
    )
} 