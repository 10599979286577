import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./pavilionsExtraReducers";
import { reducers } from "./pavilionsReducers";

export const initialState = {
  pavilions: [],
  companies: [],
  errors: [],
  company: null,
  companyMedia: [],
  companyFiles:[],
  companyProductGroups: [],
  companyPavilions: [],
  products: [],
  currentPavilion: 'all',
  searchCompany: '',
  currentProduct: {
    id: '',
    value: ''
  }
};

export const PavilionsSlice = createSlice({
  name: "PavilionsSlice",
  initialState,
  reducers,
  extraReducers,
});

export const { 
    setPavilions, 
    filterCompany, 
    setSearchSettings, 
    resetStatePavilionList,
    resetPavilionsState
} = PavilionsSlice.actions;

export const PavilionsReducer = PavilionsSlice.reducer;
