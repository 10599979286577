import React, {useEffect} from "react";
import RightBox from "./RightBox";
import { useSelector, useDispatch } from "react-redux";
import {  useNavigate, useParams } from "react-router-dom";
import { resetAppChatState, setAppChatState, setOpenDialogState } from "../../../store/appChatSlice/appChatSlice";
import { openDialogById } from "../../../store/appChatSlice/appChatExtraReducers";

const RightBoxContainer = ({mobileVersion}) => {

    const openDialog = useSelector(state => state.appChat.openDialog);
    const dialogs = useSelector(state => state.appChat.dialogs);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {chatId} = useParams();
   
    useEffect(() => {

        if(!openDialog.dialogId && dialogs) {

            const openSavedDialog = () => {
                dispatch(openDialogById({id: chatId, navigate}));
                dispatch(setOpenDialogState({...savedDialog, messages: null}));
                dispatch(setAppChatState({key: "dialogs", value: dialogs?.map(dialog => 
                    dialog.dialogId === savedDialog.dialogId ? {...dialog, unread: 0} : dialog)})
                );
            }

            let savedDialog = dialogs.find(dialog => dialog.dialogId === +chatId);

            savedDialog && openSavedDialog();
    

        }
    }, [dialogs])

    useEffect(() => () => dispatch(resetAppChatState("openDialog")), [dispatch]);

    if(!openDialog.dialogId) return null;
      
    return <RightBox  mobileVersion={mobileVersion}/>
}

export default RightBoxContainer