
// SEO. Title must be the same as link in header!

export const follow_en = {
    name: "Участие в cleVR",
    h3: "Станьте участником выставки",
    p1: {
        1: "Создайте свой стенд и аватар в корпоративном стиле. Установите свое оборудование, которое будет демонстрировать свою работу.",
        2: "Получите индивидуальную прямую ссылку на Ваш стенд.",
        3: "Площадь стенда не ограничена.",
    },
    p2:
        "Приглашаем к сотрудничеству организаторов выставок и отдельные Компании.",
    p3:
        "Для того чтобы создать стенд, достаточно отправить нам заявку. Мы оперативно свяжемся с Вами.",
    button: "Подать заявку",


    head: "Become an exhibitor",
    subhead: "Send an application and become an exhibitor on a par with other companies",
    becomeMember: "Become a member",
    benefitsHead: "Participation Benefits",
    benefitsSubhead: "Use the possibilities of the virtual world to promote your products and find new business partners!",
    stand: "Stand",
    standDesc: "Use the constructor to create a unique stand for the enterprise.",
    avatar: "Avatar",
    avatarDesc: "Create your own character in the virtual world.",
    cube3d: "3D-objects",
    cube3dDesc: "Set up 3D-models of your products and equipment at the stand.",
    link: "Direct link",
    linkDesc: "Get a direct link to your stand and share it with others.",
    materialsHead: "Materials for participants",
    materialsSubhead: "Read the standard contract and materials for working in the exhibition space",
    submitRequest: "Send an application and become an exhibitor",
    nameContract: "Standard contract",
    namePolicyHandler: "Personal Data Processing and Storage Policy",
    banner_1: "Banner cleVR 400 x 600.jpg",
    banner_2: "Banner cleVR 192 x 663.jpg",
    banner_3: "Banner cleVR 728 x 90.jpg",
    banner_4: "Banner cleVR 520 x 100.jpg",
    services: {
        askQuestion: "Ask a Question",

        photoHead: "Photo services",
        photoSubhead: "The CleVR team will help you prepare photographic materials for your company's stand",
        photoBlockH1: "Product photography",
        photoBlockSubH1: "We will create professional photos to demonstrate products to future consumers and fill your company page",
        photoBlockH2: "Photography of production and processes",
        photoBlockSubH2: "Our goal is to show the features and scale of production of your enterprise",
        photoBlockH3: "Employee portrait photography",
        photoBlockSubH3: "The image of your company - management and employees who work to improve the product every day",

        designerHead: "Design Services",
        designerSubhead: "The CleVR team will help you prepare and arrange stand materials for participation in the exhibition",
        designerBlockH1: "3D visualization of products",
        designerBlockSubH1: "We will create realistic and interactive 3D models for the most detailed acquaintance with your products.",
        designerBlockH2: "Development of an individual stand of the enterprise",
        designerBlockSubH2: "We will create a unique corporate-style stand for your company, taking into account your wishes.",
        designerBlockH3: "Individual design of the company page",
        designerBlockSubH3: "We will help you design your business page in a unique style",
    },

}
