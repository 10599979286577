export const forms_by = {
    base: {
        firstName: "Імя",
        firstNamePl: "Увядзіце імя",
        email: "Email",
        emailPl: "Увядзіце Email",
        password: "Пароль",
        passwordPl: "Увядзіце пароль",
        repeatPassword: "Паўтарыце пароль",
        repeatPasswordPl: "Увядзіце пароль паўторна",
        phone: "Тэлефон",
        cancel: "Адмяніць",
    },
    registr: {
        head: "Рэгістрацыя",
        subhead1: "Запоўніце ўсе абавязковыя палі",
        subhead2: "каб стварыць свой акаўнт карыстальніка CleVR",
        blockUserH1: "Карыстальнік",
        lastName: "Прозвішча",
        lastNamePl: "Увядзіце прозвішча",
        patronymic: "Імя па бацьку",
        patronymicPl: "Увядзіце Імя па бацьку",
        blockSecurityH1: "Бяспечнасць",
        blockAdditH1: "Дадатковыя кантакты",
        agreement1: "Я згаджаюся з правіламі карыстання,",
        agreement2: "палітыкай прыватнасці і",
        agreement3: "апрацоўкі персанальных дадзеных",
        submit: "Зарэгістравацца",
        errors: {
            acceptAgreement: "Прыміце карыстальніцкае пагадненне",
        },
    },
    login: {
        head: "Уваход",
        subhead: "Увайдзіце ў профіль выкарыстоўваючы ваш email і пароль",
        forgetPassword: "Забылі пароль?",
        login: "Увайсці",
        registr: "Рэгістрацыя",
        notAccount: "Яшчэ няма акаўнта? Зарэгіструйцеся ў CleVR",
        register: "Зарэгістравацца",
    },
    support: {
        head: "Служба падтрымкі",
        theme: "Тэма звароту",
        themePl: "Увядзіце тэму звароту",
        message: "Паведамленне",
        messagePl: "Увядзіце паведамленне",
        send: "Адправіць",
    },
    forgetPassword:{
        head: "Забылі пароль?",
        subhead: "Увядзіце email і перайдзіце па спасылцы ў пошце для змены пароля",
        addDesc: "Праверце спам і правільнасць уведзенай пошты, калі спасылка не з'явіцца ў вашых паведамленнях",
        back: "Назад",
        getLink: "Атрымаць спасылку",
        registr: "Рэгістрацыя",
        notAccount: "Яшчэ няма акаўнта? Зарэгіструйцеся ў CleVR",
        register: "Зарэгістравацца",
    },
    requestPart: {
        head: "Заяўка на ўдзел",
        subhead: "Запоўніце форму заяўкі на ўдзел",
        subhead2: "вашай арганізацыі ў CleVR і мы звяжамся з вамі",
        blockCompanyH1: "Арганізацыя",
        organizationName: "Назва арганізацыі",
        organizationNamePl: "Увядзіце назву арганізацыі",
        typeActivity: "Від дзейнасці",
        typeActivityPl: "Абярыце від дзейнасці прадпрыемства",
        blockContactH1: "Кантактная асоба",
        comment: "Каментарый",
        submitRequest: "Падаць заяўку"
    },
    question: {
        head: 'Новае пытанне',
    }
}
