import styled from "styled-components";

const ModalContainer = styled.div`
     position: fixed;
    background: #00000052;
    inset: 0px;
    z-index: 21;
`;

const ModalOpen = styled.div`
    position: absolute;
    max-width: ${props => props.maxWidth}px;
    min-width: 320px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 22;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const CloseButton = styled.button`
    width: 26px;
    height: 26px;
    background-color: #F5F5F5;
    border: none;
    cursor: pointer;
    border-radius: 9px;
    float: right;
    margin: 16px 0 0 0;
    position: absolute;
    right: 16px;
`;

const ModalSignInWrap = styled.div`
    padding: 0 16px;
    margin-top: 24px;
`;

const Title = styled.p`
    font-family: 'Helvetica';
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    text-align: center;
`;

const Desc = styled.p`
    font-weight: 400;
    width: ${props => props.width}px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #484848;
    padding: 14px 0;
    margin: 0 auto;
`;

const InputFields = styled.div`
.InputEmail{
    margin-bottom: 25px;
}
.InputPassword{
    position: relative;
    button{
        position: absolute;
        top: 11px;
        right: 10px;
        width: 20px;
        height: 1px;
        border: none;
        background: none;
        cursor: pointer;
    }
}
.RecoveryPassword{
    display: flex;
    justify-content: space-between;
}
    p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
    margin: 16px 0 0;
    .Invalid_Input{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
`;

const RegInput = styled.input`
    border: none;
    background: #F5F5F5;
    border-radius: 4px;
    outline: none;
    width: 100%;
    height: 36px;
    padding-left: 12px;
    font-family: 'Gilroy', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
`;

const ActiveLoginBtn = styled.button`
    margin: 30px 0 36px;
    width: 100%;
    height: 36px;
    border: none;
    background: #34C57F;
    border-radius: 8px;
    color: white;
    cursor: pointer;
`;

const RecoveryBtn = styled.button`
    height: 1px;
    border: none;
    background: none;
    color: #34C57F;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
`;

const RegItem = styled.div`
    padding: 0px 16px 16px;
`;

const ItemInfo = styled.div`
    padding-top: 16px;
    button{
        width: 100%;
        height: 36px;
        border: 1px solid #34C57F;
        border-radius: 8px;
        cursor: pointer;
        background-color: white;
        color: #34C57F;;
    }
`;



export {ModalContainer, ModalOpen, 
    ModalSignInWrap, CloseButton, 
    Title, Desc, 
    InputFields, RegInput, 
    ActiveLoginBtn, RecoveryBtn, 
    RegItem, ItemInfo
}