import { handlerData } from "../../services/handlerData.service";
import { handlerLocalStorage } from "../../services/handlerLocalStorage.service";
import { initialState } from "./appSlice";

const { getAppText } = handlerData();
const { set } = handlerLocalStorage();

const reducers = {

  setAppState(state, {payload}) {
    return {...state, ...payload}
  },

  setAppTextLang(state, action) {
    set("language", action.payload);
    state.appText = getAppText(action.payload);
  },

  setToast(state, {payload}){
    console.log(payload)
    state.toast = payload
  },

  resetAppState(state, action) {
    switch (action.payload) {
      case 'modal':
        state.modal = initialState.modal;
        break;
      case "tab":
        state.selectedTab = initialState.selectedTab;
        break;

      default:
        break;
    }
  },
  openMessageHint(state, {payload}){
    state.messageHint = payload
  },
  setMetaData(state, {payload}){
    state.metaData = payload
  }
};

export default reducers;
