import {ErrorMessage, Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {svg_icons} from "../../../assets/imgs/svg_icons";
import {SendQuestionFollow} from "../../../store/appSlice/appAsyncThunk";
import {ModalQuestionSchema} from "../../../validation/validation";
import {FieldWrap} from "../../Account/Settings/RestorePassword/Restore.style";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../Registration/Login/style/Login_css";
import {ModalQuestionWrap} from "./ModalQuestion.style";

const initialValues = {
  name: "",
  message: "",
  email: "",
};

export const ModalQuestion = ({setOpenModal}) => {
  const {exitIcon} = svg_icons;
  const dispatch = useDispatch();
  const {
    forms: {question, base, support},
  } = useSelector((state) => state.app.appText);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setOpenModal(false)}>
          {exitIcon}
        </CloseButton>
        <ModalQuestionWrap>
          <Title>{question.head}</Title>
          <Formik
            initialValues={initialValues}
            validationSchema={ModalQuestionSchema}
            onSubmit={(values) => {
              const SendMessage = {
                email: values.email,
                name: values.name,
                message: values.message,
              };
              dispatch(SendQuestionFollow(SendMessage));
              setOpenModal(false);
            }}
          >
            {({dirty, handleSubmit, values, isValid}) => (
              <Form>
                <FieldWrap>
                  <label htmlFor="name" required>
                    {base.firstName}
                  </label>
                  <Field
                    name="name"
                    type="text"
                    placeholder={base.firstNamePl}
                  />
                  <ErrorMessage
                    name="name"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <FieldWrap>
                  <label htmlFor="email" required>
                    Email*
                  </label>
                  <Field name="email" type="text" placeholder={base.emailPl} />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <FieldWrap>
                  <div className="MessageQuestion">
                    <label htmlFor="message" required>
                      {support.message}*
                    </label>
                    <p>
                      {values.message.length} {"из 350 символов"}
                    </p>
                  </div>
                  <Field
                    name="message"
                    className={"Message_textarea"}
                    as="textarea"
                    placeholder={support.messagePl}
                  />
                  <ErrorMessage
                    name="message"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <div className="Btns_Menu_Wrap">
                  <button
                    className="BackBtn"
                    type="button"
                    onClick={() => setOpenModal(false)}
                  >
                    {base.cancel}
                  </button>
                  <button
                    disabled={!(dirty && isValid)}
                    style={dirty && isValid ? {opacity: "1"} : {opacity: "0.5"}}
                    className="ChangeBtn"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {support.send}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalQuestionWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
