import { useDispatch, useSelector } from "react-redux"
import {PavilionsSelectsWrap, SelectsBlock, SelectsContent } from "./style/PavilionsSelectsBlock_css"
import {setSearchSettings } from "../../../store/PavilionsSlice/pavillionsSlices";
import AppSelect from "../../../components/AppSelect/AppSelect";


export const PavilionsSelectsBlock = ({setOptionOnSelectPavilions}) => {


    const {companies, pavilions, searchCompany } = useSelector((state) => state.pavilions)
    const dispatch = useDispatch()

    console.log(pavilions)

    const select_settings_1 = {
        options: setOptionOnSelectPavilions(),
      };

    const changePavilionSelect = (value) => {
        console.log(value)
        if(value.id === 0){
            dispatch(setSearchSettings({key: 'currentPavilion', value: 'all'}))
        }else{
            const pavilion = pavilions.find((pavilion) => pavilion.id === value.id)
            pavilion && dispatch(setSearchSettings({key: 'currentPavilion', value: pavilion.nameKey}))
        }
    }

    // commented: set Products into dataListInput Library
/*    const itemsProducts = useMemo(
        () =>
          products.map((product) => ({
            // required: id and value
            id: product.id,
            value: product.name,
            // optional: label, node
            // label: option.name, // use a custom label instead of the value
            // node: option.name, // use a custom ReactNode to display the option
            ...products, // pass along any other properties to access in your onSelect callback
          }
          )),
        [products],
      );

      const resetProductId = (value) => {
        value === '' && setProduct({id: '', value: ''})
      }*/

    return (
        <PavilionsSelectsWrap>
        <SelectsContent>
            <SelectsBlock>
                <h3>Павильоны</h3>
                <AppSelect settings={select_settings_1} onChange={(value) => changePavilionSelect(value)} />
            </SelectsBlock>
            {/*<SelectsBlock>
                <h3>Группы продукции</h3>
                <DatalistInput 
                value={product.value}
                placeholder={`Все (${products.length})`}
                items={itemsProducts}
                onSelect={(item) => setProduct({id: item.id, value: item.value})}
                onChange={(e) => resetProductId(e.target.value)}
                 />
            </SelectsBlock>*/}
            <SelectsBlock>
                <h3>Экспоненты</h3>
                <input onChange={(e) => dispatch(setSearchSettings({key: 'searchCompany', value: e.currentTarget.value}))} value={searchCompany} className="CompanyInput" placeholder={`Все (${companies.length})`}></input>
            </SelectsBlock>
        </SelectsContent>
        </PavilionsSelectsWrap>
    )
}