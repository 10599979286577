import styled from "styled-components";

const WrapHeaderRightBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  flex-wrap: wrap;
`;

const WrapMobileHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  min-width: 100%;
  max-width: 100%;

  .mobile-head__chats {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #363636;
    padding-left: 10px;
  }
  .mobile-head__back,
  .roll-up__icon {
    width: 24px;
    height: 24px;
    background: #f4f4f4;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    & > svg {
      margin: auto;
      display: block;
    }
  }

  .mobile-head__roll-up {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
    margin-left: auto;
    cursor: pointer;
    .roll-up__icon {
      display: flex;
      margin-left: 8px;
    }
  }
`;

const DialogUser = styled.div`
  display: flex;
  align-items: center;

  img,
  .dialog-default-img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .dialog-default-img {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d1d1d1;
    color: #ffffff;
    text-transform: capitalize;
  }

  .user-info {
    display: flex;
    align-items: center;
    padding: 0 4px 0 10px;

    .user__status {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #34c57f;
      border-radius: 50%;
      margin-left: 4px;
    }
  }

  .info__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
  }
  .info__role {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-transform: capitalize;
    color: #484848;
    opacity: 0.7;
  }
`;

const DialogTools = styled.div`
  display: flex;

  .mobile-tools {
    position: relative;
    width: 24px;
    height: 24px;
    background: #f4f4f4;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    & > svg {
      margin: auto;
      display: block;
    }
  }

  .tools__base-tool {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: none;
    background: #f5f5f5;
    border-radius: 10px;
    margin-right: 8px;
    cursor: pointer;
  }

  .remove-chat {
    background: #fff2f2;
  }
`;

export { WrapHeaderRightBox, WrapMobileHead, DialogUser, DialogTools };
