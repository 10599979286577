import styled from "styled-components";

const RestorePasswordContainer = styled.div`
padding: 0 16px;
    margin-top: 24px;
    margin-bottom: 30px;
    .Btns_Menu_Wrap{
        margin-top: 5px;
        button{
            width: 340px;
            height: 36px;
            border-radius: 8px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
            cursor: pointer;
        }
        .BackBtn{
            color: #34C57F;
            background: #FFFFFF;
            border: 1px solid #34C57F;
            margin-bottom: 10px;
        }
        .ChangeBtn{
            color: #FFFFFF;
            background: #34C57F;
            border: none;
        }
    }
`;

const FieldWrap = styled.div`
margin-bottom: 25px;
.InputPassword{
    position: relative;
    .ChangeType{
        position: absolute;
        border: none;
        background: none;
        cursor: pointer;
        right: 5px;
        top: 11px;
    }
  
}
.RecoveryPassword{
        display: flex;
        justify-content: space-between;
    }
    .field__error-mes{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
label {
    font-family: 'Gilroy';
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 16px;
color: #363636;
    padding-bottom: 5px;
  }

  input {
    height: 36px;
    background: #f5f5f5;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 10px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    width: 100%;
  }
`

export {RestorePasswordContainer, FieldWrap}