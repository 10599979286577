import styled from "styled-components";

const UserRolesWrap = styled.div`
display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 16px 34px;
  button {
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    cursor: pointer;
  }
  .head_info_wrap {
    display: flex;
    justify-content: space-between;
    button {
      width: 182px;
      height: 34px;
      background: #34c57f;
      border: 1px solid #34c57f;
      border-radius: 8px;
      color: #ffffff;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
    }
  }
`;

const UsersRolesContainer = styled.div`
width: 100%;
height: 100%;
border: 0.5px solid #D4D4D4;
border-radius: 4px;
margin-bottom: 17px;
padding-right: 4px;
padding-top: 4px;
.map_container{
    display: flex;
    flex-direction: column;
    height: inherit;
    gap: 12px;
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px 8px 16px 16px;
}
`;

const RoleCard = styled.div`
    width: inherit;
    height: 66px;
    background: #F5F5F5;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head_role_text{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        min-width: 186px;
    }
`;

const CreateDate = styled.div`
display: flex;
gap: 6px;
.role_desc{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
}
.create_role_date{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
}
` 

const RoleBtnsWrap = styled.div`
        display: flex;
    gap: 5px;
    button{
        width: 30px;
        height: 30px;
        background: #EBEBEB;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .settings_icon{
        width: 16px;
        height: 16px;
    }
    .btn_basket{
        svg{
            width: 18px;
            height: 18px;
            path{
                fill: #363636;
            }
        }
    }
`;

export {
    UserRolesWrap,
    UsersRolesContainer,
    RoleCard,
    CreateDate,
    RoleBtnsWrap
}