import styled from "styled-components";


const WrapSendMessage = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 6px;
    height: 36px;
    margin-top: 16px;

    .enter-file {
        .enter-file__input-hidden {
          position: absolute;
          visibility: hidden;
          max-width: 0;
          max-height: 0;
        }
      }

    .btn-new-mes {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 36px;
        height: 36px;
        background: #F5F5F5;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }
    .new-message {
        min-height: 36px;
        padding: 10px;
        background: #F5F5F5;
        border-radius: 8px;
        resize: none;
        border: none;
        outline: none;
    }
`;
export {
    WrapSendMessage,
}