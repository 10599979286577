import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../services/requests.services";

export const GetPavilions = createAsyncThunk(
  "PavilionsSlice/FetchPavilions",
  async () => {
    const { PavilionsApi } = getRequest();
    const response = await PavilionsApi.getPavilions();
    return response;
  }
);

export const GetCompanies = createAsyncThunk(
  "PavilionsSlice/FetchCompanies",
  async (param) => {
    const { PavilionsApi } = getRequest();
    const response = await PavilionsApi.getCompanies(param);
    return response;
  }
);

export const GetCompany = createAsyncThunk(
  "PavilionsSlice/FetchCompany",
  async (url, thunkAPI) => {
    const { PavilionsApi } = getRequest();
    const firstName = thunkAPI.getState().profile.userData.firstName
    const response = await PavilionsApi.getCompany(url, firstName);
    return response;
  }
);

export const GetListOfProducts = createAsyncThunk(
  "PavilionsSlice/FetchAllProducts",
  async () => {
    const { PavilionsApi } = getRequest();
    const response = await PavilionsApi.getAllProducts();
    return response;
  }
);
