import {
  DocumentsContainer,
  DocumentsFileContainer,
  DocumentsWrap,
} from "./style/UserDocuments_css";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { DeleteUserDocumentById, GetUserDocuments } from "../../../store/profileSlice/profileAsyncThunk";
import { FileIcon, FileInfo, FileMainBlock, FileName, FileSize } from "../../File/style/File_css";
import { useState } from "react";
import { SettingsUserBtns, UserSettings } from "../Admin/Users/AllUsers/AllUsers.style";
import { SettingsContent } from "../../SettingsContent/SettingsContent";
import downloadFile from "../../../services/downloadFile.service";
import { serverUrl } from "../../../API/api";
import { svg_icons } from "../../../assets/imgs/svg_icons";
import { changeSizeFile } from "../../../utils/helpers";


export const UserDocuments = () => {

    const dispatch = useDispatch()
    const {documents} = useSelector((state) => state.profile)
    const [openSettings, setOpenSettings] = useState({key: -1, value: '', open: false})
    const token = useSelector((state) => state.auth.token);
    const {profile: {documentsText}} = useSelector((state) => state.app.appText)

    useEffect(() => {
        dispatch(GetUserDocuments())
    }, [dispatch])


  const {downloadIcon, trashIcon, pointsIcon, wordIcon, pdfIcon, searchIcon} = svg_icons

  const Settings = [
    {
        component: (
          <SettingsUserBtns onClick={() => 
          {
            downloadFile({type: "base", data: {
                      url: `${serverUrl}/files/download?fileId=${openSettings.value.fileId}&token=${token}`,
                      fileName: openSettings.value.name,
                    }})
            setOpenSettings({key: -1, open: false, value: ''})
          }}>
            {downloadIcon} {"Скачать файл"}
          </SettingsUserBtns>
        ),
      },
      {
        component: (
          <SettingsUserBtns onClick={() => 
          {
            dispatch(DeleteUserDocumentById(openSettings.value.fileId))
            setOpenSettings({key: -1, open: false, value: ''})
          }
          } style={{ background: "#FFF2F2", color: "#FE564B" }}>
            {trashIcon} {"Удалить"}
          </SettingsUserBtns>
        ),
      },
  ]

  return (
    <DocumentsContainer>
      <h2>{documentsText.name} ({`${documents.length}`})</h2>
      <div className="filter-search">
          {searchIcon}
          <input placeholder={documentsText.searchPl}></input>
        </div>
      <DocumentsWrap>
      <div className="user_documents_container">
      {documents.length === 0 ? <p className="empty_arr">{documentsText.noDocuments}</p>  : documents.map((item, index) => {
        return (
            <Fragment key={index}>
            <DocumentsFileContainer >
            <FileIcon>{item.extension === 'docx' ? wordIcon : pdfIcon}</FileIcon>
            <FileInfo>
                <FileMainBlock>
                    <FileName>{item.name}</FileName>
                    <FileSize>{changeSizeFile(item.size)}</FileSize>
                </FileMainBlock>
                <UserSettings style={{marginLeft: 'auto'}}>
                    <button className="dots_settings" onClick={() => setOpenSettings({key: index, open: true, value: item})}>{pointsIcon}</button>
                    {openSettings.open && openSettings.key === index ? <SettingsContent exhibitor={false} setOpenSettings={setOpenSettings} SettingsItems={Settings}/> : null}
                </UserSettings>
            </FileInfo>
        </DocumentsFileContainer>
        </Fragment>
        )}
        )}
        </div>
      </DocumentsWrap>
    </DocumentsContainer>
  );
};
