import React from "react";
import { useParams } from "react-router-dom";
import { Contacts } from "../Contacts/Contacts";
import { ExhibitorPage } from "../ExhibitorPage/ExhibitorPage";
import { Meets } from "../Meets/Meets";
import { MyFavorites } from "../MyFavorites/MyFavorites";
import { Notifications } from "../Notifications/Notifications";
import { PanelUserItem } from "../PanelUserItem/PanelUserItem";
import { SelectExhibitors } from "../SelectExhibitors/SelectExhibitors";
import { UserDocuments } from "../UserDocuments/UserDocuments";

export const PanelContent = () => {
  const { content } = useParams();

  const buildContent = () => {
    switch (content) {
      case "user-panel":
        return <PanelUserItem />;
      case "contacts":
        return <Contacts activePanel={"Onlycontacts"} />;
      case "meets":
        return <Meets />;
      case "favorites":
        return <MyFavorites activePanel={"OnlyFavorites"} />;
      case "notifications":
        return <Notifications />;
      case "select-exhibitors":
        return <SelectExhibitors />;
      case "documents":
        return <UserDocuments />;
      case "page-exhibitor": 
      return <ExhibitorPage/>
      default:
        return (
          <p style={{ textAlign: "center", fontWeight: 700, margin: "auto" }}>
            Страница не найдена
          </p>
        );
    }
  };

  return <>{buildContent()}</>;
};
