export const editCompany_en = {
    imgVideo: "Image or video(Youtube)",
    numbersFiles: "Number of files",
    companyName: "Company name",
    compOrganForm: "Company organization form",
    abbreviation: "Abbreviation",
    headerColor: "Header color",
    logo: "Logo",
    addLogo: "Add a logo",
    addMedia: "Add media",
    addImage: "Add an image",
    addVideo: "Add a video",
    addDoc: "Add a document",
    linkImage: "Link to the image",
    pcImage: "An image from a computer",
    linkLogo: "Link to the logo",
    pcLogo: "A logo from a computer",
    linkYoutube: "Link from Youtube",
    pcDoc: "A document from a computer",
    url: "Insert link", 
    pc: "Select from computer",
    aboutCompany: "About the company",
    additionalInfoHeader: "Additional information (Header)",
    additionalInfoText: "Additional information (Text)",
    showCompany: "Show the company",
    btnSave: "Save",
    btnBack: "Back",
    btnDelete: "Delete",
    btnAdd: "Add",
    btnOverwrite: 'Overwrite',
    sendData: "Sending data",
    selectPavilions: "Selecting a pavilion",
    headerImg: 'Image',
    headerVideo: 'Video',
    modalDeleteCompany_text: 'Do you really want to delete the company?',
    modalDeleteCompany_btnYes: 'Yes',
    modalDeleteCompany_btnNo: 'No',
    toolbar: {
        header:'Toolbar',
        addLinkBtn:'Add a link',
        addLinkError:'It is not possible to insert part of a tag into another tag',
    },
    limit: {
        imageSize: 'Image size is not more than 10 MB',
        logoSize: 'The size of the logo is not more than 2 MB',
        docSize: 'Document size no more than 50 MB',
        mediaNumber: 'The number of media files is not more than 30',
        docNumber: 'The number of documents is not more than 30',
    },
}