import styled from "styled-components";

const RestoreEmailWrap = styled.div`
    padding: 24px 16px;
    .Btns_Menu_Wrap{
        margin-top: 5px;
        button{
            width: 340px;
            height: 36px;
            border-radius: 8px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
            cursor: pointer;
        }
        .BackBtn{
            color: #34C57F;
            background: #FFFFFF;
            border: 1px solid #34C57F;
            margin-bottom: 10px;
            margin-top: 30px;
        }
        .ChangeBtn{
            color: #FFFFFF;
            background: #34C57F;
            border: none;
        }
    }
    .email{
        input{
            width: 100%;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
        }
    }
    .field__error-mes{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
`;

export {RestoreEmailWrap}