import { icons_svg } from "../Exhibition/Company/imgs/icons.svg"

const {arrowLeft,arrowRight} = icons_svg


export const SlickArrowLeft = ({currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={
          "slick-prev slick-arrow" +
          (currentSlide === 0 ? " slick-disabled" : "")
        }
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
      >
        {arrowLeft}
      </button>
)

export const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      {arrowRight}
    </button>
)