export const contextMenu_ru = {
    writeMes: "Написать сообщение",
    block: "Заблокировать",
    unblock: "Разблокировать",
    removeChat: "Удалить чат",
    removeUser: "Удалить пользователя",
    manamgeAndConfig: "Управление и настройка",
    downloadFile: "Скачать файл",
    removeFromContact: "Удалить из контактов",
    addFromContact: "Добавить в контакты",
    remove: "Удалить",
}