export const features_ru = {
    blockHead1_0: "Привлекательные тарифы ", 
    blockHead1_1: "и индивидуальные ", 
    blockHead1_2: "3D стенды ", 
    blockHead1_3: "для каждого участника",
    blockDesc1: "Создавайте собственные выставочные стенды и оформляйте согласно с фирменным стилем вашего предприятия. Размещайте образцы продукции, в том числе и в формате 3D.", 
    blockHead2_0: "Получайте подробную ", 
    blockHead2_1: "статистику ", 
    blockHead2_2: "о работе вашей страницы", 
    blockDesc2: "Авторизованным пользователям доступна полная статистика о помещениях и активных действиях других участников выставки в рамках страницы и 3D стенда предприятия.",
}