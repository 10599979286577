import React from "react";
import * as s_c from "./style/MobileApp_css";
import MobileImg from "../../../assets/Components/Routes/MainPage/MobileApp/Install_app.jpg";
import { useSelector } from "react-redux";

export const MobileApp = () => {

  const {mobileApp: localText} = useSelector((state) => state.app.appText);

  return (
    <s_c.MobileAppSection>
    <s_c.MobileAppContainer>
    <div className="image_app_container">
        <img className="app_image" src={MobileImg} alt=""></img>
    </div>
      <s_c.MobileAppContent>
        <p className="head_text_mobileApp">
          {localText.head} <strong>{localText.headStrong}</strong>
        </p>
        <div className="line"></div>
        <p className="IOS_Android_text">
          <strong>IOS</strong> | Android
        </p>
        <p className="mobile_app_desc">
          {localText.desc1}
          <br/>
          <br/>
          {localText.desc2}
        </p>
      </s_c.MobileAppContent>
      </s_c.MobileAppContainer>
    </s_c.MobileAppSection>
  );
};
