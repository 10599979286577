import styled from "styled-components";

const HeadComponentWrap = styled.div`
border-width: 0px 0px 0.5px 0px;
height: 60px;
width: 100%;
padding: 0 16px;
border-style: solid;
border-color: #D4D4D4;
display: flex;
justify-content: space-between;
h2{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
}
.logo_wrap{
    height: inherit;
    display: flex;
    align-items: center;
    img{
        width: 36px;
        height: 36px;
    }
}
.back_to_headPage{
    height: inherit;
    display: flex;
    align-items: center;
    button{
        width: 28px;
        height: 28px;
        border: none;
        background: #F5F5F5;
        border-radius: 10px;
        cursor: pointer;
    }
}
.head_info_wrap{
    display: flex;
    align-items: center;
}
.head_btns_wrap{
    height: inherit;
    display: flex;
    align-items: center;
    gap: 10px;
    button{
        width: 158px;
        height: 32px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        border-radius: 8px;
        cursor: pointer;
        svg{
            path {
                fill: #FFFFFF;
                stroke: #FFFFFF;
            }
        }
    }
    .first_head_btn{
        color: #34C57F;
        border: 1px solid #34C57F;
        background: none;
    }
    .second_head_btn{
        color: #FFFFFF;
        background: #34C57F;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }
}
`



export {
    HeadComponentWrap
}