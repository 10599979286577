import styled from "styled-components";

const DeleModalWrap = styled.div`
padding: 24px 16px 30px;
.delete_info{
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #484848;
    padding-top: 16px;
}
.delete_text{
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    text-align: center;
    padding-bottom: 26px;
}
`

const BtnsWrap = styled.div`
display: flex;
gap: 10px;
button{
    cursor: pointer;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    width: 100%;
    height: 36px;
    border-radius: 8px;
}
.back_btn{
    border: 1px solid #34C57F;
    background: none;
    color: #34C57F;
}
.send_btn{
    background: #34C57F;
    border: none;
    color: #FFFFFF;
}
`;

export {
    DeleModalWrap,
    BtnsWrap,
}