export const footer_ru = {
    about: "Описание",
    services: "Услуги",
    partners: "Партнёры",
    pavilions: "Павильоны",
    follow: "Участие в cleVR",
    blog: "Новости",
    contacts: "Связаться",
    link: "Онлайн выставка",
    mail: "Есть вопросы?",
    copyright: "Авторские права",
    rights: "Все права защищены v. 1.2.1",
    manual: 'Инструкции',
    documents: "Документы",
}