export const errorPage_en = {
    notFound: {
        // company: 'Company not found',
        // pavilion: 'Pavilion not found',
        noMedia: 'No media',
        default: 'Page not found',
    },
}

export const errorsRequest_en = {
    unknown: 'Unknown error',
    registration: {
        'email': 'User with such email exists',
        'nick': 'User with such nickname exists',
        'notValid': 'User is not valid',
    },
    modalAddCompany: {
        nameExist: 'Company with such nameKey exist',
    },
    news: {
        'name': 'There is a news item with this name',
    },
    seo: {
        repeatFile: 'File with such name already exists',
    }
}

export const errorsValid_en = {
    required: 'This field cannot be empty',
    name: 'Name can contain only letters and should have from 2 to 20 symbols.',
    surname: 'Surname can contain only letters and should have from 2 to 32 symbols.',
    patronymic: 'Patronymic can contain only letters and should have from 2 to 20 symbols.',
    nickname: 'Nickname can contain only letters and should have from 3 to 32 symbols.',
    email: 'Doesn\'t match the E-mail format.',
    password: 'Password mustn`t shorter than 8 symbols.',
    passwordLat: 'Password must contain only latin letters and numbers',
    passwordEqual: 'Password must be equal.',
    company: 'enter error',
    position: 'enter error',
    userAgreementNotChecked: 'Accept the user agreement',
    selectTypeContent: 'Select the content type',
}