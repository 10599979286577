import {
  ExhibitorsDesc,
  ExhibitorsImg,
  ExhibitorsInfo,
  ExhibitorsMainBlock,
  Line,
  Link3DStand,
  SearchContent,
  SelectExhibitorsContainer,
  SelectExhibitorsItem,
  SelectExhibitorsWrap,
} from "./style/SelectExhibitors_css";
import testExhibitorImage from "../../../assets/Components/test/TestExpanentsImages/images/TestCompanyImg.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DeleteSelectExhibitor, GetFavoritesCompany } from "../../../store/profileSlice/profileAsyncThunk";
import { filesUrl } from "../../../API/api";
import { useState } from "react";
import { useOpenExhibition } from "../../../hooks/useOpenExhibition";
import { RequestModal } from "../../Exhibition/RequestsModal/RequestsModal";
import { setAppState } from "../../../store/appSlice/appSlice";
import { Link } from "react-router-dom";
import { svg_icons } from "../../../assets/imgs/svg_icons";

export const SelectExhibitors = () => {
  const dispatch = useDispatch()
  const {favoritesIcon, trashIcon, searchIcon, pointsIcon, visit3D} = svg_icons


  const [searchParam, setSearchParam] = useState('')
  const {favoritesCompany } = useSelector((state) => state.profile)
  const {language, profile: {selectExhText}, baseWords: {btns}} = useSelector((state) => state.app.appText);
  const openExhibitions = useOpenExhibition();
  const [openRequestsModal, setOpenRequestsModal] = useState()

  useEffect(() => {
    dispatch(GetFavoritesCompany(searchParam))
    
  }, [dispatch, searchParam])

  const handlerActions = (key, data) => (e) => {
    switch (key) {
        case "contextMenu":
            dispatch(
                setAppState({
                  contextMenu: {
                    currentTarget: e.currentTarget,
                    listMenu: [
                      {
                        onClick: () => {
                            setOpenRequestsModal(data.index)
                        },
                        icon: favoritesIcon,
                        text: "Заявка на встречу",
                      },
                      {
                        onClick: () => {
                            dispatch(DeleteSelectExhibitor(data.companyId))
                        },
                        icon: trashIcon,
                        text: "Удалить",
                        class: " error"
                      }
                    ],
                  },
                })
              );
              break;    
        default:
            break;
    }
  }


  return (
    <SelectExhibitorsContainer>
      <h2>{`${selectExhText.name} (${favoritesCompany.length})`}</h2>
      <SearchContent>
     {/*   <SelectContainer>
          <SelectDate>
            <option>По дате добавления</option>
          </SelectDate>
          <ArrowBtn>{arrow_up_down}</ArrowBtn>
        </SelectContainer>*/}
        <div className="filter-search">
          {searchIcon}
          <input placeholder={selectExhText.searchPl} onChange={(e) => setSearchParam(e.currentTarget.value)}></input>
        </div>
      </SearchContent>
      <SelectExhibitorsWrap>
        <div className="map_container">
        { favoritesCompany.length === 0 ? <p className="empty_arr">{selectExhText.noSelectExh}</p>  : favoritesCompany.map((el, index) => {
          return (
            <SelectExhibitorsItem key={index}>
              <Link to={`/company/${el.companyNameKey}`}>
              <ExhibitorsImg bg={testExhibitorImage}>
              <div className="logo_container">
                <img src={filesUrl + el.logo} alt={"Exhibitors_Logo"}></img>
                </div>
              </ExhibitorsImg>
              </Link>
              <ExhibitorsInfo>
                <ExhibitorsMainBlock>
                <Link to={`/company/${el.companyNameKey}`} style={{textDecoration: 'none'}}><h3>{el.subNameShort[language]}{' '}{el.name[language]}</h3></Link>
                  <button className="dots_settings" onClick={handlerActions("contextMenu", {...el, index})}>{pointsIcon}</button>
                </ExhibitorsMainBlock>
              </ExhibitorsInfo>
              <Line style={{margin: '0 12px'}}></Line>
              <ExhibitorsDesc>
                  <Link3DStand>
                  <div style={{cursor: 'pointer'}} onClick={() => openExhibitions({pavilionId: el.pavilions[0] && el.pavilions[0].id, company: el.companyNameKey})}>
                    {visit3D}
                    <p> {btns.stand3D}</p>
                </div>
                  </Link3DStand>
                {/*  <ExhibDate>
                    <p>
                      <strong>{selectExhText.add}:</strong> {formatDate(el.time)}
                    </p>
                  </ExhibDate>*/}
                </ExhibitorsDesc>
              {openRequestsModal === index ? <RequestModal setOpenRequestsModal={setOpenRequestsModal} companyId={el.id} /> : null}
            </SelectExhibitorsItem>
            
          );
        })}
        </div>
      </SelectExhibitorsWrap>
    </SelectExhibitorsContainer>
  );
};
