import styled from "styled-components";

const MaterialsSection = styled.section`
    background-color: #fff;
`;

const FollowRequest = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  p{
    max-width: 390px;
    color: #363636;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    padding: 50px 0 15px;
  }
`;

const FilesContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0 16px;
`;

export { MaterialsSection, FollowRequest, FilesContent }