import React from "react"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { LoginSchema } from "../../validation/validation"
import { ActiveLoginBtn, Desc, InputFields, 
    ModalSignInWrap, RecoveryBtn, 
    RegInput, Title } from "./Login/style/Login_css"
import { sendLogin } from './../../store/authSlice/authExtraReducers';
import { svg_icons } from "../../assets/imgs/svg_icons"

    const InitialValues = {
        email: '',
        password: ''
    }


export const SignIn = ({setRecover}) => {

    const {eyeIcon, crossEyeIcon} = svg_icons
    const [InputType, setInputType] = useState('password')
    const [eyePass, setEyePass] = useState()
    const {forms: {login, base}} = useSelector((state) => state.app.appText)

    const dispatch = useDispatch()
   /* useEffect(() => {
        InitialValues.password.length > 4 && InitialValues.length > 4 ? setActiveBtn(true) : setActiveBtn(false)
    }, [])*/

    useEffect(() => {
        InputType === 'password' ? setEyePass(eyeIcon) : setEyePass(crossEyeIcon)
    }, [crossEyeIcon, eyeIcon, eyePass, InputType])

    return (
        <ModalSignInWrap>
                    <Title>{login.head}</Title>
                    <Desc>{login.subhead}</Desc>
                    <Formik  initialValues={InitialValues} validationSchema = {LoginSchema} onSubmit = {(value) => {
                    dispatch(sendLogin(value))
                    document.body.style.overflow = ''
                    }
                   }
                    >
            {({errors, touched, values,isValid, handleChange, handleSubmit}) => 
            (
                <Form>
                    <InputFields>
                    <div className="InputEmail">
                        <p>Email*</p>
                        <RegInput name="email" placeholder={base.emailPl} onChange={handleChange} />
                        {
                        (errors.email && values.email)
                        ||
                        (errors.email && touched.email) 
                        ? 
                        ( <p className="Invalid_Input">{errors.email}</p> ) : 
                        null
                    }
                    </div>
                        <div className="RecoveryPassword">
                            <p>{base.password}*</p>
                            <RecoveryBtn type="button" onClick={() => setRecover(true)}>{login.forgetPassword}</RecoveryBtn>
                        </div>
                        <div className="InputPassword">
                        <RegInput name="password" type={InputType} placeholder={base.passwordPl} onChange = {handleChange}/>
                        <button type="button" onClick={() => InputType === 'password' ? setInputType('text'): setInputType('password')}>{eyePass}</button>
                        </div>
                    </InputFields>
                    <ActiveLoginBtn 
                    disabled={!isValid || (!Boolean(values.password) || !Boolean(values.email))} 
                    type="submit"
                    style={!isValid || (!Boolean(values.password) || !Boolean(values.email)) ? {cursor: 'no-drop', opacity: '0.5'} : null}
                    onClick={handleSubmit} onKeyDown={(e) => console.log(e.key)}>
                    {login.login}
                    </ActiveLoginBtn>
                </Form>
            )}
            </Formik>
                </ModalSignInWrap>
    )
} 