import styled from 'styled-components';

const BannerSection = styled.section`
    padding: 45px 0 0;
    background: url(${props => props.BG1}) center no-repeat;
    min-height: 700px;
    background-attachment: fixed;
    background-position: top;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    h1{
        color: #FFFFFF;
        max-width: 862px;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 140%;
        text-align: center;
    }
`;

const BannerAreaBox = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BannerText = styled.div`
    margin: 0px auto;
    width: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: #FFFFFF;
        text-align: center;
        padding-bottom: 24px;
    }
    > button {
        display: block;
        width: 172px;
        height: 38px;
        padding: 8px 0px;
        color: #FFFFFF;
        background: #34C57F;
        border: 1.4px solid #34C57F;
        border-radius: 12px;
        text-align: center;
        text-decoration: none;
        margin: 0 auto;
        font-family: 'Gilroy';
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        cursor: pointer;
    }
`;

export {BannerSection, BannerAreaBox, BannerText};