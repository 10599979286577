import styled from "styled-components";

const ProductsGroupWrap = styled.div`
    margin-left: 55px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const ProductCard = styled.div`
width: 100%;
height: 54px;
background: #F5F5F5;
border-radius: 4px;
padding: 0 12px;
display: flex;
align-items: center;
.product_head_wrap{
        display: flex;
        gap: 12px;
        align-items: center;
        .Uncover_btn{
            width: 28px;
            height: 28px;
            background: #EBEBEB;
            border-radius: 10px;
            border: none;
            position: relative;
        }
    }
`

const NameProductText = styled.div`
    display: flex;
    gap: 5px;
    .head_name{
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        display: flex;
        align-items: center;
        :before{
            content: '';
            display: inline-block;
            background-color: #363636;
            width: 3px;
            height: 3px;
            border-radius: 20px;
            margin-right: 5px;
        }
    }
    .product_head_text{
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
    }
`;

const ProductBtnsWrap = styled.div`
    display: flex;
    gap: 5px;
    margin-left: auto;
    button{
        width: 30px;
        height: 30px;
        background: #EBEBEB;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .settings_icon{
        width: 17px;
        height: 17px;
    }
    .btn_basket{
        svg{
            width: 18px;
            height: 18px;
            path{
                fill: #363636;
            }
        }
    }
`;

export {
    ProductsGroupWrap,
    ProductCard,
    NameProductText,
    ProductBtnsWrap
}