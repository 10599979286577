import { useDispatch, useSelector } from "react-redux"
import { ErrorHandlerWrap } from "./ErrorHandlerUI.style"
import { svg_icons } from "../../assets/imgs/svg_icons"
import { CloseButton } from "../Registration/Login/style/Login_css"
import { setToast } from "../../store/appSlice/appSlice"
import errorIcon from '../../assets/imgs/error.png'


export const ErrorHandlerUI = () => {

    const {toast} = useSelector((state) => state.app)
    const { exitIcon} = svg_icons
    const dispatch = useDispatch()

    if(toast.type !== 'error'){
        return null
    }

    return (
        <ErrorHandlerWrap>
            <div className="info_wrap">
                <img src={errorIcon} alt=""></img>
                <p>{toast.text}</p>
            </div>
            <CloseButton style={{top: '25px', margin: '0'}} onClick={() => dispatch(setToast({text: '', type: null}))}>{exitIcon}</CloseButton>
        </ErrorHandlerWrap>
    )
}