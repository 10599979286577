import { SendQuestionFollow, getMetaData } from "./appAsyncThunk"

export const extraReducers = (builder) => {
    builder.addCase(
        SendQuestionFollow.fulfilled(), (state, {payload}) => {
        state.message = 'Спасибо за ваше сообщение! Мы свяжемся с вами в ближайшее время :)'
    },
    SendQuestionFollow.rejected(), (state, {payload}) => {
        state.messageErr = 'Произошла непредвиденная ошибка'
    }
    )
    builder.addCase(
        getMetaData.fulfilled(), (state, {payload}) => {
            state.metaData = payload
        }
    )
}