import { Exhibition } from "../Pages/Exhibition/Exhibition";
import { FollowClevr } from "../Pages/Follow/FollowClevr";
import { MainPage } from "../Pages/Main/MainPage";
import { CompanyContainer } from "../Pages/Exhibition/Company/CompanyContainer";
import { Manual } from "../Pages/Manual/Manual";
import { NewsPage } from "../Pages/News/NewsPage/NewsPage";
import { SelectedNews } from "../Pages/News/SelectedNews/SelectedNews";
import { Agreement } from "../Pages/Registration/Agreement/Agreement";
import { Activate } from "../Pages/Activate/Activate";
import { PolicyAgreement } from "../Pages/Registration/PolicyAgreement/PolicyAgreement";
import { Confirm } from "../Pages/Confirm/Confirm";

export const routes = [
  { path: "/", component: MainPage, exact: true, strict: true },
  { path: "/exhibition", component: Exhibition, exact: true, strict: true },
  { path: "/news", component: NewsPage, exact: true, strict: true },
  { path: "/follow", component: FollowClevr, exact: true, strict: true },
  { path: "/manual", component: Manual, exact: true, strict: true },
  // { path: '/account', component: Account, exact: true, strict: true },
  {
    path: "/company/:id",
    component: CompanyContainer,
    exact: true,
    strict: true,
  },
  { path: "/news/:id", component: SelectedNews, exact: true, strict: true },
  { path: "/agreement", component: Agreement, exact: true, strict: true },
  { path: "/policy-agreement", component: PolicyAgreement, exact: true, strict: true},
  { path: "/activate/:token", component: Activate, exact: true, strict: true },
  {path: "/users/password/confirm/:token", component: Confirm, exact: true, strict: true }
];
