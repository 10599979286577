export const mainPage_en = {
    partners: {
        head : "Our partners",
        subhead : "We are glad to introduce our partners in the implementation of the virtual exhibition project",
        list: {
            msHead: "Ministry of Communications and Informatization of the RB",
            mpHead: "Ministry of Industry of the RB",
            cniituHead: "JSC CNIITU",
            mgtpHead: "Minsk city technopark",
            naiHead: "National Agency for Investment and Privatization",
            belisaHead: "SI «BelISA»",
            interExpo: "Unitary enterprise «BELINTEREXPO»"
        },
    },
    joinUs: {
        head: "Even more opportunities!",
        subhead: "Join CleVR and discover virtual business opportunities! Create stands, add product catalogs and communicate with potential customers.",
        join: "Join cleVR",
    },
    footer: {
        news: {
            head: "News",
            link1: "Main news",
            link2: "All news",
        },
        exhibition: {
            head: "Exhibition",
            link1: "Product groups",
            link2: "Pavilions",
            link3: "Exhibitors",
        },
        follow: {
            head: "Participation",
            link1: "Advantages",
            link2: "Photo services",
            link3: "Design services",
            link4: "Become a member",
        },
        info: {
            head: "Information",
            link1: "Instruction manual",
            link2: "Contacts",
        },
        mobileApp: {
            head: "Mobile application",
            link1: "IOS version",
            link2: "Android version",
            btnIOS: "CleVR for IOS",
            btnAndroind: "CleVR for Android"
        },
        policy: "Privacy policy",
        contract: "Offer agreement",
        support: "Technical support",
        rights: "All rights reserved v. 1.2.1",
    },
    floatLogin: {
        head: "Even more features!",
        subhead: "Log in to the site and open all the features of c Clever",
        registr: "Register",
        login: "Log in to your account",
        close: "Close",
    },
}