
// SEO. Title must be the same as link in header!

export const news_by = {
    title: "Навіны",
    blog: "Блог",
    button: "Чытаць усе навіны",
    openNews: 'Адкрыць навіну',
    rollUp: 'Згарнуць',
    expand: 'Разгарнуць',
    addNews: 'Дадаць навіну',
    editNews: 'Рэдагаваць',
    editPage: {
        btnBack: 'Назад',
        btnSave: 'Захаваць',
        logo: 'Малюнак',
        btnLogo: 'Дадаць малюнак',
        name: 'Назва',
        title: 'Загаловак',
        content: 'Кантэнт',
        showNews: 'Паказваць навіну',
        btnDelete: 'Выдаліць навіну',
    },
}
