import { AdminHead } from "../AdminHead/AdminHead"
import { SearchSortContent } from "../SearchSortContent/SearchSortContent"
import { AdminPanelContentWrap, ContentWrap, InterfaceCard } from "./AdminPanelContent.style"
import { ContentData } from "./dataContent"


export const AdminPanelContent = ({title, desc}) => {

    return (
        <AdminPanelContentWrap>
        <div className="head_info_wrap">
        <AdminHead title={title} desc = {desc}/>

        <button className="download_report">Скачать отчет</button>
        </div>

        <SearchSortContent page={'admin-panel'} dateSort = {'none'}/>

            <ContentWrap>
            <div className="map_container">
            {ContentData.map((data, index) => {
                return (
                    <InterfaceCard key={index}>
                        <h3>{data.head}</h3>
                        <p className="date_info">{data.data}</p>
                        <p className="count_info">{data.count}</p>
                        <div className="text_wrap">
                        <div className="imgs_wrap" style={data.icons !== undefined ? {width: 'auto', display: 'flex', alignItems: 'center'} : {}}>
                        {data.imgs?.avatars?.map((avatar,index) => {
                            return (
                                <img key={index} src={avatar} alt='Avatar_img' className="Avatar_img"/>
                            )
                        })}
                        {data.imgs?.logo?.map((avatar,index) => {
                            return (
                                <img key={index} src={avatar} alt='Avatar_img' className="Avatar_img"/>
                            )
                        })}
                        {data.icons}
                        </div>
                        <p className="text_info">{data.text}</p>
                        </div>
                        <button>{data.btnText}</button>
                    </InterfaceCard>
                )
            })
            }
            </div>
            </ContentWrap>
        </AdminPanelContentWrap>
    )
}