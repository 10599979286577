import styled, { css } from "styled-components";

const WrapBaseModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 30px;
  position: relative;
  min-width: ${({ type }) => (type === "removeDialog" ? "auto" : "372px")};
  background: #ffffff;
  border-radius: 8px;

  h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    padding-bottom: ${({ type }) => (type === "removeDialog" ? "18px" : "0")};
  }
  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 20px 0 14px 0;
  }
  .base-text {
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    text-align: center;
    padding-bottom: 24px;
    .text__name {
      font-weight: 700;
    }
    span:last-child {
      display: block;
    }
  }

  .base-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    button {
      min-width: 150px;
      height: 32px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      padding: 0 10px;
      &:first-child {
        color: #34c57f;
        border: 1px solid #34c57f;
        background: transparent;
      }
      &:last-child {
        background: ${({ type }) => 
          type === "clearHistory" ||
          type === "removeContact" ||
          type === "removeContactFromContacts" ||
          type === "removeContactFromUnity" ||
          type === "removeDialog"
            ? "#FE564B"
            : "#34C57F"};
        color: #ffffff;
      }
    }
  }

  .add__role {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #484848;
    padding: 3px 0px 16px 0;
  }

  ${({ type }) =>
    type === "addToContact" &&
    css`
      width: 392px;
      img {
        order: -2;
        margin-top: 0px;
      }
      h3 {
        order: -1;
      }
      .base-text .text__name {
        font-weight: normal;
      }

      .base-btns {
        button {
          width: 180px;
        }
      }
    `}
`;

const WrapNewFileContent = styled.div`
  position: relative;
  max-width: 370px;
  padding: 24px 16px 30px;
  background: #ffffff;
  border-radius: 8px;

  h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    padding-bottom: 20px;
    text-align: center;
  }

  .file__remove-btn,
  .file__close-btn {
    width: 30px;
    height: 30px;
    background: #f5f5f5;
    border-radius: 8.66667px;
    border: none;
    cursor: pointer;
  }

  .file__close-btn {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 16px;
    top: 16px;
    background: #f5f5f5;
    border-radius: 8.66667px;
    z-index: 1;
  }

  .content__selected-file {
    background: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;

    .file__preview {
      position: relative;
      width: 340px;
      height: 191px;
      max-width: 340px;
      max-height: 191px;

      &.icon {
        max-width: 26px;
        max-height: 32px;
        margin-right: 10px;
      }

      .icon-elem {
        position: absolute;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
      }

      img,
      video {
        height: 100%;
        width: 100%;
        background: black;
        object-fit: contain;
      }
    }

    .file-desc {
      display: flex;
      align-items: center;
      padding: 12px;

      & > p span {
        display: block;
        &:fast-child {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
        }
        &:last-child {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #484848;
        }
      }

      .file__remove-btn {
        background: #ebebeb;
        margin-left: auto;
        .fill_trash_svg {
          fill: #363636;
        }
      }
    }
  }

  .base-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding-top: 32px;

    button {
      width: 165px;
      height: 36px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      &:first-child {
        color: #34c57f;
        border: 1px solid #34c57f;
        background: transparent;
      }
      &:last-child {
        background: ${({ type }) =>
          type === "clearHistory" ||
          type === "removeContact" ||
          type === "removeDialog"
            ? "#FE564B"
            : "#34C57F"};
        color: #ffffff;
      }
    }
  }
`;

const WrapNewDialog = styled.div`
  position: relative;
  padding: 24px 16px 30px;
  background: #FFFFFF;
  border-radius: 8px;

  h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    padding-bottom: 20px;
    text-align: center;
  }

  .new-dialog__close-btn {
      position: absolute;
      width: 26px;
      height: 26px;
      right: 16px;
      top: 16px;
      background: #f5f5f5;
      border-radius: 8.66667px;
      border: none;
  }

  .base-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding-top: 32px;

    button {
      width: 165px;
      height: 36px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      &:first-child {
        color: #34c57f;
        border: 1px solid #34c57f;
        background: transparent;
      }
      &:last-child {
        background: ${({ type }) =>
          type === "clearHistory" ||
          type === "removeContact" ||
          type === "removeDialog"
            ? "#FE564B"
            : "#34C57F"};
        color: #ffffff;
      }
    }
  }

  form {
    label {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #363636;
    }

    .select-base-icon-btn {
      border: none;
      background: transparent;
      svg {
        vertical-align: top;
      }
    }

    .base-input { 
      background: #F5F5F5;
      border-radius: 4px;
      width: 100%;
      border: none;
      outline: none;
      margin-top: 5px;
    }

    .dialog-select-user {
      padding-bottom: 23px;
      position: relative;

      .user__selected {
        display: flex;
        align-items: center;
        height: 48px;
        background: #F5F5F5;
        border-radius: 6px;
        padding: 12px;

        img, .user__default-img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }


        .user__default-img {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #d1d1d1;
          color: #ffffff;
          text-transform: capitalize;
      }

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
          padding-left: 8px;
        }

        button {
          margin-left: auto;
          cursor: pointer;
        }

        ${({ openList }) =>
        !openList  &&
        css`
            position: absolute;
            top: 5px;
            left: 0;
            right: 0;
            height: 36px;
            padding: 6px 12px;
        `}
        

      }

      input {
        padding: 8px 12px;
        height: 36px;
        appearance: none;
        ${({ valueSelect }) =>
        !valueSelect  &&
        css`
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
          opacity: 0.5;
        `}
  
        .list__elem-none {
          display: none;
        }
      }

      .wrap-user-list {
        position: absolute;
        top: 41px;
        left: 0px;
        right: 0px;
        z-index: 1;
        display: flex;
        max-height: 208px;
        background: #FFFFFF;
        box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.35);
        border-radius: 0px 0px 4px 4px;
        padding: 12px;

        .user-list {
          position: relative;
          display: flex;
          flex: 1;  
          border: 0.5px solid #DEDEDE;
          border-radius: 4px;
          .user-list-scroll {
            display: grid;
            gap: 10px;
            margin: 2px 2px 2px 0px;
            flex: 1;
            overflow: auto;
            overflow-x: hidden;
            padding: 12px 10px 12px 12px;

            .user__not-found {
                text-align: center;
                font-size: 13px;
                line-height: 16px;
                color: #363636;
            }
          }
        }
      }


    }



    .dialog-first-message {
      position: relative;

      .message__length {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #484848;
        opacity: 0.8;
      }

      textarea {
        height: 108px;
        resize: none;
        padding: 10px;
      }

    }


  }
`;

export { WrapBaseModal, WrapNewFileContent, WrapNewDialog };
