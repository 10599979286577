import styled from "styled-components";

const FileContainer = styled.div`
    width: calc(1/3*100% - (1 - 1/3)*16px);
    height: 60px;
    background: #F8F8F8;
    border-radius: 4px;
    display: flex;
    align-items: center;
`;

const FileWrap = styled.div`
    margin: 32px auto;
    height: 60px;
    background: #F8F8F8;
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-basis: 30%;
    min-width: 300px;
`;

const FileIcon = styled.div`
    margin: 0 10px 0 12px;
`;

const FileInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 12px;
    .dots_settings{
        width: 30px;
    height: 30px;
    border: none;
    background-color: #EFEFEF;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    }
`;

const FileName = styled.p`
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const FileSize = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #484848;
`;

const FileMainBlock = styled.div`

`;

const FileSettings = styled.button`
    width: 30px;
    height: 30px;
    border: none;
    background-color: #EFEFEF;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export {FileContainer, FileIcon, 
    FileInfo, FileName, 
    FileSize, FileMainBlock,
    FileSettings, FileWrap
}