import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../services/requests.services";


export const createMetaData = createAsyncThunk(
    'MetaTagsSlice/createMetaTag', 
    async (data, thunkAPI) => {
        console.log(data)
        const {metaTagsApi} = getRequest()
        const response = await metaTagsApi.createMetaTag(data)
        return response
    }
)

export const getMetaData = createAsyncThunk(
    'MetaTagsSlice/getMetaData',
    async (data, thunkAPI) => {
        const {metaTagsApi} = getRequest()
        try {
            const response = await metaTagsApi.getMetaTag(data.url)
            return {response, lang: data.lang}
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)