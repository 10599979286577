import styled from "styled-components";

const WrapNavBurger = styled.div`
    display: flex;
    align-items: center;
  .nav-toggle{
        background: #F5F5F5;
        width: 28px;
        height: 28px;
        border-radius: 8px;
        margin-left: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const WrapBurgerMenu = styled.div`
    position: fixed;
    background: #00000052;
    height: 100%;
    width: 100%;
`;

const BurgerMenuContainer = styled.div`
background: #FFFFFF;
position: fixed;
z-index: 100;
max-width: 289px;
width: 289px;
height: 100%;
left: -100%;
overflow-y: auto;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  button{
    outline: none;
    border: none;
    margin: 16px 0 0;
    width: 28px;
    height: 28px;
    background: #F5F5F5;
    border-radius: 8px;
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
`;

const BurgerMenuTitle = styled.div`
    text-align: center;
    margin-top: 22px;
    border-bottom: 0.5px solid #D8D8D8;
    padding-bottom: 25px;
    h2{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
    }
`;

const BurgerNavLinks = styled.div`
    margin-top: 24.5px;
    text-align: center;
    margin-bottom: 32px;
    ul{
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            gap: 14px;
        li{
            width: 100%;
            height: 40px;
            background: #F5F5F5;
            border-radius: 12px;
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: center;
            a{
                font-weight: 500;
                font-size: 13px;
                line-height: 140%;
                color: #363636;
                :hover{
                    color: #34C57F;
                }
            }
        }
    }
`;

const BurgerLang = styled.div`
    margin: 0 16px;
    padding: 25px 0 0;
    border-top: 0.5px solid #D8D8D8;
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 8px;
    }
`;

const BurgerLoginBtn = styled.div`
margin: 0 16px;
button{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #FFFFFF;
    background: #34C57F;
    border-radius: 8px;
    width: 100%;
    height: 40px;
    position: inherit;
}
`;

const NavUserWrap = styled.div`
    margin-top: 27.5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NavAvatar = styled.img`
width: 72px;
height: 72px;
border-radius: 36px;
margin-bottom: 14px;
`;

const NavUserTextWrap = styled.div`
display: flex;
gap: 5px;
p{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
}
`;

export {WrapBurgerMenu, WrapNavBurger, 
    BurgerMenuContainer, BurgerMenuTitle,
    BurgerNavLinks, BurgerLang, 
    BurgerLoginBtn, NavAvatar, 
    NavUserTextWrap, NavUserWrap
}