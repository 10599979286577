import { useEffect } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { AppSwitch } from "../../../../../components/AppSwitch/AppSwitch";
import {
    resetGallery,
  setConsult,
  setGalleryInfo,
  setGalleryItems,
} from "../../../../../store/exhibitorSlice/exhibitorSlice";
import { BtnsWrap, CreateMedia } from "./AddGalleryFile.style";

export const NewVideo = ({ setAddFile, setOpenAddMedia }) => {
  const { gallery } = useSelector((state) => state.exhibitor);
  const dispatch = useDispatch();

  const ValidForm = Boolean(gallery.video.src)

  useEffect(() =>{
    return () => {
        dispatch(resetGallery({key: 'video', value: {
        name: '',
        description: '',
        consultation: false,
        src: '',
        type: 'video'
        }}))
    }
  }, [dispatch])

  return (
    <CreateMedia>
      <div className="preview">
        <p>{"Превью"}</p>
        {Boolean(gallery.video.src) ? (
          <ReactPlayer
            className={"preview_video"}
            url={gallery.video.src}
            alt=""
          />
        ) : (
          <>
            <div className="image_none_wrap">
              <div className="file_wrap_none">
                <label>
                  <p>{"Cсылка не добавлена"}</p>
                </label>
              </div>
              <div className="file_info_none"></div>
            </div>
          </>
        )}
      </div>
      <div className="name_exponat">
        <p className="head_text">{"Название экспоната"}</p>
        <input
          placeholder={"Введите название экспоната"}
          onChange={(e) =>
            dispatch(
              setGalleryInfo({
                key: "video",
                infoKey: "name",
                value: e.target.value,
              })
            )
          }
        />
      </div>
      <div className="url_on_video">
        <p>{"Ссылка на файл"}</p>
        <input
          placeholder={"Вставьте ссылку"}
          onChange={(e) =>
            dispatch(
              setGalleryInfo({
                key: "video",
                infoKey: "src",
                value: e.target.value,
              })
            )
          }
        />
      </div>
      <div className="actions">
        <p className="head_text">{"Действия"}</p>
        <div className="switcher_wrap">
          <p>{"Заказать консультацию"}</p>
          <AppSwitch
            onChange={(e) =>
              dispatch(setConsult({ key: "video", value: e.target.checked }))
            }
          />
        </div>
      </div>
      <div className="description_wrap">
        <p>{"Описание к экспонату"}</p>
        <textarea
          onChange={(e) =>
            dispatch(
              setGalleryInfo({
                key: "video",
                infoKey: "description",
                value: e.target.value,
              })
            )
          }
        />
      </div>
      <BtnsWrap>
        <button className="back" onClick={() => setAddFile("")}>
          {"Отменить"}
        </button>
        <button
          style={ValidForm ? { opacity: "1" } : { opacity: "0.5" }}
          className="send_btn"
          disabled={!ValidForm}
          onClick={() => {
            dispatch(setGalleryItems({key: 'video'}))
            setAddFile('')
            setOpenAddMedia(false)
          }}
        >
          {"Сохранить"}
        </button>
      </BtnsWrap>
    </CreateMedia>
  );
};
