
import { useState } from "react"
import { useDispatch } from "react-redux"
import { AddFavoritesCompany } from "../../../store/profileSlice/profileAsyncThunk"
import { AddFavoritesModal } from "./AddFavoritesModal"



export const AddFavoritesModalContainer = ({setOpenModal, company}) => {
    const dispatch = useDispatch()
    const [sendStatus, setSendStatus] = useState(false)
   
    const handlerDataAdd = (type, id) => (event) => {
        switch (type) {
            case "AddCompany":
                dispatch(AddFavoritesCompany(id))
                setSendStatus(true)
                break;
            default:
                break;
        }
    } 
    return (
        <AddFavoritesModal handlerDataAdd={handlerDataAdd} setOpenModal={setOpenModal} company={company} sendStatus={sendStatus} />
    )
}