import React from "react";
import { useDispatch, useSelector } from "react-redux";
import icons_svg from "../../imgs/icons_svg";
import HeaderRightBox from "./HeaderRightBox";
import { resetAppChatState, setAppChatState } from "../../../../store/appChatSlice/appChatSlice";

const HeaderRightBoxContainer = () => {
  const dispatch = useDispatch();
  const { openDialog, mobileVersion, contextMenu, dialogs } = useSelector(
    (state) => state.appChat
  );

  // {type: "blockUser", content}

  const {
    search,
    videoCall,
    removeUserIcon,
    block,
    clearHistory,
    trash,
    points,
    arrowBack,
    selectArrow,
  } = icons_svg;

  const tools = [
    // {
    //   onClick: () => dispatch(setAppChatState({ key: "toast", value: true })),
    //   icon: search,
    // },
    // {
    //   onClick: () => dispatch(setAppChatState({ key: "toast", value: true })),
    //   icon: videoCall,
    // },
    // {
    //   onClick: () =>
    //     dispatch(setAppChatState({ key: "modal", value: "removeContact" })),
    //   onClick: () => dispatch(setAppChatState({ key: "toast", value: true })),
    //   icon: removeUserIcon,
    // },
    openDialog.user?.block ===  0 || openDialog.user?.block ===  2 ?
    {icon: block, text: 'Заблокировать', onClick: () => dispatch(setAppChatState({ key: "modal", value: {type: "blockUser", content: openDialog.user || openDialog} })),}
    :
    {icon: block, text: 'Разблокировать',  onClick: () => dispatch(setAppChatState({ key: "modal", value: {type: "unblockUser", content: openDialog.user || openDialog} })) },
    // {
    //   onClick: () =>
    //     dispatch(setAppChatState({ key: "modal", value: "clearHistory" })),
    //     onClick: () => dispatch(setAppChatState({ key: "toast", value: true })),
    //   icon: clearHistory,
    // },
    {
      onClick: () =>
        dispatch(setAppChatState({ key: "modal", value: {type: "removeDialog", content: openDialog} })),
      icon: trash,
      class: " remove-chat",
    },
  ];

  const handlerActions = (type, data) => () => {
    switch (type) {
      case "contextTools":
        dispatch(setAppChatState({ key: "contextMenu", value: "toolsUser" }));
        break;
      case "closeContextTools":
        dispatch(setAppChatState({ key: "contextMenu", value: null }));
        break;
      case "closeMobileChat":
        data && dispatch(setAppChatState({ key: "mobileVersion", value: false }));
        dispatch(resetAppChatState("openDialog"));
        
        break;
      default:
        break;
    }
  };

  return (
    <HeaderRightBox
      tools={tools}
      openDialog={openDialog}
      mobileVersion={mobileVersion}
      points={points}
      contextMenu={contextMenu}
      handlerActions={handlerActions}
      arrowBack={arrowBack}
      selectArrow={selectArrow}
      dialogs={dialogs}
    />
  );
};

export default HeaderRightBoxContainer;
