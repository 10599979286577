
// SEO. Title must be the same as link in header!

export const follow_by = {
    name: "Участие в cleVR",
    h3: "Станьте участником выставки",
    p1: {
        1: "Создайте свой стенд и аватар в корпоративном стиле. Установите свое оборудование, которое будет демонстрировать свою работу.",
        2: "Получите индивидуальную прямую ссылку на Ваш стенд.",
        3: "Площадь стенда не ограничена.",
    },
    p2:
        "Приглашаем к сотрудничеству организаторов выставок и отдельные Компании.",
    p3:
        "Для того чтобы создать стенд, достаточно отправить нам заявку. Мы оперативно свяжемся с Вами.",
    button: "Подать заявку",


    head: "Станьце ўдзельнікам выставы",
    subhead: "Адпраўце заяўку і станьце ўдзельнікам выставы нароўні з іншымі прадпрыемствамі",
    becomeMember: "Стаць удзельнікам",
    benefitsHead: "Перавагі ўдзелу",
    benefitsSubhead: "Выкарыстоўвайце магчымасці віртуальнага свету для папулярызацыі сваёй прадукцыі і пошуку новых дзелавых партнёраў!",
    stand: "Стэнд",
    standDesc: "Выкарыстоўвайце канструктар для стварэння унікальнага стэнда прадпрыемства.",
    avatar: "Аватар",
    avatarDesc: "Стварыце свайго персанажа ў віртуальным свеце.",
    cube3d: "3D-аб'екты",
    cube3dDesc: "Усталюйце на стэндзе 3D-мадэлі прадукцыі і абсталявання.",
    link: "Прамая спасылка",
    linkDesc: "Атрымайце прамую спасылку на ваш стэнд і дзяліцеся ёй з іншымі.",
    materialsHead: "Матэрыялы для ўдзельнікаў",
    materialsSubhead: "Азнаёмцеся з тыпавой дамовай і матэрыяламі для працы ў выставачнай прасторы",
    submitRequest: "Дашліце заяўку і станьце ўдзельнікам выставы",
    nameContract: "Тыпавы дагавор",
    namePolicyHandler: "Палітыка апрацоўкі і захоўвання персанальных дадзеных",
    banner_1: "Банер cleVR 400 x 600.jpg",
    banner_2: "Банер cleVR 192 x 663.jpg",
    banner_3: "Банер cleVR 728 x 90.jpg",
    banner_4: "Банер cleVR 520 x 100.jpg",
    services: {
        askQuestion: "Задаць пытанне",

        photoHead: "Фотапаслугі",
        photoSubhead: "Каманда CleVR дапаможа вам падрыхтаваць фотаматэрыялы для стэнда вашага прадпрыемства",
        photoBlockH1: "Прадметная фотаздымка прадукцыі",
        photoBlockSubH1: "Мы створым прафесійныя фатаграфіі для дэманстрацыі прадукцыі будучым спажыўцам і запаўнення старонкі вашага прадпрыемства",
        photoBlockH2: "Фатаздымка вытворчасці і працэсаў",
        photoBlockSubH2: "Наша мэта - паказаць асаблівасці і маштаб вытворчасці вашага прадпрыемства",
        photoBlockH3: "Партрэтная фотаздымка супрацоўнікаў",
        photoBlockSubH3: "Імідж вашага прадпрыемства - кіраўніцтва і супрацоўнікі, якія працуюць над удасканаленнем прадукта кожны дзень",

        designerHead: "Дызайнерскія паслугі",
        designerSubhead: "Каманда CleVR дапаможа вам падрыхтаваць і аформіць матэрыялы стэнда для ўдзелу ў выставе",
        designerBlockH1: "3D-візуалізацыя прадукцыі",
        designerBlockSubH1: "Мы створым рэалістычныя і інтэрактыўныя 3D-мадэлі для максімальна падрабязнага азнаямлення з вашай прадукцыяй",
        designerBlockH2: "Распрацоўка індывідуальнага стэнда прадпрыемства",
        designerBlockSubH2: "Мы створым для вашага прадпрыемства ўнікальны стэнд у карпаратыўным стылі з улікам вашых пажаданняў",
        designerBlockH3: "Індывідуальнае афармленне старонкі прадпрыемства",
        designerBlockSubH3: "Мы дапаможам аформіць старонку вашага прадпрыемства ва ўнікальным стылі",
    },
}
