export const features_by = {
    blockHead1_0: "Прывабныя тарыфы ", 
    blockHead1_1: "і індывідуальныя ", 
    blockHead1_2: "3D стэнды ", 
    blockHead1_3: "для кожнага ўдзельніка",
    blockDesc1: "Стварайце ўласныя выставачныя стэнды і афармляйце згодна з фірмовым стылем вашага прадпрыемства. Змяшчайце ўзоры прадукцыі, у тым ліку і ў фармаце 3D.", 
    blockHead2_0: "Атрымлівайце падрабязную ", 
    blockHead2_1: "статыстыка ", 
    blockHead2_2: "аб рабоце вашай старонкі", 
    blockDesc2: "Аўтарызаваным карыстальнікам даступная поўная статыстыка аб памяшканнях і актыўных дзеяннях іншых удзельнікаў выставы ў рамках старонкі і 3D стэнда прадпрыемства.", 
}