export const mainPage_by = {
    partners: {
        head : "Нашы партнёры",
        subhead : "Рады прадставіць нашых партнёраў у рэалізацыі праекта віртуальнай выставы",
        list: {
            msHead: "Міністэрства сувязі і інфарматызацыі РБ",
            mpHead: "Міністэрства прамысловасці РБ",
            cniituHead: "ААТ ЦНІІТУ",
            mgtpHead: "Мінскі гарадскі тэхнапарк",
            naiHead: "Нацыянальнае агенцтва інвестыцый і прыватызацыі",
            belisaHead: "ГУ «БелІСА»",
            interExpo: "Унітарнае прадпрыемства «БЕЛІНТЭРЭКСПА»"
        },
    },
    joinUs: {
        head: "Яшчэ болей магчымасцяў!",
        subhead: "Далучыся да CleVR і адкрый для сябе магчымасці віртуальнага бізнэсу! Стварай стэнды, дадавай каталогі прадукцыі і перапісвайся з патэнцыйнымі заказчыкамі.",
        join: "Далучыцца",
    },
    footer: {
        news: {
            head: "Навіны",
            link1: "Галоўныя навіны",
            link2: "Усе навіны",
        },
        exhibition: {
            head: "Выстава",
            link1: "Групы прадукцыі",
            link2: "Павільёны",
            link3: "Экспаненты",
        },
        follow: {
            head: "Удзел",
            link1: "Перавага",
            link2: "Фотапаслугі",
            link3: "Дызайнерскія паслугі",
            link4: "Стаць удзельнікам",
        },
        info: {
            head: "Інфармацыя",
            link1: "Інструкцыя",
            link2: "Кантакт",
        },
        mobileApp: {
            head: "Мабільнае прыкладанне",
            link1: "Версія для IOS",
            link2: "Версія для Android",
            btnIOS: "CleVR для IOS",
            btnAndroind: "CleVR для Android"
        },
        policy: "Палітыка прыватнасці",
        contract: "Дагавор аферты",
        support: "Тэхпадтрымка",
        rights: "Усе правы абаронены v. 1.2.1",
    },
    floatLogin: {
        head: "Яшчэ больш магчымасцяў!",
        subhead: "Выканайце ўваход на сайт і адкрыйце ўсе магчымасці C Clever",
        registr: "Зарэгістравацца",
        login: "Увайсці",
        close: "Зачыніць",
    },
}