import styled from "styled-components";

const UserPanelWrap = styled.div`
    position: relative;
    min-width: 283px;
    max-width: 283px;
    margin-bottom: 33px;

    .panel__background {
        display: block;
        width: 100%;
    }
`

const ScrollUserPanel = styled.div`
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0px;
    right: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 53px 14px 0 16px;
    overflow-y: auto;
    overflow-x: hidden;
    .user__avatar {
        width: 111px;
        height: 111px;
        border-radius: 50%;
        margin-bottom: 18px;
    }
`

const WrapProfileInfo = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    width: 100%;

    .info-user {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 24px;
        .user__name {
            h3 {
                font-weight: 700;
                font-size: 15px;
                line-height: 19px;
                color: #363636;
            }
            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                text-transform: capitalize;
                color: #484848;
            }
        }
        button {
            ${({theme}) => theme.iconButton}
            .settings_icon{
                width: 18px;
                height: 18px;
            }
        }
    }

    .info-work {
        padding: 8px 12px 5px;
        background: #F5F5F5;
        border-radius: 10px;

        h4 {
            font-weight: 700;
            font-size: 13px;
            line-height: 140%;
            color: #363636;
            padding-bottom: 4px;
        }
        p {
            font-size: 13px;
            line-height: 110%;
            color: #484848;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 227px;
            height: 35px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 10px;
            border: none;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-decoration-line: underline;
            color: #34C57F;
            cursor: pointer;
            margin-top: 10px;
            span {
                padding-left: 8px;
            }
            .link_icon{
                path{
                    stroke: #34C57F;
                }
            }
        }
    }


`;


const UserMenu = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;
    div{
        display: flex;
        align-items: center;
        color: #F6F6F6;
        width: 251px;
        border-radius: 12px;
    }

    .user-menu__nav-link {
        height: 40px;
        background: #fff;
        border-radius: 12px;
        border: none;
        font-weight: 500; 
        font-size: 14px;
        color: #363636;
        text-align: inherit;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 12px;
        gap: 8px;
        text-decoration: none;

        &:hover {
            background: #F5F5F5;
        }

        &.active {
            background: #34C57F;
            color: #FFFFFF;

            .cube-3d path {
                    stroke: #FFFFFF;
                }

                path{
                    fill: #FFFFFF;
                }
                
                .Icon_2{
                    path{
                        stroke: #FFFFFF;
                        fill: none;
                    } 
                }
                .Icon_3{
                    path{
                        fill: #FFFFFF;
                        stroke: #FFFFFF;
                    } 
                }
        }

    .cube-3d {
        width: 18px;
    }
    
    }
`;


export { UserPanelWrap, UserMenu, WrapProfileInfo, ScrollUserPanel }