import React, { Fragment } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { filesUrl } from "../../../API/api";
import { svg_icons } from "../../../assets/imgs/svg_icons";
import { useOpenExhibition } from "../../../hooks/useOpenExhibition";
import { ModalMessage } from "../../ModalMessage/ModalMessage";
import { AddFavoritesModalContainer } from "../AddFavoritesModal/AddFavoritesModalContainer";

import {
  WrapCompany,
  HeaderCompany,
  BannerCompany,
  AboutCompany,
  DocumentsCompany,
  ContactsCompany,
  WorkersCompany,
} from "./company.style";
import { CompanyPavilionsContainer } from "./CompanyPavilions/CompanyPavilionsContainer";

export const Company = ({ data, handlerActions, createContactsLinks, findTagInText }) => {

  const {
    siteLinkIcon, 
    triangleUp, 
    triangleDown, 
    settingsIcon, 
    pointsIcon, 
    visit3D, 
    pdfIcon,
    favoritesIcon,
    mail,
    insta,
    phone,
    vkIcon,
    facebookIcon,
    youtubeIcon,
    location,
    tiktokIcon,
} = svg_icons


  const [openModal, setOpenModal] = useState(false);
  const language = useSelector((state) => state.app.appText);
  const { company, companyFiles } = useSelector((state) => state.pavilions);
  const { userData } = useSelector((state) => state.profile);
  const openExhibitions = useOpenExhibition();
  const [openText, setOpenText] = useState(false);
  const [hoverComponent, setHoverComponent] = useState({ key: "", hover: true });

  const typeContact = (type, value) => {
    switch (type) {
      case "PHONE":
        return (
          <p className="contact_header">
            {phone} {"Телефон:"}
          </p>
        );
      case "ADDRESS":
        return (
          <p className="contact_header">
            {location} {"Адрес:"}
          </p>
        );
      case "FAX":
        return (
          <p className="contact_header">
            {phone} {"Факс:"}
          </p>
        );
      case "INSTAGRAM":
        return (
          <p className="contact_header">
            {insta} {"Instagram"}
          </p>
        );
      case "YOUTUBE":
        return (
          <p className="contact_header">
            {youtubeIcon} {"YouTube"}
          </p>
        );
      case "TIKTOK":
        return (
          <p className="contact_header">
            {tiktokIcon} {"TikTok:"}
          </p>
        );
      case "VK":
        return (
          <p className="contact_header">
            {vkIcon} {"Вконтакте:"}
          </p>
        );
      case "FACEBOOK":
        return (
          <p className="contact_header">
            {facebookIcon} {"Facebook:"}
          </p>
        );
      case "EMAIL":
        return (
          <p className="contact_header">
            {mail} {"Email:"}
          </p>
        );
        case "SITE":
          return (
          <p className="contact_header">
          {siteLinkIcon} {'Сайт:'}
          </p>
          )
      default:
        break;
    }
  };

  if (!company) return null;

  return (
    <WrapCompany>
      <HeaderCompany>
        {/*  <div className="header-logo">
          <img src={data.logo} alt="logo" />
          <h2>{data.name}</h2>
        </div>

        <div className="header-nav">
          <nav>
            <ul>
              {["О нас", "Контакты", "Павильоны"].map((nav) => (
                <li key={nav}>
                  <a href="#">{nav}</a>
                </li>
              ))}
            </ul>
          </nav>
        </div>*/}

        {/*<div className="header-btns">
          <button className="btn_boderGreen">Заявка на встречу</button>
          <a><button className="btn_backgroundGreen">Посетить 3D стенд</button></a>
        </div>*/}
      </HeaderCompany>

      <Fragment key={uuidv4("")}>
        <BannerCompany key={uuidv4("")} backgroundUrl={company.cover ? `linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.45) 100%), url(${filesUrl + company.cover})` : '#EFEFEF'}>
          <div className="banner-content">
            <div className="Logo_container">
              <img src={filesUrl + company.logo} alt="logo" />
            </div>
            <h2>{company.name[language.language]}</h2>
            <p className="content__view">
              {company.subName[language.language]}
            </p>
            <div className="buttons_company">
              {userData.firstName ? (
                <button
                  onClick={() => setOpenModal(true)}
                  className="btn_backgroundWhite"
                >
                  {favoritesIcon} <span>В избранные</span>{" "}
                </button>
              ) : null}
              <div
                onClick={() =>
                  openExhibitions({
                    pavilionId: company.pavilions[0] && company.pavilions[0].id,
                    company: company.companyNameKey,
                  })
                }
                style={{ textDecoration: "none" }}
              >
                <button className="btn_backgroundGreen">
                  {visit3D} <span>Посетить 3D стенд</span>{" "}
                </button>
              </div>
            </div>
          </div>
          {userData.firstName && openModal ? (
            <AddFavoritesModalContainer
              setOpenModal={setOpenModal}
              company={{
                id: company.id,
                name: company.name[language.language],
                logo: company.logo,
              }}
            />
          ) : null}
        </BannerCompany>

        {/*      <ProductGroup>
        <h2>Группа продукции</h2>
        <p>
          <span>Продукция данного экспанента</span>{" "}
          <span>встречается в следующих группах продукции</span>{" "}
        </p>
        <div className="group-categories">
          {[
            "Энергетика (10)",
            "Сельское хозяйство (20)",
            "Пищевые добавки (15)",
            "Пищевые добавки 2 (15)",
            "Пищевые добавки 3 (15)",
            "Пищевые добавки 4 (15)",
            "Пищевые добавки 5 (15)",
          ].map((category) => (
            <div key={category} className="category">{category}</div>
          ))}
        </div>
      </ProductGroup>*/}

        <AboutCompany key={uuidv4("")} 
          onMouseEnter={(e) => userData.role === "ROLE_ADMIN" ? setHoverComponent({ key: "company-desc", hover: true }) : e.stopPropagation()}
          onMouseLeave={(e) => userData.role === "ROLE_ADMIN" ? setHoverComponent({ key: "", hover: false }) : e.stopPropagation()}>
        {userData.role === "ROLE_ADMIN" &&
        hoverComponent.hover &&
        hoverComponent.key === "company-desc" ? (
          <div className="component_edit_wrap">
            <button
              className="component_edit_button"
              onClick={handlerActions("editCompanyInfo", "company-desc")}
            >
              {settingsIcon} Редактировать
            </button>
          </div>
        ) : null}
          <h2>О компании</h2>
          <div className="adout-desc">
          {findTagInText(company.text[language.language]) 
            ? 
            openText ?
                <div className="about_desc_html" dangerouslySetInnerHTML={{__html:company.text[language.language]}}></div>
              :
                <div className="about_desc_html" dangerouslySetInnerHTML={{__html: company.text[language.language].slice(0, 798) + '...'}}></div>
            :
                openText
            ? (<p>{company.text[language.language]}</p>)
            : (<p>{`${company.text[language.language].slice(0, 798)}...`}</p>)
          }
          </div>
          <div className="btn_wrap">
            <button
              className="read_more"
              onClick={() => setOpenText(!openText)}
            >
              {"Показать больше"} {openText ? triangleUp : triangleDown}
            </button>
          </div>
        </AboutCompany>
      </Fragment>

      {company.representatives.length !== 0 ? <WorkersCompany>
        <h2>Представители компании ({company.representatives.length})</h2>
        <p className="worker-subhead">
          <span>Вы можете написать нашим специалистам</span>{" "}
          <span>или добавить в список контактов для последующих диалогов</span>{" "}
        </p>
        <div className="workers">
          {company.representatives.map((worker) => (
            <div key={worker.id} className="company-worker backgroudGrey">
              <img src={filesUrl + worker.avatar} alt="photo_worker" />
              <div className="worker_desc">
                <p className="worker__name">
                  {worker.firstName} {worker.lastName}
                </p>
                <p className="worker__role">
                  {worker.role === "ROLE_EXHIBITOR"
                    ? "Администратор"
                    : worker.position}
                </p>
              </div>
              <button onClick={handlerActions("contextMenu", worker)}>
                {pointsIcon}
              </button>
            </div>
          ))}
        </div>
      </WorkersCompany> 
      :
      null
      }

      <div style={{position: 'relative'}} 
      onMouseEnter={() => setHoverComponent({ key: "pavilions-gallery", hover: true })}
      onMouseLeave={() => setHoverComponent({ key: "", hover: false })}
      >
      {userData.role === "ROLE_ADMIN" &&
        hoverComponent.hover &&
        hoverComponent.key === "pavilions-gallery" ? (
          <div className="component_edit_wrap">
            <button
              className="component_edit_button"
              onClick={handlerActions("editCompanyInfo", "pavilions-gallery")}
            >
              {settingsIcon} Редактировать
            </button>
          </div>
        ) : null}
      <CompanyPavilionsContainer />
      </div>

      <DocumentsCompany>
        <h2>Документы ({`${companyFiles.length}`})</h2>
        <p className="documents-subhead">
          <span>Используйте материалы экспонента</span>{" "}
          <span>для получения дополнительной информации</span>{" "}
        </p>
        <div className="documents">
          {companyFiles.map((document, i) => (
            <div key={i} className="company-document backgroudGrey">
              {pdfIcon}
              <p className="document__name">{document.name}</p>
              <a
                href={filesUrl + document.src}
                target="_blank"
                rel="noreferrer"
              >
                <button>{pointsIcon}</button>
              </a>
            </div>
          ))}
        </div>
      </DocumentsCompany>

      <ContactsCompany
        onMouseEnter={() => setHoverComponent({ key: "contacts", hover: true })}
        onMouseLeave={() => setHoverComponent({ key: "", hover: false })}
      >
        {userData.role === "ROLE_ADMIN" &&
        hoverComponent.hover &&
        hoverComponent.key === "contacts" ? (
          <div className="component_edit_wrap">
            <button
              className="component_edit_button"
              onClick={handlerActions("editCompanyInfo", "contacts")}
            >
              {settingsIcon} Редактировать
            </button>
          </div>
        ) : null}
        <h2>Контакты</h2>
        <p className="contacts-subhead">
          <span>Каналы коммуникации</span>{" "}
          <span>для взаимодействия с представителями компании</span>{" "}
        </p>
        <div className="contacts">
          {company.companyDepartments.map((department) => {
            return (
              <Fragment key={uuidv4("")}>
                {department.companyDepartmentContacts.map(
                  (contact, contactIndex) => {
                    return (
                      <Fragment key={contactIndex}>
                        <div className="company-contact backgroudGrey">
                          <div className="contact__head">
                            {typeContact(contact.type, contact.value)}
                          </div>
                          {createContactsLinks(contact)}
                        </div>
                      </Fragment>
                    );
                  }
                )}
              </Fragment>
            );
          })}
        </div>
      </ContactsCompany>
      <ModalMessage />
    </WrapCompany>
  );
};
