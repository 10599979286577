export const tibo_ru = {
    header: "Материалы, доступные для скачивания (презентации докладчиков), представленные на TIBO 2021",
    documents03_06_2021:{
        name1: 'Яцкевич О.К. Подготовка квалифицированных специалистов под потребности цифровой трансформации',
        name2: 'Соловьев С.А. Цифровая платформа управления жизненным циклом изделия и управления предприятием',
        name3: 'Скуратов А.Г. Стандарты Индустрии 4.0',
        name4: 'Рыбак А.Е. Опережающая подготовка кадров для цифрового производства',
        name5: 'Потапович А.А. Построение цифрового предприятия на баде решений ИНТЕРМЕХ',
        name6: 'Петрушин А.А. Опыт и достигнутые результаты цифровой трансформации лидеров пппромышленности',
        name7: 'Осипенко С.Ю. Цифровая трансформация маркетинга. ЕВВ ЦНИИТУ',
        name8: 'Нестеренко А.В. Цифровая трансформация ОАО «Могилевлифтмаш», проект внедрения ERP',
        name9: 'Мышко А.П. Иванов Ю.М. Викторович В.С. Цифровая трансформация задач сопровождения изделий минского автомобильного завода в промышленной эксплуатации',
        name10: 'Мухортов А.А. Построение цифрового предприятия с использованием решений ИНТЕРМЕХ МЭТЗ',
        name11: 'Мергурьев А.В. Нарежнев А.Н. Проект глобального перевооружения МЭЙКЕР, Республика Казахстан',
        name12: 'Касьяник В.В. Опыт внедрения решений в пищевое производство на основе цифрового инжиниринга',
    },
    documents04_06_2021:{
        name1: 'Холево С.А. Опыт построения современной ИС МЗКТ',
        name2: 'Хейфец М.Л. Проектирование и прменение комплексов технологического оборудрования при цифровизации традиционного и аддитивного производства',
        name3: 'Сущенко В.И. Результаты, планы и перспективы йифровой трансформации ЗАО Атлант',
        name4: 'Плебанович В.И. Инновационные цифровые решения ОАО Планар для производства современной микроэлектронной компонентной базы',
        name5: 'Насакин А. Управление матричным производством',
        name6: 'Мэйкер. Проект глобального перевооружения ТОО МЭЙКЕР, Республика Казахстан, г. Караганда, Корпорация Казахмыс.',
        name7: 'Маляров Д. Мировой опыт цифровой трансформации',
        name8: 'Козлов В.И. Практические вопросы внедрения Индустрии 4.0. при выполнении НИОКР',
        name9: 'Исаков К.А. Цифровая трансформация ООО «Уральские Локомотивы» на примере электропоезда «Ласточка»',
        name10: 'Дорош В.А. Направления развития корпоративной информсистемы',
        name11: 'Алексейчик Д.В. Система ERP как основа цифровой трансформации на примере действующего предприятия в Республике Беларусь',
    }
}