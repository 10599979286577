import { AppSwitch } from "../../../components/AppSwitch/AppSwitch"
import { AccFooter } from "../AccountFooter/AccFooter"
import { AllSettingsContent, ChatSettingsContent, 
    MeetsSettingsContent, 
    RequestsSettingsContent, 
    SettingsComponentContainer, 
    SettingsComponentWrap } from "./settings.style"


export const SettingsComponent = ({handlerActions}) => {

    return(
        <>
        <SettingsComponentContainer>
        <SettingsComponentWrap>
            <AllSettingsContent>
            <h3>Общие</h3>
            <div className="news_notification">
                <p className="settings_text">Получать уведомление о новостях</p>
                <AppSwitch/>
            </div>
            <div className="favorites_exhibition_notification">
                <p className="settings_text">Получать уведомления о изменениях информации “Избранных” экспонентов</p>
                <AppSwitch/>
            </div>
            </AllSettingsContent>
            <ChatSettingsContent>
            <h3>Чаты</h3>
            <div className="chat_message_notification">
                <p className="settings_text">Получать уведомления о новых сообщениях в чате</p>
                <AppSwitch/>
            </div>
            <div className="chat_request_notification">
                <p className="settings_text">Получать уведомления о запросе на переписку</p>
                <AppSwitch/>
            </div>
            </ChatSettingsContent>
            <RequestsSettingsContent>
            <h3>Заявки</h3>
            <div className="new_requests_notification">
                <p className="settings_text">Получать уведомления о новых заявках</p>
                <AppSwitch/>
            </div>
            <div className="discard_requests_notification">
                <p className="settings_text">Получать уведомления об отказе на заявку</p>
                <AppSwitch/>
            </div>
            <div className="success_requests_notification">
                <p className="settings_text">Получать уведомления об подтверждении заявки на встречу</p>
                <AppSwitch/>
            </div>
            </RequestsSettingsContent>
            <MeetsSettingsContent>
            <h3>Встречи</h3>
            <div className="meets_start_notification">
                <p className="settings_text">Уведомлять о начале встречи за:</p>
                <select>
                    <option>15 минут</option>
                    <option>30 минут</option>
                    <option>60 минут</option>
                </select>
            </div>
            </MeetsSettingsContent>
            <div className="form-btns">
                  <button
                    className="btns__cancel"
                    onClick={handlerActions("toAccount")}
                  >
                    Закрыть
                  </button>
                  <button className="btns__submit" type="submit">
                    Сохранить изменения
                  </button>
                </div>
        </SettingsComponentWrap>
        </SettingsComponentContainer>
        <AccFooter position = {'relative'}/>
        </>
    )
}