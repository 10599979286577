import styled from "styled-components";

const PhotoBlock = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    height: auto;
`;

const BlockContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 10px 16px;
    h3{
        width: 300px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        color: #363636;
        border-bottom: 0.5px solid #D4D4D4;
        padding-bottom: 10px;
        height: 45px;
    }
    p{
        max-width: 307px;
        color: #363636;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        padding: 10px 0;
    }
    button{
        width: 117px;
        height: 29px;
        color: #34C57F;
        border: 1.4px solid #34C57F;
        border-radius: 8px;
        cursor: pointer;
        font-family: 'Gilroy';
font-weight: 500;
font-size: 12px;
line-height: 140%;
    }
`;

export {PhotoBlock, BlockContent}