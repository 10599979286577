import styled from "styled-components";

const AdminPanelContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 16px 34px;
  button {
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    cursor: pointer;
  }
  .head_info_wrap {
    display: flex;
    justify-content: space-between;
    button {
      width: 182px;
      height: 34px;
      background: #34c57f;
      border: 1px solid #34c57f;
      border-radius: 8px;
      color: #ffffff;
      outline: none;
    }
  }
`;

const HeadText = styled.div`
  h1 {
    font-family: "Helvetica";
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    padding-bottom: 3px;
  }
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
  }
  padding-bottom: 17px;
`;

const SearchFormWrap = styled.div`
  h2 {
    font-family: "Helvetica";
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #363636;
    padding-bottom: 6px;
  }
  margin-bottom: 21px;
`;

const SearchBlockContent = styled.div`
  display: flex;
  gap: 16px;
  .wrap-app-select {
    width: 50%;
    
  }
  .filter_search {
    height: 34px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    input {
    width: 100%;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left: 6px;
    background: none;
  }
  }
  select {
    background: #f6f6f6;
    border-radius: 8px;
    height: 34px;
    width: 100%;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #484848;
    border: none;
    outline: none;
  }

  .btn_reverse_info {
    background: #f6f6f6;
    border-radius: 8px;
    border: none;
    width: 90px;
    height: 34px;
  }
  .choose_default {
    width: 400px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #34c57f;
    border-radius: 8px;
    color: #34c57f;
  }
`;

const ContentWrap = styled.div`
    width: 100%;
    height: 95%;
    margin-top: 20px;
    position: relative;
    .map_container{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    height: 95%;
    padding-right: 4px;
    }
`;

const InterfaceCard = styled.div`
  width: calc(1/4*100% - (1 - 1/5)*16px);
@media (max-width: 1500px) {
        width: calc(1/3*100% - (1 - 1/4)*16px);
}
@media (max-width: 1200px) {
        width: calc(1/2*100% - (1 - 1/3)*16px);
}
@media (max-width: 640px) {
        width: 100%;
    }
  height: 330px;
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #363636;
    padding-top: 16.5px;
  }
  .date_info {
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #363636;
    padding: 7px 0 80px;
  }
  .count_info {
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;
    color: #363636;
    padding-bottom: 60px;
  }
  .text_info {
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;
    color: #363636;
    padding-left: 10px;
  }
  button {
    width: 237.25px;
    height: 36px;
    border: 1px solid #34c57f;
    border-radius: 8px;
    color: #34c57f;
    :hover {
      background: #34c57f;
      color: #ffffff;
    }
  }
  .text_wrap {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

  }
  .imgs_wrap {
    position: relative;
    width: 65px;
    height: 36px;
    
    .Avatar_img {
      width: 32px;
      height: 32px;
      border-radius: 18px;
      &:nth-child(2) {
        transform: translateX(-50%);
    position: absolute;
      } 
      &:last-child {
        transform: translateX(0%);
      } 
    }
  }
`;

const ScrollBarWrap = styled.div`
    position: relative;
    height: inherit;
`;

export {
  AdminPanelContentWrap,
  SearchFormWrap,
  SearchBlockContent,
  HeadText,
  ContentWrap,
  InterfaceCard,
  ScrollBarWrap
};
