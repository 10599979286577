import styled from "styled-components";

const AdditionalFuncWrap = styled.div`
    position: fixed;
    max-width: 1920px;
    width: 100%;
    bottom: 0;
    background: #FFFFFF;
    z-index: 22;
    padding: 25.5px 16px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
`;

const AdditionalFuncContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

const AdditionalFuncInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 550px) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
`;

const AdditionalFuncBtns = styled.div`
    display: flex;
    gap: 10px;
    @media (max-width: 550px) {
        flex-wrap: wrap;
        align-items: center;
    justify-content: center;
    }
    button{
        width: 165px;
        height: 34px;
        cursor: pointer;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        border-radius: 8px;
    }
    .Additional_Reg{
        border: 1px solid #34C57F;
        color: #34C57F;
        background: #FFFFFF;
    }
    .Additional_Login{
        border: none;
        background: #34C57F;
        color: #FFFFFF;
    }
    .Additional_Close{
        border: 1px solid #363636;
        background: #FFFFFF;
        color: #363636;
    }
`;

const AdditionalLogo = styled.div`

`;

const AdditionalDesc = styled.div`
    h3{
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        color: #363636;
    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #363636;
    }
`;

export {AdditionalFuncWrap, AdditionalFuncContainer,
AdditionalFuncInfo, AdditionalFuncBtns,
AdditionalLogo, AdditionalDesc
}