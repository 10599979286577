import styled from "styled-components";

const AddFavoritesWrap = styled.div`
    padding: 0 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0px;
`;

const AddFavoritesLogo = styled.div`
img{
width: 40%;
}
`;

const CompanyName = styled.div`
p{
    font-family: 'Helvetica';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 17px;
color: #363636;
}
margin-bottom: 14px;
`

const AddText = styled.p`
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: #484848;
width: 80%;
margin-bottom: 26px;
`

const AddFavoritesBtns = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
width: 100%;
.Add_Company_Favorites{
    color: #34C57F;
border: 1px solid #34C57F;
border-radius: 8px;
background: #FFFFFF;
cursor: pointer;
height: 36px;
width:100%;
}
.Not_Add_Company{
    background: #34C57F;
border-radius: 8px;
color: #FFFFFF;
border: none;
cursor: pointer;
height: 36px;
width: 100%;
}
`

const SendStatusWrap = styled.div`
padding: 0 16px;
    margin: 30px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    button{
        color: #34C57F;
border: 1px solid #34C57F;
border-radius: 8px;
background: #FFFFFF;
cursor: pointer;
height: 36px;
width:100%;
    }
`
const StatusMessage = styled.p`
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: #484848;
margin-bottom: 26px;
`

export {AddFavoritesWrap, AddFavoritesLogo, 
    CompanyName, AddText,
    AddFavoritesBtns, StatusMessage, SendStatusWrap
}