import styled from "styled-components";

const ModalHandlerUserWrap = styled.div`
padding: 24px 16px 0;
`;

const UserInfo = styled.div`
width: 100%;
img{
    width: 68px;
height: 68px;
border-radius: 20px;
display: flex; 
margin: 20px auto;
}
.role_text{
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: center;
color: #484848;
padding: 9px 0 24px;
}
`

const UserFullName = styled.div`
width: 100%;
display: flex;
justify-content: center;
gap: 5px;
p{
    font-weight: 600;
font-size: 14px;
line-height: 17px;
color: #363636;
}
`;

const AboutUser = styled.div`
width: 100%;
background: #F5F5F5;
border-radius: 4px;
padding: 15px 12px;
margin-bottom: 23px;
div{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .head_text{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
    }
    .user_about{
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
    }
}
    .date_reg{
        padding-bottom: 14px;
        border-bottom: 0.5px solid #D4D4D4;
    }
    .email_user{
        padding: 14px 0;
    }
    .phone_user{
        padding-top: 14px;
        border-top: 0.5px solid #D4D4D4;
    }
`

const UserRole = styled.div`
margin-bottom: 25px;
.role_head_text{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
}
select{
    background: #F5F5F5;
    border-radius: 4px;
    height: 36px;
    outline: none;
    border: none;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    width: 100%;
}
`;

const CompanyUser = styled.div`
position: relative;
margin-bottom: 25px;
.expanents_head_text{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
}
.expanent_select{
    position: relative;
    width: 100%;
    height: 36px;
    background: #F5F5F5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0 12px;
input{
outline: none;
border: none;
background: #F5F5F5;
width: 100%;
height: 30px;
border-radius: 10px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: #484848;
::placeholder{
    color: #484848;
}
}
}
            .expanents_container{
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1;
            overflow: auto;
            overflow-x: hidden;
            padding: 10px 6px 10px 12px;
            width: 100%;
            background: #FFFFFF;
            position: absolute;
            box-shadow: 0px 14px 30px rgba(0,0,0,0.35);
            border-radius: 0px 0px 4px 4px;
            z-index: 2;
            height: 120px;
            .expanent_card{
                background: #f5f5f5;
                text-align: start;
                padding: 12px;
                border: none;
                word-break: break-word;
                cursor: pointer;
                margin-right: 4px;
                p{
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 140%;
                }
            }
            }
`;

const NoteContent = styled.div`
margin-bottom: 30px;
p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
}
textarea{
    background: #F5F5F5;
    border-radius: 4px;
    height: 108px;
    outline: none;
    border: none;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    width: 100%;
    resize: none;
}
`

const ControlBtns = styled.div`
display: flex;
gap: 10px;
margin-bottom: 30px;
    button{
        width: 100%;
        height: 36px;
        border-radius: 8px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        cursor: pointer;
    }
    .Back{
        color: #34C57F;
        border: 1px solid #34C57F;
        background: none;
    }
    .Send{
        color: #FFFFFF;
        background: #34C57F;
        border: none
    }
`;
export {
    ModalHandlerUserWrap,
    UserInfo,
    UserFullName,
    AboutUser,
    UserRole,
    CompanyUser,
    NoteContent,
    ControlBtns
}