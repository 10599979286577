import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scrollTo } from './../utils/helpers';
import { setAppChatState, setNewMessage } from "../store/appChatSlice/appChatSlice";
import { serverWebsocketUrl } from "../API/api";
import { getDialogs } from "../store/appChatSlice/appChatExtraReducers";

const useWebSocket = () => {

  const dispatch = useDispatch();
  const wsClient = useSelector(state => state.appChat.wsClient);
  let token = useSelector(state => state.auth.token);
  let userData = useSelector(state => state.profile.userData);
  const clientRef = useRef(wsClient);

  useEffect(() => {

    if(!wsClient && userData?.id && token) {

      const reconnect = () => {
        setTimeout(() => {
          dispatch(setAppChatState({key: "wsClient", value: null}));
        }, 1500);
      }

      clientRef.current = new WebSocket(`${serverWebsocketUrl}/${token}`);

      clientRef.current.onerror = (e) => {
        reconnect();
      }

      clientRef.current.onopen = async () => {
        dispatch(setAppChatState({key: "wsClient", value: clientRef.current}));
        dispatch(getDialogs());
        console.log('ws opened')
      }

      clientRef.current.onclose = () => {
        reconnect();
        console.log("ws close")
      }

      clientRef.current.onmessage = async (e) => {

        console.log(JSON.parse(e.data || 0))

        let {payload = {}, messageTypeId} = JSON.parse(e.data || 0);

        switch (+messageTypeId) {
          case 1:
            dispatch(setNewMessage({value: payload}));
            scrollTo("scrollMessEnd", 0.5);
            break;
        
          default:
            break;
        }

    }



    }

    return () => wsClient && wsClient.close()


  }, [wsClient, token, userData?.id]);

  return clientRef.current;
};

export default useWebSocket;
