import styled, { css } from "styled-components";


const TabsContext = styled.div`

`;


const WrapTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;


const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 1px;
  width: 182px;
  height: 36px;
  background: #efefef;
  backdrop-filter: blur(15px);
  border-radius: 16px;
  color: #363636;
  cursor: pointer;
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;
    padding-left: 8px;
  }
  .security-icon path {
    fill: rgb(54, 54, 54);
    stroke: rgb(54, 54, 54);
  }
  ${({ active }) =>
    active &&
    css`
      background: #34c57f;
      color: #ffffff;
      svg path {
        fill: #ffffff;
      }
      .security-icon path {
        fill: #ffffff;
        stroke: #ffffff;
      }
      .Settings_User_Icon path{
        stroke: #ffffff;
      }
    `}
`;


export  {
  TabsContext,
  WrapTabs,
  Tab,
}
