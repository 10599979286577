import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { AccFooter } from "./AccountFooter/AccFooter";
import { Admin } from "./Admin/Admin";
import { SettingsContainer } from "./Settings/SettingsContainer";
import { UserContentBlock, WrapPanelContent } from "./UserContent/style/panelContent_css";
import { UserPanel } from "./UserPanel/UserPanel";


export const Account = () => {

  const { content } = useParams();
  const { userData } = useSelector((state) => state.profile);
  const exhibitor = useSelector((state) => state.exhibitor)

  return (
    <>
      {content === "settings" ? (
        <SettingsContainer />
      ) : (
        userData.role !== 'ROLE_ADMIN' ? 
        <UserContentBlock>
          {exhibitor.content === 'main' || content !== 'page-exhibitor' ? <UserPanel /> : null}
          <WrapPanelContent>
            <Outlet />
          </WrapPanelContent>
          <AccFooter />
        </UserContentBlock>
      :
      <Admin />
      )}
    </>
  );
};