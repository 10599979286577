import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useOpenExhibition } from "../../../../hooks/useOpenExhibition";
import { getShortName } from "../../../../utils/helpers";
import { WrapPavilions } from "./companyPavilions.styles";
import { PreviewMaterialsContainer } from "./PreviewMaterials/PreviewMaterialsContainer";
import Metalworking from "../../../../assets/Components/PavilionsSlider/images/Металлообработка.webp";
import Instrumentation from "../../../../assets/Components/PavilionsSlider/images/Приборостроение.webp";
import MechanicalEngineering from "../../../../assets/Components/PavilionsSlider/images/Машиностроение.jpg";
import HouseholdAppliances from "../../../../assets/Components/PavilionsSlider/images/БытоваяТехника.webp";
import VDNX from "../../../../assets/Components/PavilionsSlider/images/ВДНХ.webp";
import TIBO2024 from "../../../../assets/Components/PavilionsSlider/images/TIBO2024.webp";

const setPavilionImg = (nameKey) => {
switch (nameKey) {
    case "Metalworking":
      return Metalworking;
    case "Instrumentation":
      return Instrumentation;
    case "MechanicalEngineering":
      return MechanicalEngineering;
    case "HouseholdAppliances":
      return HouseholdAppliances;
    case "Vdnh":
      return VDNX;
    case "Tibo":
      return TIBO2024
    default:
      break;
  }
}

export const CompanyPavilions = ({
  totalData,
  openPreview,
  handlerOpenPreview,
}) => {
  const [PavilionId, setPavilionId] = useState(0)


  const {companyPavilions, company} = useSelector((state) => state.pavilions)
  const language = useSelector((state) => state.app.appText);
  const openExhibitions = useOpenExhibition();

  const ClickMaterials = (id) =>{
    handlerOpenPreview()
    setPavilionId(id)
  }

  return (
    <WrapPavilions>
      <h2 className="wrap__header">
        {totalData.head.ru} ({companyPavilions.length})
      </h2>
      <p className="wrap__subheader">{totalData.subhead.ru}</p>

      <div className="pavilions">
      {companyPavilions.map((pavilion, index) => {
        return (
        <div className="pavilion" key={index}>
            <img
              src={setPavilionImg(pavilion.nameKey)}
              className="pavilion__img"
              alt={`img-${pavilion.name}`}
            />
            <div className="pavilion-desc">
              <h3 className="desc__header">{'Павильон: '}</h3>
              <p className="desc__name">{pavilion[getShortName(language.language)]}</p>

              <div className="divider"></div>

              <div className="desc-action">
                <p className="action__updated">
                  <span>{totalData.desc.updated.ru}</span> {totalData.pavilions[0].updated}
                </p>
                <div className="action__view">
              {/*    {eye}{" "}
                  <p>
                    {pavilion.view} {totalData.desc.count.ru}
                  </p>*/}
                </div>
              </div>
              <div className="divider"></div>

              <div className="desc-btns">
                <button
                  className="btns__materials"
                  onClick={() => ClickMaterials(pavilion.id)}
                >
                  {totalData.desc.btns.materials.ru}
                </button>
                <div style={{textDecoration: 'none'}} 
                 onClick={() => openExhibitions({pavilionId: pavilion.id, company: company.companyNameKey})}
                >
                <button className="btns__3D-stand">
                  {totalData.desc.btns.stand3D.ru}
                </button>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      </div>
      {openPreview && (
        <PreviewMaterialsContainer PavilionId={PavilionId} handlerOpenPreview={handlerOpenPreview} />
      )}
    </WrapPavilions>
  );
};
