import styled, { css } from 'styled-components';

const WrapLeftBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 100%;
    padding: 16px;
    border-right: 0.5px solid #D4D4D4;

    .box__add-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        background: #34C57F;
        border: 1.4px solid #34C57F;
        border-radius: 8px;
        margin-top: 16px;
        cursor: pointer;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        & > svg {
            margin-right: 10px;
            path {
                fill: #FFFFFF;
            }
        }
    }

    //mobile
    ${({mobile}) => mobile && css`
        width: 100%;
        border-right: none;
    `}
`

export {
    WrapLeftBox
};