import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./profileReducers";
import { extraReducers } from "./profileExtraReducers";

export const initialState = {
    table: {
      data: null,
      filter: {
        search: "",
      },
    },
    auth: {},
    success: '',
    LoginUser: {},
    errors: [],
    activateEmail: '',
    userData: {},
    statistics: null,
    favoritesCompany: [],
    documents: [],
    contactsAll: [],
    contactsOnline: [],
    requestsMeetings: [],
    approvedMeetings: [],
    statusSend: '',
    message: '',
    errorMessage: '',
    openModal: false,
};

export const ProfileSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers,
  extraReducers
});

export const {resetProfileState, setProfileState, resetDocuments, setProfileTableState} = ProfileSlice.actions;


export const ProfileReducer = ProfileSlice.reducer;