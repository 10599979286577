import { createAdminNews, deleteAdminNews, deleteNewsMedia, getAdminNews, GetNews, GetSelectNews, makeMainNews, updateNews, updateNewsMedia, updateNewsShow, updatePreview } from "./newsAsyncThunk"

export const extraReducers = (builder) => {
    builder.addCase(
        GetNews.fulfilled(), (state, {payload}) => {
            state.news = payload
        },
        GetNews.rejected(), (state, {payload}) => {
            state.errors = payload
        },
    )
    builder.addCase(
        GetSelectNews.fulfilled(), (state, {payload}) => {
            state.selectNews = payload
        },
        GetSelectNews.rejected(), (state, {payload}) => {
            state.errors = payload
        }
    )
    builder.addCase(
        getAdminNews.fulfilled(), (state, {payload}) => {
            state.news = payload
        }
    )
    builder.addCase(
        createAdminNews.fulfilled(), (state, {payload}) => {
            console.log(payload)
            if(payload.paramUrl === 'drafts-news' && payload.response.draft){
                state.news.unshift(payload.response)
            }else if(payload.paramUrl === 'all-news' && !payload.response.draft && !payload.response.mainNews){
                state.news.unshift(payload.response)
            }else if(payload.paramUrl === 'main-news' && payload.response.mainNews){
                state.news.unshift(payload.response)
            }
        }
    )
    builder.addCase(
        deleteAdminNews.fulfilled(), (state, {payload}) => {
            state.news = state.news.filter((item) => item.id !== payload.newsId)
        }
    )
    builder.addCase(
        updateNewsShow.fulfilled(), (state, {payload}) => {
            state.news = state.news.map((item) => item.id === payload.id ? payload : item)
        }
    )
    builder.addCase(makeMainNews.fulfilled(), (state, { payload }) => {
      state.news = state.news.map((item) =>
        item.id === payload.id ? payload : item
      );
    });
    builder.addCase(updateNews.fulfilled(), (state, { payload }) => {
      state.news = state.news.map((item) =>
        item.id === payload.id ? ({...payload, media: item.media}) : item
      );
    });
    builder.addCase(
        deleteNewsMedia.fulfilled(), (state, {payload}) => {
            state.createNews.media = state.createNews.media.filter((item) => item.id !== payload.id)
        }
    )
    builder.addCase(updateNewsMedia.fulfilled(), (state, {payload}) => {
        state.news = state.news.map((item) =>
        item.id === payload.id ? payload : item
      );
    })
    builder.addCase(updatePreview.fulfilled(), (state, {payload}) => {
        state.news = state.news.map((item) => item.id === payload.response.id ? payload.response : item)
        state.createNews.media = state.createNews.media.map((el) => el.id === payload.mediaId ? ({...el, preview: !el.preview}) : el)
    })
}