export const modals_ru = {
    block: {
        chatH1: "Блокировка чата",
        userH1: "Блокировка",
        beBlocked: "будет заблокирован(a)",
        cantSendMes: "и не сможет отправлять вам сообщения",
        yesBlock: "Да, заблокировать",
    },
    unblock: {
        chatH1: "Разблокировка чата",
        userH1: "Разблокировка",
        beUnblocked: "будет разблокирован(a)",
        canSendMes: "и сможет отправлять вам сообщения",
        yesUnblock: "Да, разблокировать",
    },
    removeContact: {
        head: "Удалить контакт",
        beRemoved: "будет удален(a) из списка",
        yourContacts: "ваших контактов",
        noRemove: "Нет, оставить",
        yesRemove: "Да, удалить",
    },
    removeChat: {
        head: "Удалить чат",
        subhead1: "Желаете удалить этот чат? История переписки будет",
        subhead2: "удалена для вас и вашего собеседника",
        noRemove: "Нет, оставить",
        yesRemove: "Да, удалить",
    },
    cancel: "Отменить",
}