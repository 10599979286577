export const mainPage_ru = {
    partners: {
        head : "Наши партнёры",
        subhead : "Рады представить наших партнёров в реализации проекта виртуальной выставки",
        list: {
            msHead: "Министерство связи и информатизации РБ",
            mpHead: "Министерство промышленности РБ",
            cniituHead: "ОАО ЦНИИТУ",
            mgtpHead: "Минский городской технопарк",
            naiHead: "Национальное агентство инвестиций и приватизации",
            belisaHead: "ГУ «БелИСА»",
            interExpo: "Унитарное предприятие «БЕЛИНТЕРЭКСПО»"
        },
    },
    joinUs: {
        head: "Ещё больше возможностей!",
        subhead: "Присоединись к CleVR и открой для себя возможности виртуального бизнеса! Создавай стенды, добавляй каталоги продукции и общайся с потенциальными заказчиками.",
        join: "Присоединиться",
    },
    footer: {
        news: {
            head: "Новости",
            link1: "Главные новости",
            link2: "Все новости",
        },
        exhibition: {
            head: "Выставка",
            link1: "Группы продукции",
            link2: "Павильоны",
            link3: "Экспоненты",
        },
        follow: {
            head: "Участие",
            link1: "Преимущества",
            link2: "Фотоуслуги",
            link3: "Дизайнерские услуги",
            link4: "Стать участником",
        },
        info: {
            head: "Информация",
            link1: "Инструкция",
            link2: "Контакты",
        },
        mobileApp: {
            head: "Мобильное приложение",
            link1: "Версия для IOS",
            link2: "Версия для Android",
            btnIOS: "CleVR для IOS",
            btnAndroind: "CleVR для Android"
        },
        policy: "Политика конфиденциальности",
        contract: "Договор оферты",
        support: "Техподдержка",
        rights: "Все права защищены v. 1.2.1",
    },
    floatLogin: {
        head: "Еще больше возможностей!",
        subhead: "Выполните вход на сайт и откройте все возможности c CleVR",
        registr: "Зарегистрироваться",
        login: "Войти в аккаунт",
        close: "Закрыть",
    },
}