const reducers = {
setExhibitorLangObj(state, {payload}){
    state[payload.key] = {...state[payload.key], [state.language]: payload.value}
},
setInitValues(state, {payload}){
    state[payload.key] = payload.value
},
setInitFileValues(state, {payload}){
    state[payload.key] = {...state[payload.key], path: payload.value}
},
setOpenSettings(state, {payload}){
    state.openSettings = {...payload}
},
setExhibitorLanguage(state, {payload}){
    state.language = payload
},
setExhibitorFile(state, {payload}){
    state[payload.key] = {file: payload.value, path: payload.value === '' ? '' : URL.createObjectURL(payload.value)}
},
setExhibitorContent(state, {payload}){
    state.content = payload
},
setGalleryFiles(state, {payload}){
    state.gallery[payload.key] = {...state.gallery.image, file: payload.value}
},
resetGallery(state, {payload}){
    state.gallery = {...state.gallery, [payload.key]: payload.value}
},
setGalleryInfo(state, {payload}){
    state.gallery[payload.key] = {...state.gallery[payload.key], [payload.infoKey]: payload.value}
},
setConsult(state, {payload}){
    state.gallery[payload.key] = {...state.gallery[payload.key], consultation: payload.value}
},
setGalleryItems(state, {payload}){
    state.newGalleryItems.push(state.gallery[payload.key])  
},
deleteMediaGallery(state, {payload}){
    state.newGalleryItems = state.newGalleryItems.map((item, i) => 
    item.type === payload.type && i === payload.key ? ({...item, remove: true}) : item)
},
editMediaGallery(state, {payload}){
    state.newGalleryItems = state.newGalleryItems.map((item, index) =>{
        if(item.id === payload.id && payload.type === item.type){ 
            return {
            ...payload, update: true
        }
    }else if(payload.id === 'none' && index === payload.itemIndex){
        let {name, 
            description, 
            consultation, 
            file,
            type,
            src
        } = payload
        return {
            name,
            description,
            type,
            consultation,
            file,
            src
        }
    }
        return item
    })
},
resetStateList(state, {payload}){
    state[payload.key] = []
},
setRepresentatives(state, {payload}){
    state.representatives = payload
},
addRepresentatives(state, {payload}){
    state.representatives.push(payload)
},
deleteRepresentatives(state, {payload}){
    if(payload.userId){
        state.representatives = state.representatives.map((item) => item.id === payload.id ? ({...item, remove: true }) : item)
    }else{
        state.representatives = state.representatives.filter((item) => item.id !== payload.id)
    }
},
changeVisibilityRep(state, {payload}){
    if(payload.userId){
        state.representatives = state.representatives.map((item) => item.id === payload.id ?
        ({...item, visibility: !payload.visibility, update: !payload.update}) : item)
    }else{
        state.representatives = state.representatives.map((item) => item.id === payload.id ? 
        ({...item, visibility: !payload.visibility}) : item)
    }
},
addDocument(state, {payload}){
state.companyDocuments.push(payload)
},
setShowDocuments(state, {payload}){
    state.companyDocuments = state.companyDocuments.map((item, index) => {
        if(payload.id && item.id === payload.id){
            return {...item, update: !payload.update, show: payload.show}
        }else if(!payload.id && payload.index === index){
            return {...item, show: payload.show}
        }else{
            return item
        }
    })
},
deleteDocuments(state, {payload}){
    if(payload.id){
        state.companyDocuments = state.companyDocuments.map((item) => item.id === payload.id ? ({...item, remove: true}) : item)
    }else{
        state.companyDocuments = state.companyDocuments.filter((item, index) => index !== payload.index)
    }
},
setDepartment(state, {payload}){
    state.companyDepartments.push(payload)
},
setDepartmentContact(state, {payload}){
state.companyDepartments = state.companyDepartments.map((department) => department.id === payload.departmentId 
? ({...department, 
    companyDepartmentContacts: [...department.companyDepartmentContacts, {type: payload.contact.name, value: payload.contact.link}]}) 
    : 
    department)
},
deleteDepartmentContact(state, {payload}){
    state.companyDepartments = state.companyDepartments.map((department) => department.id === payload.departmentId 
    ?   
    ({...department, 
        companyDepartmentContacts: payload.id ?
        department.companyDepartmentContacts.map((contact) => contact.id === payload.id 
        ? ({...contact, remove: true }) 
        : contact ) 
        : 
        department.companyDepartmentContacts.filter((contact, index) => index !== payload.index)
    }) 
    :
    department
    )
},
updateDepartmentContact(state, {payload}){
    console.log(payload)
    state.companyDepartments = state.companyDepartments.map((department) => department.id === payload.departmentId 
    ?   
    ({...department, 
        companyDepartmentContacts: payload.contact.id ?
        department.companyDepartmentContacts.map((contact) => contact.id === payload.contact.id 
        ? ({...contact, value: payload.contact.link, type: payload.contact.name, update: true }) 
        : contact ) 
        : 
        department.companyDepartmentContacts.map((contact, index) => index === payload.contact.index ? 
        ({...contact, value: payload.contact.link, type: payload.contact.name}) : 
        contact)
    }) 
    :
    department
    )
}
}


export default reducers