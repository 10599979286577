import { Fragment } from "react"
import { useState } from "react"
import { svg_icons } from "../../../../../assets/imgs/svg_icons"
import { AddProductGroup } from "../AddProductGroup/AddProductGroup"
import { HintWrap } from "../AdminCatalog.style"
import { ProductsGroup } from "../ProductsGroup/ProductsGroup"
import { 
    NameSubcategoryText, 
    SubcategoriesWrap, 
    SubCategoryBtnsWrap, 
    SubCategoryCard 
} 
from "./subCategory.style"


export const SubCategory = ({subCategories}) => {

    const {
        addIcon, 
        settingsIcon, 
        trashIcon, 
        uncoverIcon, 
        uncoverUpIcon
    } = svg_icons
    const [addModalOpen, setModalOpen] = useState({key: '', open: false})
    const [showProducts, setShowProducts] = useState({key: '', open: false})

    return (
        <SubcategoriesWrap>
        {subCategories.map((subcategory, index) => {
            return (
                <Fragment key={index}>
                <SubCategoryCard>
                <div className="subcategory_head_wrap">
                <button className="Uncover_btn" onClick={() => setShowProducts({key: index, open: !showProducts.open})}>
                {showProducts.open && showProducts.key === index ? uncoverUpIcon : uncoverIcon}
                </button>
                <NameSubcategoryText>
                    <p className="head_name">{'Подкатегория -'}</p>
                    <p className="subcategory_head_text">{subcategory.name}</p>
                </NameSubcategoryText>
                </div>
                <SubCategoryBtnsWrap>
                    <button className="btn_add_icon" onClick={() => setModalOpen({key: index, open: true})}>
                        {addIcon}
                        <HintWrap>
                            <p>{'Добавить группу продукции'}</p>
                        </HintWrap>
                    </button>
                    <button>{settingsIcon}</button>
                    <button className="btn_basket">{trashIcon}</button>
                </SubCategoryBtnsWrap>
                {addModalOpen.key === index && addModalOpen.open ? <AddProductGroup setModalOpen={setModalOpen} subcategoryName={subcategory.name} /> : null}
                </SubCategoryCard>
                {showProducts.key === index && showProducts.open && subcategory?.productsGroup ? <ProductsGroup settings={''} productsGroup={subcategory.productsGroup}/> : null}
                </Fragment>
            )
        })}
        </SubcategoriesWrap>
    )
}