import React from "react";
import * as s_c from './style/services_css'
import * as b_s_c from '../Benefits/style/benefits_css'
import { PhotoServices } from "./PhotoServices/PhotoServices";
import photo1 from '../../../assets/Components/FollowClevr/Photoservices/Портретная.jpg'
import photo2 from '../../../assets/Components/FollowClevr/Photoservices/Объектная съемка.jpg'
import photo3 from '../../../assets/Components/FollowClevr/Photoservices/Производственная съемка.jpg'
import design1 from '../../../assets/Components/FollowClevr/DesignServices/Визуализация 3Д продукчции.jpg'
import design2 from '../../../assets/Components/FollowClevr/DesignServices/Индивидуальная страница.jpg'
import design3 from '../../../assets/Components/FollowClevr/DesignServices/Индивидуальный стенд.jpg'


export const Services = ({localText}) => {

    return (
    <s_c.ServicesSection>
        <b_s_c.BenefitsHead>
            <h2>{localText.photoHead}</h2>
            <p>{localText.photoSubhead}</p>
        </b_s_c.BenefitsHead>
        <s_c.PhotoServicesBlock>
            <PhotoServices img={photo2} header={localText.photoBlockH1} subhead={localText.photoBlockSubH1}/>
            <PhotoServices img={photo3} header={localText.photoBlockH2} subhead={localText.photoBlockSubH2}/>
            <PhotoServices img={photo1} header={localText.photoBlockH3} subhead={localText.photoBlockSubH3}/>
        </s_c.PhotoServicesBlock>
        <b_s_c.BenefitsHead>
            <h2>{localText.designerHead}</h2>
            <p>{localText.designerSubhead}</p>
        </b_s_c.BenefitsHead>
        <s_c.PhotoServicesBlock>
            <PhotoServices img={design1} header={localText.designerBlockH1} subhead={localText.designerBlockSubH1}/>
            <PhotoServices img={design3} header={localText.designerBlockH2} subhead={localText.designerBlockSubH2}/>
            <PhotoServices img={design2} header={localText.designerBlockH3} subhead={localText.designerBlockSubH3}/>
        </s_c.PhotoServicesBlock>
    </s_c.ServicesSection>
    )
}