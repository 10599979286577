import React, { useEffect, useMemo, useState } from "react";
import { WrapAppDatePicker } from "./appDatePicker.style";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";

const AppDatePicker = ({ datePickerRef = {}, type }) => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.app.modal);
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(
    datePickerRef.current?.start || new Date()
  );
  const [endDate, setEndDate] = useState(
    datePickerRef.current?.end || new Date(new Date(Date.now() + 86400000 * 7))
  );
  let defProps = {
    calendarClassName: "picker__calendar",
    className: "picker__input",
    dateFormat: "dd-MM-yyyy",
    disabledKeyboardNavigation: true
  };

  const getDatePicker = () => {
    switch (type) {
      case "range":
        return (
          <div className="picker-range">
            <DatePicker
              {...defProps}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              startDate={startDate}
              endDate={endDate}
              locale={ru}
            />
            <p className="range__divider">-</p>
            <DatePicker
              {...defProps}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              locale={ru}
            />
          </div>
        );

      default:
        return (
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        );
    }
  };

  useEffect(() => {
    if (startDate && endDate)
      datePickerRef.current = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
  }, [startDate, endDate]);

  let datePickerMemo = useMemo(() => getDatePicker(), [startDate, endDate]);

  return <WrapAppDatePicker>{datePickerMemo}</WrapAppDatePicker>;
};

export default AppDatePicker;
