import React from "react";
import { WrapLeftBox } from "./leftBox.style";
import HeaderLeftBoxContainer from './HeaderLeftBox/HeaderLeftBoxContainer';
import DialogsContainer from "./Dialogs/DialogsContainer";
import { svg_icons } from "../../../assets/imgs/svg_icons";



const LeftBox = ({mobileVersion, handlerActions}) => {
    const {plusInCircle} = svg_icons

    return <WrapLeftBox  mobile={mobileVersion}>
            <HeaderLeftBoxContainer/>
            <DialogsContainer mobileVersion={mobileVersion}/>
            <button className="box__add-dialog" onClick={handlerActions("addDialog")}>{plusInCircle} Добавить новый чат</button>
    </WrapLeftBox>
}

export default LeftBox;