import { svg_icons } from "../../../../../assets/imgs/svg_icons"
import { NameProductText, ProductBtnsWrap, ProductCard, ProductsGroupWrap } from "./ProductsGroup.style"

export const  ProductsGroup = ({productsGroup}) => {

    const {
        settingsIcon, 
        trashIcon, 
    } = svg_icons

return (
    <ProductsGroupWrap>
        {productsGroup.map((product, index) => {
            return (
                <ProductCard key={index}>
                <div className="product_head_wrap">
                <div className="Uncover_btn"></div>
                <NameProductText>
                    <p className="head_name">{'Группа продукции -'}</p>
                    <p className="product_head_text">{product.name}</p>
                </NameProductText>
                </div>
                <ProductBtnsWrap>
                    <button>{settingsIcon}</button>
                    <button className="btn_basket">{trashIcon}</button>
                </ProductBtnsWrap>
                </ProductCard>
            )
        })}
    </ProductsGroupWrap>
)
}