
import styled from 'styled-components';

const DropMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 32px;
    @media (max-width: 768px) {
        flex-direction: unset;
    }
`

const DropOption = styled.option`
    border:none;
    background: unset;
    cursor: pointer;
    width: 32px;
    font-size: 16px;
    transition: 0.4s;
        img {
          width: 20px;
          height: 20px;
        }
        :hover {
            color:rgb(0, 170, 80);
            transition: 0.4s;
        }
        
`



export {DropMenu, DropOption};