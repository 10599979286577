import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filesUrl } from "../../../../../API/api";
import { AdminGetUsers, GetAdminExpanents, GetRoles } from "../../../../../store/adminSlice/adminAsyncThunk";
import { icons_svg } from "../../../../Exhibition/Company/imgs/icons.svg";
import { AdminHead } from "../../AdminHead/AdminHead";
import { ScrollBarWrap } from "../../AdminPanelContent/AdminPanelContent.style";
import { SearchSortContent } from "../../SearchSortContent/SearchSortContent";
import { ModalHandlerUser } from "../ModalHandlerUser/ModalHandlerUser";
import {
  AllUsersContainer,
  AllUsersWrap,
  FullName,
  UserAllInfo,
  UserCard,
  UserData,
  UserRegDate,
  UserSettings,
} from "./AllUsers.style";
import { setAppState } from "../../../../../store/appSlice/appSlice";
import { svg_icons } from "../../../../../assets/imgs/svg_icons";
import { dateToLocaleDateString } from "../../../../../utils/helpers";

export const AllUsers = () => {
  const { dotsIcons } = icons_svg;
  const { settingsIcon, chatsIcon, trashIcon, blockIcon } = svg_icons;
  const [openModals, setOpenModals] = useState({key: '', open: false, userId: ''})
  const {appText} = useSelector((state) => state.app)
  const {users, roles} = useSelector((state) => state.admin)
  const [localeState, setLocalState] = useState({
    searchUser: '',
    searchCompany: '',
    company: {},
    focus: false,
    note: '',
    rolesSettings: {
        options:[
            {id: 0, value: 'Выберите роль'}
        ]
    },
    user: {}
  }) 
  console.log(localeState)

  const showRoles = (role) => {
    switch (role) {
        case 'ROLE_USER':
            return 'Пользователь'
        case 'ROLE_ADMIN':
            return 'Администратор'
        case 'ROLE_EXHIBITOR':
            return 'Администратор экспонента'
        case 'ROLE_REPRESENTATIVE':
            return 'Представитель экспонента'
        default:
            return 'Посетитель'
    }
}

  const handlerActions = (key, data) => (e) => {
    switch (key) {
        case "setSearchUser":
            setLocalState((state) => ({...state, searchUser: e.target.value}))
            break;
        case "contextMenu":
          dispatch(setAppState({contextMenu: {
              currentTarget: e.currentTarget,
              listMenu: [
                { onClick: () => handlerOpenModals('control', data), icon: settingsIcon, text: "manamgeAndConfig" },
                { onClick: () => {}, icon: chatsIcon, text: "writeMes", },
                { onClick: () => {}, icon: blockIcon, text: "block", },
                { onClick: () => {}, icon: trashIcon, text: "removeUser", class: " error",},
              ]
            }}))
          break;
        case "setSearchCompany":
            setLocalState((state) => ({...state, searchCompany: e.target.value}))
            break;
        case "setFocus":
            setLocalState((state) => ({...state, focus: !state.focus}))
            break;
        case "setCompanySelect":
            setLocalState((state) => ({...state, company: data, searchCompany: data.name[appText.language], focus: false}))
            break;
        default:
            break;
    }
  }

  //[roles.find((item) => item.name === user.role), ]


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AdminGetUsers(localeState.searchUser))
  }, [dispatch, localeState.searchUser])

  useEffect(() => {
    dispatch(GetAdminExpanents(localeState.searchCompany))
  }, [dispatch, localeState.searchCompany])

  useEffect(() => {
    dispatch(GetRoles())
  }, [dispatch])

  const handlerOpenModals = (key, user) => {
    console.log(user)
    switch (key) {
        case 'control':
            setLocalState((state) => (
                {...state, 
                    user, 
                    rolesSettings: {
                        options: [{id: roles.find((item) => item.name === user.role).id, value: showRoles(roles.find((item) => item.name === user.role).name)},
                        ...roles.map((role) => ({id: role.id, value: showRoles(role.name)})).filter((el) => el.value !== showRoles(user.role))
                        ]
                    }
                }
            ))
            break;
        default:
            break;
    }
    setOpenModals({key: key, open: true, userId: user.id})
  }

  return (
    <AllUsersWrap>
      <AdminHead
        title={`Пользователи (${users.length})`}
        desc={"Здесь вы можете отредактировать роли и права пользователей"}
      />
      <SearchSortContent page={'all-users'} inputChange={handlerActions("setSearchUser")} />
      <AllUsersContainer>
        <ScrollBarWrap>
          <div className="map_container">
            {users.map((user, index) => {
              return (
                <UserCard key={index}>
                  <div className="onePart_block">
                    <img
                      src={filesUrl + user?.avatar}
                      className="admin-panel_user_avatar"
                      alt="avatar"
                    />
                    <UserData>
                      <FullName>
                        <p>{user?.firstName}</p>
                        <p>{user?.lastName}</p>
                      </FullName>
                      <UserAllInfo>
                        <p className="user_role">{showRoles(user?.role)}</p>
                        <p className="user_id">{'ID'}{' '}{user?.id}</p>
                      </UserAllInfo>
                    </UserData>
                  </div>
                  <div className="secondPart_block">
                    <UserRegDate>
                      <p className="text_date">{"Дата регистрации:"}</p>
                      <p className="reg_date">{dateToLocaleDateString(user.createTime)}</p>
                    </UserRegDate>
                   {/* {user.companyName ?
                    <UserCompanyInfo>
                      <p className="text_company">{"Предприятие:"}</p>
                      <p className="company_name">{user.companyName}</p>
                    </UserCompanyInfo>
                    : null
                    }*/}
                    <UserSettings>
                      <button
                        className="user_dots"
                        onClick={handlerActions("contextMenu", user)}
                      >
                        {dotsIcons}
                      </button>
                    </UserSettings>
                  </div>
                  {user.id === openModals.userId  && openModals.open === true ? 
                  <ModalHandlerUser showRoles={showRoles} setOpenModals={setOpenModals} handlerActions = {handlerActions} localeState={localeState}/> : null}
                </UserCard>
              );
            })}
          </div>
        </ScrollBarWrap>
      </AllUsersContainer>
    </AllUsersWrap>
  );
};
