import styled from "styled-components";

const MeetsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
  h2{
    font-family: 'Helvetica';
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
}
`;

const SelectAllItem = styled.select`
    width: 33%;
    max-width: 230px;
    height: 34px;
    background: #F6F6F6;
    border: none;
    border-radius: 10px;
    padding: 10px 12px;
    outline: none;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #484848;
    option{
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }
`;


const SearchContent = styled.div`
    display: flex;
    padding: 16px 0;
    .filter-search {
        width: 100%;
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: #F6F6F6;
        height: 34px;
        background: #f6f6f6;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        order: 1;
        margin-left: auto;
        max-width: 606px;
        flex: 1;
    
        input {
          width: 100%;
          border-radius: 10px;
          background: #f6f6f6;
          border: none;
          outline: none;
          padding-left: 6px;
          font-family: 'Gilroy';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
        }
      }
    .app-select {
        height: 34px;
        min-width: 195px;
        margin-right: 50px;
        width: auto;
    }
`;

const MeetsWrap =styled.div`
    border: 0.5px solid #D4D4D4;
    padding-bottom: 16px;
    border-radius: 4px;
    overflow-y: auto;
    flex: 1;
    .empty_arr{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
            padding: 13px 0px 0 10px;
    }
`;

const MeetsItem = styled.div`
height: 76px;
background: #F8F8F8;
border-radius: 4px;
margin: 0 16px;
margin-top: 16px;
display: flex;
align-items: center;
h3{
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-transform: uppercase;
    padding-bottom: 5px;
}
.Favorites_item_block{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
`;

const MeetsItemInfo = styled.div`
div{
    display: flex;
}
`;

const MeetsItemSettings = styled.button`
background: url(${props => props.bg}) center no-repeat;
border: none;
background-color: #EFEFEF;
border-radius: 8px;
width: 24px;
height: 24px;
display: flex;
align-items: center;
margin-right: 16px;
cursor: pointer;
`;

const SendDate = styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 110%;
    color: #363636;
    padding-bottom: 4px;
    padding-right: 6px;
`;


export {MeetsContainer, SelectAllItem, SearchContent, MeetsWrap, MeetsItem, MeetsItemInfo, MeetsItemSettings, SendDate}