export const errorPage_ru = {
    notFound: {
        // company: 'Компания не найдена',
        // pavilion: 'Павильон не найден',
        noMedia: 'Нет медиа',
        default: 'Страница не найдена',
    },
}

export const errorsRequest_ru = {
    unknown: 'Неизвестная ошибка',
    registration: {
        'email': 'Пользователь с таким адресом электронной почты существует',
        'nick': 'Пользователь с таким ником существует',
        'notValid': 'Неверно заполнены данные',
    },
    modalAddCompany: {
        nameExist: 'Компания с таким названием уже существует',
    },
    news: {
        'name': 'Новость с таким названием существует',
    },
    seo: {
        repeatFile: 'Файл с таким именем уже существует',
    }
}

export const errorsValid_ru = {
    required: 'Это поле не может быть пустым',
    name: 'Имя может состоять только из букв и должно содержать от 2 до 20 символов.',
    surname: 'Фамилия может состоять только из букв и должна содержать от 2 до 32 символов.',
    patronymic: 'Отчество может состоять только из букв и должно содержать от 2 до 20 символов.',
    nickname: 'Ник может состоять только из букв и должен содержать от 3 до 32 символов.',
    email: 'Не соответствует формату электронной почты.',
    password: 'Пароль должен быть не короче 8 символов.',
    passwordLat: 'Пароль должен состоять только из латинских букв и цифр',
    passwordEqual: 'Пароли должны быть одинаковыми.',
    tel: 'Телефон может содержать только цифры',
    company: 'enter error',
    position: 'enter error',
    userAgreementNotChecked: 'Примите пользовательское соглашение',
    selectTypeContent: 'Выберите тип контента',
}