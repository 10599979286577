import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts/Gilroy/Gilroystylesheet.css";
import "./fonts/Helvetica/Helveticastylesheet.css"
import App from "./App";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./assets/styles/theme";
import store from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  // </React.StrictMode>
);
