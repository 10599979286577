import styled from "styled-components";

const DescriptionSection = styled.section`
  padding: 60px 0 90px;
  position: relative;
`;



const WrapperTitle = styled.div`
  padding-bottom: 25px;
  p {
    font-size: 14px;
    line-height: 24px;
    color: #6b6d6f;
    padding: 10px 0 20px 0;
  }
`;

const Headers = styled.div`
  text-align: center;
  font-family: 'Helvetica', sans-serif;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    color: #363636;
    @media (max-width: 1024px) {
      line-height: unset;
    }
    @media (max-width: 768px) {
      font-size: 36px;
      padding-bottom: 0;
    }
    @media (max-width: 540px) {
      font-size: 28px;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 31fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const ContentText = styled.div`
    display: flex;
    justify-content: center;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #484848;
    text-align: center;
    max-width: 545px;
  }
  strong{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #34C57F;
  }
`;

  


const Wrapper = styled.div`
padding: 70px calc(50% - 600px);
    margin: 0 20px;
`

export {
    DescriptionSection,
    WrapperTitle,
    Headers,
    Content,
    ContentText,
    Wrapper
};

