import styled from 'styled-components';

const HeaderApp = styled.header`
  max-width: 1920px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  z-index: 20;
  height: 64px;
  justify-content: space-between;
`;

const WrapHeaderLogo = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

const WrapNavBar = styled.div`
    display: flex;
    align-items: center;
    .Virtuals_Exhibition{
    width: 102px;
    height: 28px;
    border: 1.4px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    background: #FFFFFF;
    cursor: pointer;
  }
`;

const NavBar = styled.ul`
  margin: 0;
  padding-inline-start: 0;
  display: flex;
  gap: 50px;
  div {
    display: flex;
  }
`;

const NavBarNormal = styled(NavBar)`
.Language_Wrapper{
    width: 56px;
}
@media (max-width: 1200px) {
   gap: 20px;
  };
  @media (max-width: 1060px) {
    gap: 10px;
  }
  @media (max-width: 854px) {
    gap: 7px;
  }
`;

const NavBarSelect = styled.select`
  width: 100%;
  border: 1px solid #3F3F3F;
  border-radius: 8px;
  outline: none;
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #363636;
`;

const WrapNavBarItem = styled.div`
  position: relative;
  height: 100%;
  a {
    color: inherit;
  }
`;

const WrapNavBarFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
`

const LogInBtn = styled.button`
  text-decoration: none;
  border: none;
  width: 89px;
  height: 34px;
  background: #34C57F;
  border-radius: 12px;
  cursor: pointer;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
`;


const NavBarItemText = styled.div`
  font-family: 'Gilroy', sans-serif;
  height: 17px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
  padding: 0;
  letter-spacing: 0.28px;
  background: transparent;
  position: relative;
  z-index: 3;
  -webkit-transition: all 0.4s linear 0s;
  -o-transition: all 0.4s linear 0s;
  transition: all 0.4s linear 0s;
  text-decoration: unset;
  white-space: nowrap;
  @media (max-width: 980px) {
   font-size: 13px;
  }
  @media (max-width: 920px) {
   font-size: 12px;
  }
  @media (max-width: 880px) {
    font-size: 11px;
  }
`;

const NavBarItemNotActive = styled.li`
  line-height: 90px;
  float: none;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  div{
    :hover{
    ::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #34C57F;
    position: absolute;
    top: 15px;
    -webkit-transition: all 0.1s linear 0s;
    -o-transition: all 0.1s linear 0s;
    transition: all 0.1s linear 0s;
  }
    }
  }
  :hover ${NavBarItemText} {
    color: #34C57F;
    transition: 0.4s;
  }

  :hover::before {
    opacity: 1;
  };
  @media (max-width: 880px) {
   line-height: 80px;
  }
`;

const NavBarItemActive = styled(NavBarItemNotActive)`
    ${NavBarItemText} {
        color: #34C57F;
    transition: 0.4s;
  }
  div{
  ::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #34C57F;
    position: absolute;
    top: 15px;
    -webkit-transition: all 0.1s linear 0s;
    -o-transition: all 0.1s linear 0s;
    transition: all 0.1s linear 0s;
  }
  }
::before {
  opacity: 1;
}
`;

const NavBarItemLang = styled(NavBarItemNotActive)`
  display: flex;
  align-items: center;
`;

const UserDataWrap = styled.div`
display: flex;
`;
const UserAvatar = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 20px;
`;

const UserTextWrap = styled.div`
display: flex;
align-items: center;
margin-left: 12px;
gap: 5px;
p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
}
@media(max-width: 834px){
    display: none;
}
`;

const BorderLine = styled.div`
    width: 0.5px;
    height: 25px;
    background: #D4D4D4;
    border-radius: 2px;
    margin: 0 25px;
    @media (max-width: 1200px) {
        margin: 0 25px;
  };
  @media (max-width: 1060px) {
    margin: 0 10px;
  }
  @media (max-width: 854px) {
    margin: 0 7px;
  }
`

export {
    HeaderApp, WrapHeaderLogo,
    WrapNavBar, NavBarNormal, WrapNavBarFlex,
    NavBarItemNotActive, NavBarItemActive, NavBarItemText,
    WrapNavBarItem, NavBarItemLang, 
    LogInBtn, NavBarSelect, UserDataWrap, 
    UserAvatar, UserTextWrap, BorderLine
};