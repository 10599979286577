import {useSelector} from "react-redux";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {CheckBox} from "../../../../../components/AppCheckBox/AppCheckBox";
import {AppSwitch} from "../../../../../components/AppSwitch/AppSwitch";
import {getDescription, getName} from "../../../../../utils/helpers";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {ScrollBarWrap} from "../../AdminPanelContent/AdminPanelContent.style";
import {SeoSettingsWrap} from "../../Companies/CreateCompany/CreateCompany.style";
import {
  BtnsWrap,
  CharterCard,
  CharterWrap,
  RoleAccessWrap,
} from "../../Roles/AddNewRole/AddNewRole.style";
import {AddNewPavilionWrap, BtnsPavilionWrap} from "./addNewPavilion.style";

export const AddNewPavilion = ({
  handlerActions,
  localeState,
  stateCheckBox,
  stateAllCheckBox,
  checkAction,
  findCheck,
}) => {
  const {exitIcon, triangleDown, triangleUp, searchIcon} = svg_icons;
  const {expanents} = useSelector((state) => state.admin);

  const [checkBoxState, setCheckBoxState] = stateCheckBox;
  const [AllCheckBox, setAllCheckBox] = stateAllCheckBox;
  const ValidForm =
    Boolean(localeState.nameRu) &&
    Boolean(localeState.nameBy) &&
    Boolean(localeState.nameEn) &&
    Boolean(localeState.descriptionRu) &&
    Boolean(localeState.descriptionBy) &&
    Boolean(localeState.descriptionEn) &&
    Boolean(localeState.companies.length) &&
    Boolean(localeState.nameKey);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={handlerActions("setOpenPavilion")}>
          {exitIcon}
        </CloseButton>
        <AddNewPavilionWrap>
          <Title>{"Новый павильон"}</Title>
          <SeoSettingsWrap>
            <div
              className="general_seo_data"
              style={{justifyContent: "space-between", width: "100%"}}
            >
              <p>{"Общие данные:"}</p>
              <div style={{display: "flex", gap: "10px"}}>
                <button
                  className={`seo_language ${
                    localeState.language === "ru" ? "active" : ""
                  }`}
                  onClick={handlerActions("changeLang", "ru")}
                >
                  {"РУС"}
                </button>
                <button
                  className={`seo_language ${
                    localeState.language === "en" ? "active" : ""
                  }`}
                  onClick={handlerActions("changeLang", "en")}
                >
                  {"ENG"}
                </button>
                <button
                  className={`seo_language ${
                    localeState.language === "by" ? "active" : ""
                  }`}
                  onClick={handlerActions("changeLang", "by")}
                >
                  {"БЕЛ"}
                </button>
              </div>
            </div>
          </SeoSettingsWrap>
          <div className="pavilion_name">
            <p className="head_text">{"Название"}</p>
            <input
              value={localeState.newPavilion[getName(localeState.language)]}
              placeholder={"Введите название"}
              onChange={handlerActions("setName")}
            />
          </div>
          <div className="pavilion_name">
            <p className="head_text">{"PagePass"}</p>
            <input
              placeholder={"Введите URL"}
              onChange={handlerActions("setNameKey")}
            />
          </div>
          <div className="add_expanents" style={{position: "relative"}}>
            <p className="head_text">{"Экспоненты"}</p>
            <button
              className="add_exp_btn"
              onClick={handlerActions("setOpenAccess")}
            >
              {localeState.newPavilion.companies.length === 0
                ? "Выберите организацию"
                : `Добавлено организаций: ${localeState.newPavilion.companies.length}`}
              {localeState.openAccess ? triangleUp : triangleDown}
            </button>
            <RoleAccessWrap
              style={
                localeState.openAccess
                  ? {
                      display: "block",
                      position: "absolute",
                      background: "#FFFFFF",
                      zIndex: "3",
                    }
                  : {display: "none"}
              }
            >
              <div className="all_role_access">
                <CheckBox
                  checked={AllCheckBox}
                  onChange={(e) => {
                    setCheckBoxState((state) =>
                      state.map((item) => ({
                        id: item.id,
                        check: e.target.checked,
                      }))
                    );
                    setAllCheckBox(e.target.checked);
                  }}
                />
                <p className="pavilion_text">{"Все"}</p>
                <div className="search_access_roles">
                  {searchIcon}
                  <input
                    type={"text"}
                    placeholder={"Начните вводить"}
                    onChange={handlerActions("setSearchPavilion")}
                  />
                </div>
              </div>
              <CharterWrap>
                <ScrollBarWrap>
                  <div className="charter_container">
                    {expanents.map((item, index) => {
                      return (
                        <CharterCard key={index}>
                          <CheckBox
                            checked={checkAction(item.id)}
                            onChange={() =>
                              setCheckBoxState((state) =>
                                state.map((value) =>
                                  value.id === item.id
                                    ? {id: value.id, check: !value.check}
                                    : value
                                )
                              )
                            }
                          />
                          <p className="pavilion_text">
                            {item.name[localeState.language]}
                          </p>
                        </CharterCard>
                      );
                    })}
                  </div>
                </ScrollBarWrap>
              </CharterWrap>
              <BtnsWrap>
                <button
                  style={{width: "100%", cursor: "pointer"}}
                  className="back"
                  onClick={handlerActions("setOpenAccess")}
                >
                  {"Отменить"}
                </button>
                <button
                  style={
                    findCheck(checkBoxState)
                      ? {width: "100%", cursor: "pointer", opacity: "1"}
                      : {width: "100%", cursor: "pointer", opacity: "0.5"}
                  }
                  className="send_btn"
                  onClick={handlerActions("setCompanies", checkBoxState)}
                >
                  {"Добавить выбранные"}
                </button>
              </BtnsWrap>
            </RoleAccessWrap>
          </div>
          <div className="visibility_forUser">
            <p className="head_text">{"Видимость"}</p>
            <div className="switcher-wrap">
              <p>{"Доступен администраторам экспонента"}</p>
              <AppSwitch onChange={handlerActions("setShow")} />
            </div>
          </div>
          <div className="description_wrap">
            <p className="head_text">{"Описание"}</p>
            <textarea
              value={
                localeState.newPavilion[getDescription(localeState.language)]
              }
              onChange={handlerActions("setDescription")}
            />
          </div>
          <BtnsPavilionWrap>
            <button
              className="back"
              onClick={handlerActions("setOpenPavilion")}
            >
              {"Отменить"}
            </button>
            <button
              style={ValidForm ? {opacity: "1"} : {opacity: "0.5"}}
              disabled={!ValidForm}
              className="send_btn"
              onClick={handlerActions("sendNewPavilion")}
            >
              {"Сохранить"}
            </button>
          </BtnsPavilionWrap>
        </AddNewPavilionWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
