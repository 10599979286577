import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { filesUrl } from "../../../../API/api";
import { svg_icons } from "../../../../assets/imgs/svg_icons";
import {
  AddGalleryImage,
  AddGalleryVideo,
  AddNewContactDepartment,
  AddRepresentatives,
  ChangeVisibleRepresentative,
  DeleteCompanyDocuments,
  DeleteDepartmentContact,
  DeleteGalleryMedia,
  DeleteRepresentatives,
  UpdateCompanyDocuments,
  UpdateCompanyHeader,
  UpdateCompanyText,
  UpdateDepartmentContact,
} from "../../../../store/exhibitorSlice/exhibitorAsyncThunk";
import { resetStateList, setExhibitorContent } from "../../../../store/exhibitorSlice/exhibitorSlice";
import { HeadComponentWrap } from "./headComponent.style";

export const HeadComponent = ({ title, setOpenModal, openModal }) => {
  const { uncoverLeftIcon, settingsIcon } = svg_icons;
  const { content } = useSelector((state) => state.exhibitor);
  const company = useSelector((state) => state.profile.userData?.company);
  const {
    newGalleryItems,
    text,
    name,
    logo,
    cover,
    representatives,
    companyDocuments,
    galleryItems,
    companyDepartments
  } = useSelector((state) => state.exhibitor);
  const dispatch = useDispatch();

  const [checkText, setCheckText] = useState(
    Boolean(
      text?.by !== company?.text?.by ||
        text?.ru !== company?.text?.ru ||
        text?.en !== company?.text?.en
    )
  );
  const [checkHeadScreen, setCheckHeadScreen] = useState(
    Boolean(
      company?.name?.by !== name?.by ||
        company?.name?.en !== name?.en ||
        company?.name?.ru !== name?.ru
    ) ||
      Boolean(logo.path && logo.path !== company?.logo) ||
      Boolean(cover.path && cover.path !== company?.cover)
  );

  const [localeState, setLocaleState] = useState({
    localeGalleryItems: {
      removeItems: [],
      addItems: [],
      validate: false,
    },
    representatives: {
      validate: false,
    },
    documents: {
      validate: false,
    },
    departments: {
        validate: false
    },
    contacts: {
        validate: false
    }
  });

  useEffect(() => {
    representatives.some((el) => el.update || el.remove || !el.userId) ?
        setLocaleState((state) => (
            {...state, representatives: { ...state.representatives, validate: true }})
            )
            :
            setLocaleState((state) => (
                {...state, representatives: { ...state.representatives, validate: false }})
                )
  }, [representatives]);

  useEffect(() => {
    companyDocuments.some((el) => el.update || el.remove || !el.id) ?
      setLocaleState(state => (
        { ...state, documents: { ...state.documents, validate: true }})
      )
      :
      setLocaleState(state => (
        { ...state, documents: { ...state.documents, validate: false }})
      )
  }, [companyDocuments]);

  useEffect(() => {
    companyDepartments.forEach(department => {
        department.companyDepartmentContacts.some((contact) => contact.update || contact.remove || !contact.id) ?
        setLocaleState((state) => (
            {...state, contacts: {...state.contacts, validate: true}}
        )) 
        :
        setLocaleState((state) => (
            {...state, contacts: {...state.contacts, validate: false}}
        ))
    });
  }, [companyDepartments])

  useEffect(() => {
    setCheckText(
      Boolean(
        text?.by !== company?.text?.by ||
          text?.ru !== company?.text?.ru ||
          text?.en !== company?.text?.en
      )
    );
    setCheckHeadScreen(
      Boolean(
        company?.name?.by !== name?.by ||
          company?.name?.en !== name?.en ||
          company?.name?.ru !== name?.ru
      ) ||
        Boolean(logo.path !== company?.logo) ||
        Boolean(cover.path !== company?.cover)
    );
  }, [
    text,
    company?.text,
    company?.name,
    name,
    logo,
    cover,
    company?.cover,
    company?.logo,
  ]);

  useEffect(() => {
    if (galleryItems) {
      setLocaleState((state) => {
        return {
          ...state,
          localeGalleryItems: newGalleryItems.reduce(
            (prev, current, i, array) => {
              let filter = prev.removeItems.filter(
                (elem) => elem.remove && elem.id
              );
              prev.removeItems = filter;
              (!galleryItems.find(
                (elem) => {
                    return elem.id === current.id
                }
              ) ||
                current.update) &&
                !current.remove &&
                prev.addItems.push(current);
              Boolean(prev.removeItems.length) || Boolean(prev.addItems.length)
                ? (prev.validate = true)
                : (prev.validate = false);
              return {
                removeItems: prev.removeItems,
                addItems: prev.addItems,
                validate: prev.validate,
              };
            },
            { removeItems: newGalleryItems, addItems: [], validate: false }
          ),
        };
      });
    }
    return () => {
        dispatch(resetStateList('newGalleryItems'))
    }
  }, [newGalleryItems, galleryItems, dispatch]);

  const handlerActions = (content, action) => (e) => {
    switch (content) {
      case "pavilions-gallery":
        if (localeState.localeGalleryItems.addItems.length !== 0) {
          localeState.localeGalleryItems.addItems.map((item) => {
            if (item.type === "image") {
              return dispatch(AddGalleryImage({companyId: company.id, body: item}));
            } else {
              return dispatch(AddGalleryVideo({companyId: company.id, body: item}));
            }
          });
          setLocaleState((state) => {
            return {
              ...state,
              localeGalleryItems: {
                ...state.localeGalleryItems,
                addItems: [],
                validate: false,
              },
            };
          });
        }
        if (localeState.localeGalleryItems.removeItems.length !== 0) {
          localeState.localeGalleryItems.removeItems.map((item) =>
            dispatch(DeleteGalleryMedia({companyId: company.id, body: item}))
          );
          setLocaleState((state) => {
            return {
              ...state,
              localeGalleryItems: {
                ...state.localeGalleryItems,
                removeItems: [],
                validate: false,
              },
            };
          });
        }
        break;
      case "company-desc":
        dispatch(UpdateCompanyText({companyId: company.id, body: {text}}));
        setCheckText(false);
        break;
      case "main":
        setOpenModal(!openModal);
        break;
      case "head-screen":
        dispatch(UpdateCompanyHeader({ logo, name, cover }));
        setCheckHeadScreen(false);
        break;
      case "representatives":
        representatives.map((el) => {
          if (el.update) {
            dispatch(ChangeVisibleRepresentative(el));
          } else if (!el.userId) {
            dispatch(AddRepresentatives(el));
          } else if (el.remove) {
            dispatch(DeleteRepresentatives(el));
          }
          setLocaleState((state) => {
            return {
              ...state,
              representatives: { ...state.representatives, validate: false },
            };
          });
          return el;
        });
        break;
      case "documents":
        companyDocuments.map((el) => {
          if (el.update || !el.id) {
            dispatch(UpdateCompanyDocuments(el));
          } else if (el.remove) {
            dispatch(DeleteCompanyDocuments(el.id));
          }
          setLocaleState((state) => {
            return {
              ...state,
              documents: { ...state.documents, validate: false },
            };
          });
          return el;
        });
        break;
      case "contacts": 
      companyDepartments.forEach((department) => {
      department.companyDepartmentContacts.forEach((contact) => {
        if(!contact.id){
            dispatch(AddNewContactDepartment({companyId: company?.id ,body: {...contact, departmentId: department.id}}))        
        }else if(contact.remove){
            dispatch(DeleteDepartmentContact({companyId: company?.id ,body: {...contact, departmentId: department.id}}))
        }else if(contact.update){
            dispatch(UpdateDepartmentContact({companyId: company?.id ,body: {...contact, departmentId: department.id}}))
        }
      })
    })
    setLocaleState((state) => {
        return {
          ...state,
          contacts: { ...state.contacts, validate: false },
          departments: {...state.departments, validate: false}
        };
      });
      break;
      default:
        break;
    }
  };

  const setDisabled = (content) => {
    switch (content) {
      case "main":
        return false;
      case "pavilions-gallery":
        return !localeState.localeGalleryItems.validate;
      case "company-desc":
        return !checkText;
      case "head-screen":
        return !checkHeadScreen;
      case "representatives":
        return !localeState.representatives.validate;
      case "documents":
        return !localeState.documents.validate;
      case "contacts":
        return !localeState.contacts.validate
      default:
        return true;
    }
  };
  const setValidForm = (content) => {
    switch (content) {
      case "main":
        return { opacity: "1" };
      case "pavilions-gallery":
        if (!localeState.localeGalleryItems.validate) {
          return { opacity: "0.5" };
        } else {
          return { opacity: "1" };
        }
      case "company-desc":
        return checkText ? { opacity: "1" } : { opacity: "0.5" };
      case "head-screen":
        return checkHeadScreen ? { opacity: "1" } : { opacity: "0.5" };
      case "representatives":
        return localeState.representatives.validate
          ? { opacity: "1" }
          : { opacity: "0.5" };
      case "documents":
        return localeState.documents.validate
          ? { opacity: "1" }
          : { opacity: "0.5" };
      case "contacts": 
        return localeState.contacts.validate
        ? { opacity: "1" }
        : { opacity: "0.5" };
      default:
        return { opacity: "0.5" };
    }
  };

  if(!company){
    return null 
  }

  return (
    <HeadComponentWrap>
      <div className="head_info_wrap">
        {content === "main" ? (
          <div className="logo_wrap" style={{ marginRight: "10px" }}>
            <img
              className="company_logo"
              src={filesUrl + company?.logo}
              alt="company_logo"
            />
          </div>
        ) : (
          <div className="back_to_headPage" style={{ marginRight: "14px" }}>
            <button onClick={() => dispatch(setExhibitorContent("main"))}>
              {uncoverLeftIcon}
            </button>
          </div>
        )}
        <h2>{title}</h2>
      </div>
      <div className="head_btns_wrap">
        {content === "main" ? (
          <Link to={`/company/${company.companyNameKey}`}>
            <button className="first_head_btn">{"Предпросмотр"}</button>
          </Link>
        ) : (
          <button
            onClick={() => dispatch(setExhibitorContent("main"))}
            className="first_head_btn"
          >
            {"Отменить действие"}
          </button>
        )}
        <button
          disabled={setDisabled(content)}
          style={setValidForm(content)}
          type={content === "main" ? "button" : "submit"}
          onClick={handlerActions(content)}
          className="second_head_btn"
        >
          {content === "main" ? (
            <>
              {settingsIcon} {"Настройки"}{" "}
            </>
          ) : (
            "Опубликовать"
          )}
        </button>
      </div>
    </HeadComponentWrap>
  );
};
