export const modals_by = {
    block: {
        chatH1: "Блакаванне чата",
        userH1: "Блакаванне",
        beBlocked: "будзе заблякаваны(aя)",
        cantSendMes: "і не зможа адпраўляць вам паведамленні", 
        yesBlock: "Так, заблакаваць",
    },
    unblock: {
        chatH1: "Разблакіроўка чата",
        userH1: "Разблакіроўка",
        beUnblocked: "будзе разблакіраван(ая)",
        canSendMes: "і зможа адпраўляць вам паведамленні",
        yesUnblock: "Так, разблакаваць",
    },
    removeContact: {
        head: "Выдаліць кантакт",
        beRemoved: "будзе выдалены(aя) з спісу",
        yourContacts: "вашых кантактаў",
        noRemove: "Не, пакінуць",
        yesRemove: "Так, выдаліць",
    },
    removeChat: {
        head: "Выдаліць чат",
        subhead1: "Жадаеце выдаліць гэты чат? Гісторыя перапіскі будзе ",
        subhead2: "выдаленая для вас і вашага суразмоўцы",
        noRemove: "Не, пакінуць",
        yesRemove: "Так, выдаліць",
    },
    cancel: "Адмяніць",
}