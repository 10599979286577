import { header_en } from "./components_text/header_en";
import { authorization_en } from "./components_text/authorization_en";
import { modalAddCompany_en } from "./components_text/modalAddCompany_en";
import { banner_en } from "./components_text/banner_en";
import { description_en } from "./components_text/description_en";
import { manual_en } from "./components_text/manual_en";
import { media_en } from "./components_text/media_en";
import { mission_en } from "./components_text/mission_en";
import { news_en } from "./components_text/news_en";
import { partners_en } from "./components_text/partners_en";
import { follow_en } from "./components_text/follow_en";
import { contactus_en } from "./components_text/contactus_en";
import { seoModal_en } from "./components_text/seoModal_en";
import { footer_en } from "./components_text/footer_en";
import { companiesPage_en } from "./components_text/companiesPage_en";
import {
  pavilionPage_en,
  pavilionsList_en,
} from "./components_text/pavilion_en";
import { editCompany_en } from "./components_text/editCompany_en";
import { filesDownload_en } from "./components_text/filesDownload_en";
import { activate_en } from "./components_text/activate_en";
import { changeMain_en } from "./components_text/changeMain_en";
import { tibo_en } from "./components_text/tibo_en";
import {
  errorPage_en,
  errorsRequest_en,
  errorsValid_en,
} from "./components_text/errors_en";
import { search_en } from "./components_text/search_en";
import { PavilionVDNX_en } from "./components_text/pavilionVDNX_en";
import { features_en } from "./components_text/features_en";
import { mobileApp_en } from "./components_text/mobileApp_en";
import { mainPage_en } from "./components_text/mainPage_en";
import { modals_en } from "./components_text/modals_en";
import { contextMenu_en } from "./components_text/contextMenu_en";
import { forms_en } from './components_text/forms_en';
import { profile_en } from "./components_text/profile_en";
import { baseWords_en } from "./baseWords_en";

export const en = {
  language: "en",
  header: header_en,
  authorization: authorization_en,
  modalAddCompany: modalAddCompany_en,
  banner: banner_en,
  description: description_en,
  manual: manual_en,
  media: media_en,
  mission: mission_en,
  news: news_en,
  partners: partners_en,
  follow: follow_en,
  contactus: contactus_en,
  seoModal: seoModal_en,
  footer: footer_en,
  companiesPage: companiesPage_en,
  pavilionPage: pavilionPage_en,
  pavilionsList: pavilionsList_en,
  editCompany: editCompany_en,
  filesDownload: filesDownload_en,
  activate: activate_en,
  changeMain: changeMain_en,
  tibo: tibo_en,
  errorPage: errorPage_en,
  errorsRequest: errorsRequest_en,
  errorsValid: errorsValid_en,
  search: search_en,
  VDNX: PavilionVDNX_en,
  features: features_en,
  mobileApp: mobileApp_en,
  mainPage: mainPage_en,
  modals: modals_en,
  contextMenu: contextMenu_en,
  forms: forms_en,
  profile: profile_en,
  baseWords: baseWords_en
};
