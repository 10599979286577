import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setInitFileValues, setInitValues } from "../../../store/exhibitorSlice/exhibitorSlice"
import { ExhibitorPageWrap } from "./exhibitorPage.style"
import { ExhibitorPageContent } from "./ExhibitorPageContent/ExhibitorPageContent"
import { HeadComponent } from "./HeadComponent/HeadComponent"
import { VisibilityPage } from "./VisibilityPage"

export const ExhibitorPage = () => {
    const [openModal, setOpenModal] = useState(false)
    const company = useSelector((state) => state.profile.userData?.company);
    const {content} = useSelector((state) => state.exhibitor)
    const {profile: {exhibitorPage}} = useSelector((state) => state.app.appText)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setInitValues({key: 'name', value: company?.name}))
        dispatch(setInitValues({key: 'text', value: company?.text}))
        dispatch(setInitFileValues({key: 'logo', value: company?.logo}))
        dispatch(setInitFileValues({key: 'cover', value: company?.cover}))
    }, [company, dispatch])

    const setTitle = (key) => {
        switch (key) {
            case 'head-screen':
                return exhibitorPage.h1.headScreen
            case 'company-desc':
                return exhibitorPage.h1.companyDesc
            case 'representatives':
                return exhibitorPage.h1.representatives
            case 'pavilions-gallery':
                return exhibitorPage.h1.pavilionsGallery
            case 'documents':
                return exhibitorPage.h1.documents
            case 'contacts':
                return exhibitorPage.h1.contacts
            default:
                return exhibitorPage.h1.mainScreen
        }
    }

    return (
        <ExhibitorPageWrap>
            <HeadComponent
            setOpenModal={setOpenModal}
            title={setTitle(content)} 
            openModal={openModal}
            />
            <ExhibitorPageContent content={content} />
        {openModal ? <VisibilityPage setOpenModal={setOpenModal}/> : null}
        </ExhibitorPageWrap>
    )
}