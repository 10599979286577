import React from 'react'
import { useSelector } from 'react-redux';
import { PartnersCarousel } from './PartnersCarousel'
import {PartnersHeader, ContentText, ContentTitle} from './style/partners_css'

export const Partners = () => {

    const {mainPage: {partners: localText}} = useSelector((state) => state.app.appText);

    return (
        <PartnersHeader id='scroll_partners' className="b_popular_product">
            <ContentTitle>
                <h2>{localText.head}</h2>
            </ContentTitle>
            <ContentText>
                <p>{localText.subhead}</p>
            </ContentText>
            <PartnersCarousel />
        </PartnersHeader>
        )
}
