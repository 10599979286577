import styled from "styled-components";


const WrapContextMenuBtn = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    background: #FFFFFF;
    border-radius: 20px 0px 20px 20px;
    padding: 12px;
`;

const ListContextMenu = styled.div`
    display: grid;
    grid-gap: 10px;
    width: 230px;

    .list-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        background: #F5F5F5;
        border-radius: 12px;
        padding: 12px;
        cursor: pointer;
        svg {
            margin-right: 8px;
        }
        &.remove-chat {
           color: #FE564B;
           background: #FFF2F2;
        }  
    }
`;

export {
    WrapContextMenuBtn,
    ListContextMenu,
}