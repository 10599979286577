export const errorPage_by = {
    notFound: {
        // company: 'Кампанія не знойдзена',
        // pavilion: 'Павільён не знойдзены',
        noMedia: 'Няма медыя',
        default: 'Старонка не знойдзена',
    },
}

export const errorsRequest_by = {
    unknown: 'Невядомая памылка',
    registration: {
        'email': 'Карыстальнік з такім адрасам электроннай пошты існуе',
        'nick': 'Карыстальнік з такім нікам існуе',
        'notValid': 'Няправільна запоўненыя дадзеныя',
    },
    modalAddCompany: {
        nameExist: 'Кампанія з такой назвай ужо існуе',
    },
    news: {
        'name': 'Навіна з такой назвай існуе',
    },
    seo: {
        repeatFile: 'Файл з такім імем ужо існуе',
    }
}

export const errorsValid_by = {
    required: 'Гэтае поле не можа быць пустым',
    name: 'Імя можа ўтрымліваць толькі літары і павінна мець ад 2 да 20 сімвалаў.',
    surname: 'Прозвішча можа ўтрымліваць толькі літары і павінна мець ад 2 да 32 сімвалаў.',
    patronymic: 'Па бацьку можа ўтрымліваць толькі літары і павінна мець ад 2 да 20 сімвалаў.',
    nickname: 'Мянушка можа ўтрымліваць толькі літары і павінна мець ад 3 да 32 сімвалаў.',
    email: 'Не адпавядае фармату электроннай пошты.',
    password: 'Пароль павінен утрымліваць не менш за 8 сімвалаў.',
    passwordLat: 'Пароль павінен утрымліваць толькі лацінскія літары і лічбы',
    passwordEqual: 'Паролі павінны быць роўнымі.',
    company: 'enter error',
    position: 'enter error',
    userAgreementNotChecked: 'Прыміце карыстацкую дамову',
    selectTypeContent: 'Выберыце тып кантэнту',
}