import styled from "styled-components";

const ErrorModalWrap = styled.div`
    padding: 0 16px;
    margin-top: 15%;
    margin-bottom: 30px;
    text-align: center;
    p{
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 26px;
    }
    .Activate_Acc{
        width: 340px;
        height: 36px;
        background: #34C57F;
        border-radius: 8px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }
`;

export {ErrorModalWrap}