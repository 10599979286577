import styled from "styled-components";

const BenefitsSection = styled.section`
    padding: 80px 0;
    background: #fff;
`;

const BenefitsHead = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    h2{
        max-width: 370px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        color: #363636;
        padding-bottom: 15px;
        text-align: center;
    }
    p{
        max-width: 445px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        color: #484848;
        text-align: center;
        padding-bottom: 15px;
    }
`;

const BenefitsBlocks = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0 16px;
`;

const BlockContent = styled.div`
    flex-basis: 24%;
    min-width: 300px;
    height: 141px;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #363636;
        padding: 13px 0;
    }
    p{
        max-width: 250px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #000000;
    }
    svg{
        width: 30px;
        height: 20px;
    }
    .visit_3D{
        path{
            stroke: #363636;
        }
    }
`;

export { BenefitsSection, BenefitsHead, BenefitsBlocks, BlockContent }