import styled from "styled-components";

const WrapPanelContent = styled.div`
    display: flex;
    flex: 1;
    background: #FFFFFF;
    border-width: 0.5px 0px 0.5px 0.5px;
    border-style: solid;
    border-color: #D4D4D4;
    margin-bottom: 33px;
`;

const UserContentBlock = styled.div`
    padding: 64px 0 0;
    display: flex;
    background: #fff;
    max-height: 100vh;
    min-height: 100vh;
    overflow: hidden;
`;

const PanelContentWrap = styled.div`
    width: 50%;
    border: 0.5px solid #D4D4D4;
    border-bottom: none;
`;

const StatisticBlock =styled.div`
    height: 273px;
    border-bottom: 0.5px solid #D4D4D4;
    h2{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        padding: 17px 0 15px 16px;
    }
`;

const DateBlock = styled.div`
    width: 153px;
    height: 34px;
    background: #F6F6F6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    p{
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #484848;
    }
`;

const DiagramsBlock = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
`;

const TimeDiagram = styled.div`
    width: 371px;
    height: 148px;
    background: #F6F6F6;
    border-radius: 12px;
`;

const TimeDiagramText = styled.p`
    padding: 13px 0 7px 16px;
`;

const StandsDiagramText = styled.p`
    padding: 13px 0 7px 16px;
`;

const StandsDiagram = styled.div`
    width: 371px;
    height: 148px;
    background: #F6F6F6;
    border-radius: 12px;
`;

const StandsItem = styled.div`
    width: 42px;
    background: rgba(52, 197, 127, 0.8);
    border-radius: 4px 4px 0px 0px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
`;

const TimeItem = styled.div`
    width: 42px;
    background: rgba(52, 197, 127, 0.8);
    border-radius: 4px 4px 0px 0px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
`;

const StandsItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
`;

const TimeItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
`;

export {
    WrapPanelContent,
    PanelContentWrap, UserContentBlock, 
    StatisticBlock, DateBlock, 
    DiagramsBlock, TimeDiagram,
    StandsDiagram, StandsItem, 
    TimeItem, StandsItemsContainer, 
    TimeItemsContainer, TimeDiagramText,
    StandsDiagramText
}