import ReactPlayer from "react-player"
import { useSelector } from "react-redux"
import { filesUrl } from "../../../../../API/api"
import Preview3DModels from "../../../../Exhibition/Company/CompanyPavilions/PreviewMaterials/Preview3DModels/Preview3DModels"
import { WrapMaterials } from "../../../../Exhibition/Company/CompanyPavilions/PreviewMaterials/previewMaterials.styles"
import { icons_svg } from "../../../../Exhibition/Company/imgs/icons.svg"
import { ShowGalleryItemWrap } from "./showGalleryItem.style"

export const ShowGalleryItem = ({galleryItem, setOpenPreview}) => {

    const {userData} = useSelector((state) => state.profile)
    const {closeBtn, arrowList} = icons_svg
    let companyNameKey = userData?.companyNameKey || userData?.company?.companyNameKey

    return(
        <ShowGalleryItemWrap>
      <div className="backgroud-modal"></div>
      <WrapMaterials style={galleryItem.type === 'model' ? {width: '998px'} : {width: 'auto'}}>
        <div className="materials-header" style={{justifyContent: "flex-end"}}>
          <div className="header__close">
            Закрыть{" "}
            <div className="close__icon" onClick={() => setOpenPreview({key: -1, open: false})}>
              {closeBtn}
            </div>
          </div>
        </div>

        {/*Content for slider*/}
        <div className="wrap-materials" >
          {galleryItem.type === "image" || galleryItem.type === "video"  ? 
          <div className="material">
          { galleryItem.type === 'image' ?
                <img
                  src={ galleryItem.file ? URL.createObjectURL(galleryItem.file) : (filesUrl + galleryItem.src)}
                  className="material__img"
                  alt="material_img"
                ></img>
                :
                <ReactPlayer url={galleryItem.src}
                  className="material__video"
                  controls={true}
                  alt="material_img"></ReactPlayer>
          }
                <div className="material-desc">
                  <h3 className="material-desc__name">{galleryItem.name}</h3>
                  <div className="divider"></div>
                  <h4 className="material-desc__title">Описание</h4>
                  <p className="material-desc__text">
                  {galleryItem.description}
                  </p>
                  <div className="Btns_materials_container">
                  <button className="material-desc__more-btn">
                    Показать больше {arrowList}
                  </button>
                  </div>
                </div>
            </div>
          : 
          <Preview3DModels companyNameKey={companyNameKey} model={galleryItem}/>}
        </div>

      </WrapMaterials>

  );
        </ShowGalleryItemWrap>
    )
}