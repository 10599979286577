import styled from "styled-components";

const VideoSliderWrap = styled.div`
  .slick-prev {
    left: 32px;
    z-index: 1;
    background: url(${props => props.arrL});
    background-size: 100%;
    top: 50%;
    width: 32px;
    height: 33px;
    ::before{
        content: none;
    }
    :hover{
        background: url(${props => props.arrL});
    }
    :focus {
        background: url(${props => props.arrL});
    }
    }
  .slick-next {
    right: 32px;
    top: 50%;
    background: url(${props => props.arrR});
    background-size: 100%;
    width: 32px;
    height: 33px;
    ::before{
        content: none;
    }
    :hover{
        background: url(${props => props.arrR});
    }
    :focus{
        background: url(${props => props.arrR});
    }
  }
  .slider{
    display: flex !important;
  }

  .slick-list{
    height: 100%
  }

`

export {VideoSliderWrap}