import styled from "styled-components";

const DocumentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
  h2{
    font-family: 'Helvetica';
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
}

.filter-search {
    width: 100%;
    height: 34px;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    max-width: 606px;
    align-self: flex-start;
    margin: 16px 0;

    input {
      width: 100%;
      border-radius: 10px;
      background: #f6f6f6;
      border: none;
      outline: none;
      padding-left: 6px;
    }
  }
`;


const DocumentsWrap = styled.div`
    border: 0.5px solid #D4D4D4;
    padding: 16px;
    flex: 1;
    border-radius: 4px;
    overflow-y: auto;
    .user_documents_container{
        display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    }
    .empty_arr{
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
    }
`;

const DocumentsFileContainer = styled.div`
    border-radius: 4px;
    flex: 1;
    height: 70px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
`;


export {DocumentsContainer,  
    DocumentsWrap, DocumentsFileContainer}