
import { icons_svg } from "../../Pages/Exhibition/Company/imgs/icons.svg"
import { CheckBoxLabel } from "./appCheckBox.style"



export const CheckBox = ({onChange, checked}) => {

    const {checkMark} = icons_svg

    return (
        <CheckBoxLabel className="app_checkbox">
            <input type="checkbox" onChange={onChange} checked = {checked === undefined ? false : checked} />
            <span>{checkMark}</span>
        </CheckBoxLabel>
    )
}