import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { filesUrl } from "../../../API/api.js";
import {
  ExpanentContainer,
  ExpanentContent,
  ExpanentDesc,
  ExpanentLogo,
  ExpanentNameContainer,
  ExpanentsWrap,
  ExpanentVisitLink,
  MeetsCompanyBtn,
} from "./style/Expanents_css";
import ExpanentBG from "../../../assets/Components/test/ExpanentBg.jpg";
import { RequestModal } from "../RequestsModal/RequestsModal.jsx";
import { AddFavoritesModalContainer } from "../AddFavoritesModal/AddFavoritesModalContainer.jsx";
import { ModalMessage } from "../../ModalMessage/ModalMessage.jsx";
import { useOpenExhibition } from "../../../hooks/useOpenExhibition.js";
import { svg_icons } from "../../../assets/imgs/svg_icons.js";

export const Expanents = () => {
  const { companies } = useSelector((state) => state.pavilions);

  const {favoritesIcon, visit3D} = svg_icons

  const {language} = useSelector((state) => state.app.appText);
  const [openRequestsModal, setOpenRequestsModal] = useState(false)
  const { userData } = useSelector((state) => state.profile);
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const openExhibitions = useOpenExhibition();

  const TransferToExpanent = (url) => {

    navigate(`/company/${url}`)
  }
  const AddFavCompanyEvent = (index) => (e) => {
    e.stopPropagation()
    setOpenModal(index)
  }

  return (
    <ExpanentsWrap>
      {companies.map((expanent, index) => {
        return (
          <ExpanentContainer key={expanent.id}>
              <ExpanentLogo bg={ExpanentBG} onClick={() => TransferToExpanent(expanent.url)}>
              { userData.firstName ?
              <div className="Add_Favorites_Company">
                <button className="Add_To_Favorites" onClick={AddFavCompanyEvent(index)}>{favoritesIcon} В избранные</button>
              </div> : null
              }
              <div className="Logo_Container">
                <img src={filesUrl + expanent.src} alt={"Expanent_Logo"}></img>
                </div>
              </ExpanentLogo>
              {openModal === index ? <AddFavoritesModalContainer setOpenModal={setOpenModal} company={
            {
                id: expanent.id,
                name: expanent.name[`${language}`],
                logo: expanent.src
            }} /> : null}
            <ExpanentContent>
              <ExpanentNameContainer>
                <p onClick={() => TransferToExpanent(expanent.url)}>
                  {expanent.name[`${language}`].length > 50
                    ? expanent.name[`${language}`].slice(0, 50) + "..."
                    : `${expanent.name[`${language}`]}`}
                </p>
              {/*  <ExpanentSettingsContainer>
                  <ExpanentSettings
                    bg={settings}
                    onClick={() =>
                      !openSettings.open
                        ? setOpenSettings({ id: `${index}`, open: true })
                        : setOpenSettings({ id: "", open: false })
                    }
                  ></ExpanentSettings>
                  {openSettings.open && openSettings.id == index ? (
                    <ModalSettings openHint={setOpenSettings} />
                  ) : (
                    ""
                  )}
                </ExpanentSettingsContainer>*/}
              </ExpanentNameContainer>
              <ExpanentDesc>
              {userData.firstName ? <MeetsCompanyBtn onClick={() => setOpenRequestsModal(index)}>{'Заявка на встречу'}</MeetsCompanyBtn> : null}
              {openRequestsModal === index ? <RequestModal setOpenRequestsModal={setOpenRequestsModal} companyId={expanent.id} /> : null}
                <ExpanentVisitLink onClick={() => openExhibitions({pavilionId: expanent.pavilions[0] && expanent.pavilions[0].id, company: expanent.url})}>
                  {visit3D}{" "}
                  <div>
                    {"3D стенд"}
                  </div>
                </ExpanentVisitLink>
                {/*      <ExpanentViewsContainer>
                                {eye}{' '}
                                <p>12,3 тыс.</p>
                            </ExpanentViewsContainer>*/}
              </ExpanentDesc>
            </ExpanentContent>
          </ExpanentContainer>
        );
      })}
      <ModalMessage />
    </ExpanentsWrap>
  );
};
