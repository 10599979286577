import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createDialog, getDialogs, openDialogById } from "../store/appChatSlice/appChatExtraReducers";

export const useOpenDialogNotFromChat = () => {

  const dispatch = useDispatch();
  let dialogs = useSelector((state) => state.appChat.dialogs);
  let userData = useSelector((state) => state.profile.userData);
  const navigate = useNavigate();

  const openDialogNotFromChat = async ({mobile, dialogUser} = {}) => {
    let userId = dialogUser.userId || dialogUser.id;
    try {
        let dialogsResponse = (await dispatch(getDialogs())).payload;
        dialogs = dialogsResponse || dialogs;
    } catch (error) {
        dialogs = dialogs;
    }

    let findedDialog = dialogs?.find((dialog) => dialog.user.id === userId);
    if (findedDialog) {
        dispatch(openDialogById({id: findedDialog.dialogId, navigate: mobile ? undefined: navigate}));
    } else {
      await dispatch(createDialog({body: {
        userId,
        text: undefined,
      }, navigate}));
    }
  };

  return openDialogNotFromChat;
};
