import { initialState } from "./adminSlice";

export const reducers = {

    setAdminState(state, action) {
        state[action.payload.key] = action.payload.value;
      },

    changePavilionsSequence(state, {payload}){
        state.pavilions = state.pavilions.map((pavilion) =>
        {
            if(pavilion.id === payload.dropPav.id){
                return {...pavilion, order: payload.currentPav.order}
            }else if(pavilion.id === payload.currentPav.id){
                return {...pavilion, order: payload.dropPav.order}
            }
            return pavilion
        })
    },

    setCreateCompanyInfo(state, {payload}) {
        if(payload.language){
            state.createCompany[payload.type][payload.language] = payload.value
        }else{
            state.createCompany[payload.type] = payload.value
        }
    },
    setCompanyContract(state, {payload}) {
        state.createCompany.contract = {
            file: payload.file
        }
    },
    setCompanyPavilionsId(state, {payload}){
        if(payload.check){
            state.createCompany.pavilionsId = [...state.createCompany.pavilionsId, payload]
        }else{
            state.createCompany.pavilionsId = state.createCompany.pavilionsId.filter((item) => item.id !== payload.id)
        }
    },

    resetAdminState(state, {payload}){
        state[payload] = initialState[payload]
    }
}