import { Fragment } from "react"
import { useState } from "react"
import { svg_icons } from "../../../../assets/imgs/svg_icons"
import { AdminHead } from "../AdminHead/AdminHead"
import { ScrollBarWrap } from "../AdminPanelContent/AdminPanelContent.style"
import { SearchSortContent } from "../SearchSortContent/SearchSortContent"
import { AddClass } from "./AddClass/AddClass"
import { AddSubCategory } from "./AddSubCategory/AddSubCategory"
import { 
    AdminCatalogWrap, 
    CatalogBtnsWrap, 
    CatalogCard, 
    CatalogContainer, 
    DateReg, 
    HintWrap, 
    NameCatalogText } from "./AdminCatalog.style"
import { SubCategory } from "./SubCategory/SubCategory"


export const AdminCatalog = () => {
    const {addIcon, settingsIcon, trashIcon, uncoverIcon, uncoverUpIcon } = svg_icons
    const [addClass, setAddClass] = useState(false)
    const [openSubCategory, setOpenSubCategory] = useState({key: '', open: false})
    const [showSubCategory, setShowSubCategory] = useState({ key: '', open: false})
    const catalogData = [
        {
            CatalogName: 'СК28  Машины и оборудование',
            dateReg: '19 апреля 2022',
            subCategories: [
                {
                    name: 'СК2892 Машины и оборудование',
                    productsGroup: [
                        {name: 'СК28921 Машины и оборудование'},
                        {name: 'СК28922 Машины и оборудование'}
                    ]
                },
                {
                    name: 'СК2892 Машины и оборудование'
                },
                {
                    name: 'СК2892 Машины и оборудование'
                }
            ]
        },
        {
            CatalogName: 'СК28  Машины и оборудование',
            dateReg: '19 апреля 2022'
        },
        {
            CatalogName: 'СК28  Машины и оборудование',
            dateReg: '19 апреля 2022'
        },
    ]

    return (
        <AdminCatalogWrap>
            <div className="head_info_wrap">
                <AdminHead 
                title={`Каталог  (${catalogData.length})`} 
                desc={'Здесь вы можете добавить и отредактировать информацию о экспонентах'} />
                
                <button className="Add_Class" onClick={() => setAddClass(true)}>{addIcon} Добавить класс</button>
            </div>

            <SearchSortContent page={'admin-catalog'} />

            <CatalogContainer>
                <ScrollBarWrap>
                    <div className="map_container">
                        {catalogData.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                <CatalogCard>
                                    <div className="catalog_head_wrap">
                                    <button className="Uncover_btn" 
                                    onClick={() => setShowSubCategory({ key: index, open: !showSubCategory.open})}>
                                    {showSubCategory.open && showSubCategory.key === index ? uncoverUpIcon : uncoverIcon}
                                    </button>
                                    <div>
                                        <NameCatalogText>
                                            <p className="head_name">{'Класс - '}</p>
                                            <p className="catalog_name_text">{item.CatalogName}</p>
                                        </NameCatalogText>
                                        <DateReg>
                                            <p className="admin_catalog_date_text">{'Дата создания:'}</p>
                                            <p className="admin_catalog_date">{item.dateReg}</p>
                                        </DateReg>
                                    </div>
                                    </div>
                                    <CatalogBtnsWrap>
                                    <button className="btn_add_icon" onClick={() => setOpenSubCategory({key: index, open: true})}>
                                    {addIcon}
                                     <HintWrap>
                                    <p>{'Добавить подкатегорию'}</p>
                                    </HintWrap>
                                    </button>
                                    { openSubCategory.key === index && openSubCategory.open ? <AddSubCategory setOpenSubCategory={setOpenSubCategory} subcategory={item} /> : null}
                                    <button>{settingsIcon}</button>
                                    <button className="btn_basket">{trashIcon}</button>
                                    </CatalogBtnsWrap>
                                </CatalogCard>
                                {showSubCategory.open && showSubCategory.key===index && item?.subCategories ? <SubCategory settings={''} subCategories={item.subCategories} /> : null}
                                </Fragment>
                            )
                        })}
                    </div>
                </ScrollBarWrap>
            </CatalogContainer>
            {addClass ? <AddClass setAddClass={setAddClass}/> : null}
        </AdminCatalogWrap>
    )
}