import React from "react";
import * as s_c from '../Agreement/agreement.style.js'


export const PolicyAgreement = () => {

    return (
        <s_c.AgreementContainer>
            <s_c.AgreementContainer_text>
            Политика обработки персональных данных<br/>
        <p>1.	Общие положения</p>
1.1.	Важнейшим условием реализации целей деятельности Открытого акционерного общества «ЦНИИТУ» (далее по тексту – ОАО «ЦНИИТУ», «Оператор») является обеспечение необходимого и достаточного уровня информационной безопасности информации, к которой, в том числе, относятся персональные данные.<br/>
1.2.	Основные понятия, используемые в настоящей Политике:<br/>
1.2.1.	персональные данные – любая информация, относящаяся к прямо или косвенно к определенному или определяемому физическому лицу (субъекту персональных данных);<br/>
1.2.2.	оператор персональных данных (оператор) – государственный орган, юридическое лицо Республики Беларусь, иная организация, физическое лицо, в том числе индивидуальный предприниматель, самостоятельно или совместно с иными указанными лицами организующие и (или) осуществляющие обработку персональных данных;<br/>
1.2.3.	обработка персональных данных – любое действие или совокупность действий с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя, в том числе:<br/>
-	сбор;<br/>
-	систематизацию;<br/>
-	хранение;<br/>
-	изменение;<br/>
-	использование;<br/>
-	обезличивание;<br/>
-	блокирование;<br/>
-	распространение;<br/>
-	предоставление;<br/>
-	удаление.<br/>
1.2.4.	автоматизированная	обработка	персональных	данных	–	обработка персональных данных с помощью средств вычислительной техники;<br/>
1.2.5.	распространение	персональных	данных	–	действия,	направленные	на ознакомление с персональными данными неопределенного круга лиц;<br/>
1.2.6.	предоставление	персональных	данных	–	действия,	направленные	на ознакомление с персональными данными определенных лиц или круга лиц;<br/>
1.2.7.	блокирование персональных данных – прекращение доступа к персональным данным без их удаления;<br/>
1.2.8.	удаление персональных данных - действия, в результате которых становится невозможным восстановить персональные данные в информационных ресурсах (системах), содержащих персональные данные, и (или) в результате которых уничтожаются материальные носители персональных данных;<br/>
1.2.9.	обезличивание персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;<br/>
1.2.10.	информационная система персональных данных – совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.<br/>

1.3.	Политика в отношении обработки персональных данных в ОАО «ЦНИИТУ» (далее – Политика) определяет любое действие (операцию) или совокупность действий (операций), по обработке персональных данных, а также сведения о реализуемых требованиях к защите персональных данных.<br/>
1.4.	Политика разработана в соответствии с законодательством Республики Беларусь и обработка персональных данных основана на следующих нормативных правовых актах:<br/>
-	Закон Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных»;<br/>
-	Закон Республики Беларусь от 10.11.2008 № 455-З «Об информации, информатизации и защите информации».<br/>
1.5.	ОАО «ЦНИИТУ» осуществляет обработку персональных данных на законной и справедливой основе с учетом принципа целевого использования данных.<br/>
1.6.	ОАО «ЦНИИТУ» не осуществляет обработку биометрических персональных данных.<br/>
1.7.	ОАО «ЦНИИТУ» вправе поручить обработку персональных данных субъекта персональных данных другим лицам на основании договора. При этом ответственность перед субъектом персональных данных за действия указанного лица будет нести Оператор.<br/>



2.	Цели обработки персональных данных<br/>
2.1.	Персональные данные обрабатываются ОАО «ЦНИИТУ» в следующих целях:<br/>
2.1.1.	осуществления и выполнения возложенных законодательством Республики Беларусь на Оператора функций, полномочий и обязанностей, в частности:<br/>
-	выполнение требований законодательства в сфере труда и налогообложения;<br/>
-	ведение текущего бухгалтерского и налогового учёта, формирование, изготовление и своевременная подача бухгалтерской, налоговой и статистической отчётности;<br/>
-	выполнение требований законодательства по определению порядка обработки и защиты персональных данных граждан, являющихся работниками, клиентами или контрагентами ОАО «ЦНИИТУ».<br/>
2.1.2.	осуществления прав и законных интересов ОАО «ЦНИИТУ» в рамках осуществления видов деятельности, предусмотренных Уставом и иными локальными нормативными актами ОАО «ЦНИИТУ», или третьих лиц либо достижения общественно значимых целей, в том числе:<br/>
-	в целях организации и проведения ОАО «ЦНИИТУ» программ лояльности, маркетинговых и/или рекламных акций, исследований, опросов;<br/>
-	исполнения ОАО «ЦНИИТУ» обязательств в рамках договора размещения материалов клиентов на сайте <a style={{color: 'green', wordWrap: 'break-word'}} rel="noreferrer" href={'https://clevr.by'}>https://clevr.by</a><br/>
-	продвижения услуг и/или товаров ОАО «ЦНИИТУ» и/или партнеров ОАО «ЦНИИТУ» на рынке путем осуществления прямых контактов с клиентами ОАО «ЦНИИТУ» с помощью различных средств связи, включая, но не ограничиваясь: по телефону с использованием специальных программ обмена сообщений, смс – рассылка сообщений, по электронной почте, почтовой рассылке и иными не запрещенными способами.<br/>
2.1.3.	в иных законных целях.<br/>



3.	Состав персональных данных и сроки их обработки<br/>
3.1.	Все обрабатываемые ОАО «ЦНИИТУ» персональные данные являются конфиденциальной, строго охраняемой информацией в соответствии с законодательством.<br/>
3.2.	Состав персональных данных, подлежащих обработке, в зависимости о целей, сроков и необходимости обработки включает: ФИО; пол; дата и место рождения; паспортные данные; идентификационный номер; номер страхового свидетельства; анкетные и биографические данные; образование; сведения о трудовом и общем стаже; сведения о составе семьи; сведения о воинском учете; сведения о заработной плате; сведения о социальных льготах; специальность; занимаемая должность; наличие судимостей; адрес места регистрации; адрес места жительства; номер домашнего телефона; номер сотового (мобильного телефона), адрес электронной почты; содержание трудового договора; состав декларируемых сведений о наличии материальных ценностей; подлинники и копии приказов по личному составу; информация из личных дел и трудовых книжек работников; основания к приказам по личному составу; сведения о квалификации и переподготовке работников, их аттестации, служебным расследованиям; копии отчетов, направляемые в органы статистики.<br/>
3.3.	ОАО «ЦНИИТУ» в целях надлежащего исполнения своих обязанностей Оператора обрабатывает персональные данные следующих субъектов персональных данных, необходимые для надлежащего исполнения договорных обязательств:<br/>
-	соискателей на замещение вакантных должностей – в составе и в сроки, необходимые для принятия ОАО «ЦНИИТУ» решения о приеме либо отказе в приеме на работу, с согласия субъектов персональных данных, а также для формирования кадрового резерва с согласия субъектов персональных данных;<br/>
-	работников, состоящих или состоявших в трудовых отношениях с ОАО «ЦНИИТУ» – в составе и в сроки, необходимые для достижения целей, предусмотренных законодательством Республики Беларусь, осуществления и выполнения возложенных законодательством Республики Беларусь функций, полномочий и обязанностей, для формирования кадрового резерва с согласия субъектов персональных данных, а также для заключения и исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, в том числе в целях предоставления страхования с согласия субъектов персональных данных;<br/>
-	представителей контрагентов ОАО «ЦНИИТУ» – в составе и в сроки, необходимые для осуществления взаимодействия с поставщиками с согласия субъектов персональных данных;<br/>
-	лиц, получающих доход на основании гражданско-правового договора с Оператором, в составе и в сроки, необходимые для достижения целей, предусмотренных таким гражданско-правовым договором и законодательством Республики Беларусь;<br/>
-	представителей потенциальных и существующих клиентов – в составе и в сроки, необходимые для осуществления взаимодействия с потенциальными и существующими клиентами, с согласия субъектов персональных данных;<br/>
-	иных физических лиц, состоящих в договорных отношениях с ОАО «ЦНИИТУ» - в составе и в сроки, необходимые для осуществления целей взаимодействия.<br/>



4.	Порядок сбора, хранения, передачи и иных видов обработки персональных данных<br/>
4.1.	Обработка персональных данных происходит с использованием и без использования средств автоматизации.<br/>
4.1.1.	Обработка персональных данных, осуществляемая без использования средств автоматизации, осуществляется таким образом, чтобы обеспечивался поиск персональных данных и (или) доступ к ним по определенным критериям (картотеки, списки, базы данных, журналы и другое). Оператором установлен перечень лиц, осуществляющих обработку персональных данных либо имеющих к ним доступ. Обеспечивается раздельное хранение персональных данных (материальных носителей), обработка которых осуществляется в различных целях. Оператор обеспечивает сохранность персональных данных и принимает меры, исключающие несанкционированный доступ к персональным данным.<br/>
4.1.2.	Обработка персональных данных, осуществляемая с использованием средств автоматизации, проводится при условии выполнения следующих действий:<br/>
-	Оператор проводит технические мероприятия, направленные на предотвращение несанкционированного доступа к персональным данным и (или) передачи их лицам, не имеющим права доступа к такой информации;<br/>
-	защитные инструменты настроены на своевременное обнаружение фактов несанкционированного доступа к персональным данным;<br/>
-	технические средства автоматизированной обработки персональных данных изолированы в целях недопущения воздействия на них, в результате которого может быть нарушено их функционирование;<br/>
-	Оператор производит резервное копирование данных, с тем, чтобы иметь возможность незамедлительного восстановления персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;<br/>
-	осуществляется постоянный контроль за обеспечением уровня защищенности персональных данных.<br/>
4.2.	К обработке персональных данных допускаются только работники, прошедшие определенную процедуру допуска, к которой относятся:<br/>
-	ознакомление работника под роспись с локальными нормативными актами, регламентирующими порядок и процедуру работы с персональными данными;<br/>
-	взятие с работника подписки о соблюдении конфиденциальности в отношении персональных данных при работе с ними;<br/>
-	получение работником и использование в работе индивидуальных атрибутов доступа к информационным системам, содержащим в себе персональные данные. При этом каждому работнику выдаются минимально необходимые для исполнения трудовых обязанностей права на доступ в информационные системы персональных данных.<br/>
4.3.	Работники, имеющие доступ к персональным данным, получают только те персональные данные, которые необходимы им для выполнения конкретных трудовых обязанностей.<br/>
4.4.	Персональные данные хранятся в бумажном и электронном виде.<br/>
4.4.1.	В бумажном виде персональные данные хранятся в специально оборудованных металлических шкафах и/или сейфах, либо в иных местах, которые запираются и опечатываются. Ключи находятся у ответственного работника Оператора, назначаемого соответствующего приказом.<br/>
4.4.2.	В электронном виде персональные данные хранятся в базах данных информационных систем персональных данных, а также в архивных (резервных) копиях баз данных этих информационных систем персональных данных.<br/>
4.4.3.	При хранении персональных данных соблюдаются организационные и технические меры по обеспечению их сохранности и исключению несанкционированного доступа к ним, в том числе, но не исключительно:<br/>
-	назначение работника, ответственного за обработку персональных данных;<br/>
-	ограничение физического доступа к местам обработки персональных данных, включая установку запирающих устройств, ограничение круга лиц, имеющих доступ и т.п.;<br/>
-	cъемные электронные носители, на которых хранятся резервные копии персональных данных субъектов персональных данных (при необходимости маркируются и учитываются в журнале регистрации, учета и выдачи внешних носителей для хранения резервных копий персональных данных);<br/>
-	учет всех информационных систем, электронных и бумажных носителей, а также архивных копий;<br/>
-	применение сертифицированных средств защиты информации и средств криптографической защиты информации.<br/>
4.4.4.	Подробный порядок регламентируется внутренними локальными актами Оператора.<br/>
4.5.	Для целей обработки данных ОАО «ЦНИИТУ» может передавать персональные данные:<br/>
-	работникам ОАО «ЦНИИТУ»;<br/>
-	третьим лицам, подписавшим соглашение по обеспечению конфиденциальности и безопасности полученных сведений по поручению ОАО «ЦНИИТУ», где должны быть указаны перечень действий (операций) с персональными данными, которые будут совершаться лицом, осуществляющим обработку персональных данных, и цели обработки, должна быть установлена обязанность такого лица соблюдать конфиденциальность персональных данных и обеспечивать безопасность персональных данных при их обработке, а также должны быть указаны требования к защите обрабатываемых персональных данных в соответствии со статьей 17 Закона Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных»;<br/>
-	по запросу органов дознания и следствия, суда в связи с проведением расследования или судебным разбирательством, по запросу органа уголовно-исполнительной системы в связи с исполнением уголовного наказания и осуществлением контроля за поведением условно осужденного, осужденного, в отношении которого отбывание наказания отсрочено, и лица, освобожденного условно-досрочно.<br/>
4.6.	Передача персональных данных третьим лицам в остальных случаях возможна только с согласия субъекта персональных данных, только на основании соглашения об обработке персональных данных и только с целью исполнения обязанностей перед субъектом персональных данных, если иное не установлено законодательством Республики Беларусь.<br/>
4.7.	При передаче персональных данных в электронном виде третьим лицам по открытым каналам связи ОАО «ЦНИИТУ» обязано принимать все необходимые меры по защите передаваемой информации в соответствии с требованиями законодательства Республики Беларусь в порядке, регламентированным локальными актами.<br/>
4.8.	Внесение изменений в персональные данные субъекта персональных данных производится на основании заявления такого субъекта персональных данных, на основании официальных документов, содержащих персональные данные субъекта персональных данных и в иных случаях, предусмотренных законодательством Республики Беларусь.<br/>
4.9.	Персональные данные хранятся не дольше, чем этого требуют цели их обработки, и подлежат удалению по достижении целей обработки или в случае утраты необходимости в их достижении.<br/>
4.10.	Удаление с носителей и уничтожение носителей, содержащих персональные данные субъектов, должно соответствовать следующим правилам:<br/>
-	быть максимально надежным и	конфиденциальным, исключая возможность последующего восстановления;<br/>
-	оформляться соответствующим актом;<br/>
-	должно проводиться комиссией по удалению персональных данных;<br/>
-	удаление должно касаться только тех персональных данных, которые подлежат удалению в связи с достижением цели обработки указанных персональных данных либо утраты необходимости в их достижении, не допуская случайного или преднамеренного уничтожения актуальных носителей.<br/>

5.	Права и обязанности субъекта персональных данных<br/>
5.1.	Субъект персональных данных имеет право:<br/>
-	требовать внесения изменений в свои персональные данные, бесплатного прекращения обработки своих персональных данных, включая их удаления, при отсутствии оснований для обработки персональных данных, предусмотренных законодательством Республики Беларусь, а также в случае, если в случае, если персональные данные являются неполными, устаревшими или неточными;<br/>
-	на получение информации, касающейся обработки своих персональных данных, содержащей:<br/>
-	наименование (ФИО (если таковое имеется)) и место нахождения (адрес места жительства (места пребывания)) оператора;<br/>
-	подтверждение факта обработки персональных данных оператором (уполномоченным лицом);<br/>
-	его персональные данные и источник их получения;<br/>
-	правовые основания и цели обработки персональных данных;<br/>
-	срок, на который дано его согласие;<br/>
-	наименование и место нахождения уполномоченного лица, которое является государственным органом, юридическим лицом Республики Беларусь, иной организацией, если обработка персональных данных поручена такому лицу;<br/>
-	иную информацию, предусмотренную законодательством Республики Беларусь;<br/>
-	обжаловать в уполномоченный орган по защите прав субъектов персональных данных в порядке, установленном законодательством об обращениях граждан и юридических лиц, действия (бездействие) и решения оператора, нарушающие его права при обработке персональных данных;<br/>
-	на защиту своих прав и законных интересов, в том числе на возмещение убытков, компенсацию морального вреда в судебном порядке;<br/>
-	отозвать свое согласие на обработку персональных данных в любое время без объяснения причин в порядке, предусмотренном законодательством Республики Беларусь.<br/>
5.2.	Получить запрашиваемую информацию субъект персональных данных может, обратившись с письменным заявлением в ОАО «ЦНИИТУ». Ответ, содержащий запрашиваемую информацию, либо мотивированный отказ в ее предоставлении направляется по адресу, указанному в запросе, в течение 15 (пятнадцати) дней.<br/>
5.3.	Порядок обработки запросов субъектов персональных данных по выполнению их законных прав производится согласно утвержденному внутреннему документу, разработанному в соответствии с действующим законодательством в области защиты персональных данных и подконтролен работнику, ответственному за обработку персональных данных после получения заявления субъекта персональных данных.<br/>

6.	Сведения о реализуемых требованиях к защите персональных данных<br/>
6.1.	ОАО «ЦНИИТУ» получает персональные данные от субъектов персональных данных и от третьих лиц (лиц, не являющихся субъектами персональных данных).<br/>
6.2.	Компания прекращает обработку персональных данных в следующих случаях:<br/>
-	при наступлении условий прекращения обработки персональных данных или по истечении установленных сроков;<br/>
-	по достижении целей их обработки либо в случае утраты необходимости в достижении этих целей;<br/>
-	по требованию субъекта персональных данных, а также если обрабатываемые в ОАО<br/>
«ЦНИИТУ» персональные данные являются неполными, устаревшими, неточными или при отсутствии оснований для обработки персональных данных, предусмотренных законодательством Республики Беларусь;<br/>
-	в случае выявления неправомерной обработки персональных данных, если обеспечить правомерность обработки персональных данных невозможно;<br/>
-	в случае отзыва субъектом персональных данных согласия на обработку его персональных данных или истечения срока действия такого согласия (если персональные данные обрабатываются ОАО «ЦНИИТУ» исключительно на основании согласия субъекта персональных данных);<br/>
-	в случае ликвидации ОАО «ЦНИИТУ».<br/>

6.3.	ОАО «ЦНИИТУ» для обеспечения выполнения обязанностей, предусмотренных Законом Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных» и принятыми в соответствии с ним нормативными правовыми актами, приняты следующие меры для защиты персональных данных:<br/>
6.3.1.	назначено лицо, ответственное за осуществление внутреннего контроля за обработкой персональных данных;<br/>
6.3.2.	изданы локальные акты по вопросам обработки и обеспечения безопасности персональных данных, а также локальные акты, устанавливающие процедуры, направленные на предотвращение и выявление нарушений законодательства Республики Беларусь, устранение последствий таких нарушений:<br/>
-	Регламент по обработке персональных данных работников;<br/>
-	настоящая Политика.<br/>
6.3.3.	установлен порядок доступа к персональным данным, в том числе обрабатываемым в информационном ресурсе (системе);<br/>
6.3.4.	организован режим обеспечения безопасности помещений, в которых размещены информационные системы, препятствующий возможности неконтролируемого проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения;<br/>
6.3.5.	осуществляется внутренний контроль соответствия обработки персональных данных требованиям Закона Республики Беларусь от 07.05.2021 № 99-З «О защите персональных данных» и принятых в соответствии с ним нормативных правовых актов, настоящей Политики, локальных актов Компании;<br/>
6.3.6.	работники ОАО «ЦНИИТУ», непосредственно осуществляющие обработку персональных данных, ознакомлены с требованиями по защите персональных данных, настоящей Политикой и локальными актами по вопросам обработки персональных данных, а также обучены в порядке, установленном законодательством;<br/>
6.3.7.	осуществляется техническая и криптографическая защиты персональных данных в порядке, установленном Оперативно-аналитическим центром при Президенте Республики Беларусь, в соответствии с классификацией информационных ресурсов (систем), содержащих персональные данные. В рамках реализации технических мер защиты информации Оператор использует средства защиты информации, прошедшие процедуру оценки соответствия требованиям законодательства Республики Беларусь в области обеспечения безопасности информации.<br/>
 
7.	Положения по конфиденциальности и защите персональной информации на сайте<br/>
7.1.	ОАО «ЦНИИТУ» (далее – «Оператор») осуществляет обработку персональных данных клиентов, собранных на сайте виртуальной выставки, находящегося по адресу: <a style={{color: 'green', wordWrap: 'break-word'}} rel="noreferrer" href={'https://clevr.by'}>https://clevr.by</a> (далее – «сайт»).<br/>
7.2.	К персональным данным, собранным на сайте, относятся:<br/>
-	ФИО;<br/>
-	Дата рождения;<br/>
-	Место работы;<br/>
-	Номер мобильного телефона;<br/>
-	Адрес электронной почты;<br/>
-	Прочая информация, предоставляемая клиентом в бумажных анкетах, анкетах на сайте, при общении с операторами Контакт-центра Оператора и в других источниках.<br/>
7.3.	При регистрации на сайте <a style={{color: 'green', wordWrap: 'break-word'}} rel="noreferrer" href={'https://clevr.by'}>https://clevr.by</a> клиент предоставляет личную информацию, а именно фамилию, имя, отчество (при наличии),  адрес электронной почты и прочие данные, перечисленные в анкете, а Оператор использует эту информацию в соответствии с целями настоящего документа, а также для выполнения своих обязательств перед клиентом.<br/>
7.4.	Клиент имеет право пользоваться всеми разделами личного кабинета на сайте, изменять и удалять информацию о себе.<br/>
7.5.	Полное удаление персональных данных клиента производится после обращения клиента в Контакт-центр Продавца. Срок удаления необходимо уточнить у оператора Контакт-центра.<br/>
7.6.	Оператор не несёт ответственность, если от имени клиента на сайте были совершены противоправные действия, в случае если персональные данные клиента, необходимые для пользования сайтом, были разглашены клиентом самостоятельно или по вине клиента.<br/>
7.7.	Передавая свои персональные данные Оператору, клиент даёт согласие на получение коммуникации от Оператора. Клиент может отозвать своё согласие на получение коммуникации через личный кабинет на сайте или через обращение в Контакт-центр. Есть возможность отказаться от получения коммуникации через один или несколько каналов коммуникации, сохраняя возможность получить сообщения от Оператора по другим каналам.<br/>
7.8.	Коммуникация, получаемая клиентом, в связи с оформлением заказа на сайте / регистрацией на сайте / изменением персональной информации или в связи с другими действиями на сайте, которые инициируются клиентом, отправляется автоматически. От таких сообщений нельзя отписаться, так как они отправляются в целях соблюдения интересов клиента.<br/>
7.9.	Оператор записывает cookies на устройство клиента, которое клиент использует для реализации своих потребностей на сайте. Cookies – это небольшие фрагменты данных, отправленные веб-сервером и хранимые на электронном устройстве клиента. Она используются для упрощения клиентского опыта пользования сайтом и для сбора аналитики Оператором для улучшения качества предоставляемых услуг на сайте.<br/>
«Cookies» не содержат конфиденциальную информацию. Посетитель сайта или клиент настоящим дает согласие на сбор, анализ и использование cookies, в том числе третьими лицами для целей формирования статистики и оптимизации рекламных сообщений.<br/>
7.10.	Оператор получает информацию об ip-адресе посетителя Сайта <a style={{color: 'green', wordWrap: 'break-word'}} rel="noreferrer" href={'https://clevr.by'}>https://clevr.by</a>. Данная информация не используется для установления личности посетителя.<br/>
7.11.	Оператор вправе осуществлять записи телефонных разговоров с клиентом. При этом Оператор обязуется: предотвращать попытки несанкционированного доступа к информации, полученной в ходе телефонных переговоров, и/или передачу ее третьим лицам, не имеющим непосредственного отношения к исполнению заказов, в соответствии с требованиями законодательства Республики Беларусь.<br/>

8.	Информация о продавце<br/>
8.1.	ОАО «ЦНИИТУ», 220033, Республика Беларусь, г. Минск, пр. Партизанский, д.2, корп. 4<br/>
9.	Заключительные положения<br/>
9.1.	Настоящая Политика подлежит изменению, дополнению в случае появления новых законодательных актов и специальных нормативных документов по обработке и защите персональных данных.<br/>
9.2.	Настоящая Политика является внутренним документом ОАО «ЦНИИТУ», и подлежит размещению на сайте ОАО «ЦНИИТУ».<br/>
9.3.	Контроль исполнения требований настоящей Политики осуществляется ответственным за обеспечение безопасности персональных данных ОАО «ЦНИИТУ».<br/>

            </s_c.AgreementContainer_text>
        </s_c.AgreementContainer>
    )
}