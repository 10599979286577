import { useDispatch } from "react-redux"
import { svg_icons } from "../../assets/imgs/svg_icons"
import { CloseButton } from "../../Pages/Registration/Login/style/Login_css"
import { openMessageHint } from "../../store/appSlice/appSlice"
import { AppMessageWrap } from "./appMessage.style"

export const AppMessage = () => {
    const dispatch = useDispatch()
    const {exitIcon, successIcon} = svg_icons
    const message= 'Выполнено успешно!'

    return (
        <AppMessageWrap>
            <div className="info_wrap">
                {successIcon}
                <p>{message}</p>
            </div>
            <CloseButton style={{top: '16px', margin: '0'}} onClick={() => dispatch(openMessageHint(false))}>{exitIcon}</CloseButton>
        </AppMessageWrap>
    )
}