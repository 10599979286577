export const seoModal_en = {
    deleteTag: 'Delete meta tag',
    createTag: 'Create meta tag',
    updateTag: 'Update meta tag',
    title: 'Title',
    description: 'Description',
    send: 'Send',
    delete: 'Delete',
    contentType: 'Content type',
    imageForSocialMedia: 'Picture for social networks',
    file: 'File',
    link: 'Link',
    pc: 'Select a file',
    url: 'Enter link',
}