import styled, { css } from "styled-components";


const WrapAppChat = styled.div`

    display: flex;
    flex: 1;
    background: #ffffff;


    //MOBILE
    ${({mobile}) => mobile && css`
        top: auto;
        left: auto;
        bottom: 16px;
        right: 16px;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        width: 360px;
        overflow: hidden;
    `}
`;

export {
    WrapAppChat
}