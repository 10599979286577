import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { AddNewPavilion } from "./AddNewPavilion"

export const AddNewPavilionContainer = ({localeState, handlerActions}) => {
    const {expanents} = useSelector((state) => state.admin)
    
    const [checkBoxState, setCheckBoxState] = useState([])
    const [AllCheckBox, setAllCheckBox] = useState(false)

    useEffect(() => {
        let expForCheckbox = expanents.map((company) => ({id: company.id, check: false}));
        setCheckBoxState((state) => ([...state, ...expForCheckbox]))
    }, [expanents])

    const checkAction = (companyId) => {
        const isCheck = checkBoxState.filter((item) => item.id === companyId)
        return isCheck[0]?.check
    }
    const findCheck = (checkBoxState) => {
        const findElem = checkBoxState.find((el) => el.check === true)
        if(findElem === undefined){
            return false
        }else{
            return true
        }
    }

    return(
        <AddNewPavilion 
        stateCheckBox= {[checkBoxState, setCheckBoxState]}  
        handlerActions = {handlerActions} 
        localeState = {localeState} 
        stateAllCheckBox={[AllCheckBox, setAllCheckBox]}
        checkAction={checkAction}
        findCheck={findCheck}
        />
    )
}