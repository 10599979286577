
import { css } from 'styled-components';

const theme = {
    iconButton: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #F4F4F4;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    `,
    heightFooter: "265px",
};

export {
    theme,
}