import {ErrorMessage, Field, Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {svg_icons} from "../../../assets/imgs/svg_icons";
import {setAuthState} from "../../../store/authSlice/authSlice";
import {SendRecoverPassword} from "../../../store/profileSlice/profileAsyncThunk";
import {RecoverSchema} from "../../../validation/validation";
import {Line} from "../../Account/SelectExhibitors/style/SelectExhibitors_css";
import {FieldWrap} from "../../Account/Settings/settings.style";
import {
  CloseButton,
  Desc,
  ItemInfo,
  ModalContainer,
  ModalOpen,
  Title,
} from "../Login/style/Login_css";
import {
  GoBack,
  RecoverWrap,
  RecText,
  SendEmail,
} from "./style/RecoverPassword_css";

const initialValues = {
  email: "",
};

export const RecoverPassword = ({
  setRecover,
  closeBtnEvent,
  RegistrationModal,
}) => {
  let DescWidth = 210;
  const {exitIcon} = svg_icons;
  const dispatch = useDispatch();
  const {
    forms: {forgetPassword, base},
  } = useSelector((state) => state.app.appText);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton
          onClick={RegistrationModal ? closeBtnEvent : () => setRecover(false)}
        >
          {exitIcon}
        </CloseButton>
        <RecoverWrap>
          <Title>{forgetPassword.head}</Title>
          <Desc width={DescWidth}>{forgetPassword.subhead}</Desc>
          <Formik
            initialValues={initialValues}
            validationSchema={RecoverSchema}
            onSubmit={(values) => {
              const SendValues = {
                email: values.email,
              };
              dispatch(SendRecoverPassword(SendValues));
              setRecover(false);
              dispatch(setAuthState({key: "open", value: false}));
            }}
          >
            {({dirty, handleSubmit, isValid, values}) => (
              <Form>
                <FieldWrap>
                  <div className="RecoveryEmail">
                    <label htmlFor="email">
                      <p>Email*</p>
                    </label>
                  </div>
                  <div className="email">
                    <Field name="email" placeholder={base.emailPl} />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <RecText>{forgetPassword.addDesc}</RecText>
                <div className="Recover_Btn">
                  <GoBack type="button" onClick={() => setRecover(false)}>
                    {forgetPassword.back}
                  </GoBack>
                  <SendEmail
                    style={
                      !isValid || !Boolean(values.email)
                        ? {cursor: "no-drop", opacity: "0.5"}
                        : null
                    }
                    type="submit"
                    disabled={!isValid || !Boolean(values.email)}
                    onClick={handleSubmit}
                  >
                    {forgetPassword.getLink}
                  </SendEmail>
                </div>
              </Form>
            )}
          </Formik>
          {RegistrationModal ? (
            <>
              <Line></Line>
              <ItemInfo style={{paddingBottom: "16px"}}>
                <Title>{forgetPassword.registr}</Title>
                <Desc width={DescWidth}>{forgetPassword.notAccount}</Desc>
                <button
                  onClick={() =>
                    dispatch(
                      setAuthState({key: "openRegistration", value: true})
                    )
                  }
                >
                  {forgetPassword.register}
                </button>
              </ItemInfo>
            </>
          ) : null}
        </RecoverWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
