import { useDispatch, useSelector } from "react-redux"
import { svg_icons } from "../../../../../assets/imgs/svg_icons"
import { AppSwitch } from "../../../../../components/AppSwitch/AppSwitch"
import { SeoModal } from "../../../../SeoModal/SeoModal"
import { SeoSettingsWrap } from "../../Companies/CreateCompany/CreateCompany.style"
import { CreateNewsContainer, CreateNewsWrap, NewsTitle } from "./createNews.style"
import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import { SlickArrowRight } from "../../../../SlickArrowComponent/SlickArrowComponent"
import { useEffect } from "react"
import { resetNewsState, setInfoNewNews } from "../../../../../store/newsSlices/NewsSlices"
import { serverUrl } from "../../../../../API/api"
import { Editor } from "@tinymce/tinymce-react"
import { ScrollBarWrap } from "../../AdminPanelContent/AdminPanelContent.style"
import { resetMetaTagsState } from "../../../../../store/MetaTagsSlice/MetaTagsSlice"


export const CreateNews = ({localState, handlerActions, editorSettings}) => {
    const { addIcon, trashIcon, plusIcon, discardIcon, uncoverLeftIcon} = svg_icons

    const {createNews} = useSelector((state) => state.news)
    const dispatch = useDispatch()

    const sliderSettings = {
        prevArrow: false,
        nextArrow: <SlickArrowRight/>,
        variableWidth: true,
        infinite: true,
        centerMode: true,
      };

      const ValidForm = Boolean(createNews.name.by) && Boolean(createNews.name.ru) 
      && Boolean(createNews.name.en) && Boolean(createNews.text.by) 
      && Boolean(createNews.text.ru) && Boolean(createNews.text.en) 
      && Boolean(createNews.shortName.ru) && Boolean(createNews.shortName.en)
      && Boolean(createNews.shortName.en) && Boolean(createNews.media.length)
      && Boolean(createNews.newsAuthor)

      useEffect(() => {

        switch (localState.contentParam) {
            case "drafts-news":
                dispatch(setInfoNewNews({
                  type: "draft",
                  value: true,
                }));
            break;
            case "main-news":
                dispatch(setInfoNewNews({
                  type: "mainNews",
                  value: true,
                }));
            break;
            default:
                break;
        }

        return () => {
            dispatch(resetNewsState("createNews"))
        }
      }, [dispatch, localState.contentParam])

      useEffect(() => {
        return () => {
            dispatch(resetMetaTagsState('metaError'))
        }
      }, [dispatch])

    return (
        <CreateNewsWrap>
            <NewsTitle>
              <div className="title_wrap">
                <button onClick={handlerActions("setModalCreate", {newsId: '', open: false})}>{uncoverLeftIcon}</button>
                <h3>{localState.openModalCreate.type ==='create' ? 'Новая новость' : 'Редактирование'}</h3>
              </div>
              <div className="btns_wrap">
              <button className="add_drafts" onClick={handlerActions("saveDraftNews", !createNews.draft)}>{createNews.draft ? 'Убрать из черновика' : 'Сохранить черновик'}</button>
                <div>
                    <button className="preview" disabled={true} style={{opacity: 0.5}}>{'Предпросмотр'}</button>
                    <button className="save_news" disabled={!ValidForm} style={ValidForm ? {opacity: 1} : {opacity: 0.5}} onClick={handlerActions(localState.openModalCreate.newsId ? "updateNews" : "createNews")}>{'Опубликовать'}</button>
                </div>
              </div>
            </NewsTitle>
            <ScrollBarWrap style={{height: '100%', marginRight: '4px', marginTop: '4px'}}>
            <CreateNewsContainer>
              <SeoSettingsWrap style={{marginTop: '22px'}}>
            <div className="general_seo_data">
              <p className={`text_total_data ${localState.content === "total" ? "active_total" : ''}`} onClick={handlerActions("setSeoContent", 'total')}>{"Общие данные:"}</p>
              <button
                className={`seo_language ${
                  localState.language === "ru" ? "active" : ""
                }`}
                onClick={handlerActions("changeLang", "ru")}
              >
                {"РУС"}
              </button>
              <button
                className={`seo_language ${
                  localState.language === "en" ? "active" : ""
                }`}
                onClick={handlerActions("changeLang", "en")}
              >
                {"ENG"}
              </button>
              <button
                className={`seo_language ${
                  localState.language === "by" ? "active" : ""
                }`}
                onClick={handlerActions("changeLang", "by")}
              >
                {"БЕЛ"}
              </button>
            </div>
            <button onClick={handlerActions("setSeoContent", 'seo')} className={`open_seo_settings ${localState.seoContent === "seo" ? "active_seo" : ''}`}>{"SEO настройки"}</button>
          </SeoSettingsWrap>
          {
            localState.seoContent === 'seo' ? 
            <SeoModal componentState={localState} handlerActions={handlerActions}/> 
            :
            <>
          <div className="name_news">
            <div className="short_name">
                <p className="header_text">{'Короткое название'}</p>
                <input value={createNews.shortName[localState.language]} name="shortName" onChange={handlerActions("setInfoNewNews", 'input')} className="input_text" style={{height: '36px'}} placeholder={'Введите  название'} />
            </div>
            <div className="full_name">
                <p className="header_text">{'Название'}</p>
                <input value={createNews.name[localState.language]} name="name" onChange={handlerActions("setInfoNewNews", 'input')} className="input_text" style={{height: '36px'}} placeholder={'Введите  название'} />
            </div>
          </div>
          <div className="drafts">
             <p className="header_text">{'Обложка'}</p>
             <div className="drafts_info">
                <p className="drafts_text">{'Дублировать обложку внутри новости'}</p>
                <AppSwitch name={'duplicatePreview'} checked={createNews.duplicatePreview} onChange={handlerActions("setInfoNewNews", 'check')}/>
             </div>
          </div>
          <div className="text_news">
            <p className="header_text">{'Текст'}</p>
            {/*<textarea value={createNews.text[localState.language]} name="text" onChange={handlerActions("setInfoNewNews", 'input')} style={{height: '64px'}} placeholder={'Введите  текст новости'} />*/}
            <Editor {...editorSettings}/>
          </div>
          <div className="gallery_news">
          <div className="head_gallery_wrap">
            <p className="header_text">{'Галерея новости'}</p>
            {createNews.media.length > 2 ? 
                <label className="add_file" style={{height: '30px', borderRadius: '8px', width: '158px'}}>
            <>
                  <p className="add_gallery">
                    {addIcon} {"Добавить файл"}
                  </p>
                  <input
                    accept={'image/png, image/gif, image/jpeg'}
                    type={"file"}
                    onChange={handlerActions("setMediaNewNews")}
                  />
                </>
            </label>: null}
            </div>
            <div className="gallery_files_wrap">
            {createNews.media.length > 3 ?
            <Slider {...sliderSettings}>
                {createNews.media.map((media, index) => {
                    return (
                      <div className="media_content_wrap" key={index}>
                        <img alt="media_content" src={
                            media.id
                              ? `${serverUrl}/api/media/download/${
                                  media.id
                                }`
                              : media.src
                          } />
                        <div className="btns_edit_wrap">
                          <button
                            className="delete"
                            onClick={handlerActions("deleteMedia", index)}
                          >
                            {trashIcon}
                          </button>
                        </div>
                        <div className="add_preview">
                        {media.preview ? (
                            <div 
                            className="wrap_preview" 
                            onClick={handlerActions("setPreviewMedia", {
                                ...media,
                                index,
                              })}>
                              {discardIcon}{"Обложка"}
                            </div>
                          ) : 
                          createNews.media.some((item) => item.preview) &&
                            !media.preview ? null : (
                            <button
                              onClick={handlerActions("setPreviewMedia", {
                                ...media,
                                index,
                              })}
                            >
                              {plusIcon} Обложка
                            </button>
                          )}
                        </div>
                      </div>
                    );
                })}
            </Slider>
            :
            <>
            {createNews.media.map((media, index) => {
                if(media.remove) return null
                    return (
                      <div className="media_content_wrap" key={index}>
                        <img
                          alt="media_content"
                          src={
                            media.id
                              ? `${serverUrl}/api/media/download/${
                                  media.id
                                }`
                              : media.src
                          }
                        />
                        <div className="btns_edit_wrap">
                          <button
                            className="delete"
                            onClick={handlerActions("deleteMedia", media?.id ? media : index)}
                          >
                            {trashIcon}
                          </button>
                        </div>
                        <div className="add_preview">
                          {media.preview ? (
                            <div 
                            className="wrap_preview" 
                            onClick={handlerActions("setPreviewMedia", {
                                ...media,
                                index,
                              })}>
                              {discardIcon}{"Обложка"}
                            </div>
                          ) : 
                          createNews.media.some((item) => item.preview) &&
                            !media.preview ? null : (
                            <button
                              onClick={handlerActions("setPreviewMedia", {
                                ...media,
                                index,
                              })}
                            >
                              {plusIcon} Обложка
                            </button>
                          )}
                        </div>
                      </div>
                    );
                })}
            <label className="add_file" style={{height: '190px'}}>
            <>
                  <p className="add_gallery">
                    {addIcon} {"Добавить файл"}
                  </p>
                  <input
                    accept={'image/png, image/gif, image/jpeg'}
                    type={"file"}
                    onChange={handlerActions("setMediaNewNews")}
                  />
                </>
            </label>
            </>
            }
            </div>
          </div>
          <div className="author_news">
                <p className="header_text">{'Автор'}</p>
                <input value={createNews.newsAuthor} name="newsAuthor" onChange={handlerActions("setNewsAuthor")} className="input_text" style={{height: '36px'}} placeholder={'Введите ФИО'} />
          </div>
          </>
          }
          </CreateNewsContainer>
          </ScrollBarWrap>
          </CreateNewsWrap>
    )
}