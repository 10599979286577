import {CloseButton, Desc, ModalContainer, ModalOpen, RegInput, Title } from "./Login/style/Login_css"
import { ActiveRegBtn, CheckAgreement, 
    GoBack, MoreContacts, ModalContent, RegistrationWrap, 
    SecurityWrap, UserData } from "./style/Registration_css"
import { Line } from "../Account/SelectExhibitors/style/SelectExhibitors_css"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { RegSchema } from "../../validation/validation"
import { Formik } from "formik"
import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RegisterUser } from "../../store/profileSlice/profileAsyncThunk"
import { setAuthState } from "../../store/authSlice/authSlice"
import { CheckBox } from "../../components/AppCheckBox/AppCheckBox"
import { svg_icons } from "../../assets/imgs/svg_icons"

const initialValues = {
    name: '',
    surname: '',
    patronymic: '',
    password: '',
    email: '',
    equalPass: '',
    tel: '',
}


export const Registration = () => {

    const {phone, securityIcon, peopleIcon, eyeIcon, crossEyeIcon, exitIcon} = svg_icons
    const [isCheck, setIsCheck] = useState(false)
    let DescWidth = 300;

    const {language, errorsValid } = useSelector((state) => state.app.appText);

    const [typeIPass, setTypeIPass] = useState('password')
    const [eyePass, setEyePass] = useState()
    const [typeIRepPass, setTypeRepPass] = useState('password')
    const [eyeRep, setEyeRep] = useState()
    const dispatch = useDispatch()
    const { openRegistration } = useSelector(state => state.auth)
    const {forms: {registr, base}} = useSelector((state) => state.app.appText)

    useEffect(() => {
        typeIPass === 'password' ? setEyePass(eyeIcon) : setEyePass(crossEyeIcon)
        typeIRepPass === 'password' ? setEyeRep(eyeIcon) : setEyeRep(crossEyeIcon)
    }, [crossEyeIcon, eyeIcon, typeIPass, typeIRepPass])
   

    if(!openRegistration) return null

    const CloseModals = () => {
        dispatch(setAuthState({key: "openRegistration", value: false}))
        dispatch(setAuthState({key: "open", value: false}));
        document.body.style.overflow = ''
    }

    return (
        <ModalContainer>
        <ModalOpen maxWidth = {550}>
        <CloseButton onClick = {CloseModals}>{exitIcon}</CloseButton>
        <RegistrationWrap>
            <Title>{registr.head}</Title>
            <Desc width = {DescWidth}> {registr.subhead1} <br/> {registr.subhead2} </Desc>
            <ModalContent>
                <p>{peopleIcon}{registr.blockUserH1}</p>
                <Line></Line>
            </ModalContent>
            <Formik  initialValues={initialValues} validationSchema = {RegSchema} onSubmit = {(value) => {
                const SendValues = {
                    password: value.password,
                    firstName: value.name,
                    lastName: value.surname,
                    phone: value.tel,
                    email: value.email,
                    patronymic: value.patronymic ,
                    language
                }
                dispatch(RegisterUser(SendValues))
                document.body.style.overflow = ''
                CloseModals()
            }}>
            {({errors, touched, values, handleChange, handleSubmit, isValid}) => 
            (
                <Fragment>
                <UserData>
            <div className="SurName">
                <p>{registr.lastName}*</p>
                <RegInput name="surname" placeholder={registr.lastNamePl} onChange={handleChange} />
                {
                    (errors.surname && values.surname)
                    ||
                    (errors.surname && touched.surname) 
                     ? 
                    ( <p className="Invalid_Input">{errors.surname}</p> ) : 
                    null
                }
            </div>
            <div className="Name_Patronymic">
                <div className="Name">
                    <p>{base.firstName}*</p>
                    <RegInput name="name" placeholder={base.firstNamePl} onChange={handleChange} />
                    {
                        (errors.name && values.name)
                        ||
                        (errors.name && touched.name) 
                         ? 
                        (<p className="Invalid_Input">{errors.name}</p> ) : 
                        null
                    }
                </div>
                <div className="Patronymic">
                    <p>{registr.patronymic}</p>
                    <RegInput name="patronymic" placeholder={registr.patronymicPl} onChange={handleChange} />
                    {
                        (errors.patronymic && values.patronymic)
                        ||
                        (errors.patronymic && touched.patronymic) 
                        ? 
                        ( <p className="Invalid_Input">{errors.patronymic}</p> ) : 
                        null
                    }
                </div>
            </div>
            </UserData>
            <ModalContent>
                <p>{securityIcon}{registr.blockSecurityH1}</p>
                <Line></Line>
            </ModalContent>
            <SecurityWrap>
                <div className="Email">
                        <p>Email*</p>
                        <RegInput name="email" placeholder={base.emailPl} onChange={handleChange} />
                        {
                        (errors.email && values.email)
                        ||
                        (errors.email && touched.email) 
                        ? 
                        ( <p className="Invalid_Input">{errors.email}</p> ) : 
                        null
                    }
                </div>
            <div className="Password_PasswordRepeat">
                <div className="Password">
                    <p>{base.password}*</p>
                    <RegInput name="password" type={typeIPass} placeholder={base.passwordPl} onChange = {handleChange}/>
                    {
                        (errors.password && values.password) 
                        ||
                        (errors.password && touched.password) 
                         ? 
                        ( <p className="Invalid_Input">{errors.password}</p> ) : 
                        null
                    }
                    <button onClick={() => typeIPass === 'password' ? setTypeIPass('text') : setTypeIPass('password')}>{eyePass}</button>
                </div>
                <div className="PasswordRepeat">
                    <p>{base.repeatPassword}*</p>
                    <RegInput name="equalPass" type={typeIRepPass} placeholder={base.repeatPasswordPl} onChange = {handleChange}/>
                    {
                        (errors.equalPass && values.equalPass) 
                        ||
                        (errors.equalPass && touched.equalPass)
                        ? 
                        ( <p className="Invalid_Input">{errors.equalPass}</p> ) : 
                        null
                    }
                    <button onClick={() => typeIRepPass === 'password' ? setTypeRepPass('text') : setTypeRepPass('password')}>{eyeRep}</button>
                </div>
            </div>
            </SecurityWrap>
            <ModalContent>
                <p>{phone}{registr.blockAdditH1}</p>
                <Line></Line>
            </ModalContent>
            <MoreContacts>
                <div className="Tel">
                    <p>{base.phone}</p>
                    <RegInput 
                    name="tel" 
                    type={'tel'} 
                    placeholder={'+ _ _ _ (_ _) _ _ _  _ _  _ _'} 
                    onChange={handleChange} />
                    {
                        (errors.tel && values.tel) 
                        ||
                        (errors.tel && touched.tel)
                        ? 
                        ( <p className="Invalid_Input">{errors.tel}</p> ) : 
                        null
                    }
                </div>
            </MoreContacts>
            <CheckAgreement>
                <CheckBox onChange={(e) => setIsCheck(e.target.checked)} checked={isCheck} />
                <p>{registr.agreement1}{' '} 
                <Link to = {'/agreement'} target={'_blank'}>{registr.agreement2}</Link>{' '}
                {' '}
                <Link to = {'/policy-agreement'} target={'_blank'}>{registr.agreement3}</Link>
                </p>
            </CheckAgreement>
            {!isCheck ? <p className="Invalid_Input">{errorsValid.userAgreementNotChecked}</p> : null}
            <div className="modal_btns" style={{display: 'flex'}}>
                <GoBack onClick={() => dispatch(setAuthState({key: "openRegistration", value: false}))}>{base.cancel}</GoBack>
                <ActiveRegBtn 
                style={!isValid || !isCheck ? {cursor: 'no-drop', opacity: '0.5'} : null} 
                type="submit" 
                disabled = {!isCheck || !isValid} 
                onClick = {handleSubmit}>
                {registr.submit}
                </ActiveRegBtn>
            </div>
            </Fragment>
            )
            }
            </Formik>
        </RegistrationWrap>
        </ModalOpen>
        </ModalContainer>
    )
}