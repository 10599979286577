import styled from "styled-components";

const ManualSection = styled.div`
    background: #FFFFFF;
    border-top: 0.5px solid #D4D4D4;
    margin-top: 64px;
`;

const ManualWrap = styled.div`
    text-align: center;
`

const Title = styled.h1`
font-family: 'Helvetica';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 110%;
color: #363636;
padding-top: 36px;
padding-bottom: 12px;
`

const Desc = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #484848;
    max-width: 443px;
    margin: 0 auto;
`;

const ManualTextWrap = styled.div`
text-align: left;
    margin: 0 auto;
    max-width: 874px;
h3{
    font-weight: 700;
font-size: 15px;
line-height: 140%;
color: #363636;
}
`

const ManualText = styled.p`
font-weight: 400;
font-size: 15px;
line-height: 140%;
color: #363636;
padding-bottom: 32px;
`;

const BtnManualText = styled.button`
    width: 147px;
    height: 30px;
    background: #F4F4F4;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    padding: 5px 12px;
    justify-content: space-between;
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 13px;
    cursor: pointer;
    margin: 0 auto;
`;

const ContactsClevr = styled.div`
    padding: 50px 0;
    text-align: center;
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #363636;
  }
  .contacts-subhead {
    display: flex; 
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px 0 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #484848;
  } 

  .contacts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 50px;
    margin: 0 16px;
  }

    .contact__head {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      p {
        padding-left: 8px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
      }
    }

    .contact__desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      color: #484848;
    }
  .contact_backgroundGrey{
    height: 88px;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 24%;
    min-width: 300px;
  }
  .ContactUs{
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: #484848;
    padding-bottom: 15px;
  }
  .ContactUs_Btn{
    font-family: 'Gilroy';
    width: 176px;
    height: 34px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #FFFFFF;
    background: #34C57F;
    border: 1.4px solid #34C57F;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export {
    ManualWrap, Title, 
    Desc, ManualSection, 
    ManualTextWrap, ManualText, 
    BtnManualText, ContactsClevr
}