import React from "react";
import { WrapModalBackground } from "./modalBackground.style";

const ModalBackground = ({ onClose, children }) => {
  
  return(
  <WrapModalBackground className="modal-background">
    <div className="background" onClick={onClose}/>
    {children}
  </WrapModalBackground>
)};

export default ModalBackground;
