import styled from "styled-components";

const SeoModalWrap = styled.div`
input{
    width: 100%;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 0 12px;
    border: none;
    outline: none;
    background: #F5F5F5;
    border-radius: 4px;
    height: 36px;
}
.head_text{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
}
.header_seo{
    margin-top: 20px;
}
.description_seo{
    margin: 20px 0;
    textarea{
        width: 100%;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 9px 12px;
        height: 444px;
    }
}
`;

export {
    SeoModalWrap
}