import styled from "styled-components";

const AccFooterWrap = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background: #000000;
    color: #FFFF;
    justify-content: space-between;
    padding: 0 16px;
    max-width: 1920px;
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
`;

export {AccFooterWrap}