import React from "react";
import { icons_svg } from "../../Exhibition/Company/imgs/icons.svg";
import { followIcons } from "../follow.icons";
import * as s_c from "./style/benefits_css";

export const Benefits = ({localText}) => {
    const {avatarIcon, directLinkIcon} = followIcons
    const {cude3D, visit3D} = icons_svg

  return (
    <s_c.BenefitsSection id="BenefitsMembers">
      <s_c.BenefitsHead>
        <h2>{localText.benefitsHead}</h2>
        <p>{localText.benefitsSubhead}</p>
      </s_c.BenefitsHead>
      <s_c.BenefitsBlocks>
        <s_c.BlockContent>
          {visit3D}
          <h3>{localText.stand}</h3>
          <p>{localText.standDesc}</p>
        </s_c.BlockContent>
        <s_c.BlockContent>
        {avatarIcon}
          <h3>{localText.avatar}</h3>
          <p>{localText.avatarDesc}</p>
        </s_c.BlockContent>
        <s_c.BlockContent>
        {cude3D}
          <h3>{localText.cube3d}</h3>
          <p>{localText.cube3dDesc}</p>
        </s_c.BlockContent>
        <s_c.BlockContent>
        {directLinkIcon}
          <h3>{localText.link}</h3>
          <p>{localText.linkDesc}</p>
        </s_c.BlockContent>
      </s_c.BenefitsBlocks>
    </s_c.BenefitsSection>
  );
};
