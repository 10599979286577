import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { svg_icons } from "../../../assets/imgs/svg_icons"
import { ChangeVisibility } from "../../../store/exhibitorSlice/exhibitorAsyncThunk"
import { CloseButton, Desc, ModalContainer, ModalOpen, Title } from "../../Registration/Login/style/Login_css"
import { VisibilityPageWrap } from "./visibilityPage.style"

export const VisibilityPage = ({setOpenModal}) => {
    const {exitIcon, eyeIcon, crossEyeIcon} = svg_icons
    const {userData} = useSelector((state) => state.profile)
    const [isShow, setIsShow] = useState(userData?.companyInfo?.isShow)
    const dispatch = useDispatch()


    return(
        <ModalContainer>
            <ModalOpen maxWidth ={372}>
                <CloseButton onClick={() => setOpenModal(false)}>{exitIcon}</CloseButton>
                <VisibilityPageWrap>
                    <Title>{'Видимость страницы'}</Title>
                    <Desc width={260}>{' Настройте видимость страницы на сайте. Кто может видеть эту страницу'}</Desc>
                    <div className="choose_visibility">
                        <button className={`visibility_company ${isShow ? '' : 'active'}`} onClick={() => setIsShow(false)}>{crossEyeIcon}{'Только я'}</button>
                        <button className={`visibility_company ${isShow  ? 'active' : ''}`} onClick={() => setIsShow(true)}>{eyeIcon}{'Все пользователи'}</button>
                    </div>
                    <div className="Btns_wrap">
                        <button className="Back" onClick={() => setOpenModal(false)}>{'Отменить'}</button>
                        <button className="save_btn" onClick={() => {
                            dispatch(ChangeVisibility(isShow))
                            setOpenModal(false)
                            }}>{'Сохранить'}</button>
                    </div>
                </VisibilityPageWrap>
            </ModalOpen>
        </ModalContainer>
    )
}