import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddGalleryImage, AddGalleryVideo, AddNewContactDepartment, DeleteDepartmentContact, DeleteGalleryMedia, UpdateCompanyText, UpdateDepartmentContact } from "../../../../store/exhibitorSlice/exhibitorAsyncThunk";
import { AccFooter } from "../../../Account/AccountFooter/AccFooter";
import { ContactsPage } from "../../../Account/ExhibitorPage/ContactsPage/ContactsPage";
import { EditCompanyPageWrap } from "./editCompanyPage.style";
import { HeadEditCompanyPage } from "./HeadEditCompanyPage";
import { PavilionsGallery } from "../../../Account/ExhibitorPage/PavilionsGallery/PavilionsGallery";
import { AboutCompany } from "../../../Account/ExhibitorPage/AboutCompany/AboutCompany";


export const EditCompanyPage = ({component}) => {
    const {companyDepartments, text} = useSelector((state) => state.exhibitor)
    const {company} = useSelector((state) => state.pavilions)
    const dispatch = useDispatch()
    let companyId = company?.id
    console.log(company)

    const [checkText, setCheckText] = useState(
        Boolean(
            text?.by !== company?.text?.by ||
            text?.ru !== company?.text?.ru ||
            text?.en !== company?.text?.en
        )
      );

      useEffect(() => {
        setCheckText(
          Boolean(
            text?.by !== company?.text?.by ||
              text?.ru !== company?.text?.ru ||
              text?.en !== company?.text?.en
          )
        )}, [
            company?.text?.by, 
            company?.text?.en, 
            company?.text?.ru, 
            text?.by, 
            text?.en, 
            text?.ru
        ])

    const [localeState, setLocaleState] = useState({
        localeGalleryItems: {
          removeItems: [],
          addItems: [],
          validate: false,
        },
        representatives: {
          validate: false,
        },
        documents: {
          validate: false,
        },
        departments: {
            validate: false
        },
        contacts: {
            validate: false
        }
      });
      const {profile: {exhibitorPage}} = useSelector((state) => state.app.appText)
      const setTitle = (key) => {
        switch (key) {
            case 'head-screen':
                return exhibitorPage.h1.headScreen
            case 'company-desc':
                return exhibitorPage.h1.companyDesc
            case 'representatives':
                return exhibitorPage.h1.representatives
            case 'pavilions-gallery':
                return exhibitorPage.h1.pavilionsGallery
            case 'documents':
                return exhibitorPage.h1.documents
            case 'contacts':
                return exhibitorPage.h1.contacts
            default:
                return exhibitorPage.h1.mainScreen
        }
    }
      const setDisabled = (content) => {
        switch (content) {
          case "main":
            return false;
          case 'company-desc':
            return !checkText
          case "pavilions-gallery":
            return !localeState.localeGalleryItems.validate;
          case "representatives":
            return !localeState.representatives.validate;
          case "documents":
            return !localeState.documents.validate;
          case "contacts":
            return !localeState.contacts.validate
          default:
            return true;
        }
      };
      const setValidForm = (content) => {
        switch (content) {
          case "main":
            return { opacity: "1" };
          case 'company-desc':
            return checkText 
            ? { opacity: "1" }
            : { opacity: "0.5" };
          case "pavilions-gallery":
            if (!localeState.localeGalleryItems.validate) {
              return { opacity: "0.5" };
            } else {
              return { opacity: "1" };
            }
          case "representatives":
            return localeState.representatives.validate
              ? { opacity: "1" }
              : { opacity: "0.5" };
          case "documents":
            return localeState.documents.validate
              ? { opacity: "1" }
              : { opacity: "0.5" };
          case "contacts": 
            return localeState.contacts.validate
            ? { opacity: "1" }
            : { opacity: "0.5" };
          default:
            return { opacity: "0.5" };
        }
      };
    

    const buildComponent = (component) => {
        switch (component) {
            case 'company-desc':
                return <AboutCompany/> 
            case 'contacts':
                return <ContactsPage/>
            case 'pavilions-gallery':
                return <PavilionsGallery/>
            default:
                break;
        }
    }
    const handlerActions = (content, action) => (e) => {
        switch (content) {
            case "company-desc":
                dispatch(UpdateCompanyText({companyId, body: {text}}));
                setCheckText(false);
                break;
          case "pavilions-gallery":
            if (localeState.localeGalleryItems.addItems.length !== 0) {
              localeState.localeGalleryItems.addItems.map((item) => {
                if (item.type === "image") {
                  return dispatch(AddGalleryImage({companyId, body: item}));
                } else {
                  return dispatch(AddGalleryVideo({companyId, body: item}));
                }
              });
              setLocaleState((state) => {
                return {
                  ...state,
                  localeGalleryItems: {
                    ...state.localeGalleryItems,
                    addItems: [],
                    validate: false,
                  },
                };
              });
            }
            if (localeState.localeGalleryItems.removeItems.length !== 0) {
              localeState.localeGalleryItems.removeItems.map((item) =>
                dispatch(DeleteGalleryMedia({companyId, body: item}))
              );
              setLocaleState((state) => {
                return {
                  ...state,
                  localeGalleryItems: {
                    ...state.localeGalleryItems,
                    removeItems: [],
                    validate: false,
                  },
                };
              });
            }
            break;
        /*  case "representatives":
            representatives.map((el) => {
              if (el.update) {
                dispatch(ChangeVisibleRepresentative(el));
              } else if (!el.userId) {
                dispatch(AddRepresentatives(el));
              } else if (el.remove) {
                dispatch(DeleteRepresentatives(el));
              }
              setLocaleState((state) => {
                return {
                  ...state,
                  representatives: { ...state.representatives, validate: false },
                };
              });
              return el;
            });
            break;
          case "documents":
            companyDocuments.map((el) => {
              if (el.update || !el.id) {
                dispatch(UpdateCompanyDocuments(el));
              } else if (el.remove) {
                dispatch(DeleteCompanyDocuments(el.id));
              }
              setLocaleState((state) => {
                return {
                  ...state,
                  documents: { ...state.documents, validate: false },
                };
              });
              return el;
            });
            break;*/
          case "contacts": 
          companyDepartments.forEach((department) => {
          department.companyDepartmentContacts.forEach((contact) => {
            if(!contact.id){
                dispatch(AddNewContactDepartment({companyId ,body: {...contact, departmentId: department.id}}))        
            }else if(contact.remove){
                dispatch(DeleteDepartmentContact({companyId ,body: {...contact, departmentId: department.id}}))
            }else if(contact.update){
                dispatch(UpdateDepartmentContact({companyId ,body: {...contact, departmentId: department.id}}))
            }
          })
        })
        setLocaleState((state) => {
            return {
              ...state,
              contacts: { ...state.contacts, validate: false },
              departments: {...state.departments, validate: false}
            };
          });
          break;
          default:
            break;
        }
      };

    return (
        <EditCompanyPageWrap>
            <HeadEditCompanyPage 
            component={component}
            setTitle={setTitle} 
            setDisabled={setDisabled} 
            setValidForm={setValidForm} 
            localeState = {localeState} 
            setLocaleState={setLocaleState} 
            handlerActions = {handlerActions}
            />
            {buildComponent(component)}
            <AccFooter position={'absolute'}/>
        </EditCompanyPageWrap>
    );
}