export const profile_ru = {
    statistics: {
        head: "Статистика",
        timePeriod: "Временной период",
        today: "Сегодня",
        week: "Неделя",
        month: "Месяц",
        year: "Год",
        setPeriod: "Задать период",
        periodSelection: "Выбор периода",
        apply: "Применить",
        downloadReport: "Скачать отчёт",
    },
    menu: {
        userPanel: "Панель пользователя",
        contactsAndRepres: "Контакты и представители",
        contacts: "Контакты",
        myMeet: "Мои встречи",
        requests: "Заявки",
        chats: "Чаты",
        favoriteExhs: "Избранные экспоненты",
        documents: "Документы",
        _3dStand: "3D стенд",
        exhPage: "Страница экспонента",
        isExhibitor: 'Вы являетесь администратором:',
        profileCompany: 'Предприятие:'
    },
    settings: {
        personalData: "Личные данные",
        firstName: "Имя",
        lastName: "Фамилия",
        patronymic: "Отчество",
        close: "Закрыть",
        save: "Сохранить изменения",
        security: "Безопасность",
        password: "Пароль",
        forgetPassword: "Забыли пароль?",
        currentPassword: "Текущий пароль",
        change: "Изменить",
        email: "E-mail",
        settings: "Настройки",
    },
    contactsText: {
        name: 'Контакты',
        all: 'Всего:',
        searchPl: 'Введите ФИО контакта',
        offline: 'Все',
        online: 'В сети',
        noContacts: 'Добавьте контакты',
        noOnline: 'Нет контактов в сети'
    },
    requestText: {
        name: 'Заявки',
        all: 'Всего',
        searchPl: 'Что вы ищите?',
        theme: 'Тема:',
        noRequests: 'У вас ещё нет заявок',
        search: 'Найдено',
        all: "Все",
        requestConsult: "Заявки на консультацию",
        requestMeet: "Заявка на встречу",
        incoming: "Входящая",
        outgoing: "Исходящая",
        requests: "Заявки",
        noRequests: "У вас нет заявок",
    },
    meetsText: {
        name: 'Встречи',
        noMeets: 'У вас еще нет подтвержденных встреч',
        searchPl: 'Что вы ищите?',
        time:'Время'
    },
    selectExhText: {
        name: 'Избранные экспоненты',
        searchPl: 'Введите название экспонента?',
        noSelectExh: 'У вас ещё нет избранных экспонентов',
        add: 'Добавлено'
    },
    documentsText: {
        name: 'Документы',
        searchPl: 'Что вы ищите?',
        noDocuments: 'У вас ещё нет добавленных документов'
    },
    exhibitorPage: {
        h1: {
            mainScreen: 'Страница экспонента',
            headScreen: 'Главный экран',
            companyDesc: 'О компании',
            representatives: 'Представители',
            pavilionsGallery: 'Галерея павильона',
            documents: 'Документы',
            contacts: 'Контакты',
        },
        h3: 'Разделы',
        descPart1: 'Настройте страницу предприятия заполнив предложенные разделы.',
        descPart2: 'Чтобы отобразить все заполненные данные на странице, нажмите',
        descPart3: '“Опубликовать”.'
    }
}