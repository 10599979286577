
// SEO. Title must be the same as link in header!

export const contactus_en = {
    title: "Contacts",
    h2: "Do you have any questions? Contact us!",
    commercialQuestions: 'On organizational and commercial issues: ',
    technicalQuestions: 'On technical issues: ',
    sendMessage: 'Or send your message by filling out the form below: ',
    name: "Your name",
    email: "Your e-mail",
    subject: "Subject of the message",
    message: "Message text",
    alertTerms: "You need to accept the terms of use",
    alertEmailValid: "Invalid Email format",
    sent: "Send",
    ph: "MESSAGE",
    messageOk: "Message sent",
    messageFail: "Error, please try again",
    noEmail: "Email not entered",
    noName: "Enter Name",
    noSubject: "Subject not entered",
    noMessage: "Enter your message text",
    terms: {
        text: "This site is protected by reCAPTCHA and Google",
        pp: "Privacy Policy",
        and: "and",
        ts: "Terms of Service",
        a: "apply",
    },
}
