import { useSelector, useDispatch } from "react-redux";
import { getFormDataFileForBody } from "../utils/helpers";
import { serverUrl } from "../API/api";
import { resetAppChatState, setOpenDialogState } from "../store/appChatSlice/appChatSlice";
import { sendMessage } from "../store/appChatSlice/appChatExtraReducers";

const useSendFiles = () => {

    // PROCESSING ONE FILE, LATER MANY FILES

    const openDialog = useSelector((state) => state.appChat.openDialog);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    return async (files) => {

        const getFileIds = async (files) => {
   
    
            const sendFilesToServer = async (file) => {
                let types = {
                    image: "image",
                    video: "video",
                    application: "file"
                },
                typeFileShot = file.type.substring(0, file.type.indexOf("/")),
                savedFile = await ((await fetch(`${serverUrl}/api/v2/dialogs/file/upload?type=${types[typeFileShot]}`, {
                    method: "POST",
                    body: getFormDataFileForBody(file),
                    headers: {"Authorization": "Bearer " + token}
                })).json())

                return savedFile?.savedFileId;
            }

            return await Promise.all(
                files.map(async file => await sendFilesToServer(file)));         
        } 

        let fileIds = await getFileIds([files]);
          
        dispatch(sendMessage({
            id: openDialog.dialogId,
            body: {
            text: " ",
            fileIds,
        }}))

        dispatch(resetAppChatState("modal"));
        dispatch(setOpenDialogState({sendFile: null}))

    }





  
};

export default useSendFiles;
