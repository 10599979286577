import React, { useEffect } from "react";
import { TabsContext, WrapTabs, Tab } from "./appTabs.style";
import { useDispatch, useSelector } from 'react-redux';
import { getParamFromUrl } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { resetAppState, setAppState } from "../../store/appSlice/appSlice";

export const AppTabs = ({ settings }) => {

  const selectedTab = useSelector(state => state.app.selectedTab);
  const dispatch = useDispatch();
  const navigate =  useNavigate();

  useEffect(() => {!selectedTab && dispatch(setAppState({selectedTab:  getParamFromUrl("tab")}))}, [dispatch, selectedTab])

  useEffect(() => () => dispatch(resetAppState("tab")), [dispatch])

  if (!settings) return null;

  return (
    <TabsContext>
      <WrapTabs>
        {settings.tabs?.map(tab => (
        <Tab 
        key={tab.name} 
        active={selectedTab === tab.name}
        onClick={() => {
          dispatch(setAppState({selectedTab:  tab.name}));
          navigate(`?tab=${tab.name}`)
        }}
        >{tab.content}
        </Tab>))}
      </WrapTabs>
    </TabsContext>
  );
};
