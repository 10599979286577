import styled from "styled-components";

const PavilionsSelectsWrap = styled.div`
    
`;

const SelectsContent = styled.div`
    display: flex;
    margin: 0 16px;
    gap: 16px;
`;

const SelectsBlock = styled.div`
    width: 100%;
    h3{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #484848;
        padding-bottom: 6px;
    }
    select{
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #484848;
        width: 100%;
        height: 36px;
        background: #F6F6F6;
        border-radius: 10px;
        outline: none;
        border: none;
    }
    .CompanyInput{
        margin: 0 0 0 5px;
        padding-left: 6px;
    }
    input{
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #484848;
        width: 100%;
        height: 34px;
        background: #F6F6F6;
        border-radius: 10px;
        outline: none;
        border: none;
    }
`;

export { PavilionsSelectsWrap, SelectsBlock, 
    SelectsContent }