import styled from "styled-components";

const AddCompanyWrap = styled.div`
padding: 24px 16px 0;
.company_nomination{
    margin-top: 24px;
    display: flex;
    gap: 16px;
    margin-bottom: 25px;
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    div{
        width: 100%;
    }
    input{
        padding-left: 12px;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        width: 100%;
        color: #363636;
    }
}
.other_info{
    display: flex;
    gap: 16px;
    margin-bottom: 25px;
    .info_text{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    .user_info{
        height: 36px;
    background: #F5F5F5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    }
    .info_wrap{
        width: 100%;
        position: relative;
        .wrap-app-select {
            width: 100%;
          }
        .btn_select{
            width: 100%;
            height: 36px;
            background: #F5F5F5;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;
            border: none;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
        }
        .pavilions_container{
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1;
            overflow: auto;
            overflow-x: hidden;
            padding: 10px 6px 10px 12px;
            width: 100%;
            background: #FFFFFF;
            position: absolute;
            box-shadow: 0px 14px 30px rgba(0,0,0,0.35);
            border-radius: 0px 0px 4px 4px;
            z-index: 2;
            height: 120px;
            .pavilion_card{
                background: #f5f5f5;
                text-align: start;
                padding: 12px;
                border: none;
                word-break: break-word;
                cursor: pointer;
                margin-right: 4px;
                p{
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 140%;
                    padding-left: 30px;
                }
            }
        }
        img{
            width: 24px;
            height: 24px;
            border-radius: 20px;
        }
.fullName_text{
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
}
.email_info{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #363636;
    opacity: 0.7;
}
.add_user_company{
    border: none;
    background: none;
    margin-left: auto;
    height: 16px;
                svg{
                    path{
                        fill: #363636;
                    }
                }
}
    }
}
.agreement_file_wrap{
    margin-bottom: 31px;
    .wrap_text{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    .add_file{
        display: block;
        height: 54px;
        background: #F5F5F5;
        border-radius: 4px;
        position: relative;
        .contract{
            display: flex;
            gap: 10px;
            align-items: center;
            height: inherit;
            margin: 0 12px;
            .btn_basket{
                width: 30px;
                height: 30px;
                background: #EBEBEB;
                border-radius: 10px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                svg{
                    width: 18px;
                    height: 18px;
                    path{
                        fill: #363636;
                    }
                }
            }
            .contract_infoWrap{
                .contract_name{
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 16px;
                    color: #363636;
                    }
                .contract_size{
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    color: #484848;
                }
    }
    }
            .add_contract
            {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #363636;
                svg{
                    path{
                        fill: #363636;
                    }
                }
            }
        input{
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}
.description{
    margin-bottom: 30px;
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    textarea{
    width: 100%;
    height: 108px;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    resize: none;
    font-family: 'Gilroy',sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 9px 12px;
    }
}
`;

const SeoSettingsWrap = styled.div`
margin-top: 27px;
display: flex;
justify-content: space-between;
width: 100%;
padding-bottom: 10px;
border-bottom: 0.5px solid #D4D4D4;
p{
font-weight: 500;
font-size: 13px;
line-height: 16px;
}
.general_seo_data{
    display: flex;
    align-items: center;
    gap: 10px;
    .text_total_data{
        color: #363636;
        cursor: pointer;
    }
    .active_total{
        color: #34C57F;
    }
    button{
        width: 46px;
        height: 24px;
        background: #F5F5F5;
        border-radius: 6px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: #363636;
        border: none;
        cursor: pointer;
    }
    .active{
        background: #34C57F;
        color: #FFFFFF;
    }
}
.open_seo_settings{
    border:none;
    color: #363636;
    background: none;
}
.active_seo{ 
    color: #34C57F;
} 
`

const BtnsSendWrap = styled.div`
display: flex;
justify-content: center;
gap: 10px;
margin-bottom: 30px;
div{
    display: flex;
    width: 100%;
    gap: 10px;
}
button{
    width: 182px;
    height: 36px;
    cursor: pointer;
    border-radius: 8px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
}
.back{
    border: 1px solid #34C57F;
    background: none;
    color: #34C57F;
}
.linkTo_expanent{
    border: 1px solid #363636;
    background: none;
    color: #363636;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
.Send{
    border: none;
    background: #34C57F;
    color: #FFFFFF;
}
`;

export {
    AddCompanyWrap, SeoSettingsWrap,
    BtnsSendWrap
}