import React from "react";
import StatisticsImg from "../../../assets/Components/Routes/MainPage/Features/Statistics.jpg";
import StandsImg from "../../../assets/Components/Routes/MainPage/Features/Stands.jpg";
import { useSelector } from 'react-redux';
import {
  FeaturesSection,
  Standsinfo,
  FeatureStatistics,
  StandsinfoText,
  FeatureStatisticsText,
} from "./style/features_css";

export const Features = () => {

  const {features: localText} = useSelector((state) => state.app.appText);

  return (
    <FeaturesSection>
      <Standsinfo>
      <div className="stands_img_container">
        <img className="stands_img" alt="stands_img" src={StandsImg}></img>
      </div>
        <StandsinfoText>
          <p className="stands_head_text">
          {localText.blockHead1_0} <br/> {localText.blockHead1_1} <strong>{localText.blockHead1_2}</strong> {localText.blockHead1_3}
          </p>
          <div></div>
          <p className="stands_desc">
            {localText.blockDesc1}
          </p>
        </StandsinfoText>
      </Standsinfo>
      <FeatureStatistics>
        <FeatureStatisticsText>
          <p className="statistic_head_text">
          {localText.blockHead2_0} <strong>{localText.blockHead2_1}</strong> {localText.blockHead2_2}
          </p>
          <div></div>
          <p className="statistic_desc">
            {localText.blockDesc2}
          </p>
        </FeatureStatisticsText>
        <div className="statistic_img_container">
        <img
          className="statistic_img"
          alt="statistic_img"
          src={StatisticsImg}
        ></img>
        </div>
      </FeatureStatistics>
  {/*    <VirtualImage>
        <img className="VirtualImg" alt="VirtualImg" src={VirtualImg}></img>
      </VirtualImage>*/}
    </FeaturesSection>
  );
};
