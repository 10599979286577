import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrapStatistics } from "./statistics.style";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import AppSelect from "../../../components/AppSelect/AppSelect";
import AppDatePicker from "./../../../components/AppDatePicker/AppDatePicker";
import AppModals from "../../../components/AppModals/AppModals";

const Statistics = ({
  dataMemo,
  optionsMemo,
  handlerActions,
  settingsSelect,
  localText,
  datePickerRef,
}) => {
  return (
    <WrapStatistics>
      <AppModals>
        <AppDatePicker type="range" datePickerRef={datePickerRef} />
      </AppModals>
      <div className="statistics-head">
        <h2>{localText.head}</h2>
        <div className="head-filter">
          <AppSelect
            settings={settingsSelect}
            onChange={handlerActions("changePeriod")}
          />
          <button
            className="filter__download"
            onClick={handlerActions("downloadReport")}
          >
            {localText.downloadReport}
          </button>
        </div>
      </div>
      <div className="wrap-bars">
        <div className="statistics-bar">
          <Bar options={optionsMemo.options1} data={dataMemo.data1} />
        </div>
        <div className="statistics-bar">
          <Bar options={optionsMemo.options2} data={dataMemo.data2} />
        </div>
        <div className="statistics-bar">
          <Bar options={optionsMemo.options3} data={dataMemo.data3} />
        </div>
        <div className="statistics-bar">
          <Bar options={optionsMemo.options4} data={dataMemo.data4} />
        </div>
      </div>
    </WrapStatistics>
  );
};

export default Statistics;
