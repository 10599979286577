import styled from "styled-components";

const AdminPavilionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 16px 34px;

.head_info_wrap {
    display: flex;
    justify-content: space-between;
    button {
      width: 182px;
      height: 34px;
      background: #34c57f;
      border: 1px solid #34c57f;
      border-radius: 8px;
      color: #ffffff;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      gap: 7px;
    }
  }
`;

const AdminPavilionsContainer = styled.div`
width: 100%;
height: 100%;
border: 0.5px solid #D4D4D4;
border-radius: 4px;
margin-bottom: 17px;
padding-right: 4px;
padding-top: 4px;
.map-container{
    display: flex;
    flex-direction: column;
    height: inherit;
    gap: 12px;
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px 8px 16px 16px;
}
`;

const PavilionCard = styled.div`
    width: inherit;
    height: 66px;
    background: #F5F5F5;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    align-items: center;
    .admin_pavilion_name{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        padding-bottom: 5px;
    }
`

const DateReg = styled.div`
display: flex;
gap: 5px;
.admin_pavilion_date_text{
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #363636;
}
.admin_pavilion_date{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
}
`;

const PavilionsBtnsWrap = styled.div`
    display: flex;
    gap: 5px;
    margin-left: auto;
    button{
        width: 30px;
        height: 30px;
        background: #EBEBEB;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .settings_icon{
        width: 16px;
        height: 16px;
    }
    .btn_basket{
        svg{
            width: 18px;
            height: 18px;
            path{
                fill: #363636;
            }
        }
    }
`;

export {
    AdminPavilionsWrap,
    AdminPavilionsContainer,
    PavilionCard,DateReg,
    PavilionsBtnsWrap
}