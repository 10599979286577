import {
  MeetsContainer,
  MeetsItem,
  MeetsItemInfo,
  MeetsItemSettings,
  MeetsWrap,
  SearchContent,
  SelectAllItem,
  SendDate,
} from "./style/Meets_css";

import settings2Icon from "../../../assets/Components/Account/images/Settings2.png";
import { icons_svg } from "../../Exhibition/Company/imgs/icons.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { deleteMeetings, getApprovedMeetings } from "../../../store/profileSlice/profileAsyncThunk";
import { filesUrl } from "../../../API/api";
import { resetProfileState, setProfileTableState } from "../../../store/profileSlice/profileSlice";
import AppSelect from "../../../components/AppSelect/AppSelect";
import { setAppState } from "../../../store/appSlice/appSlice";

export const Meets = () => {

    const {profile: {meetsText, requestText}} = useSelector((state) => state.app.appText)
    const dispatch = useDispatch()
    const {table: {data: approvedMeetings, filter}} = useSelector((state) => state.profile)
  
    const {search} = icons_svg;

    const settingsSelect = {
      options: [
        { id: 0, value: requestText.all },
        { id: "true", value: requestText.incoming },
        { id: "false", value: requestText.outgoing },
      ],
  };

    const handlerActions = (type, data) => async (e) => {
      switch (type) {
        case "changeSearch":
          dispatch(
            setProfileTableState({
              filter: { ...filter, search: e.target.value },
            })
          );
          break;
        case "changeFilter":
          dispatch(
            setProfileTableState({
              filter: { ...filter, incoming: e},
            })
          );
          break;
          case "contextMenu":
            dispatch(setAppState({contextMenu: {
              currentTarget: e.currentTarget,
              listMenu: [
                { onClick: () => dispatch(deleteMeetings({id: data.id, consult: filter.consult})), icon: '', text: "remove", class: " error" },
              ]
            }}))
            break;
        default:
          break;
      }
    };
  
    useEffect(() => {
      dispatch(getApprovedMeetings(filter))
    }, [dispatch, filter])

    useEffect(() => () => dispatch(resetProfileState("table")), [dispatch]);

    if(!approvedMeetings) return null;

  return (
    <MeetsContainer>
      <h2>{meetsText.name} ({approvedMeetings.length})</h2>
      <SearchContent>
        <AppSelect settings={settingsSelect} onChange={handlerActions("changeFilter")}/>
        <div className="filter-search">
          {search}
          <input placeholder={meetsText.searchPl} onChange={handlerActions("changeSearch")}></input>
        </div>
      </SearchContent>
      <MeetsWrap>
        {approvedMeetings.length === 0 ? <p className="empty_arr">{meetsText.noMeets}</p>  :  approvedMeetings.map(el => {
          return (
            <MeetsItem key={el.id}>
              <img
                style={{ padding: "12px" }}
                src={filesUrl + el.logo}
                width={52}
                height={52}
                alt="testicon"
              ></img>
              <div className="Favorites_item_block">
                <MeetsItemInfo>
                  <h3>{el.theme}</h3>
                  <div>
                    <SendDate>
                      <strong>{meetsText.time}:</strong> {el.timeFrom}{'-'}{el.timeTo}
                    </SendDate>
                    {el.icon}
                  </div>
                </MeetsItemInfo>
                <MeetsItemSettings bg={settings2Icon}  onClick={handlerActions("contextMenu", el)}></MeetsItemSettings>
              </div>
            </MeetsItem>
          );
        })}
      </MeetsWrap>
    </MeetsContainer>
  );
};
