import styled, { css } from "styled-components";

const AddRepresentativesWrap = styled.div`
padding: 24px 16px 0;
.wrap_rep{
    margin-top: 20px;
    .wrap-app-select {
        width: 100%;
    }
    position: relative;

    .head_text{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    input{
        width: 100%;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 0 12px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
    }
    textarea{
        width: 100%;
        height: 108px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 9px 12px;
    }
    .dialog-select-user{
        padding-bottom: 23px;
    position: relative;
    .user__selected {
        display: flex;
        align-items: center;
        height: 48px;
        background: #F5F5F5;
        border-radius: 6px;
        padding: 12px;

        img, .user__default-img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }


        .user__default-img {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #d1d1d1;
          color: #ffffff;
          text-transform: capitalize;
      }

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
          padding-left: 8px;
        }

        button {
          margin-left: auto;
          cursor: pointer;
          border: none;
          background: transparent;
        }

        ${({ openList }) =>
        !openList  &&
        css`
            position: absolute;
            top: 5px;
            left: 0;
            right: 0;
            height: 36px;
            padding: 6px 12px;
        `}
        

      }

      input {
        padding: 8px 12px;
        height: 36px;
        appearance: none;
        ${({ valueSelect }) =>
        !valueSelect  &&
        css`
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #363636;
          opacity: 0.5;
        `}
  
        .list__elem-none {
          display: none;
        }
      }

      .wrap-user-list {
        position: absolute;
        left: 0px;
        right: 0px;
        z-index: 1;
        display: flex;
        max-height: 208px;
        background: #FFFFFF;
        box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.35);
        border-radius: 0px 0px 4px 4px;
        padding: 12px;

        .user-list {
          position: relative;
          display: flex;
          flex: 1;  
          border: 0.5px solid #DEDEDE;
          border-radius: 4px;
          .user-list-scroll {
            display: grid;
            gap: 10px;
            margin: 2px 2px 2px 0px;
            flex: 1;
            overflow: auto;
            overflow-x: hidden;
            padding: 12px 10px 12px 12px;

            .user__not-found {
                text-align: center;
                font-size: 13px;
                line-height: 16px;
                color: #363636;
            }
          }
        }
      }
}
}
`;

const BtnsWrap = styled.div`

`;

export {AddRepresentativesWrap, BtnsWrap}