import styled from "styled-components";


const ServicesSection = styled.section`
    background-color: #fff;
    padding: 100px 0;
`;

const PhotoServicesBlock = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 85px;
    gap: 16px;
        flex-wrap: wrap;
`;



export { ServicesSection, PhotoServicesBlock } 