import styled from "styled-components";

const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  h2 {
    font-family: "Helvetica";
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
  }
`;

const SearchContent = styled.div`
  display: flex;
  padding: 16px 0;

  .filter-search {
    height: 34px;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    order: 1;
    margin-left: auto;
    max-width: 606px;
    flex: 1;

    input {
      width: 100%;
      border-radius: 10px;
      background: #f6f6f6;
      border: none;
      outline: none;
      padding-left: 6px;
    }
  }
`;

const NotificationsButtons = styled.div`
  display: flex;
  width: 336px;
  display: flex;

  .NotificationBtn {
    width: 100%;
    height: 34px;
    border-radius: 10px;
    border: none;
    background: #f6f6f6;
    cursor: pointer;
  }

  .active {
    color: #ffffff;
    background: #34c57f;
  }
`;

const NotificationsWrap = styled.div`
  border: 0.5px solid #d4d4d4;
  padding-bottom: 16px;
  border-radius: 4px;
  overflow-y: auto;
  flex: 1;
`;

const NotificationsItem = styled.div`
  height: 76px;
  background: #f8f8f8;
  border-radius: 4px;
  margin: 0 16px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-transform: uppercase;
  }
  .Favorites_item_block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
`;

const NotificationsItemInfo = styled.div``;

const NotificationsDescription = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
`;

const NotificationsItemSettings = styled.button`
  background: url(${(props) => props.bg}) center no-repeat;
  border: none;
  background-color: #efefef;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
`;

const SendDate = styled.p`
  font-weight: 400;
  font-size: 11px;
  line-height: 110%;
  color: #484848;
  padding-bottom: 4px;
`;

export {
  NotificationsContainer,
  SearchContent,
  NotificationsButtons,
  NotificationsWrap,
  NotificationsItem,
  NotificationsItemInfo,
  NotificationsDescription,
  NotificationsItemSettings,
  SendDate,
};
