import { header_by } from "./components_text/header_by";
import { authorization_by } from "./components_text/authorization_by";
import { modalAddCompany_by } from "./components_text/modalAddCompany_by";
import { banner_by } from "./components_text/banner_by";
import { description_by } from "./components_text/description_by";
import { manual_by } from "./components_text/manual_by";
import { media_by } from "./components_text/media_by";
import { mission_by } from "./components_text/mission_by";
import { news_by } from "./components_text/news_by";
import { partners_by } from "./components_text/partners_by";
import { follow_by } from "./components_text/follow_by";
import { contactus_by } from "./components_text/contactus_by";
import { seoModal_by } from "./components_text/seoModal_by";
import { footer_by } from "./components_text/footer_by";
import { companiesPage_by } from "./components_text/companiesPage_by";
import {
  pavilionPage_by,
  pavilionsList_by,
} from "./components_text/pavilion_by";
import { editCompany_by } from "./components_text/editCompany_by";
import { filesDownload_by } from "./components_text/filesDownload_by";
import { activate_by } from "./components_text/activate_by";
import { changeMain_by } from "./components_text/changeMain_by";
import { tibo_by } from "./components_text/tibo_by";
import {
  errorPage_by,
  errorsRequest_by,
  errorsValid_by,
} from "./components_text/errors_by";
import { search_by } from "./components_text/search_by";
import { PavilionVDNX_by } from "./components_text/pavilionVDNX_by";
import { features_by } from "./components_text/features_by";
import { mobileApp_by } from "./components_text/mobileApp_by";
import { mainPage_by } from "./components_text/mainPage_by";
import { modals_by } from "./components_text/modals_by";
import { contextMenu_by } from "./components_text/contextMenu_by";
import { forms_by } from './components_text/forms_by';
import { profile_by } from "./components_text/profile_by";
import { baseWords_by } from "./baseWords_by";

export const by = {
  language: "by",
  header: header_by,
  authorization: authorization_by,
  modalAddCompany: modalAddCompany_by,
  banner: banner_by,
  description: description_by,
  manual: manual_by,
  media: media_by,
  mission: mission_by,
  news: news_by,
  partners: partners_by,
  follow: follow_by,
  contactus: contactus_by,
  seoModal: seoModal_by,
  footer: footer_by,
  companiesPage: companiesPage_by,
  pavilionPage: pavilionPage_by,
  pavilionsList: pavilionsList_by,
  editCompany: editCompany_by,
  filesDownload: filesDownload_by,
  activate: activate_by,
  changeMain: changeMain_by,
  tibo: tibo_by,
  errorPage: errorPage_by,
  errorsRequest: errorsRequest_by,
  errorsValid: errorsValid_by,
  search: search_by,
  VDNX: PavilionVDNX_by,
  features: features_by,
  mobileApp: mobileApp_by,
  mainPage: mainPage_by,
  modals: modals_by,
  contextMenu: contextMenu_by,
  forms: forms_by,
  profile: profile_by,
  baseWords: baseWords_by
};
