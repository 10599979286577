import styled from "styled-components";

const ShowGalleryItemWrap = styled.div`
 .backgroud-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.6;
    z-index: 1;
  }
`;

export {
    ShowGalleryItemWrap
}