import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {filesUrl, serverUrl} from "../../../../../API/api";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import icons_svg from "../../../../../components/AppChat/imgs/icons_svg";
import {addRepresentatives} from "../../../../../store/exhibitorSlice/exhibitorSlice";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {ControlBtns} from "../../../Admin/Users/ModalHandlerUser/ModalHandlerUser.style";

import {AddRepresentativesWrap} from "./addRepresentative.style";

export const AddRepresantatives = ({setAddRepresentative}) => {
  const {exitIcon} = svg_icons;
  const {removeUserIcon, plusInCircle} = icons_svg;
  const {language} = useSelector((state) => state.app.appText);
  const dispatch = useDispatch();
  const [localState, setLocalState] = useState({
    "search-user": "",
    userList: false,
    selectedUser: false,
    job: "",
    description: "",
  });
  const changeInputs = (e) =>
    setLocalState((state) => ({...state, [e.target.name]: e.target.value}));

  useEffect(() => {
    const searchUsers = async () => {
      try {
        const users = await (
          await fetch(
            serverUrl +
              `/users/contacts/pageable/list?language=${language}&page=${1}&size=${100}&searchParam=${
                localState["search-user"] || ""
              }`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
        ).json();

        setLocalState((state) => ({
          ...state,
          userList: Array.isArray(users.contacts)
            ? users.contacts.filter((item) => item.role === "ROLE_USER")
            : [],
        }));
      } catch (e) {
        setLocalState((state) => ({...state, userList: []}));
      }
    };
    if (localState["search-user"]) {
      searchUsers();
    } else {
      setLocalState((state) => ({...state, userList: false}));
    }
  }, [localState["search-user"]]);

  const toggleUser = (user) => () =>
    setLocalState((state) => ({...state, selectedUser: user}));

  const buildInfoUser = (user) =>
    user && (
      <div key={user.id} className="user__selected">
        {user.avatar ? (
          <img src={filesUrl + user.avatar} alt="user_photo" />
        ) : (
          <div className="user__default-img">{user.firstName[0]}</div>
        )}
        <p>
          {user.firstName} {user.lastName}
        </p>
        <button
          className="select-base-icon-btn"
          onClick={toggleUser(localState.selectedUser ? false : user)}
        >
          {localState.selectedUser ? removeUserIcon : plusInCircle}
        </button>
      </div>
    );

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setAddRepresentative(false)}>
          {exitIcon}
        </CloseButton>
        <AddRepresentativesWrap
          openList={!localState.selectedUser && localState.userList}
        >
          <Title>{"Представитель"}</Title>
          <div className="wrap_rep">
            <p className="head_text">{"Пользователь"}</p>
            <div className="dialog-select-user">
              <input
                placeholder={"Введите email или Имя пользователя"}
                onChange={changeInputs}
                name={"search-user"}
                value={localState["search-user"]}
              />
              {buildInfoUser(localState.selectedUser)}

              {!localState.selectedUser && localState.userList && (
                <div className="wrap-user-list">
                  <div className="user-list">
                    <div className="user-list-scroll">
                      {localState.userList.length ? (
                        localState.userList.map((user) => buildInfoUser(user))
                      ) : (
                        <p className="user__not-found">
                          Пользователь не найден
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="wrap_rep">
            <p className="head_text">{"Должность"}</p>
            <input
              onChange={changeInputs}
              placeholder={"Введите должность"}
              name={"job"}
            />
          </div>
          {/* <div className="wrap_rep">
                        <p className="head_text">{"Роль"}</p>
                        <AppSelect settings={settings}/>
                    </div>
                        <AppSelect settings={settings} width={"100%"}/>
                    </div>*/}

          <div className="wrap_rep">
            <p className="head_text">{"Примечание"}</p>
            <textarea name={"description"} onChange={changeInputs} />
          </div>
          <ControlBtns style={{marginTop: "20px"}}>
            <button
              className="Back"
              onClick={() => setAddRepresentative(false)}
            >
              {"Отменить"}
            </button>
            <button
              disabled={!Boolean(localState.selectedUser && localState.job)}
              style={
                Boolean(localState.selectedUser && localState.job)
                  ? {opacity: "1"}
                  : {opacity: "0.5"}
              }
              className="Send"
              onClick={() => {
                dispatch(
                  addRepresentatives({
                    job: localState.job,
                    description: localState.description,
                    ...localState.selectedUser,
                    visibility: true,
                  })
                );
                setAddRepresentative(false);
              }}
            >
              {"Сохранить"}
            </button>
          </ControlBtns>
        </AddRepresentativesWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
