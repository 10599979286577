import React, { useEffect, useState } from "react";
import { WrapModal } from "./appModals.style";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalBackground from "./../ModalBackground/ModalBackground";
import { resetAppState } from "../../store/appSlice/appSlice";
import { svg_icons } from "../../assets/imgs/svg_icons";

const AppModals = ({ children }) => {
  const { closeIcon } = svg_icons;
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.app.modal);
  const navigate = useNavigate();

  const closeModal = (addHandler) => () => {
    switch (modal.type) {
      case "registrSuccess":
        navigate("/login");
        break;

      default:
        break;
    }
    addHandler && addHandler();
    dispatch(resetAppState("modal"));
  };

  if (
    !modal.type ||
    (!modal.withChildren && children) ||
    (modal.type && modal.withChildren && !children)
  )
    return null;

  return (
    <ModalBackground onClose={closeModal(modal.cancel)}>
      <WrapModal className="app-modals">
        <button className="close-btn" onClick={closeModal(modal.cancel)}>
          {closeIcon}
        </button>
        <h2 className="modals__head">{modal.head || ""}</h2>
        {modal.subhead ? (
          <p className="modals__subhead">{modal.subhead}</p>
        ) : null}
        {children ? children : null}
        <button className="submit-btn" onClick={closeModal(modal.submit)}>
          {modal.submitText || "Понятно"}
        </button>
      </WrapModal>
    </ModalBackground>
  );
};

export default AppModals;
