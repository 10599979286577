import { AddGalleryImage, AddGalleryVideo, AddNewContactDepartment, AddRepresentatives, ChangeVisibleRepresentative, DeleteDepartmentContact, GetCompanyDepartments, GetCompanyDocuments, GetCompanyMedia, GetContactsTypes, GetRepresentatives, UpdateDepartmentContact } from "./exhibitorAsyncThunk"

export const extraReducers = (builder) => {
    builder.addCase(
    AddGalleryImage.fulfilled(), (state, {payload}) => {
        state.Exhmessage = 'Фотография успешно добавлена в галерею'
    },
    )
    builder.addCase(
        AddGalleryVideo.fulfilled(), (state, {payload}) =>{
            state.Exhmessage = 'Видео успешно добавлено'
        }
    )
    builder.addCase(
        GetCompanyDocuments.fulfilled(), (state, {payload}) => {
            console.log(payload)
            state.companyDocuments = payload
        }
    )
    builder.addCase(
        GetRepresentatives.fulfilled(), (state, {payload}) => {
            state.representatives = payload
        }
    )
    builder.addCase(
        GetCompanyDepartments.fulfilled(), (state, {payload}) => {
            state.companyDepartments = payload
        }
    )
    builder.addCase(
        GetCompanyMedia.fulfilled(), (state, {payload}) => {
            payload.image && state.newGalleryItems.push(...payload.image) && state.galleryItems.push(...payload.image)
            payload.video && state.newGalleryItems.push(...payload.video) && state.galleryItems.push(...payload.video)
            payload.model && state.newGalleryItems.push(...payload.model) && state.galleryItems.push(...payload.model)
        }
    )
    builder.addCase(
        GetContactsTypes.fulfilled(), (state, {payload}) => {
            state.contactsTypes = payload
        }
    )
    builder.addCase(
        AddNewContactDepartment.fulfilled(), (state, {payload}) => {
            state.companyDepartments = state.companyDepartments.map((department) => department.id === payload.departmentId 
            ?   
            ({...department, 
                companyDepartmentContacts: payload.contactList
            }) 
            :
            department
            )
        }
    )
    builder.addCase(
        DeleteDepartmentContact.fulfilled(), (state, {payload}) => {
            state.companyDepartments = state.companyDepartments.map((department) => department.id === payload.departmentId 
            ?   
            ({...department, 
                companyDepartmentContacts: 
                department.companyDepartmentContacts.filter((contact) => contact.id !== payload.id) 
            }) 
            :
            department
            )
        }
    )
    builder.addCase(
        UpdateDepartmentContact.fulfilled(), (state, {payload}) => {
            state.companyDepartments = state.companyDepartments.map((department) => department.id === payload.departmentId 
            ?   
            ({...department, 
                companyDepartmentContacts: payload.contactList
            }) 
            :
            department
            )
        }
    )
    builder.addCase(
        ChangeVisibleRepresentative.fulfilled(), (state, {payload}) => {
            state.representatives = state.representatives.map((item) => item.id === payload.user.id ? {...payload.user, update: false } : item)
        }
    )
    builder.addCase(
        AddRepresentatives.fulfilled(), (state, action) => {
            if(action.payload.representatives){
            state.representatives = action.payload.representatives
            }
        },
    )
}