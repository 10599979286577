import { useState } from "react"
import { svg_icons } from "../../../../../assets/imgs/svg_icons"
import { CheckBox } from "../../../../../components/AppCheckBox/AppCheckBox"
import { ModalContainer, ModalOpen, CloseButton, Title } from "../../../../Registration/Login/style/Login_css"
import { ScrollBarWrap } from "../../AdminPanelContent/AdminPanelContent.style"
import { ControlBtns } from "../../Users/ModalHandlerUser/ModalHandlerUser.style"
import { AddNewRoleWrap, BtnsWrap, CharterCard, CharterWrap, RoleAccessWrap } from "./AddNewRole.style"
import { useEffect } from "react"

const accessData = [
    {
        id: 0,
        text: 'Редактировать страницу экспанента'
    },
    {
        id: 1,
        text: 'Добавлять и редактировать новость'
    },
    {
        id: 2,
        text: 'Принимать решение по заявкам'
    },
    {
        id: 3,
        text: 'Назначать встречи'
    },
]

export const AddNewRole = ({setOpenModal}) => {

    const {exitIcon, triangleDown, triangleUp, searchIcon} = svg_icons
    const [openAccess, setOpenAccess] = useState(false)

    const [checkboxes, setCheckboxes] = useState([{id: 'all', checked: false}])

    const handlerActions = (type, data) => (e) => {
        switch (type) {
            case 'setAllCheck':
                setCheckboxes((state) => {return state.map((item) => ({...item, checked: !data.checked}))})
                break;
            case 'setCheck':
                setCheckboxes((state) => {return state.map((item) => item.id === data.id ? ({...item, checked: !data.checked}) : item)})
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setCheckboxes(accessData.reduce((acc, curr) => {
            acc.push({id: curr.id, checked: false})
            return acc
        }, [{id: 'all', checked: false}])
        )
    }, [])

    console.log(checkboxes)

    return (
        <ModalContainer>
            <ModalOpen maxWidth = {372}>
            <CloseButton onClick={() => setOpenModal(false)}>{exitIcon}</CloseButton>
            <AddNewRoleWrap>
                <Title>{'Новая роль'}</Title>
                <div className="new_role_name">
                    <p>{'Название'}</p>
                    <input placeholder={'Введите название'}/>
                </div>
                <div className="new_role_access">
                    <p>{'Права на действия'}</p>
                    <button className="btn_select" onClick={() => setOpenAccess(!openAccess)}>{'Выберите действия'}{openAccess ? triangleUp : triangleDown}</button>
                    <RoleAccessWrap style={openAccess ? {display: 'block'} : {display: 'none'}}>
                        <div className="all_role_access">
                        <CheckBox checked={checkboxes.find((el) => el.id === 'all').checked} onChange={handlerActions('setAllCheck', checkboxes.find((el) => el.id === 'all'))}/>
                        <p>{'Все'}</p>
                        <div className="search_access_roles">
                            {searchIcon}
                            <input type={'text'} placeholder={'Начните вводить'}/>
                        </div>
                        </div>
                        <CharterWrap>
                            <ScrollBarWrap>
                                <div className="charter_container">
                                    {accessData.map((item, index) => {
                                        return (
                                            <CharterCard key={index}>
                                                <CheckBox checked={checkboxes.find((el) => el.id === item.id)?.checked} onChange={handlerActions('setCheck', checkboxes.find((el) => el.id === item.id))} />
                                                <p>{item.text}</p>
                                            </CharterCard>
                                        )
                                    })}
                                </div>
                            </ScrollBarWrap>
                        </CharterWrap>
                        <BtnsWrap>
                            <button className="back" onClick={() => setOpenAccess(false)}>{'Отменить'}</button>
                            <button className="send_btn">{'Добавить выбранные'}</button>
                        </BtnsWrap>
                    </RoleAccessWrap>
                </div>
                <div className="new_role_notice">
                    <p>{'Примечание'}</p>
                    <textarea />
                </div>
                <ControlBtns>
                        <button className="Back" onClick={() => setOpenModal(false)}>{'Отменить'}</button>
                        <button className="Send">{'Сохранить'}</button>
                </ControlBtns>
            </AddNewRoleWrap>
            </ModalOpen>
        </ModalContainer>
    )
}