import styled from "styled-components";

const SelectedNewsWrap = styled.div`
    padding-top: 64px;
    background: white;
    padding-bottom: 33px;
`;

const LogoContainer = styled.div`

`

const WrapNews = styled.div`

.News_Title_Container{

}
`;

const WrapLogo = styled.div`
    background: url(${props => props.Bg}) center center / cover no-repeat;
    position: relative;
    background-size: contain;
    height: 420px;
`;

const Date = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.5px solid #D4D4D4;
    border-bottom: 0.5px solid #D4D4D4;
    margin: 0 16px;
    p{
        font-weight: 400;
        font-size: 13px;
        line-height: 110%;
        color: #363636;
        padding-right: 45px;
    }
`;

const WrapNewsTitle = styled.h1`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px 0;
`;

const WrapNewsContent = styled.div`
    padding: 50px 0 0 0px;
    max-width: 696px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    p{
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: #363636;
    }
    span{
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: #363636 !important;
    }
    blockquote{
        border-left: 4px solid #7dcca6;
        margin-bottom: 10px;
        p{
            ::before{
                content: '***';
                padding-right: 5px;
            }
            font-family: 'Helvetica';
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: #363636;
            padding-left: 13px;
        }
    }
`;

const AuthorBlock = styled.div`
    margin: 27px 16px;
`;

const TextAuthor = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #363636;
    text-align: center;
`;

const PhotoAuthor = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #363636;
    text-align: center;
`;

const FeedbackForm = styled.div`
    height: 36px;
    margin: 0 16px 32px;
    border-top: 0.5px solid #D4D4D4;
    border-bottom: 0.5px solid #D4D4D4;
    display: flex;
    align-items: center;
    justify-content: center;
    strong{
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #363636;
        padding-right: 5px;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #34C57F;
        cursor: pointer;
    }
`;

const OtherNews = styled.div`
`;

const OtherNewsTitle =styled.p`
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    color: #363636;
    padding-bottom: 22px;
`;

const MessengersBlock = styled.div`
width: 206px;
height: 56px;
background: #F8F8F8;
border-radius: 12px;
margin: 0 auto 45px;
display: flex;
align-items: center;
justify-content: center;
div{
    display: flex;
    align-items: center;
}
`;

export {SelectedNewsWrap, WrapNews,
     WrapLogo, WrapNewsTitle, 
     WrapNewsContent, Date,
     AuthorBlock, TextAuthor,
     PhotoAuthor, FeedbackForm,
     OtherNews, OtherNewsTitle,
     LogoContainer, MessengersBlock
    }