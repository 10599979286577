import { useSelector } from "react-redux";
import { serverUrl } from "../../../../API/api";
import { svg_icons } from "../../../../assets/imgs/svg_icons";
import { dateToLocaleDateString } from "../../../../utils/helpers";
import { AdminHead } from "../AdminHead/AdminHead";
import { ScrollBarWrap } from "../AdminPanelContent/AdminPanelContent.style";
import { SearchSortContent } from "../SearchSortContent/SearchSortContent";
import {
  AllNewsCard,
  AllNewsContainer,
  AllNewsWrap,
} from "./AdminNewsControl.style";
import { ModalNews } from "./ModalNews/ModalNews";

export const AdminNewsControl = ({localState, handlerActions}) => {
  const { addIcon, eyeIcon, crossEyeIcon, settingsIcon, trashIcon, starIcon, starIconGreen } = svg_icons;

  const { news } = useSelector((state) => state.news);
  const { language } = useSelector((state) => state.app.appText);


  return (
    <AllNewsWrap>
      <div className="head_info_wrap">
        <AdminHead
          title={localState.componentsText.title}
          desc={localState.componentsText.text}
        />

        <button
          className="Add_Organization"
          onClick={handlerActions("setModalCreate", { newsId: "", open: true, type: 'create' })}
        >
          {addIcon} {localState.componentsText.addBtnText}
        </button>
      </div>
      <SearchSortContent
        page={localState.contentParam}
        inputChange={handlerActions("setSearch")}
      />
      <AllNewsContainer>
        <ScrollBarWrap>
          <div className="map_container">
            {news.map((item, index) => {
              return (
                <AllNewsCard key={index}>
                  <div className="content_card_wrap">
                    <div className="preview_container">
                      {item?.media.some((el) => el.preview) ? (
                        <img
                          alt="news_preview"
                          src={`${serverUrl}/api/media/download/${item.media.find(el => el.preview).id}`}
                        />
                      ) : (
                        <p className="not_found_preview">Preview not found</p>
                      )}
                    </div>
                    <div className="info_news_wrap">
                      <p className="name_news">
                        {
                          item.newsTranslators.find(
                            (el) =>
                              el.language.languageCode ===
                              language.toUpperCase()
                          ).name?.name
                        }
                      </p>
                      <div className="date_create">
                        <p className="head_date">{"Дата публикации:"}</p>
                        <p className="date_text">
                          {dateToLocaleDateString(item.time)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card_btns_control">
                    <button
                      onClick={handlerActions("setModal", {
                        type: "hideNews",
                        news: item,
                        open: true,
                      })}
                    >
                      {item.isShow ? eyeIcon : crossEyeIcon}
                    </button>
                    {localState.contentParam !== 'drafts-news' && <button onClick={handlerActions("setModal", {
                        type: "makeMainNews",
                        news: item,
                        open: true
                    })}>{item.mainNews ? starIconGreen : starIcon}</button>}
                    <button onClick={handlerActions("editNews", item)}>{settingsIcon}</button>
                    <button
                      onClick={handlerActions("setModal", {
                        type: "deleteNews",
                        news: item,
                        open: true,
                      })}
                    >
                      {trashIcon}
                    </button>
                  </div>
                </AllNewsCard>
              );
            })}
          </div>
        </ScrollBarWrap>
      </AllNewsContainer>
      {localState.openModal.open && (
        <ModalNews
          handlerActions={handlerActions}
          contentParam={localState.contentParam}
          openModal={localState.openModal}
        />
      )}
    </AllNewsWrap>
  );
};
