import styled from "styled-components";

const FollowContainer = styled.div`
    padding: 0px 16px 30px;
    margin-top: 24px;
    .Invalid_Input{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
`;

const EmptityWrap = styled.div`
    margin: 12px 0 30px;
    p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
    .Emptity{
        margin-bottom: 14px;
    }
    .Activity{
        select{
            border: none;
            background: #F5F5F5;
            border-radius: 4px;
            outline: none;
            width: 100%;
            height: 36px;
            padding-left: 12px;
            option{
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #363636;
            }
        }
    }
`

const Comment = styled.div` 
    p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
    textarea{
    width: 100%;
    height: 83px;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    resize: none;
    font-family: 'Gilroy',sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 9px 12px;
    }
    margin-bottom: 15px;
`;

const ActiveApplyBtn = styled.button`
    margin-top: 15px;
    width: 100%;
    height: 36px;
    border: none;
    background: #34C57F;
    border-radius: 8px;
    color: white;
    cursor: pointer;
`;

export { FollowContainer, EmptityWrap, Comment, ActiveApplyBtn}