import { createAsyncThunk } from "@reduxjs/toolkit";
import { handlerApiRequests } from "../../services/handlerApiRequests.service";
import { getRequest } from "../../services/requests.services";
import { openMessageHint } from "../appSlice/appSlice";
import { handlerErrorsService } from "../../services/handlerErrors.service";

export const AddGalleryImage = createAsyncThunk('ExhibitorSlice/AddGalleryImage', async (data, thunkAPI) => {
    let image;
    const {ExhibitorApi} = getRequest()
    if(data.body.file){
        const formData = new FormData()
        formData.append('file', data.body.file) 
        const type = 'image'
        image = await ExhibitorApi.uploadCompanyFile(data.companyId, type, formData)
    }else{
        image = {
            src: data.body.src,
            fileName: data.body.fileName,
            fileSize: data.body.fileSize
        }
    }
    const sendData = {
        id: data.body.id,
        name: data.body.name,
        description: data.body.description,
        consultation: data.body.consultation,
        src: image.src,
        fileName: image.fileName,
        fileSize: image.fileSize,
        order: 0
    }
    const response = await ExhibitorApi.addGalleryMedia(data.companyId, sendData, 'image')
    handlerApiRequests(response, thunkAPI, 'addGalleryMedia')
    if(response){
        thunkAPI.dispatch(openMessageHint(true))
    }
    return response
})

export const AddGalleryVideo = createAsyncThunk('ExhibitorSlice/AddGalleryVideo', async (data, thunkAPI) => {
    const src = data.body.src
    const keyVideo = src.slice(src.indexOf('=') + 1, src.length)
    const {ExhibitorApi} = getRequest()
    const sendData = {
        tumb: `https://img.youtube.com/vi/${keyVideo}/hqdefault.jpg`,
        order: 0,
        src,
        name: data.body.name,
        description: data.body.description,
        consultation: data.body.consultation,
    }
    const response = await ExhibitorApi.addGalleryMedia(data.companyId, sendData, 'video')
    if(response){
        thunkAPI.dispatch(openMessageHint(true))
    }
    return response
})
export const DeleteGalleryMedia = createAsyncThunk('ExhibitorSlice/DeleteGalleryMedia', async (data, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.deleteGalleryMedia(data.companyId, data.body.id, data.body.type)
    thunkAPI.dispatch(openMessageHint(true))
    return response
})

export const UpdateCompanyText = createAsyncThunk('ExhibitorSlice/UpdateCompanyText', async(data, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = ExhibitorApi.updateCompanyText(data.companyId, data.body)
    if(response){
        thunkAPI.dispatch(openMessageHint(true))
    }
    return response
})

export const UpdateCompanyHeader = createAsyncThunk('ExhibitorSlice/UpdateCompanyHeader', async (data, thunkAPI) => {
    let userData = thunkAPI.getState().profile.userData;
    const companyId = userData.company?.id;
    const {ExhibitorApi} = getRequest()
    let logoSrc;
    let coverSrc;
    if(data.logo.file){
        const formData = new FormData()
        formData.append('file', data.logo.file) 
        const type = 'image'
        logoSrc = await ExhibitorApi.uploadCompanyFile(companyId, type, formData)
    }else{
        logoSrc = {src: data.logo.path}
    }
    if(data.cover.file){
        const formData = new FormData()
        formData.append('file', data.cover.file) 
        const type = 'image'
        coverSrc = await ExhibitorApi.uploadCompanyFile(companyId, type, formData)
    }else{
        coverSrc = {src: data.cover.path}
    }

    const sendCompanyHeader = {
        name: data.name,
    }
    if(logoSrc.src){
        sendCompanyHeader.logo = logoSrc.src
    }
    if(coverSrc.src){
        sendCompanyHeader.cover = coverSrc.src
    }
    const response = await ExhibitorApi.updateCompanyHeader(companyId, sendCompanyHeader)
    if(response){
        thunkAPI.dispatch(openMessageHint(true))
    }
    return response
})

export const ChangeVisibility = createAsyncThunk('ExhibitorSlice/ChangeVisibility', async (show, thunkAPI) => {
    let userData = thunkAPI.getState().profile.userData;
    const companyId = userData.company?.id;
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.changeVisibility(show, companyId)
    if(response){
        thunkAPI.dispatch(openMessageHint(true))
    }
    return response
})

export const GetRepresentatives = createAsyncThunk('ExhibitorSlice/GetRepresentatives', async (id, thunkAPI)=> {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.getRepresentatives()
    return response
})

export const AddRepresentatives = createAsyncThunk('ExhibitorSlice/AddRepresentatives', async (data, thunkAPI) => {
const {ExhibitorApi} = getRequest()
    try {    
        const response = await ExhibitorApi.addRepresentatives(data)
        thunkAPI.dispatch(openMessageHint(true))
        let representatives = await ExhibitorApi.getRepresentatives() 
        return {response, representatives}
    } catch (error) {
        return await handlerErrorsService(error, thunkAPI)
    }
})

export const ChangeVisibleRepresentative = createAsyncThunk(
    'ExhibitorSlice/ChangeVisibleRepresentative', 
    async (data, thunkAPI) => 
    {
       const {ExhibitorApi} = getRequest()
       let userData = thunkAPI.getState().profile.userData;
       const companyId = userData.company?.id;
       const response = await ExhibitorApi.changeVisibleRepresentative(companyId, data)
       thunkAPI.dispatch(openMessageHint(true))
       return {...response, user: data}
    }
)

export const DeleteRepresentatives = createAsyncThunk('ExhibitorSlice/DeleteRepresentatives', async (data, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.deleteRepresentatives(data)
    thunkAPI.dispatch(openMessageHint(true))
    return response
})

export const GetCompanyDocuments = createAsyncThunk('ExhibitorSlice/GetCompanyDocuments', async (key, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    let userData = thunkAPI.getState().profile.userData;
    const companyId = userData.company?.id;
    const response = await ExhibitorApi.getCompanyDocuments(companyId)
    return response
})

export const UpdateCompanyDocuments = createAsyncThunk('ExhibitorSlice/UpdateCompanyDocuments', async (file, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    let uploadFile;
    let userData = thunkAPI.getState().profile.userData;
    const companyId = userData.company?.id;
    if(!file.id){
        const formData = new FormData()
        formData.append('file', file.file) 
        const type = 'file'
        uploadFile = await ExhibitorApi.uploadCompanyFile(companyId, type, formData) 
    }else{
        uploadFile = {
            savedFileId: file.id
        }
    }
    const response = await ExhibitorApi.updateCompanyDocuments(uploadFile.savedFileId, file.show)
    thunkAPI.dispatch(openMessageHint(true))
    return response
})

export const DeleteCompanyDocuments = createAsyncThunk('ExhibitorSlice/DeleteCompanyDocuments', async(fileId, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.deleteCompanyDocuments(fileId)
    thunkAPI.dispatch(openMessageHint(true))
    return response
})

export const GetCompanyDepartments = createAsyncThunk('ExhibitorSlice/GetCompanyDepartments', async (companyId, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.getCompanyDepartments(companyId)
    return response
})

export const GetCompanyMedia = createAsyncThunk('ExhibitorSlice/GetCompanyMedia', async (companyId, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.getCompanyMedia(companyId)
    return response
})

export const GetContactsTypes = createAsyncThunk('ExhibitorSlice/GetContactsTypes', async (key, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.getContactsTypes()
    return response
}) 

export const AddNewContactDepartment = createAsyncThunk('ExhibitorSlice/AddNewContactDepartment', async (contact, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.addNewDepartmentContact(contact.companyId, contact.body.departmentId, {type: contact.body.type, value: contact.body.value})
    thunkAPI.dispatch(openMessageHint(true))
    return {contactList: response, departmentId: contact.body.departmentId }
})

export const DeleteDepartmentContact = createAsyncThunk('ExhibitorSlice/DeleteContactDepartment', async (contact, thunkAPI) =>{
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.deleteDepartmentContact(contact.companyId, contact.body.departmentId, contact.body)
    thunkAPI.dispatch(openMessageHint(true))
    return {...response, ...contact.body}
})

export const UpdateDepartmentContact = createAsyncThunk('ExhibitorSlice/UpdateDepartmentContact', async(contact, thunkAPI) => {
    const {ExhibitorApi} = getRequest()
    const response = await ExhibitorApi.updateDepartmentContact(contact.companyId, contact.body.departmentId, contact.body)
    thunkAPI.dispatch(openMessageHint(true))
    return {contactList: response, departmentId: contact.body.departmentId}
})