import styled from 'styled-components';



const WrapCompany = styled.div`
.component_edit_wrap{
    width: 100%;
    position: absolute;
    height: 100%;
    background: #0000007d;
    bottom: 0;
    .component_edit_button{
        position: inherit;
        right: 16px;
        top: 16px;
        width: 158px;
        height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        color: #FFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        svg{
            path{
                    fill: #FFFF;
                    stroke: #FFFF;
            }
        }
        }
  }

  background: #ffffff;

  .btn_boderGreen {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      background: #ffffff;
      border: 1px solid #34C57F;
      border-radius: 8px;
      color: #34C57F;
      
      width: 158px;
      height: 32px;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
  }

  .btn_backgroundWhite {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      background: #ffffff;
      border: none;
      border-radius: 8px;
      color: #34C57F;
      
      width: 158px;
      height: 32px;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
  }
  .btn_backgroundGreen {
    font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      background: #34C57F;
      border-radius: 8px;
      color: #ffffff;
      border: none;
      
      width: 158px;
      height: 32px;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
  }
  
  .backgroudGrey {
    background: #F8F8F8;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

`

const HeaderCompany = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 16px;

  .header-logo {
    display: flex;
    align-items: center;
    img {
      width: 55.39px;
      height: 34px;
    }
    h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        padding-left: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        opacity: 0.9;
    }
  }

  .header-nav {
    nav {
      height: 100%;
    }
    nav ul {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0;
      margin: auto;
    }
    nav ul li {
      float: left;
      position: relative;
      list-style-type: none;
      
      &:nth-of-type(2) {
        padding: 0 30px;
      }
      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        opacity: 0.9;
      }

    }
  }

  .header-btns {
    button:first-child {
      margin-right: 10px;
    }
  }

`

const BannerCompany = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  margin-bottom: 30px;
  background: ${props => props.backgroundUrl};
  background-repeat: no-repeat;
  background-size: cover;
  ${'' /*clip-path: ellipse(150% 100% at top);*/}

  .banner-content {
    text-align: center;
.Logo_container{
    width: 340px;
    height: 162px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        max-height: 100%;
    }
}
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 110%;
      text-align: center;
      color: #363636;
      padding: 16px 0;
    }
    .buttons_company{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    }

    .content__view {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #363636;
      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 110%;
        color: #FFFFFF;
        padding-left: 4px;
    padding-bottom: 3px;
      }
    }

    button {
      width: 181px;
      height: 36px;
      margin-top: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      cursor: pointer;
      svg {
        margin-right: 10px;
      }
      span {
        // height: 14px;
      }
    }
    .btn_backgroundWhite {
      margin-right: 10px;
      float: left;
      .favorites_icon{
        path{
            fill: #34C57F;
        }
      }
    }

  }
`

const ProductGroup = styled.div`
  width: 810px;
  margin: auto;
  padding: 70px 0;
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #363636;
  }
  p {
    display: flex; 
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px 0 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #484848;
  }

  .group-categories {
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;  

    .category:first-child {
      margin-left: 0px;
    }
    .category {
      padding: 4.5px 8px 4.5px 10px;
      border: 1px solid #363636;
      border-radius: 100px;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: #363636;
      white-space: nowrap;
      margin-top: 16px;
      margin-left: 10px;
    }
  }
`

const AboutCompany = styled.div`
    position: relative;
    padding: 40px 0 40px;
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #363636;
  }

  .adout-desc {
    display: flex;
    max-width: 900px;
    margin: auto;
    padding: 34px 0;
    text-align: justify;
    p {
        -webkit-transition: all 0.4s linear 0s;
        -o-transition: all 0.4s linear 0s;
        transition: all 0.4s linear 0s;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: #363636;
        opacity: 0.9;
    }
    
  }
  .btn_wrap{
    width: 100%;
    display: flex;
  .read_more{
    margin: 0 auto;
    width: 147px;
    height: 30px;
    background: #F4F4F4;
    border-radius: 8px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #363636;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
  }
  }

`

const WorkersCompany = styled.div`
  padding: 40px 0 40px;
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #363636;
  }
  .worker-subhead {
    display: flex; 
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px 0 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #484848;
  }

  .workers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 16px;
    gap: 16px;
  }

  .company-worker {
    flex-basis: 24%;
    max-width: 340px;
    min-width: 300px;
    max-height: 66px;
    padding: 12px;

    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    .worker_desc {
      padding-left: 12px;
      .worker__name {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }
      .worker__role {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-transform: capitalize;
        color: #484848;
        opacity: 0.7;
      }
    }



    button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #EFEFEF;
      border-radius: 8px;
      border: none;
      margin-left: auto;
      cursor: pointer;
    }
  }
`

const DocumentsCompany = styled.div`
  padding: 20px 0 40px;
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #363636;
  }
  .documents-subhead {
    display: flex; 
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px 0 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #484848;
  } 

  .documents {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 16px;
  }

  .company-document {
    flex-basis: 24%;
    min-width: 300px;
    max-height: 56px;
    margin-left: 16px;
    margin-top: 16px;
    padding: 12px;
    &:first-child {
      margin-left: 0;
    }

    .pdf-icon {
      width: 26px;
      height: 32px;
    }

      .document__name {
        padding-left: 12px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
      }

    button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #EFEFEF;
      border-radius: 8px;
      border: none;
      margin-left: auto;
      cursor: pointer;
    }
a{
    margin-left: auto;
}
  }



`
const ContactsCompany = styled.div`
  padding: 40px 0;
  position: relative;
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    color: #363636;
  }
  .contacts-subhead {
    display: flex; 
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 20px 0 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #484848;
  } 

  .contacts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 16px;
  }

  .company-contact {
    flex-basis: 24%;
    max-width: 340px;
    min-width: 300px;
    max-height: 135px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin: 16px 8px 0;

    .contact__head {
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      p {
        padding-left: 8px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        display: flex;
    align-items: center;
    gap: 5px;
}
      }
    }

    .contact__desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      color: #484848;
    }
`


export {
  WrapCompany,
  HeaderCompany,
  BannerCompany,
  ProductGroup,
  AboutCompany,
  WorkersCompany,
  DocumentsCompany,
  ContactsCompany,
};

  