
let variablesMap = new Map([
    ['http://127.0.0.1:3000', {
        interfaceUrl: 'http://127.0.0.1:3000',
        serverUrl: 'https://dev.rest.clevr.by',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://localhost:3000', {
        interfaceUrl: 'http://localhost:3000',
        serverUrl: 'https://dev.rest.clevr.by',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://localhost:3001', {
        interfaceUrl: 'http://localhost:3000',
        serverUrl: 'https://dev.rest.clevr.by',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://localhost:3779', {
        interfaceUrl: 'http://localhost:3000',
        serverUrl: 'https://dev.rest.clevr.by',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://134.17.16.140:9000', {
        interfaceUrl: 'http://localhost:9000',
        serverUrl: 'https://dev.rest.clevr.by',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://localhost:9000', {
        interfaceUrl: 'http://localhost:9000',
        serverUrl: 'https://dev.rest.clevr.by',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://dev_expo_react_web', {
        interfaceUrl: 'https://dev.clevr.by',
        serverUrl: 'http://devexpospring',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
        serverWebsocketUrl: 'wss://test.chat.clevr.by',
    }],
    ['https://dev.clevr.by', {
        serverUrl: 'https://dev.rest.clevr.by',
        filesUrl: 'https://dev.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://test_expo_react_web', {
        interfaceUrl: 'https://test.clevr.by',
        serverUrl: 'http://testexpospring',
        filesUrl: 'https://test.expo.clevr.by',
        canOpenExhibition: true,
        serverWebsocketUrl: 'wss://test.chat.clevr.by',
    }],
    ['https://test.clevr.by', {
        serverUrl: 'https://test.rest.clevr.by',
        filesUrl: 'https://test.files.clevr.by',
        canOpenExhibition: true,
    }],
    ['http://prod_expo_react_web', {
        interfaceUrl: 'https://clevr.by',
        serverUrl: 'http://prodexpospring',
        filesUrl: 'https://online.clevr-expo.com',
        canOpenExhibition: true,
        serverWebsocketUrl: 'wss://chat.clevr.by',
    }],
    ['https://clevr.by', {
        serverUrl: 'https://rest.online.clevr-expo.com',
        filesUrl: 'https://files.clevr-expo.com',
        canOpenExhibition: true,
        serverWebsocketUrl: 'wss://chat.clevr.by',
    }],
    ['https://clevr-expo.com', {
        serverUrl: 'https://rest.online.clevr-expo.com',
        filesUrl: 'https://files.clevr-expo.com',
        canOpenExhibition: true,
        serverWebsocketUrl: 'wss://chat.clevr.by',
        
    }],
    ['https://www.clevr.by', {
        serverUrl: 'https://rest.online.clevr-expo.com',
        filesUrl: 'https://files.clevr-expo.com',
        canOpenExhibition: true,
        serverWebsocketUrl: 'wss://chat.clevr.by',
    }],
    ['https://www.clevr-expo.com', {
        serverUrl: 'https://rest.online.clevr-expo.com',
        filesUrl: 'https://files.clevr-expo.com',
        canOpenExhibition: true,
        serverWebsocketUrl: 'wss://chat.clevr.by',
    }],
    ])

let creatUrlExhibition;
let interfaceUrl;
let serverUrl;
let filesUrl;
let isSsr;
let serverWebsocketUrl;

function initiate(origin, _isSsr) {

    creatUrlExhibition = () => {

        if(variablesMap.get(origin)?.canOpenExhibition){
            return filesUrl;
        }
        else {
            return filesUrl + '/virtualStandsWillBeAvailableSoon.jpg';
        }

    }
    
    // console.log('origin = ', origin);
    // console.log('_isSsr = ', _isSsr);

    interfaceUrl = variablesMap.get(origin)?.interfaceUrl ? variablesMap.get(origin)?.interfaceUrl : origin;
    serverUrl = variablesMap.get(origin)?.serverUrl;
    filesUrl = variablesMap.get(origin)?.filesUrl;
    isSsr = _isSsr;
    serverWebsocketUrl = variablesMap.get(origin)?.serverWebsocketUrl;

    if(!serverWebsocketUrl) {
        serverWebsocketUrl = 'wss://dev.chat.clevr.by';
    }

    return {filesUrl, serverUrl};
    // console.log('interfaceUrl = ', interfaceUrl);
    // console.log('serverUrl = ', serverUrl);
    // console.log('filesUrl = ', filesUrl);

}

const reCAPTCHA_siteKey = '6LcCTDsbAAAAAL8ii3ewW4j8P36pR2ZMvaFZJq3x'

export { filesUrl, interfaceUrl, serverUrl, reCAPTCHA_siteKey, creatUrlExhibition, initiate, isSsr, serverWebsocketUrl }
