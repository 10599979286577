
export const forms_en = {
    base: {
        firstName: "Name",
        firstNamePl: "Enter your name",
        email: "Email",
        emailPl: "Enter Email",
        password: "Password",
        passwordPl: "Enter password",
        repeatPassword: "Repeat password",
        repeatPasswordPl: "Please re-enter your password",
        phone: "Phone",
        cancel: "Cancel ",
    },
    registr: {
        head: "Registration",
        subhead1: "Fill in all required fields",
        subhead2: "to create your CleVR user account",
        blockUserH1: "User",
        lastName: "Last name",
        lastNamePl: "Enter your last name",
        patronymic: "Surname",
        patronymicPl: "Enter your surname",
        blockSecurityH1: "Safety",
        blockAdditH1: "Additional contacts",
        agreement1: "I agree with the terms of use,",
        agreement2: "privacy policy and",
        agreement3: "personal data processing",
        submit: "Register",
        errors: {
            acceptAgreement: "Accept user agreement",
        },
    },
    login: {
        head: "Login",
        subhead: "Sign in with your email and password",
        forgetPassword: "Forgot password?",
        login: "Login",
        registr: "Registration",
        notAccount: "Don't have an account yet? Sign up for CleVR",
        register: "Register",
    },
    support: {
        head: "Support service",
        theme: "Subject",
        themePl: "Enter the subject of the appeal",
        message: "Message",
        messagePl: "Enter the message",
        send: "Send",
    },
    forgetPassword:{
        head: "Forgot your password?",
        subhead: "Enter your email and follow the link in the mail to change your password",
        addDesc: "Check the spam and the correctness of the entered mail, if the link does not appear in your messages",
        back: "Back",
        getLink: "Get the link",
        registr: "Registration",
        notAccount: "Don't have an account yet? Sign up for CleVR",
        register: "Register",
    },
    requestPart: {
        head: "Application for participation",
        subhead1: "Fill in the Application Form",
        subhead2: "of your organization in CleVR and we will contact you",
        blockCompanyH1: "Organization",
        organizationName: "Organization name",
        organizationNamePl: "Enter organization name",
        typeActivity: "Type of activity",
        typeActivityPl: "Choose the type of activity of the enterprise",
        blockContactH1: "The contact person",
        comment: "Comment",
        submitRequest: "Submit a request"
    },
    question: {
        head: 'New question',
    }
}
