export const modalAddCompany_en = {
    nameUrl: "Company name for the url",
    nameUrlPlaceH: "Company name",
    noNameUrl: "Email not entered",
    nameRu: "Company name in russian",
    nameRuPlaceH: "Название компании",
    noNameRu: "Email not entered",
    nameEn: "Company name in english",
    nameEnPlaceH: "Company name",
    noNameEn: "Email not entered",
    nameBy: "Company name in belarusian",
    nameByPlaceH: "Назва кампаніі",
    noNameBy: "Email not entered",
    example: "Example",
    nameError: "Only small letters of the Latin alphabet are allowed",
    addCompanyBtn: "Add a company",
}