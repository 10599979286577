import Slider from "react-slick";
import { VideoSliderWrap } from "./VideoSlider.style";
import arrowRight from "../../../../../../assets/Components/Routes/PavilionsList/arrow-right.png";
import arrowLeft from "../../../../../../assets/Components/Routes/PavilionsList/arrow-left.png";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player/lazy";

export const VideoSlider = () => {
  const { companyMedia } = useSelector((state) => state.pavilions);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const materialsVideo = companyMedia.filter(
      (material) => material.type === "video"
    );

    setVideos(materialsVideo);
  }, [companyMedia]);

  return (
    <VideoSliderWrap arrR={arrowRight} arrL={arrowLeft} className="slider-container">
      <Slider>
        {videos.map((video, index) => {
          return (
            <div className="slider" key={index}>
              <div className="material">
                <ReactPlayer
                  url={video.src}
                  className="material__video"
                  alt="material_img"
                  controls={true}
                ></ReactPlayer>
              </div>
            </div>
          );
        })}
      </Slider>
    </VideoSliderWrap>
  );
};
