import styled from "styled-components";

const ModalMessageWrap = styled.div`
    padding: 0px 16px 30px;
    margin-top: 24px;
    .message{
        font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
text-align: center;
color: #484848;
padding: 16px 0;
    }
    button{
        border: 1px solid #34C57F;
        height: 36px;
        width: 100%;
border-radius: 8px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 140%;
color: #34C57F;
background: #FFFFFF;
cursor: pointer;
    }
`

export { ModalMessageWrap }