import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NewsGalleryWrap } from "./style/newsGallery_css";
import { SlickArrowLeft, SlickArrowRight } from "../../../SlickArrowComponent/SlickArrowComponent";
import { serverUrl } from "../../../../API/api";

export const NewsGallery = ({media}) => {
    const settings = {
        prevArrow: <SlickArrowLeft/>,
        nextArrow: <SlickArrowRight/>,
        variableWidth: true,
        infinite: true,
        centerMode: true,
      };

      if(!media) return null


  return (
    <NewsGalleryWrap>
      <h2>Галерея новости {`(${media.length - 1})`}</h2>
      <Slider {...settings}>
        {media.filter((item) => !item.preview).map((item, index) => (
          <div key={index}>
            <img src={`${serverUrl}/api/media/download/${item.id}`} alt="media_gallery" className="media_img"></img>
          </div>
        ))}
      </Slider>
    </NewsGalleryWrap>
  );
};
