import { createSlice } from "@reduxjs/toolkit"
import { extraReducers } from "./adminExtraReducers"
import { reducers } from "./adminReducers"

export const initialState ={
    roles: [],
    error: '',
    pavilions: [],
    expanents: [],
    users: [],
    message: '',
    openModal: false,
    editCompany: {
        open: false,
        component: ''
    },
    createCompany: {
        name: {
            ru: "",
            en: "",
            by: "",
          },
        nameKey: "",
        numberOfContract: "",
        email: "",
        pavilionsId: [],
        user: {},
        note: "",
        contract: {
            file: ''
        },
    }
}

export const AdminSlice = createSlice({
    name: 'AdminSlice',
    initialState,
    reducers,
    extraReducers
})
export const {
    setAdminState, 
    setCreateCompanyInfo,
    setCompanyContract,
    setCompanyPavilionsId,
    resetAdminState,
    changePavilionsSequence
} = AdminSlice.actions
export const AdminReducer = AdminSlice.reducer