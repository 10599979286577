import React from "react";
import { PavilionItem } from "./PavilionVDNX/PavilionItem";
import { StyledContainer } from "./style/pavilions_css";
import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { SlickArrowLeft, SlickArrowRight } from "../../SlickArrowComponent/SlickArrowComponent";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { useState } from "react";

export const Pavilions = () => {
    const {width} = useWindowDimensions()
    const { pavilions } = useSelector((state) => state.pavilions);
    const [currSlide, setCurrSlide] = useState(0)

    const setCenterPadding = (width) => {
        if(width > 1200){
            return '141px'
        }else if(width < 1200 && width > 769) {
            return '100px'
        } else {
            return '0px'
        }
    }

    const renderSliderDots = (dots) => {

        return (
            <div>
                <p style={
                    {
                    color: '#FFF', 
                    textAlign: 'center', 
                    fontFamily: 'Gilroy', 
                    fontSize: '14px', 
                    fontStyle: 'normal', 
                    fontWeight: 500, 
                    lineHeight: '140%'
                    }}
                >{`${currSlide + 1}/${pavilions.length}`}</p>
                <ul>{dots}</ul>
            </div>
        )
    }


  const sliderSettings = {
    centerMode: true,
    centerPadding: setCenterPadding(width),
    dots: true,
    appendDots: (dots) => renderSliderDots(dots),
    customPaging: (i) => <button className="slick_dots_btn"></button>,
    beforeChange: (oldI, nextI) => {
        setCurrSlide(nextI)
    },
    prevArrow: <SlickArrowLeft/> ,
    nextArrow: <SlickArrowRight/>,
  };

  if(pavilions.length === 0){
    return null
  }


  return (
    <section id="scrollPavilions" style={{maxWidth: '1920px'}}>
      <StyledContainer>
        <Slider {...sliderSettings}>
          {pavilions.map((pavilion, index) => {
            return <PavilionItem key={index} pavilion={pavilion} />;
          })}
        </Slider>
      </StyledContainer>
    </section>
  );
};
