export const modals_en = {
    block: {
        chatH1: "Blocking the chat",
        userH1: "Blocking",
        beBlocked: "will be blocked",
        cantSendMes: "and will not be able to send you messages",
        yesBlock: "Yes, block",
    },
    unblock: {
        chatH1: "Unblocking the chat",
        userH1: "Unblocking",
        beUnblocked: "will be unblocked",
        canSendMes: "and will be able to send you messages",
        yesUnblock: "Yes, unblock",
    },
    removeContact: {
        head: "Delete a contact",
        beRemoved: "will be removed from the list",
        yourContacts: "your contacts",
        noRemove: "No, leave",
        yesRemove: "Yes, delete",
    },
    removeChat: {
        head: "Delete a chat",
        subhead1: "Would you like to delete this chat? The history of the correspondence will be",
        subhead2: "deleted for you and your interlocutor.",
        noRemove: "No, leave",
        yesRemove: "Yes, delete",
    },
    cancel: "Cancel",
}