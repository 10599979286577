import { useState } from "react"
import { useSelector } from "react-redux"
import { filesUrl } from "../../../../../API/api"
import { svg_icons } from "../../../../../assets/imgs/svg_icons"
import { icons_svg } from "../../../../Exhibition/Company/imgs/icons.svg"
import { ScrollBarWrap } from "../../AdminPanelContent/AdminPanelContent.style"
import { CharterCard, CharterWrap, RoleAccessWrap } from "../../Roles/AddNewRole/AddNewRole.style"

export const AddExhibitor = ({handlerActions}) => {
    const {triangleDown, triangleUp, addIcon} = svg_icons
    const {search} = icons_svg

    const [openTab, setOpenTab] = useState(false)
    const {users} = useSelector((state) => state.admin)

    return (
        <div className="info_wrap" style={{position: 'relative'}}>
        <p className="info_text">{"Администратор экспонента"}</p>
        <button className="btn_select" onClick={() => setOpenTab(!openTab)}>
          {"Пригласите администратора"} {!openTab ? triangleDown : triangleUp}
        </button>
        <RoleAccessWrap style={openTab ? {display: 'block', position: 'absolute', background: '#FFFFFF', zIndex: '3'} : {display: 'none'}}>
                  <div className="all_role_access">
                  <div className="search_access_roles">
                      {search}
                      <input type={'text'} placeholder={'Начните вводить'}/>
                  </div>
                  </div>
                  <CharterWrap>
                      <ScrollBarWrap>
                          <div className="charter_container">
                              {users.map((user, index) => {
                                  return (
                                      <CharterCard key={index}>
                                          <img style={{marginRight: '8px'}} src={filesUrl + user.avatar} alt='user_avatar' />
                                          <div>
                                          <div style={{display: 'flex', gap: '5px'}}>
                                          <p className="fullName_text">{user.firstName}</p>
                                          <p className="fullName_text">{user.lastName}</p>
                                          </div>
                                          <p className="email_info">{user.email}</p>
                                          </div>
                                          <button className="add_user_company" onClick={handlerActions('setUser', user)}>{addIcon}</button>
                                      </CharterCard>
                                  )
                              })}
                          </div>
                      </ScrollBarWrap>
                  </CharterWrap>
              </RoleAccessWrap>
      </div>
    )
}