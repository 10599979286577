export const modalAddCompany_by = {
    nameUrl: "Назва кампаніі па англійску для url адресы",
    nameUrlPlaceH: "Назва кампаніі",
    noNameUrl: "Email not entered",
    nameRu: "Назва кампаніі па руску",
    nameRuPlaceH: "Название компании",
    noNameRu: "Назва кампаніі на руску",
    nameEn: "Назва кампаніі па англійску",
    nameEnPlaceH: "Company name",
    noNameEn: "Email not entered",
    nameBy: "Назва кампаніі па беларуску",
    nameByPlaceH: "Назва кампаніі",
    noNameBy: "Email not entered",
    example: "Прыклад",
    nameError: "Дапускаюцца толькі маленькія літары лацінскага алфавіту",
    addCompanyBtn: "Дадаць кампанію",
}