import styled from "styled-components";

const AddDepartmentPageWrap = styled.div`
padding: 24px 16px 25px;
.name_department{
    padding-top: 20px;
    margin-bottom: 30px;
    .name_text{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    input{
        width: 100%;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        padding: 0 12px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        outline: none;
    }
}
`;

export {
    AddDepartmentPageWrap
}