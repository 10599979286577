export const editCompany_ru = {
    imgVideo: "Изображение или видео(Youtube)",
    numbersFiles: "Количество файлов",
    companyName: "Название компании",
    compOrganForm: "Форма организации компании",
    abbreviation: "Аббревиатура",
    headerColor: "Цвет заголовков",
    logo: "Логотип",
    addLogo: "Добавить логотип",
    addMedia: "Добавить медиа",
    addImage: "Добавить изображение",
    addVideo: "Добавить видео",
    addDoc: "Добавить документ",
    linkImage: "Ссылка на изображение",
    pcImage: "Изображение с компьютера",
    linkLogo: "Ссылка на логотип",
    pcLogo: "Логотип с компьютера",
    linkYoutube: "Ссылка с Youtube",
    pcDoc: "Документ с компьютера",
    url: "Вставить ссылку",
    pc: "Выбрать с компьютера",
    aboutCompany: "О компании",
    additionalInfoHeader: "Дополнительная информация (Заголовок)",
    additionalInfoText: "Дополнительная информация (Текст)",
    showCompany: "Показывать компанию",
    btnSave: "Сохранить",
    btnBack: "Назад",
    btnDelete: "Удалить",
    btnAdd: "Добавить",
    btnOverwrite: 'Перезаписать',
    sendData: "Отправка данных",
    selectPavilions: "Выбор павильона",
    headerImg: 'Изображение',
    headerVideo: 'Видео',
    modalDeleteCompany_text: 'Вы действительно хотите удалить компанию?',
    modalDeleteCompany_btnYes: 'Да',
    modalDeleteCompany_btnNo: 'Нет',
    toolbar: {
        header:'Панель инструментов',
        addLinkBtn:'Добавить ссылку',
        addLinkError:'Невозможно вставить часть тега в другой тег',
    },
    limit: {
        imageSize: 'Размер изображаения не более 10мб',
        logoSize: 'Размер логотипа не более 2мб',
        docSize: 'Размер документа не более 50мб',
        mediaNumber: 'Количество медиа файлов не более 30',
        docNumber: 'Количество документов не более 30',
    },
}