import {
  FavoritesDescription,
  MyFavoritesInfo,
  MyFavoritesItem,
  MyFavoritesWrap,
  SendDate,
  WrapRequests,
} from "./style/MyFavorites_css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ApproveMeeting } from "../../../store/profileSlice/profileAsyncThunk";
import { filesUrl } from "../../../API/api";
import { setAppState } from "../../../store/appSlice/appSlice";
import { deleteMeetings, getRequestsMeetings } from "../../../store/profileSlice/profileAsyncThunk";
import { UserSettings } from "../Admin/Users/AllUsers/AllUsers.style";
import AppSelect from './../../../components/AppSelect/AppSelect';
import { resetProfileState, setProfileTableState } from "../../../store/profileSlice/profileSlice";
import { dateToLocaleDateString } from "../../../utils/helpers";
import {svg_icons} from "../../../assets/imgs/svg_icons"

export const MyFavorites = ({ activePanel }) => {
  const onlyPage = activePanel === "OnlyFavorites";
  const dispatch = useDispatch();
  const {table: {filter}, requestsMeetings} = useSelector((state) => state.profile);
  const { pointsIcon, incomingIcon, outgoingIcon, searchIcon, allowIcon, discardIcon } = svg_icons;
  const {
    language,
    profile: { requestText },
  } = useSelector((state) => state.app.appText);
  const settingSelects = {
    select1: {
      options: [
        { id: 1, value: requestText.requestMeet},
        { id: 2, value: requestText.requestConsult},
    ],
      id: "select1",
    },
    select2: {
      options: [
        { id: 0, value: requestText.all },
        { id: "true", value: requestText.incoming },
        { id: "false", value: requestText.outgoing },
      ],
      id: "select2",
    },
  };

  const handlerActions = (type, data) => async (e) => {
    switch (type) {
      case "changeSearch":
        dispatch(
          setProfileTableState({
            filter: { ...filter, search: e.target.value },
          })
        );
        break;
      case "changeFilter":
        let filterSelect = e === 2 || e === 1 ? 
          {incoming: filter.incoming, consult: e === 2} :
          {incoming: e, consult: filter.consult};
        dispatch(
          setProfileTableState({
            filter: { ...filter, ...filterSelect},
          })
        );
        break;
        case "contextMenu":
          dispatch(setAppState({contextMenu: {
            currentTarget: e.currentTarget,
            listMenu: [
              {
                onClick: () => {
                    dispatch(ApproveMeeting(data.id))
                },
                icon: allowIcon,
                text: "Принять",
              },
              { onClick: () => dispatch(deleteMeetings({id: data.id, consult: filter.consult})), icon: discardIcon, text: "remove", class: " error" },
            ]
          }}))
          break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getRequestsMeetings(filter));
  }, [dispatch, filter]);

  useEffect(() => () => dispatch(resetProfileState("table")), [dispatch]);

  if(!requestsMeetings) return null;

  return (
    <WrapRequests onlyPage={onlyPage}>
      <h2>
        {onlyPage
          ? `${requestText.name} (${requestsMeetings.length})`
          : requestText.name}
      </h2>

      <div className="requests-filter">
        <AppSelect
          settings={settingSelects.select1}
          onChange={handlerActions("changeFilter")}
        />
        <AppSelect
          settings={settingSelects.select2}
          onChange={handlerActions("changeFilter")}
        />
        <div className="filter-search">
          {searchIcon}
          <input
            placeholder={requestText.searchPl}
            onChange={handlerActions("changeSearch")}
          ></input>
        </div>
      </div>

      <MyFavoritesInfo>
        <strong>{onlyPage ? requestText.search : requestText.all}: </strong>
        {requestsMeetings.length}
      </MyFavoritesInfo>

      <MyFavoritesWrap>
        {requestsMeetings.length === 0 ? (
          <p className="empty_arr">{requestText.noRequests}</p>
        ) : (
          requestsMeetings.map((el, index) => {
            return (
              <MyFavoritesItem key={index}>
                <img
                  style={{ padding: "12px" }}
                  src={filesUrl + el.logo}
                  width={52}
                  height={52}
                  alt="Logo"
                ></img>
                <div className="Favorites_item_block">
                  <div>
                    <h3>
                      {el.subNameShort[language]} {el.name[language]}
                    </h3>
                    <SendDate>
                    {el.time ? dateToLocaleDateString(el.time) : ""}
                      {el.incoming ? (
                        <span className="request__incoming">{incomingIcon} {requestText.incoming}</span>
                      ) : (
                        <span className="request__outgoing">{outgoingIcon} {requestText.outgoing}</span>
                      )}
                    </SendDate>
                    <FavoritesDescription>
                      <strong>{requestText.theme}</strong>
                      {el.theme}
                    </FavoritesDescription>
                  </div>
                  <UserSettings style={{ marginLeft: "auto" }}>
                    <button
                      className="dots_settings"
                      onClick={handlerActions("contextMenu", el)}
                    >
                      {pointsIcon}
                    </button>
                  </UserSettings>
                </div>
              </MyFavoritesItem>
            );
          })
        )}
      </MyFavoritesWrap>
    </WrapRequests>
  );
};
