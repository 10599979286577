export const features_en = {
    blockHead1_0: "Attractive rates ", 
    blockHead1_1: "and individual ", 
    blockHead1_2: "3D stands ", 
    blockHead1_3: "for each participant",
    blockDesc1: "Create your own exhibition stands and design them according to the corporate identity of your company. Place product samples, including in 3D format.", 
    blockHead2_0: "Get detailed ", 
    blockHead2_1: "statistics ", 
    blockHead2_2: "about the work of your page", 
    blockDesc2: "Authorized users have access to complete statistics on the premises and the active actions of other exhibitors within the company's page and 3D stand.", 
}