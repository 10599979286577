import axios from "axios";
import {handlerLocalStorage} from "./handlerLocalStorage.service";
import {serverUrl} from "../API/api";

const {get} = handlerLocalStorage();

/*const token = get("token")
const setAuthorization = {'Authorization': `Bearer ${token}`}*/
//https://rest.online.clevr-expo.com
export const getRequest = () => {
  let lang = get("language") || "ru";

  const initRequest = async (url, method, headers, data, progress, rest) => {
    headers =
      headers === "noHeader"
        ? undefined
        : {Authorization: `Bearer ${get("token")}`};

    const response = await axios.request({
      url,
      method,
      headers,
      data,
      onUploadProgress: progress,
      ...rest,
    });
    return response.data;
  };

  const PavilionsApi = {
    getPavilions() {
      return initRequest(`${serverUrl}/api/pavilion`, "get", "noHeader");
    },
    getCompanies({selectPavilion, selectCompany}) {
      return initRequest(
        `${serverUrl}/api/company?pavilionKey=${selectPavilion}&searchParam=${selectCompany}&page=1&size=100&language=${lang}`,
        "get",
        "noHeader"
      );
    },
    getCompany(url, firstName) {
      return initRequest(
        `${serverUrl}/api/company/${url}`,
        "get",
        get("token") && firstName ? "" : "noHeader"
      );
    },
    getAllProducts() {
      return initRequest(`${serverUrl}/api/product-groups`, "get", "noHeader");
    },
  };

  const NewsApi = {
    getNews({searchParam = ""}) {
      return initRequest(
        `${serverUrl}/api/v2/news/all?searchParam=${searchParam}`,
        "get",
        "noHeader"
      );
    },
    getSelectNews(id) {
      return initRequest(`${serverUrl}/api/v2/news/${id}`, "get", "noHeader");
    },
    //getNews(data){
    //    return initRequest('https://rest.online.clevr-expo.com' + `/api/news/all?language=${lang}&page=${1}&size=${data.size}`, 'get', 'noHeader')
    //},
    //getSelectNews(id){
    //    return initRequest('https://rest.online.clevr-expo.com' + `/api/news/${id}?language=${lang}`, 'get', 'noHeader')
    //},
    getAdminNews(data) {
      return initRequest(
        `${serverUrl}/api/v2/news/filter?searchParam=${data.search}&mainNews=${data.mainNews}&draft=${data.draft}`,
        "get"
      );
    },
    createNews(data) {
      return initRequest(`${serverUrl}/api/v2/news/create`, "post", "", data);
    },
    deleteAdminNews(newsId) {
      return initRequest(`${serverUrl}/api/v2/news/${newsId}`, "delete");
    },
    updateNewsShow(news) {
      return initRequest(
        `${serverUrl}/api/v2/news/${news.id}/is-show?isShow=${!news.isShow}`,
        "put"
      );
    },
    makeMainNews(news) {
      return initRequest(
        `${serverUrl}/api/v2/news/${
          news.id
        }/main-news?mainNews=${!news.mainNews}`,
        "put"
      );
    },
    deleteMediaNews(media, newsId) {
      return initRequest(
        `${serverUrl}/api/v2/news/${newsId}/media/${media.id}`,
        "delete"
      );
    },
    updateNewsMedia(media, newsId) {
      return initRequest(
        `${serverUrl}/api/v2/news/upload/${newsId}`,
        "put",
        "",
        media
      );
    },
    updateNews(news, newsId) {
      return initRequest(
        `${serverUrl}/api/v2/news/update/${newsId}`,
        "put",
        "",
        news
      );
    },
    updatePreview({mediaId, preview}) {
      return initRequest(
        `${serverUrl}/api/v2/news/${mediaId}/preview?preview=${preview}`,
        "put"
      );
    },
  };

  const QuestionsApi = {
    sendQuestionModal(data) {
      return initRequest(
        `${serverUrl}/clevr/email/question`,
        "post",
        "noHeader",
        data
      );
    },
  };

  const UserApi = {
    deleteDialog(id) {
      return initRequest(`${serverUrl}/api/v2/dialogs/${id}`, "delete");
    },

    sendMessage(data) {
      return initRequest(
        `${serverUrl}/api/v2/dialogs/${data.id}/messages`,
        "post",
        null,
        data.body
      );
    },

    getDialogs() {
      return initRequest(`${serverUrl}/api/v2/dialogs`, "get");
    },

    createDialog(body) {
      return initRequest(`${serverUrl}/api/v2/dialogs`, "post", null, body);
    },

    getDialogById(id) {
      return initRequest(`${serverUrl}/api/v2/dialogs/${id}`, "get");
    },

    /* makeCompanyOwner(params) {
                let url =`${serverUrl}/users/user-${params.post ? "to-company" : "from-company" }?contactId=${params.contactId}`;
                let method = params.post ? "post" : "delete" ;
                return initRequest(url, method) 
            },*/

    getStatisticsCompany(params) {
      return initRequest(
        `${serverUrl}/api/statistics/company?dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`,
        "get"
      );
    },

    toggleBlockUser(params) {
      return initRequest(
        `${serverUrl}/users/block?blockUserId=${params.userId}&blockStatus=${params.status}`,
        "post"
      );
    },

    toggleAddUser(params) {
      return initRequest(
        `${serverUrl}/users/contacts/${
          params.method === "post" ? "add" : "delete"
        }?contactId=${params.userId}`,
        params.method
      );
    },

    deleteUserDocumentsById(fileId) {
      return initRequest(
        `${serverUrl}/users/documents/delete?fileId=${fileId}`,
        "delete"
      );
    },

    authUser(data) {
      return initRequest(
        `${serverUrl}/api/v2/auth/login`,
        "post",
        "noHeader",
        data
      );
    },
    getUserData() {
      return initRequest(`${serverUrl}/users/profile/data`, "get");
    },
    registerUser(data) {
      return initRequest(
        `${serverUrl}/api/v2/auth/register`,
        "post",
        "noHeader",
        data
      );
    },
    async activateEmail(token) {
      return initRequest(
        `${serverUrl}/api/v2/auth/activate/${token}`,
        "get",
        "noHeader"
      );
    },
    UpdateProfileName(data) {
      return initRequest(`${serverUrl}/users/profile/update`, "put", "", data);
    },
    uploadFiles(file, type, isAvatar, companyId) {
      return initRequest(
        `${serverUrl}/files/api/upload?type=${type}${
          companyId ? `&companyId=${companyId}` : ""
        }&isAvatar=${isAvatar}&isGeneralAccess=true`,
        "post",
        "",
        file
      );
    },
    changeAvatar(avatarId) {
      return initRequest(
        `${serverUrl}/users/change_avatar?avatarId=${avatarId}`,
        "post"
      );
    },
    changePassword({oldPassword, newPassword}) {
      return initRequest(
        `${serverUrl}/api/user-password/change?oldPassword=${oldPassword}&newPassword=${newPassword}`,
        "post"
      );
    },
    getUserFavoritesCompany(language, searchParam) {
      return initRequest(
        `${serverUrl}/users/favorites-company?language=${language}&search=${searchParam}`,
        "get"
      );
    },
    getUserDocuments() {
      return initRequest(`${serverUrl}/users/documents`, "get");
    },
    getContactsList(language, searchParam) {
      return initRequest(
        `${serverUrl}/users/contacts/pageable/list?language=${language}&page=${1}&size=${100}&searchParam=${
          searchParam || ""
        }`,
        "get"
      );
    },
    addFavoritesCompany(id) {
      return initRequest(
        `${serverUrl}/users/favorites-company?companyId=${id}`,
        "post"
      );
    },
    addRequestMeeting(data) {
      return initRequest(`${serverUrl}/api/meetings`, "post", "", data);
    },
    getRequestsMeetings(language, params) {
      return initRequest(
        `${serverUrl}${
          params.consult
            ? "/api/product-consultation"
            : "/api/meetings/requests"
        }?language=${language}&search=${params.search}&incoming=${
          params.incoming || ""
        }`,
        "get"
      );
    },
    deleteMeetings(id, consult) {
      return initRequest(
        `${serverUrl}${
          consult ? "/api/product-consultation" : "/api/meetings"
        }/${id}`,
        "delete"
      );
    },
    getApprovedMeetings(language, params) {
      return initRequest(
        `${serverUrl}/api/meetings/approved?language=${language}&search=${
          params.search
        }&incoming=${params.incoming || ""}`,
        "get"
      );
    },
    sendConsultCompany(data, language) {
      return initRequest(
        `${serverUrl}/api/company/${data.id}/email/consult?language=${language}&imageId=${data.imageId}`,
        "post"
      );
    },
    sendRequestJoinClevr(data) {
      return initRequest(
        `${serverUrl}/clevr/email/request`,
        "post",
        "noHeader",
        data
      );
    },
    sendTechSupportQuestion(data) {
      return initRequest(
        `${serverUrl}/clevr/email/question`,
        "post",
        "noHeader",
        data
      );
    },
    confirmNewPassword(token) {
      return initRequest(
        `${serverUrl}/api/user-password/confirm/${token}`,
        "get",
        "noHeader"
      );
    },
    recoverPassword(data) {
      return initRequest(
        `${serverUrl}/api/user-password/restore`,
        "post",
        "noHeader",
        data
      );
    },
    deleteSelectExhibitor(companyId) {
      return initRequest(
        `${serverUrl}/users/favorites-company?companyId=${companyId}`,
        "delete"
      );
    },
    approveMeeting(requestId) {
      return initRequest(
        `${serverUrl}/api/meetings/${requestId}/approve`,
        "put"
      );
    },
    discardMeeting(meetingId) {
      return initRequest(`${serverUrl}/api/meetings/${meetingId}`, "delete");
    },
  };

  const AdminApi = {
    getAllRoles() {
      return initRequest(`${serverUrl}/api/roles`, "get");
    },
    getAdminPavilions(search = "") {
      return initRequest(`${serverUrl}/api/pavilion?search=${search}`, "get");
    },
    getAdminExpanents(search) {
      return initRequest(
        `${serverUrl}/api/company?language=${lang}&size=${100}&searchParam=${search}`,
        "get"
      );
    },
    getAllUsers(search) {
      return initRequest(`${serverUrl}/users/all?search=${search}`, "get");
    },
    createCompany(data) {
      return initRequest(`${serverUrl}/api/company/create`, "post", "", data);
    },
    createPavilion(pavilion) {
      return initRequest(`${serverUrl}/api/pavilion`, "post", "", pavilion);
    },
    deleteCompany(companyName) {
      return initRequest(`${serverUrl}/api/company/${companyName}`, "delete");
    },
    getCompanyInfo(id) {
      return initRequest(`${serverUrl}/api/company/${id}/info`, "get");
    },
    updateCompany(data) {
      return initRequest(
        `${serverUrl}/api/company/update/${data.id}`,
        "put",
        "",
        data.body
      );
    },
  };

  const ExhibitorApi = {
    getCompanyMedia(companyId) {
      return initRequest(
        `${serverUrl}/api/company/media/${companyId}`,
        "get",
        ""
      );
    },
    addGalleryMedia(companyId, sendData, type) {
      return initRequest(
        `${serverUrl}/api/company/media/${companyId}/${type}`,
        "put",
        "",
        sendData
      );
    },
    deleteGalleryMedia(companyId, id, type) {
      return initRequest(
        `${serverUrl}/api/company/media/${companyId}/${type}/${id}`,
        "delete",
        ""
      );
    },
    updateCompanyText(companyId, data) {
      return initRequest(
        `${serverUrl}/api/company/${companyId}/text`,
        "put",
        "",
        data
      );
    },
    updateCompanyHeader(companyId, data) {
      return initRequest(
        `${serverUrl}/api/company/${companyId}/header`,
        "put",
        "",
        data
      );
    },
    changeVisibility(show, companyId) {
      return initRequest(
        `${serverUrl}/api/company/${companyId}/show?show=${show}`,
        "put"
      );
    },
    getRepresentatives(search = "") {
      return initRequest(
        `${serverUrl}/api/representatives/exhibitor?search=${search}`,
        "get"
      );
    },
    addRepresentatives(data) {
      return initRequest(
        `${serverUrl}/api/representatives/add-to-company?contactId=${data.id}&position=${
          data.job
        }&visibility=${data.visibility}&note=${data.description}`,
        "post"
      );
    },
    changeVisibleRepresentative(companyId, data) {
      return initRequest(
        `${serverUrl}/api/representatives/visibility?visibility=${data.visibility}&companyId=${companyId}&representativeId=${data.id}`,
        "post"
      );
    },
    deleteRepresentatives(data) {
      return initRequest(
        `${serverUrl}/api/representatives/remove-from-company?representativeId=${data.id}`,
        "delete"
      );
    },
    getCompanyDocuments(companyId) {
      return initRequest(
        `${serverUrl}/api/company/files?companyId=${companyId}`,
        "get"
      );
    },
    updateCompanyDocuments(fileId, show) {
      return initRequest(
        `${serverUrl}/api/company/files/${fileId}?show=${show}`,
        "put"
      );
    },
    deleteCompanyDocuments(fileId) {
      return initRequest(`${serverUrl}/api/company/files/${fileId}`, "delete");
    },
    uploadCompanyFile(companyId, type, data) {
      return initRequest(
        `${serverUrl}/files/api/company/file/upload?type=${type}&companyId=${companyId}`,
        "post",
        "",
        data
      );
    },
    getCompanyDepartments(companyId) {
      return initRequest(
        `${serverUrl}/api/company/${companyId}/departments`,
        "get"
      );
    },
    getContactsTypes() {
      return initRequest(`${serverUrl}/api/company/contact/types`, "get");
    },
    addNewDepartmentContact(companyId, departmentId, data) {
      return initRequest(
        `${serverUrl}/api/company/${companyId}/departments/${departmentId}/contact`,
        "post",
        "",
        data
      );
    },
    deleteDepartmentContact(companyId, departmentId, data) {
      return initRequest(
        `${serverUrl}/api/company/${companyId}/departments/${departmentId}/contact/${data.id}`,
        "delete"
      );
    },
    updateDepartmentContact(companyId, departmentId, data) {
      return initRequest(
        `${serverUrl}/api/company/${companyId}/departments/${departmentId}/contact/${data.id}?value=${data.value}`,
        "put"
      );
    },
  };

  const metaTagsApi = {
    getMetaTag(url) {
      return initRequest(
        `${serverUrl}/api/meta-tag?url=${url}`,
        "get",
        "noHeader"
      );
    },
    createMetaTag(data) {
      return initRequest(
        `${serverUrl}/api/meta-tag/${data.type}?url=${data.url}&type=WEBSITE&image=${data.image}&title=${data.title}&description=${data.description}`,
        data.type === 'create' ? "post" : "put" 
      );
    },
  };
  return {
    PavilionsApi,
    NewsApi,
    UserApi,
    QuestionsApi,
    AdminApi,
    ExhibitorApi,
    metaTagsApi,
  };
};
