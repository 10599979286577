import { Fragment } from "react"
import { useDispatch } from "react-redux"
import { SettingsUserContainer, SettingsUserWrap } from "./SettingsContent.style"


export const SettingsContent = ({setOpenSettings, SettingsItems, exhibitor}) => {
    const dispath = useDispatch()

    return(
    <>
        <SettingsUserWrap onClick={() => exhibitor ? dispath(setOpenSettings({key: -1, open: false})) :  setOpenSettings({ key: -1, open: false })}/>
        <SettingsUserContainer>
            {SettingsItems.map((items, index) => {
                return(
                    <Fragment key={index}>
                        {items.component}
                    </Fragment>
                )
            })}
        </SettingsUserContainer>
    </>
    )
}