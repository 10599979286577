import {createAsyncThunk} from "@reduxjs/toolkit";
import { getRequest } from "../../services/requests.services";
import { getContactsList } from "../profileSlice/profileAsyncThunk";
import downloadFile from "../../services/downloadFile.service";

export const getPreviewUrlFiles = createAsyncThunk(
  "appChat/getPreviewUrlFiles",
  async ({messages, dialogId}, thunkAPI) => {
    let mutate = false;
    let mutateMessages =  await Promise.all(messages.map(async mes => {
      let files = mes.files;
      if(files && files.length) {
        mutate = true;
        files = await Promise.all(files.map(async file => ({
            ...file,
            previewUrl: await downloadFile({
              type: "previewDialogFile",
              data: {
                fileId: file.fileId,
                name: file.fileName,
                dialogId,
            }
            })
          }))
        )
      }
      return {...mes, files}
    })); 

    return {mutateMessages, mutate}
  }
);

export const createDialog = createAsyncThunk(
  "appChat/createDialog",
  async ({body, navigate}, thunkAPI) => {
    let { UserApi } = getRequest();
    let newDialog = await UserApi.createDialog(body);
    navigate && navigate(`/account/chat/${newDialog.id}`);
    return newDialog;
  }
);

export const sendMessage = createAsyncThunk(
  "appChat/sendMessage",
  async (data, thunkAPI) => {
    let { UserApi } = getRequest();
    return await UserApi.sendMessage(data);
  }
);

export const openDialogById = createAsyncThunk(
  "appChat/openDialogById",
  async ({id, navigate}, thunkAPI) => {
    let { UserApi } = getRequest();
    navigate && navigate(`/account/chat/${id}`);
    let dialog = await UserApi.getDialogById(id);
    thunkAPI.dispatch(getPreviewUrlFiles(dialog))
    return dialog;
  }
);

export const getDialogs = createAsyncThunk(
  "appChat/getDialogs",
  async (data, thunkAPI) => {
    let { UserApi } = getRequest();
    return await UserApi.getDialogs();
  }
);

export const deleteDialog = createAsyncThunk(
  "appChat/deleteDialog",
  async (id, thunkAPI) => {
    let { UserApi } = getRequest();
    await UserApi.deleteDialog(id);
    thunkAPI.dispatch(getDialogs());
    return id;
  }
);

export const toggleBlockUser = createAsyncThunk(
  'appChat/toggleBlockUser',
  async(params, thunkAPI) => {
    const { UserApi } = getRequest();
    const response = await UserApi.toggleBlockUser(params);
    return response;
  }
);

export const toggleAddUser = createAsyncThunk(
  'appChat/toggleAddUser',
  async(params, thunkAPI) => {
    const { UserApi } = getRequest();
    const response = await UserApi.toggleAddUser(params);
    thunkAPI.dispatch(getContactsList(""));
    return response;
  }
);

const extraReducers = {
  [getPreviewUrlFiles.fulfilled]: (state, {payload}) => {
    if(payload.mutate) {
      state.openDialog.messages = payload.mutateMessages;
    }
  },

  [createDialog.fulfilled]: (state, {payload}) => {
    state.dialogs.unshift(payload);
    state.openDialog = payload;
  },

  [openDialogById.fulfilled]: (state, {payload}) => {
    state.openDialog = payload;
  },

  [getDialogs.fulfilled]: (state, {payload}) => {
    state.dialogs = payload
    // state.dialogs = payload.chats
    // state.totalUnread = payload.totalUnread
  },

  [deleteDialog.fulfilled]: (state, {payload}) => {
    state.dialogs = state.dialogs.filter(el => el.id !== payload);
  },

  [toggleBlockUser.fulfilled]: (state, action) => {
    // state.modal = initialState.modal;
  },

  [toggleAddUser.fulfilled]: (state, action) => {
    // state.modal = initialState.modal;
  },
  
};

export default extraReducers;