import React from "react";
import { BannerAreaBox, BannerSection, BannerText } from "./style/banner_css";
import { SearchBlock } from "../SearchBlock/SearchBlock";
import { useSelector } from "react-redux";
import BG1 from "../../../assets/Components/Routes/MainPage/Banner/images/HeadImage.jpg";
import WinterBG from '../../../assets/Components/Routes/MainPage/Banner/images/7.gif'
import { HeadIcons } from "./mainLogo.svg";
import { useOpenExhibition } from "../../../hooks/useOpenExhibition";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

//const BG1 = folder + "BGNewMain.jpg";

export const Banner = () => {

const {banner: localText} = useSelector((state) => state.app.appText);
const openExhibitions = useOpenExhibition();
  const {mainLogo, mainLogoMobile} = HeadIcons
  const {width} = useWindowDimensions()

  return (
    <BannerSection BG1={BG1} id="scrollMain">
      <BannerAreaBox >
                {width < 480 ? mainLogoMobile : mainLogo}
                <BannerText> 
                <h1>{localText.h1}</h1>
                <p dangerouslySetInnerHTML={{ __html: localText.subhead}}/>
                <button onClick={openExhibitions}>
                    {localText.visitExhibition}
                </button>
                </BannerText>
            </BannerAreaBox>
      {width > 710 && <SearchBlock />}
    </BannerSection>
  );
};
