import { useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { filesUrl } from "../../../../API/api";
import { svg_icons } from "../../../../assets/imgs/svg_icons";
import { setTokenApp } from "../../../../store/authSlice/authSlice";
import { resetProfileState } from "../../../../store/profileSlice/profileSlice";
import { socialIcons } from "../../../Footer/socialIcons.svg";
import { ScrollUserPanel, UserMenu, UserPanelWrap, WrapProfileInfo } from "../../UserPanel/style/userPanel_css";
import { LinePanel, SubNavMenu, LinksWrap } from "./AdminPanel.style";

export const AdminPanel = () => {

      const {
        userIcon, 
        userPanelIcon, 
        logoutIcon, 
        productsIcon, 
        supportIcon, 
        adminNewsIcon,
        triangleUp,
        triangleDown,
        settingsIcon,
        pavilionsIcon
    } = svg_icons

      let {bag} = socialIcons

      const {userData} = useSelector((state) => state.profile)
      const dispatch = useDispatch()
      const [currentLink, setCurrentLink] = useState({Index: '', open: false, type: null})

    const navLinks = [
    {
        to: "admin-panel", text: <>{userPanelIcon} Панель администратора</>,
    },
    {
        text: <><p>{userIcon} Пользователи</p> <p>{currentLink.open && currentLink.type === "users" ? triangleUp : triangleDown}</p></>,
        type: "users",
         subNav: [
            {to: 'all-users', text: <>Список пользователей</>},
            {to: 'users-roles', text: <>Роли</>},
            {to: 'users-documents', text: <>Документы</>}
         ],
    },
    {
        to: "admin-pavilions", text: <>{pavilionsIcon} Павильоны</>
    },
    {
        to: "admin-companies", text: <>{bag} Организации</>
    },
    {
        to: "admin-catalog", text: <>{productsIcon} Каталог</>
    },
    {
        to: "Technical-support", text: <>{supportIcon} Техническая поддержка</>
    },
    {
        text: <><p>{adminNewsIcon} Новости на сайте</p> <p>{currentLink.open && currentLink.type === "news" ? triangleUp : triangleDown}</p></>,
        type: "news",
        subNav: [
          {to: 'all-news', text: <>Все новости</>},
          {to: 'main-news', text: <>Главные новости </>},
          {to: 'drafts-news', text: <>Черновики</>}
       ],
    }
]
const navigate = useNavigate();
  const LogoutEvent = () => {
    dispatch(setTokenApp());
    dispatch(resetProfileState("userData"))
    navigate('/')
  }

    return (
        <UserPanelWrap style={{borderRight: '0.5px solid #D4D4D4'}}>
      <ScrollUserPanel style={{paddingTop: '28px'}}>
        <img className="user__avatar" src={filesUrl + userData.avatar} alt={"avatar"} />
        <WrapProfileInfo>
          <div className="info-user">
            <button onClick={LogoutEvent}>{logoutIcon}</button>
            <div className="user__name">
              <h3>{userData.firstName}{' '}{userData.lastName}</h3>
              <p>Администратор</p>
            </div>
            <button onClick={() => navigate("settings?tab=personalData")}>
              {settingsIcon}
            </button>
          </div>
          </WrapProfileInfo>
          <LinePanel/>
          <UserMenu>
          {navLinks.map((nav, index) => (
            <Fragment key={index}>
            {
            nav.hasOwnProperty('subNav') ?
            <LinksWrap style={currentLink.Index === index && currentLink.open ? {background: '#F5F5F5'} : {background: 'none'}} key={nav.to}>
            <div onClick={() => setCurrentLink({Index: index, open: !currentLink.open, type: nav.type})}>{nav.text}</div>
            <SubNavMenu style={currentLink.Index === index 
            && 
            currentLink.open ? 
            {visibility: 'visible', position: 'relative', top: 0} : 
            {position: "absolute", height: '0', top: '-20px', visibility: 'hidden', opacity: 0}}>
            {
              nav.subNav.map((link) => {
                return <li key={link.to}><NavLink to={link.to} className={`user-menu__subnav-link`}>
                    {link.text}
                </NavLink></li>
              })
          }
              </SubNavMenu>
              </LinksWrap>
              :
            <NavLink key={nav.to} to={nav.to} className={`user-menu__nav-link`}>
              {nav.text}
            </NavLink>
            }    
            </Fragment>    
            ))}
        </UserMenu>
          </ScrollUserPanel>
        </UserPanelWrap>
    )
}