import {createSlice} from '@reduxjs/toolkit';
import { handlerLocalStorage } from '../../services/handlerLocalStorage.service';

import extraReducers from './authExtraReducers';
import reducers from './authReducers';

const {get} = handlerLocalStorage();

export const initialState = {
  open: false,
  openAdditional: true,
  openRegistration: false,
  authData: null,
  token: get('token'),
  role: 'admin'

  // form: {
  //   initValues: {},
  //   values: {
  //     login: {
  //       phoneOrEmail: '',
  //       password: '',
  //     },
  //     registration: {
  //       firstName: '',
  //       lastName: '',
  //       phone: '',
  //       email: '',
  //       password: '',
  //       repeatPassword: '',
  //     },
  //     addFirstCatStep2: {
  //       maker: [""],
  //       model: [""],
  //       carConnectorTypes: [""],
  //       batteryCapacity: "",
  //     }
  //   }
  // }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers,
  extraReducers,
})

const authReducer = authSlice.reducer;

export const {setAuthState, setTokenApp} = authSlice.actions;

export default authReducer;