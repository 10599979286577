export const companiesPage_en = {
    about: "About the company",
    products: "Products",
    services: 'Services',
    contacts: 'Contacts',
    shortText: 'Brief information about the company',
    partners: "Partners",
    button: "For a more detailed acquaintance with the organization's products and communication with its specialists, we invite you to visit the current stand of the virtual exhibition",
    toStand: "to the stand",
    headerFiles: "Files",
    edit: 'Edit',
    addFileUserAcc: 'The file has been added to your personal account',
    repeatFileUserAcc: 'File with such name already exists in your account',
}