import styled from "styled-components"

const FeaturesSection = styled.section`
    -webkit-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
    margin: 83px 141px;
    @media(max-width: 769px){
        margin: 83px 48px;
    }
    @media(max-width: 590px){
        margin: 83px 16px;
    }
    .VirtualImg{
        width: 100%;
        opacity: 0.5;
    } 
`;

const Standsinfo = styled.div`
    display: flex;
    margin-bottom: 50px;
    @media(max-width: 1075px){
        flex-direction: column;
    }
    .stands_img_container{
        @media(max-width: 1075px){
            max-height: 495px;
        }
    }
    .stands_img{
        width: 100%;
        border-radius: 16px 0px 0px 16px;
        @media(max-width: 1075px){
            border-radius: 16px 16px 0px 0px;
        }
        object-fit: cover;
        object-position: center;
        height: 100%;
    }
`;

const StandsinfoText = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 0px 16px 16px 0px;
    padding-left: 56px;
    @media(max-width: 769px){
        padding-left: 16px;
    }
    @media(max-width: 1075px){
        width: 100%;
        border-radius: 0px 0px 16px 16px;
    }

    .stands_desc{
        max-width: 590px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #3F3F3F;
        padding: 30px 16px 30px 0px;
        opacity: 0.8;
    }
    .stands_head_text{
        @media(max-width: 1200px){
            font-size: 30px;
            padding-top: 16px;
        }
        @media(max-width: 769px){
            font-size: 26px;
        }
        @media(max-width: 680px){
            font-size: 22px;
        }
        max-width: 525px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        color: #3F3F3F;
        padding-bottom: 30px;
        padding-right: 16px;
    }
    strong{
        color: #34C57F;
    }
    div{
        content: "";
        width: 80px;
        height: 1px;
        background: #3F3F3F;
        opacity: 0.95;
        border-radius: 1px;
    }
`;

const FeatureStatistics = styled.div`
    display: flex;
    @media(max-width: 1075px){
        flex-direction: column-reverse;
    }
    .statistic_img_container{
        @media(max-width: 1075px){
            max-height: 495px;
    }
    }
    .statistic_img{
        width: 100%;
        border-radius: 0px 16px 16px 0px;
        @media(max-width: 1075px){
            border-radius: 16px 16px 0px 0px;
        }
        object-fit: cover;
        object-position: center;
        height: 100%;
    }
`;

const FeatureStatisticsText = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px 0px 0px 16px;
    padding-left: 56px;
    @media(max-width: 769px){
        padding-left: 16px;
    }
    @media(max-width: 1075px){
        width: 100%;
        border-radius: 0px 0px 16px 16px;
    }

    .statistic_desc{
        max-width: 590px;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #3F3F3F;
        padding: 30px 16px 30px 0px;
        opacity: 0.8;
    }
    .statistic_head_text{
        -webkit-transition: all 0.4s linear 0s;
        -o-transition: all 0.4s linear 0s;
        transition: all 0.4s linear 0s;
        @media(max-width: 1200px){
            font-size: 30px;
            padding-top: 16px;
        }
        @media(max-width: 769px){
            font-size: 26px;
        }
        max-width: 490px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        color: #3F3F3F;
        padding-bottom: 30px;
        padding-right: 16px;
    }
    strong{
        color: #34C57F;
    }
    div{
        content: "";
        width: 80px;
        height: 1px;
        background: #3F3F3F;
        opacity: 0.95;
        border-radius: 1px;
    }
`;

const FeaturesBtn = styled.button`
width: 166px;
height: 38px;
background: #34C57F;
border-radius: 12px;
color: #fff;
border: none;
margin-left: 104px;
cursor: pointer;
`;

const VirtualImage = styled.div`
background: #000000;
margin-bottom: -4px;
`;

export {FeaturesSection, Standsinfo, FeatureStatistics, StandsinfoText, FeatureStatisticsText, FeaturesBtn, VirtualImage}