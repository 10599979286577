import styled from "styled-components";

const AllUsersWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 16px 34px;
`;

const AllUsersContainer = styled.div`
width: 100%;
height: 100%;
border: 0.5px solid #D4D4D4;
border-radius: 4px;
margin-bottom: 17px;
padding-right: 4px;
padding-top: 4px;
.map_container{
    display: flex;
    flex-direction: column;
    height: inherit;
    gap: 12px;
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px 8px 16px 16px;
}
.onePart_block{
    display: flex;
}
.secondPart_block{
    display: flex;
    align-items: center;
}
`

const UserCard = styled.div`
    width: inherit;
    height: 66px;
    background: #F5F5F5;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        width: 42px;
        height: 42px;
        border-radius: 20px;
    }
    .settings_icon{
            width: 16px;
            height: 16px;
        }
    .user_dots{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: #EBEBEB;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }
`;

const UserData = styled.div`
    margin: 0 12px;
`;

const FullName = styled.div`
    display: flex;
    gap: 4px;
    margin-bottom: 6px;
    p{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
    }
`;

const UserAllInfo = styled.div`
    display: flex;
    gap: 4px;
    .user_role{
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        text-transform: capitalize;
        color: #484848;
}
    .user_id{
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        text-transform: capitalize;
        color: #7C7C7C;
}
`;

const UserRegDate = styled.div`
margin: 12px 0;
border-right: 0.5px solid #D4D4D4;
border-left: 0.5px solid #D4D4D4;
border-radius: 2px;
padding: 0 15px;
.text_date{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
}
.reg_date{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
}
`;

const UserCompanyInfo = styled.div`
    margin-left: 16px;
    .text_company{
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #363636;
    }
    .company_name{
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #363636;
    }
`;

const UserSettings= styled.div`
position: relative;
margin-left: 100px;
`;

const SettingsUserBtns = styled.button`
width: 100%;
height: 42px;
background: #F5F5F5;
border-radius: 12px;
cursor: pointer;
border: none;
font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 100%;
color: #363636;
display: flex;
align-items: center;
padding-left: 12px;
gap: 10px;
`

export {
    AllUsersWrap, 
    AllUsersContainer, 
    UserCard, 
    UserData,
    FullName,
    UserAllInfo,
    UserRegDate,
    UserCompanyInfo,
    UserSettings,
    SettingsUserBtns
}