import React from 'react'
import { Wrapper, DescriptionSection, 
         WrapperTitle, Headers, Content, ContentText } from './style/description_css';
import { DescBlocks } from './DescBlocks/DescBlocks';
import { useSelector } from 'react-redux';

export const Description = () => {
    const {description: localText} = useSelector((state) => state.app.appText);

    return (
        <>
            <DescriptionSection id="scrollAbout">
                <Wrapper>
                    <WrapperTitle>
                        <Headers>
                            <h2>{localText.h5}<br/> {localText.h6}</h2>
                        </Headers>
                        <Content>
                            <ContentText>
                                <p><strong>{localText.clevr}</strong> – {localText.text}</p>
                            </ContentText>
                        </Content>
                    </WrapperTitle>
                </Wrapper>
                <DescBlocks/>
            </DescriptionSection>
        </>
    )
}