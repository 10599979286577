import styled from "styled-components";

const SelectExhibitorsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
  h2{
    font-family: 'Helvetica';
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
}
`;

const SearchContent = styled.div`
    display: flex;
    padding: 16px 0;
    
    .filter-search {
        height: 34px;
        background: #f6f6f6;
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        order: 1;
        margin-left: auto;
        max-width: 606px;
        flex: 1;
        input {
            width: 100%;
            border-radius: 10px;
            background: #f6f6f6;
            border: none;
            outline: none;
            padding-left: 6px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
        }
    }
`;

const SelectDate = styled.select`
    min-width: 170px;
    height: 36px;
    background: #F6F6F6;
    border: none;
    border-radius: 10px;
    padding: 10px 12px;
    outline: none;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #484848;
option{
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}
`;


const ArrowBtn = styled.button`
    border: none;
    background-color: #EFEFEF;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    cursor: pointer;
`;

const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const SelectExhibitorsWrap = styled.div`
    border: 0.5px solid #D4D4D4;
    border-radius: 4px;
    position: relative;
    height: 100%;
    .map_container{
        display: flex;
        gap: 16px;
        padding: 16px 16px;
        flex-wrap: wrap;
        -webkit-align-content: flex-start;
        align-content: flex-start;
        position: absolute;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
    }
        .empty_arr{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 13px 0px 0 10px;
    }
`;

const SelectExhibitorsItem = styled.div`
    height: 255px;
    background: #F8F8F8;
    border-radius: 4px;
    width: calc(1/4 * 100% - (1 - 1/4)*16px);
    @media (max-width: 1500px) {
        width: calc(1/3 * 100% - (1 - 1/3)*16px);
    }
    @media (max-width: 1200px) {
        width: calc(1/2*100% - (1 - 1/2)*16px);
    }
    @media (max-width: 920px) {
        width: 100%;
    }
`;

const ExhibitorsImg = styled.div`
    min-width: 182px;
    height: 162px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo_container{
        width: 116px;
        height: 69px;
    }
    img{
        width: 100%;
        height: 100%;
    }
`;

const ExhibitorsInfo = styled.div`
    width: 100%;
    height: 40px;
h3{
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-transform: uppercase;
    color: #363636;
    max-width: 220px;
}
`;

const ExhibitorsMainBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;
    padding: 0 12px;
    .dots_settings{
        border: none;
        background-color: #EFEFEF;
        border-radius: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ExhibitorsDesc = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
        height: 58px;
    padding: 0 12px;
`;

const Line = styled.div`
    height: 0.5px;
    background: #D4D4D4;
`;

const Link3DStand = styled.div`
div{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #363636;
    text-decoration: none;
}
    display: flex;
    align-items: center;
    svg{
        path{
            stroke: #363636;
        }
    }
    p{
        font-weight: 500;
        font-size: 12px;
        line-height: 110%;
        color: #363636;
        padding: 9px;
    }
`;

const ExhibDate = styled.div`
    p{
        font-weight: 400;
        font-size: 12px;
        line-height: 110%;
        color: #363636;
    }
`;

export { SelectExhibitorsContainer, SearchContent, 
    SelectDate,  
    ArrowBtn,  
    SelectContainer, SelectExhibitorsWrap, 
    SelectExhibitorsItem, ExhibitorsImg,
    ExhibitorsInfo, ExhibitorsMainBlock,
    ExhibitorsDesc, Line, 
    Link3DStand, ExhibDate
}