import {ErrorMessage, Field, Form, Formik} from "formik";
import {useEffect} from "react";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {svg_icons} from "../../../../assets/imgs/svg_icons";
import {ChangePassword} from "../../../../store/profileSlice/profileAsyncThunk";
import {RestoreSchema} from "../../../../validation/validation";
import {
  CloseButton,
  Desc,
  ModalContainer,
  ModalOpen,
  RecoveryBtn,
  Title,
} from "../../../Registration/Login/style/Login_css";
import {RecoverPassword} from "../../../Registration/RecoveryPassword/RecoverPassword";
import {FieldWrap, RestorePasswordContainer} from "./Restore.style";

const restorePassData = {
  password: "",
  NewPassword: "",
  EqualPassword: "",
};

export const RestorePassword = ({setRestorePass}) => {
  const [TypePass, setTypePass] = useState("password");
  const [TypeNewPass, setTypeNewPass] = useState("password");
  const [TypeEqualPass, setTypeEqualPass] = useState("password");
  const [eyePass, setEyePass] = useState();
  const [eyeNewPass, setEyeNewPass] = useState();
  const [eyeEqualPass, setEyeEqualPass] = useState();
  const {eyeIcon, crossEyeIcon, exitIcon} = svg_icons;
  const dispatch = useDispatch();
  const [recover, setRecover] = useState(false);

  useEffect(() => {
    TypePass === "password" ? setEyePass(eyeIcon) : setEyePass(crossEyeIcon);
    TypeNewPass === "password"
      ? setEyeNewPass(eyeIcon)
      : setEyeNewPass(crossEyeIcon);
    TypeEqualPass === "password"
      ? setEyeEqualPass(eyeIcon)
      : setEyeEqualPass(crossEyeIcon);
  }, [TypeEqualPass, TypeNewPass, TypePass, crossEyeIcon, eyeIcon]);

  return recover ? (
    <RecoverPassword setRecover={setRecover} />
  ) : (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setRestorePass(false)}>
          {exitIcon}
        </CloseButton>
        <RestorePasswordContainer>
          <Title>Новый пароль</Title>
          <Desc style={{maxWidth: "255px"}}>
            Для изменения введите текущий пароль и добавьте новый
          </Desc>
          <Formik
            initialValues={restorePassData}
            validationSchema={RestoreSchema}
            onSubmit={(values) => {
              const SendValues = {
                oldPassword: values.password,
                newPassword: values.NewPassword,
              };
              dispatch(ChangePassword(SendValues));
              setRestorePass(false);
            }}
          >
            {({dirty, handleSubmit, isValid, values}) => (
              <Form>
                <FieldWrap>
                  <div className="RecoveryPassword">
                    <label htmlFor="password" required>
                      <p>Текущий пароль*</p>
                    </label>
                    <RecoveryBtn onClick={() => setRecover(true)}>
                      Забыли пароль?
                    </RecoveryBtn>
                  </div>
                  <div className="InputPassword">
                    <Field
                      name="password"
                      type={TypePass}
                      placeholder={"Введите пароль"}
                    />
                    <button
                      type="button"
                      className="ChangeType"
                      onClick={() =>
                        TypePass === "password"
                          ? setTypePass("text")
                          : setTypePass("password")
                      }
                    >
                      {eyePass}
                    </button>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <FieldWrap>
                  <label htmlFor="NewPassword" required>
                    Новый пароль*
                  </label>
                  <div className="InputPassword">
                    <Field
                      name="NewPassword"
                      type={TypeNewPass}
                      placeholder={"Введите пароль"}
                    />
                    <button
                      type="button"
                      className="ChangeType"
                      onClick={() =>
                        TypeNewPass === "password"
                          ? setTypeNewPass("text")
                          : setTypeNewPass("password")
                      }
                    >
                      {eyeNewPass}
                    </button>
                  </div>
                  <ErrorMessage
                    name="NewPassword"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <FieldWrap>
                  <label htmlFor="EqualPassword" required>
                    Повторите новый пароль*
                  </label>
                  <div className="InputPassword">
                    <Field
                      name="EqualPassword"
                      type={TypeEqualPass}
                      placeholder={"Введите пароль"}
                    />
                    <button
                      type="button"
                      className="ChangeType"
                      onClick={() =>
                        TypeEqualPass === "password"
                          ? setTypeEqualPass("text")
                          : setTypeEqualPass("password")
                      }
                    >
                      {eyeEqualPass}
                    </button>
                  </div>
                  <ErrorMessage
                    name="EqualPassword"
                    component="span"
                    className="field__error-mes"
                  />
                </FieldWrap>
                <div className="Btns_Menu_Wrap">
                  <button
                    className="BackBtn"
                    type="button"
                    onClick={() => setRestorePass(false)}
                  >
                    Отменить
                  </button>
                  <button
                    className="ChangeBtn"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                      !isValid ||
                      (values.password.length === 0 &&
                        values.NewPassword.length === 0 &&
                        values.EqualPassword.length === 0)
                    }
                    style={
                      !isValid ||
                      (values.password.length === 0 &&
                        values.NewPassword.length === 0 &&
                        values.EqualPassword.length === 0)
                        ? {cursor: "no-drop", opacity: "0.5"}
                        : null
                    }
                  >
                    Сохранить изменения
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </RestorePasswordContainer>
      </ModalOpen>
    </ModalContainer>
  );
};
