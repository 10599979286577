import React from "react";
import {
  WrapHeaderRightBox,
  WrapMobileHead,
  DialogUser,
  DialogTools,
} from "./headerRightBox.style";
// import phortUserTest from "../../imgs/Ellipse.png";
// import icons_svg from './../../imgs/icons_svg';
import { filesUrl } from "../../../../API/api";
import ContextMenuBtn from "../../../ContextMenuBtn/ContextMenuBtn";

const HeaderRightBox = ({
  tools,
  openDialog,
  mobileVersion,
  points,
  contextMenu,
  handlerActions,
  arrowBack,
  selectArrow,
  dialogs
}) => {
  return (
    <WrapHeaderRightBox>
      {mobileVersion && <WrapMobileHead>
        <button className="mobile-head__back" onClick={handlerActions("closeMobileChat")}>{arrowBack}</button>
        <p className="mobile-head__chats">Чаты {dialogs?.length ? `(${dialogs?.length || ""})` : ""}</p>
        <button className="mobile-head__roll-up" onClick={handlerActions("closeMobileChat", true)}>
          Свернуть
          <div className="roll-up__icon">{selectArrow}</div>
        </button>
      </WrapMobileHead>}
      <DialogUser>
        {openDialog.user.avatar  ? (
          <img src={`${filesUrl}${openDialog.user.avatar}`} alt="user_photo" />
        ) : (
          <div className="dialog-default-img">{openDialog.user.firstName[0]}</div>
        )}
        <div className="user-info">
          <p className="info__name">
            {openDialog.user.firstName} {openDialog.user.lastName}
            {/* <span className="user__status"></span> */}
          </p>
          {/* <p className="info__role">Главный инженер</p> */}
        </div>
      </DialogUser>

      <DialogTools>
        {mobileVersion ? (
          <button
            className="mobile-tools"
            onClick={handlerActions("contextTools")}
          >
            {points}
            {contextMenu === "toolsUser" && (
              <ContextMenuBtn
                data={{ dialog: openDialog }}
                onClose={handlerActions("closeContextTools")}
              />
            )}
          </button>
        ) : (
          tools.map((tool, i) => (
            <button
              key={i}
              className={`tools__base-tool${tool.class || ""}`}
              onClick={tool.onClick}
            >
              {tool.icon}
            </button>
          ))
        )}
      </DialogTools>
    </WrapHeaderRightBox>
  );
};

export default HeaderRightBox;
