import styled from "styled-components";

const PavilionsGalleryWrap = styled.div`
padding: 26px 16px 0;
position: relative;
height: 100%;
h3{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #363636;
}
.head_wrap{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    div{
        display: flex;
        gap: 8px;
        align-items: center;
    }
    button{
        width: 30px;
        height: 30px;
        background: #F5F5F5;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        svg{
            width: 18px;
            height: 18px;
            path{
                fill: #363636;
            }
        }
    }
}
.gallery_wrap{
    width: 100%;
    height: inherit;
    margin-top: 20px;
    position: relative;
    .map_container{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    gap: 16px;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    height: 80%;
    padding-right: 4px;
    }
}
.add_exponat_wrap{
    margin: 16px 0 0;
    width: 100%;
    button{
        width: 100%;
        height: 40px;
        background: #F5F5F5;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 11px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        svg{
            width: 18px;
            height: 18px;
            path{
                fill: #363636;
            }
        }
    }
}
`;

const GalleryCard = styled.div`
width: calc(1/5*100% - (1 - 1/5)*16px);
@media (max-width: 1200px) {
        width: calc(1/4*100% - (1 - 1/4)*16px);
}
@media (max-width: 920px) {
        width: calc(1/3*100% - (1 - 1/3)*16px);
}
@media (max-width: 660px) {
        width: calc(1/2*100% - (1 - 1/2)*16px);
    }
    @media (max-width: 440px) {
        width: 100%;
    }
height: 207px;
.media_wrap{
    display: flex;
    position: relative;
    .media_type{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 0px;
        top: 0px;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        .cube-3d{
            width: 18px;
            height: 18px;
            path{
                fill: #FFFFFF;
            }
        }
        .video-play-icon{
            path{
                fill:#FFFFFF;
                stroke: #FFFFFF;
            }
        }
    }
    .media_image{
    width: 100%;
    height: 151px;
    object-fit: cover;
    }
    .media_video{
        width: 100% !important;
        height: 151px !important;
        object-fit: cover;
    }
}
.media_info{
    padding: 0 16px;
    height: 56px;
    background: #F5F5F5;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text_info{
        .media_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
            padding-bottom: 2px;
        }
        .media_size{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #484848;
        }
    }
    .dots_settings{
        width: 30px;
        height: 30px;
        background: #EBEBEB;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
`;

export {
    PavilionsGalleryWrap,
    GalleryCard
}