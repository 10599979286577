import {useDispatch} from "react-redux";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {setDepartment} from "../../../../../store/exhibitorSlice/exhibitorSlice";
import {getName} from "../../../../../utils/helpers";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {SeoSettingsWrap} from "../../../Admin/Companies/CreateCompany/CreateCompany.style";
import {BtnsWrap} from "../../../Admin/Roles/AddNewRole/AddNewRole.style";
import {AddDepartmentPageWrap} from "./addDepartment.style";

export const AddDepartment = ({
  localeState,
  handlerActions,
  setOpenAddDepartment,
}) => {
  const {exitIcon} = svg_icons;
  const dispatch = useDispatch();

  const ValidForm =
    localeState.departments.nameRu &&
    localeState.departments.nameEn &&
    localeState.departments.nameBy;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setOpenAddDepartment(false)}>
          {exitIcon}
        </CloseButton>
        <AddDepartmentPageWrap>
          <Title>{"Новый отдел"}</Title>
          <SeoSettingsWrap>
            <div
              className="general_seo_data"
              style={{justifyContent: "space-between", width: "100%"}}
            >
              <p>{"Язык ввода:"}</p>
              <div style={{display: "flex", gap: "10px"}}>
                <button
                  className={`seo_language ${
                    localeState.language === "ru" ? "active" : ""
                  }`}
                  onClick={handlerActions("changeLang", "ru")}
                >
                  {"РУС"}
                </button>
                <button
                  className={`seo_language ${
                    localeState.language === "en" ? "active" : ""
                  }`}
                  onClick={handlerActions("changeLang", "en")}
                >
                  {"ENG"}
                </button>
                <button
                  className={`seo_language ${
                    localeState.language === "by" ? "active" : ""
                  }`}
                  onClick={handlerActions("changeLang", "by")}
                >
                  {"БЕЛ"}
                </button>
              </div>
            </div>
          </SeoSettingsWrap>
          <div className="name_department">
            <p className="name_text">{"Название"}</p>
            <input
              value={localeState.departments[getName(localeState.language)]}
              onChange={handlerActions("changeDepartmentName")}
              placeholder={"Введите название"}
            />
          </div>
          <BtnsWrap>
            <button
              style={{width: "100%", height: "36px"}}
              className="back"
              onClick={() => setOpenAddDepartment(false)}
            >
              {"Отменить"}
            </button>
            <button
              style={
                ValidForm
                  ? {width: "100%", height: "36px", opacity: "1"}
                  : {width: "100%", height: "36px", opacity: "0.5"}
              }
              className="send_btn"
              disabled={!ValidForm}
              onClick={() => {
                dispatch(setDepartment(localeState.departments));
                setOpenAddDepartment(false);
              }}
            >
              {"Сохранить"}
            </button>
          </BtnsWrap>
        </AddDepartmentPageWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
