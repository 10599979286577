import React from "react";
import { Fragment } from "react";
import { Contacts } from "../Contacts/Contacts";
import { MyFavorites } from "../MyFavorites/MyFavorites";
import { WrapLeftBlock } from "./panelUser.style";
import { useSelector } from "react-redux";
import StatisticsContainer from "../Statistics/StatisticsContainer";

export const PanelUserItem = () => {
  const { userData } = useSelector((state) => state.profile);

  return (
    <Fragment>
      <WrapLeftBlock>
        {userData.exhibitor ? <StatisticsContainer/> : ""}
        <MyFavorites />
      </WrapLeftBlock>
      <div
        className="divider-vert"
        style={{
          borderWidth: "0 0.5px 0 0",
          borderStyle: "solid",
          borderColor: "#D4D4D4",
          width: "0px",
          maxWidth: "0px",
        }}
      ></div>
      <Contacts />
    </Fragment>
  );
};
