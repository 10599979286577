import { initialState } from "./MetaTagsSlice"

export const reducers = {
    
    setMetaTagsState(state, {payload}) {
        payload.lang ?
        state[payload.key] = {...state[payload.key], [payload.type]: {
            ...state[payload.key][payload.type],
            [payload.lang]: payload.value 
        }}
        :
        state[payload.key] = {...state[payload.key], [payload.type]: payload.value}
    },
    resetMetaTagsState(state, {payload}){
        state[payload] = initialState[payload]
    }
}