import { header_ru } from "./components_text/header_ru";
import { authorization_ru } from "./components_text/authorization_ru";
import { modalAddCompany_ru } from "./components_text/modalAddCompany_ru";
import { banner_ru } from "./components_text/banner_ru";
import { description_ru } from "./components_text/description_ru";
import { manual_ru } from "./components_text/manual_ru";
import { media_ru } from "./components_text/media_ru";
import { mission_ru } from "./components_text/mission_ru";
import { news_ru } from "./components_text/news_ru";
import { partners_ru } from "./components_text/partners_ru";
import { follow_ru } from "./components_text/follow_ru";
import { contactus_ru } from "./components_text/contactus_ru";
import { seoModal_ru } from "./components_text/seoModal_ru";
import { footer_ru } from "./components_text/footer_ru";
import { companiesPage_ru } from "./components_text/companiesPage_ru";
import {
  pavilionPage_ru,
  pavilionsList_ru,
} from "./components_text/pavilion_ru";
import { editCompany_ru } from "./components_text/editCompany_ru";
import { filesDownload_ru } from "./components_text/filesDownload_ru";
import { activate_ru } from "./components_text/activate_ru";
import { changeMain_ru } from "./components_text/changeMain_ru";
import { tibo_ru } from "./components_text/tibo_ru";
import {
  errorPage_ru,
  errorsRequest_ru,
  errorsValid_ru,
} from "./components_text/errors_ru";
import { search_ru } from "./components_text/search_ru";
import { PavilionVDNX_ru } from "./components_text/pavilionVDNX_ru";
import { features_ru } from "./components_text/features_ru";
import { mobileApp_ru } from "./components_text/mobileApp_ru";
import { mainPage_ru } from "./components_text/mainPage_ru";
import { modals_ru } from "./components_text/modals_ru";
import { contextMenu_ru } from "./components_text/contextMenu_ru";
import { forms_ru } from './components_text/forms_ru';
import { profile_ru } from "./components_text/profile_ru";
import { baseWords_ru } from "./baseWords_ru";

export const ru = {
  language: "ru",
  header: header_ru,
  authorization: authorization_ru,
  modalAddCompany: modalAddCompany_ru,
  banner: banner_ru,
  description: description_ru,
  manual: manual_ru,
  media: media_ru,
  mission: mission_ru,
  news: news_ru,
  partners: partners_ru,
  follow: follow_ru,
  contactus: contactus_ru,
  seoModal: seoModal_ru,
  footer: footer_ru,
  companiesPage: companiesPage_ru,
  pavilionPage: pavilionPage_ru,
  pavilionsList: pavilionsList_ru,
  editCompany: editCompany_ru,
  filesDownload: filesDownload_ru,
  activate: activate_ru,
  changeMain: changeMain_ru,
  tibo: tibo_ru,
  errorPage: errorPage_ru,
  errorsRequest: errorsRequest_ru,
  errorsValid: errorsValid_ru,
  search: search_ru,
  VDNX: PavilionVDNX_ru,
  features: features_ru,
  mobileApp: mobileApp_ru,
  mainPage: mainPage_ru,
  modals: modals_ru,
  contextMenu: contextMenu_ru,
  forms: forms_ru,
  profile: profile_ru,
  baseWords: baseWords_ru
};
