import { GetCompanies, GetCompany, GetListOfProducts, GetPavilions } from "./pavilionsAsyncThunk"

export const extraReducers = (bilder) => {
    bilder.addCase(
    GetPavilions.fulfilled(), (state, {payload}) => {
        state.pavilions = payload
    },
    GetPavilions.rejected(), (state, {payload}) => {
        state.errors = payload
    }
    )
    bilder.addCase(
        GetCompanies.fulfilled(), (state, {payload}) => {
            state.companies = payload.companies
        },
        GetCompanies.rejected(), (state, {payload}) => {
            state.errors = payload
        }
    )
    bilder.addCase(
        GetCompany.fulfilled(), (state, {payload}) => {
            state.company = payload
            state.companyMedia = payload.media
            state.companyFiles = payload.files
            state.companyProductGroups = payload.productGroups
            state.companyPavilions = payload.pavilions
        },
        GetCompany.rejected(), (state, {payload}) => {
            state.errors = payload
        }
    )
    bilder.addCase(
        GetListOfProducts.fulfilled(), (state, {payload}) => {
            state.products = payload
        },
        GetListOfProducts.rejected(), (state, {payload}) => {
            state.errors = payload
        }
    )
}