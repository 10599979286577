import React from "react";
import { Outlet } from "react-router-dom";
import { WrapAppChat } from "./appChat.style";
import LeftBoxContainer from "./LeftBox/LeftBoxContainer";
import ModalsChat from "./ModalsChat/ModalsChat";
import AppToast from "./../AppToast/AppToast";
import RightBoxContainer from './RightBox/RightBoxContainer';

const AppChat = ({ mobileVersion }) => {
  return (
    <WrapAppChat mobile={mobileVersion}>
      <LeftBoxContainer mobileVersion={mobileVersion} />
      {mobileVersion ? (
        <RightBoxContainer mobileVersion={mobileVersion} />
      ) : (
        <Outlet />
      )}
      <AppToast />
    </WrapAppChat>
  );
};

export default AppChat;
