import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import icons_svg from "../imgs/icons_svg";
import { WrapNewDialog } from "./modalsChat.style";
import { filesUrl, serverUrl } from "../../../API/api";

export default function NewDialog({ hanlderActions }) {

  const { userId } = useSelector(
    (state) => state.appChat
  );
  let { closeModal, removeUserIcon, plusInCircle } = icons_svg;
  const [localState, setLocalState] = useState({
    "search-user": "",
    "first-message": "",
    userList: false,
    selectedUser: false,
  });

  const changeInputs = (e) =>
    setLocalState((state) => ({ ...state, [e.target.name]: e.target.value }));

  const toggleUser = (user) => () =>
    setLocalState((state) => ({ ...state, selectedUser: user }));

  const submit = (e) => {
    e.preventDefault();
    hanlderActions("createNewDialog", {
      userId: localState.selectedUser.id,
      text: localState["first-message"],
    })();
  };

  const buildInfoUser = (user) =>  ( user &&
      <div key={user.id} className="user__selected">
        {user.avatar ? (
          <img
            src={`${filesUrl}/files/unity_images/${user.avatar}`}
            alt="user_photo"
          />
        ) : (
          <div className="user__default-img">{user.firstName[0]}</div>
        )}
        <p>
          {user.firstName} {user.lastName}
        </p>
        <button
          className="select-base-icon-btn"
          onClick={toggleUser(localState.selectedUser ? false : user)}
        >
          {localState.selectedUser ? removeUserIcon : plusInCircle}
        </button>
      </div>
    );


  useEffect(() => {
    const searchUsers = async () => {
      try {
        const users = await (
          await fetch(
            `${serverUrl}/users/search?param=${localState["search-user"]}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
        ).json();

        setLocalState((state) => ({
          ...state,
          userList: Array.isArray(users) ? users : [],
        }));
      } catch (e) {
        setLocalState((state) => ({ ...state, userList: [] }));
      }
    };
    if (localState["search-user"]) {
      searchUsers();
    } else {
      setLocalState((state) => ({ ...state, userList: false }));
    }
  }, [localState["search-user"]]);

  return (
    <WrapNewDialog
      valueSelect={localState["search-user"]}
      openList={!localState.selectedUser && localState.userList}
    >
      <button
        className="new-dialog__close-btn"
        onClick={hanlderActions("closeModal")}
      >
        {closeModal}
      </button>
      <h3>Новый диалог</h3>

      <form onSubmit={submit}>
        <label htmlFor="user-list-label">Пользователь</label>

        <div className="dialog-select-user">
          <input
            className="base-input"
            type="text"
            name="search-user"
            id="user-list-label"
            value={localState["search-user"]}
            onChange={changeInputs}
            placeholder="Введите email или Имя пользователя"
          />

          {buildInfoUser(localState.selectedUser)}

          {!localState.selectedUser && localState.userList && (
            <div className="wrap-user-list">
              <div className="user-list">
                <div className="user-list-scroll">
                  {localState.userList.length ? (
                    localState.userList.map((user) => buildInfoUser(user))
                  ) : (
                    <p className="user__not-found">Пользователь не найден</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <label htmlFor="first-message">Сообщение</label>
        <div className="dialog-first-message">
          <p className="message__length">
            {localState["first-message"].length} из 350 символов
          </p>
          <textarea
            name="first-message"
            id="first-message"
            className="base-input"
            value={localState["first-message"]}
            onChange={changeInputs}
            maxLength={350} 
            placeholder="Введите сообщение"
          ></textarea>
        </div>

        <div className="base-btns">
          <button onClick={hanlderActions("closeModal")}>Отменить</button>
          <button type="submit">Начать диалог</button>
        </div>
      </form>
    </WrapNewDialog>
  );
}
