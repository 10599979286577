import { configureStore } from "@reduxjs/toolkit";
import { NewsReducer } from "./newsSlices/NewsSlices";
import { PavilionsReducer } from "./PavilionsSlice/pavillionsSlices";
import { ProfileReducer } from "./profileSlice/profileSlice";
import appReducer  from "./appSlice/appSlice";
import authReducer from "./authSlice/authSlice";
import appChatReducer from './appChatSlice/appChatSlice';
import { AdminReducer } from "./adminSlice/adminSlice";
import { exhibitorReducer } from "./exhibitorSlice/exhibitorSlice";
import { MetaTagsReducer } from "./MetaTagsSlice/MetaTagsSlice";


const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        news: NewsReducer,
        pavilions: PavilionsReducer,
        profile: ProfileReducer,
        appChat: appChatReducer,
        admin: AdminReducer,
        exhibitor: exhibitorReducer,
        metaTags: MetaTagsReducer
     },
     middleware: (getDefaultMiddleware) =>
       getDefaultMiddleware({
         serializableCheck: false,
       }),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store