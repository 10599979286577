import styled from "styled-components";

const AddNewPavilionWrap = styled.div`
    padding: 24px 16px 0;
    div{
        .head_text{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding-bottom: 5px;
        }
    }
    .pavilion_name{
        width: 100%;
        margin-top: 20px;
        input{
            width: 100%;
            height: 36px;
            background: #F5F5F5;
            border-radius: 4px;
            border: none;
            outline: none;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding-left: 12px;
        }
    }
    .add_expanents{
        width: 100%;
        margin-top: 25px;
        .add_exp_btn{
            width: 100%;
            height: 36px;
            background: #F5F5F5;
            border-radius: 4px;
            border: none;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            cursor: pointer;
        }
    }
    .description_wrap{
        width: 100%;
        margin-top: 25px;
    textarea{
        width: 100%;
        height: 108px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 9px 12px;
    }
    }
    .visibility_forUser{
        width: 100%;
        margin-top: 25px;
        .switcher-wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 340px;
            height: 36px;
            background: #F5F5F5;
            border-radius: 4px;
            padding: 0 12px;
            p{
            font-weight: 400;
            font-size: 13px;
            line-height: 110%;
            color: #363636;
        }
        }
    }
`;

const BtnsPavilionWrap = styled.div`
display: flex;
gap: 10px;
margin: 30px 0;
width: 100%;
.back{
    width: 100%;
    height: 32px;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: none;
    cursor: pointer;
}
.send_btn{
    width: 100%;
    background: #34C57F;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    height: 32px;
    cursor: pointer;
}
`;

export {
    AddNewPavilionWrap,
    BtnsPavilionWrap
}