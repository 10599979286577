import styled from "styled-components";

const AboutCompanyWrap = styled.div`
padding: 45px 16px 0;
h3{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #363636;
    padding-bottom: 23px;
}
.text_company{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 23px 0 5px;
}
textarea{
    background: #F5F5F5;
    border-radius: 4px;
    height: 200px;
    outline: none;
    border: none;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    width: 100%;
    resize: none;
    padding: 0 12px;
}
`;

export {
    AboutCompanyWrap
}