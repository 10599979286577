import { AboutCompany } from "../AboutCompany/AboutCompany";
import { ContactsPage } from "../ContactsPage/ContactsPage";
import { ExhibitorDocuments } from "../ExhibitorDocuments/ExhibitorDocuments";
import { HeadScreenExhibitor } from "../HeadScreenExhibitor/HeadScreenExhibitor";
import { MainExhibitorPage } from "../MainExhibitorPage/MainExhibitorPage";
import { PavilionsGallery } from "../PavilionsGallery/PavilionsGallery";
import { RepresentetivesPage } from "../RepresentetivesPage/RepresentetivesPage";

export const ExhibitorPageContent = ({content}) => {

    const BuildContent = (content) => {
        switch (content) {
            case 'main':
                return <MainExhibitorPage />
            case 'head-screen': 
                return <HeadScreenExhibitor/>
            case 'company-desc':
                return <AboutCompany/>
            case 'pavilions-gallery':
                return <PavilionsGallery />
            case 'representatives':
                return <RepresentetivesPage />
            case 'documents':
                return <ExhibitorDocuments />
            case 'contacts': 
                return <ContactsPage/>
            default:
                return <p className="New_Func">Функционал будет доступен в скором времени</p>
        }
    }

    return (
        <>{BuildContent(content)}</>
    )
}