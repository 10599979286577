import { Formik } from "formik"
import { Fragment } from "react"
import { useDispatch } from "react-redux"
import { svg_icons } from "../../../assets/imgs/svg_icons"
import { AddRequestsMeeting } from "../../../store/profileSlice/profileAsyncThunk"
import { RequestsModalSchema } from "../../../validation/validation"
import { CloseButton, ModalContainer, ModalOpen, RegInput, Title } from "../../Registration/Login/style/Login_css"
import { DateConfirence, DateStart, Message, RequestModalBtns, RequestModalWrap, TimeStartEnd, TopicContent } from "./RequestsModal.style"

const valuesData = {
    date: '',
    topic: '',
    message: '',
    startTime: '',
    endTime: '',
}

export const RequestModal = ({setOpenRequestsModal, companyId}) => {
    const {exitIcon} = svg_icons
    const dispatch = useDispatch()

    return (
    <ModalContainer>
        <ModalOpen maxWidth = {747}>
            <CloseButton onClick={() => setOpenRequestsModal(false)}>{exitIcon}</CloseButton>
            <RequestModalWrap>
                <Title>Заявка на встречу</Title>
                <Formik initialValues={valuesData} validationSchema={RequestsModalSchema} onSubmit={(values) => {
                const date = new Date(values.date).toISOString()
                const data = {
                    time: date,
                    description: values.message,
                    companyId: companyId,
                    theme: values.topic,
                    timeFrom: values.startTime,
                    timeTo: values.endTime,
                }
                dispatch(AddRequestsMeeting(data))
                setOpenRequestsModal(false)
                }}>
                {({errors, touched, values, handleChange, handleSubmit}) => 
            (
                <Fragment>
                <TopicContent>
                    <p>Тема*</p>
                    <RegInput name="topic" placeholder={'Введите текст...'} onChange={handleChange} />
                    {
                    (errors.topic && values.topic)
                    ||
                    (errors.topic && touched.topic) 
                     ? 
                    ( <p className="Invalid_Input">{errors.topic}</p> ) : 
                    null
                }
                </TopicContent>
                <DateConfirence>
                    <DateStart>
                    <p>Дата*</p>
                        <input name="date" placeholder="Выберите дату" type={'date'} onChange={handleChange} />
                        {
                    (errors.date && values.date)
                    ||
                    (errors.date && touched.date) 
                     ? 
                    ( <p className="Invalid_Input">{errors.date}</p> ) : 
                    null
                }
                    </DateStart>
                    <TimeStartEnd>
                        <div className="Start">
                        <p>Начало*</p>
                            <input name="startTime" type={'time'} onChange={handleChange} />
                            {
                    (errors.startTime && values.startTime)
                    ||
                    (errors.startTime && touched.startTime) 
                     ? 
                    ( <p className="Invalid_Input">{errors.startTime}</p> ) : 
                    null
                }
                        </div>
                        <div className="Line"></div>
                       
                        <div className="End">
                        <p>Окончание*</p>
                            <input name="endTime" type={'time'}  onChange={handleChange} />
                            {
                    (errors.endTime && values.endTime)
                    ||
                    (errors.endTime && touched.endTime) 
                     ? 
                    ( <p className="Invalid_Input">{errors.endTime}</p> ) : 
                    null
                }
                        </div>
                    </TimeStartEnd>
                </DateConfirence>
                <Message>
                <p>Сообщение</p>
                <textarea></textarea>
                </Message>
                <RequestModalBtns>
                    <button className="Exit" onClick={() => setOpenRequestsModal(false)}>Отменить</button>
                    <button type="submit" className="Send_Request" onClick={handleSubmit}>Отправить заявку</button>
                </RequestModalBtns>
                </Fragment>
            )}
                </Formik>
            </RequestModalWrap> 
        </ModalOpen>
    </ModalContainer>)
}