export const pavilionPage_by = {
    pavilion: 'Павільён',
    descriptonPavilion: `XXV Беларускі энергетычны і экалагічны форум, выстава і кангрэс "Энергетыка. Экалогія. Энергазберажэнне. Электра."
    Мерапрыемства праводзіцца 12-15 кастрычніка 2021 г. па адрасе г. Мінск, пр. Пераможцаў 20/2, Футбольны манеж`,
    linkStand: 'Стэнд',
    linkPage: 'Старонка',
    linkPrev: 'Папярэдні павільён',
    linkNext: 'Наступны павільён',
    linkPavilions: 'Назад да выбару павільёна',
}

export const pavilionsList_by = {
    pavilions: 'Павільёны',
    description: 'Прадпрыемствы-экспаненты падзелены па павільёнах, якія адпавядаюць тэматыцы іх дзейнасці. Адзін экспанент можа выстаўляцца некалькімі стэндамі і, у залежнасці ад прадстаўленых на стэндах матэрыялаў, быць размешчаны ў некалькіх павільёнах. Таксама, спецыялізаваныя павільёны могуць быць створаны для правядзення мерапрыемстваў, выставак і канферэнцый.',
}