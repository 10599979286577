import { SwitchWrap } from "./appSwitch.style"


export const AppSwitch = ({onChange, name, checked}) => {

    return (
        <SwitchWrap>
            <label className="switch">
                    <input name={name} type={'checkbox'} onChange={onChange} checked={checked === undefined ? false : checked}/>
                    <span className="slider round"></span>
                </label>
        </SwitchWrap>
    )
}