import { useDispatch, useSelector } from "react-redux"
import { setExhibitorContent } from "../../../../store/exhibitorSlice/exhibitorSlice"
import { ScrollBarWrap } from "../../Admin/AdminPanelContent/AdminPanelContent.style"
import { Desc, MainExhibitorPageWrap, Title } from "./mainExhibitorPage.style"

const totalData = [
    {
        title: {
            ru: 'Главный экран',
            en: 'Main screen',
            by: 'Галоўны экран'
        },
        text: {
            ru: 'Логотип, обложка и наименование предприятия',
            en: 'Logo, cover and company name',
            by: 'Лагатып, вокладка і найменне прадпрыемства',
        },
        content: 'head-screen'
    },
    {
        title: {
            ru: 'О компании',
            en: 'About company',
            by: 'Аб кампаніі'
        },
        text: {
            ru: 'Краткое описание о вашем предприятиии',
            en: 'Brief description about your business',
            by: 'Кароткае апісанне аб вашым прадпрыемстве.'
        },
        content: 'company-desc'
    },
    {
        title: {
            ru:'Представители',
            en: 'Representatives',
            by: 'Прадстаўнікі'
        },
        text: {
            ru:'Добавляйте и редактируйте представителей вашего предприятия',
            en: 'Add and edit representatives of your enterprise',
            by: 'Дадавайце і рэдагуйце прадстаўнікоў вашага прадпрыемства.'
        },
        content: 'representatives'
    },
    {
        title: { 
            ru: 'Галерея павильона',
            en: 'Gallery',
            by: 'Галерэя павільёна'
        },
        text: { 
            ru: 'Добавляйте и редактируйте галерею вашего предприятия',
            en: 'Add and edit your company gallery',
            by: 'Дадавайце і рэдагуйце галерэю вашага прадпрыемства.'
        },
        content: 'pavilions-gallery'
    },
    {
        title: { 
            ru : 'Документы',
            en: 'Documents',
            by: 'Дакументы'
        },
        text: { 
            ru: 'Добавляйте и редактируйте документы',
            en: 'Add and edit documents',
            by: 'Дадавайце і рэдагуйце дакументы.'
        },
        content: 'documents'
    },
    {
        title: {
            ru: 'Контакты',
            en: 'Contacts',
            by: 'Кантакты'
        },
        text: { 
            ru: 'Добавляйте и редактируйте контакты',
            en: 'Add and edit contacts',
            by: 'Дадавайце і рэдагуйце кантакты.'
        },
        content: 'contacts'
    },
]

export const MainExhibitorPage = () => {
    const dispatch = useDispatch()
    const {language, profile: {exhibitorPage}} = useSelector((state) => state.app.appText)

    return (
        <MainExhibitorPageWrap>
        <ScrollBarWrap>
            <div className="map-container">
                <div className="main_content">
                    <Title>{exhibitorPage.h3}</Title>
                    <Desc>{exhibitorPage.descPart1} <br/>{exhibitorPage.descPart2}{' '}<strong>{exhibitorPage.descPart3}</strong></Desc>
                </div>
                {totalData.map((item, index) => {
                    return (
                        <div className="other_content" key={index}>
                            <div>
                                <Title>{item.title[language]}</Title>
                                <Desc>{item.text[language]}</Desc>
                            </div>
                            <button onClick={() => dispatch(setExhibitorContent(item.content))}>{'Редактировать'}</button>
                        </div>
                    )
                })}
            </div>
        </ScrollBarWrap>
        </MainExhibitorPageWrap>
    )
}