import {useSelector} from "react-redux";
import {filesUrl} from "../../../../../API/api";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import AppSelect from "../../../../../components/AppSelect/AppSelect";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {
  AboutUser,
  CompanyUser,
  ControlBtns,
  ModalHandlerUserWrap,
  NoteContent,
  UserFullName,
  UserInfo,
  UserRole,
} from "./ModalHandlerUser.style";
import {dateToLocaleDateString} from "../../../../../utils/helpers";
import {useEffect} from "react";

export const ModalHandlerUser = ({
  setOpenModals,
  handlerActions,
  localeState,
  showRoles,
}) => {
  const {expanents} = useSelector((state) => state.admin);
  const {appText} = useSelector((state) => state.app);
  const {exitIcon, triangleUp, triangleDown} = svg_icons;

  useEffect(() => {});

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setOpenModals({key: "", open: false})}>
          {exitIcon}
        </CloseButton>
        <ModalHandlerUserWrap>
          <Title>{showRoles(localeState.user.role)}</Title>
          <UserInfo>
            <img src={filesUrl + localeState.user.avatar} alt="user_avatar" />
            <UserFullName>
              <p>{localeState.user.lastName}</p>
              <p>{localeState.user.firstName}</p>
              <p>{localeState.user.patronymic}</p>
            </UserFullName>
            <p className="role_text">{showRoles(localeState.user.role)}</p>
            <AboutUser>
              <div className="date_reg">
                <p className="head_text">{"Дата регистрации:"}</p>
                <p className="user_about">
                  {dateToLocaleDateString(localeState.user.createTime)}
                </p>
              </div>
              <div className="email_user">
                <p className="head_text">{"Email:"}</p>
                <p className="user_about">
                  {localeState.user.email ? localeState.user.email : "-"}
                </p>
              </div>
              <div className="phone_user">
                <p className="head_text">{"Телефон:"}</p>
                <p className="user_about">
                  {localeState.user.phone ? localeState.user.phone : "-"}
                </p>
              </div>
            </AboutUser>
            <UserRole>
              <p className="role_head_text">{"Роль"}</p>
              <AppSelect
                settings={localeState.rolesSettings}
                onChange={(e) => console.log(e)}
              />
            </UserRole>
            <CompanyUser>
              <p className="expanents_head_text">{"Организация"}</p>
              <div className="expanent_select">
                <input
                  value={localeState.searchCompany}
                  onClick={handlerActions("setFocus")}
                  onChange={handlerActions("setSearchCompany")}
                  placeholder="Выберите организацию"
                />
                {localeState.focus ? triangleUp : triangleDown}
              </div>
              {localeState.focus ? (
                <div
                  className="expanents_container"
                  onBlur={handlerActions("setFocus")}
                >
                  {expanents.map((expanent, i) => {
                    return (
                      <div
                        className="expanent_card"
                        key={expanent.id}
                        onClick={handlerActions("setCompanySelect", expanent)}
                      >
                        <p
                          onClick={handlerActions("setCompanySelect", expanent)}
                        >
                          {expanent.name[appText.language]}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </CompanyUser>
            <NoteContent>
              <p>{"Примечание"}</p>
              <textarea />
            </NoteContent>
            <ControlBtns>
              <button
                className="Back"
                onClick={() => setOpenModals({key: "", open: false})}
              >
                {"Отменить"}
              </button>
              <button className="Send">{"Сохранить"}</button>
            </ControlBtns>
          </UserInfo>
        </ModalHandlerUserWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
