import {useEffect} from "react";
import {useState} from "react";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {
  CloseButton,
  Desc,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {ModalNewsWrap} from "./ModalNews.style";

export const ModalNews = ({handlerActions, contentParam, openModal}) => {
  const {exitIcon} = svg_icons;
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [btnText, setBtnText] = useState("");

  useEffect(() => {
    switch (contentParam) {
      case "all-news":
        switch (openModal.type) {
          case "deleteNews":
            setTitle("Удалить новость?");
            setText(
              "Новость будет удалена из новостей. Желаете выполнить это действие?"
            );
            setBtnText("Да, удалить");
            break;
          case "hideNews":
            openModal.news.isShow
              ? setTitle("Не показывать на сайте?")
              : setTitle("Отображать новость на сайте?");
            openModal.news.isShow
              ? setText(
                  "Новость будет скрыта от просмотра на сайте. Желаете скрыть эту новость?"
                )
              : setText(
                  "Новость будет доступна для просмотра на сайте. Желаете отобразить эту новость?"
                );
            openModal.news.isShow
              ? setBtnText("Да, скрыть")
              : setBtnText("Да, отобразить");
            break;
          case "makeMainNews":
            openModal.news.mainNews
              ? setTitle("Хотите убрать новость из главных?")
              : setTitle("Хотите сделать новость главной?");
            openModal.news.mainNews
              ? setText(
                  "Новость будет удалена из главных новостей. Желаете убрать эту новость из главных?"
                )
              : setText(
                  "Новость будет добавлена к главным новостям. Желаете добавить эту новость к главным?"
                );
            openModal.news.mainNews
              ? setBtnText("Да, убрать")
              : setBtnText("Да, добавить");
            break;
          default:
            break;
        }
        break;
      case "drafts-news":
        switch (openModal.type) {
          case "deleteNews":
            setTitle("Удалить черновик?");
            setText(
              "Черновик будет удален из новостей. Желаете выполнить это действие?"
            );
            setBtnText("Да, удалить");
            break;
          case "hideNews":
            openModal.news.isShow
              ? setTitle("Не показывать на сайте?")
              : setTitle("Отображать новость на сайте?");
            openModal.news.isShow
              ? setText(
                  "Новость будет скрыта от просмотра на сайте. Желаете скрыть эту новость?"
                )
              : setText(
                  "Черновик будет доступен для просмотра на сайте. Желаете отобразить этот черновик?"
                );
            openModal.news.isShow
              ? setBtnText("Да, скрыть")
              : setBtnText("Да, отобразить");
            break;
          default:
            break;
        }
        break;
      case "main-news":
        switch (openModal.type) {
          case "makeMainNews":
            openModal.news.mainNews
              ? setTitle("Хотите убрать новость из главных?")
              : setTitle("Хотите сделать новость главной?");
            openModal.news.mainNews
              ? setText(
                  "Новость будет удалена из главных новостей. Желаете убрать эту новость из главных?"
                )
              : setText(
                  "Новость будет добавлена к главным новостям. Желаете добавить эту новость к главным?"
                );
            openModal.news.mainNews
              ? setBtnText("Да, убрать")
              : setBtnText("Да, добавить");
            break;
          case "hideNews":
            openModal.news.isShow
              ? setTitle("Не показывать на сайте?")
              : setTitle("Отображать новость на сайте?");
            openModal.news.isShow
              ? setText(
                  "Новость будет скрыта от просмотра на сайте. Желаете скрыть эту новость?"
                )
              : setText(
                  "Черновик будет доступен для просмотра на сайте. Желаете отобразить этот черновик?"
                );
            openModal.news.isShow
              ? setBtnText("Да, скрыть")
              : setBtnText("Да, отобразить");
            break;
          case "deleteNews":
            setTitle("Удалить новость?");
            setText(
              "Новость будет удалена из новостей. Желаете выполнить это действие?"
            );
            setBtnText("Да, удалить");
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }, [
    contentParam,
    openModal.type,
    openModal.news.isShow,
    openModal.news.mainNews,
  ]);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton
          onClick={handlerActions("setModal", {
            type: "",
            open: false,
            news: "",
          })}
        >
          {exitIcon}
        </CloseButton>
        <ModalNewsWrap>
          <Title>{title}</Title>
          <Desc style={{paddingTop: "18px"}} width={332}>
            {text}
          </Desc>
          <div className="controls_btn">
            <button
              className="back"
              onClick={handlerActions("setModal", {
                type: "",
                open: false,
                news: "",
              })}
            >
              {"Нет, отменить"}
            </button>
            <button
              className="save"
              onClick={handlerActions(openModal.type, openModal.news)}
            >
              {btnText}
            </button>
          </div>
        </ModalNewsWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
