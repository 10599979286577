import styled from "styled-components";

const ErrorHandlerWrap = styled.div`
    position: absolute;
    width: 365px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 6px 48px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: 2;
    right: 25px;
    top: 65px;
    .info_wrap{
        display: flex;
        align-items: center;
        height: inherit;
        margin-left: 16px;
        gap: 12px;
        img{
            width: 35px;
        }
        p{
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: #ff00008a;
            width: 75%;
        }
    }
`;

export {
    ErrorHandlerWrap
}