import { initialState } from "./NewsSlices"

export const reducers = {
    setNews: (state, action) => {
        state.news = action.payload
    },
    setFilteredNews: (state, action) => {
        state.filterNews =  action.payload
    }, 
    setInfoNewNews(state, {payload}){
        if(!payload.language){
        state.createNews[payload.type] = payload.value
        }else{
        state.createNews[payload.type][payload.language] = payload.value
        }
    },
    setText(state, {payload}){
        state.createNews.text[payload.language] = payload.value
    },
    setMediaNewNews(state, {payload}){
        state.createNews.media.push({src: URL.createObjectURL(payload), file: payload, preview: false}) 
    },
    setNewsAuthor(state, {payload}){
        state.createNews.newsAuthor = payload
    },
    deleteMedia(state, {payload}){
        state.createNews.media = state.createNews.media.filter((item, index) => index !== payload)
    },
    setPreviewMedia(state, {payload}){
        state.createNews.media = state.createNews.media.map((item, index) => 
        index === payload.index ? ({...item, preview: payload.preview }) : item
        )
    },
    setEditNews(state, {payload}){
        state.createNews = payload
    },
    resetNewsState(state, {payload}){
        state[payload] = initialState[payload]
    },
}