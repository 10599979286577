import styled from "styled-components";


const DescBlock = styled.div`
    display: flex;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin: 0 32px;
`;

const DescItem = styled.div`
    width: 315px;
    height: 177px;
    background: #FFFFFF;
    padding: 0 16px;
    div{
    content: "";
    width: 100%;
    height: 0.5px;
    background: #363636;
    opacity: 0.5;
    border-radius: 10px;
}
`;

const DescItemHeader = styled.p`
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 41px;
    display: flex;
    align-items: center;
    color: #363636;
    padding-top: 12px;
    padding-bottom: 13px;
    strong{
    color: #34C57F;
    padding-right: 5px;
    font-size: 36px;
    }
`;

const DescItemText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #484848;
    padding-top: 13px;
    padding-bottom: 13px;
`;

const DescItemBtn = styled.button`
    cursor: pointer;
    color: #34C57F;
    width: 163px;
    height: 34px;
    border: 1.4px solid #34C57F;
    border-radius: 8px;
    background: #fff;
    :hover{
        background: #34C57F;
        color: #fff;
        transition: 0.1s ease-out 0.3s;
    }
`;

export { DescBlock, DescItem, DescItemHeader, DescItemText, DescItemBtn }