import { useSelector } from "react-redux"
import { svg_icons } from "../../../../assets/imgs/svg_icons"
import { AdminHead } from "../AdminHead/AdminHead"
import { ScrollBarWrap } from "../AdminPanelContent/AdminPanelContent.style"
import { ModalMessage } from "../MessageContent"
import { SearchSortContent } from "../SearchSortContent/SearchSortContent"
import { AddNewPavilionContainer } from "./AddNewPavilion/AddNewPavilionContainer"
import { AdminPavilionsContainer, AdminPavilionsWrap, DateReg, PavilionCard, PavilionsBtnsWrap } from "./adminPavilions.style"

export const AdminPavilions = ({localeState, handlerActions}) => {
    const {pavilions} = useSelector((state) => state.admin)
    const localText = useSelector((state) => state.app.appText);
    const {addIcon, settingsIcon, trashIcon, expandIcon} = svg_icons

    return (
        <AdminPavilionsWrap>
            <div className="head_info_wrap">
                <AdminHead 
                title={`Павильоны (${pavilions.length})`} 
                desc={'Здесь вы можете добавить и отредактировать информацию о павильоне'} />
                <button onClick={handlerActions('setOpenPavilion')} className="Add_Pavilions">{addIcon} Добавить павильон</button>
            </div>

            <SearchSortContent page={'admin-pavilions'} />

            <AdminPavilionsContainer>
                <ScrollBarWrap>
                    <div className="map-container">
                        {[...pavilions].sort((a , b) => a.order - b.order).map((pavilion, index) => {
                            return (
                                <PavilionCard 
                                key={index} 
                                draggable={true}
                                onDragStart={handlerActions('setDraggable', {typeEv: 'start', pavilion})}
                                onDragLeave={handlerActions('setDraggable', {typeEv: 'leave'})}
                                onDragEnd={handlerActions('setDraggable', {typeEv: 'end'})}
                                onDragOver={handlerActions('setDraggable', {typeEv: 'over'})}
                                onDrop={handlerActions('setDraggable', {typeEv: 'drop', pavilion})}
                                >
                                <div className="admin_pavilion_info_wrap">
                                    <p className="admin_pavilion_name">{pavilion[localText.language]}</p>
                                    <DateReg>
                                        <p className="admin_pavilion_date_text">{'Дата регистрации:'}</p>
                                        <p className="admin_pavilion_date">{'19 апреля 2022'}</p>
                                    </DateReg>
                                    </div>
                                    <PavilionsBtnsWrap>
                                    <button>{expandIcon}</button>
                                    <button>{settingsIcon}</button>
                                    <button className="btn_basket">{trashIcon}</button>
                                    </PavilionsBtnsWrap>
                                </PavilionCard>
                            )
                        })}
                    </div>
                    {localeState.openPavilion ? <AddNewPavilionContainer localeState={localeState} handlerActions={handlerActions} /> : null}
                </ScrollBarWrap>
            </AdminPavilionsContainer>
            <ModalMessage />
        </AdminPavilionsWrap>
    )
}