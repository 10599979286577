import { svg_icons } from "../../../../assets/imgs/svg_icons"
import AppSelect from "../../../../components/AppSelect/AppSelect"
import { icons_svg } from "../../../Exhibition/Company/imgs/icons.svg"
import { SearchBlockContent, SearchFormWrap } from "../AdminPanelContent/AdminPanelContent.style"



export const SearchSortContent = ({page, inputChange, dateSort}) => {

    const {reverseArrow} = svg_icons
    const {search} = icons_svg



    const initOptions = (key) => {
        switch (key) {
          case "admin-panel":
            return {
              appSelect1: { options: [{ id: 0, value: "Сегодня" }] },
              appSelect2: {
                options: [{ id: 1, value: "По дате регистрации" }],
              },
            };
          case "all-users":
            return {
              appSelect1: { options: [{ id: 0, value: "Все пользователи" }] },
              appSelect2: {
                options: [{ id: 1, value: "По дате регистрации" }],
              },
            };
          case "users-roles":
            return {
              appSelect1: { options: [{ id: 0, value: "Все пользователи" }] },
              appSelect2: {
                options: [{ id: 1, value: "По дате регистрации" }],
              },
            };
          case "admin-companies":
            return {
              appSelect1: {
                options: [{ id: 0, value: "Все группы продукций" }],
              },
              appSelect2: {
                options: [{ id: 1, value: "По дате регистрации" }],
              },
            };
          case "admin-pavilions":
            return {
              appSelect1: { options: [{ id: 0, value: "Все павильоны" }] },
              appSelect2: { options: [{ id: 1, value: "По дате добавления" }] },
            };
          case "admin-catalog":
            return {
              appSelect1: { options: [{ id: 0, value: "Все классы" }] },
              appSelect2: {
                options: [{ id: 1, value: "По дате регистрации" }],
              },
            };
          case "all-news":
            return {
              appSelect1: { options: [{ id: 0, value: "Все новости" }] },
              appSelect2: {
                options: [{ id: 1, value: "По дате публикации" }],
              },
            };
          case "drafts-news":
            return {
              appSelect2: {
                options: [{ id: 1, value: "По дате публикации" }],
              },
            };
          case "main-news":
            return {
              appSelect2: {
                options: [{ id: 1, value: "По дате публикации" }],
              },
            };
          default:
            return {
              appSelect1: { options: [{ id: 0, value: "Сегодня" }] },
              appSelect2: {
                options: [{ id: 1, value: "По дате регистрации" }],
              },
            };
        }
    }

    return (
        <SearchFormWrap>
                <h2>Поиск и сортировка</h2>
                <SearchBlockContent>
                    <AppSelect settings={initOptions(page).appSelect1}/>
                    <div className="filter_search">
                    {search}
                    <input className="search_info" placeholder={'Начните вводить'} onChange={inputChange}/>
                    </div>
                    {dateSort === 'none' ? null : <AppSelect settings={initOptions(page).appSelect2}/>}
                    {dateSort === 'none' ? null : <button className="btn_reverse_info">{reverseArrow}</button>}
                    <button className="choose_default">По умолчанию</button>
                </SearchBlockContent>
            </SearchFormWrap>
    )
}