import styled from "styled-components";

const RecoverWrap = styled.div`
    padding: 0 16px;
    margin-top: 24px;
    .field__error-mes{
        margin-bottom: 25px;
        p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
    }
    .Recover_Btn{
        display: flex;
        flex-direction: column;
    }
    .RecoveryEmail{
        padding-bottom: 5px;
    }
    .email{
            input{
                width: 100%;
                font-family: 'Gilroy';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #363636;
            }
    }
    .field__error-mes{
        position: relative;
        bottom: 12px;
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
        margin-bottom: 0px;
    }
`;

const RecText = styled.p`
    width: 338px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #484848;
    text-align: center;
    margin: 25px 0;
`;

const GoBack = styled.button`
    height: 36px;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: none;
    cursor: pointer;
    margin-bottom: 8px;
`;

const SendEmail = styled.button`
    height: 36px;
    background: #34C57F;
    border-radius: 8px;
    color: white;
    border: none;
    cursor: pointer;
    margin-bottom: 36px;
`;

export {RecoverWrap, RecText, GoBack, SendEmail }