import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { StyledFooter, FooterWidgets, FooterWidgetsColumn, 
    StyledCopyright, VirtualExhibitionColumn, FollowColumn, 
    InfoColumn, MobileAppColoumn, HeadColumns, LinksColumns,
    MobileInstallBtn, BottomLine, BlockCopyright, AdditInfoBlock, AdditContent, SocialBlock } from './style/footer_css'
import { socialIcons } from './socialIcons.svg'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { TechSupport } from '../TechSupport/TechSupport'
import { ModalMessage } from '../ModalMessage/ModalMessage'
import useWindowDimensions from '../../hooks/useWindowDimensions'

export const Footer = ({footerRef}) => {

    const {mainPage: {footer: localText}} = useSelector((state) => state.app.appText);
    const [TechSupportActive, setTechSupportActive] = useState(false)
    const {width} = useWindowDimensions()

    const {
        linkedIn, instagram, 
        tiktok, facebook, 
        youtube, email, logoFooter
    } = socialIcons


    return (
        <StyledFooter ref={footerRef}>
        {!TechSupportActive ? null : <TechSupport setTechSupportActive={setTechSupportActive}/>}
            <FooterWidgets>
                <FooterWidgetsColumn>
                {width > 580 && <Fragment>
                    {logoFooter}
                    <VirtualExhibitionColumn>
                        <HeadColumns>{localText.news.head}</HeadColumns>
                        <Link to={'/news'}><LinksColumns>{localText.news.link1}</LinksColumns></Link>
                        <Link to={'/news'}><LinksColumns>{localText.news.link2}</LinksColumns></Link>
                    </VirtualExhibitionColumn>
                    <VirtualExhibitionColumn>
                        <HeadColumns>{localText.exhibition.head}</HeadColumns>
                        <Link to={'/exhibition'}><LinksColumns>{localText.exhibition.link2}</LinksColumns></Link>
                        <Link to={'/exhibition'}><LinksColumns>{localText.exhibition.link3}</LinksColumns></Link>
                    </VirtualExhibitionColumn>
                    <FollowColumn>
                        <HeadColumns>{localText.follow.head}</HeadColumns>
                        <Link to={'/follow'}><LinksColumns>{localText.follow.link1}</LinksColumns></Link>
                        <Link to={'/follow'}><LinksColumns>{localText.follow.link2}</LinksColumns></Link>
                        <Link to={'/follow'}><LinksColumns>{localText.follow.link3}</LinksColumns></Link>
                        <Link to={'/follow'}><LinksColumns>{localText.follow.link4}</LinksColumns></Link>
                    </FollowColumn>
                    <InfoColumn>
                        <HeadColumns>{localText.info.head}</HeadColumns>
                        <Link to={'/manual'}><LinksColumns>{localText.info.link1}</LinksColumns></Link>
                        <Link to={'/manual'}><LinksColumns>{localText.info.link2}</LinksColumns></Link>
                    </InfoColumn>
                    </Fragment>
                 }
                 {width < 580 && <AdditContent>
                    <Link style={{textDecoration: 'none'}} to={'/agreement'} target={'_blank'}><p>{localText.policy}</p></Link>
                    <a href={'https://dev.files.clevr.by/files/clevr/Типовой договор.pdf'} target='_blank' rel="noreferrer"><p>{localText.contract}</p></a>
                    <p className='TechSupport' onClick={() => setTechSupportActive(true)}>{localText.support}</p>
                    </AdditContent>
                 }
                    { width >= 1025 &&
                    <MobileAppColoumn>
                        <HeadColumns>{localText.mobileApp.head}</HeadColumns>
                        <LinksColumns href='#'>{localText.mobileApp.link1}</LinksColumns>
                        <LinksColumns href='#'>{localText.mobileApp.link2}</LinksColumns>
                    </MobileAppColoumn>
                    }
                </FooterWidgetsColumn>
                { width < 1024 && <div className='mobile_line'></div>}
                <div className='download_mobile_container'>
                    <MobileInstallBtn>
                        <button>{localText.mobileApp.btnIOS}</button>
                        <button>{localText.mobileApp.btnAndroind}</button>
                    </MobileInstallBtn>
                    { width < 1024 &&
                    <MobileAppColoumn>
                        <HeadColumns>{localText.mobileApp.head}</HeadColumns>
                        <div className='mobile_links'>
                            <LinksColumns href='#'>{localText.mobileApp.link1}</LinksColumns>
                            <LinksColumns href='#'>{localText.mobileApp.link2}</LinksColumns>
                        </div>
                    </MobileAppColoumn>
                    }
                </div>
            </FooterWidgets>
            <BottomLine></BottomLine>
            <AdditInfoBlock>
            {
                width > 580 && <AdditContent>
                    <Link style={{textDecoration: 'none'}} to={'/agreement'} target={'_blank'}><p>{localText.policy}</p></Link>
                    <a href={'https://dev.files.clevr.by/files/clevr/Типовой договор.pdf'} target='_blank' rel="noreferrer"><p>{localText.contract}</p></a>
                    <p className='TechSupport' onClick={() => setTechSupportActive(true)}>{localText.support}</p>
                </AdditContent>
            }
                <SocialBlock>
                <p className='emailIcon'>{email} info@clevr.by</p>
                {width < 580 && <div className='mobile_line'></div>}
                <div className='Social'>
                   <a href='https://www.linkedin.com/showcase/clevr.expo' target={'_blank'} rel={'noreferrer'}><p>{linkedIn}</p></a>
                   <a href={'https://www.instagram.com/clevr.expo'} target={'_blank'} rel={'noreferrer'}><p>{instagram}</p></a>
                   <a href='https://vm.tiktok.com/ZMeSqjhX9' target={'_blank'} rel={'noreferrer'}><p>{tiktok}</p></a>
                   <a href='https://www.facebook.com/clevrexpo' target={'_blank'} rel={'noreferrer'}><p>{facebook}</p></a>
                   <a href='https://www.youtube.com/channel/UCV3Lo_wgMqWHfBY2JTzJ3eA/featured' target={'_blank'} rel={'noreferrer'}><p>{youtube}</p></a>
                </div>
                </SocialBlock>
            </AdditInfoBlock>
            <BottomLine></BottomLine>
            <BlockCopyright>
            <StyledCopyright>© 2023 СleVR. {localText.rights}</StyledCopyright>
            </BlockCopyright>
            <ModalMessage />
        </StyledFooter>
    )
}
