import styled from "styled-components";

const PavilionsContainerWrap = styled.div`
    margin-top: 64px;
    background-color: #FFFFFF;
    padding-bottom: 16px;
    border-top: 0.5px solid #D4D4D4;
    border-bottom: 0.5px solid #D4D4D4;
    h1{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 16px;
        line-height: 110%;
        padding: 22px 0;
        color: #363636;
        text-align: center;
    }
`;


export {PavilionsContainerWrap}