import styled from "styled-components";


const WrapAppToast = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 16px;
    z-index: 9999;
    background: #F5F5F5;
    border-radius: 10px;
    color: #363636;
`;


export {
    WrapAppToast,

}