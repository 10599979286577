import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { filesUrl } from "../../../API/api";
import { svg_icons } from "../../../assets/imgs/svg_icons";
import { setAuthState } from "../../../store/authSlice/authSlice";
import Language from "../Language/Language";
import {
  BurgerLang,
  BurgerLoginBtn,
  BurgerMenuContainer,
  BurgerMenuTitle,
  BurgerNavLinks,
  NavAvatar,
  NavUserTextWrap,
  NavUserWrap,
  WrapBurgerMenu,
  WrapNavBurger,
} from "./NavBurger.style";

export const NavBurger = () => {
  const { exitIcon, burgerMenu } = svg_icons;
  const [Display, setDisplay] = useState("none");
  const { header: localText } = useSelector((state) => state.app.appText);
  const { userData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const OpenLogin = () => {
    setDisplay("none");
    dispatch(setAuthState({ key: "open", value: true }));
  };

  return (
    <>
      <WrapNavBurger>
        <input
          type={"checkbox"}
          onChange={(e) => setDisplay("block")}
          id="nav-toggle"
          hidden
        />
        <label htmlFor="nav-toggle" className="nav-toggle">
          {burgerMenu}
        </label>
      </WrapNavBurger>
      <WrapBurgerMenu style={{ display: Display }}></WrapBurgerMenu>
      <BurgerMenuContainer
        style={
          Display === "block"
            ? { left: "0", visibility: "visible" }
            : { left: "-100%", visibility: "hidden" }
        }
      >
        <button onClick={() => setDisplay("none")}>{exitIcon}</button>
        <BurgerMenuTitle>
          <h2>Меню</h2>
        </BurgerMenuTitle>
        {userData.firstName ? (
          <NavUserWrap>
            <NavAvatar src={filesUrl + userData.avatar} />
            <NavUserTextWrap>
              <p>{userData.firstName}</p>
              <p>{userData.lastName}</p>
            </NavUserTextWrap>
          </NavUserWrap>
        ) : null}
        <BurgerNavLinks>
          <ul>
            <li>
              <Link
                to={"/"}
                onClick={() => setDisplay("none")}
                style={{ textDecoration: "none" }}
              >
                {localText.main}
              </Link>
            </li>
            <li>
              <Link
                to={"/exhibition"}
                onClick={() => setDisplay("none")}
                style={{ textDecoration: "none" }}
              >
                {localText.exhibition}
              </Link>
            </li>
            <li>
              <Link
                to={"/news"}
                onClick={() => setDisplay("none")}
                style={{ textDecoration: "none" }}
              >
                {localText.blog}
              </Link>
            </li>
            <li>
              <Link
                to={"/follow"}
                onClick={() => setDisplay("none")}
                style={{ textDecoration: "none" }}
              >
                {localText.participans}
              </Link>
            </li>
            <li>
              <Link
                to={"/manual"}
                onClick={() => setDisplay("none")}
                style={{ textDecoration: "none" }}
              >
                {localText.info}
              </Link>
            </li>
            {userData.firstName ? (
              <li>
                <Link
                  to={"/account/user-panel"}
                  onClick={() => setDisplay("none")}
                  style={{ textDecoration: "none" }}
                >
                  {localText.account}
                </Link>
              </li>
            ) : null}
          </ul>
        </BurgerNavLinks>
        <BurgerLang>
          <p>Выбор языка</p>
          <Language height={38} />
        </BurgerLang>
        {!userData.firstName ? (
          <BurgerLoginBtn>
            <button onClick={OpenLogin}>Вход и регистрация</button>
          </BurgerLoginBtn>
        ) : null}
      </BurgerMenuContainer>
    </>
  );
};
