import styled from "styled-components";

const SettingsUserWrap = styled.div`
position: fixed;
background: #000000;
opacity: 0.25;
inset: 0;
z-index: 100;
`;

const SettingsUserContainer = styled.div`
position: absolute;
width: 231px;
background: #FFFFFF;
border-radius: 20px 0px 20px 20px;
z-index: 101;
right: 15px;
top: 17px;
display: flex;
flex-direction: column;
padding: 12px;
gap: 10px;
`;

export {
    SettingsUserContainer,
    SettingsUserWrap
}