export const forms_ru = {
    base: {
        firstName: "Имя",
        firstNamePl: "Введите имя",
        email: "Email",
        emailPl: "Введите Email",
        password: "Пароль",
        passwordPl: "Введите пароль",
        repeatPassword: "Повторите пароль",
        repeatPasswordPl: "Введите пароль повторно",
        phone: "Телефон",
        cancel: "Отменить",
    },
    registr: {
        head: "Регистрация",
        subhead1: "Заполните все обязательные поля",
        subhead2: "чтобы создать свой аккаунт пользователя CleVR",
        blockUserH1: "Пользователь",
        lastName: "Фамилия",
        lastNamePl: "Введите фамилию",
        patronymic: "Отчество",
        patronymicPl: "Введите Отчество",
        blockSecurityH1: "Безопасность",
        blockAdditH1: "Дополнительные контакты",
        agreement1: "Я соглашаюсь с правилами пользования,",
        agreement2: "политикой конфиденциальности и",
        agreement3: "обработки персональных данных",
        submit: "Зарегистрироваться",
        errors: {
            acceptAgreement: "Примите пользовательское соглашение",
        },
    },
    login: {
        head: "Вход",
        subhead: "Войдите в профиль используя ваш email и пароль",
        forgetPassword: "Забыли пароль?",
        login: "Войти",
        registr: "Регистрация",
        notAccount: "Ещё нет аккаунта? Зарегистрируйтесь в CleVR",
        register: "Зарегистрироваться",
    },
    support: {
        head: "Служба поддержки",
        theme: "Тема обращения",
        themePl: "Введите тему обращения",
        message: "Сообщение",
        messagePl: "Введите сообщение",
        send: "Отправить",
    },
    forgetPassword:{
        head: "Забыли пароль?",
        subhead: "Введите email и перейдите по ссылке в почте для смены пароля",
        addDesc: "Проверьте спам и правильность введённой почты, если ссылка не появится в ваших сообщениях",
        back: "Назад",
        getLink: "Получить ссылку",
        registr: "Регистрация",
        notAccount: "Ещё нет аккаунта? Зарегистрируйтесь в CleVR",
        register: "Зарегистрироваться",
    },
    requestPart: {
        head: "Заявка на участие",
        subhead1: "Заполните форму заявки на участие",
        subhead2: "вашей организации в CleVR и мы свяжемся с вами",
        blockCompanyH1: "Организация",
        organizationName: "Название организации",
        organizationNamePl: "Введите название организации",
        typeActivity: "Вид деятельности",
        typeActivityPl: "Выберите вид деятельности предприятия",
        blockContactH1: "Контактное лицо",
        comment: "Комментарий",
        submitRequest: "Подать заявку"
    },
    question: {
        head: 'Новый вопрос',
    }
}
