export const filesDownload_ru = {
    title: "Файлы",
    titleDoc: "Документы",
    subTitle: "Загрузка",
    docs:{
        industry4_2021: {
            text: "Материалы, доступные для скачивания (презентации докладчиков), представленные на онлайн-конференции \"Indusrty - 4.0 - инновации в производственном секторе\" 29 апреля 2021 года",
            name1: "Еромкин Д.Д. Astersoft Как внедрить ERP и не пожалеть об этом",
            name2: "Кричмар А.Б. PROF-IT Система ERP",
            name3: "Мартынов С.В. Siemens Применение цифровой инжиниринговой платформы",
            name4: "Сафонов А.И. БНТУ Подготовка квалифицированных специалистов",
            name5: "Осипенко С.Ю. ЦНИИТУ Единая виртуальная выставка",
            name6: "Кукареко Е.П. Витязь Интеграционное решение по управлению цифровым двойником",
            name7: "Соловьев С.А. ЦНИИТУ Индустрия 4.0 от теории к практике",      
        },
        industry4_2022: {
            text: "Материалы, доступные для скачивания (презентации докладчиков), представленные на онлайн-конференции \"Indusrty - 4.0 - инновации в производственном секторе\" 13 мая 2022 года",
            name1: "Алексейчик Д.В. Проф-ИТ Appius-PLM",
            name2: "Ануфриев Ю.А. ЮколаИнфо Успешные кейсы цифровой трансформации",
            name3: "Ваганов А.С. ТопСофт Индустрия 4.0 практический взгляд на современные тенденции в производстве",
            name4: "Еромкин Д.Д. Astersoft Автоматизация процессов управления персоналом ",
            name5: "Кукареко Е.П. ОМПсистем Цифровая трансформация отечественных предприятий",
            name6: "Потапович А.А. ИНТЕРМЕХ Управление цифровым двойником изделия ",
            name7: "Соловьев С.А. ЦНИИТУ Цифровая трансформация промышленных производств",
            name8: "Хейфец М.Л. НАНБ Системология объектов и процессов при цифровизации производства",
        },
        tibo2022: {
            text: "Материалы, доступные для скачивания (презентации докладчиков), представленные на TIBO 2022 года",
            name0: "Глушков, ЦНИИТУ",
            name1: "Интермех, Могилевлифтмаш",
            name2: "МЭТЗ, Мухортов",
            name3: "Бобруйскагромаш, Шостак Жуков Коровкин",
            name4: "Хейфец, НАН РБ",
            name5: "Ваганов, ТопСофт",
            name6: "Кишкурно, Беллесбумпром",
            name7: "Осипенко, ЦНИИТУ",
            name8: "Кричмар, Проф-ИТ",
            name9: "Юкола Ануфриев",
            name10: "Кирсанов, БКМ",
            name11: "Еромкин, Астерсофт",
            name12: "Григорьев, Ресолва Диджитал",
            name13: "Соловьев, круглый стол",
        },
        digitalTrasnformation: {
            text: "Материалы, доступные для скачивания (презентации докладчиков), представленные на круглом столе «Цифровая трансформация промышленного сектора Республики Беларусь. Вызовы и перспективы» 20 сентября 2022 года",
            name0: "Абазовик М.А. Комплексный подход 1С к реализации проектов ERP",
            name1: "Ваганов А.В. ТопСофт Белорусский цифровой продукт миф или реальность",
            name2: "Глушков А.В. ЦНИИТУ Цифровая трансформация производственного сектора на базе отечественной цифровой платформы",
            name3: "Жуков В.В. Бобруйскагромаш Решение актуальных задач цифровой трансформации",
            name4: "Кукареко Е.П. Omega Production как платформа цифровой трансформации отечественных предприятий",
            name5: "Соловьев С.А. ЦНИИТУ Офис цифровизации предприятий реального сектора экономики",
        },

        contractClevr: 'Договор оказания услуг по участию в Единой виртуальной выставке',
    },
}