import React from "react";
import { useState } from "react";
import { ModalQuestion } from "../../ModalQuestion/ModalQuestion";
import * as s_c from "./style/photo_services_css";
import { useSelector } from "react-redux";

export const PhotoServices = ({img, header, subhead}) => {
    const [OpenModal, setOpenModal] = useState(false);
    const {follow: {services: localText}} = useSelector(state => state.app.appText)
  return (
    <s_c.PhotoBlock>
      <img alt="photo_services_content" src={img}></img>
      <s_c.BlockContent>
        <div>
          <h3>{header}</h3>
          <p>{subhead}</p>
        </div>
        <button onClick={() => setOpenModal(true)}>{localText.askQuestion}</button>
      </s_c.BlockContent>
      {OpenModal ? <ModalQuestion setOpenModal={setOpenModal} /> : null}
    </s_c.PhotoBlock>
  );
};
