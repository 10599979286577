import { svg_icons } from "../../../../../assets/imgs/svg_icons"
import { AppSwitch } from "../../../../../components/AppSwitch/AppSwitch"
import { CloseButton, ModalContainer, ModalOpen, Title } from "../../../../Registration/Login/style/Login_css"
import { AddClassWrap, BtnsCatalogWrap } from "./addClass.style"


export const AddClass = ({setAddClass}) => {

    const {exitIcon} = svg_icons

    return (
        <ModalContainer>
            <ModalOpen maxWidth = {372}>
                <CloseButton onClick={() => setAddClass(false)}>{exitIcon}</CloseButton>
                <AddClassWrap>
                    <Title>{'Новый класс'}</Title>
                    <div className="class_code">
                        <p>{'Код класса'}</p>
                        <input placeholder={'Введите код'} />
                    </div>
                    <div className="name_Productclass">
                        <p>{'Название класса'}</p>
                        <input placeholder={'Введите название'}/>
                    </div>
                    <div className="visibility_forUser">
                        <p className="head_text">{'Видимость'}</p>
                        <div className="switcher-wrap">
                        <p>{'Доступен всем пользователям'}</p>
                        <AppSwitch/>
                        </div>
                    </div>
                    <div className="description_wrap">
                        <p className="head_text">{'Примечание'}</p>
                        <textarea />
                    </div>
                    <BtnsCatalogWrap>
                        <button className="back" onClick={() => setAddClass(false)}>{'Отменить'}</button>
                        <button className="send_btn">{'Сохранить'}</button>
                    </BtnsCatalogWrap>
                </AddClassWrap>
            </ModalOpen>
        </ModalContainer>
    )
}