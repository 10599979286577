export const seoModal_ru = {
    deleteTag: 'Удалить мета-тег',
    createTag: 'Добавить мета-тег',
    updateTag: 'Обновить мета-тег',
    title: 'Заголовок',
    description: 'Описание',
    send: 'Отправить',
    delete: 'Удалить',
    contentType: 'Тип контента',
    imageForSocialMedia: 'Картинка для социальных сетей',
    file: 'Файл',
    link: 'Ссылка',
    pc: 'Выберите файл',
    url: 'Введите ссылку',
}