import { useSelector } from "react-redux";
import { icons_svg } from "../Exhibition/Company/imgs/icons.svg";
import { File } from "../File/File";
import {
  ContactsClevr,
  Desc,
  ManualSection,
  ManualWrap,
  Title,
} from "./style/Manual.style";
import { Footer } from "../Footer/Footer";
import { useState } from "react";
import { TechSupport } from "../TechSupport/TechSupport";

export const Manual = () => {
  const { manual: localText } = useSelector((state) => state.app.appText);
  const [TechSupportActive, setTechSupportActive] = useState(false);

  const { pdfIcon, mail, phone } = icons_svg;
  const documents = [
    {
      name: localText.instrDoc,
      icon: pdfIcon,
      url: "https://dev.files.clevr.by/files/clevr/Инструкция Clevr.pdf",
    },
  ];

  const contacts = [
    { head: localText.mail, icon: mail, desc: "info@clevr.by" },
    { head: localText.orgQuestions, icon: phone, desc: "+375 17 311 84 20", },
    { head: localText.commQuestions, icon: phone, desc: "+375 17 311 84 20" },
    { head: localText.techQuestions, icon: phone, desc: "+375 17 311 84 20" },
  ];

  return (
    <ManualSection>
      {!TechSupportActive ? null : (
        <TechSupport setTechSupportActive={setTechSupportActive} />
      )}
      <ManualWrap>
        <Title>{localText.instrHead}</Title>
        <Desc>{localText.instrSubhead}</Desc>
        <div className="file_container" style={{display: 'flex'}}>
            <File documents={documents} page={"manual"}></File>
        </div>
        {/*      <img src={testImg} alt = 'TestImg'></img>
            <ManualTextWrap>
            <h3>{language.manual.textInfo}</h3>
            <ManualText>{language.manual.text}</ManualText>
            </ManualTextWrap>
            <BtnManualText>Показать больше {arrowList}</BtnManualText>*/}
      </ManualWrap>
      <ContactsClevr>
        <h2>{localText.contactsHead}</h2>
        <p className="contacts-subhead" dangerouslySetInnerHTML={{ __html: localText.contactsSubhead}}/>
        <div className="contacts">
          {contacts.map((contact, i) => (
            <div key={i} className="contact_backgroundGrey">
              <div className="contact__head">
                {contact.icon} <p>{contact.head}:</p>
              </div>
              <p className="contact__desc">{contact.desc}</p>
            </div>
          ))}
        </div>
        <p className="ContactUs">{localText.orText}:</p>
        <button
          onClick={() => setTechSupportActive(true)}
          className="ContactUs_Btn"
        >
          {localText.writeMes}
        </button>
      </ContactsClevr>
      <Footer />
    </ManualSection>
  );
};
