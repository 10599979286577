
// SEO. Title must be the same as link in header!

export const manual_ru = {
    title_1: 'Инструкция',
    desc: 'Ознакомьтесь с инструкцией по работе с виртуальной выставкой на сайте или скачайте файл',
    textInfo: 'Портретная съёмка',
    text: 'К современной портретной съемке можно отнести съемку модельного или актёрского портфолио, классический портрет, семейный портрет, фотографии в ретро-стиле, винтажные фото, гламур, грандж, индастриал,  а также fashion, pin-up, ню-арт, и многочисленные направления, созданные смешением стилей. АК современной портретной съемке можно отнести съемку модельного или актёрского портфолио, классический портрет, семейный портрет, фотографии в ретро-стиле, винтажные фото, гламур, грандж, в ретро-стиле, винтажные фото, гламур, грандж, индастриал,  а также fashion, pin-up, ню-арт',

    instrHead: "Инструкция",
    instrSubhead: "Ознакомьтесь с инструкцией по работе с виртуальной выставкой на сайте или скачайте файл",
    instrDoc: "Инструкция CleVR.pdf",

    contactsHead: "Контакты",
    contactsSubhead: "Используйте дополнительные каналы коммуникации, если у вас остались вопросы",

    mail: "Почта",
    orgQuestions: "Организационные вопросы",
    commQuestions: "Коммерческие вопросы",
    techQuestions: "Технические вопросы",
    orText: "Или напишите ваше сообщение в форме ниже",
    writeMes: "Написать сообщение",


}
