import {useEffect} from "react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {svg_icons} from "../../assets/imgs/svg_icons";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
} from "../Registration/Login/style/Login_css";
import {ErrorModalWrap} from "./ErrorModal.style";

export const ErrorModal = ({setErrModalOpen}) => {
  const {language} = useSelector((state) => state.lang);
  const {errors} = useSelector((state) => state.profile);
  const {message, setMessage} = useState("");
  const {exitIcon} = svg_icons;

  useEffect(() => {
    if (errors.length !== 0) {
      errors[0]?.message === "User with such email exists"
        ? setMessage(language.errorsRequest.registration.email)
        : setMessage("Произошла непредвиденная ошибка");
    }
  });

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372} style={{height: "180px"}}>
        <CloseButton onClick={() => setErrModalOpen(false)}>
          {exitIcon}
        </CloseButton>
        <ErrorModalWrap>
          <p>{message}</p>
          <button
            onClick={() => setErrModalOpen(false)}
            className="Activate_Acc"
          >
            Понятно
          </button>
        </ErrorModalWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
