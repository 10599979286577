import { RepresantativesCard, RepresentetivesPageWrap } from "./representetivesPage.style"
import { useState } from "react"
import { AddRepresantatives } from "./AddRepresentative/AddRepresantative"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeVisibilityRep, deleteRepresentatives, setRepresentatives } from "../../../../store/exhibitorSlice/exhibitorSlice"
import { filesUrl } from "../../../../API/api"
import { setAppState } from "../../../../store/appSlice/appSlice"
import { Fragment } from "react"
import { GetRepresentatives } from "../../../../store/exhibitorSlice/exhibitorAsyncThunk"
import { svg_icons } from "../../../../assets/imgs/svg_icons"

export const RepresentetivesPage = () => {
    const {addIcon, eyeIcon, crossEyeIcon, trashIcon, pointsIcon} = svg_icons
    const [addRepresentative, setAddRepresentative] = useState(false)
    const {representatives} = useSelector((state) => state.exhibitor)
    const dispatch = useDispatch()
    
    useEffect(() => {

        dispatch(GetRepresentatives(''))

        return () => {
            dispatch(setRepresentatives([]))
        }
    },[dispatch])

const handlerActions = (key, data) => (e) => {
        switch (key) {
          case "contextMenu":
            dispatch(
              setAppState({
                contextMenu: {
                  currentTarget: e.currentTarget,
                  listMenu: [{
                      onClick: () => {
                          dispatch(
                            changeVisibilityRep({...data, visibility: data.visibility})
                          );
                      },
                      icon: data.visibility ? crossEyeIcon : eyeIcon,
                      text: data.visibility ? "Cкрыть представителя" : 'Показывать представителя',
                    },
                    {
                      onClick: () => {
                        dispatch(deleteRepresentatives(data))
                      },
                      icon: trashIcon,
                      text: "Удалить из представителей",
                      class: " error"
                    }],
            }
        }
        )
    )
    break;
    default:
        break;
        }
}

console.log(representatives)


    return (
        <RepresentetivesPageWrap>
        <div className="head_wrap">
            <h3>{'Представители'}</h3>
            <button className="add_icon" onClick={() => setAddRepresentative(true)}>{addIcon}</button>
        </div>
        <div className="representatives onPage">
            <div className="text_wrap">
                <p>{eyeIcon}{'На странице'}</p>
            </div>
            <div className="representative_array_wrap">
                    {representatives.map(item => {
                        if(item.remove) return ''
                        return (
                            
                            <Fragment key={item.id}>
                            { item.visibility ?
                                
                            <RepresantativesCard>
                                <div className="wrap_repInfo">
                                    <img className="avatar" src={filesUrl + item.avatar} alt={'avatar'}/>
                                    <div>
                                        <p className="rep_name">{item.firstName}{' '}{item.lastName}</p>
                                        <p className="rep_job">{item.position ? item.position : item.job}</p>
                                    </div>
                                </div>
                                <button className="dots-settings" onClick={handlerActions("contextMenu", item)}>{pointsIcon}</button>
                            </RepresantativesCard>
                            : 
                            null
                            }
                            </Fragment>
                        )
                    })}
                </div>
        </div>
       {/* <div className="representatives onPreview">
            <div className="text_wrap">
                <p>{hourGalssIcon}{'На рассмотрении'}</p>
            </div>
            <div className="representative_array_wrap">
                    {representatives.map(item => {
                        return (
                            <RepresantativesCard>
                                <div className="wrap_repInfo">
                                    <img className="avatar" src={filesUrl + item.avatar} alt={'avatar'}/>
                                    <div>
                                        <p className="rep_name">{item.firstName}{' '}{item.lastName}</p>
                                        <p className="rep_job">{item.job}</p>
                                    </div>
                                </div>
                                <button className="dots-settings">{dotsIcons}</button>
                            </RepresantativesCard>
                        )
                    })}
                </div>
        </div>*/}
        <div className="representatives hover">
            <div className="text_wrap">
                <p>{crossEyeIcon}{'Скрытые'}</p>
            </div>
            <div className="representative_array_wrap">
                    {representatives.map(item => {
                        if(item.remove) return ''
                        return (
                            <Fragment key={item.id}>
                            { !item.visibility ?
                            <RepresantativesCard>
                                <div className="wrap_repInfo">
                                    <img className="avatar" src={filesUrl + item.avatar} alt={'avatar'}/>
                                    <div>
                                        <p className="rep_name">{item.firstName}{' '}{item.lastName}</p>
                                        <p className="rep_job">{item.job}</p>
                                    </div>
                                </div>
                                <button className="dots-settings" onClick={handlerActions("contextMenu", item)}>{pointsIcon}</button>
                            </RepresantativesCard>
                            :
                            null
                            }
                            </Fragment>
                        )
                    })}
                </div>
        </div>
        {addRepresentative && <AddRepresantatives setAddRepresentative={setAddRepresentative} />}
        </RepresentetivesPageWrap>
    )
}