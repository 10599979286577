import React from "react";
import {
  VDNXContainer,
  DescriptionContainer,
  TextContainer,
  LinePavilionContent,
} from "./style/pavilionVDNX_css";
import Metalworking from "../../../../assets/Components/PavilionsSlider/images/Металлообработка.webp";
import Instrumentation from "../../../../assets/Components/PavilionsSlider/images/Приборостроение.webp";
import MechanicalEngineering from "../../../../assets/Components/PavilionsSlider/images/Машиностроение.jpg";
import HouseholdAppliances from "../../../../assets/Components/PavilionsSlider/images/БытоваяТехника.webp";
import TIBO2024 from "../../../../assets/Components/PavilionsSlider/images/TIBO2024.webp"
import VDNX from "../../../../assets/Components/PavilionsSlider/images/ВДНХ.webp";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { getShortName } from "../../../../utils/helpers";
import { setSearchSettings } from "../../../../store/PavilionsSlice/pavillionsSlices";

export const PavilionItem = ({ pavilion }) => {
  let ImgSlider;

  const localText = useSelector((state) => state.app.appText);


  switch (pavilion.nameKey) {
    case "Metalworking":
      ImgSlider = Metalworking;
      break;
    case "Instrumentation":
      ImgSlider = Instrumentation;
      break;
    case "MechanicalEngineering":
      ImgSlider = MechanicalEngineering;
      break;
    case "HouseholdAppliances":
      ImgSlider = HouseholdAppliances;
      break;
    case "Vdnh":
      ImgSlider = VDNX;
      break;
    case "Tibo":
      ImgSlider = TIBO2024
      break;
    default:
      break;
  }

  const {width} = useWindowDimensions()

  const dispatch = useDispatch()

  const height = width / 2

  return (
    <VDNXContainer height = {height} bg={ImgSlider}>
      {/*<ImgBg alt="ImgBg" src={ImgSlider}></ImgBg>*/}
      
   {/*   <HeadContent>
        <p className="head">{localText.VDNX.head}</p>
      </HeadContent>*/}
      <DescriptionContainer>
        <p className="description_title">
          {pavilion[getShortName(localText.language)]}
        </p>
        <LinePavilionContent></LinePavilionContent>
        <TextContainer>
          <div className="description_text">
          {pavilion[localText.language]}
          </div>
          <Link to={{ pathname: `/exhibition` }}>
            <button onClick={() => dispatch(setSearchSettings({key: 'currentPavilion', value: pavilion.nameKey}))} className="description_btn">{localText.VDNX.textBtn}</button>
          </Link>
        </TextContainer>
      </DescriptionContainer>
    </VDNXContainer>
  );
};
