import React, { useState } from "react";
import { Fragment } from "react";
import { Benefits } from "./Benefits/Benefits";
import { Services } from "./FollowServices/Services";
import { Materials } from "./Materials/Materials";
import * as s_c from "./style/follow_css";
import background from "../../assets/Components/FollowClevr/images/HeadFollow.png";
import { FollowModal } from "./FollowModal/FollowModal";
import { Footer } from "../Footer/Footer";
import { ModalMessage } from "../ModalMessage/ModalMessage";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const FollowClevr = () => {
  const [isModal, setIsModal] = useState(false);
  const {follow: localText} = useSelector(state => state.app.appText)

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <div style={{ backgroundColor: "#fff", marginTop: '64px' }}>
        <s_c.FollowUsSection id="scrollFollowUs" Bg={background}>
          <s_c.WrapContentText>
            <h1>{localText.head}</h1>
            <p>{localText.subhead}</p>
            <s_c.FollowBtn onClick={() => setIsModal(true)}>
              {localText.becomeMember}
            </s_c.FollowBtn>
          </s_c.WrapContentText>
        </s_c.FollowUsSection>
      </div>
      <Benefits localText={localText}/>
      <Materials setIsModal={setIsModal} localText={localText} />
      <Services localText={localText.services}/>
      {isModal ? <FollowModal setIsModal={setIsModal} /> : <></>}
      <ModalMessage />
      <Footer />
    </Fragment>
  );
};
