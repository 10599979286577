import { createSlice } from "@reduxjs/toolkit"
import { handlerData } from "../../services/handlerData.service";
import { extraReducers } from "./exhibitorExtraReducers";
import reducers from "./exhibitorReducers"

const { getAppText } = handlerData();

const initialState = {
language: getAppText().language || 'ru',
content: 'main',
errors: '',
error: '',
Exhmessage: '',
openModal: false,
name: {
    ru: '',
    by: '',
    en: ''
},
cover: {
    path: '',
    file: ''
},
logo: {
    path: '',
    file: ''
},
text: {
    ru: '',
    by: '',
    en: ''
},
dirty: false,
visibilityPage: false,
openSettings: {
    key: '',
    open: false,
},
gallery: {
    image: {
        name: '',
        description: '',
        consultation: false,
        src: '',
        file: '',
        type: 'image'
    },
    video: {
        name: '',
        description: '',
        consultation: false,
        src: '',
        type: 'video'
    }
},
galleryItems: [],
newGalleryItems: [],
representatives: [],
companyDocuments: [],
companyDepartments: [],
contactsTypes: []
}

export const exhibitorSlice = createSlice({
    name: 'exhibitorSlice',
    initialState,
    reducers,
    extraReducers
})

export const {
    setExhibitorLangObj, 
    setInitValues, 
    setOpenSettings, 
    setExhibitorLanguage,
    setExhibitorFile,
    setExhibitorContent,
    setInitFileValues,
    setGalleryFiles,
    setGalleryInfo,
    setConsult,
    setGalleryItems,
    resetStateList,
    deleteMediaGallery,
    editMediaGallery,
    setRepresentatives,
    deleteRepresentatives,
    changeVisibilityRep,
    addRepresentatives,
    addDocument,
    setShowDocuments,
    deleteDocuments,
    setDepartment,
    resetGallery,
    setDepartmentContact,
    deleteDepartmentContact,
    updateDepartmentContact
} = exhibitorSlice.actions

export const exhibitorReducer = exhibitorSlice.reducer

