import styled from "styled-components";

const CheckBoxLabel = styled.label`
	margin: 0 0 16px 0;
	cursor: pointer;
	user-select: none;
	position: relative;
input[type=checkbox] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
span {
	position: relative;  
    svg{
        position: absolute;
        top: 3px;
        left: 3px;
    }  
}
span:before {
	content: "";
	display: inline-block;
	width: 16px;
	height: 16px;
	position: absolute;
	left: 0;
	top: 0;
	transition: background 0.3s ease;
	background: #FFFFFF;
border: 1px solid #D9D9D9;
border-radius: 2px;
}
input[type=checkbox]:checked + span:before {
	background-color: #34C57F;
    border: 1px solid #34C57F;
}
`

export {
CheckBoxLabel
}