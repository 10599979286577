import React, {useEffect, useState} from "react";
import {
  CloseButton,
  Desc,
  ItemInfo,
  ModalContainer,
  ModalOpen,
  RegItem,
  Title,
} from "./style/Login_css";
import {Line} from "../../Account/SelectExhibitors/style/SelectExhibitors_css";
import {SignIn} from "../SignIn";
import {RecoverPassword} from "../RecoveryPassword/RecoverPassword";
import {useDispatch, useSelector} from "react-redux";
import {setAuthState} from "../../../store/authSlice/authSlice";
import {svg_icons} from "../../../assets/imgs/svg_icons";

const {exitIcon} = svg_icons;
export const Login = () => {
  const [isRecover, setRecover] = useState(false);

  const dispatch = useDispatch();

  const {open} = useSelector((state) => state.auth);
  const {
    forms: {login},
  } = useSelector((state) => state.app.appText);

  let DescWidth = 183;

  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";
  });

  const closeBtnEvent = () => {
    dispatch(setAuthState({key: "open", value: false}));
    isRecover ? setRecover(false) : setRecover(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";
  });

  if (!open) return null;

  return !isRecover ? (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={closeBtnEvent}>{exitIcon}</CloseButton>
        {!isRecover ? <SignIn setRecover={setRecover} /> : null}
        <RegItem>
          <Line></Line>
          <ItemInfo>
            <Title>{login.registr}</Title>
            <Desc width={DescWidth}>{login.notAccount}</Desc>
            <button
              onClick={() =>
                dispatch(setAuthState({key: "openRegistration", value: true}))
              }
            >
              {login.register}
            </button>
          </ItemInfo>
        </RegItem>
      </ModalOpen>
    </ModalContainer>
  ) : (
    <RecoverPassword
      RegistrationModal={true}
      closeBtnEvent={closeBtnEvent}
      setRecover={setRecover}
    />
  );
};
