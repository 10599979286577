
// SEO. Title must be the same as link in header!

export const manual_by = {
    title_1: 'Інструкцыя',
    desc: '',

    instrHead: "Інструкцыя",
    instrSubhead: "Азнаёмцеся з інструкцыяй па рабоце з віртуальнай выставай на сайце або запампуйце файл",
    instrDoc: "Інструкцыя CleVR.pdf",

    contactsHead: "Кантакты",
    contactsSubhead: "Выкарыстоўвайце дадатковыя каналы камунікацыі, калі ў вас засталіся пытанні",

    mail: "Пошта",
    orgQuestions: "Арганізацыйныя пытанні",
    commQuestions: "Камерцыйныя пытанні",
    techQuestions: "Тэхнічныя пытанні",
    orText: "Або напішыце ваша паведамленне ў форме ніжэй",
    writeMes: "Напісаць паведамленне",
}
