import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GetCompanies, GetPavilions } from "../../store/PavilionsSlice/pavilionsAsyncThunk"
import { PavilionsSelectsBlock } from "./PavilionsSelectsBlock/PavilionsSelectsBlock"
import { PavilionsContainerWrap } from "./style/PavilionsContainer_css"
import { resetPavilionsState } from "../../store/PavilionsSlice/pavillionsSlices"
import { getShortName } from "../../utils/helpers"


export const PavilionsContainer = () => {

    const {companies, currentPavilion, currentProduct, searchCompany, pavilions} = useSelector((state) => state.pavilions)
    const {language} = useSelector((state) => state.app.appText);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetPavilions());
        console.log('effect')
        return () => {
            dispatch(resetPavilionsState('pavilions'))
        }
    }, [dispatch])

    useEffect(() => {
        const param = {
            selectPavilion: currentPavilion,
            productId: currentProduct.id,
            selectCompany: searchCompany
        }
            dispatch(GetCompanies(param))
            return () => {
                dispatch(resetPavilionsState('companies'))
            }
    }, [dispatch, searchCompany, currentPavilion, currentProduct])

    const setOptionOnSelectPavilions = () => {
        if(currentPavilion === 'all'){
            return [{ id: 0, value: `Все (${pavilions.length})`}, ...pavilions.map((item) => ({id: item.id, value: item[getShortName(language)]}))]
        }else{
            return [
            pavilions.reduce((acc, curr) => {
                if(curr.nameKey === currentPavilion){
                    acc = {
                        id: curr.id,
                        value: curr[getShortName(language)]
                    }
                }
                return acc
            }, {}),
            { id: 0, value: `Все (${pavilions.length})`} , 
            ...pavilions.reduce((acc, curr) => {
                if(curr.nameKey === currentPavilion){
                    return acc
                }else{
                    acc.push({...curr, value: curr[getShortName(language)]})
                    return acc
                }
            }, []),
        ]
        }
    }

    if(pavilions.length === 0){
        return null
      }


    return (
        <PavilionsContainerWrap>
            <h1>Экспоненты ({`${companies.length}`})</h1>
            <PavilionsSelectsBlock setOptionOnSelectPavilions={setOptionOnSelectPavilions}  />
        </PavilionsContainerWrap>
    )
}