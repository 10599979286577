import { handlerLocalStorage } from "./handlerLocalStorage.service";
import languages from "../languages/languages";

export const handlerData = () => {
  let initLanguage;

  switch (handlerLocalStorage().get("language")) {
    case 'ru':
    case 'en':
    case 'by':
        initLanguage = handlerLocalStorage().get("language")
        break;
    default:
        initLanguage = 'ru'
        break;
  }

  const getAppText = (lang = initLanguage) => languages[lang]

  return {
    getAppText,
  };
};
