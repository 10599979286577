import {svg_icons} from "../../../assets/imgs/svg_icons";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../Registration/Login/style/Login_css";
import {BtnsWrap, DeleModalWrap} from "./DeleteModal.style";

export const DeleteModal = ({name, text, setDeleteModalOpen, deleteClick}) => {
  const {exitIcon} = svg_icons;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setDeleteModalOpen({open: false})}>
          {exitIcon}
        </CloseButton>
        <DeleModalWrap>
          <Title>{"Желаете удалить?"}</Title>
          <p className="delete_info">{name}</p>
          <p className="delete_text">{text}</p>
          <BtnsWrap>
            <button
              className="back_btn"
              onClick={() => setDeleteModalOpen({open: false})}
            >
              Отменить
            </button>
            <button onClick={deleteClick} className="send_btn">
              Да, удалить
            </button>
          </BtnsWrap>
        </DeleModalWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
