import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../services/requests.services";
import { openMessageHint } from "../appSlice/appSlice";

export const GetNews = createAsyncThunk("NewsSlice/FetchNews", async (data) => {
  const { NewsApi } = getRequest();
  const response = await NewsApi.getNews(data);
  return response;
});

export const GetSelectNews = createAsyncThunk(
  "NewsSlice/FetchSelectNews",
  async (id) => {
    const { NewsApi } = getRequest();
    const response = await NewsApi.getSelectNews(id);
    return response;
  }
);

export const getAdminNews = createAsyncThunk(
    "NewsSlice/GetAdminNews", 
    async (data) => {
        const {NewsApi} = getRequest()
        const response = await NewsApi.getAdminNews(data)
        return response
    }
)

export const createAdminNews = createAsyncThunk(
  "NewsSlice/createAdminNews",
  async (data, thunkAPI) => {
    const { NewsApi } = getRequest();
    const response = await NewsApi.createNews(data.body);

    if(response){
        thunkAPI.dispatch(openMessageHint(true))
    }
    return {response, paramUrl: data.param};
  }
);

export const deleteAdminNews = createAsyncThunk(
    "NewsSlice/deleteAdminNews",
    async (newsId) => {
        const {NewsApi} = getRequest()
        const response = await NewsApi.deleteAdminNews(newsId)
        return {...response, newsId}
    }
    );

export const updateNewsShow = createAsyncThunk(
    "NewsSlice/updateNewsShow",
    async(news) =>{
        const {NewsApi} = getRequest()
        const response = await NewsApi.updateNewsShow(news)
        return response
    }
)

export const makeMainNews = createAsyncThunk(
    "NewsSlice/makeMainNews",
    async(news) => {
        const {NewsApi} = getRequest()
        const response = await NewsApi.makeMainNews(news)
        return response
    }
)

export const deleteNewsMedia = createAsyncThunk(
    "NewsSlice/deleteNewsMedia",
    async(data) =>{
        const {NewsApi} = getRequest()
        const response = await NewsApi.deleteMediaNews(data.media, data.newsId)
        return {...response, id: data.media.id}
    }
) 

export const updateNewsMedia = createAsyncThunk(
    "NewsSlice/updateNewsMedia", 
    async(data) => {
        const {NewsApi} = getRequest()
        const response = await NewsApi.updateNewsMedia(data.media, data.newsId)
        return response
    }
)

export const updateNews = createAsyncThunk(
    "NewsSlice/updateNews",
    async(data) => {
        const  {NewsApi} = getRequest()
        const response = await NewsApi.updateNews(data.news, data.newsId)
        return response
    }
)

export const updatePreview = createAsyncThunk(
    "NewsSlice/updatePreview",
    async(data) => {
        const {NewsApi} = getRequest()
        const response = await NewsApi.updatePreview(data)
        return {response, mediaId: data.mediaId}
    }
)
