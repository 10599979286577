import styled from "styled-components";

const AddContactWrap = styled.div`
padding: 24px 16px 25px;
.head_text{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
.choose_type_contact{
    margin-top: 25px;
    .update_disabled_select{
        width: 100%;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        display: flex;
    align-items: center;
    padding-left: 12px;
}
    }
}
.link_description_contact{
    margin-top: 25px;
    margin-bottom: 30px;
    input{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        width: 100%;
        height: 36px;
        border: none;
        outline: none;
        background: #F5F5F5;
        border-radius: 4px;
        padding: 0 12px;
    }
}
`;

export {
    AddContactWrap
}