import React from "react";
import { useSelector } from "react-redux";
import {
  Item,
  ItemTitle,
  CompaniesContainer,
  ItemContainer,
} from "./style/partnersCarousel_css"; //s_c ===> styled-components
import MGTP from "../../../assets/Components/Routes/MainPage/Partners/images/carousel/MGTP.png";
import MS from "../../../assets/Components/Routes/MainPage/Partners/images/carousel/MS.jpg";
import MP from "../../../assets/Components/Routes/MainPage/Partners/images/carousel/MP.jpg";
import belisa from "../../../assets/Components/Routes/MainPage/Partners/images/carousel/belisa.jpg";
import Nai from "../../../assets/Components/Routes/MainPage/Partners/images/carousel/Nai.jpg";
import Cniitu from "../../../assets/Components/Routes/MainPage/Partners/images/carousel/Cniitu.png";
import belinterexpo from '../../../assets/Components/Routes/MainPage/Partners/images/carousel/belinterexpo.jpg'

const Company = ({ src, title }) => {

  return (
    <>
      <Item>
        <img src={src} alt={title} unselectable="on" loading="lazy" />
        <ItemTitle>{title}</ItemTitle>
      </Item>
    </>
  );
};

export const PartnersCarousel = () => {

  const {mainPage: {partners: localText}} = useSelector((state) => state.app.appText);

  const companies = [
    {
      src: MS,
      title: localText.list.msHead,
      url: "https://www.mpt.gov.by",
    },
    {
      src: MP,
      title: localText.list.mpHead,
      disc: " stand alone syr",
      url: "https://minprom.gov.by",
    },
    {
      src: Cniitu,
      title: localText.list.cniituHead,
      disc: "HAshelbe",
      url: "http://cniitu.by",
    },
    {
      src: MGTP,
      title: localText.list.mgtpHead,
      disc: "HAshelbe",
      url: "https://mgtp.by",
    },
    {
      src: Nai,
      title: localText.list.naiHead,
      disc: "HAshelbe",
      url: "https://www.investinbelarus.by",
    },
    {
      src: belisa,
      title: localText.list.belisaHead,
      disc: "HAshelbe",
      url: "http://www.belisa.org.by",
    },
    {
        src: belinterexpo,
        title: localText.list.interExpo,
        disc: "HAshelbe",
        url: "https://www.belinterexpo.by/",
    }
  ];

  return (
    <>
      <CompaniesContainer>
        {companies.map((key, i) => (
          <ItemContainer key={i}>
            <a
              href={key.url}
              key={i}
              target="_blank"
              rel="noreferrer nofollow"
              style={{ userDrag: "none", width: "200px" }}
            >
              <Company
                src={key.src}
                title={key.title}
              />
            </a>
          </ItemContainer>
        ))}
      </CompaniesContainer>
    </>
  );
};
