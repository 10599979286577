import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { svg_icons } from "../../../../assets/imgs/svg_icons"
import { setAppState } from "../../../../store/appSlice/appSlice"
import { GetCompanyDocuments } from "../../../../store/exhibitorSlice/exhibitorAsyncThunk"
import { addDocument, deleteDocuments, setShowDocuments } from "../../../../store/exhibitorSlice/exhibitorSlice"
import { changeSizeFile, getInfoFile } from "../../../../utils/helpers"
import { ExhibitordocumentsCard, ExhibitorDocumentsWrap } from "./exhibitorDocuments.style"

export const ExhibitorDocuments = () => {
    const {companyDocuments} = useSelector((state) => state.exhibitor)
    const {addIcon, eyeIcon, trashIcon, crossEyeIcon, pointsIcon, pdfIcon } = svg_icons
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetCompanyDocuments())
    },[dispatch])

    const handlerActions = (key, data) => (e) => {
        switch (key) {
          case "contextMenu":
            dispatch(
              setAppState({
                contextMenu: {
                  currentTarget: e.currentTarget,
                  listMenu: [
                    {
                        onClick: () => dispatch(setShowDocuments({...data, show: !data.show})),
                        text: data.show ? "Скрыть документ" : "Показывать документ",
                        icon: data.show ? crossEyeIcon : eyeIcon
                    },
                    {
                      onClick: () => dispatch(deleteDocuments(data)),
                      icon: trashIcon,
                      text: "Удалить документ",
                      class: " error",
                    },
                  ],
                },
              })
            );
            break;
            default:
                break;
        }
      }

    return (
    <ExhibitorDocumentsWrap>
        <div className="head_wrap">
            <h3>{'Материалы раздела'}</h3>
            <button className="add_icon" >{addIcon}<label><input accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf" type={'file'} onChange={(e) => dispatch(addDocument({file: e.target.files[0], path: URL.createObjectURL(e.target.files[0]), show: true}))}/></label></button>
        </div>
        <div className="documents onPage">
            <div className="text_wrap">
                <p>{eyeIcon}{'На странице'}</p>
            </div>
            <div className="documents_array_wrap">
                    {companyDocuments.map((item, index) => {
                        if(item.remove) return ''
                        return (
                            <Fragment key={index}>
                            { item.show ?
                            <ExhibitordocumentsCard>
                                <div className="wrap_repInfo">
                                {pdfIcon}
                                    <div>
                                    {item.id ?
                                    <>
                                        <p className="rep_name">{item.name}</p>
                                        <p className="rep_size">{changeSizeFile(item.size)}</p>
                                    </>
                                        :
                                        <>
                                        <p className="rep_name">{getInfoFile(item.file).name}</p>
                                        <p className="rep_size">{getInfoFile(item.file).size}</p>
                                        </>
                                    }
                                    </div>
                                </div>
                                <button className="dots-settings" onClick={handlerActions("contextMenu", {...item, index})}>{pointsIcon}</button>
                            </ExhibitordocumentsCard>
                            :
                            null
                            }
                            </Fragment>
                        )
                    })}
                </div>
        </div>
        <div className="documents hover">
            <div className="text_wrap">
                <p>{crossEyeIcon}{'Скрытые'}</p>
            </div>
            <div className="documents_array_wrap">
                    {companyDocuments.map((item, index) => {
                        if(item.remove) return ''
                        return (
                            <Fragment key={index}>
                            { !item.show ?
                            <ExhibitordocumentsCard>
                            <div className="wrap_repInfo">
                                {pdfIcon}
                                    <div>
                                    {item.id ?
                                    <>
                                        <p className="rep_name">{item.name}</p>
                                        <p className="rep_size">{changeSizeFile(item.size)}</p>
                                    </>
                                        :
                                        <>
                                        <p className="rep_name">{getInfoFile(item.file).name}</p>
                                        <p className="rep_size">{getInfoFile(item.file).size}</p>
                                        </>
                                    }
                                    </div>
                                </div>
                                <button className="dots-settings" onClick={handlerActions("contextMenu", {...item, index})}>{pointsIcon}</button>
                            </ExhibitordocumentsCard>
                            :
                            null
                            }
                            </Fragment>
                        )
                    })}
                </div>
        </div>
    </ExhibitorDocumentsWrap>
    )
}