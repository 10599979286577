import styled from "styled-components";
import backgroundTest from "../../../assets/Components/Account/images/background-settings.png";

const WrapSettings = styled.div`
  padding-top: 74px;
  padding-bottom: 100px;
  background: #ffffff;
  height: 100vh;

  .divider-tab {
    height: 0.5px;
    background: #d4d4d4;
    margin: 30px 0;
  }

  .settings-banner {
    position: relative;
    height: 106px;
    margin-bottom: 130px;
    background: url(${backgroundTest});
    background-repeat: no-repeat;
    background-size: cover;

    .banner-user-info {
      position: absolute;
      left: 50%;
      top: 50px;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: column;
      align-items: center;

      .avatar_wrap{
        position: relative;
        height: 111px;
        :hover{
            label{
                visibility: visible;
            }
        }
      img {
        width: 111px;
        height: 111px;
        border-radius: 50%;
      }
      input{
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            left: 0;
            display: none;
      }
      label{
        display: block;
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.6);
        visibility: hidden;
        div{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            svg{
                width: 26px;
                height: 26px;
            }
            p{
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
            }
        }
      }
      }

      h2 {
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        color: #363636;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-transform: capitalize;
        color: #484848;
      }
    }
  }
`;

const WrapContentTab = styled.div`
  max-width: 700px;
  margin: auto;

  form {
    .form-fields {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    .form-btns {
      display: flex;
      justify-content: center;
      padding-top: 60px;
      gap: 10px;
      button {
        width: 186px;
        height: 36px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
      }

      .btns__cancel {
        background: #ffffff;
        color: #34c57f;
        border: 1px solid #34c57f;
      }
      .btns__submit {
        background: #34c57f;
        color: #ffffff;
      }
    }
  }

  .security__close {
    width: 186px;
    height: 36px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background: #ffffff;
    color: #34c57f;
    border: 1px solid #34c57f;
    display: block;
    margin: auto;
    margin-top: 58px;
  }

  .content-security {
    display: flex;
    gap: 16px;

    .security-elem {
      width: 340px;
      p {
        display: inline-block;
      }

      .elem__head {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 7px;

        p:first-child {
          font-weight: 700;
          font-size: 13px;
          line-height: 110%;
          color: #363636;
        }
        p:last-child {
          font-weight: 500;
          font-size: 12px;
          line-height: 110%;
          color: #34C57F;
          cursor: pointer;
        }
      }

      .elem__text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 36px;
          background: #F5F5F5;
          border-radius: 4px;
          padding: 10px;

          p {
            font-size: 14px;
            line-height: 110%;
            color: #363636;
          }

          button {
            width: 73px;
            height: 22px;
            background: #ffffff;
            border: 1px solid #34c57f;
            border-radius: 6px;
            padding: 4px 10px;
            cursor: pointer;
            font-size: 12px;
            line-height: 110%;
            color: #34C57F;
            font-family: 'Gilroy';
          }
      }
    }
  }

`;

const FieldWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-basis: ${({ grow }) => (grow ? `calc(100% / ${grow} - 8px)` : "100%")};

  label {
    font-weight: 700;
    font-size: 13px;
    line-height: 110%;
    color: #363636;
    padding-bottom: 5px;
  }

  input {
    height: 36px;
    background: #f5f5f5;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 14px;
    line-height: 110%;
    color: #363636;
  }

  .field__error-mes {
    position: absolute;
    bottom: -1px;
    transform: translateY(100%);
    font-size: 10px;
    color: #ff7575;
  }

  label[required]::after {
    content: "*";
  }

  .field__help-elem {
    position: absolute;
    right: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 110%;
    color: #34c57f;
    cursor: pointer;
  }
`;

const SettingsComponentWrap = styled.div`
    margin: 34px auto 0;
    width: 696px;
  h3{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
  }
  .settings_text{
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        color: #363636;
    }

`

const AllSettingsContent = styled.div`
    .news_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        padding: 16.5px 0;
    }
    .favorites_exhibition_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        justify-content: space-between;
        padding: 16.5px 0;
        margin-bottom: 36px;
    }
`
const ChatSettingsContent = styled.div`
    .chat_message_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        padding: 16.5px 0;
    }
    .chat_request_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        justify-content: space-between;
        padding: 16.5px 0;
        margin-bottom: 36px;
    }
`;

const RequestsSettingsContent = styled.div`
    .new_requests_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        padding: 16.5px 0;
    }
    .discard_requests_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        justify-content: space-between;
        padding: 16.5px 0;
    }
    .success_requests_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        justify-content: space-between;
        padding: 16.5px 0;
        margin-bottom: 36px;
    }
`;

const MeetsSettingsContent = styled.div`
    .meets_start_notification{
        border-top: 0.5px solid #D4D4D4;
        display: flex;
        margin-top: 6px;
        justify-content: space-between;
        padding: 16.5px 0;
    }
    select{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        color: #363636;
        min-width: 175px;
        height: 34px;
        background: #F6F6F6;
        border-radius: 4px;
        border: none;
        outline: none;
    }
`

const SettingsComponentContainer = styled.div`
    background: white;
    .form-btns {
      display: flex;
      justify-content: center;
      padding: 58px 0 ;
      gap: 10px;
      button {
        width: 186px;
        height: 36px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
      }

      .btns__cancel {
        background: #ffffff;
        color: #34c57f;
        border: 1px solid #34c57f;
      }
      .btns__submit {
        background: #34c57f;
        color: #ffffff;
      }
    }
`;

export { 
    WrapSettings, WrapContentTab, 
    FieldWrap, SettingsComponentWrap, 
    AllSettingsContent, ChatSettingsContent,
    RequestsSettingsContent, MeetsSettingsContent,
    SettingsComponentContainer
};
