import styled from "styled-components";

const TechSupportWrap = styled.div`
 padding: 0 16px;
    margin-top: 24px;
    margin-bottom: 30px;
    .Invalid_Input{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
    .SupportBtn{
        display: flex;
    }
`;
const InputFields = styled.div`
    p{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
    }
    div{
        margin-bottom: 23px;
    }
    textarea{
    width: 100%;
    height: 83px;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    resize: none;
    font-family: 'Gilroy',sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 9px 12px;
    }
`;

const TechSupportBtn = styled.button`
    width: 50%;
    background: #34C57F;
    border-radius: 8px;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #FFFF;
    cursor: pointer;
    margin-right: 8px;
    height: 36px;
`;
export {InputFields, TechSupportBtn, TechSupportWrap}