import styled from "styled-components";

const WrapLeftBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    
`;


export {
    WrapLeftBlock,
}