import styled from "styled-components";

const WrapPavilions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #363636;
  background: #ffffff;
  padding: 40px 0;

  .wrap__subheader {
    max-width: 584px;
    color: #484848;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    padding: 10px 0 22px 0;
  }

  .pavilions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -16px;
    margin-top: -16px;
    max-width: 2000px;
  }

  .pavilion {
    width: 356px;
    height: 394px;
    padding: 16px 0 0 16px;
  }

  .pavilion__img {
    width: 100%;
    max-height: 196px;
    display: block;
  }

  .pavilion-desc {
    background: #f5f5f5;
    padding: 16px;
  }

  .desc__header {
    font-weight: 700;
    font-size: 14px;
    line-height: 110%;
    padding-bottom: 3px;
  }

  .desc__name {
    padding-bottom: 12px;
    line-height: 120%;
    font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 14px;
  }

  .desc-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
  }

  .action__updated{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    color: #363636;
  }

  .action__updated span {
    font-weight: 600;
    font-size: 12px;
    line-height: 110%;
    color: #363636;
  }

  .action__view {
    display: flex;
    align-items: center;
  }

  .action__view p {
    padding-left: 5px;
  }

  .desc-btns {
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
  }

  .desc-btns button {
    width: 149px;
    height: 36px;
    border-radius: 8px;
  }

  .desc-btns button:hover {
    cursor: pointer;
  }

  .btns__materials {
    border: 1px solid #34c57f;
    background: #ffffff;
    color: #34c57f;
    font-weight: 500;
font-size: 13px;
line-height: 140%;
  }

  .btns__3D-stand {
    border: none;
    background: #34c57f;
    color: #ffffff;
    font-weight: 500;
font-size: 13px;
line-height: 140%;
  }

  .backgroud-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.6;
    z-index: 1;
  }
`;
export { WrapPavilions };
