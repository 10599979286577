import React, { useMemo, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrapStatistics } from "./statistics.style";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import AppSelect from "../../../components/AppSelect/AppSelect";
import Statistics from "./Statistics";
import { getDateAgo, viewDatePeriod } from "../../../utils/helpers";
import { getStatisticsCompany } from "../../../store/profileSlice/profileAsyncThunk";
import { setAppState } from "../../../store/appSlice/appSlice";
import downloadFile from "../../../services/downloadFile.service";

export const StatisticsContainer = () => {
  const statistics = useSelector((state) => state.profile.statistics);
  const localText = useSelector(
    (state) => state.app.appText.profile.statistics
  );
  const dispatch = useDispatch();
  let todayDate = new Date().toISOString();
  let {
    current: { defDate },
  } = useRef({
    defDate: {
      dateFrom: getDateAgo({ number: 1 }).toISOString(),
      dateTo: todayDate,
    },
  });
  let datePickerRef = useRef({});
  const [{ selectDate }, setLocalState] = useState({
    selectDate: {
      id: 1,
      dateFrom: defDate.dateFrom,
      dateTo: defDate.dateTo,
      view: viewDatePeriod(defDate.dateFrom, defDate.dateTo),
    },
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const handlerActions = (key, data) => (e) => {
    let dateFrom, dateTo, id, view;
    switch (key) {
      case "changePeriod":
        if (e.id === 5) {
          dispatch(
            setAppState({
              modal: {
                type: "periodStatistics",
                withChildren: "registrSuccess",
                head: localText.periodSelection,
                submitText: localText.apply,
                cancel: e.setPrevSelect,
                submit: handlerActions("applySelectedPeriod", e.setSelectValue),
              },
            })
          );
          return;
        }
        let selectPeriod = settingsSelect.options.find(
          (elem) => elem.id === e.id
        );
        dateFrom = selectPeriod.date.dateFrom;
        dateTo = selectPeriod.date.dateTo;
        id = selectPeriod.id;
        if (id === selectDate.id) return;
        view = viewDatePeriod(dateFrom, dateTo);
        setLocalState((state) => ({
          ...state,
          selectDate: {
            id,
            dateFrom,
            dateTo,
            view,
          },
        }));
        break;
      case "applySelectedPeriod":
        dateFrom = datePickerRef.current.startDate;
        dateTo = datePickerRef.current.endDate;
        id = 5;
        view = viewDatePeriod(dateFrom, dateTo);
        setLocalState((state) => ({
          ...state,
          selectDate: {
            id,
            dateFrom,
            dateTo,
            view,
          },
        }));
        data(view);
        break;
      case "downloadReport":
        downloadFile({
          type: "excel",
          data: {
            dateFrom: selectDate.dateFrom,
            dateTo: selectDate.dateTo,
            name: `${selectDate.view}.xlsx`,
          },
        });

      default:
        break;
    }
  };

  const settingsSelect = {
    options: [
      {
        id: 1,
        value: localText.today,
        date: defDate,
      },
      {
        id: 2,
        value: localText.week,
        date: {
          dateFrom: getDateAgo({ number: 7 }).toISOString(),
          dateTo: todayDate,
        },
      },
      {
        id: 3,
        value: localText.month,
        date: {
          dateFrom: getDateAgo({ type: "month" }).toISOString(),
          dateTo: todayDate,
        },
      },
      {
        id: 4,
        value: localText.year,
        date: {
          dateFrom: getDateAgo({ type: "year" }).toISOString(),
          dateTo: todayDate,
        },
      },
      {
        id: 5,
        value: localText.setPeriod,
      },
    ],
  };

  const getDataForBar = (statistics) => {
    let { visits, clicks, uniqueVisits, downloads } = statistics || {};
    const datasets = {
      label: "Посещений",
      backgroundColor: "rgba(52, 197, 127, 0.8)",
      borderRadius: 4,
    };

    const mapToDate = (list) =>
      list?.map((click) => new Date(click.timestamp).toLocaleDateString());
    const mapToData = (list, key) =>
      list?.map((uniqueVisit) => uniqueVisit[key]);

    const createData = (dataArray, dataKey, dataDatasets) => ({
      labels: mapToDate(dataArray),
      datasets: [
        {
          ...datasets,
          ...dataDatasets,
          data: mapToData(dataArray, dataKey),
        },
      ],
    });

    return {
      data1: createData(visits, "visits"),
      data2: createData(clicks, "clicks"),
      data3: createData(uniqueVisits, "visits"),
      data4: createData(downloads, "downloaded", { label: "Загрузок" }),
    };
  };

  let dataMemo = useMemo(() => getDataForBar(statistics), [statistics]);

  const getOptionsForBar = (statistics) => {
    let { visits, clicks, uniqueVisits, downloads } = statistics || {};
    const optionsBase = {
      responsive: true,
      maintainAspectRatio: false,

      scales: {
        x: {
          ticks: { display: false },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          position: "top",
          align: "start",
        },
      },
    };

    const createOptions = (textArray, textKey, textHead) => ({
      ...optionsBase,
      plugins: {
        ...optionsBase.plugins,
        title: {
          ...optionsBase.plugins.title,
          text: `${textHead}: ${
            textArray?.reduce((prev, next) => prev + next[textKey], 0) || 0
          }`,
        },
      },
    });

    return {
      options1: createOptions(visits, "visits", "Страницу посетили"),
      options2: createOptions(clicks, "clicks", "Стенд посетили"),
      options3: createOptions(uniqueVisits, "visits", "Уникальные посетили"),
      options4: createOptions(downloads, "downloaded", "Документов загружено"),
    };
  };

  let optionsMemo = useMemo(() => getOptionsForBar(statistics), [statistics]);

  useEffect(() => {
    dispatch(getStatisticsCompany(selectDate));
  }, [dispatch, selectDate]);

  return (
    <Statistics
      optionsMemo={optionsMemo}
      dataMemo={dataMemo}
      handlerActions={handlerActions}
      settingsSelect={settingsSelect}
      localText={localText}
      datePickerRef={datePickerRef}
    />
  );
};

export default StatisticsContainer;
