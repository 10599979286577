import styled, { css } from "styled-components";

const WrapMaterials = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 36px);
  top: 75px;
  z-index: 1000;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  max-width: 1920px;

  .full-screen-btn {
    position: absolute;
    bottom: 3px;
    right: 10px;
    z-index: 1;
    background: transparent;
    border: none;
    outline: none;
    & > svg {
        width: 32px;
        height: 32px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  .materials-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    max-height: 57px;
  }

  .header-text {
    width: calc(50% - 190px);
    h3 {
      font-weight: 700;
      font-size: 13px;
      line-height: 110%;
    }
    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 110%;
    }
  }

  .header-btns {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 200px;
    right: 200px;
  }

  .header-btns button {
    border-radius: 14.1511px;
    background: #efefef;
    backdrop-filter: blur(13.2666px);
    height: 32px;
    color: #363636;
    margin: 0 4px;
    padding: 2px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    &.btns__imgs {
      .img-icon {
        path {
          fill: #363636;
        }
      }
    }
  }

  .header-btns .btns__imgs_active {
    background: #34c57f;
    color: #ffffff;
    .video-play-icon {
      path {
        stroke: white;
        fill: #ffffff;
      }
    }
    .cube-3d {
      path {
        stroke: #ffffff;
      }
    }
  }

  .header-btns button svg {
    margin-right: 6px;
  }

  .header__close {
    display: flex;
    align-items: center;
    height: 24px;
  }
  .header__close {
    display: flex;
    align-items: center;
    height: 24px;
    font-weight: 600;
    font-size: 13px;
    line-height: 110%;
    color: #363636;
  }

  .close__icon {
    display: flex;
    cursor: pointer;
    padding-left: 9px;
  }

  .slider {
    display: flex;
    flex-direction: column;
    background: #aac3d9;
  }

  .material {
    position: relative;
    height: 670px;
    display: inline-block;
    margin: auto;
  }

  .material__img {
    height: 100%;
  }

  .material__video {
    height: 100% !important;
    width: 1130px !important;
  }

  .material-desc {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 325px;
    height: 159px;
    background: #ffffff;
    padding: 11px 16px;
  }
  .Btns_materials_container {
    display: flex;
    align-items: center;
    margin-top: 13px;
    gap: 10px;
  }
  .material-desc_bookConsult {
    border: 1px solid #34c57f;
    border-radius: 8px;
    color: #34c57f;
    background: #ffffff;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    height: 30px;
    width: 100%;
    cursor: pointer;
  }

  .material-desc .divider {
    height: 2px;
    margin: 10px 0;
  }

  .material-desc__name {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
  }

  .material-desc__title {
    font-weight: 700;
    font-size: 12px;
    line-height: 110%;
  }

  .material-desc__text {
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #363636;
    letter-spacing: -0.6px;
    padding-top: 4px;
  }

  .material-desc__more-btn {
    width: 100%;
    height: 30px;
    background: #f4f4f4;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    padding: 5px 12px;
    justify-content: space-between;
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    cursor: pointer;
  }

  .wrap-materials {
    display: flex;
    flex-direction: column;
    height: 670px;
  }

  ${(props) =>
    props.fullScreen &&
    css`
      width: 100%;
      max-width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: translateX(0);
      .material {
        height: 100%;
      }
      .material__img {
        height: auto;
        max-width: 100%;
      }
      .material__video {
        height: 100% !important;
        width: 100% !important;
      }
      .wrap-materials {
        height: 100%;
        justify-content: center;
      }
      .slider-container {
        height: 100%;
        .slick-slider {
          height: calc(100% - 58px);
          display: flex;
          align-items: center;
          .slick-list {
            flex: 1;
            height: 100%;
            .slick-track {
              height: 100%;
              .slick-slide > div {
                height: 100%;
                .slider {
                  height: 100%;
                }
              }
            }
            .material {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    `}
`;

const MaterialImgSliderContainer = styled.div`
  .slick-prev {
    left: 32px;
    z-index: 1;
    background: url(${(props) => props.arrL});
    background-size: 100%;
    top: 50%;
    width: 32px;
    height: 33px;
    ::before {
      content: none;
    }
    :hover {
      background: url(${(props) => props.arrL});
    }
    :focus {
      background: url(${(props) => props.arrL});
    }
  }
  .slick-next {
    right: 32px;
    top: 50%;
    background: url(${(props) => props.arrR});
    background-size: 100%;
    width: 32px;
    height: 33px;
    ::before {
      content: none;
    }
    :hover {
      background: url(${(props) => props.arrR});
    }
    :focus {
      background: url(${(props) => props.arrR});
    }
  }
  .slider {
    display: flex !important;
  }
`;

export { WrapMaterials, MaterialImgSliderContainer };
