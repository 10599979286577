
// SEO. Title must be the same as link in header!

export const contactus_by = {
    title: "Кантакты",
    h2: "З'явіліся пытанні? Звяжыцеся з намі!",
    commercialQuestions: 'Па арганізацыйных і камерцыйных пытаннях: ',
    technicalQuestions: 'Па тэхнічных пытаннях: ',
    sendMessage: 'Або адпраўце ваша паведамленне запоўніўшы форму ніжэй: ',
    name: "Ваша імя",
    email: "Ваш e-mail",
    subject: "Тэма паведамлення",
    message: "Тэкст паведамлення",
    alertTerms: "Трэба прыняць умовы выкарыстання",
    alertEmailValid: "Няправільны фармат Email",
    sent: "Адправіць",
    ph: "Кантакты",
    messageOk: "Паведамленне адпраулена",
    messageFail: "Памылка, паспрабуйце яшчэ раз",
    noEmail: "Email не увездены",
    noName: "Увядзіце Імя",
    noSubject: "Тэма не ўведзена",
    noMessage: "Увядзіце тэкст паведамлення",
    terms: {
        text: "Гэты сайт абаронены reCAPTCHA і Google",
        pp: "Палітыка прыватнасці",
        and: "і",
        ts: "Умовы выкарыстання",
        a: "",
    },
}
