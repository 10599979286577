import { AdminCreatePavilion, AdminGetUsers, CreateCompany, GetAdminExpanents, GetAdminPavilions, GetRoles, deleteAdminCompany, getCompanyInfo } from "./adminAsyncThunk"

export const extraReducers = (builder) => {
    builder.addCase(
        GetRoles.fulfilled(), (state, {payload}) => {
            state.roles = payload
        },
        GetRoles.rejected(), (state, {payload}) => {
            state.error = 'Bad request :('
        }
    )
    builder.addCase(
        GetAdminPavilions.fulfilled(), (state, {payload}) => {
            state.pavilions = payload.map((el, i) => ({...el, order: i + 1}))
        },
        GetAdminPavilions.rejected(), (state, {payload}) => {
            state.error = 'Bad request :('
        }
    )
    builder.addCase(
        GetAdminExpanents.fulfilled(), (state, {payload}) => {
            state.expanents = payload.companies
        },
        GetAdminExpanents.rejected(), (state, {payload}) => {
            state.error = 'Bad request :('
        }
    )
    builder.addCase(
        AdminGetUsers.fulfilled(), (state, {payload}) => {
            state.users = payload.content || []
        },
        AdminGetUsers.rejected(), (state, {payload}) => {
            state.error = 'Bad request :('
        }
    )
    builder.addCase(
        CreateCompany.fulfilled(), (state, {payload}) => {
            state.message = 'Компания создана успешно!'
            state.openModal = true
        },
        CreateCompany.rejected(),(state, {payload}) => {
            state.message = 'Произошла непредвиденная ошибка('
            state.openModal = true
        },
    )
    builder.addCase(
        AdminCreatePavilion.fulfilled(), (state, {payload}) => {
            state.message = 'Павильон успешно создан!'
            state.openModal = true
        },
    )
    builder.addCase(
        getCompanyInfo.fulfilled(), (state, {payload}) => {
            console.log(payload)
            state.createCompany = {
                nameKey: payload.companyNameKey || '',
                numberOfContract: payload.numberOfContract || '',
                email: payload.email || '',
                note: payload.note || '',
                pavilionsId: payload.pavilions.map((pavilion) => ({check: true, id: pavilion.id})) || [],
                name: {
                    ru: payload.companyTranslator.find((item) => item.language.languageCode === 'RU').companyName,
                    en: payload.companyTranslator.find((item) => item.language.languageCode === 'EN').companyName,
                    by: payload.companyTranslator.find((item) => item.language.languageCode === 'BY').companyName
                },
                user: payload.userTos[0] || {},
                contract: payload.contract ? {
                    ...payload.contract,
                    file: true
                } : {
                    file: ''
                }
            }
        }
    )
    builder.addCase(
        deleteAdminCompany.fulfilled(), (state, {payload}) => {
            state.expanents = state.expanents.filter((item) => item.url !== payload.companyName)
        }
    )
}