import React from 'react';
import { useSelector } from 'react-redux';
import { DescBlock, DescItem, DescItemHeader, DescItemText } from './style/descBlocks_css'


export const DescBlocks = () => {

    const {description: localText} = useSelector((state) => state.app.appText);

return (
    <DescBlock>
        <DescItem>
            <DescItemHeader><strong>{localText.AmountHeader1}</strong>{localText.Header1}</DescItemHeader>
            <div></div>
            <DescItemText>{localText.text1}</DescItemText>
        </DescItem>
        <DescItem>
            <DescItemHeader><strong>{localText.AmountHeader2}</strong>{localText.Header2}</DescItemHeader>
            <div></div>
            <DescItemText>{localText.text2}</DescItemText>
        </DescItem>
        <DescItem>
            <DescItemHeader><strong>{localText.AmountHeader3}</strong>{localText.Header3}</DescItemHeader>
            <div></div>
            <DescItemText>{localText.text3}</DescItemText>
        </DescItem>
    </DescBlock>
)
}