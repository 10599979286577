export const companiesPage_ru = {
    about: 'О компании',
    products: 'Продукция',
    services: 'Услуги',
    contacts: 'Контакты',
    shortText: 'Краткая информация о компании',
    partners: 'Партнеры',
    button: 'Для более детального знакомства с продукцией организации и общения с ее специалистами, приглашаем посетить действующий стенд виртуальной выставки',
    toStand: "на стенд",
    headerFiles: "Файлы",
    edit: 'Редактировать',
    addFileUserAcc: 'Файл добавлен в личный кабинет',
    repeatFileUserAcc: 'Файл с таким именем уже существует в личном кабинете',
}