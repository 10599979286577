import { HeadText } from "../AdminPanelContent/AdminPanelContent.style"


export const AdminHead = ({title, desc}) => {

    return(
        <HeadText>
            <h1>{title}</h1>
            <p>{desc}</p>
        </HeadText>
    )
}