import styled from "styled-components";

const AllNewsWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 16px 34px;
  button {
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    cursor: pointer;
  }
  .head_info_wrap {
    display: flex;
    justify-content: space-between;
    button {
      width: 182px;
      height: 34px;
      background: #34c57f;
      border: 1px solid #34c57f;
      border-radius: 8px;
      color: #ffffff;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
    }
  }
`;

const AllNewsContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 0.5px solid #d4d4d4;
  border-radius: 4px;
  margin-bottom: 17px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 6px;
  position: relative;
  .map_container {
    display: flex;
    flex-direction: column;
    height: inherit;
    gap: 12px;
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px 8px 16px 16px;
  }
`;

const AllNewsCard = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  height: 54px;
  width: 100%;
  justify-content: space-between;
  padding-right: 12px;
  border-radius: 0px 4px 4px 0px;
  .content_card_wrap {
    display: flex;
  }
  .preview_container {
    width: 93px;
    height: 54px;
    display: flex;
    align-items: center;
    img {
      object-fit: cover;
      width: inherit;
      height: inherit;
      border-radius: 4px 0px 0px 4px;
    }
    p {
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: #363636;
      padding-left: 12px;
      text-align: center;
    }
  }
  .info_news_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
    .name_news {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #363636;
    }
    .date_create {
      height: inherit;
      display: flex;
      align-items: center;
      .head_date {
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        color: #363636;
      }
      .date_text {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #363636;
        padding-left: 5px;
      }
    }
  }
  .card_btns_control {
    display: flex;
    gap: 6px;
    button {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #ebebeb;
      border-radius: 10px;
      .trash_icon {
        path {
          fill: #363636;
        }
      }
      svg {
        display: block;
        width: 17px;
        height: 17px;
      }
    }
  }
`;

export { AllNewsWrap, AllNewsContainer, AllNewsCard };
