import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppMessage } from "../../../components/AppMessage/AppMessage";
import { AdditContent, BlockCopyright, StyledCopyright } from "../../Footer/style/footer_css"
import { TechSupport } from "../../TechSupport/TechSupport";
import { AccFooterWrap } from "./AccFooter.style"


export const AccFooter = ({position}) => {
    const {footer: localText} = useSelector((state) => state.app.appText);
    const [TechSupportActive, setTechSupportActive] = useState(false)
    const {messageHint} = useSelector((state) => state.app)
    const {editCompany} = useSelector((state) => state.admin)

    return (
        <AccFooterWrap style={{position: position}}>
        {!TechSupportActive ? null : <TechSupport setTechSupportActive={setTechSupportActive}/>}
            <BlockCopyright>
            <StyledCopyright>© 2023 СleVR. {localText.rights}</StyledCopyright>
            </BlockCopyright>
            <AdditContent>
                    <Link style={{textDecoration: 'none', color: '#FFF'}} to={'/agreement'} target={'_blank'}><p style={{cursor: 'pointer'}}>Политика конфиденциальности</p></Link>
                    <a href={'https://dev.files.clevr.by/files/clevr/Типовой договор.pdf'} target='_blank' rel="noreferrer"><p style={{cursor: 'pointer'}}>Договор оферты</p></a>
                    <p className='TechSupport' style={{cursor: 'pointer'}} onClick={() => setTechSupportActive(true)}>Техподдержка</p>
                </AdditContent>
                {messageHint && editCompany.open && <AppMessage/>}
        </AccFooterWrap>
    )
}