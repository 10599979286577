import {Formik} from "formik";
import {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {svg_icons} from "../../assets/imgs/svg_icons";
import {SendTechSupportQuestion} from "../../store/profileSlice/profileAsyncThunk";
import {TechSupportSchema} from "../../validation/validation";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  RegInput,
  Title,
} from "../Registration/Login/style/Login_css";
import {GoBack} from "../Registration/style/Registration_css";
import {
  InputFields,
  TechSupportBtn,
  TechSupportWrap,
} from "./style/TechSupport.style";

const initialValues = {
  name: "",
  email: "",
  topic: "",
  message: "",
};

export const TechSupport = ({setTechSupportActive}) => {
  const dispatch = useDispatch();
  const {
    forms: {base, support},
  } = useSelector((state) => state.app.appText);

  const {exitIcon} = svg_icons;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <TechSupportWrap>
          <CloseButton
            style={{margin: "-8px 16px 0 0", right: "0"}}
            onClick={() => setTechSupportActive(false)}
          >
            {exitIcon}
          </CloseButton>
          <Title style={{marginLeft: "30px", marginBottom: "20px"}}>
            {support.head}
          </Title>
          <Formik
            initialValues={initialValues}
            validationSchema={TechSupportSchema}
            onSubmit={(value) => {
              const SendValues = {
                name: value.name,
                email: value.email,
                subject: value.topic,
                message: value.message,
                captcha: "",
              };
              dispatch(SendTechSupportQuestion(SendValues));
              setTechSupportActive(false);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleSubmit,
              dirty,
              isValid,
            }) => (
              <Fragment>
                <InputFields>
                  <div className="InputName">
                    <p>{base.firstName}</p>
                    <RegInput
                      name="name"
                      placeholder={base.firstNamePl}
                      onChange={handleChange}
                    />
                    {(errors.name && values.name) ||
                    (errors.name && touched.name) ? (
                      <p className="Invalid_Input">{errors.email}</p>
                    ) : null}
                  </div>
                  <div className="InputEmail">
                    <p>Email*</p>
                    <RegInput
                      name="email"
                      placeholder={base.emailPl}
                      onChange={handleChange}
                    />
                    {(errors.email && values.email) ||
                    (errors.email && touched.email) ? (
                      <p className="Invalid_Input">{errors.email}</p>
                    ) : null}
                  </div>
                  <div className="InputTopic">
                    <p>{support.theme}*</p>
                    <RegInput
                      name="topic"
                      placeholder={support.themePl}
                      onChange={handleChange}
                    />
                    {(errors.topic && values.topic) ||
                    (errors.topic && touched.topic) ? (
                      <p className="Invalid_Input">{errors.topic}</p>
                    ) : null}
                  </div>
                  <div className="InputTopic">
                    <p>{support.message}*</p>
                    <textarea
                      name="message"
                      placeholder={support.messagePl}
                      onChange={handleChange}
                    />
                    {(errors.message && values.message) ||
                    (errors.message && touched.message) ? (
                      <p className="Invalid_Input">{errors.message}</p>
                    ) : null}
                  </div>
                </InputFields>
                <div className="SupportBtn">
                  <GoBack
                    style={{marginTop: "0px", width: "50%"}}
                    onClick={() => setTechSupportActive(false)}
                  >
                    {base.cancel}
                  </GoBack>
                  <TechSupportBtn
                    disabled={!(dirty && isValid)}
                    style={dirty && isValid ? {opacity: "1"} : {opacity: "0.5"}}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {support.send}
                  </TechSupportBtn>
                </div>
              </Fragment>
            )}
          </Formik>
        </TechSupportWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
