import React, { useState } from "react";
import * as s_c from "./style/getin_css";
import JoinImg from "../../../assets/Components/Routes/MainPage/MobileApp/JoinImg.png";
import { FollowModal } from "../../Follow/FollowModal/FollowModal";
import { ModalMessage } from "../../ModalMessage/ModalMessage";
import { useSelector } from "react-redux";

export const JoinUS = () => {
  const [isModal, setIsModal] = useState(false);
  const {mainPage: {joinUs: localText}} = useSelector((state) => state.app.appText);

  return (
    <s_c.JoinSection Bg={JoinImg}>
      <s_c.JoinWrapp>
        <p className="Join_head_text">{localText.head}</p>
        <s_c.JoinContent>
          <p className="Join_desc">{localText.subhead}</p>
          <button className="JoinBtn" onClick={() => setIsModal(true)}>
          {localText.join}
          </button>
        </s_c.JoinContent>
        {isModal ? <FollowModal setIsModal={setIsModal} /> : <></>}
        <ModalMessage />
      </s_c.JoinWrapp>
    </s_c.JoinSection>
  );
};
