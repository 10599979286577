import styled from "styled-components";

const JoinSection = styled.section`
    background: url(${props => props.Bg}) center no-repeat;
    background-size: cover;
`;

const JoinWrapp = styled.div`
    background: #00000078;
    height: 630px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 25px;
    @media (max-width: 660px) {
        flex-direction: column;
    }
    .Join_head_text{
        font-family: 'Helvetica';
        font-style: normal;
        max-width: 552px;
        font-weight: 700;
        font-size: 59px;
        line-height: 116%;
        text-transform: uppercase;
        -webkit-transition: all 0.4s linear 0s;
        -o-transition: all 0.4s linear 0s;
        transition: all 0.4s linear 0s;
        color: #fff;
        @media (max-width: 980px) {
            font-size: 50px;
        }
        @media (max-width: 920px) {
            font-size: 45px;
        }
        @media (max-width: 880px) {
            font-size: 40px;
        }
        @media (max-width: 660px) {
            text-align: center;
            padding-bottom: 24px;
            font-size: 35px;
        }
        @media (max-width: 480px) {
            font-size: 30px;
        }
        @media (max-width: 320px) {
            font-size: 25px;
        }
    }
    .Join_desc{
        max-width: 415px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #fff;
        padding-bottom: 31px;
        @media (max-width: 660px) {
            text-align: center;
        }
    }
    .JoinBtn{
        width: 142px;
        height: 34px;
        background: #34C57F;
        border: 1.4px solid #34C57F;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
    }
`;

const JoinContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    @media (max-width: 980px) {
        padding-left: 10px;
    }
    @media (max-width: 660px) {
        align-items: center;
    }
`;

export {JoinSection, JoinContent, JoinWrapp}