import { useSelector } from "react-redux";
import { serverUrl } from "../API/api";

export const useOpenExhibition = () => {

    const token = useSelector((state) => state.auth.token);

    let urlMove = serverUrl.includes("rest.online.clevr") ? "https://online.clevr-expo.com" : "https://dev.expo.clevr.by"

    return ({pavilionId, company, toUrl} = {}) => {
        let my_form,
        my_tb;

        my_form=document.createElement('FORM');
        my_form.name='myForm';
        my_form.method='GET';
        my_form.target='_blank';
        my_form.action= `${urlMove}${toUrl || ""}`;

        const createElemFrom = (name, value) => {
            my_tb=document.createElement('INPUT');
            my_tb.type='text';
            my_tb.name=name;
            my_tb.value=value;
            my_form.appendChild(my_tb);
        }

        if(pavilionId && company) {
            createElemFrom('pavilionId', pavilionId);
            createElemFrom('companyNameKey', company);
        }    
        if(token) createElemFrom('t', token);

        document.body.appendChild(my_form);
        my_form.submit()
        document.body.removeChild(my_form);
    }
}
