export const pavilionPage_en = {
    pavilion: 'Pavilion',
    descriptonPavilion: `XXV Belarusian Energy and Ecological Forum, Exhibition and Congress "Energy. Ecology. Energy Saving. Electro."
    The event is held on October 12-15, 2021. Location: Minsk, Pobediteley ave. 20/2, Football arena`,
    linkStand: 'Stand',
    linkPage: 'Page',
    linkPrev: 'Previous pavilion',
    linkNext: 'Next pavilion',
    linkPavilions: 'Back to choosing a pavilion',
}

export const pavilionsList_en = {
    pavilions: 'Pavilions',
    description: 'Exhibiting companies are divided into pavilions corresponding to the subject of their activities. One exhibitor can exhibit at several stands and, depending on the materials presented at the stands, be placed in several pavilions. Also, specialized pavilions can be created for events, exhibitions and conferences.',
}