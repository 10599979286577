import React from "react";
import { useImgs3DModels } from "./useImgs3DModels";


const Preview3DModels = ({company = {}, companyNameKey, model}) => {
    console.log(companyNameKey)
    console.log(model)

  let key = company.companyNameKey || companyNameKey;

    useImgs3DModels(key, model?.link)

  return (
    <>
            <div id={`KeyShotXR_${key}_${model?.link}`} style={{flex: 1}}></div>
    </>
  );
};

export default Preview3DModels;
