export const handlerLocalStorage = () => {

    const set = (key, value) => localStorage.setItem(key, value);

    const remove = (key) => localStorage.removeItem(key);

    const get = (key) => {
        if(key === 'language' && !(localStorage.getItem(key))) {
            set("language", "ru");
        }
        return localStorage.getItem(key);
        };

    return {get, set, remove}
}