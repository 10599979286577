import { createSlice } from "@reduxjs/toolkit";
import { handlerData } from "../../services/handlerData.service";
import { getParamFromUrl } from "../../utils/helpers";
import reducers from "./appReducers";
import { extraReducers } from "./appExtraReducers";

const { getAppText } = handlerData();

export const initialState = {
  appText: getAppText(),
  selectedTab: getParamFromUrl("tab"),
  message: '',
  messageErr: '',
  toast: {text: "", type: null},
  progress: false,
  contextMenu: null,
  messageHint: false,
  metaData: null,
  modal: {type: ''},
  fullScreen: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers,
  extraReducers
});

const appReducer = appSlice.reducer;

export const { setAppState, resetAppState, setAppTextLang,
openMessageHint, setMetaData, setToast } = appSlice.actions;

export default appReducer;
