import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { svg_icons } from "../../../../assets/imgs/svg_icons"
import { GetRoles } from "../../../../store/adminSlice/adminAsyncThunk"
import { AdminHead } from "../AdminHead/AdminHead"
import { ScrollBarWrap } from "../AdminPanelContent/AdminPanelContent.style"
import { SearchSortContent } from "../SearchSortContent/SearchSortContent"
import { AddNewRole } from "./AddNewRole/AddNewRole"
import { RoleBtnsWrap, RoleCard, UserRolesWrap, UsersRolesContainer } from "./Roles.style"

export const Roles = () => {
    const {addIcon, settingsIcon, trashIcon} = svg_icons
    const [openModal, setOpenModal] = useState(false)
    const {roles} = useSelector((state) => state.admin)
    const ShowRoles = (role) => {
        switch (role) {
            case 'ROLE_USER':
                return 'Пользователь'
            case 'ROLE_ADMIN':
                return 'Администратор CleVR'
            case 'ROLE_EXHIBITOR':
                return 'Администратор экспонента'
            case 'ROLE_REPRESENTATIVE':
                return 'Представитель экспонента'
            default:
                return 'Посетитель'
        }
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(GetRoles())
    }, [dispatch])
    return (
        <UserRolesWrap>
        <div className="head_info_wrap">
        <AdminHead 
        title={`Роли пользователей (${roles.length})`} 
        desc = {'Здесь вы можете добавить контакты и разделить контакты по отделам/подразделениям.'}
        />

        <button className="Add_Role" onClick={() => setOpenModal(true)}>{addIcon} Добавить роль</button>
        </div>
        <SearchSortContent page={'users-roles'} />
        <UsersRolesContainer>
            <ScrollBarWrap>
                <div className="map_container">
                {roles.map((role, index) =>{
                return (
                    <RoleCard key={index}>
                        <p className="head_role_text">{ShowRoles(role.name)}</p>
                        {/*<CreateDate>
                            <p className="role_desc">{'Дата создания:'}</p>
                            <p className="create_role_date">{'19 апреля 2022'}</p>
                        </CreateDate>*/}
                        <RoleBtnsWrap>
                            <button>{settingsIcon}</button>
                            <button className="btn_basket">{trashIcon}</button>
                        </RoleBtnsWrap>
                    </RoleCard>
                )
                }
                )}
                </div>
            </ScrollBarWrap>
        </UsersRolesContainer>
        {openModal ? <AddNewRole setOpenModal={setOpenModal}/> : null}
        </UserRolesWrap>
    )
}