import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useParams } from "react-router-dom"
import { serverUrl } from "../../../API/api"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

import { 
    DateNewsContainer, DescContainer, 
    NewsItemContent, NewsItemsContainer, 
    NewsItemWrap, NewsLogo, 
    ReadMore} 
    from "../NewsPage/style/newsPage_css"
import { 
    AuthorBlock,
    Date, FeedbackForm, 
    LogoContainer, MessengersBlock, OtherNews, 
    OtherNewsTitle, PhotoAuthor, SelectedNewsWrap, 
    TextAuthor, 
    WrapLogo, WrapNews, 
    WrapNewsContent, WrapNewsTitle 
} 
    from "./style/selectedNews_css"
import { GetNews, GetSelectNews } from "../../../store/newsSlices/newsAsyncThunk"
import { useState } from "react"
import { TechSupport } from "../../TechSupport/TechSupport"
import { dateToLocaleDateString } from "../../../utils/helpers"
import { NewsGallery } from "./NewsGallery/NewsGallery"
import { svg_icons } from "../../../assets/imgs/svg_icons"
import instWidget from '../../../assets/imgs/instWidget.svg'



export const SelectedNews = () => {

    const {news, selectNews} = useSelector((state) => state.news)
    const {language} = useSelector((state) => state.app.appText)
    const dispatch = useDispatch()
    const path = useParams()
    const [TechSupportActive, setTechSupportActive] = useState(false)
    const {width} = useWindowDimensions()
    const {facebookWidget, linkedInWidget, gmailWidget} = svg_icons

    useEffect(() => {
        dispatch(GetSelectNews(path.id))
        dispatch(GetNews({searchParam: '', size: 5}))
    }, [dispatch, path.id, language])
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if(!selectNews){
    return null
  }

  const findTranslatorNews = (type, translators) => {
    switch (type) {
      case "name":
        return translators.find(
          (item) => item.language.languageCode === language.toUpperCase()
        )?.name?.name;
      case "shortName":
        return translators.find(
          (item) => item.language.languageCode === language.toUpperCase()
        )?.shortName?.shortName;
      case "text":
        return translators.find(
          (item) => item.language.languageCode === language.toUpperCase()
        )?.text?.text;
        case "shortText":
        let text = translators.find(
            (item) => item.language.languageCode === language.toUpperCase()
          )?.text?.text;
        return text?.length > 70 ? text?.slice(0, 70) : text
      default:
        break;
    }
  } 

    let countNews = width > 1440 ? 5 : 4

    let InterestNews = news.filter((el) => el.id != path.id)


    return (
        <SelectedNewsWrap>
            <WrapNews>
            {selectNews.duplicate ?
            <LogoContainer>
                <WrapLogo width = {width} 
                Bg = {`${serverUrl}/api/media/download/${
                        selectNews.media.find((el) => el.preview)?.id
                      }`}
                      >
                </WrapLogo>
            </LogoContainer>
            : null
            }
                <div className="News_Title_Container">
                {selectNews?.newsTranslators ?<WrapNewsTitle>{findTranslatorNews("name", selectNews?.newsTranslators)}</WrapNewsTitle> : null}
                </div>
                <Date>
                    <p><strong>Добавлено:</strong>{' '}{dateToLocaleDateString(selectNews.time)}</p>
                    {/*{eye}{" "}
                    <p>12,3 тыс.</p>*/}
                </Date>
                <WrapNewsContent>
                    {selectNews?.newsTranslators ? <div dangerouslySetInnerHTML={{ __html: findTranslatorNews("text", selectNews?.newsTranslators)}}></div> : null}
                </WrapNewsContent>
                {
                    selectNews?.media?.length - 1 > 0 ?
                    <NewsGallery media={selectNews.media} />
                    :
                    null
                }
                <AuthorBlock>
                    <TextAuthor><strong>Текст:</strong> {selectNews.author}</TextAuthor>
                    <PhotoAuthor><strong>Фото:</strong> {selectNews.author}</PhotoAuthor>
                </AuthorBlock>
                <FeedbackForm>
                    <strong>Заметили неточность?</strong>
                    <p onClick={() => setTechSupportActive(true)}>Напишите нам</p>
                </FeedbackForm>
                {!TechSupportActive ? null : <TechSupport setTechSupportActive={setTechSupportActive} />}
                <MessengersBlock>
                    <a href={'https://www.instagram.com/clevr.expo'} target={'_blank'} rel={'noreferrer'}><div style={{marginRight: '16px'}}><img src={instWidget} alt="" /></div></a>
                    <a href='https://www.facebook.com/clevrexpo' target={'_blank'} rel={'noreferrer'}><div style={{marginRight: '16px'}}>{facebookWidget}</div></a>
                    <a href="mailto:vitosgroznuy@gmail.com"><div style={{marginRight: '16px'}}>{gmailWidget}</div></a>
                    <a href='https://www.linkedin.com/showcase/clevr.expo' target={'_blank'} rel={'noreferrer'}><div>{linkedInWidget}</div></a>
                </MessengersBlock>
                <OtherNews>
                    <OtherNewsTitle>Вам может быть интересно:</OtherNewsTitle>
                    <NewsItemsContainer>
                    {InterestNews.map((item, index) => index < countNews ?
                            <NewsItemWrap key={item.id}>
                            <Link to={`/news/${item.id}`}>
                 <NewsLogo>
                  <img
                    src={`${serverUrl}/api/media/download/${
                        item.media.find((el) => el.preview)?.id
                      }`}
                    alt={"News_Photo"}
                    height={196}
                  ></img>
                </NewsLogo>
                </Link>
                <NewsItemContent>
                <Link to={`/news/${item.id}`} style={{textDecoration: 'none'}}>
                  <h2 className="head_name_news">
                      {findTranslatorNews("shortName", item.newsTranslators)}
                  </h2>
                  </Link>
                                    <DateNewsContainer>
                                        <p><strong>Добавлено:</strong> {dateToLocaleDateString(item.time)}</p>
                                        {/*<ViewsContainer>
                                            {eye}{' '}
                                            <p>12,3 тыс.</p>
                                        </ViewsContainer>*/}
                                    </DateNewsContainer>
                                    <DescContainer>
                                    <div className="news_text_container">
                                    <div dangerouslySetInnerHTML={{ __html: findTranslatorNews("shortText", item.newsTranslators)}}></div>
                                    </div>
                                    <Link to={`/news/${item.id}`}><ReadMore>Читать больше</ReadMore></Link>
                                    </DescContainer>
                                 </NewsItemContent>
                        </NewsItemWrap>
                        : ''
                    )}
                    </NewsItemsContainer>
                </OtherNews>
            </WrapNews>
        </SelectedNewsWrap>
    )
}

