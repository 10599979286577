export const seoModal_by = {
    deleteTag: 'Выдаліць мета-тэг',
    createTag: 'Стварыць мета-тэг',
    updateTag: 'Абнавіць мета-тэг',
    title: 'Загаловак',
    description: 'Апісанне',
    send: 'Адправіць',
    delete: 'Выдаліць',
    contentType: 'Тып кантэнту',
    imageForSocialMedia: 'Малюнак для сацыяльных сетак',
    file: 'Файл',
    link: 'Спасылка',
    pc: 'Абярыце файл',
    url: 'Увядзіце спасылку',
}