import { useDispatch, useSelector } from "react-redux";
import { icons_svg } from "../../../imgs/icons.svg";
import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import arrowRight from "../../../../../../assets/Components/Routes/PavilionsList/arrow-right.png";
import arrowLeft from "../../../../../../assets/Components/Routes/PavilionsList/arrow-left.png";
import { useEffect, useState } from "react";
import { filesUrl } from "../../../../../../API/api";
import { MaterialImgSliderContainer } from "../previewMaterials.styles";
import { SendConsultCompany } from "../../../../../../store/profileSlice/profileAsyncThunk";
import { ModalMessage } from "../../../../../ModalMessage/ModalMessage";
import { SlickArrowLeft, SlickArrowRight } from "../../../../../SlickArrowComponent/SlickArrowComponent";
const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <SlickArrowLeft/> ,
  nextArrow: <SlickArrowRight/>,
  className: "Slick-test"
};

export const ImgSlider = () => {
  const { arrowList } = icons_svg;
  const { companyMedia, company } = useSelector((state) => state.pavilions);
  const [images, setImg] = useState([]);
  const { userData } = useSelector((state) => state.profile);
  const dispatch = useDispatch()
  // console.log(userData)
  useEffect(() => {
    const materialsImg = companyMedia.filter(
      (material) => material.type === "image"
    );
    setImg(materialsImg);
  }, [companyMedia]);

  const SendConsultation = (imageId) => {
    const data  = {
        id: company?.id,
        email: userData.email,
        imageId
    }
    dispatch(SendConsultCompany(data))
  }

  return (
    <MaterialImgSliderContainer arrR={arrowRight} arrL={arrowLeft} className="slider-container">
      <Slider {...settings} className="Slick-test">
        {images.map((image, index) => {
          return (
            <div className="slider" key={index}>
              <div className="material">
                <img
                  src={filesUrl + image.src}
                  className="material__img"
                  alt="material_img"
                ></img>
                <div className="material-desc">
                  <h3 className="material-desc__name">{image.name}</h3>
                  <div className="divider"></div>
                  <h4 className="material-desc__title">Описание</h4>
                  <p className="material-desc__text">
                  {image.description}
                  </p>
                  <div className="Btns_materials_container">
                  <button className="material-desc__more-btn">
                    Показать больше {arrowList}
                  </button>
                  {userData.firstName ? <button className="material-desc_bookConsult" onClick={() => SendConsultation(image.id)}>Консультация</button> : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      <ModalMessage />
    </MaterialImgSliderContainer>
  );
};
