export const editCompany_by = {
    imgVideo: "Малюнак або відэа(Youtube)",
    numbersFiles: "Колькасць файлаў",
    companyName: "Назва кампаніі",
    compOrganForm: "Форма арганізацыі кампаніі",
    abbreviation: "Абрэвіятура",
    headerColor: "Колер загалоўкаў",
    logo: "Лагатып",
    addLogo: "Дадаць лагатып",
    addMedia: "Дадаць медыя",
    addImage: "Дадаць малюнак",
    addVideo: "Дадаць відэа",
    addDoc: "Дадаць дакумент",
    linkImage: "Спасылка на малюнак",
    pcImage: "Малюнак з камп'ютара",
    linkLogo: "Спасылка на лагатып",
    pclogo: "Лагатып з камп'ютара",
    linkYoutube: "Спасылка з Youtube",
    pcDoc: "Дакумент з камп'ютара",
    url: "Уставіць спасылку",
    pc: "Выбраць з камп'ютара",
    aboutCompany: "Аб кампаніі",
    additionalInfoHeader: "Дадатковая інфармацыя (Загаловак)",
    additionalInfoText: "Дадатковая інфармацыя (Тэкст)",
    showCompany: "Паказваць кампанію",
    btnSave: "Захаваць",
    btnBack: "Назад",
    btnDelete: "Выдаліць",
    btnAdd: "Дадаць",
    btnOverwrite: 'Перазапісаць',
    sendData: "Адпраўка дадзеных",
    selectPavilions: "Выбар павільёна",
    headerImg: 'Малюнак',
    headerVideo: 'Відэа',
    modalDeleteCompany_text: 'Вы сапраўды хочаце выдаліць кампанію?',
    modalDeleteCompany_btnYes: 'Так',
    modalDeleteCompany_btnNo: 'Не',
    toolbar: {
        header:'Панэль інструментаў',
        addLinkBtn:'Дадаць спасылку',
        addLinkError:'Немагчыма ўставіць частку тэга ў іншы тэг',
    },
    limit: {
        imageSize: 'Памер малюнка не больш за 10 мб',
        logoSize: 'Памер лагатыпа не больш за 2 мб',
        docSize: 'Памер дакумента не больш 50мб',
        mediaNumber: 'Колькасць медыя файлаў не больш за 30',
        docNumber: 'Колькасць дакументаў не больш за 30',
    },
}