import styled from "styled-components";

const FollowUsSection = styled.section`
padding-top: 64px;
  min-height: 500px;
  background: url(${props => props.Bg}) center no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-color: #fff;

  @media (max-width: 540px) {
  }
  @media (max-width: 414px){
  }
`;

const WrapContentText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  h1 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 116%;
    max-width: 439px;
    text-align: center;
    color: #fff;
    padding-bottom: 10px;
  }

  p {
    max-width: 320px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    color: #fff;
    padding-bottom: 20px;
  }
`;

const FollowBtn = styled.button`
    font-family: 'Gilroy';
    width: 151px;
    height: 34px;
    background: #34C57F;
    border: 2px solid #34C57F;
    border-radius: 8px;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
font-size: 14px;
line-height: 140%;

  @media (max-width: 440px) {
  }
`;

export {FollowUsSection, FollowBtn, WrapContentText};
