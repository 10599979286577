import React from "react";
import { WrapDialogs, DialogsScroll, Dialog } from "./dialogs.style";
import { filesUrl } from "../../../../API/api";
import { svg_icons } from "../../../../assets/imgs/svg_icons";

const Dialogs = ({ handlerActions, dialogs }) => {
  const { pointsIcon } = svg_icons;

  if(!dialogs) return null;

  // avatar-def.svg

  return (
    <WrapDialogs>
      <DialogsScroll>
        {dialogs.map(
          dialog => (
            <Dialog key={dialog.dialogId} onClick={handlerActions("openDialog", dialog)}>
              {dialog.user.avatar ? <img src={`${filesUrl}${dialog.user.avatar}`} alt="user_photo" /> :
              <div className="dialog-default-img">
                {dialog.user.firstName[0]}
              </div>
              }
              <div className="dialog-info">
                <p className="info__name">{dialog.user.firstName} {dialog.user.lastName}</p>
                {/* <p className="info__last-message">...</p> */}
              </div>
              <button
                className="dialog__more-info"
                onClick={handlerActions("contextMenu", dialog)}
              >
                {pointsIcon}
              </button>
            </Dialog>
          )
        )}
      </DialogsScroll>
    </WrapDialogs>
  );
};

export default Dialogs;
