import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {
  AddProductGroupWrap,
  BtnsProductGroupWrap,
} from "./addProductGroup.style";

export const AddProductGroup = ({setModalOpen, subcategoryName}) => {
  const {exitIcon} = svg_icons;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setModalOpen(false)}>
          {exitIcon}
        </CloseButton>
        <AddProductGroupWrap>
          <Title>{"Новая группа продукции"}</Title>
          <div className="class_subcategory_name">
            <p className="head_subcategory_name">{"Подкатегория:"}</p>
            <p className="head_subcategory_text">{subcategoryName}</p>
          </div>
          <div className="product_code">
            <p>{"Код группы"}</p>
            <input placeholder={""} />
          </div>
          <div className="product_name">
            <p>{"Название группы"}</p>
            <input placeholder={""} />
          </div>
          <div className="description_wrap">
            <p className="head_text">{"Примечание"}</p>
            <textarea />
          </div>
          <BtnsProductGroupWrap>
            <button className="back" onClick={() => setModalOpen(false)}>
              {"Отменить"}
            </button>
            <button className="send_btn">{"Сохранить"}</button>
          </BtnsProductGroupWrap>
        </AddProductGroupWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
