import styled from "styled-components";

const WrapMessages = styled.div`
  flex: 1;
`;

const MessagesView = styled.div`
  position: relative;
  height: calc(100% - 52px);
  background: #f5f5f5;
  border-radius: 4px;
  .messages-scroll {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3px;
    left: 0;
    right: 3px;
    bottom: 3px;
    padding: 13px 13px 13px 16px;
    overflow: auto;
    overflow-x: hidden;
  }
`;

const NewContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 316px;
  img {
    width: 81px;
    height: 81px;
    border: 1px solid #ffffff;
    border-radius: 50%;
  }
  .contact__name {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #363636;
    padding: 20px 0 12px 0;
  }
  .contact__allow-mes {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #363636;
    padding: 0 20px;
  }

  .contact-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding-top: 19px;
    padding-bottom: 30px;
    button {
      width: 150px;
      height: 32px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      &:first-child {
        color: #34c57f;
        border: 1px solid #34c57f;
      }
      &:last-child {
        background: #34c57f;
        color: #ffffff;
      }
    }
  }
`;

const WrapMessage = styled.div`
  background: ${({ file }) => (file ? "transparent" : "#e4eefd")};;
  border-radius: ${({ alignSelf,}) => alignSelf ? "16px 16px 0px 16px" : "16px 16px 16px 0px"};
  padding: ${({ file }) => (file ? 0 : "14px 16px 12px")};
  max-width: 70%;
  align-self: ${({ alignSelf }) => (alignSelf ? "flex-end" : "flex-start")};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  .message__time {
    padding-top: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 110%;
    color: #484848;
    opacity: 0.8;
  }

  .message__file {
    position: relative;
    max-width: 280px;
    max-height: 280px;
    min-width: 170px;
    min-height: 170px;
    background: black;
    overflow: hidden;
    border-radius: ${({ alignSelf }) => alignSelf ? "8px 8px 0px 8px" : "8px 8px 8px 0px"};
    display: flex;
    justify-content: center;
    align-items: center;

    &.icon {
        justify-content: space-between;
        max-width: initial;
        min-width: 1px;
        min-height: 1px;
        background: white;
        padding: 12px;
        padding-bottom: 32px;

        .file__desc {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #363636;
            padding: 0 55px 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            span {
                display: block;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: #484848
            }
        }

        .file__download-btn {
            width: 30px;
            height: 30px;
            background: #F4F4F4;
            border-radius: 8px;
            border: none;
            cursor: pointer;

            svg {
                vertical-align: middle;
            }
        }

        .file__time {
            position: absolute;
            bottom: 12px;
            left: 12px;
            font-weight: 500;
            font-size: 12px;
            line-height: 110%;
            color: #484848;
            opacity: 0.8;
        }
    }

    img,
    video {
      max-width: 280px;
      max-height: 280px;
      vertical-align: top;
    }


    .file-desc_backgrond {
        position: absolute;  
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 4px;
        padding: 6px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 12px;
        line-height: 110%;
    }

    .file-preview-time {
        bottom: 6px;
        left: 6px;
    }

    .file-preview-size {
        bottom: 6px;
        right: 6px;
    }

    .video_icon {
        top: 0;
        left: 0;

        p {
            padding-left: 5.5px;
        }
    }
  }
`;

export { WrapMessages, MessagesView, NewContact, WrapMessage };
