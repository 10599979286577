import React, {useState, useEffect} from "react";
import { WrapRightBox } from "./rightBox.style";
import HeaderRightBoxContainer from './HeaderRightBox/HeaderRightBoxContainer';
import MessagesContainer from "./Messages/MessagesContainer";

const RightBox = ({mobileVersion}) => {

    const [openMobileBox, setOpenMobileBox] = useState(false)

    useEffect(() => {
            setTimeout(() => {
                setOpenMobileBox(mobileVersion)
            }, 100);

    }, [mobileVersion])
   
    return <WrapRightBox mobile={mobileVersion} openMobileBox={openMobileBox}>
            <HeaderRightBoxContainer/>
            <MessagesContainer/>
    </WrapRightBox>
}

export default RightBox