import { createAsyncThunk } from "@reduxjs/toolkit";
import { serverUrl } from "../../API/api";
import { handlerLocalStorage } from "../../services/handlerLocalStorage.service";
import { getRequest } from "../../services/requests.services";
import { setAppChatState } from "../appChatSlice/appChatSlice";
import { handlerErrorsService } from "../../services/handlerErrors.service";

const { set } = handlerLocalStorage();

// Для обработки не стандартных ошибок использовать rejectWithValue!!!!!!!!!

export const sendLogin = createAsyncThunk(
  "auth/login",
  async (data, thunkAPI) => {
    const { UserApi } = getRequest(serverUrl);
    try {
        const response = (await UserApi.authUser(data));
        thunkAPI.dispatch(setAppChatState({key: "userId", value: response.id}))
        return response;
    } catch (error) {
        return await handlerErrorsService(error, thunkAPI)
    }
  }
);

const extraReducers = {
  [sendLogin.fulfilled]: (state, action) => {
    let { token, id, ...rest } = action.payload;
    set("token", token);
    set("userId", id);
    state.open = false;
    state.token = token;
    state.authData = rest;
  },
};

export default extraReducers;
