import styled from "styled-components";

const MainExhibitorPageWrap = styled.div`
width: 100%;
height: 100%;
padding: 2px;
    .map-container{
        position: absolute;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 13px 14px 16px 16px;
        height: 90%;
        .main_content{
            margin-bottom: 22px;
        }
        .other_content{
            border-top: 0.5px solid #D4D4D4;
            padding: 22px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                border: none;
                background: none;
                color: #34C57F;
                height: 15px;
                cursor: pointer;
            }
        }
    }
`;
const Title = styled.h3`
font-family: 'Gilroy';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 18px;
color: #363636;
padding-bottom: 4px;
`
const Desc = styled.p`
font-family: 'Gilroy';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
color: #363636;
max-width: 530px;
`

export {
    MainExhibitorPageWrap, Title, Desc
}