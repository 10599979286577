import styled from "styled-components";

const StyledContainer = styled.div`
  height: 804px;
  -webkit-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
    @media (max-width: 1200px) {
    height: 600px;
  }
  @media (max-width: 1025px) {
    height: 460px;
  }
  @media (max-width: 480px) {
    .slick-dots ul{
       display: none;
    }
    .slick-dots{
        margin-bottom: 30px;
    }
  }
  .slick-prev {
    left: 181px;
    z-index: 1;
    background-size: 100%;
    top: 50%;
    width: 32px;
    height: 33px;
    @media(max-width: 769px){
        display: none !important;
    }
    ::before{
        content: none;
    }
    :hover{
        .arrow-background{
            fill: white;
        }
        .arrow_1{
            stroke: #363636;
        }
        .arrow_2{
            stroke: #363636;
        }
    }
    }
  .slick-next {
    right: 181px;
    top: 50%;
    background-size: 100%;
    width: 32px;
    height: 33px;
    @media(max-width: 769px){
        display: none !important;
    }
    ::before{
        content: none;
    }
    :hover{
        .arrow-background{
            fill: white;
        }
        .arrow_1{
            stroke: #363636;
        }
        .arrow_2{
            stroke: #363636;
        }
    }
  }
  .slick-current{
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)) !important;
                    opacity: 1 !important;
                }
  .slick-slide{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
    opacity: 0.3;
  }
  .slick-dots li{
    width: 6px;
    margin: 0px 1px;
  }
  .slick-dots li button{
    width: 5px;
    height: 5px;
    background: gray;
    border-radius: 5px;
    padding: 2px;
    ::before{
        content: none;
    }
  }
 li.slick-active{
    width: 52px;
    .slick_dots_btn{
        width: 52px;
        background: #484848;
    }
  }
`;

const ImgBg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  z-index: -1;
`;


export {StyledContainer, ImgBg}