import { object, string, ref, date} from "yup";
import { handlerData } from "../services/handlerData.service";

const { getAppText } = handlerData();

export const RegSchema = object({
  surname: string()
    .min(2, getAppText().errorsValid.surname)
    .max(32, getAppText().errorsValid.surname)
    .required(getAppText().errorsValid.required)
    .matches(/^[a-zа-яё]+$/i, getAppText().errorsValid.surname),
  name: string()
    .min(2, getAppText().errorsValid.name)
    .max(20, getAppText().errorsValid.name)
    .required(getAppText().errorsValid.required)
    .matches(/^[a-zа-яё]+$/i, getAppText().errorsValid.name),
  patronymic: string()
    .min(2, getAppText().errorsValid.patronymic)
    .max(20, getAppText().errorsValid.patronymic)
    .matches(/^[a-zа-яё]+$/i, getAppText().errorsValid.patronymic),
  email: string()
    .email(getAppText().errorsValid.email)
    .required(getAppText().errorsValid.required),
  password: string()
    .min(8, getAppText().errorsValid.password)
    .required(getAppText().errorsValid.required)
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=\w+$)/,
      getAppText().errorsValid.passwordLat
    ),
  equalPass: string()
    .oneOf([ref("password"), null], getAppText().errorsValid.passwordEqual)
    .required(getAppText().errorsValid.required),
  tel: string(),
});

export const LoginSchema = object({
  email: string()
    .email(getAppText().errorsValid.email)
    .required(getAppText().errorsValid.required),
    password: string()
    .required(getAppText().errorsValid.required)
});

export const TechSupportSchema = object({
  name: string(),
  email: string()
    .email(getAppText().errorsValid.email)
    .required(getAppText().errorsValid.required),
  topic: string().max(50).required(getAppText().errorsValid.required),
  message: string().max(350).required(getAppText().errorsValid.required),
});

export const FollowSchema = object({
    CompanyName: string().required(getAppText().errorsValid.required),
    activity: string().required(getAppText().errorsValid.required),
    name: string()
    .min(2, getAppText().errorsValid.name)
    .max(20, getAppText().errorsValid.name)
    .required(getAppText().errorsValid.required)
    .matches(/^[a-zа-яё]+$/i, getAppText().errorsValid.name),
    lastName: string()
    .min(2, getAppText().errorsValid.surname)
    .max(32, getAppText().errorsValid.surname)
    .required(getAppText().errorsValid.required)
    .matches(/^[a-zа-яё]+$/i, getAppText().errorsValid.surname),
  email: string()
    .email(getAppText().errorsValid.email)
    .required(getAppText().errorsValid.required),
    tel: string(),
    comment: string().required(getAppText().errorsValid.required),
})


export const RequestsModalSchema = object({
    topic: string().max(50).required(getAppText().errorsValid.required),
    date: date().required(getAppText().errorsValid.required),
    startTime: string().required(getAppText().errorsValid.required),
    endTime: string().required(getAppText().errorsValid.required),
    message: string().max(350)
})

export const RestoreSchema = object({
    password: string().required(getAppText().errorsValid.required),
    NewPassword: string()
    .min(8, getAppText().errorsValid.password)
    .required(getAppText().errorsValid.required)
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=\w+$)/,
      getAppText().errorsValid.passwordLat
    ),
    EqualPassword: string()
    .oneOf([ref("NewPassword"), null], getAppText().errorsValid.passwordEqual)
    .required(getAppText().errorsValid.required),
})

export const ModalQuestionSchema = object({
    message: string().max(350).required(getAppText().errorsValid.required),
    name: string(),
    email: string()
    .email(getAppText().errorsValid.email)
    .required(getAppText().errorsValid.required),
})

export const RecoverSchema = object({
    email: string()
    .email(getAppText().errorsValid.email)
    .required(getAppText().errorsValid.required),
})
