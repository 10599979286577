import { useEffect } from "react"
import { AdminPavilions } from "./AdminPavilions"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { AdminCreatePavilion, GetAdminExpanents, GetAdminPavilions } from "../../../../store/adminSlice/adminAsyncThunk"
import { getDescription, getName } from "../../../../utils/helpers"
import { changePavilionsSequence } from "../../../../store/adminSlice/adminSlice"

export const AdminPavilionsContainer = () => {
    const localText = useSelector((state) => state.app.appText);

    const [localeState, setLocaleState] = useState({
        language: localText.language,
        searchPavilion: '',
        openAccess: false,
        openPavilion: false,
        currentDragPav: {},
        newPavilion: {
            nameRu: '',
            nameBy: '',
            nameEn: '',
            nameKey: '',
            priority: 0,
            descriptionRu: '',
            descriptionBy: '',
            descriptionEn: '',
            companies: [],
            show: false,
        }
    })

    const dispatch = useDispatch()

    const dragStartHandler = (e, pavilion) => {
        setLocaleState((state) => ({...state, currentDragPav: pavilion}))
    }

    const dragEndHandler = (e) => {

    }

    const dragOverHandler = (e) => {
        e.preventDefault()
    }

    const dropHandler = (e, pavilion) => {
        e.preventDefault()
        dispatch(changePavilionsSequence({dropPav: pavilion, currentPav: localeState.currentDragPav}))
    }

    const handlerActions = (key, data) => (e) => {
        switch (key) {
            case 'setDraggable':
                    switch (data.typeEv) {
                        case 'start':
                            dragStartHandler(e, data.pavilion)
                            break;
                        case 'end':
                        case 'leave':
                            dragEndHandler(e)
                        break;
                        case 'over':
                            dragOverHandler(e)
                        break;
                        case 'drop':
                            dropHandler(e, data.pavilion)
                        break;
                        default:
                            break;
                    }
                break;
            case 'setSearchPavilion':
                setLocaleState((state) => ({...state, searchPavilion: e.target.value}))
            break;
            case 'setOpenPavilion':
                setLocaleState((state) => ({...state, openPavilion: !state.openPavilion}))
            break;
            case 'setOpenAccess':
                setLocaleState((state) => ({...state, openAccess: !state.openAccess}))
                break;
            case "changeLang":
                setLocaleState((state) => ({ ...state, language: data }));
            break;
            case "setName": 
                setLocaleState((state) => ({...state, newPavilion: {
                    ...state.newPavilion, 
                    [getName(state.language)]: e.target.value 
                }
                }
                    ))
            break;
            case "setDescription":
                setLocaleState((state) => ({...state, newPavilion: { 
                    ...state.newPavilion,
                    [getDescription(state.language)]: e.target.value
                }
            }))
            break;
            case 'setShow':
                setLocaleState((state) => ({...state, newPavilion: {
                    ...state.newPavilion, 
                    show: e.target.checked
                }
            }))
            break;
            case 'setCompanies':
                setLocaleState((state) => ({...state, newPavilion: {
                    ...state.newPavilion, 
                    companies: state.checkBoxCompanies.reduce(
                    (prev, current) => (
                        current.check ? [...prev, current.id] : prev
                        ), []),
                    openAccess: false
                }
                    }))
            break;
            case 'sendNewPavilion':
                dispatch(AdminCreatePavilion(localeState.newPavilion))
                setLocaleState((state) => ({...state, openPavilion: false }))
            break;
            case "setNameKey":
                setLocaleState((state) => ({...state, newPavilion: { 
                    ...state.newPavilion,
                    nameKey: e.target.value }
                }))
            break;
            default:
                break;
        }
    }

    useEffect(() => {
        dispatch(GetAdminPavilions())
        dispatch(GetAdminExpanents(localeState.searchPavilion))
    }, [dispatch, localeState.searchPavilion])

    return (
        <AdminPavilions
        handlerActions={handlerActions} 
        localeState={localeState}/>
    )
}