import { Routes, Route } from "react-router-dom";
import React from "react";
import { routes } from "../../common/routes";
import { Account } from "../Account/Account";
import { PanelContent } from "../Account/UserContent/PanelContent";
import AppChatContainer from "../../components/AppChat/AppChatContainer";
import RightBoxContainer from "../../components/AppChat/RightBox/RightBoxContainer";
import { AdminContent } from "../Account/Admin/AdminContent/AdminContent";
import { useSelector } from "react-redux";

export const UseRoutes = (props) => {
    const { userData } = useSelector((state) => state.profile);
  return (
    <Routes>
      {routes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component {...props} />}
          />
        );
      })}



      <Route path={"/account"} element={<Account />}>
      { userData.role !== 'ROLE_ADMIN' ?
      <>
        <Route path={"chat"} element={<AppChatContainer/>}>
          <Route path=":chatId" element={<RightBoxContainer />} />
        </Route>
        <Route path={":content"} element={<PanelContent />}/>
        </>
        :
        <Route path={":content"} element={<AdminContent />}/>
      }
      </Route>


      
    </Routes>
  );
};
