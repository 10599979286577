import {useState} from "react";
import ReactPlayer from "react-player";
import {useDispatch} from "react-redux";
import {filesUrl} from "../../../../../API/api";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {AppSwitch} from "../../../../../components/AppSwitch/AppSwitch";
import {editMediaGallery} from "../../../../../store/exhibitorSlice/exhibitorSlice";
import {getInfoFile} from "../../../../../utils/helpers";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {BtnsWrap, CreateMedia} from "../AddGalleryFile/AddGalleryFile.style";
import {EditGalleryFileWrap} from "./editGalleryFile.style";

export const EditGalleryFile = ({setOpenEditFile, galleryItem, itemIndex}) => {
  const {exitIcon, trashIcon} = svg_icons;
  const checkYoutubeUrl =
    /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:[\w-=&]*&)?v=|(?:embed|v)\/))([\w-]+)[\w-=&#]*/;
  const [localeState, setLocaleState] = useState({
    image: {
      id: galleryItem.id || "none",
      name: galleryItem.name || "",
      description: galleryItem.description || "",
      src: galleryItem.src,
      file: galleryItem.file || "",
      consultation: galleryItem.consultation || false,
      type: galleryItem.type,
      itemIndex,
    },
    video: {
      id: galleryItem.id || "none",
      name: galleryItem.name || "",
      description: galleryItem.description || "",
      src: galleryItem.src,
      consultation: galleryItem.consultation || false,
      type: galleryItem.type,
      itemIndex,
    },
  });
  const dispatch = useDispatch();
  const ValidFormImg =
    (Boolean(localeState.image.file) || Boolean(localeState.image.src)) &&
    Boolean(localeState.image.name) &&
    Boolean(localeState.image.description);
  const ValidFormVideo =
    Boolean(localeState.video.src) &&
    Boolean(localeState.video.description) &&
    Boolean(localeState.video.name);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setOpenEditFile({key: -1, open: false})}>
          {exitIcon}
        </CloseButton>
        <EditGalleryFileWrap>
          <Title>{"Редактирование экспоната"}</Title>
          {galleryItem.type === "image" ? (
            <CreateMedia>
              <div className="preview">
                <p>{"Превью"}</p>
                {localeState.image.file || localeState.image.src ? (
                  <>
                    <img
                      src={
                        localeState.image.src
                          ? filesUrl + localeState.image.src
                          : URL.createObjectURL(localeState.image.file)
                      }
                      alt=""
                    />
                    <div className="file_info">
                      <div>
                        <p className="file_name">
                          {localeState.image.file
                            ? getInfoFile(localeState.image.file).name
                            : localeState.image.fileName
                            ? localeState.image.fileName
                            : "Белкоммунмаш.jpg"}
                        </p>
                        <p className="file_size">
                          {localeState.image.file
                            ? getInfoFile(localeState.image.file).size
                            : localeState.image.fileSize
                            ? localeState.image.fileSize
                            : "214 МБ"}
                        </p>
                      </div>
                      <button
                        className="delete_file"
                        onClick={() =>
                          setLocaleState((state) => {
                            return {
                              ...state,
                              image: {...state.image, src: "", file: ""},
                            };
                          })
                        }
                      >
                        {trashIcon}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="image_none_wrap">
                      <div className="file_wrap_none">
                        <label>
                          <p>{"Изображение не добавлено"}</p>
                          <input
                            type={"file"}
                            accept={"image/png, image/gif, image/jpeg"}
                            onChange={(e) =>
                              setLocaleState((state) => {
                                return {
                                  ...state,
                                  image: {
                                    ...state.image,
                                    file: e.target.files[0],
                                  },
                                };
                              })
                            }
                          />
                        </label>
                      </div>
                      <div className="file_info_none"></div>
                    </div>
                  </>
                )}
              </div>
              <div className="name_exponat">
                <p className="head_text">{"Название экспоната"}</p>
                <input
                  placeholder={"Введите название экспоната"}
                  value={localeState.image.name}
                  onChange={(e) =>
                    setLocaleState((state) => {
                      return {
                        ...state,
                        image: {...state.image, name: e.target.value},
                      };
                    })
                  }
                />
              </div>
              <div className="actions">
                <p className="head_text">{"Действия"}</p>
                <div className="switcher_wrap">
                  <p>{"Заказать консультацию"}</p>
                  <AppSwitch
                    onChange={(e) =>
                      setLocaleState((state) => {
                        return {
                          ...state,
                          image: {
                            ...state.image,
                            consultation: e.target.checked,
                          },
                        };
                      })
                    }
                  />
                </div>
              </div>
              <div className="description_wrap">
                <p>{"Описание к экспонату"}</p>
                <textarea
                  value={localeState.image.description}
                  onChange={(e) =>
                    setLocaleState((state) => {
                      return {
                        ...state,
                        image: {...state.image, description: e.target.value},
                      };
                    })
                  }
                />
              </div>
              <BtnsWrap>
                <button className="back" onClick={() => setOpenEditFile(false)}>
                  {"Отменить"}
                </button>
                <button
                  style={ValidFormImg ? {opacity: "1"} : {opacity: "0.5"}}
                  className="send_btn"
                  disabled={!ValidFormImg}
                  onClick={() => {
                    dispatch(editMediaGallery(localeState.image));
                    setOpenEditFile(false);
                  }}
                >
                  {"Сохранить"}
                </button>
              </BtnsWrap>
            </CreateMedia>
          ) : (
            <CreateMedia>
              <div className="preview">
                <p>{"Превью"}</p>
                {checkYoutubeUrl.test(localeState.video.src) ? (
                  <ReactPlayer
                    className={"preview_video"}
                    url={localeState.video.src}
                    alt=""
                    controls={true}
                  />
                ) : (
                  <>
                    <div className="image_none_wrap">
                      <div className="file_wrap_none">
                        <label>
                          <p>{"Неверный формат ссылки"}</p>
                        </label>
                      </div>
                      <div className="file_info_none"></div>
                    </div>
                  </>
                )}
              </div>
              <div className="name_exponat">
                <p className="head_text">{"Название экспоната"}</p>
                <input
                  value={localeState.video.name}
                  placeholder={"Введите название экспоната"}
                  onChange={(e) =>
                    setLocaleState((state) => {
                      return {
                        ...state,
                        video: {...state.video, name: e.target.value},
                      };
                    })
                  }
                />
              </div>
              <div className="url_on_video">
                <p>{"Ссылка на файл"}</p>
                <input
                  value={localeState.video.src}
                  placeholder={"Вставьте ссылку"}
                  onChange={(e) =>
                    setLocaleState((state) => {
                      return {
                        ...state,
                        video: {...state.video, src: e.target.value},
                      };
                    })
                  }
                />
              </div>
              <div className="actions">
                <p className="head_text">{"Действия"}</p>
                <div className="switcher_wrap">
                  <p>{"Заказать консультацию"}</p>
                  <AppSwitch
                    onChange={(e) =>
                      setLocaleState((state) => {
                        return {
                          ...state,
                          video: {
                            ...state.video,
                            consultation: e.target.checked,
                          },
                        };
                      })
                    }
                  />
                </div>
              </div>
              <div className="description_wrap">
                <p>{"Описание к экспонату"}</p>
                <textarea
                  value={localeState.video.description}
                  onChange={(e) =>
                    setLocaleState((state) => {
                      return {
                        ...state,
                        video: {...state.video, description: e.target.value},
                      };
                    })
                  }
                />
              </div>
              <BtnsWrap>
                <button
                  className="back"
                  onClick={() => setOpenEditFile({key: -1, open: false})}
                >
                  {"Отменить"}
                </button>
                <button
                  style={ValidFormVideo ? {opacity: "1"} : {opacity: "0.5"}}
                  className="send_btn"
                  disabled={!ValidFormVideo}
                  onClick={() => {
                    dispatch(editMediaGallery(localeState.video));
                    setOpenEditFile(false);
                  }}
                >
                  {"Сохранить"}
                </button>
              </BtnsWrap>
            </CreateMedia>
          )}
        </EditGalleryFileWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
