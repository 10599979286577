import styled from 'styled-components';

const AgreementContainer = styled.div`
    padding: 64px 20px 20px;
    background: white;
`

const AgreementContainer_text = styled.p`
    font-size: 16px;
    color: #444;
    p {
        padding: 5px 0 0;
        color: #444;
    }
`


export {AgreementContainer, AgreementContainer_text}