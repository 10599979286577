import React from "react";
import {
  WrapProfileInfo,
  UserMenu,
  UserPanelWrap,
  ScrollUserPanel,
} from "./style/userPanel_css";
import UserIcon from "../../../assets/Components/Account/images/UserIcon.webp";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filesUrl } from "../../../API/api";
import { setTokenApp } from "../../../store/authSlice/authSlice";
import { resetProfileState } from "../../../store/profileSlice/profileSlice";
import { useOpenExhibition } from "../../../hooks/useOpenExhibition";
import { svg_icons } from "../../../assets/imgs/svg_icons";


export const UserPanel = () => {

  const {
    settingsIcon, 
    favoritesIcon, 
    chatsIcon, 
    logoutIcon, 
    userPanelIcon, 
    userIcon,
    requestsIcon,
    documentIcon,
    meetingsIcon,
    linkIcon,
    homeIcon,
    cubeIcon
} = svg_icons

  const { userData } = useSelector((state) => state.profile);
  const { language, profile: {menu} } = useSelector((state) => state.app.appText);
  const dispatch= useDispatch();
  const openExhibitions = useOpenExhibition();

  const navLinks =
  userData.exhibitor
      ? [
          { to: "user-panel", text: <>{userPanelIcon} {menu.userPanel}</> },
          {
            to: "contacts",
            text: <>{userIcon} {menu.contactsAndRepres}</>,
          },
          { to: "page-exhibitor", text: <>{homeIcon} {menu.exhPage}</> },
          { to: "goMyStand", text: <>{cubeIcon} {menu._3dStand}</> },
          { to: "meets", text: <>{meetingsIcon} {menu.myMeet}</> },
          /*{ to: "notifications", text: <>{NotificationsIcon} Уведомления</> },*/
          { to: "favorites", text: <>{requestsIcon} {menu.requests}</> },
          { to: "chat", text: <>{chatsIcon} {menu.chats}</> },
          {
            to: "select-exhibitors",
            text: <>{favoritesIcon} {menu.favoriteExhs}</>,
          },
          { to: "documents", text: <>{documentIcon} {menu.documents}</> },
        ]
      : [
          { to: "user-panel", text: <>{userPanelIcon} {menu.userPanel}</> },
          { to: "contacts", text: <>{userIcon} {menu.contacts}</> },
          { to: "meets", text: <>{meetingsIcon} {menu.myMeet}</> },
          /*{ to: "notifications", text: <>{NotificationsIcon} Уведомления</> },*/
          { to: "favorites", text: <>{requestsIcon} {menu.requests}</> },
          { to: "chat", text: <>{chatsIcon} {menu.chats}</> },
          {
            to: "select-exhibitors",
            text: <>{favoritesIcon} {menu.favoriteExhs}</>,
          },
          { to: "documents", text: <>{documentIcon} {menu.documents}</>},
        ];

  const navigate = useNavigate();
  const LogoutEvent = () => {
    dispatch(setTokenApp());
    dispatch(resetProfileState("userData"))
    navigate('/')
  }

  return (
    <UserPanelWrap>
      <img className="panel__background" src={UserIcon} alt={"background"} />
      <ScrollUserPanel>
        <img className="user__avatar" src={filesUrl + userData.avatar} alt={"avatar"} />
        <WrapProfileInfo>
          <div className="info-user">
            <button onClick={LogoutEvent}>{logoutIcon}</button>
            <div className="user__name">
              <h3>{userData.firstName}{' '}{userData.lastName}</h3>
              <p>Пользователь</p>
            </div>
            <button onClick={() => navigate("settings?tab=personalData")}>
              {settingsIcon}
            </button>
          </div>

          {userData.exhibitor ? <div className="info-work">
            <h4>
              {
                (userData.exhibitor
                  ? menu.isExhibitor
                  : menu.profileCompany)
              }
            </h4>
            <p>{`${userData.company?.subNameShort[language] || ""} «${userData.company?.name[language] || ""}»`}</p>
            {
              (userData.exhibitor && (
                <Link to={`/company/${userData.company?.companyNameKey}`}>
                  <button>
                    {linkIcon} <span>{menu.exhPage}</span>
                  </button>
                </Link>
              ))
            }
          </div> : null
          }
        </WrapProfileInfo>

        <UserMenu>
          {navLinks.map((nav, i) => nav.to.includes("goMyStand") ? (
            <button 
              key={nav.to} 
              className={`user-menu__nav-link`} 
              onClick={() => openExhibitions({toUrl: `/profile/manage-stand`})}>
                {nav.text}
            </button>
          ) : (
            <NavLink key={nav.to} to={nav.to} className={`user-menu__nav-link`}>
              {nav.text}
            </NavLink>
          ))}
        </UserMenu>
      </ScrollUserPanel>
    </UserPanelWrap>
  );
};
