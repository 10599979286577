import styled, { css } from "styled-components";

const WrapContacts = styled.div`
  flex: 1;
  max-width: ${({ onlyPage }) => (onlyPage ? "initial" : "370px")};
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const ContactsBlockInfo = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    font-family: "Helvetica";
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
  }
  p {
    font-size: 13px;
    line-height: 16px;
  }
`;

const WrapContactsFilter = styled.div`
  display: flex;

  .filter-search {
    height: 34px;
    margin-top: 16px;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    order: 1;
    margin-left: auto;
    max-width: 606px;
    flex: 1;

    input {
      width: 100%;
      border-radius: 10px;
      background: #f6f6f6;
      border: none;
      outline: none;
      padding-left: 6px;
      font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 16px;
    }
  }

  .filter-btns {
    width: 336px;
    margin: 18px 0;
    display: flex;
    .filter-btns__btn {
      width: 100%;
      height: 34px;
      border-radius: 10px;
      border: none;
      background: #f6f6f6;
      cursor: pointer;
      font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 16px;
color: #363636;
    }

    .active {
      color: #ffffff;
      background: #34c57f;
    }
  }

  ${({ onlyPage }) =>
    !onlyPage &&
    css`
      flex-direction: column;
      .filter-search {
        order: initial;
        margin-left: initial;
      }
    `}
`;

const ContactsScroll = styled.div`
  background: #ffffff;
  border: 0.5px solid #d4d4d4;
  border-radius: 4px;
  padding: 16px;
  overflow-y: auto;
  flex: 1;

  .contacts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    .empty_arr{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
    }

    .wrap-contact {
      display: flex;
      padding: 12px;
      min-width: 300px;
      width: ${({ onlyPage }) => (onlyPage ? "calc(33% - 8px)" : "100%")};
      height: 66px;
      background: #f5f5f5;
      border-radius: 4px;

      .contact__avatar {
        width: 42px;
        height: 42px;
        min-width: 42px;
        min-height: 42px;
        border-radius: 50%;
      }
      .contact-info {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding-left: 12px;
        .dots_settings{
            border: none;
            background-color: #efefef;
            border-radius: 8px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: auto;
        }
      }
    }
  }
`;

const ContactName = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #363636;
`;

const ContactDesc = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #484848;
`;

const ContactItemSettings = styled.button`
  background: url(${(props) => props.bg}) center no-repeat;
 
`;

export {
  WrapContacts,
  ContactsScroll,
  ContactsBlockInfo,
  ContactDesc,
  ContactItemSettings,
  ContactName,
  WrapContactsFilter,
};
