import { useParams } from "react-router-dom";
import { AdminCatalog } from "../AdminCatalog/AdminCatalog";
import { AdminNewsControlContainer } from "../AdminNews/AdminNewsControlContainer";
import { AdminPanelContainer } from "../AdminPanelContent/AdminPanelContainer";
import { Companies } from "../Companies/Companies";
import { Roles } from "../Roles/Roles";
import { AllUsers } from "../Users/AllUsers/AllUsers";
import { AdminPavilionsContainer } from "../AdminPavilions/AdminPavilionsContainer";

export const AdminContent = () => {
  const { content } = useParams();

  const BuildContent = () => {
    switch (content) {
      case "admin-panel":
        return <AdminPanelContainer />;
      case "all-users":
        return <AllUsers />;
      case "users-roles":
        return <Roles />;
      case "admin-companies":
        return <Companies />;
      case "admin-pavilions":
        return <AdminPavilionsContainer />;
      case "admin-catalog":
        return <AdminCatalog />;
      case "all-news":
        return <AdminNewsControlContainer contentParam={content} />;
      case 'drafts-news':
        return (<AdminNewsControlContainer contentParam={content} />)
      case 'main-news':
        return (<AdminNewsControlContainer contentParam={content} />)
      default:
        return (
          <p style={{ textAlign: "center", fontWeight: 700, margin: "auto" }}>
            Страница не найдена
          </p>
        );
    }
  };

  return <>{BuildContent()}</>;
};
