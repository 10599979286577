import styled from "styled-components";

const AdminCatalogWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 16px 34px;
  button {
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    cursor: pointer;
  }
  .head_info_wrap {
    display: flex;
    justify-content: space-between;
    button {
      width: 182px;
      height: 34px;
      background: #34c57f;
      border: 1px solid #34c57f;
      border-radius: 8px;
      color: #ffffff;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
    }
  }
`;

const CatalogContainer = styled.div`
width: 100%;
height: 100%;
border: 0.5px solid #D4D4D4;
border-radius: 4px;
margin-bottom: 17px;
padding-right: 4px;
padding-top: 4px;
.map_container{
    display: flex;
    flex-direction: column;
    height: inherit;
    gap: 12px;
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 12px 8px 16px 16px;
}
`;

const CatalogCard = styled.div`
    width: inherit;
    height: 66px;
    background: #F5F5F5;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    align-items: center;
    .catalog_head_wrap{
        display: flex;
        gap: 12px;
        .Uncover_btn{
            width: 28px;
            height: 28px;
            background: #EBEBEB;
            border-radius: 10px;
            border: none;
            position: relative;
        }
    }
`;

const NameCatalogText = styled.div`
    display: flex;
    gap: 5px;
    .head_name{
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
    }
    .catalog_name_text{
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #363636;
    }
`;

const DateReg = styled.div`
display: flex;
gap: 5px;
.admin_catalog_date_text{
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #363636;
}
.admin_catalog_date{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
}
`;

const CatalogBtnsWrap = styled.div`
 display: flex;
    gap: 5px;
    margin-left: auto;
    button{
        width: 30px;
        height: 30px;
        background: #EBEBEB;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn_basket{
        svg{
            width: 18px;
            height: 18px;
            path{
                fill: #363636;
            }
        }
    }
    .settings_icon{
        width: 17px;
        height: 17px;
    }
    .btn_add_icon{
        div{
    visibility: hidden;
    }
        svg{
            path{
                fill: #363636;
            }
        }
    :hover{
        div{
            visibility: visible;
        }
    }
    }
`;

const HintWrap = styled.div`
position: absolute;
    width: 163px;
    height: 32px;
    background: #000000;
    border-radius: 12px 12px 0px 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    right: 115px;
    inset: 2;
    margin-bottom: 50px;
    padding: 0 12px;
p{
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
}
`;

export {
    AdminCatalogWrap,
    CatalogContainer,
    CatalogCard,
    DateReg,
    NameCatalogText,
    CatalogBtnsWrap,
    HintWrap
}