export const filesDownload_by = {
    title: "Файлы",
    titleDoc: "Дакументы",
    subTitle: "Запампоука",
    docs:{
        industry4_2021: {
            text: "Матэрыялы, даступныя для запампоўкі (прэзентацыі дакладчыкаў), прадстаўленыя на онлайн-канферэнцыі \"Indusrty - 4.0 - інавацыі ў вытворчым сектары\" 29 красавіка 2021 года",
            name1: "Еромкин Д. Д. Astersoft Як укараніць ERP і не пашкадаваць пра гэта",
            name2: "Крычмар А. Б. PROF-IT сістэма ERP",
            name3: "Мартынаў С. В. Siemens Прымяненне лічбавы інжынірынгавай платформы",
            name4: "Сафонаў А. і. БНТУ падрыхтоўка кваліфікаваных спецыялістаў",
            name5: "Асіпенка С. Ю. ЦНІІТУ Адзіная віртуальная выстава",
            name6: "Кукареко Е. П. Віцязь інтэграцыйнае рашэнне па кіраванні лічбавым двайніком",
            name7: "Салаўёў С. А. ЦНІІТУ Індустрыя 4.0 ад тэорыі да практыкі",
        },
        industry4_2022: {
            text: "Матэрыялы, даступныя для запампоўкі (прэзентацыі дакладчыкаў), прадстаўленыя на онлайн-канферэнцыі \"Indusrty - 4.0 - інавацыі ў вытворчым сектары\" 13 мая  2022 года",
            name1: "Аляксейчык Д.У. Праф-ІТ Appius-PLM",
            name2: "Ануфрыеў Ю.А. ЮколаІнфа Паспяховыя кейсы лічбавай трансфармацыі",
            name3: "Ваганаў А.С. ТопСофт Індустрыя 4.0 практычны погляд на сучасныя тэндэнцыі ў вытворчасці",
            name4: "Яромкін Д.Д. Astersoft Аўтаматызацыя працэсаў кіравання персаналам",
            name5: "Кукарэка А.П. ОМПсистем Лічбавая трансфармацыя айчынных прадпрыемстваў",
            name6: "Патаповіч А.А. ІНТЭРМЕХ Упраўленне лічбавым двайнікам выраба",
            name7: "Салаўёў С.А. ЦНДІТУ Лічбавая трансфармацыя прамысловых вытворчасцей",
            name8: "Хейфец М.Л. НАНБ Сістэмалогія аб'ектаў і працэсаў пры цыфравізацыі вытворчасці",
        },
        tibo2022: {
            text: "Материалы, доступные для скачивания (презентации докладчиков), представленные на TIBO 2022 года",
            name0: "Глушков, ЦНИИТУ",
            name1: "Интермех, Могилевлифтмаш",
            name2: "МЭТЗ, Мухортов",
            name3: "Бобруйскагромаш, Шостак Жуков Коровкин",
            name4: "Хейфец, НАН РБ",
            name5: "Ваганов, ТопСофт",
            name6: "Кишкурно, Беллесбумпром",
            name7: "Осипенко, ЦНИИТУ",
            name8: "Кричмар, Проф-ИТ",
            name9: "Юкола Ануфриев",
            name10: "Кирсанов, БКМ",
            name11: "Еромкин, Астерсофт",
            name12: "Григорьев, Ресолва Диджитал",
            name13: "Соловьев, круглый стол",
        },
        digitalTrasnformation: {
            text: "Матэрыялы, даступныя для спампоўкі (прэзентацыі дакладчыкаў), прадстаўленыя на круглым стале “Лічбавая трансфармацыя прамысловага сектара Рэспублікі Беларусь. Выклікі і перспектывы» 20 верасня 2022 года",
            name0: "Абазавік М.А. Комплексны падыход 1С да рэалізацыі праектаў ERP",
            name1: "Ваганаў А.В. ТопСофт Беларускі лічбавы прадукт міф ці рэальнасць",
            name2: "Глушкоў А.У. ЦНДІТУ Лічбавая трансфармацыя вытворчага сектара на базе айчыннай лічбавай платформы",
            name3: "Жукаў В.У. Бабруйскаграмаш Рашэнне актуальных задач лічбавай трансфармацыі",
            name4: "Кукарэка А.П. Omega Production як платформа лічбавай трансфармацыі айчынных прадпрыемстваў",
            name5: "Салаўёў С.А. ЦНІІТУ Офіс цыфравізацыі прадпрыемстваў рэальнага сектара эканомікі",
        },

        contractClevr: 'Дагавор аказання паслуг па ўдзелу ў адзінай віртуальнай выставе',
    }
}