import { getMetaData } from "./MetaTagsAsyncThunk"

export const extraReducers = (builder) => {
    builder.addCase(
        getMetaData.fulfilled(), (state, {payload}) => {
            state.metaData = {
                ...payload.response,
                title: {
                    ...state.metaData.title,
                    [payload.lang]: payload.response.title
                },
                description: {
                    ...state.metaData.description,
                    [payload.lang]: payload.response.description
                }
            }
        }
    )
    builder.addCase(
        getMetaData.rejected(), (state, {payload}) => {
            state.metaError = payload
        }
    )
}