import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { ConfirmPassword } from "../../store/profileSlice/profileAsyncThunk"
import { ModalMessage } from "../ModalMessage/ModalMessage"


export const Confirm = () => {

    const params = useParams()
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(ConfirmPassword(params.token))
    },[dispatch, params.token])

    return (
        <>
            <ModalMessage isConfirm={true} />
        </>
    )
}