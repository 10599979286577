import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { filesUrl } from "../../../../API/api"
import { svg_icons } from "../../../../assets/imgs/svg_icons"
import { setAppState } from "../../../../store/appSlice/appSlice"
import { setTokenApp } from "../../../../store/authSlice/authSlice"
import { resetProfileState } from "../../../../store/profileSlice/profileSlice"
import { socialIcons } from "../../../Footer/socialIcons.svg"
import Language from "../../../Header/Language/Language"
import { UserAvatar, UserDataWrap} from "../../../Header/style/header_css"
import { AdminHeaderWrap, AdminTextWrap, HeaderLogo, InfoTextWrap, LanguageSelectWrap, NavigateContentWrap, NotificationWrap } from "./AdminHeader.style"


export const AdminHeader = () => {

    const {logo, contacts, bag, notification} = socialIcons
    const {logoutIcon} = svg_icons
    const {userData} = useSelector((state) => state.profile)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const handlerActions = (key, data) => (e) => {
        switch (key) {
          case "contextMenu":
            dispatch(
              setAppState({
                contextMenu: {
                  currentTarget: e.currentTarget,
                  listMenu: [
                    {
                        onClick: () => {
                            dispatch(setTokenApp());
                            dispatch(resetProfileState("userData"))
                            navigate('/')
                        },
                        icon: logoutIcon,
                        text: "Выйти из профиля",
                      },
                  ]
                }
            }))
            break;
            default:
                break;
        }
      }

    return (
        <header id="Admin_header">
            <AdminHeaderWrap>
                <HeaderLogo>{logo}</HeaderLogo>
                <NavigateContentWrap>
                    <ul>
                        <li>
                            <Link target={'_blank'} to={'/'}>Сайт</Link>
                        </li>
                        <li>
                            <a href="https://online.clevr-expo.com" target='_blank' rel="noreferrer">3D выставка</a>
                        </li>
                        <li>
                            <Link style={location.pathname.includes('admin-panel') ? {color: "#34C57F"} : {}} to={'/account/admin-panel'}>Администрирование</Link>
                        </li>
                    </ul>
                </NavigateContentWrap>
                <NavigateContentWrap style={{width: '67%'}}>
                    <ul>
                        <li>
                        {contacts}
                            <Link 
                            to={'/account/all-users'}
                            style={location.pathname.includes('all-users') 
                            ? {color: "#34C57F"} : {}}>
                            Пользователи
                            </Link>
                        </li>
                        <li>
                        {bag}
                            <Link 
                            to={'/account/admin-companies'}
                            style={location.pathname.includes('admin-companies') 
                            ? {color: "#34C57F"} : {}}>
                            Организации
                            </Link>
                        </li>
                    </ul>
                </NavigateContentWrap>
                <LanguageSelectWrap>
                <Language height={32} border = {'none'} background={'#F5F5F5'}/>
                </LanguageSelectWrap>
                <NotificationWrap>
                    <button>{notification}</button>
                </NotificationWrap>
                <UserDataWrap style={{cursor: 'pointer', padding: '0 16px', alignItems: 'center'}} onClick={handlerActions("contextMenu")}>
                    <UserAvatar src={filesUrl + userData.avatar} />
                    <InfoTextWrap>
                        <AdminTextWrap>
                            <p>{userData.firstName}</p>
                            <p>{userData.lastName}</p>
                        </AdminTextWrap>
                        <p className="text__role">Администратор</p>
                    </InfoTextWrap>
                </UserDataWrap>
            </AdminHeaderWrap>
        </header>
    )
}