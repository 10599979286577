export const contextMenu_by = {
    writeMes: "Напісаць паведамленне",
    block: "Заблакаваць",
    unblock: "Разблакіраваць",
    removeChat: "Выдаліць чат",
    removeUser: "Выдаліць карыстальніка",
    manamgeAndConfig: "Кіраванне і налада",
    downloadFile: "Спампаваць файл",
    removeFromContact: "Выдаліць з кантактаў",
    addFromContact: "Дадаць у кантакты",
    remove: "Выдаліць",
}