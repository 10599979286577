import styled from "styled-components";

const AddGalleryFileWrap = styled.div`
padding: 24px 16px 30px;
.wrap_btns{
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .add_gallery_file{
        width: 100%;
        height: 42px;
        background: #F5F5F5;
        border-radius: 12px;
        border: none;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 100%;
        color: #363636;
        cursor: pointer;
    }
    .wrap_3d{
        position: relative;
        border-top: 0.5px solid #D4D4D4;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .head_3d{
            position: absolute;
            font-weight: 500;
            font-size: 13px;
            line-height: 100%;
            color: #363636;
            top: -8px;
            background: white;
            width: 110px;
            text-align: center;
        }
        .text_3D{
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #484848;
            margin-top: 12px;
        }
        .email_clevr{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #484848;
        }
        a{
            width: 100%;
            text-decoration: none;
        }
        button{
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
            color: #34C57F;
            height: 40px;
            margin-top: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: none;
            background: #F5F5F5;
            border-radius: 8px;
            gap: 10px;
            cursor: pointer;
            svg{
                width: 20px;
                height: 20px;
                path{
                    stroke: #34C57F;
                }
            }
        }
    }
}
`;

const CreateMedia = styled.div`
margin-top: 19px;
.preview{
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    img{
        width: 100%;
        height: 159px;
        object-fit: cover;
    }
    .preview_video{
        width: 100% !important;
        height: 159px !important;
        object-fit: cover;
    }
    .file_info{
        width: 100%;
        height: 54px;
        background: #F5F5F5;
        border-radius: 0px 0px 4px 4px;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .file_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
        }
        .file_size{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #484848;
        }
        .delete_file{
            width: 30px;
            height: 30px;
            background: #EBEBEB;
            border-radius: 10px;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                path{
                    fill: #363636;
                }
            }
        }
    }
}
.image_none_wrap{
    .file_wrap_none{
        position: relative;
        width: 100%;
        height: 159px;
        background: #EBEBEB;
        input{
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        left: 0;
        display: none;
      }
      label{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 0 16px;
        top: 0;
        left: 0;
        p{
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            text-align: center;
            width: 200px;
        }
      }
    }
    .file_info_none{
        width: 100%;
        height: 54px;
        background: #F5F5F5;
        border-radius: 0px 0px 4px 4px;
    }
}
.name_exponat{
    margin-top: 23px;
        .head_text{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding-bottom: 5px;
        }
        input{
            width: 100%;
            height: 34px;
            background: #F5F5F5;
            border-radius: 4px;
            outline: none;
            border: none;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding: 0 12px;
        }
    }
.actions{
    margin: 24px 0;
    .head_text{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    .switcher_wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        width: 100%;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        p{
            font-weight: 400;
            font-size: 13px;
            line-height: 110%;
            color: #363636;
        }
    }
}
.description_wrap{
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    textarea{
        width: 100%;
        height: 108px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 9px 12px;
    }
}
.url_on_video{
    margin-top: 24px;
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding-bottom: 5px;
    }
    input{
        width: 100%;
        height: 34px;
        background: #F5F5F5;
        border-radius: 4px;
        outline: none;
        border: none;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 0 12px;
    }
}
`;

const BtnsWrap = styled.div`
width: 100%;
display: flex;
gap: 10px;
margin-top: 20px;
.back{
    width: 100%;
    height: 32px;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: none;
    cursor: pointer;
}
.send_btn{
    background: #34C57F;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    height: 32px;
    width: 100%;
    cursor: pointer;
}
`;

export {
    AddGalleryFileWrap,
    CreateMedia,
    BtnsWrap
}