import styled from "styled-components";

const VDNXContainer = styled.div`
    width: 100%;
    height: 804px;
    -webkit-transition: all 0.4s linear 0s;
    -o-transition: all 0.4s linear 0s;
    transition: all 0.4s linear 0s;
    @media (max-width: 1200px) {
       height: 600px;
    }
    @media (max-width: 1025px) {
        height: 460px;
    }
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${props => props.bg}) center no-repeat;
    background-size: cover;
    .Desc_Container{
        position: relative;
        height: inherit;
    }
    .Desc_Pavilions_Wrapp{
        height: inherit;
    }
`;

const ImgBg = styled.img`
  position: absolute;
  width: 100%;
  height: 101%;
  margin-top: -2px;
  z-index: -1;
`;

const HeadContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .head{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        color: #fff;
        padding: 67px 0 0;
    }
    .head_text{
        width: 340px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        color: #fff;
    }
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .description_title{
        -webkit-transition: all 0.4s linear 0s;
        -o-transition: all 0.4s linear 0s;
        transition: all 0.4s linear 0s;
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 34px;
        line-height: 110%;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        @media(max-width: 1200px){
            font-size: 30px;
        }
        @media(max-width: 1025px){
            font-size: 26px;
        }
        @media(max-width: 769px){
            font-size: 22px;
        }
        @media(max-width: 480px){
            font-size: 20px;
        }
    }
    .description_title_VDNX{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 150px;
        line-height: 110%;
        color: #fff;
        text-transform: uppercase;
    }
`;

const TextContainer = styled.div`
display: flex;
    flex-direction: column;
    align-items: center;
    .description_text{
    max-width: 409px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #fff;
    text-align: center;
    }
    .description_text_VDNX{
        width: 409px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #fff;
    }
    .description_btn{
        width: 165px !important;
        height: 34px;
        border: 1.4px solid #FFFFFF;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        cursor: pointer;
        background: none !important;
        color: #fff;
        margin-top: 28px;
        font-family: 'Gilroy';
    }
`;

const LinePavilionContent = styled.div`
    width: 108px;
    height: 1px;
    background: #FFFFFF;
    border-radius: 2px;
    opacity: 0.5;
    margin: 32px 0 28px;
`;

const AllpavilionContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    top: -94px;
    p{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #fff;
    }
`;


export {VDNXContainer, HeadContent, DescriptionContainer, TextContainer, AllpavilionContainer, ImgBg, LinePavilionContent}