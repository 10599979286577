import styled from "styled-components";

const RequestModalWrap = styled.div`
    padding: 0 16px;
    margin: 30px 0;
    .Invalid_Input{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
`;

const TopicContent = styled.div`
margin: 20px 0 24px;
p{
font-weight: 600;
font-size: 13px;
line-height: 110%;
color: #484848;
}
`;

const DateConfirence = styled.div`
display: flex;
gap: 50px;
margin-bottom: 26px;
p{
font-weight: 600;
font-size: 13px;
line-height: 110%;
color: #484848;
padding-bottom: 5px;
}
`;

const DateStart = styled.div`
width: 100%;
input{
    font-family: 'Gilroy';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 110%;
    height: 36px;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    width: 100%;
    ::-webkit-calendar-picker-indicator {
        opacity: 0;
}
}
`

const TimeStartEnd = styled.div`
.Line{
    color: #363636;
    width: 13px;
    height: 1px;
    background: #363636;
    margin: auto;
}
display: flex;
gap: 15px;
margin-left: auto;
width: 100%;
.Start{
    width: 100%;
}
.End{
    width: 100%;
}
input{
    font-family: 'Gilroy';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 110%;
    height: 36px;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    width: 100%;
    text-align: center;
    ::-webkit-calendar-picker-indicator {
        opacity: 0;
}
}

`

const Message = styled.div`
margin-bottom: 30px;
p{
font-weight: 600;
font-size: 13px;
line-height: 110%;
color: #484848;
padding-bottom: 5px;
}
textarea{
    width: 100%;
    height: 83px;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    resize: none;
    font-family: 'Gilroy',sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 9px 12px;
}
`
const RequestModalBtns = styled.div`
display: flex;
justify-content: space-between;
button{
    width: 172px;
    height: 36px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 140%;
}
.Send_Request{
    background: #34C57F;
    color: #FFFFFF;
}
.Exit{
    border: 1px solid #34C57F;
    color: #34C57F;
    background: #FFFFFF;
}
`

export {RequestModalWrap, TopicContent, 
    DateConfirence, DateStart, 
    TimeStartEnd, Message, 
    RequestModalBtns
}