import React from "react";
import { filesUrl, initiate } from "./API/api";
import Header from "./Pages/Header/Header";
import { UseRoutes } from "./Pages/Routes/Routes";
import ModalsChat from "./components/AppChat/ModalsChat/ModalsChat";

// import { v4 as uuidv4 } from 'uuid'; how import uuid
import AppContextMenu from './components/AppContextMenu/AppContextMenu';
import useWebSocket from "./hooks/useWebSocket";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { getMetaData } from "./store/appSlice/appAsyncThunk";


function App() {
    const {metaData} = useSelector((state) => state.app)
    const {language} = useSelector((state) => state.app.appText)
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        dispatch(getMetaData(`${location.pathname}?lang=${language}`))
    }, [location.pathname, dispatch, language])


  initiate(window.origin);
  useWebSocket();

  return (
    <div className="App">
      <AppContextMenu/>
      <Header />
      <UseRoutes />
      <ModalsChat />
      <Helmet>
        <title>{metaData ? metaData.title : ''}</title>
        <link rel='canonical' href={window.location.href}>{}</link>
        <meta name='description' content={metaData ? metaData.description : ''} />
        <meta name='title' content={metaData ? metaData.title : ''}/>
        <meta name='og:title' content={metaData ? metaData.title : ''} />
        <meta name='og:image' content={metaData ? filesUrl + metaData.image : ''} />
        <meta name='og:type' content={metaData ? metaData.type : ''} />
        <meta name='og:url' content={window.location.href} />
      </Helmet>
    </div>
  );
}

export default App;


