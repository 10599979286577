export const pavilionPage_ru = {
    pavilion: 'Павильон',
    descriptonPavilion: `XXV Белорусский энергетический и экологический форум, выставка и конгресс "Энергетика. Экология. Энергосбережение. Электро."
    Мероприятие проводится 12-15 октября 2021 г. по адресу г. Минск, пр. Победителей 20/2, Футбольный манеж`,
    linkStand: 'Стенд',
    linkPage: 'Страница',
    linkPrev: 'Предыдущий павильон',
    linkNext: 'Следующий павильон',
    linkPavilions: 'Назад к выбору павильона',
}

export const pavilionsList_ru = {
    pavilions: 'Павильоны',
    description: 'Предприятия-экспоненты разделены по павильонам соответствующим тематике их деятельности. Один экспонент может выставляться несколькими стендами и, в зависимости от представляемых на стендах материалов, быть размещен в нескольких павильонах. Также, специализированные павильоны могут быть созданы для проведения мероприятий, выставок и конференций.',
}