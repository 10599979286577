import styled from "styled-components";

const VisibilityPageWrap = styled.div`
    padding: 24px 16px 30px;
    .choose_visibility{
        display: flex;
        margin-top: 2px;
        .visibility_company{
            width: 162px;
            height: 30px;
            background: #F6F6F6;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            gap: 5px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
        }
        .active{
            background: #34C57F;
            color: #FFFFFF;
            svg{
                path {
                    fill: #FFFFFF;
                }
            }
        }
    }
    .Btns_wrap{
        margin-top: 32px;
    button{
        width: 100%;
        height: 36px;
        border-radius: 8px;
        cursor: pointer;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
    }
    .save_btn{
        color: #FFFFFF;
        background: #34C57F;
        border: none;
        margin-top: 10px;
    }
    .Back{
        border: 1px solid #34C57F;
        background: none;
        color: #34C57F;
    }
    }
`;

export {
    VisibilityPageWrap
}