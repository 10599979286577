export const authorization_by = {
    email: "Ваш e-mail",
    noEmail: "Email не увездены",
    alertEmailValid: "Няправільны фармат Email",
    password: "Ваш пароль",
    noPassword: "Пароль не ўведзены",
    logIn: "Увайсці",
    logInLoad: "Выконваецца ўваход...",
    signUp: 'Зарэгістравацца',
    userAgreement: 'Карыстальніцкае пагадненне',
    registerSuccess: 'Для завяршэння рэгістрацыі націсніце кнопку «Пацвердзіць» у лісце, які прыйшоў на ўказаны Вамі электронны адрас.',
    placeH: {
        name: 'Ваша імя',
        surname: 'Ваша прозвішча',
        patronymic: 'Ваша імя па бацьку',
        nickname: 'Ваш нік',
        email: 'Электронная пошта',
        password: 'Пароль',
        passwordEqual: 'Паўтарыце пароль',
    },
}