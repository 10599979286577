import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import icons_svg from "../../imgs/icons_svg";
import { WrapMessage } from "./messages.style";
import { getTimebySecond } from "../../../../utils/helpers";
import loaderSvg from "../../../../assets/imgs/loader.svg"

export default function Message({ message }) {

  const openDialog = useSelector(state => state.appChat.openDialog);
  let file = message.files && message.files[0];
  let { videoIcon, pdfIcon, defaultFile, donwloadFileMes } = icons_svg;
  const [localState, setLocalState] =  useState({size: null})


  const videoRef = useRef()

  useEffect(() => {
    let on = true;
    const setDurationVideo = () => on && setLocalState(state => ({...state, size: getTimebySecond(parseInt(videoRef.current?.duration || 0))}));
    videoRef.current?.addEventListener('loadedmetadata', setDurationVideo);

    return () => {
      on = false
      videoRef.current?.removeEventListener("loadedmetadata", setDurationVideo)
    }
    
  },[])


  const getFilePreviewByType = (file) => {

    let type = file.mimeType || file.type.toLowerCase();

    let result = { img: null, icon: null };

    let infoPreview = (
      <>
        <p className="file-preview-time file-desc_backgrond">{message.time}</p>
        <p className="file-preview-size file-desc_backgrond">{file.size}</p>
      </>
    );

    if (type.includes("image")) {
      result.img = (
        <>
          <img src={file.previewUrl || loaderSvg} alt="file" />
          {infoPreview}
        </>
      );
    } else if (type.includes("video")) {
      result.img = (
        <>
          <div className="video_icon file-desc_backgrond">
            {videoIcon}
            <p>{localState.size || ""}</p>
          </div>
          <video ref={videoRef}>
            <source src={file.previewUrl || loaderSvg} type="video/mp4" />
          </video>
          {infoPreview}
        </>
      );
    } else if (type.includes("pdf")) {
      result.icon = pdfIcon;
    } else {
      result.icon = defaultFile;
    }

    return result;
  };

  
  let preview = file && getFilePreviewByType(file);


  return (
    <WrapMessage alignSelf={message.align} file={file}>
      {preview ? (
      <>
                {preview.img &&  <div className="message__file">{preview.img}</div>}
                  {preview.icon && (
                     <div className="message__file icon">
                      {preview.icon}
                      <p className="file__desc">
                        {file.fileName}
                        <span>{file.size}</span>
                      </p>
                      <a href={file.previewUrl} download={file.fileName}>
                        <button className="file__download-btn">{donwloadFileMes}</button>
                      </a>
                      
                      <p className="file__time">{message.time}</p>
                      </div>
                  )}
      </>
      ) : (
        <>
          <p className="message__text">{message.text}</p>
          <p className="message__time">{message.time}</p>
        </>
      )}
    </WrapMessage>
  );
}
