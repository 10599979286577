export const banner_ru = {
    title: "CleVR - виртуальная выставка предприятий с обширным набором инструментов онлайн конференций, семинаров и иных форм делового общения.",
    h1: "Виртуальная выставка предприятий",
    subhead: "Посети стенды предприятий <br /> и ознакомься с продукцией онлайн!",
    visitExhibition : "Посетить выставку", 


    h2: "ЕДИНАЯ ВИРТУАЛЬНАЯ ВЫСТАВКА",
    h3: "ПРЕДПРИЯТИЙ РЕСПУБЛИКИ БЕЛАРУСЬ",
    h6: "Виртуальное пространство для реального бизнеса",
    toTheExhibition: 'На выставку',
}