import { initialState } from "./pavillionsSlices";

export const reducers = {
    setPavilions (state, action) {
      state.pavilions = action.payload;
    },
    filterCompany (state, action){
        state.companies = action.payload
    },
    setSearchSettings(state, {payload}){
        state[payload.key] = payload.value
    },
    resetStatePavilionList(state, {payload}){
        state[payload.key] = []
    },
    resetPavilionsState(state, {payload}){
        state[payload] = initialState[payload]
    }
}