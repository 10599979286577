export const authorization_en = {
    email: "Your e-mail",
    noEmail: "Email not entered",
    alertEmailValid: "Invalid Email format",
    password: "Your password",
    noPassword: "The password is not entered",
    logIn: "Log in",
    logInLoad: "Login is in progress...",
    signUp: 'Sign Up',
    userAgreement: 'The user agreement',
    registerSuccess: 'To complete registration, click the “Confirm” button in the letter that was sent to your email address.',
    placeH: {
        name: 'Your name',
        surname: 'Your surname',
        patronymic: 'Your patronymic',
        nickname: 'Your displaying name',
        email: 'E-mail',
        password: 'Password',
        passwordEqual: 'Repeat password',
    },
}