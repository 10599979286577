import styled from "styled-components";

const WrapAppDatePicker = styled.div`
  padding: 16px 0;
  & * {
    color: #363636;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-range {
    background: #34c57f;
    color: #ffffff;
  }
  .react-datepicker__input-container {
    background: #f5f5f5;
    cursor: pointer;
    input {
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      padding: 8px;
      
      cursor: pointer;
    }
  }
  .picker-range {
    display: flex;
    justify-content: space-between;
    .range__divider {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
  }
`;

export { WrapAppDatePicker };
