import React from "react"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { ChangeFullName, UpdateAvatar } from "../../../store/profileSlice/profileAsyncThunk";
import { Settings } from "./Settings"


export const SettingsContainer = () => {

    const navigate = useNavigate();
    const {userData} = useSelector(state => state.profile)
    const selectedTab = useSelector(state => state.app.selectedTab);
    const dispatch = useDispatch()
    const [newAvatar, setNewAvatar] = useState({path: '', file: ''})




    const handlerActions = (type, data) => (e, formActions) => {
        switch (type) {
            case "toAccount":
                navigate(userData.role === 'ROLE_ADMIN' ? "admin-panel" : "user-panel");
                break;
            case "changeFullName":
                    Boolean(newAvatar.path) && dispatch(UpdateAvatar(newAvatar.file))
                    dispatch(ChangeFullName(e))
              setTimeout(() => {
                formActions?.setSubmitting(false);
              }, 400);
              break;
              case "changeAvatar" :
                setNewAvatar({path: URL.createObjectURL(e.target.files[0]), file: e.target.files[0]})
                break;
            default:
                break;
        }
    }


    return ( <Settings 
        handlerActions={handlerActions} 
        userData={userData}
        selectedTab={selectedTab}
        newAvatar={newAvatar}
        />
    )
}