export const tibo_en = {
    header: "Materials available for download (speakers presentations) presented at TIBO 2021",
    documents03_06_2021:{
        name1: 'Yatskevich O. K. Training of qualified specialists for the needs of digital transformation',
        name2: 'Soloviev S. A. Digital platform for product lifecycle management and enterprise management',
        name3: 'Skuratov A. G. Standards of Industry 4.0',
        name4: 'Rybak A. E. Advanced training of personnel for digital production',
        name5: 'Potapovich A. A. Building a digital enterprise based on the BADA of INTERMECH solutions',
        name6: 'Petrushin A. A. Experience and achieved results of digital transformation of the leaders of the ppindustry',
        name7: 'Osipenko S.Yu. Digital transformation of marketing. EVV CNIITU',
        name8: 'Nesterenko A.V. Digital transformation of OJSC Mogilevliftmash, ERP implementation project',
        name9: 'Myshko A. P. Ivanov Yu. M. Viktorovich V. S. Digital transformation of tasks of maintenance of products of the Minsk automobile plant in industrial operation',
        name10: 'Mukhortov A. A. Building a digital enterprise using INTERMECH MATZ solutions',
        name11: 'Merguryev A.V. Narezhnev A. N. Global Rearmament Project MAKER, Republic of Kazakhstan',
        name12: 'Kasyanik V. V. Experience of implementing solutions in food production based on digital engineering',
    },
    documents04_06_2021:{
        name1: 'Kholevo S. A. Experience of building a modern IS MZKT',
        name2: 'Kheifets M. L. Design and application of technological equipment complexes in the digitalization of traditional and additive manufacturing',
        name3: 'Sushchenko V. I. Results, plans and prospects of yifrovoy transformation of CJSC Atlant',
        name4: 'Plebanovich V. I. Innovative digital solutions of JSC Planar for the production of modern microelectronic component base',
        name5: 'Nasakin A. Matrix production management',
        name6: 'Maker. Global rearmament project of MAKER LLP, Republic of Kazakhstan, Karaganda, Kazakhmys Corporation.',
        name7: 'Malyarov D. The world experience of digital transformation',
        name8: 'Kozlov V. I. Practical issues of implementing Industry 4.0. when performing R & D',
        name9: 'Isakov K. A. Digital transformation of Ural Locomotives LLC on the example of the «Lastochka» electric train',
        name10: 'Dorosh V. A. Directions of development of the corporate information system',
        name11: 'Alekseychik D. V. ERP system as the basis of digital transformation on the example of an operating enterprise in the Republic of Belarus',
    }
}