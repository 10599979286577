export const header_en = {
    main: "Main",
    logIn: "Log in",
    logOut: "Log out",
    services: "Services",
    exhibition: "Exhibition",
    participans: "Follow",
    info: 'Information',
    blog: "News",
    account: 'Personal account'
}