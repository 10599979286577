import React, { useEffect, useState } from 'react'
import { DropOption } from './style/language_css'
import {NavBarSelect} from '../style/header_css'
import { useDispatch, useSelector } from 'react-redux';
import { setAppTextLang } from '../../../store/appSlice/appSlice';

const options = [
    {
        key: 'en',
        value: '',
        text: 'Eng'
    },
    {
        key: 'ru',
        value: '',
        text: 'Рус'
    },
    {
        key: 'by',
        value: '',
        text: 'Бел'
    },
];

export default function Language({height, border, background}){
    const [value, setValue] = useState('')
    const {language} = useSelector((state) => state.app.appText);
    const dispatch = useDispatch()

    useEffect(() => {
        options.map((option) => option['key'] === language ? setValue(option['text']) : option)
    }, [language])

    const SelectEvent = (event) => {
        options.map((option) => option['text'] === event ? dispatch(setAppTextLang(option.key)) : option)
    }

    return (
        <NavBarSelect style={{height: height, border: border, background: background}} onChange={(e) => SelectEvent(e.currentTarget.value)} value = {value}>
                {options.map((key, i) =>
                    <DropOption key={i} >
                        {key.text}
                    </DropOption> )}
        </NavBarSelect>
    )
}