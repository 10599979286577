import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {svg_icons} from "../../../../assets/imgs/svg_icons";
import {GetAdminExpanents, deleteAdminCompany, getCompanyInfo} from "../../../../store/adminSlice/adminAsyncThunk";
import {DeleteModal} from "../../DeleteModal/DeleteModal";
import {AdminHead} from "../AdminHead/AdminHead";
import {ScrollBarWrap} from "../AdminPanelContent/AdminPanelContent.style";
import {ModalMessage} from "../MessageContent";
import {SearchSortContent} from "../SearchSortContent/SearchSortContent";
import { CreateCompanyContainer} from "./CreateCompany/CreateCompanyContainer";
import {
  CompaniesBtnsWrap,
  CompaniesCard,
  CompaniesContainer,
  CompaniesWrap,
  DateReg,
} from "./Companies.style";
import { resetAdminState } from "../../../../store/adminSlice/adminSlice";

export const Companies = () => {
  const {addIcon, trashIcon, settingsIcon} = svg_icons;
  const [deleteModalOpen, setDeleteModalOpen] = useState({
    key: "",
    open: false,
  });
  const [createCompany, setCreateCompany] = useState({content: 'create', open: false, companyId: ''});
  const [searchCompany, setSearchCompany] = useState("");
  const dispatch = useDispatch();
  const {expanents} = useSelector((state) => state.admin);
  const localText = useSelector((state) => state.app.appText);

  useEffect(() => {
    dispatch(GetAdminExpanents(searchCompany));

    return () => {
        resetAdminState('expanents')
    }
  }, [dispatch, searchCompany]);

  const handlerActions = (type, data) => (e) => {
    switch (type) {
        case 'deleteCompany':
            dispatch(deleteAdminCompany(data.url))
            setDeleteModalOpen({ open: false })
            break;
        case 'editCompany':
          dispatch(getCompanyInfo(data.id))
          setCreateCompany({content: 'update', open: true, companyId: data.id})
        break;
        default:
            break;
    }
  }

  return (
    <CompaniesWrap>
      <div className="head_info_wrap">
        <AdminHead
          title={`Организации (${expanents.length})`}
          desc={
            "Здесь вы можете добавить и отредактировать информацию о экспонентах"
          }
        />

        <button
          className="Add_Organization"
          onClick={() => setCreateCompany({content: 'create', open: true})}
        >
          {addIcon} Организация
        </button>
      </div>
      <SearchSortContent
        page={"admin-companies"}
        inputChange={(e) => setSearchCompany(e.target.value)}
      />
      <CompaniesContainer>
        <ScrollBarWrap>
          <div className="map_container">
            {expanents.map((company, index) => {
              return (
                <CompaniesCard key={index}>
                  <p className="admin_company_id">{company.id}</p>
                  <div className="admin_company_info_wrap">
                    <p className="admin_company_name">
                      {company.name[localText.language]}
                    </p>
                    <DateReg>
                      <p className="admin_company_date_text">
                        {"Дата регистрации:"}
                      </p>
                      <p className="admin_company_date">{"19 апреля 2022"}</p>
                    </DateReg>
                  </div>
                  <CompaniesBtnsWrap>
                    <button
                      onClick={handlerActions('editCompany', company)}
                    >
                      {settingsIcon}
                    </button>
                    <button
                      className="btn_basket"
                      onClick={() =>
                        setDeleteModalOpen({key: index, open: true})
                      }
                    >
                      {trashIcon}
                    </button>
                  </CompaniesBtnsWrap>
                  {deleteModalOpen.key === index && deleteModalOpen.open ? (
                    <DeleteModal
                      deleteClick={handlerActions('deleteCompany', company)}
                      setDeleteModalOpen={setDeleteModalOpen}
                      name={company.name[localText.language]}
                      text={"будет удален из орагнизаций"}
                    />
                  ) : null}
                </CompaniesCard>
              );
            })}
          </div>
        </ScrollBarWrap>
      </CompaniesContainer>
      {createCompany.open ? (
        <CreateCompanyContainer
          content={createCompany.content}
          companyId={createCompany.companyId}
          setCreateCompany={setCreateCompany}
          title={createCompany.content === 'create' ? "Новая организация" : 'Редактирование'}
          openModalCreateCompany={createCompany}
        />
      ) : null}
      <ModalMessage />
    </CompaniesWrap>
  );
};
