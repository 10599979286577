import React from "react";
import { WrapSettings, WrapContentTab, FieldWrap } from "./settings.style";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { AppTabs } from "../../../components/AppTabs/AppTabs";
import { filesUrl } from "../../../API/api";
import { useState } from "react";
import { RestorePassword } from "./RestorePassword/RestorePassword";
import { ModalMessage } from "../../ModalMessage/ModalMessage";
import { SettingsComponent } from "./SettingsComponent";
import { AccFooter } from "../AccountFooter/AccFooter";
import { RecoverPassword } from "../../Registration/RecoveryPassword/RecoverPassword";
import { RestoreEmail } from "./RestoreEmail/RestoreEmail";
import { useSelector } from "react-redux";
import { svg_icons } from "../../../assets/imgs/svg_icons";


export const Settings = ({ handlerActions, userData, selectedTab, newAvatar }) => {
  let { userIcon, securityIcon, settingsIcon, addIcon } = svg_icons;
  const {profile: {settings}} = useSelector((state) => state.app.appText)
  const [RestorePass, setRestorePass] = useState(false)
  const [recover, setRecover] = useState(false);
  const [ModalEmail, setModalEmail] = useState(false)

  if (!userData.firstName) return null;

  const tabSettings = {
    tabs: [
      {
        name: "personalData",
        content: (
          <>
            {userIcon} <p>{settings.personalData}</p>
          </>
        ),
      },
      {
        name: "security",
        content: (
          <>
            {securityIcon} <p>{settings.security}</p>
          </>
        ),
      },
      {
        name: "settings",
        content: (
          <>
            {settingsIcon} <p>{settings.settings}</p>
          </>
        ),
      },
    ],
  };

  return (
    <WrapSettings>
      <div className="settings-banner">
        <div className="banner-user-info">
          <div className="avatar_wrap">
          <img src={Boolean(newAvatar.path) ? newAvatar.path  : filesUrl + userData.avatar } alt="avatar" />
          <label className="add_avatar">
            <input type={'file'} accept="image/png, image/jpeg" onChange={handlerActions("changeAvatar")} />
            <div>
            {addIcon}
            <p>{settings.change}</p>
            </div>
          </label>
          </div>
          <h2>{userData.firstName}{' '}{userData.lastName}</h2>
          <p>{'Пользователь'}</p>
        </div>
      </div>

      <AppTabs settings={tabSettings} />

      <div className="divider-tab"></div>

      {selectedTab === "personalData" && (
        <>
        <WrapContentTab>
          <Formik
            initialValues={userData}
            // validationSchema={Yup.object({
            //   firstName: Yup.string()
            //     .max(15, "Must be 15 characters or less")
            //     .required("Required"),
            //   lastName: Yup.string()
            //     .max(20, "Must be 20 characters or less")
            //     .required("Required"),
            //   email: Yup.string()
            //     .email("Invalid email address")
            //     .required("Required"),
            // })}
            onSubmit={handlerActions("changeFullName")}
          >
            {({dirty, handleSubmit}) => {
              return(
                <Form>
                <div className="form-fields">
                  <FieldWrap className="field-wrap">
                    {/* <span className="field__help-elem">Забыли пароль?</span> */}
                    <label htmlFor="lastName" required>
                      {settings.lastName}
                    </label>
                    <Field name="lastName" type="text" />
                    <ErrorMessage
                      name="lastName"
                      component="span"
                      className="field__error-mes"
                    />
                  </FieldWrap>
                  <FieldWrap className="field-wrap" grow={2}>
                    <label htmlFor="firstName" required>
                      {settings.firstName}
                    </label>
                    <Field name="firstName" type="text" />
                    <ErrorMessage
                      name="firstName"
                      component="span"
                      className="field__error-mes"
                    />
                  </FieldWrap>
                  <FieldWrap className="field-wrap" grow={2}>
                    <label htmlFor="patronymic" required>
                      {settings.patronymic}
                    </label>
                    <Field name="patronymic" type="text" />
                    <ErrorMessage
                      name="patronymic"
                      component="span"
                      className="field__error-mes"
                    />
                  </FieldWrap>
                </div>
                <div className="form-btns">
                  <button
                    className="btns__cancel"
                    onClick={handlerActions("toAccount")}
                  >
                    {settings.close}
                  </button>
                  <button 
                  style={dirty || Boolean(newAvatar.path) ? {opacity: '1'} : {opacity: '0.5'}} 
                  className="btns__submit"
                  onClick={handleSubmit}
                  type="submit" 
                  disabled={!dirty && !Boolean(newAvatar.path)}>
                    {settings.save}
                  </button>
                </div>
              </Form>
              )
            }}

          </Formik>
        </WrapContentTab>
        <AccFooter/>
        </>
      )}
      {selectedTab === "security" && (
        <>
        <WrapContentTab>
          <div className="content-security">
            <div className="security-elem">
              <div className="elem__head">
                <p>{settings.password}</p>
                <p onClick={() => setRecover(true)}>{settings.forgetPassword}</p>
              </div>
              <div className="elem__text">
                <p>{settings.currentPassword}</p>
                <button onClick={() => setRestorePass(true)}>{settings.change}</button>
              </div>
            </div>
            <div className="security-elem">
              <div className="elem__head">
                <p>Email</p>
                <p onClick={() => setModalEmail(true)}>{settings.change}</p>
              </div>
              <div className="elem__text">
                <p>{userData.email}</p>
              </div>
            </div>
          </div>
          <button
            className="security__close"
            onClick={handlerActions("toAccount")}
          >
            {settings.close}
          </button>
          {recover ? <RecoverPassword setRecover={setRecover} /> : null}
        </WrapContentTab>
        <AccFooter/>
        </>
      )}
      {selectedTab === "settings" && (
        <SettingsComponent handlerActions={handlerActions} />
      )}
      { RestorePass ? <RestorePassword setRestorePass={setRestorePass}/> : null}
      {ModalEmail ? <RestoreEmail setModalEmail={setModalEmail}/> : null}
      <ModalMessage />
    </WrapSettings>
  );
};
