import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {AddSubCategoryWrap, BtnsSubCategoryWrap} from "./addSubCategory.style";

export const AddSubCategory = ({setOpenSubCategory, subcategory}) => {
  const {exitIcon} = svg_icons;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setOpenSubCategory(false)}>
          {exitIcon}
        </CloseButton>
        <AddSubCategoryWrap>
          <Title>{"Новая подкатегория"}</Title>
          <div className="class_category_name">
            <p className="head_category_name">{"Класс:"}</p>
            <p className="head_category_text">{subcategory.CatalogName}</p>
          </div>
          <div className="subcategory_code">
            <p>{"Код подкатегории"}</p>
            <input placeholder={""} />
          </div>
          <div className="subcategory_name">
            <p>{"Название подкатегории"}</p>
            <input placeholder={""} />
          </div>
          <div className="description_wrap">
            <p className="head_text">{"Примечание"}</p>
            <textarea />
          </div>
          <BtnsSubCategoryWrap>
            <button className="back" onClick={() => setOpenSubCategory(false)}>
              {"Отменить"}
            </button>
            <button className="send_btn">{"Сохранить"}</button>
          </BtnsSubCategoryWrap>
        </AddSubCategoryWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
