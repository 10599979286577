import React, { useEffect, useState } from "react";
import {  useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filesUrl } from "../../API/api";
import { setAuthState, setTokenApp } from "../../store/authSlice/authSlice";
import { getUserData } from "../../store/profileSlice/profileAsyncThunk";

/*import logo from '../../assets/Components/Header/images/logo.webp'*/
import { socialIcons } from "../Footer/socialIcons.svg";
import { Login } from "../Registration/Login/Login";
import Language from "./Language/Language";
import {
  HeaderApp,
  WrapHeaderLogo,
  WrapNavBar,
  NavBarNormal,
  WrapNavBarFlex,
  NavBarItemNotActive,
  NavBarItemActive,
  NavBarItemText,
  WrapNavBarItem,
  LogInBtn,
  UserDataWrap,
  UserAvatar,
  UserTextWrap,
  BorderLine,
} from "./style/header_css.js";
import { Activate } from "../Activate/Activate";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import { AdditionalFunc } from "../AdditionalFunc/AdditionalFunc";
import { Registration } from "../Registration/Registration";
import { useOpenExhibition } from "../../hooks/useOpenExhibition";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavBurger } from "./NavBurger/NavBurger";
import { AppMessage } from "../../components/AppMessage/AppMessage";
import { setAppState } from "../../store/appSlice/appSlice";
import { resetProfileState } from "../../store/profileSlice/profileSlice";
import { svg_icons } from "../../assets/imgs/svg_icons";
import { ErrorHandlerUI } from "../ErrorHandlerUI/ErrorHandlerUI";

export default function Header() {
  const [activeMenu, setActiveMenu] = useState("blog");

  const { header: localText } = useSelector((state) => state.app.appText);
  const {messageHint} = useSelector((state) => state.app)
  const {editCompany} = useSelector((state) => state.admin)
  const { logo } = socialIcons;
  const {userIcon, logoutIcon} = svg_icons
  const location = useLocation();

  const { userData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const { open, openRegistration, openAdditional} = useSelector(state => state.auth)
  const {width} = useWindowDimensions()
  const [activateAcc, setActivateAcc] = useState(false);
  const [errModalOpen, setErrModalOpen] = useState(false);
  const navigate = useNavigate()
  const openExhibitions = useOpenExhibition();

  const { success, errors } = useSelector((state) => state.profile);
  const handlerActions = (key, data) => (e) => {
    switch (key) {
      case "contextMenu":
        dispatch(
          setAppState({
            contextMenu: {
              currentTarget: e.currentTarget,
              listMenu: [ !(location.pathname.includes('/account')) ?
                {
                  onClick: () => {
                    navigate(`/account/${userData.role === 'ROLE_ADMIN' ? 'admin-panel' : 'user-panel'}`)
                  },
                  icon: userIcon,
                  text: "Профиль",
                }
                :
                ''
                ,
                {
                    onClick: () => {
                        dispatch(setTokenApp());
                        dispatch(resetProfileState("userData"))
                        location.pathname.includes('/account') && navigate('/')
                    },
                    icon: logoutIcon,
                    text: "Выйти из профиля",
                  },
              ]
            }
        }))
        break;
        default:
            break;
    }
  }

  useEffect(() => {
    const nav = location.pathname.split("/");
    switch (nav[1]) {
      case "":
        setActiveMenu("blog");
        break;
      case "news":
        setActiveMenu("news");
        break;
      case "exhibition":
        setActiveMenu("exhibition");
        break;
      case "follow":
        setActiveMenu("follow");
        break;
      case "manual":
        setActiveMenu("manual");
        break;
      case "account":
        setActiveMenu("account");
        break;
      case "company":
        setActiveMenu("exhibition");
        break;
      default:
        setActiveMenu("blog");
        break;
    }
  }, [location.pathname]);

  const NavBarItem = {
    LogIn: activeMenu === "logIn" ? NavBarItemActive : NavBarItemNotActive,
    Exhibition:
      activeMenu === "exhibition" ? NavBarItemActive : NavBarItemNotActive,
    Follow: activeMenu === "follow" ? NavBarItemActive : NavBarItemNotActive,
    Blog: activeMenu === "blog" ? NavBarItemActive : NavBarItemNotActive,
    Manual: activeMenu === "manual" ? NavBarItemActive : NavBarItemNotActive,
    News: activeMenu === "news" ? NavBarItemActive : NavBarItemNotActive,
    Account: activeMenu === "account" ? NavBarItemActive : NavBarItemNotActive,
  };


  useEffect(() => {
    success !== "" ? setActivateAcc(true) : setActivateAcc(false);
    if (errors.length !== 0) {
      setErrModalOpen(true);
    }
  }, [success, errors]);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getUserData(token));
    }
    token && fetchData();
  }, [dispatch, token]);

  if(editCompany.open){
    return null
  }

  return (
    <>
      <HeaderApp id="app-header" style={location.pathname.includes('/account') && userData.role === 'ROLE_ADMIN' ? {display: 'none'} : {}}>
      {width <= 834 ? <NavBurger/> : null }
        <WrapHeaderLogo>{logo}</WrapHeaderLogo>
        {width > 834 ? <WrapNavBar>
          <NavBarNormal>
            <NavBarItem.Blog>
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <NavBarItemText>{localText.main}</NavBarItemText>
              </Link>
            </NavBarItem.Blog>
            <NavBarItem.News>
              <Link to={"/news"} style={{ textDecoration: "none" }}>
                <WrapNavBarItem>
                  <NavBarItemText>{localText.blog}</NavBarItemText>
                </WrapNavBarItem>
              </Link>
            </NavBarItem.News>
            <NavBarItem.Exhibition>
              <Link style={{ textDecoration: "none" }} to={"/exhibition"}>
                <NavBarItemText>{localText.exhibition}</NavBarItemText>
              </Link>
            </NavBarItem.Exhibition>
            <NavBarItem.Follow>
              <Link style={{ textDecoration: "none" }} to={"/follow"}>
                <NavBarItemText>{localText.participans}</NavBarItemText>
              </Link>
            </NavBarItem.Follow>
            <NavBarItem.Manual>
              <Link style={{ textDecoration: "none" }} to={"/manual"}>
                <NavBarItemText>{localText.info}</NavBarItemText>
              </Link>
            </NavBarItem.Manual>
            {!userData.firstName ? null : (
              <NavBarItem.Account>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/account/${userData.role === 'ROLE_ADMIN' ? 'admin-panel' : 'user-panel'}`}
                >
                  <NavBarItemText>{localText.account}</NavBarItemText>
                </Link>
              </NavBarItem.Account>
            )}
          </NavBarNormal>
          <BorderLine/>
          <NavBarNormal>
            <div className="Language_Wrapper">
                <Language height = {28} border={'none'} background={'#F6F6F6'}/>
            </div>
          </NavBarNormal>
          <BorderLine/>
          <div className="Href_Virtual_Exhibition"
              style={{
                textDecoration: "none",
                height: "28px",
              }}
              onClick={openExhibitions}
            >
              <NavBarItemText>
                <button className="Virtuals_Exhibition" >3D Выставка</button>
              </NavBarItemText>
            </div>
        </WrapNavBar> :
        null
        }
        <WrapNavBarFlex>
          {!userData.firstName ? (
            <LogInBtn
              onClick={() =>
                dispatch(setAuthState({ key: "open", value: true }))
              }
            >
              {localText.logIn}
            </LogInBtn>
          ) : (
            <UserDataWrap>
              <UserAvatar src={filesUrl + userData.avatar}></UserAvatar>
              <UserTextWrap style={{cursor: 'pointer'}} onClick={handlerActions("contextMenu")}>
                <p>{userData.firstName}</p>
                <p>{userData.lastName}</p>
              </UserTextWrap>
            </UserDataWrap>
          )}
        </WrapNavBarFlex>
      </HeaderApp>

      <Login />
      <Registration/>
      {
      !userData.firstName && 
      !open && 
      !openRegistration &&
      openAdditional &&
      location.pathname !== '/agreement' &&
      location.pathname !== '/policy-agreement'
       ?  
      <AdditionalFunc /> : 
      null
      }
      {activateAcc ? <Activate setActivateAcc={setActivateAcc} /> : null}
      {errModalOpen ? <ErrorModal setErrModalOpen={setErrModalOpen} /> : null}
      {messageHint && <AppMessage/>}
      <ErrorHandlerUI />
    </>
  );
}
