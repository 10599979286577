import styled from "styled-components";

const ModalQuestionWrap = styled.div`
padding: 0 16px;
    margin-top: 24px;
    margin-bottom: 30px;
    .Invalid_Input{
        font-weight: 400;
        font-size: 13px;
        line-height: 120%;
        color: red;
    }
    .MessageQuestion{
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #484848;
    }
    .Message_textarea{
        width: 100%;
    height: 83px;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    resize: none;
    font-family: 'Gilroy',sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 9px 12px;
    }
    .Btns_Menu_Wrap{
        display: flex;
        gap: 10px;
        button{
            width: 340px;
            height: 36px;
            border-radius: 8px;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
            cursor: pointer;
        }
        .BackBtn{
            color: #34C57F;
            background: #FFFFFF;
            border: 1px solid #34C57F;
        }
        .ChangeBtn{
            color: #FFFFFF;
            background: #34C57F;
            border: none;
        }
    }
`;

export {ModalQuestionWrap}