import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Messages from "./Messages";
import { setAppChatState } from "../../../../store/appChatSlice/appChatSlice";
import { checkDateForToday, dateToLocaleString, dateToLocaleTimeString } from "../../../../utils/helpers";
import { useEffect } from "react";
import { scrollTo } from './../../../../utils/helpers';

const MessagesContainer = () => {

    const dispatch = useDispatch();
    const {openDialog, userId} = useSelector(state => state.appChat);
    let messages = openDialog.messages;

    const handlerActions = (key) => (e) => {
        switch (key) {
            case "modalBlockUser":
                dispatch(setAppChatState({key: "modal", value: "blockUser"}))
                break;
            case "addToContact":
                dispatch(setAppChatState({key: "modal", value: "addToContact"}))
                break;
            case "scrollMessView":
                if(e.target.scrollTop < 5) {
                    e.target.scrollTop = 6;
                }

                break;
            default:
                break;
        }

    }

    useEffect(() => {
        if(messages) {
            scrollTo("scrollMessEnd");
        }
    }, [messages])


    if(!messages) return null;

    messages = (openDialog?.messages || []).map(mes => ({
        ...mes, 
        align: +userId === +mes.userId,
        time: checkDateForToday(mes.time) ? 
          dateToLocaleTimeString(mes.time, "sec") || "-" : dateToLocaleString(mes.time, null, "sec"),
      }))
      
    return <Messages handlerActions={handlerActions} openDialog={openDialog} messages={messages}/>
}

export default MessagesContainer