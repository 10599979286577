import { scroller } from "react-scroll";

export const viewDatePeriod = (from, to) =>
  `${dateToLocaleDateString(from)} - ${dateToLocaleDateString(to)}`;

export const getDateAgo = ({number = 1, type = "days", date = new Date()} = {}) => {
  let dateCopy = new Date(date);
  dateCopy.setHours(0, 0, 0);
  switch (type) {
    case "month":
      dateCopy.setMonth(date.getMonth() - number);
      break;
    case "year":
      dateCopy.setFullYear(date.getFullYear() - number);
      break;
    default:
      dateCopy.setDate(date.getDate() - number);
      break;
  }
  return dateCopy;
};

export const getParamFromUrl = (param) => new URLSearchParams(document.location.search).get(param);

export function isEmpty(obj) {
    for (var key in obj) {
      return false;
    }
    return true;
  }
  
  export const dateToLocaleTimeString = (date, hidden) => {
      let result = date ? new Date(date).toLocaleTimeString() : "-";
      switch (hidden) {
        case "sec":
          return result.substring(0, result.length - 3);
        default:
          return result;
      }
  };
  
  export const getFormDataFileForBody = (file) => {
    const fileFromData = new FormData();
    fileFromData.append('file', file);
    return fileFromData;
  };
  
  export const dateToLocaleString = (date, text, hidden) => {
      let result = date
        ? new Date(date).toLocaleString().replace(",", text ? ` ${text}` : ",")
        : "-";
      switch (hidden) {
        case "sec":
          return result.substring(0, result.length - 3);
        default:
          return result;
      }
  };

  export const dateToLocaleDateString = (date) =>
  date ? new Date(date).toLocaleDateString() : "-";
  
  export const checkDateForToday = (date) =>
    new Date(date).toLocaleDateString() === new Date().toLocaleDateString();
  
  export const scrollTo = (id, time = 0) => (
    setTimeout(() => {
      scroller.scrollTo(id, {
        duration: time * 1000,
        smooth: true,
        containerId: 'messages-scroll',
    })
    }, 0)
  );
  
  export const getInfoFile = (file) => {
    if (!file) return file;
  
    // const { get } = handlerLocalStorage();
    // let lang = get("language");
    // let text = (lang && langs[lang])?.baseWords;
  
    // test var
  
    let text = {
      'byte': 'b',
      'kilobyte': 'Kb',
      'megabyte': 'Mb',
      'gigabyte': 'Gb',
    }
  
    let sizeInformation = {
      kilobyte: 2 ** 10,
      megabyte: 2 ** 20,
      gigabyte: 2 ** 30,
    };
  
    const calcSize = (sizeFile) => {
      if (
        sizeFile >= sizeInformation.kilobyte &&
        sizeFile < sizeInformation.megabyte
      ) {
        return `${(sizeFile / sizeInformation.kilobyte).toFixed(1)} ${
          text.kilobyte
        }`;
      } else if (
        sizeFile >= sizeInformation.megabyte &&
        sizeFile < sizeInformation.gigabyte
      ) {
        return `${(sizeFile / sizeInformation.megabyte).toFixed(1)} ${
          text.megabyte
        }`;
      } else if (sizeFile >= sizeInformation.gigabyte) {
        return `${(sizeFile / sizeInformation.gigabyte).toFixed(1)} ${
          text.gigabyte
        }`;
      } else {
        return `${(+sizeFile).toFixed(1)} ${text.byte}`;
      }
    };
  
    return {
    ...file,
      lastModified: file.lastModified,
      shortName: (file.name || "file").substring(0, file.name?.lastIndexOf(".")),
      name: file.name,
      size: calcSize(file.size),
      type: file.type,
      format: file.name.slice(file.name.indexOf(".") + 1, file.name.length)
    };
  };
  
  export const getTimebySecond = (sec) => {
    let hours = Math.trunc(sec / 3600);
    if (hours < 10) hours = '0' + hours;
    let minutes = Math.trunc((sec - hours * 3600) / 60);
    if (minutes < 10) minutes = '0' + minutes;
    let seconds = sec % 60;
    if (seconds < 10) seconds = '0' + seconds;
    return hours + ':' + minutes + ':' + seconds;
  };
  
  export const getShortName = (lang) => {
    switch (lang) {
      case "ru":
        return "shortNameRu";
      case "en":
        return "shortNameEn";
      case "by":
        return "shortNameBy";
      default:
        return "shortNameRu";
    }
  };
    
  export const getName = (lang) => {
    switch (lang) {
        case "ru":
          return "nameRu";
        case "en":
          return "nameEn";
        case "by":
          return "nameBy";
        default:
          return "nameRu";
      }
  }

  export const getDescription = (lang) => {
    switch (lang) {
        case "ru":
          return "descriptionRu";
        case "en":
          return "descriptionEn";
        case "by":
          return "descriptionBy";
        default:
          return "descriptionRu";
      }
  }

  export const changeSizeFile = (bytes, decimals = 2) => {
    if (bytes === 0) {
		return '0';
	} else {
		var k = 1024;
		var dm = decimals < 0 ? 0 : decimals;
		var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
		var i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}
  }