import styled from "styled-components";

const ActivateWrap = styled.div`
    padding: 0 16px;
    margin-top: 15%;
    margin-bottom: 30px;
    text-align: center;
    .message{
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        margin: 16px;
    }
    .Activate_Acc{
        width: 340px;
        height: 36px;
        background: #34C57F;
        border-radius: 8px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
    }
`

export {
    ActivateWrap
}

