import styled from "styled-components";

const LinePanel = styled.div`
width: 100%;
height: 0.5px;
background: #D4D4D4;
border-radius: 2px;
margin: 0 16px 32px;
`;

const LinksWrap = styled.div`
display: flex;
flex-direction: column;
border-radius: 8px;
padding: 13px;
    position: relative;
div{
    width: 100%;
    font-weight: 500;
font-size: 14px;
line-height: 17px;
    color: #363636;
    display:flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    p{
        display: flex;
        align-items: center;
        gap: 6px;
        user-select: none;
    }
}
`

const SubNavMenu = styled.ul`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
    transition: 0.7s;
    li{
        list-style-type: none;
    }
    .user-menu__subnav-link{
        height: 40px;
        border-radius: 12px;
        border: none;
        font-weight: 500; 
        font-size: 14px;
        line-height: 17px;
        color: #363636;
        text-align: inherit;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 32px;
        gap: 8px;
        text-decoration: none;
        &.active {
            :before{
                content: '';
                display: inline-block;
                background-color: #FFFFFF;
                width: 4px;
                height: 4px;
                border-radius: 20px;
            }
            background: #34C57F;
            color: #FFFFFF;
        }
    }
`;

export {
    LinePanel, LinksWrap, SubNavMenu
}