import styled, { css } from "styled-components";


const WrapAppContextMenu = styled.div`
    position: absolute;
    z-index: 11;
    background: #FFFFFF;
    border-radius: 20px 0px 20px 20px;
    padding: 12px;
    
    ${({coordsCss}) => coordsCss && css`
    ${coordsCss}
    transform: translateX(-100%);
    `}
`;

const ListContextMenu = styled.div`
    display: grid;
    grid-gap: 10px;
    .list-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        background: #F5F5F5;
        border-radius: 12px;
        padding: 12px;
        cursor: pointer;
        color: #363636;
        p {
            white-space: nowrap;
            font-weight: 500;
            font-size: 13px;
        }
        .settings_icon{
            width: 18px;
            height: 18px;
        }
        .chats_icon{
            width: 18px;
            height: 18px;
        }
        svg {
            margin-right: 8px;
        }
        &.error {
           color: #FE564B;
           background: #FFF2F2;
        }  
    }
`;

export {
    WrapAppContextMenu,
    ListContextMenu,
}