import styled from "styled-components";

const WrapStatistics = styled.div`
  padding: 16px;
  .statistics-head {
    padding-bottom: 16px;
    h2 {
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      padding-bottom: 16px;
    }
    .head-filter {
      display: flex;
      align-items: center;
      .wrap-app-select {
        min-width: 160px;
      }
      .filter__download {
        width: 182px;
        height: 34px;
        background: #34c57f;
        border: 1px solid #34c57f;
        border-radius: 8px;
        color: #ffffff;
        margin-left: auto;
      }
    }
  }

  .wrap-bars {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .statistics-bar {
    display: flex;
    justify-content: center;
    flex-basis: calc(50% - 6px);
    max-width: 50%;
    width: calc(50% - 6px);
    // height: 150px;
    background: #f6f6f6;
    border-radius: 12px;
    padding: 0px 16px 10px 16px;
    canvas {
      max-width: 100%;
    }
  }
`;

export { WrapStatistics };
