export const footer_by = {
    about: "Апісанне",
    services: "Паслугі",
    partners: "Партнёры",
    pavilions: "Павільёны",
    follow: "Удзел у cleVR",
    blog: "Навіны",
    contacts: "Звязацца",
    link: "Анлайн выстава",
    mail: "Ёсць пытанні?",
    copyright: "Аўтарскае права",
    rights: "Усе правы абаронены v. 1.2.1",
    manual: 'Інструкцыі',
    documents: "Дакументы",
}