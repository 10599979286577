import { useSelector } from "react-redux"
import { SeoModalWrap } from "./seoModal.style"

export const SeoModal = ({handlerActions, componentState}) => {

    const {metaData} = useSelector((state) => state.metaTags)

    return (
        <SeoModalWrap>
            <div className="header_seo">
                <p className="head_text">{'Заголовок'}</p>
                <input 
                    value={metaData.title[componentState.language]} 
                    placeholder={'Введите название'} 
                    onChange={handlerActions('setMetaData', {type: 'title'})} 
                />
            </div>
            <div className="description_seo">
                <p className="head_text">{'Описание'}</p>
                <textarea 
                    value={metaData.description[componentState.language]} 
                    placeholder={'Введите текст'} 
                    onChange={handlerActions('setMetaData', {type: 'description'})} 
                />
            </div>
        </SeoModalWrap>
    )
}