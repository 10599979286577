import styled from "styled-components";

const MobileAppSection = styled.section`
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;
    padding: 0 34px;
    @media (max-width: 880px) {
        flex-direction: column;
    }
`;

const MobileAppContainer = styled.div`
    display: flex;
    justify-content: center;
    .image_app_container{
        max-height: 620px;
    @media (max-width: 980px) {
        margin-bottom: 20px;
    }
        img{
            width: 100%;
            height: 100%;
            max-height: 620px;
            object-fit: cover;
        }
    }
    @media (max-width: 980px) {
        flex-direction: column;
        align-items: center;
    }
`;


const MobileAppContent = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    @media (max-width: 980px) {
        padding-left: 0px;
    }
    .head_text_mobileApp{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        color: #3F3F3F;
        padding-bottom: 24px;
        -webkit-transition: all 0.4s linear 0s;
        -o-transition: all 0.4s linear 0s;
        transition: all 0.4s linear 0s;
        @media (max-width: 1025px) {
            font-size: 28px;
        }
        @media (max-width: 880px) {
            font-size: 26px;
        }
        @media (max-width: 660px) {
            font-size: 24px;
        }
        @media (max-width: 480px) {
            font-size: 20px;
        }
    }
    strong{
        color: #34C57F; 
    }
    .IOS_Android_text{
        font-family: 'Helvetica';
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        color: gray;
        padding: 24px 0 18px;
        strong{
            color: #363636;
        }
        @media (max-width: 1025px) {
            font-size: 28px;
        }
        @media (max-width: 880px) {
            font-size: 26px;
        }
        @media (max-width: 769px) {
            font-size: 24px;
        }
        @media (max-width: 480px) {
            font-size: 20px;
        }
    }
    .mobile_app_desc{
        max-width: 496px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #484848;
        padding-bottom: 33px;
    }
    .line{
        content: "";
        width: 80px;
        height: 1px;
        background: #3F3F3F;
        opacity: 0.95;
        border-radius: 1px;
    }
`;

const BtnWrap = styled.div`
    display: flex;
`;

const installMobileApp = styled.button`
    width: 152px;
    height: 34px;
    border: 1.4px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: none;
    cursor: pointer;
    margin-right: 10px;
    :hover{
        background: #34C57F;
        color: #fff;
        transition: 0.1s ease-out 0.3s;
    }
`;

export {MobileAppSection, MobileAppContent, installMobileApp, BtnWrap, MobileAppContainer}