import React from "react";
import AppChat from "./AppChat";

import useWebSocket from './../../hooks/useWebSocket';

const AppChatContainer = () => {

  // let mobileVersion = useSelector((state) => state.appChat.mobileVersion);
  let mobileVersion = false;
  // useWebSocket();

  return <AppChat mobileVersion={mobileVersion} />;

};

export default AppChatContainer;
