import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./MetaTagsReducers";
import { extraReducers } from "./MetaTagsExtraReducers";

export const initialState = {
    metaData: {
        title: {
            ru: '',
            by: '',
            en: ''
        },
        description: {
            ru: '',
            by: '',
            en: ''
        }
    },
    metaError: null
};

export const MetaTagsSlice = createSlice({
  name: "ProfileSlice",
  initialState,
  reducers,
  extraReducers
});

export const {setMetaTagsState, resetMetaTagsState} = MetaTagsSlice.actions;


export const MetaTagsReducer = MetaTagsSlice.reducer;