import {useEffect} from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {svg_icons} from "../../assets/imgs/svg_icons";
import {setProfileState} from "../../store/profileSlice/profileSlice";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../Registration/Login/style/Login_css";
import {ModalMessageWrap} from "./ModalMessage.style";

export const ModalMessage = ({isConfirm}) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const {message, errorMessage, openModal} = useSelector(
    (state) => state.profile
  );
  const {exitIcon} = svg_icons;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    message !== "" ? setTitle("Успешно отправлено!") : setTitle("Ошибка");
    message !== "" ? setText(message) : setTitle(errorMessage);
  }, [errorMessage, message]);

  if (!openModal) return null;

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton
          onClick={() =>
            dispatch(setProfileState({key: "openModal", value: false}))
          }
        >
          {exitIcon}
        </CloseButton>
        <ModalMessageWrap>
          <Title>{title}</Title>
          <p className="message">{text}</p>
          <button
            onClick={() =>
              isConfirm
                ? navigate("/account/user-panel")
                : dispatch(setProfileState({key: "openModal", value: false}))
            }
          >
            Понятно, спасибо!
          </button>
        </ModalMessageWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
