import styled from "styled-components";

const NewsContainer = styled.div`
padding: 64px 0 0;
`;

const NewsWrap = styled.div`
    background: #FFFFFF;
`;

const NewsSearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    z-index: 1;
    position: sticky;
    top: 64px;
    border-top: 0.5px solid #D4D4D4;
    border-bottom: 0.5px solid #D4D4D4;
    padding: 10px 16px;
  p{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 110%;
    min-width: 150px;
    margin-right: 16px;
  }
`;

const SearchNewsInput = styled.div`
    width: 518px; 
    height: 34px;
    display: flex;
    align-items: center;
    background: #F4F4F4;
    border-radius: 10px;
    padding-left: 10px;
    input{
        width: calc(100% - 20px);
        height: 100%;
        border: none;
        outline: none;
        background: #F4F4F4;
        border-radius: 10px;
    }
`;

const NewsSetting = styled.button`
    background: url(${props => props.bg}) center no-repeat;
    width: 34px;
    height: 34px;
    border: none;
    cursor: pointer;
    background-color: #F4F4F4;
    border-radius: 10px;
    margin-right: 16px;
`;

const NewsItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px;
    gap: 16px;
    padding: 16px 0 40px;
`;

const NewsItemWrap = styled.div`
    width: calc(1/5*100% - (1 - 1/5)*16px);
    @media (max-width: 1440px) {
        width: calc(1/4*100% - (1 - 1/4)*16px);
    }
    @media (max-width: 1200px) {
        width: calc(1/3*100% - (1 - 1/3)*16px);
    }
    @media (max-width: 900px) {
        width: calc(1/2*100% - (1 - 1/2)*16px);
    }
    @media (max-width: 620px) {
        width: 100%;
    }
`;

const NewsLogo = styled.div`
    position: relative;
    height: 196px;
    img{
        width: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
`;

const NewsItemContent = styled.div`
    background: #F8F8F8;
    height: 213px;
.head_name_news{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    padding: 11px 16px;
    height: 70px;
}
`;

const DateNewsContainer = styled.div`
    max-width: 340px;
    height: 34px;
    border-top: 0.5px solid #D4D4D4;
    border-bottom: 0.5px solid #D4D4D4;
    margin: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-weight: 400;
        font-size: 12px;
        line-height: 110%;
        color: #363636;
    }
    @media (max-width: 1200px) {
        max-width: 385px;
    }
    @media (max-width: 620px) {
        max-width: 539px;
    }
`;

const ViewsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    p{
        font-weight: 400;
        font-size: 12px;
        line-height: 110%;
        color: #363636;
    }
`;

const DescContainer = styled.div`
    margin: 0 16px;
    div{
        max-width: 316px;
        font-weight: 400;
        font-size: 13px; 
        line-height: 16px;
        padding: 9px 0px 12px 0px;
        color: #363636;
        h2{
            font-family: 'Helvetica';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 17px;
            color: #363636;
            padding-bottom: 5px;
        }
    }
    .news_text_container{
        height: 72px;
    }
`;

const ReadMore = styled.button`
    width: 117px;
    height: 29px;
    border: 1.4px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    cursor: pointer;
    background: #F8F8F8;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
`;

export { NewsContainer, NewsWrap, 
    NewsSearchBlock, SearchNewsInput, 
    NewsSetting, NewsItemsContainer, 
    NewsLogo, NewsItemWrap, 
    NewsItemContent, DateNewsContainer, 
    DescContainer, ReadMore, ViewsContainer
}