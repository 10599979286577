import styled from "styled-components";

const CreateNewsContainer = styled.div`
padding: 0 12px 0 16px;
height: 81vh;
overflow-y: auto;
overflow-x: hidden;
.name_news{
    display: flex;
    width: 100%;
    gap: 16px;
    margin: 20px 0 25px;
    .short_name{
        width: 100%;
    }
    .full_name{
        width: 100%;
    }
}
.header_text{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding-bottom: 5px;
}
.input_text{
    width: 100%;
    background: #F5F5F5;
    border-radius: 4px;
    border: none;
    outline: none;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    padding: 0 12px;
}
textarea{
        width: 100%;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 9px 12px;
    }
.drafts{
    margin-bottom: 25px;
    .drafts_info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        padding: 0 12px;
        .drafts_text{
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 110%;
            color: #363636;
        }
    }
}
.text_news{
    margin-bottom: 25px;
}
.gallery_news{
    margin-bottom: 25px;
    .head_gallery_wrap{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
    }
    .gallery_files_wrap{
        display: flex;
        width: 100%;
        height: 100%;
        .slick-slider{
            width: 100%;
            .slick-prev{
                display: none !important;
            }
            .slick-next{
                width: 32px;
                height: 33px;
                right: 10px;
                ::before{
                    content: none;
                }
                :hover{
            .arrow-background{
                fill: white;
            }
            .arrow_1{
                stroke: #363636;
            }
            .arrow_2{
                stroke: #363636;
            }
    }
            }
            .slick-track{
                min-width: 100% !important;
                display: flex !important;
                width: 100% !important;
                .slick-slide{
                    width: auto !important;
                }
            }
        }
        .media_content_wrap{
            position: relative;
            height: 190px;
            img{
                width: 390px;
                height: 190px;
                object-fit: cover;
            }
            :hover{
                .btns_edit_wrap{
                    display: flex;
                }
                .add_preview{
                    display: flex;
                }
            }
            .add_preview{
                position: absolute;
                bottom: 0;
                width: 100%;
                display: none;
                align-items: flex-end;
                button{
                    background: #000000;
                    width: 100%;
                    height: 18px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                }
                .wrap_preview{
                    width: 100%;
                    background: #34C57F;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 110%;
                    color: #FFFFFF;
                    height: 18px;
                    cursor: pointer;
                    svg{
                        width: 16px;
                        height: 16px;
                        margin-right: 2px; 
                        path{
                            fill: #FFFFFF;
                            stroke: #FFFFFF;
                        }
                    }
                }
                }
            .btns_edit_wrap{
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                display: none;
                align-items: center;
                justify-content: center;
                .delete{
                    border: none;
                    background: none;
                    cursor: pointer;
                    svg{
                        cursor: pointer;
                        path{
                            fill: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
    .add_file{
        display: block !important;
        width: 100%;
        background: #F5F5F5;
        border-radius: 4px;
        position: relative;
            .add_gallery
            {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #363636;
                svg{
                    path{
                        fill: #363636;
                    }
                }
            }
        input{
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}
.author_news{
    margin-bottom: 25px;
    .author_head{
        display: flex;
        justify-content: space-between;
        .add_author{
            background: #F5F5F5;
            border-radius: 8px;
            border: none;
            height: 30px;
            width: 158px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 140%;
            gap: 8px;
            svg{
                path{
                    fill: #363636;
                }
            }
        }
    }
    .author_wrap{
        margin-top: 12px;
        height: 90px;
        background: #F5F5F5;
        border: 0.5px solid #D4D4D4;
        border-radius: 4px;
    }
}
`;

const NewsTitle = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
height: 70px;
border-bottom: 0.5px solid #D9D9D9;
padding: 0 16px;
.title_wrap{
    display: flex;
align-items: center;
    button{
    width: 28px;
    height: 28px;
    border: none;
    background: #F5F5F5;
    border-radius: 10px;
    cursor: pointer;
}
h3{
    font-family: 'Helvetica';
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    margin-left: 16px;
}
}
.btns_wrap{
    display: flex;
    div{
        display: flex;
        gap: 10px;
    }
    button{
        width: 182px;
        height: 36px;
        cursor: pointer;
        border-radius: 8px;
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
    }
    .add_drafts{
        border: 1px solid #484848;
        background: #FFFFFF;
        color: #484848;
        margin-right: 16px;
    }
    .preview{
        border: 1px solid #34C57F;
        color: #34C57F;
        background: #FFFFFF;
    }
    .save_news{
        border: none;
        color: #FFFFFF;
        background: #34C57F;
    }
}
`

const CreateNewsWrap = styled.div`
    width: calc(100% - 283px);
`;

export {
    CreateNewsContainer,
    NewsTitle,
    CreateNewsWrap
}