import styled from "styled-components";

const ContactsPageWrap = styled.div`
margin-top: 25px;
padding: 0 16px;
`;

const DepartmentContactsCard = styled.div`
margin-bottom: 50px;
.department_wrap{
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #D9D9D9;
    padding-bottom: 10px;
    margin-bottom: 16px;
    .text_wrap{
h3{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #363636;
}
p{
    font-family: 'Gilroy';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #363636;
}
    }
    .btns_control_wrap{
        display: flex;
        gap: 8px;
        button{
            width: 158px;
            height: 30px;
            border-radius: 8px;
            cursor: pointer;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
            color: #363636;
        }
        .add_department{
            background: #F5F5F5;
            border: none;
            svg{
                path {
                    fill: #363636;
                }
            }
        }
        .add_contact{
            background: #FFFFFF;
            border: 1px solid #34C57F;
            color: #34C57F;
            svg{
                path{
                    fill: #34C57F;
                }
            }
        }
    }
}
.contacts_container{
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}
`

const ContactsCard = styled.div`
    width: 340px;
    height: 86px;
    background: #F5F5F5;
    border-radius: 4px;
    position: relative;
.contact_header{
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    gap: 8px;
}
.contact_info_text{
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #484848;
    padding-top: 7px;
    padding-left: 4px;
}
.dots_settings{
    position: absolute;
    width: 24px;
    height: 24px;
    border: none;
    background: #EBEBEB;
    border-radius: 8px;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
`;


export {
    ContactsPageWrap,
    DepartmentContactsCard,
    ContactsCard
}