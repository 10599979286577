import styled, { css } from "styled-components";


const WrapHeaderLeftBox = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 17px;

    .chat__back {
        width: 26px;
        height: 26px;
        background: #F6F6F6;
        border-radius: 10px;
        margin-right: 10px;
    }

    h2 {
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
        color: #363636;
        margin-right: auto;
    }

    .head__margin {
        width: 10px;
    }

    .mobile-head__roll-up {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #363636;
        margin-left: auto;
        cursor: pointer;
        .roll-up__icon {
            display: flex;
            margin-left: 8px;
            width: 24px;
            height: 24px;
            background: #f4f4f4;
            border-radius: 8px;
            border: none;
            cursor: pointer;
            & > svg {
              margin: auto;
              display: block;
            }
          }
      }
`;

const WrapSearch = styled.div`
    background: #F5F5F5;
    border-radius: 10px;

    ${({mobileVersion}) => mobileVersion && css`
        flex: 1;
        margin-top: 16px;
    `}

    .search__close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        width: 20px;
        height: 20px;      
        background: #E9E9E9;
        border-radius: 16px;
        svg {
            width: 7px;
            height: 7px;
            margin: auto;
            display: block;    
        }
    }

    .search__btn {
        width: 32px;
        height: 32px;
        border: none;
        background: transparent;
        cursor: pointer;
        & > svg {
            display: block;
            margin: auto;
        }
    }

    .search-mobile {
        display: flex;
        align-items: center;
        padding: 7.5px 10px;
        input {
            &::placeholder {font-size: 13px;
            line-height: 16px;
            color: #363636;
            opacity: 0.5;}
            flex: 1;
            padding-left: 6px;
            background: transparent;
            border: none;
            outline: none;
        }
    }

    ${({openSearch}) => openSearch && css`
    position: relative;
    flex: 1;
    height: 32px;
    .search-icon {
        width: 16px; 
        height: 16px; 
        path {
            fill: #34C57F;
            stroke: #34C57F;
        }
    }
    .search-mobile {
        input {
            padding: 0px 20px 0 6px;
        }
    }
`}
`;

export {
    WrapHeaderLeftBox,
    WrapSearch,
}