import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filesUrl } from "../../../../API/api";
import { svg_icons } from "../../../../assets/imgs/svg_icons";
import { 
    setExhibitorFile, 
    setExhibitorLangObj, 
    setExhibitorLanguage, 
    setOpenSettings 
} from "../../../../store/exhibitorSlice/exhibitorSlice";
import { getInfoFile } from "../../../../utils/helpers";
import { SettingsContent } from "../../../SettingsContent/SettingsContent";
import { SeoSettingsWrap } from "../../Admin/Companies/CreateCompany/CreateCompany.style";
import { SettingsUserBtns, UserSettings } from "../../Admin/Users/AllUsers/AllUsers.style";
import {
  HeadScreenWrap,
  MainInfoWrap,
  PreviewWrap,
} from "./headScreenExhibitor.style";

export const HeadScreenExhibitor = () => {
  const {addIcon, trashIcon, pointsIcon } = svg_icons
  const {name, language, cover, openSettings, logo} = useSelector((state) => state.exhibitor)
  const dispatch = useDispatch()

  const SettingLogo = [
    {
      component: (
        <SettingsUserBtns onClick={() => 
        {
        dispatch(setExhibitorFile({key: 'logo', value: ''}))
        dispatch(setOpenSettings({key:-1, open: false}))
        }
        } style={{ background: "#FFF2F2", color: "#FE564B" }}>
          {trashIcon} {"Удалить"}
        </SettingsUserBtns>
      ),
    },
  ];

  const SettingCover = [
    {
        component: (
          <SettingsUserBtns onClick={() => 
          {
            dispatch(setExhibitorFile({key: 'cover', value: ''}))
            dispatch(setOpenSettings({key:-1, open: false}))
          }
          } style={{ background: "#FFF2F2", color: "#FE564B" }}>
            {trashIcon} {"Удалить"}
          </SettingsUserBtns>
        ),
      },
  ]


  return (
    <HeadScreenWrap>
      <MainInfoWrap>
        <h3>{"Логотип"}</h3>
        <div className="logo_container">
          <div className="show_logo">
            {Boolean(logo.path) ? 
                <img
              alt="preview_logo"
              src={Boolean(logo.file) ? logo.path  : filesUrl + logo.path}
              /> : 
            <div className="logo_none">
                <label>
                    <input type={'file'} accept={'.svg'} onChange={(e) => dispatch(setExhibitorFile({key: 'logo', value: e.target.files[0]}))}/>
                    {addIcon}
                    <p>{'Добавьте логотип'}</p>
                </label>
            </div>
            }
          </div>
          <div className="logo_info">
          {Boolean(logo.file) ?
            <div className="file_info">
                <div>
                    <p className="file_name">{getInfoFile(logo.file).name}</p>
                    <p className="file_size">{getInfoFile(logo.file).size}</p>
                </div>
            </div>
            :
            <p className="logo_name">{"Logo.svg"}</p>
          }
            <UserSettings>
                <button className="dots_settings" onClick={() => dispatch(setOpenSettings({key:"logo", open: true}))}>{pointsIcon}</button>
                {openSettings.open && openSettings.key === "logo" ? <SettingsContent exhibitor={true} setOpenSettings={setOpenSettings} SettingsItems={SettingLogo}/> : null}
            </UserSettings>
          </div>
        </div>
        <div className="expanents_name">
          <p className="expanents_text">{"Название на странице"}</p>
          <SeoSettingsWrap>
            <div className="general_seo_data" style={{justifyContent: 'space-between', width: '100%'}}>
              <p style={{color: '#666666'}}>{"Язык ввода:"}</p>
              <div style={{display: 'flex', gap: '10px'}}>
              <button
                className={`seo_language ${
                  language === "ru" ? "active" : ""
                }`}
                onClick={() => dispatch(setExhibitorLanguage('ru'))}
              >
                {"РУС"}
              </button>
              <button
                className={`seo_language ${
                  language === "en" ? "active" : ""
                }`}
                onClick={() => dispatch(setExhibitorLanguage('en'))}
              >
                {"ENG"}
              </button>
              <button
                className={`seo_language ${
                  language === "by" ? "active" : ""
                }`}
                onClick={() => dispatch(setExhibitorLanguage('by'))}
              >
                {"БЕЛ"}
              </button>
              </div>
            </div>
            </SeoSettingsWrap>
          <div className="name_container">
            <p>{"Название предприятия"}</p>
            <textarea value={name[language]} onChange={(e) => dispatch(setExhibitorLangObj({key: 'name', value: e.target.value}))}/>
          </div>
        </div>
      </MainInfoWrap>
      <PreviewWrap>
        <h3 className="head_text">{'Обложка'}</h3>
        <div className="cover_wrap" style={{height: '460px'}}>
        {
        Boolean(cover.path) ?
            <Fragment>
                <div className="preview_cover">
                <img alt="cover_exp" src={Boolean(cover.file) ? cover.path  : filesUrl + cover.path} />
                        <div className="file_info">
                        {Boolean(cover.file) ?
                            <div>
                                <p className="file_name">{getInfoFile(cover.file).name}</p>
                                <p className="file_size">{getInfoFile(cover.file).size}</p>
                            </div> 
                            :
                            null
                        }
                        <UserSettings style={{marginLeft: 'auto'}}>
                                <button className="dots_settings" onClick={() => dispatch(setOpenSettings({key: "cover", open: true}))}>{pointsIcon}</button>
                                {openSettings.open && openSettings.key === "cover" ? <SettingsContent exhibitor={true} setOpenSettings={setOpenSettings} SettingsItems={SettingCover}/> : null}
                        </UserSettings>
                        </div>
                </div>
            </Fragment> 
            :
            <div className="cover_none">
                <label>
                    <input type={'file'} accept={'image/png, image/gif, image/jpeg'} onChange={(e) => dispatch(setExhibitorFile({key: 'cover', value: e.target.files[0]}))}/>
                    {addIcon}
                    <p>{'Добавьте подложку на ваше предприятие'}</p>
                </label>
            </div>
            }
        </div>
      </PreviewWrap>
    </HeadScreenWrap>
  );
};
