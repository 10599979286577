import styled from "styled-components";

const PartnersHeader = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ContentTitle = styled.div`
    padding: 75px 0 15px;
    h2{
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        color: #363636;
    }
`;

const ContentText = styled.div`
  margin-bottom: 30px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #484848;
    max-width: 430px;
    text-align: center;
  }
`;


export {PartnersHeader, ContentText, ContentTitle};
