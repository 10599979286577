import styled from "styled-components";

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  justify-content: center;

  img {
    width: auto;
    max-height: 50%;
    pointer-events: none;
  }
`

const ItemContainer = styled.div`
    background: #FFFFFF;
    width: 270px;
`;

const ItemTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  text-align: center;
  text-decoration: none;
  color: #d7d2d2;
  min-width: 152px;
  padding-top: 15px;
`

const CompaniesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 91px;
  margin: 0 32px;
  justify-content: center;
  gap: 16px;
  a{
    text-decoration: none;
  }
`

export {Item, ItemTitle, CompaniesContainer, ItemContainer};
