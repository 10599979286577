import React from "react";
import { useDispatch, useSelector } from "react-redux";
import icons_svg from "../imgs/icons_svg";
import { WrapBaseModal } from "./modalsChat.style";
import SendFile from "./SendFile";
import NewDialog from "./NewDialog";
import ModalBackground from "../../ModalBackground/ModalBackground";
import { resetAppChatState, setAppChatState, setOpenDialogState } from "../../../store/appChatSlice/appChatSlice";
import useSendFiles from './../../../hooks/useSendFiles';
import { createDialog, deleteDialog, getDialogs, toggleAddUser, toggleBlockUser } from "../../../store/appChatSlice/appChatExtraReducers";
import { filesUrl } from "../../../API/api";
import { useNavigate } from "react-router-dom";
import { getContactsList} from "../../../store/profileSlice/profileAsyncThunk";

const ModalsChat = () => {
  const { modal: {type, content: contentModal}, openDialog, mobileVersion } = useSelector((state) => state.appChat);
  const {modals: localText} = useSelector(state => state.app.appText)
  const dispatch = useDispatch();
  const sendFiles = useSendFiles()
  const navigate = useNavigate(); 
  const { closeModal } = icons_svg;

  const hanlderActions = (key, data) => async () => {
    dispatch(resetAppChatState("modal"));
    switch (key) {
      case "blockUser":
      case "unblockUser":
      case "blockUserFromContacts":
      case "unblockUserFromContacts":
        await dispatch(toggleBlockUser({userId: data, status: key === "blockUser" || key === "blockUserFromContacts" ? "BLOCK" : "UNBLOCK"}));
        key === "blockUser" || key === "unblockUser" ? dispatch(getDialogs()) : dispatch(getContactsList(""));
        openDialog?.user && dispatch(setOpenDialogState({...openDialog, user: {...openDialog.user, block: key === "blockUser" ? 1 : 0}}));
        break;
      case "addToContact":
      case "removeContact": 
      case "removeContactFromContacts":              
        dispatch(toggleAddUser({userId: data, method: key === "addToContact" ? "post" : "delete"}));
        break;
      case "closeModal":
        dispatch(resetAppChatState("modal"));
        break;
      case "closeSendFile":
        dispatch(resetAppChatState("modal"));
        dispatch(setOpenDialogState({sendFile: null}))
        break;
      case "sendFile":
        sendFiles(data);
        console.log(data)
      break;
      case "createNewDialog":
        await dispatch(createDialog({body: data, navigate: mobileVersion ? false : navigate}));
        break;
      case "removeDialog":
          dispatch(deleteDialog(data));
          (!mobileVersion && data === openDialog.dialogId) && navigate("/account/chat");
          (mobileVersion && data === openDialog.dialogId) && dispatch(resetAppChatState("openDialog"));
        break;
      default:
        break;
    }

  };

  const buildModal = (key) => {
    const closeModalBtn = (
      <button
        className="close-modal-btn"
        onClick={hanlderActions("closeModal")}
      >
        {closeModal}
      </button>
    );
    let content;
    switch (key) {
      case "blockUser":
      case "blockUserFromContacts":
        console.log(contentModal)
        content = {
          h3: key === "blockUser" ? localText.block.chatH1 : localText.block.userH1,
          srcImg: filesUrl + contentModal.avatar,
          name: contentModal.firstName,
          text1: localText.block.beBlocked,
          text2: localText.block.cantSendMes,
          btnCancel: localText.cancel,
          btnSubmit: localText.block.yesBlock,
          onApply: hanlderActions(key, contentModal.userId || contentModal.id),
        };
        break;
      case "unblockUser":
      case "unblockUserFromContacts":
        console.log(contentModal)
        content = {
          h3: key === "unblockUser" ? localText.unblock.chatH1 : localText.unblock.userH1,
          srcImg: filesUrl + contentModal.avatar,
          name: contentModal.firstName,
          text1: localText.unblock.beUnblocked,
          text2: localText.unblock.canSendMes,
          btnCancel: localText.cancel,
          btnSubmit: localText.unblock.yesUnblock,
          onApply: hanlderActions(key, contentModal.userId || contentModal.id),
        };
        break;

      case "clearHistory":
        content = {
          h3: "Очистить переписку",
          srcImg: filesUrl + (contentModal.avatar || contentModal.src),
          name: "Анастасия и вы.",
          text1: "Ваша история переписки",
          text2: "будет безвозвратно очищена",
          btnCancel: "Отменить",
          btnSubmit: "Да, очистить",
          onApply: () => dispatch(setAppChatState({key: "toast", value: true})),
        };
        break;
      case "addToContact":
        content = {
            h3: `${contentModal.firstName || ""} ${contentModal.lastName || ""}`,
            role: `${contentModal.position || ""}`,
            srcImg: filesUrl + (contentModal.avatar || contentModal.src),
            text1: "Добавить пользователя в список",
            text2: "ваших контактов?",
            btnCancel: "Не добавлять",
            btnSubmit: "Да, добавить в контакты",
            onApply:  hanlderActions("addToContact", contentModal.userId ? contentModal.userId : contentModal.id),
          };
          break;
      case "removeContact":       
      case "removeContactFromContacts":       
        content = {
            h3: localText.removeContact.head,
            srcImg: filesUrl + (contentModal.avatar || contentModal.src),
            name: contentModal.firstName,
            text1: localText.removeContact.beRemoved,
            text2: localText.removeContact.yourContacts,
            btnCancel: localText.removeContact.noRemove,
            btnSubmit: localText.removeContact.yesRemove,
            onApply:  hanlderActions(key, contentModal.userId ? contentModal.userId : contentModal.id),
          };
          break;
        case "removeDialog":  
        content = {
            h3: localText.removeChat.head,
            text1: localText.removeChat.subhead1,
            text2: localText.removeChat.subhead2,
            btnCancel: localText.removeChat.noRemove,
            btnSubmit: localText.removeChat.yesRemove,
            onApply: hanlderActions(key, contentModal.dialogId),
          };
          break;
      case "addFileToMessage":      
        return (<SendFile hanlderActions={hanlderActions}/>)
      case "createNewDialog":      
        return (<NewDialog hanlderActions={hanlderActions}/>)
      default:
        return null;
    }
    return (
      <WrapBaseModal type={key}>
        {closeModalBtn}
        <h3>{content.h3}</h3>
        {content.role && <p className="add__role">{content.role}</p>}
        {content.srcImg && <img src={content.srcImg} alt="user_photo" />}
        <p className="base-text">
          <span className="text__name">{content.name}</span> {content.text1}
          <span>{content.text2}</span>
        </p>
        <div className="base-btns">
          <button onClick={hanlderActions("closeModal")}>
            {content.btnCancel}
          </button>
          <button onClick={content.onApply}>{content.btnSubmit}</button>
        </div>
      </WrapBaseModal>
    );
  };

  // console.log(type, userId)

  if (!type) return null;

  return (
    <ModalBackground onClose={hanlderActions("closeModal")}>
      {buildModal(type)}
    </ModalBackground>
  );
};

export default ModalsChat;
