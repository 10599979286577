
// SEO. Title must be the same as link in header!

export const news_en = {
    title: "News",
    blog: "Blog",
    button: "read all news",
    openNews: "Open a news item",
    rollUp: 'Roll up',
    expand: 'Expand',
    addNews: 'Add news',
    editNews: 'Edit',
    editPage: {
        btnBack: 'Back',
        btnSave: 'Save',
        logo: 'Image',
        btnLogo: 'Add an image',
        title: 'Title',
        name: 'Name',
        content: 'Content',
        showNews: 'Show news',
        btnDelete: 'Delete a news item',
    },
}
