import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../../services/requests.services";

export const GetRoles = createAsyncThunk('AdminSlice/GetRoles', async () => {
    const {AdminApi} = getRequest()
    const response = await AdminApi.getAllRoles()
    return response
})

export const GetAdminPavilions = createAsyncThunk('AdminSlice/GetAdminPavilions', async (search) => {
    const  {AdminApi} = getRequest()
    const response = await AdminApi.getAdminPavilions()
    return response
})

export const GetAdminExpanents = createAsyncThunk('AdminSlice/GetAdminExpanents', async (search) => {
const {AdminApi} = getRequest()
const response = await AdminApi.getAdminExpanents(search)
return response
})

export const AdminGetUsers = createAsyncThunk('AdminSlice/AdminGetUsers', async (search) => {
    const {AdminApi} = getRequest()
    const response = await AdminApi.getAllUsers(search)
    return response
})

export const CreateCompany = createAsyncThunk('AdminSlice/CreateCompany', async (data) => {
    const {AdminApi} = getRequest()
    const response = await AdminApi.createCompany(data)
    return response
})

export const updateCompany = createAsyncThunk('AdminSlice/updateCompany', async (data) => {
    const {AdminApi} = getRequest()
    const response = await AdminApi.updateCompany(data)
    return response
})

export const getCompanyInfo = createAsyncThunk('AdminSlice/getCompanyInfo', async (id) => {
    console.log(id)
    const {AdminApi} = getRequest()
    const response = AdminApi.getCompanyInfo(id)
    return response
})

export const AdminCreatePavilion = createAsyncThunk('AdminSlice/CreatePavilion', async (pavilion) => {
    const {AdminApi} = getRequest()
    const response = await AdminApi.createPavilion(pavilion)
    console.log(response)
    return response
})

export const deleteAdminCompany = createAsyncThunk('AdminSlice/deleteAdminCompany', async (companyName) => {
    const {AdminApi} = getRequest()
    const response = AdminApi.deleteCompany(companyName)
    return {...response, companyName}
})