import styled from "styled-components";


const SearchBlockContent = styled.div`
    position: absolute;
    width: 672px;
    height: 106px;
    top: 645px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
p{
    color: #363636;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    width: 626px;
    padding-top: 16px;
}
`;

const SearchBlockForm = styled.div`
    display: flex;
    align-items: center;
    width: 624px;
    height: 40px;
    border: 1px solid #363636;
    border-radius: 10px;
    padding-left: 12px;
    padding-right: 1px;
    div{
        content: "";
        width: 1px;
        height: 18px;
        background: #363636;
        border-radius: 2px;
        margin-right: 8px;
    }

    .react-datalist-input__container{
    border: none;
    height: 95%;
    width: 284px;
    }
`;
const SearchBlockInput = styled.input`
padding-left: 12px;
    border: none;
    height: 95%;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #363636;
    font-family: 'Gilroy';
    :active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
    }
`;

const SearchBlockSelect = styled.select`
    font-family: 'Gilroy';
    border: none;
    outline: none;
    width: 200px;
    height: 95%;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    color: #363636;
`;

const SearchBlockBtn = styled.button`
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-decoration: none;
    border: none;
    width: 98px;
    height: 36px;
    cursor: pointer;
    background: #34C57F;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #FFFFFF;
    :active, :hover, :focus {
        border: none;
        outline: 0;
        outline-offset: 0;
    }
`;

export {
    SearchBlockContent,
    SearchBlockInput,
    SearchBlockSelect,
    SearchBlockBtn,
    SearchBlockForm
}