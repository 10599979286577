import styled from "styled-components";

const AddProductGroupWrap = styled.div`
padding: 24px 16px 0;
.class_subcategory_name{
    margin: 7px 0 15px;
    display: flex;
    gap: 5px;
    text-align: center;
    justify-content: center;
    .head_subcategory_name{
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        color: #484848;
    }
    .head_subcategory_text{
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #484848;
    }
}

input{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        width: 100%;
        height: 36px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 0 12px;
    }
    .product_code{
        p{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding-bottom: 5px;
        }
    }
    .product_name{
        margin: 25px 0;
        p{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding-bottom: 5px;
        }
    }
    .description_wrap{
        margin-bottom: 25px;
        p{
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #363636;
            padding-bottom: 5px;
        }
        textarea{
        width: 100%;
        height: 108px;
        background: #F5F5F5;
        border-radius: 4px;
        border: none;
        outline: none;
        resize: none;
        font-family: 'Gilroy',sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
        padding: 9px 12px;
    }
    }
`;

const BtnsProductGroupWrap = styled.div`
display: flex;
gap: 10px;
margin: 30px 0;
width: 100%;
.back{
    width: 100%;
    height: 32px;
    border: 1px solid #34C57F;
    border-radius: 8px;
    color: #34C57F;
    background: none;
    cursor: pointer;
}
.send_btn{
    width: 100%;
    background: #34C57F;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    height: 32px;
    cursor: pointer;
}
`;

export {
    AddProductGroupWrap,
    BtnsProductGroupWrap
}