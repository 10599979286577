import { useEffect } from "react";
import {
  CloseButton,
  Desc,
  ModalContainer,
  ModalOpen,
  RegInput,
  Title,
} from "../../Registration/Login/style/Login_css";
import {
    ActiveApplyBtn,
  Comment,
  EmptityWrap,
  FollowContainer,
} from "./style/FollowModal.style";
import {
  GoBack,
  ModalContent,
  UserData,
} from "../../Registration/style/Registration_css";
import { Line } from "../../Account/SelectExhibitors/style/SelectExhibitors_css";
import { Formik } from "formik";
import { FollowSchema } from "../../../validation/validation";
import { useDispatch, useSelector } from "react-redux";
import { SendRequestJoinClevr } from "../../../store/profileSlice/profileAsyncThunk";
import { svg_icons } from "../../../assets/imgs/svg_icons";

const initialValues ={
    CompanyName: '',
    activity: '',
    name: '',
    lastName: '',
    email: '',
    tel: '',
    comment: ''
}

export const FollowModal = ({ setIsModal }) => {

  let ModalOpenWidth = 550;
  let ModalOpenHeight = 678;

  const {exitIcon, peopleIcon, bagIcon} = svg_icons
  const dispatch = useDispatch()
  const {forms: {requestPart, base, registr}} = useSelector((state) => state.app.appText)

  useEffect(() => {
    document.body.style.overflow = "hidden";
  });

  const closeBtnEvent = () => {
    setIsModal(false);
    document.body.style.overflow = "";
  };


  return (
    <ModalContainer>
      <ModalOpen maxWidth={ModalOpenWidth} height={ModalOpenHeight}>
        <CloseButton onClick={closeBtnEvent}>{exitIcon}</CloseButton>
        <FollowContainer>
          <Title>{requestPart.head}</Title>
          <Desc width={330}>
            {requestPart.subhead1} <br /> {requestPart.subhead2}
          </Desc>
          <ModalContent>
            <p>
              {bagIcon}
              {requestPart.blockCompanyH1}
            </p>
            <Line></Line>
          </ModalContent>
          <Formik  initialValues={initialValues} validationSchema = {FollowSchema} onSubmit = {(value) => {
                const SendValues = {
                    firstName: value.name,
                    phone: value.tel,
                    email: value.email,
                    lastName: value.lastName ,
                    companyName: value.CompanyName,
                    businessType: value.activity,
                    comment: value.comment
                }
                dispatch(SendRequestJoinClevr(SendValues))
                closeBtnEvent()
            }}>
            {({errors, touched, values, handleChange, handleSubmit, dirty, isValid}) => 
            (
                <>
          <EmptityWrap>
            <div className="Emptity">
              <p>{requestPart.organizationName}*</p>
              <RegInput
                name="CompanyName"
                placeholder={requestPart.organizationNamePl}
                onChange={handleChange}
              />
              {
                        (errors.CompanyName && values.CompanyName)
                        ||
                        (errors.CompanyName && touched.CompanyName) 
                         ? 
                        (<p className="Invalid_Input">{errors.CompanyName}</p> ) : 
                        null
                    }
            </div>
            <div className="Activity">
              <p>{requestPart.typeActivity}*</p>
              <RegInput name="activity" placeholder={requestPart.typeActivityPl} onChange={handleChange} />
              {
                        (errors.activity && values.activity)
                        ||
                        (errors.activity && touched.activity) 
                         ? 
                        (<p className="Invalid_Input">{errors.activity}</p> ) : 
                        null
                    }
            </div>
          </EmptityWrap>
          <ModalContent>
            <p>
              {peopleIcon}
              {requestPart.blockContactH1}
            </p>
            <Line></Line>
          </ModalContent>
          <UserData style={{ margin: "12px 0" }}>
            <div className="Name_Patronymic">
              <div className="Name">
                <p>{base.firstName}*</p>
                <RegInput
                  name="name"
                  placeholder={base.firstNamePl}
                  onChange={handleChange}
                />
                 {
                        (errors.name && values.name)
                        ||
                        (errors.name && touched.name) 
                         ? 
                        (<p className="Invalid_Input">{errors.name}</p> ) : 
                        null
                    }
              </div>
              <div className="Patronymic">
                <p>{registr.lastName}*</p>
                <RegInput
                  name="lastName"
                  placeholder={registr.lastNamePl}
                  onChange={handleChange}
                />
                {
                        (errors.lastName && values.lastName)
                        ||
                        (errors.lastName && touched.lastName) 
                        ? 
                        ( <p className="Invalid_Input">{errors.lastName}</p> ) : 
                        null
                    }
              </div>
            </div>
            <div className="Email_Tel">
              <div className="Email">
                <p>Email*</p>
                <RegInput
                name="email"
                  placeholder={base.emailPl}
                  onChange={handleChange}
                />
                {
                        (errors.email && values.email)
                        ||
                        (errors.email && touched.email) 
                        ? 
                        ( <p className="Invalid_Input">{errors.email}</p> ) : 
                        null
                    }
              </div>
              <div className="Tel">
                <p>{base.phone}</p>
                <RegInput
                name="tel"
                  type={"tel"}
                  placeholder={"+ _ _ _ (_ _) _ _ _  _ _  _ _"}
                  pattern={""}
                  onChange={handleChange}
                />
                 {
                        (errors.tel && values.tel) 
                        ||
                        (errors.tel && touched.tel)
                        ? 
                        ( <p className="Invalid_Input">{errors.tel}</p> ) : 
                        null
                    }
              </div>
            </div>
          </UserData>
          <Comment>
            <p>{requestPart.comment}*</p>
            <textarea name="comment" onChange={handleChange} />
            {
                        (errors.comment && values.comment) 
                        ||
                        (errors.comment && touched.comment)
                        ? 
                        ( <p className="Invalid_Input">{errors.tel}</p> ) : 
                        null
                    }
          </Comment>
          <div style={{display: 'flex'}}>
          <GoBack onClick={closeBtnEvent}>{base.cancel}</GoBack>
          <ActiveApplyBtn disabled={!(dirty && isValid)} style={(dirty && isValid) ? {opacity: '1'} : {opacity: '0.5'}} type="submit" onClick={handleSubmit}>{requestPart.submitRequest}</ActiveApplyBtn>
        </div>
          </>
          )
            }
            </Formik>
        </FollowContainer>
      </ModalOpen>
    </ModalContainer>
  );
};
