import styled from "styled-components";



const WrapRequests = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    padding: 16px;
    h2 {
    font-family: 'Helvetica';
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #363636;
    }

    .requests-filter {
        display: flex;
        padding-top: 16px;
        gap: 12px;
        .app-select {
            height: 34px;
            min-width: 195px;
            &#select1 {
              min-width: 202px;
            }
          }
        select {
            height: 37px;
            background: #F6F6F6;
            border: none;
            border-radius: 10px;
            padding: 10px 12px;
            outline: none;
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #484848;
            option{
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
            }
            min-width: 195px;
            max-width: 200px;
        }
        .filter-search {
            height: 37px;
            background: #f6f6f6;
            display: flex;
            align-items: center;
            border-radius: 10px;
            padding: 10px;
            order: 1;
            margin-left: auto;
            max-width: 606px;
            flex: 1;
            input {
              width: 100%;
              border-radius: 10px;
              background: #f6f6f6;
              border: none;
              outline: none;
              padding-left: 6px;
              font-family: 'Gilroy';
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #363636;
            }
          }
    }
`;

const MyFavoritesWrap = styled.div`
    border: 0.5px solid #D4D4D4;
    border-radius: 4px;
    overflow-y: auto;
    flex: 1;
    .empty_arr{
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #363636;
            padding: 13px 0px 0 10px;
    }
`;

const MyFavoritesInfo = styled.p`
    font-size: 12px;
    line-height: 15px;
    padding: 13px 0 6px 0;
`

const MyFavoritesItem = styled.div`
height: 76px;
background: #F8F8F8;
border-radius: 4px;
margin: 0 16px;
margin-top: 16px;
display: flex;
align-items: center;
h3{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    color: #363636;
    text-transform: uppercase;
}
.Favorites_item_block{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .dots_settings{
        border: none;
background-color: #EFEFEF;
border-radius: 8px;
width: 24px;
height: 24px;
display: flex;
align-items: center;
margin-right: 16px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
    }
}
`;



const FavoritesDescription = styled.p`
font-weight: 500;
font-size: 12px;
line-height: 110%;
color: #363636;
strong{
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 110%;
color: #363636;
}
`;

const SendDate = styled.p`
    font-weight: 400;
    font-size: 11px;
    line-height: 110%;
    color: #484848;
    padding-bottom: 4px;
    opacity: 0.9;
    padding-top: 2px;
`;


export {
    WrapRequests,
    SendDate,
    MyFavoritesWrap,
    MyFavoritesItem, 
    FavoritesDescription, 
    MyFavoritesInfo, 
}