import TestAvatar from '../../../../assets/Components/test/TestAvatar.jpg'
import TestExpanentLogo from '../../../../assets/Components/test/TestLogoExp.jpg'
import { svg_icons } from '../../../../assets/imgs/svg_icons'
import { socialIcons } from '../../../Footer/socialIcons.svg'

const {pavilionsIcon, pdfIcon} = svg_icons
const {contacts} = socialIcons

export const ContentData = [
    {
        head: 'Все пользователи',
        data: '15.11.2022',
        count: 1359,
        text: '+16 пользователей',
        btnText: 'Список пользователей',
        imgs: {
            avatars: [TestAvatar, TestAvatar, TestAvatar]
        }
    },
    {
        head: 'Заблокированные',
        data: '15.11.2022',
        count: 0,
        text: 'нет заблокированных пользователей',
        btnText: 'Список пользователей',
        imgs: {
            avatars: []
        }
    },
    {
        head: 'Организации',
        data: '15.11.2022',
        count: 259,
        text: '+1356 пользователей',
        btnText: 'Список организаций',
        imgs: {
            logo: [TestExpanentLogo, TestExpanentLogo, TestExpanentLogo]
        }
    },
    {
        head: 'Стенды',
        data: '15.11.2022',
        count: 338,
        text: '+2 стенда',
        btnText: 'Список стендов',
        icons: pavilionsIcon
    },
    {
        head: 'Посетители 3D',
        data: '15.11.2022',
        count: 359,
        text: '+56 уникальных посетителя',
        btnText: 'Список пользователей',
        icons: contacts
    },
    {
        head: 'Посетители сайта',
        data: '15.11.2022',
        count: 759,
        text: '+26 уникальных посетителя',
        btnText: 'Список пользователей',
        icons: contacts
    },
    {
        head: 'Заявки на встречу',
        data: '15.11.2022',
        count: 12,
        text: '+7 организаций',
        btnText: 'Список заявок',
        imgs: {
            logo: [TestExpanentLogo, TestExpanentLogo, TestExpanentLogo]
        }
    },
    {
        head: 'Документы',
        data: '15.11.2022',
        count: 759,
        text: '+2 скачаных документа',
        btnText: 'Список документов',
        icons: pdfIcon
    },
]