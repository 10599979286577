
// SEO. Title must be the same as link in header!

export const follow_ru = {
    name: "Участие в cleVR",
    h3: "Станьте участником выставки",
    p1: {
        1: "Создайте свой стенд и аватар в корпоративном стиле. Установите свое оборудование, которое будет демонстрировать свою работу.",
        2: "Получите индивидуальную прямую ссылку на Ваш стенд.",
        3: "Площадь стенда не ограничена.",
    },
    p2:
        "Приглашаем к сотрудничеству организаторов выставок и отдельные Компании.",
    p3:
        "Для того чтобы создать стенд, достаточно отправить нам заявку. Мы оперативно свяжемся с Вами.",
    button: "Подать заявку",


    head: "Станьте участником выставки",
    subhead: "Отправьте заявку и станьте участником выставки наравне с другими предприятиями",
    becomeMember: "Стать участником",
    benefitsHead: "Преимущества участия",
    benefitsSubhead: "Используйте возможности виртуального мира для продвижения своей продукции и поиска новых деловых партнеров!",
    stand: "Стенд",
    standDesc: "Используйте конструктор для создания уникального стенда предприятия.",
    avatar: "Аватар",
    avatarDesc: "Создайте своего персонажа в виртуальном мире.",
    cube3d: "3D-объекты",
    cube3dDesc: "Установите на стенде 3D –модели продукции и оборудования.",
    link: "Прямая ссылка",
    linkDesc: "Получите прямую ссылку на ваш стенд и делитесь ей с другими.",
    materialsHead: "Материалы для участников",
    materialsSubhead: "Ознакомьтесь с типовым договором и материалами для работы в выставочном пространстве",
    submitRequest: "Отправьте заявку и станьте участником выставки",
    nameContract: "Типовой договор",
    namePolicyHandler: "Политика обработки и хранения персональных данных",
    banner_1: "Баннер cleVR 400 x 600.jpg",
    banner_2: "Баннер cleVR 192 x 663.jpg",
    banner_3: "Баннер cleVR 728 x 90.jpg",
    banner_4: "Баннер cleVR 520 x 100.jpg",
    services: {
        askQuestion: "Задать вопрос",

        photoHead: "Фотоуслуги",
        photoSubhead: "Команда CleVR поможет вам подготовить фотоматериалы для стенда вашего предприятия ",
        photoBlockH1: "Предметная фотосъемка продукции",
        photoBlockSubH1: "Мы создадим профессиональные фотографии для демонстрации продукции будущим потребителям и заполнения страницы вашего предприятия",
        photoBlockH2: "Фотосъемка производства и процессов",
        photoBlockSubH2: "Наша цель – показать особенности и масштаб производства вашего предприятия",
        photoBlockH3: "Портретная фотосъемка сотрудников",
        photoBlockSubH3: "Имидж вашего предприятия – руководство и сотрудники, которые трудятся над совершенствованием продукта каждый день",

        designerHead: "Дизайнерские услуги",
        designerSubhead: "Команда CleVR поможет вам подготовить и оформить материалы стенда для участия в выставке",
        designerBlockH1: "3D-визуализация продукции",
        designerBlockSubH1: "Мы создадим реалистичные и интерактивные 3D-модели для максимально подробного ознакомления с вашей продукцией",
        designerBlockH2: "Разработка индивидуального стенда предприятия",
        designerBlockSubH2: "Мы создадим для вашего предприятия уникальный стенд в корпоративном стиле с учетом ваших пожеланий",
        designerBlockH3: "Индивидуальное оформление страницы предприятия",
        designerBlockSubH3: "Мы поможем оформить страницу вашего предприятия в уникальном стиле",
    },

}
