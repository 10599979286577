import styled from "styled-components";

const ExpanentsWrap = styled.div`
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px 16px;
` ;


const ExpanentContainer = styled.div`
     width: calc(1/5*100% - (1 - 1/5)*16px);

     @media (max-width: 1440px) {
        width: calc(1/4*100% - (1 - 1/4)*16px);
    }
    @media (max-width: 1200px) {
        width: calc(1/3*100% - (1 - 1/3)*16px);
    }
    @media (max-width: 900px) {
        width: calc(1/2*100% - (1 - 1/2)*16px);
    }
    @media (max-width: 620px) {
        width: 100%;
    }
`;

const ExpanentLogo = styled.div`
    height: 162px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-color: #EFEFEF;
    .Logo_Container{
        width: 190px;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img{
        max-width: 100%;
        max-height: 100%;
    }
    :hover{
        cursor: pointer;
        position: relative;
        .Add_Favorites_Company{
            display: block;
            background: #00000082;
            height: inherit;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            button{
                width: 123px;
                height: 32px;
                background: rgba(0, 0, 0, 0.6);
                border-radius: 10px;
                color: #FFFFFF;
                border: none;
                display: flex;
                align-items: center;
                float: right;
                justify-content: center;
                gap: 4px;
                margin: 8px 8px 0 0;
                cursor: pointer;
                .favorites_icon{
                    path{
                        fill: #FFFFFF;
                    }
                }
            }
        }
    }
    .Add_Favorites_Company{
        display: none;
    }
`;

const ExpanentContent = styled.div`
    height: 106px;
    background-color: #F6F6F6;
`;

const ExpanentNameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    padding: 14px 0 12px 0;
    border-bottom: 0.5px solid #D4D4D4;
    height: 50px;
    p{
        font-weight: 600;
        font-size: 13px;
        line-height: 110%;
        color: #363636;
        text-transform: uppercase;
        :hover{
            cursor: pointer;
        }
    }
`;

const ExpanentSettingsContainer = styled.div`
    position: relative;
`

const ExpanentSettings = styled.button`
    background: url(${props => props.bg}) center no-repeat;
    width: 24px;
    height: 24px;
    background-color: #EFEFEF;
    border-radius: 8px;
    border: none;
    cursor: pointer;
`;

const ExpanentDesc = styled.div`
    display: flex;
    margin: 0 12px;
    gap: 4px;
`;

const ExpanentViewsContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 8px;
    p{
        font-weight: 500;
        font-size: 13px;
        line-height: 110%;
        color: #363636;
    }
`;

const ExpanentVisitLink = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 6px;
    background: #34C57F;
    border-radius: 8px;
    height: 32px;
    margin-top: 12px;
    width: 100%;
    cursor: pointer;
    div{
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 13px;
    line-height: 111%;
    padding-top: 2px;
    font-family: 'Gilroy';
    }
`;

const MeetsCompanyBtn = styled.button`
font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 140%;
color: #34C57F;
height: 32px;
border: 1px solid #34C57F;
border-radius: 8px;
width: 100%;
margin-top: 12px;
background: #FFFFFF;
cursor: pointer;
`;

export {ExpanentsWrap, ExpanentContainer, 
    ExpanentLogo, ExpanentContent, 
    ExpanentNameContainer, ExpanentSettings,
    ExpanentViewsContainer, ExpanentVisitLink, 
    ExpanentDesc, ExpanentSettingsContainer, MeetsCompanyBtn
}