import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {svg_icons} from "../../../../../assets/imgs/svg_icons";
import AppSelect from "../../../../../components/AppSelect/AppSelect";
import {GetContactsTypes} from "../../../../../store/exhibitorSlice/exhibitorAsyncThunk";
import {
  CloseButton,
  ModalContainer,
  ModalOpen,
  Title,
} from "../../../../Registration/Login/style/Login_css";
import {BtnsWrap} from "../../../Admin/Roles/AddNewRole/AddNewRole.style";
import {AddContactWrap} from "./addContact.style";

export const AddContact = ({
  localeState,
  handlerActions,
  setAddcontact,
  typeText,
  addContact,
}) => {
  const dispatch = useDispatch();
  const {contactsTypes} = useSelector((state) => state.exhibitor);

  const {exitIcon} = svg_icons;
  const settings = {
    options: [{id: 0, value: "Выберите тип"}],
    multiplay: true,
  };
  settings.options.push(
    ...contactsTypes.map((el) => {
      return {id: el, value: typeText(el, "select")};
    })
  );

  useEffect(() => {
    dispatch(GetContactsTypes());
  }, [dispatch]);

  const ValidForm =
    Boolean(localeState.contact.name) && Boolean(localeState.contact.link);

  return (
    <ModalContainer>
      <ModalOpen maxWidth={372}>
        <CloseButton onClick={() => setAddcontact(false)}>
          {exitIcon}
        </CloseButton>
        <AddContactWrap>
          <Title>
            {addContact.update ? "Редактирование" : "Новый контакт"}
          </Title>
          <div className="choose_type_contact">
            <p className="head_text">Тип контакта*</p>
            {addContact.update ? (
              <button
                disabled={true}
                style={{opacity: "0.5"}}
                className="update_disabled_select"
              >
                {typeText(localeState.contact.name, "select")}
              </button>
            ) : (
              <AppSelect
                settings={settings}
                onChange={handlerActions("changeContact", {key: "name"})}
              />
            )}
          </div>
          <div className="link_description_contact">
            <p className="head_text">Ссылка/Описание*</p>
            <input
              value={localeState.contact.link}
              placeholder={"Введите ссылку"}
              onChange={handlerActions("changeContact", {key: "link"})}
            />
          </div>
          <BtnsWrap>
            <button
              style={{width: "100%", height: "36px"}}
              className="back"
              onClick={() => setAddcontact(false)}
            >
              {"Отменить"}
            </button>
            <button
              style={
                ValidForm
                  ? {width: "100%", height: "36px", opacity: "1"}
                  : {width: "100%", height: "36px", opacity: "0.5"}
              }
              className="send_btn"
              disabled={!ValidForm}
              onClick={handlerActions(
                addContact.update ? "updateContact" : "addContact"
              )}
            >
              {"Сохранить"}
            </button>
          </BtnsWrap>
        </AddContactWrap>
      </ModalOpen>
    </ModalContainer>
  );
};
