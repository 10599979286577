export const modalAddCompany_ru = {
    nameUrl: "Название компании для url адреса",
    nameUrlPlaceH: "Название компании",
    noNameUrl: "Email not entered",
    nameRu: "Название компании на русском",
    nameRuPlaceH: "Название компании",
    noNameRu: "Email not entered",
    nameEn: "Название компании на английском",
    nameEnPlaceH: "Company name",
    noNameEn: "Email not entered",
    nameBy: "Название компаниии на белорусском",
    nameByPlaceH: "Назва кампаніі",
    noNameBy: "Название компаниии на белорусском",
    example: "Пример",
    nameError: "Допускаются только маленькие буквы латинского алфавита",
    addCompanyBtn: "Добавить компанию",
}