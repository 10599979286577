import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAdminNews, deleteAdminNews, deleteNewsMedia, getAdminNews, updateNews, updateNewsMedia, updateNewsShow, makeMainNews, updatePreview } from "../../../../store/newsSlices/newsAsyncThunk";
import { deleteMedia, resetNewsState, setEditNews, setInfoNewNews, setMediaNewNews, setNewsAuthor, setPreviewMedia, setText } from "../../../../store/newsSlices/NewsSlices";
import { AdminNewsControl } from "./AdminNewsControl";
import { useRef } from "react";
import { CreateNews } from "./CreateNews/CreateNews";
import { resetMetaTagsState, setMetaTagsState } from "../../../../store/MetaTagsSlice/MetaTagsSlice";
import { createMetaData, getMetaData } from "../../../../store/MetaTagsSlice/MetaTagsAsyncThunk";
import Gilroy from "../../../../fonts/Gilroy/GilroyRegular.css"


export const AdminNewsControlContainer = ({contentParam}) => {

      const { language } = useSelector((state) => state.app.appText);
      const { createNews } = useSelector((state) => state.news);
      const {metaData, metaError} = useSelector((state) => state.metaTags)
      const { news } = useSelector((state) => state.news);
      const editorRef = useRef(null)

      const [localState, setLocalState] = useState({
        componentsText: {
            title: '',
            text: '',
            addBtnText: ''
        },
        langs: ["ru", "by", "en"],
        seoContent: "total",
        contentParam,
        language,
        search: "",
        openModalCreate: {
          newsId: "",
          open: false,
          type: ''
        },
        openModal: {
          open: false,
          news: "",
          type: "",
        },
      });

      const dispatch = useDispatch();


      useEffect(() => {
        switch (contentParam) {
            case 'all-news':
                dispatch(getAdminNews({mainNews: false, draft: false, search: localState.search}));
                break;
            case 'drafts-news':
                dispatch(getAdminNews({mainNews: false, draft: true, search: localState.search}));
                break;
            case 'main-news':
                dispatch(getAdminNews({mainNews: true, draft: false, search: localState.search}));
                break;
            default:
                break;
        }
        return () => {
            dispatch(resetNewsState('news'))
        }
      }, [dispatch, localState.search, contentParam]);

      useEffect(() => {
        switch (contentParam) {
            case 'all-news':
                setLocalState((state) => {
                  return {
                    ...state,
                    componentsText: {
                      title: `Все новости (${news.length})`,
                      text: "Здесь вы можете добавить и отредактировать информацию в разделе новостей на сайте",
                      addBtnText: 'Добавить новость',
                    },
                  };
                });
                break;
            case 'main-news':
                setLocalState((state) => {
                  return {
                    ...state,
                    componentsText: {
                      title: `Главные новости (${news.length})`,
                      text: "Здесь вы можете добавить и отредактировать последовательность главных новостей на сайте",
                      addBtnText: "Главная новость",
                    },
                  };
                });
                break;
            case 'drafts-news':
                setLocalState((state) => {
                  return {
                    ...state,
                    componentsText: {
                      title: `Черновики (${news.length})`,
                      text: "Здесь вы можете добавить и отредактировать черновик",
                      addBtnText: "Добавить черновик",
                    },
                  };
                });
                break;
            default:
                break;
        }
      }, [contentParam, news])
      
      useEffect(() => {
        if(localState.openModalCreate.type === 'edit'){
            localState.langs.forEach((lang) => {
                dispatch(getMetaData({url: `/news/${localState.openModalCreate.newsId}?lang=${lang}`, lang}))
            })
        }
        return () => {
            dispatch(resetMetaTagsState('metaData'))
        } 
      }, [dispatch, localState.openModalCreate.type])

      const handlerActions = (type, data) => async (e) => {
        switch (type) {
          case "changeLang":
            setLocalState((state) => ({ ...state, language: data }));
            break;
          case 'setText':
            if(!e.selection) dispatch(setText({value: e.content, language: localState.language}))
          break;
          case "setSearch":
            setLocalState((state) => {
              return { ...state, search: e.target.value };
            });
            break;
          case "setModalCreate":
            setLocalState((state) => {
              return {
                ...state,
                openModalCreate: {
                  newsId: data.newsId,
                  open: data.open,
                  type: data.type
                },
              };
            });
            break;
          case "setSeoContent":
            setLocalState((state) => {
              return { ...state, seoContent: data };
            });
            break;
          case "setInfoNewNews":
            dispatch(
              setInfoNewNews({
                language: data === "input" ? localState.language : false,
                type: e.target.name,
                value: data === "input" ? e.target.value : e.target.checked,
              })
            );
            break;
          case "setMediaNewNews":
            dispatch(setMediaNewNews(e.target.files[0]));
            break;
          case "saveDraftNews":
            localState.openModalCreate.type === 'create' ? 
              handlerActions('createNews', {type: 'draft', draft: data})() 
            :
              handlerActions('updateNews', {type: 'draft', draft: data})()
              setLocalState((state) => {
                return {
                  ...state,
                  openModalCreate: {
                    newsId: "",
                    open: false,
                    type: ''
                  },
                };
              });
            break;
          case "deleteMedia":
            if(data?.id){
                dispatch(deleteNewsMedia({media: data, newsId: localState.openModalCreate.newsId}))
            }else{
                dispatch(deleteMedia(data));
            }
            break;
          case "setPreviewMedia":
            if(data.id){
                dispatch(updatePreview({mediaId: data.id, preview: !data.preview}))
            }else{
               dispatch(
                 setPreviewMedia({ index: data.index, preview: !data.preview })
               );
            }
            break;
          case "setNewsAuthor":
            dispatch(setNewsAuthor(e.target.value));
            break;
          case "setModal":
            setLocalState((state) => {
              return {
                ...state,
                openModal: {
                  news: data.news,
                  open: data.open,
                  type: data.type,
                },
              };
            });
            break;
          case "createNews":
            const sendData = {
              name: createNews.name,
              shortName: createNews.shortName,
              text: createNews.text,
              draft: data?.type ? data.draft : createNews.draft,
              isShow: createNews.isShow,
              mainNews: createNews.mainNews,
              author: createNews.newsAuthor,
              duplicate: createNews.duplicatePreview,
            };
            let request = new Blob([JSON.stringify(sendData)], {
              type: "application/json",
            });
            const formData = new FormData();
            const previewMedia = createNews.media.find((el) => el.preview)
                if(previewMedia){
                    formData.append("preview", previewMedia.file);
                }
            createNews.media.forEach((item) => !item.preview ? formData.append("media", item.file) : '')
            formData.append("request", request);
            const createdNews = await dispatch(createAdminNews({body: formData, param: localState.contentParam}))
            localState.langs.forEach((item) => {
                const createMetaTag = {
                    type: 'create',
                    url: `/news/${createdNews.payload.response.id}?lang=${item}`,
                    image:'',
                    title: metaData.title[item],
                    description: metaData.description[item]
                }
                dispatch(createMetaData(createMetaTag))
            })
            setLocalState((state) => {
              return {
                ...state,
                openModalCreate: {
                  newsId: "",
                  open: false,
                },
              };
            });
            break;
          case "deleteNews":
            dispatch(deleteAdminNews(data.id));
            setLocalState((state) => {
              return {
                ...state,
                openModal: {
                  news: "",
                  open: false,
                  type: "",
                },
              };
            });
            break;
          case 'makeMainNews': 
            dispatch(makeMainNews(data))
            setLocalState((state) => {
                return {
                    ...state,
                    openModal: {
                        news: '',
                        open: false,
                        type: ''
                    }
                }
            })
          break;
          case "hideNews":
            dispatch(updateNewsShow(data))
            setLocalState((state) => {
                return {
                    ...state,
                    openModal: {
                        news: '',
                        open: false,
                        type: ''
                    }
                }
            })
            break;
          case "editNews":
            dispatch(
              setEditNews({
                name:data.newsTranslators.find(
                    (el) => el.language.languageCode === "RU"
                  ).name ? {
                  ru: data.newsTranslators.find(
                    (el) => el.language.languageCode === "RU"
                  ).name.name,
                  en: data.newsTranslators.find(
                    (el) => el.language.languageCode === "EN"
                  ).name.name,
                  by: data.newsTranslators.find(
                    (el) => el.language.languageCode === "BY"
                  ).name.name
                } : {
                    ru: '',
                    by: '',
                    en: ''
                },
                text:data.newsTranslators.find(
                    (el) => el.language.languageCode === "RU"
                  ).text ? {
                  ru: data.newsTranslators.find(
                    (el) => el.language.languageCode === "RU"
                  ).text.text,
                  en: data.newsTranslators.find(
                    (el) => el.language.languageCode === "EN"
                  ).text.text,
                  by: data.newsTranslators.find(
                    (el) => el.language.languageCode === "BY"
                  ).text.text,
                } :
                {
                    ru: '',
                    by: '',
                    en: ''
                },
                shortName:data.newsTranslators.find(
                    (el) => el.language.languageCode === "RU"
                  ).shortName ? {
                  ru: data.newsTranslators.find(
                    (el) => el.language.languageCode === "RU"
                  ).shortName.shortName,
                  en: data.newsTranslators.find(
                    (el) => el.language.languageCode === "EN"
                  ).shortName.shortName,
                  by: data.newsTranslators.find(
                    (el) => el.language.languageCode === "BY"
                  ).shortName.shortName,
                } 
                :
                {
                    ru: '',
                    by: '',
                    en: ''
                }
                ,
                duplicatePreview: data.duplicate,
                draft: data.draft,
                mainNews: data.mainNews,
                isShow: data.isShow,
                newsAuthor: data.author,
                media: data.media
              })
            );
            setLocalState((state) => {
              return {
                ...state,
                openModalCreate: {
                  newsId: data.id,
                  open: true,
                  type: 'edit'
                },
              };
            });
            break;
          case "updateNews":
                createNews.media.forEach((item) => {
                    if(!item.id){
                        const updateMedia = new FormData()
                        updateMedia.append('media', item.file)
                        let request = new Blob([JSON.stringify(item.preview)], {
                            type: "application/json",
                          });
                          updateMedia.append('preview', request)
                        dispatch(updateNewsMedia({media: updateMedia, newsId: localState.openModalCreate.newsId}))
                    }
                });
                localState.langs.forEach((lang) => {
                    const sendData = {
                      duplicate: createNews.duplicatePreview,
                      draft: data?.type ? data.draft : createNews.draft,
                      author: createNews.newsAuthor,
                      name: {
                        id: 0,
                        name: createNews.name[lang],
                      },
                      shortName: {
                        id: 0,
                        shortName: createNews.shortName[lang],
                      },
                      text: {
                        id: 0,
                        text: createNews.text[lang],
                      },
                      language: lang.toUpperCase(),
                    };
                    dispatch(updateNews({news: sendData, newsId: localState.openModalCreate.newsId}))
                    const updateMetaTag = {
                        type: metaError?.message ? 'create' : 'update',
                        url: `/news/${localState.openModalCreate.newsId}?lang=${lang}`,
                        image:'',
                        title: metaData.title[lang],
                        description: metaData.description[lang]
                    }
                    dispatch(createMetaData(updateMetaTag))
                })
                setLocalState((state) => {
                  return {
                    ...state,
                    openModalCreate: {
                      newsId: '',
                      open: false,
                    },
                  };
                });
                break;
          case 'setMetaData': 
                dispatch(setMetaTagsState({key: 'metaData', type: data.type, value: e.target.value, lang: localState.language}))
            break;
          default:
            break;
        }
      };

      const editorSettings= {
        apiKey: "0wxyqa96wlurz3nc493bpavuryfy2pz82s0g4ou2xme9vaug",
        onInit: (evt, editor) => editorRef.current = editor,
        init: {
                selector: 'textarea',
                height: 300,
                menubar: true,
                branding: false,
                fullscreen_native: true,
                plugins:
                   "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern file code",
                toolbar:
                   "formatselect | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor blockquote table | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent code | removeformat",
                image_advtab: true,
                file_picker_types: 'image media',
                file_picker_callback: (cb, value, meta) => {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.addEventListener('change', (e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener('load', () => {
                            /*
                              Note: Now we need to register the blob in TinyMCEs image blob
                              registry. In the next release this part hopefully won't be
                              necessary, as we are looking to handle it internally.
                            */
                            const id = 'blobid' + (new Date()).getTime();
                            const blobCache =  editorRef.current.editorUpload.blobCache;
                            const base64 = reader.result.split(',')[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            console.log(blobCache)
  
                            /* call the callback and populate the Title field with the file name */
                            cb(blobInfo.blobUri(), { title: file.name });
                          });
                        reader.readAsDataURL(file);
                        });

                  input.click();
                },
                font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Gilroy=Gilroy",
                  // Setting up the content styles. In this codepen we are using
                  //   content_style, therefore we disable the default styling that
                  //   comes with the default content_css
                  font_css: Gilroy,
                  content_style: `
                    blockquote{
                        ::before{
                            content: '***';
                            padding-right: 5px;
                        }
                        border-left: 4px solid #7dcca6;
                        margin-bottom: 10px;
                    }
                    blockquote > p{
                        font-family: 'Helvetica' !important;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 140%;
                        color: #363636;
                        padding-left: 13px;
                    }
                 `,
        },
        value: createNews.text[localState.language],
        onGetContent: handlerActions('setText')
      }

    return (
        <>
        {localState.openModalCreate.open ? 
            <CreateNews editorSettings={editorSettings} localState={localState} handlerActions={handlerActions} />
        :
            <AdminNewsControl
                editorSettings={editorSettings} 
                localState={localState} 
                handlerActions={handlerActions}
            />
        }
        </>
    )
}