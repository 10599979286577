export const profile_en = {
    statistics: {
        head: "Statistics",
        timePeriod: "Time period",
        today: "Today",
        week: "Week",
        month: "Month",
        year: "Year",
        setPeriod: "Set the period",
        periodSelection: "Period selection",
        apply: "Apply",
        downloadReport: "Download the report",
    },
    menu: {
        userPanel: "User panel",
        contactsAndRepres: "Contacts and representatives",
        contacts: "Contacts",
        myMeet: "My meetings",
        requests: "Requests",
        chats: "Chats",
        favoriteExhs: "Favorite exhibitors",
        documents: "Documents",
        _3dStand: "3D stand",
        exhPage: "Exhibitor's page",
        isExhibitor: 'You are an administrator:',
        profileCompany: 'Company:'
    },
    settings: {
        personalData: "Personal data",
        firstName: "Name",
        lastName: "Surname",
        patronymic: "Patronymic",
        close: "Close",
        save: "Save changes",
        security: "Security",
        password: "Password",
        forgetPassword: "Forgot your password?",
        currentPassword: "Current password",
        change: "Change",
        email: "E-mail",
        settings: "Settings",
    },
    contactsText: {
        name: 'Contacts',
        all: 'Total:',
        searchPl: 'Enter the name of the contact',
        offline: 'All',
        online: 'Online',
        noContacts: 'Add contacts',
        noOnline: 'No contacts online'
    },
    requestText: {
        name: 'Requests',
        all: 'Total',
        searchPl: 'What are you looking for?',
        theme: 'Subject:',
        noRequests: 'You don`t have any applications yet',
        search: 'Found',
        all: "All",
        requestConsult: "Request for consultation",
        requestMeet: "Request for a meeting",
        incoming: "Incoming",
        outgoing: "Outgoing",
        requests: "Requests",
        noRequests: "You have no requests",
    },
    meetsText: {
        name: 'Meetings',
        noMeets: 'You don`t have any confirmed meetings yet',
        searchPl: 'What are you looking for?',
        time: 'Time'
    },
    selectExhText: {
        name: 'Featured exhibitors',
        searchPl: 'Enter exhibitor name?',
        noSelectExh: 'You don`t have featured exhibitors yet',
        add: 'Added'
    },
    documentsText: {
        name: 'Documents',
        searchPl: 'What are you looking for?',
        noDocuments: 'You don`t have any documents added yet'
    },
    exhibitorPage: {
        h1: {
            mainScreen: 'Exhibitor Page',
            headScreen: 'Main screen',
            companyDesc: 'About company',
            representatives: 'Representatives',
            pavilionsGallery: 'Gallery',
            documents: 'Documents',
            contacts: 'Contacts',
        },
        h3: 'Sections',
        descPart1: 'Customize the company`s page by filling in the suggested sections.',
        descPart2: 'To display all the completed data on the page, click',
        descPart3: '“Publish”.'
    }
}