import React, {useState, useRef, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderLeftBox from "./HeaderLeftBox";
import { setAppChatState, setDialogsChat } from "../../../../store/appChatSlice/appChatSlice";


const HeaderLeftBoxContainer = () => {

    const {dialogs, mobileVersion} = useSelector(state => state.appChat);
    const dispatch = useDispatch();
    const [localState, setLocalState] = useState({ openSearch: false });
    let initDialogs = useRef(dialogs);

    const tools = {
        search: () => dispatch(setAppChatState({key: "toast", value: true})),
    }

    const handlerActions = (type, data) => (e) => {
        switch (type) {
          case "closeMobileChat":
            dispatch(setAppChatState({ key: "mobileVersion", value: false }));
            break;
            case "toggleSearch":
              setLocalState(state => ({...state, openSearch: !state.openSearch}))
              break;
            case "searchDialogs":
              let lowerValue = e.target.value.toLowerCase();
              let filterDialogs = initDialogs.current?.filter((dialog) =>
                  `${dialog.user.firstName} ${dialog.user.laststName}`.toLowerCase().includes(lowerValue)
                );
              filterDialogs && dispatch(setDialogsChat({ value: filterDialogs }));
              break;
          default:
            break;
        }
      };

      useEffect(() => {
        if (dialogs && !initDialogs.current) initDialogs.current = dialogs;
      }, [dialogs]);

    return <HeaderLeftBox 
      dialogs={dialogs} 
      ools={tools} 
      handlerActions={handlerActions} 
      localState={localState}
      mobileVersion={mobileVersion}/>
}

export default HeaderLeftBoxContainer